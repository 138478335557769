 const message = {
    COMMON: {
        NO_ACTIONS_AVAILABLE: 'Không có đối tượng nào cho hành động Thêm nhanh',
        NOTES_REMOVE_MESSAGE:'(Các) Ghi chú đã chọn sẽ bị xóa. Bạn có chắc không?',
        SELECTED_NOTE_MESSAGE:'Ít nhất phải chọn một Ghi chú để thực hiện hành động này.',
        BR_WINDOW_OPEN_CONFORMATION: 'Chúng tôi không hỗ trợ tính năng kéo và thả cho Chiến dịch. Bạn có muốn mở Chiến dịch không?',
        CONFIRM_DIALOG_TITLE: 'Hộp thoại xác nhận',
        ALERT_DIALOG_TITLE: 'Hộp thoại cảnh báo',
        XMS_SUCCESS_ALERT_DIALOG_TITLE: 'Thành công',
        XMS_ERROR_ALERT_DIALOG_TITLE: 'Lỗi',
        SAVE: 'Đã lưu thành công.',
        MERGE: 'Sáp nhập thành công.',
        UPDATE: 'Cập Nhật thành công.',
        DELETE: 'Đã xóa thành công.',
        REMOVE: 'Loại bỏ thành công.',
        UNLINK: 'Bỏ liên kết thành công ',
        COPY_EMAIL:'Id email đã được sao chép vào clipboard của bạn',
        EMAIL_VALIDATION:'Vui lòng nhập email hợp lệ.',
        EMPTY_EMAIL:'Không có email để sao chép',
        ERROR: 'Hệ thống không đáp ứng.',
        ACCESS_DENIED: 'Xin lỗi, bạn không có quyền thực hiện thao tác này. Hãy liên hệ với người quản trị.',
        NOTIFICATION_INFO_EMPTY: 'Không có thông báo',
        /** START:  Validation message */
        BLANK: 'blank',
        EMAIL: 'không phải là một phao.',
        VALID_EMAIL: 'Địa chỉ email mà bạn đã nhập không xuất hiện để có một địa chỉ hợp lệ. Xin vui lòng thử lại. Gợi ý: Hãy đảm bảo rằng địa chỉ email có định dạng \\@domain.com email.',
        URL: 'là một URL không hợp lệ.',
        VALID_URL: 'Vui lòng nhập URL hợp lệ.',
        INTEGER: 'không phải là số nguyên.',
        VALID_INTEGER: 'Vui lòng nhập số nguyên hợp lệ.',
        FLOAT: 'không phải là một phao.',
        VALID_FLOAT: 'Hãy nhập vào một phao hợp lệ.',
        VALID_PHONE: 'Số điện thoại phải được soá!!',
        VALID_LIST: 'Hãy chọn một giá trị.',
        TOO_BIG_SIZE: 'Giá trị bạn nhập vào quá lớn cho. Vui lòng nhập giá trị thấp hơn.',
        NO_RECORD: 'Không có hồ sơ tìm thấy.',
        VALIDATE_BLANK: 'không thể trống.',
        VALIDATE_INTEGER: 'phải là một số nguyên.',
        VALIDATE_DECIMAL: 'có được trong thập phân.',
        VALIDATE_POSITIVE_DECIMAL: 'phải lớn hơn 0 và trong thập phân.',
        VALIDATE_URL: 'Định dạng URL không hợp lệ. Ví dụ-www.soffront.com',
        VALIDATE_EMAIL: 'Định dạng email không hợp lệ.',
        VALUE: 'giá trị',
        LIBRARY_DIALOG_TITLE: 'Soffront thư viện trực tuyến',
        BLANK_EMAIL: 'Email là trống',
        INVALID_EMAIL: 'Xin vui lòng cung cấp một email hợp lệ!',
        BLANK_NUMBER : 'Số trống',
        INVALID_NUMBER :'Vui lòng cung cấp một số hợp lệ',
        EMPTY_SEARCH_CRITERIA: 'Vui lòng nhập tiêu chí tìm kiếm',
        VALIDATE_MORE_CHECKBOX_SELECTION: 'Bạn không thể thực hiện tìm kiếm với < * > khi có nhiều hơn một hộp kiểm được chọn!',
        VALIDATE_NO_CHECKBOX_SELECTION: 'Bạn không thể thực hiện tìm kiếm với < * > khi có nhiều hơn một hộp kiểm được chọn!',
        NO_DATA: 'Không tìm thấy dữ liệu',
        DISCONNECT_ALERT_MSG: 'Bạn có chắc chắn để ngắt kết nối khách hàng này?',
        DISCONNECT_SUCCESS: 'Bạn đã ngắt kết nối thành công khách hàng của mình.',
        CONFIRM_DELETE_RECORD: 'Bản ghi sẽ bị xóa bỏ. Bạn có chắc không?',
        DELETE_SUCCESS: ' đã được xóa thành công',
        COULDNOT_LOCATE: "Chúng tôi không thể xác định vị trí",
        DELETED_OR_ACCESS_DENIED: " Nó có thể đã bị xóa hoặc bạn có thể không có quyền truy cập vào nó hoặc bạn đã mở nó.",
        CLICK: 'Nhấp vào',
        HERE: 'Ở đây',
        MERGE_ALERT_1: 'Bạn có chắc để hợp nhất ',
        WITH: 'Với',
        MERGE_ALERT_2: 'và xóa',
        DATA_ACCESS_REQUEST_SEND_SUCCESS: 'Yêu cầu truy cập dữ liệu đã được gửi tới',
        DATA_ACCESS_REQUEST_SEND_FAILURE: 'Yêu cầu truy cập dữ liệu không thể gửi đến  ',
        REQUEST_DATA_ACCESS: 'Yêu cầu truy cập dữ liệu từ',
        WE_WILL_EMAIL: 'Chúng tôi sẽ gửi email',
        AT: 'Lúc',
        TO_APPROVE_REQUEST: 'để chấp thuận yêu cầu của bạn',
        HAVE_BEEN: 'Đã',
        REMINDER_VALUE: 'Giá trị Reminder không thể trống',
        DUPLICATE_EMAIL: 'Bạn đã có ghi (các) với cùng một email ID. Bấm OK để tiếp tục hoặc bấm Hủy bỏ để thay đổi email ID.',
        DUPLICATE_CONTACT_EMAIL: 'Bạn đã có (các) bản ghi có cùng id email. Vui lòng nhập id email khác.',
        CLIENT_CONNECT_MESSAGE: 'Khách hàng này không có tài khoản với email này trong Soffront Trực tuyến. Trước tiên, yêu cầu khách hàng tạo tài khoản bằng cùng một email.',
        BLANK_CAMPAIGN_TYPE: 'Loại chiến dịch không thể trống.',
        DUPLICATE_CAMPAIGN_TYPE: 'Loại chiến dịch đã tồn tại.',
        CAMPAIGN_TYPE_SAVE_SUCCESS: 'Loại chiến dịch đã lưu thành công.',
        /** END:  Validation message */
        STEPPER_BACK_MESSAGE: 'Bạn có muốn vào trang thông tin Mẫu không? Mọi thay đổi chưa được lưu sẽ bị mất.',
        CENCEL_MOSAICO_MESSAGE: 'Bạn có chắc bạn muốn hủy bỏ mà không lưu không?',
        NO_RESULTS_FOUND: 'Không tìm thấy kết quả',
        FOR: 'cho',
        ENABLE_MAILING_SUCCESS: 'Cho phép gửi email thành công',
        DISABLE_MAILING_SUCCESS: 'Gửi email thành công',
        MAILING_FAILURE: 'Tài khoản này không phải là người thuê CRM',
        TEST: 'Kiểm tra',
        SELECT_RECORD_FOR_DELETE: 'Vui lòng chọn một bản ghi để xóa.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Vui lòng chọn một bản ghi để chỉnh sửa.',
        SELECT_RECORD_FOR_DELETE_ONE_RECORD: 'Vui lòng chọn một bản ghi để xóa.',
        ASSETMENU_DIALOG_TITLE: 'Chọn một tài sản',
        UNIT_SELECT_ALERT_MESSAGE: 'Bạn có thể chọn một đơn vị tại một thời gian.',
        LOOKUP_MULTI_SELECT: 'Vui lòng chỉ chọn một bản ghi.',
        LOOKUP_SELECT_A_RECORD: 'Vui lòng chọn một bản ghi.',
        BOTH_COMPANY_AND_WORKFLOW_SELECTED: 'Bạn đã chọn cả công ty và quy trình làm việc.',
        VALIDATE_ZERO: 'không thể bằng không.',
        GREENLIGHT_EXPORT_NO_RECORD: 'Không có tài khoản nào trong quy trình làm việc Greenlight cho phạm vi ngày này.',
        WITHOUT_SAVE_MESSAGE: 'Bạn có muốn tiếp tục mà không lưu các thay đổi?',
        DELETE_API_KEY: 'Điều này sẽ xóa Khóa API của bạn trong CRM. Bạn có muốn tiếp tục không?',
        UNIT_ALERT: 'Vui lòng chọn một bản ghi để đăng nhập.',
        THIS_FIELD_CANNOT_BLANK: 'Chỗ này không được bỏ trống.',
        CANNOT_DELETE_ALL_ITEM: 'Bạn phải có một mục cuối cùng trong Menu di động.',
        DUPLICATE_MSG_1: 'Bạn đã có kỷ lục (s) với cùng một ',
        DUPLICATE_MSG_2: '. Nhấn Ok để tiếp tục hoặc nhấn Cancel để thay đổi ',
        DUPLICATE_MSG_3: ' và ',
        DUPLICATE_CONTACT_EMAIL_1: 'Bạn đã có kỷ lục (s) với cùng một ',
        DUPLICATE_CONTACT_EMAIL_2: '. Vui lòng nhập khác nhau ',
        INVALID_TIME_ERROR_MSG: 'Vui lòng cung cấp một khoảng thời gian hợp lệ.',
        DOESNT_HAVE_ASSOCIATED_1:'Điều này ',
        DOESNT_HAVE_ASSOCIATED_2: " không có liên hệ được liên kết.", 
        PHONE_CALL_NOT_ENABLED: 'Cuộc gọi điện thoại không được kích hoạt.',
        NOT_CONFG_CALL_SETUP : 'Bạn chưa định cấu hình thiết lập cuộc gọi.',
        NOT_CONFG_CALL_SMS_SETUP:'Bạn chưa định cấu hình thiết lập cuộc gọi / tin nhắn.',
        SEARCH_NO_CHECKBOX_SELECTION: 'Você não pode realizar a pesquisa quando nenhuma caixa de seleção está marcada!',
        VALID_SOURCE_LIST: 'Vui lòng chọn trường nguồn.',
        LMS_USER_NOT_AVAILABLE: 'LMS chưa được kích hoạt. Vui lòng liên hệ với quản trị viên của bạn.',   
        ADD_BRANCH_BILLINGMASSAGE:'Hiện tại bạn không có giấy phép. Bạn sẽ mua giấy phép mới cho thiết bị này với giá $40,00/tháng. Ngoài ra, phí thiết lập $100,00 sẽ được tính để thiết lập và đào tạo người thuê mới.'      
    },
    SALES_TEMPLATE: {
        NAME_BLANK: 'Vui lòng nhập tên để xác định mẫu này.',
        SUBJECT_BLANK: 'Vui lòng nhập một chủ đề cho mẫu này.',
        DUPLICATE_NAME: 'Bạn đã có mẫu có tên%%. Chúng tôi không hỗ trợ các mẫu trùng lặp. Hãy gõ vào một tên khác và thử lại.',
        DELETE: 'Mẫu sẽ bị xóa. Bạn có chắc không?',
        TEMPLATE_DELETED: 'Tiêu bản đã xóa thành công',
        TEMPLATE_NAME_ERROR: 'Bạn đã có mẫu hiện có với tên này. Vui lòng nhập tên khác và thử lại.',
    },
    AGREEMENT_TEMPLATE: {
        NAME_BLANK: 'Vui lòng nhập tên để xác định mẫu này.',
        // SUBJECT_BLANK: 'Please enter a subject for this template.',
        DUPLICATE_NAME: 'Bạn đã có một mẫu có tên%%. Chúng tôi không hỗ trợ các mẫu trùng lặp. Vui lòng nhập tên khác và thử lại.',
        DELETE: 'Mẫu sẽ bị xóa. Bạn có chắc không?',
        TEMPLATE_DELETED: 'Tiêu bản đã xóa thành công',
        TEMPLATE_NAME_ERROR: 'Bạn đã có mẫu hiện có với tên này. Vui lòng nhập tên khác và thử lại.',
        DUPLICATE_TEMPLAE_EXIST: 'Tên mẫu này đã tồn tại',
        RECORD_NOT_EXIST: 'Hồ sơ này không tồn tại.',
        SAVE_TO_DOWNLOAD: 'Bạn chưa lưu Mẫu Thỏa thuận. Vui lòng lưu mẫu Thỏa thuận trước để tải xuống.',
        TEMPLATE_CAN_NOT_EMPTY: 'Tên mẫu thỏa thuận không thể trống.',
        SELECT_TEMPLATE: 'Vui lòng chọn mẫu.',
        SENT_OR_SIGNED: 'Bạn đã gửi thỏa thuận này một lần. Bạn không thể gửi nó một lần nữa.',
        SIGNATURE_TAGS_NOT_SEQUENCED_1: 'Vui lòng thêm các thẻ chữ ký theo thứ tự.',
        SIGNATURE_TAGS_NOT_SEQUENCED_2: 'Có vẻ như bạn đã bỏ qua thứ tự tuần tự khi thêm thẻ chữ ký.',
        SIGNATURE_TAGS_MISSING: 'Không có thẻ chữ ký nào có sẵn trong nội dung Thỏa thuận. Cơ quan thỏa thuận phải có ít nhất một thẻ chữ ký.',
        SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING: 'Có vẻ như bạn đã sử dụng các trường hợp nhất cho chữ ký mà không sử dụng thẻ chữ ký. Vui lòng thêm thẻ chữ ký để tiếp tục.',
        AGREEMENT_EDIT: 'Você pode editar apenas registros de contrato e não outros registros de anexo ou registros de contrato já enviados.',
        AGREEMENT_EXPIRED_1: 'Thỏa thuận này đã hết hạn vào ngày ',
        AGREEMENT_EXPIRED_2: 'Vui lòng gia hạn ngày hết hạn thỏa thuận trước khi gửi nó.',
    },
    SMS_TEMPLATE: {
        BLANK_MESSAGE: 'Vui lòng nhập một tin nhắn để lưu mẫu này.',
        SAVE_MESSAGE: 'SMS đã lưu thành công.',
    },
    MARKETING_TEMPLATE: {
        NAME_BLANK: 'Vui lòng nhập tên để xác định mẫu này.',
        SUBJECT_BLANK: 'Vui lòng nhập một chủ đề cho mẫu này.',
        DUPLICATE_NAME: 'Bạn đã có mẫu có tên%%. Chúng tôi không hỗ trợ các mẫu trùng lặp. Hãy gõ vào một tên khác và thử lại.',
        DELETE: 'Mẫu sẽ bị xóa. Bạn có chắc không?',
        BACK: 'Nếu bạn quay trở lại, bạn sẽ mất các thay đổi chưa lưu của bạn. Bạn có muốn tiếp tục không?',
        SELECT_DESIGN: 'Chọn từ một thiết kế',
        TEMPLATE_UPDATE: 'Cập nhật mẫu giao diện',
        CONFIRM1: 'của',
        CONFIRM2: 'Hồ sơ sẽ được cập nhật',
        CONFIRM3: 'Bạn có chắc chắn không?',
        ALERT1: 'Rời khỏi',
        FROM_NAME_BLANK: 'Vui lòng nhập tên từ.',
        FROM_EMAIL_BLANK: 'Vui lòng chọn một từ email.',
        DUPLICATE_TEMPLATE_NAME: 'Tên mẫu đã tồn tại.'
    },
    BROADCAST_CAMPAIGNS: {
        NAME_BLANK: 'Xin vui lòng nhập tên để xác định các chiến dịch phát sóng này.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Hãy chọn',
        FILTER_TYPE_GROUP: 'một nhóm',
        FILTER_TYPE_CONTACT: 'một bộ lọc liên hệ',
        FILTER_TYPE_ACCOUNT: 'một bộ lọc tài khoản',
        SUBJECT_BLANK: 'Vui lòng nhập Chủ đề',
        FROM_NAME_BLANK: 'Vui lòng nhập Tên Từ',
        FROM_EMAIL_BLANK: 'Vui lòng chọn một Email Từ',
        GROUP_NAME_BLANK: 'Tên nhóm không thể trống!',
        SAVE: 'Bạn đã tạo thành công chiến dịch này.',
        UPDATE: 'Bạn đã cập nhật thành công chiến dịch này.',
        GROUP_NAME_SIZE_LIMIT: 'Tên nhóm không thể được nhiều hơn 128 ký tự.',
        EXECUTION_CANNOT_CANCEL_1: 'Thực hiện không có thể được hủy bỏ bây giờ.',
        EXECUTION_CANCEL_MSG: 'Ứng dụng sẽ cố gắng hủy bỏ việc thực hiện ngay lập tức.',
        CONFIRM: 'Xin vui lòng xác nhận.',
        EXECUTION_CANNOT_CANCEL_2: 'Chiến dịch đã bị xử lý không thể bị hủy bỏ.',
        PURCHASE_MORE: 'để mua nhiều hơn nữa.',
        CREDITS_REMAINING: 'tín dụng email còn lại.',
        VERIFY_BLANK_EMAIL: 'Bạn nhập địa chỉ email để xác minh',
        VERIFY_INVALID_EMAIL: 'Email của bạn không hợp lệ',
        SEND_VERIFICATION_EMAIL_SUCCESS: 'Chúng tôi đã gửi email xác minh. Vui lòng kiểm tra hộp thư đến của bạn và nhấp vào xác minh địa chỉ email này. Sau đó quay lại và nhấp vào biểu tượng làm mới để xem địa chỉ email mới của bạn.',
        EMAIL_INTEGRATION_NEW: 'Địa chỉ email này đã được gửi để xác minh! Vui lòng kiểm tra hộp thư đến của bạn và nhấp vào xác minh địa chỉ email này. Sau đó quay lại và nhấp vào biểu tượng làm mới để xem địa chỉ email mới của bạn.',
        EMAIL_INTEGRATION_VERIFIED: 'Địa chỉ email này đã được kiểm chứng',
        BD_NAME_COPY_ERROR: 'Bạn có một chiến dịch hiện tại với tên này. Vui lòng nhập một tên khác cho chiến dịch này',
        STEPPER_BACK_MESSAGE: 'Bạn có muốn vào trang thông tin phát sóng không? Mọi thay đổi chưa được lưu sẽ bị mất.'
    },
    SPACE: ' ',
    LIST_VIEW: {
        SELECT_RECORD: 'Hãy chọn một bản ghi để thực hiện hành động khác.',
        SELECT_RECORD_FOR_CONVERT: 'Vui lòng chọn một bản ghi để chuyển đổi.',
        SELECT_RECORD_FOR_EDIT: 'Hãy chọn một bản ghi để chỉnh sửa.',
        SELECT_RECORD_FOR_COPY: 'Vui lòng chọn một bản ghi để sao chép.',
        SELECT_RECORD_FOR_SEND_EMAIL: 'Hãy chọn một bản ghi để gửi email.',
        MULTIPLE_RECORD_FOR_EDIT: 'Hãy chọn một bản ghi cho chỉnh sửa.',
        MULTIPLE_RECORD_FOR_COPY: 'Vui lòng chọn một bản ghi để sao chép.',
        MULTIPLE_RECORD_FOR_MORE: 'Vui lòng chọn một bản ghi để biết THÊM.',
        CANNOT_COPY_APPOINTMENT_WORKFLOW: 'Bạn không thể sao chép dòng công việc cuộc hẹn.',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Vui lòng chọn một bản ghi để sao chép.',
        SELECT_MAXIMUM_FIELD_FOR_SETUP: 'Bạn đạt đến giới hạn tối đa của 15 trường lựa chọn.',
        CONVERT_CONTACT_TO_ACCOUNT_CONFIRM: 'Liên hệ này đã được liên kết với một tài khoản. Nhấp OK để xem chi tiết của tài khoản.',
        SHARE_PERMISSION: 'Bạn không có quyền chia sẻ kỷ lục này.',
        NOT_ADDED: 'Bạn chưa thêm',
        FIELDS_TO_VIEW: 'các lĩnh vực để xem',
        SETUP_MINIMUM_FIELD_ALERT: 'Hãy thêm một trường khác. Chúng tôi yêu cầu tối thiểu là hai lĩnh vực để xem.',
        SELECT_ANOTHER_TABLE: 'Vui lòng chọn một bảng khác và thử lại.',
        WEB_FORM_ALERT1: 'Rời khỏi',
        WEB_FORM_ALERT2: 'Mẫu websie đã được chọn thì không thể xóa vì nó được sử dụng trong thiết kế tự động hóa.',
        SETUP_WORKFLOWSTAGE_MINIMUM_FIELD_ALERT: 'Vui lòng thêm một trường cho cột. Chúng tôi yêu cầu tối thiểu 1 trường để xem.',
        SELECT_RECORD_FOR_SEND_SMS: 'Vui lòng chọn một bản ghi để gửi tin nhắn văn bản.',
        SELECT_RECORD_FOR_CALL: 'Vui lòng chọn bản ghi để thực hiện cuộc gọi.',
        SELECT_MORE_RECORD_ACTION_ALERT: 'Vui lòng chọn một bản ghi để thực hiện thêm hành động.',
        SELECT_RECORD_FOR_DOWNLOAD: 'Vui lòng chọn bản ghi để tải xuống tài khoản.',
        CHECKBOX_COLOR_GREEN_TITLE: 'Cập nhật lần cuối trong vòng 30 ngày',
        CHECKBOX_COLOR_YELLOW_TITLE: 'Last update within 30-60 days',
        CHECKBOX_COLOR_RED_TITLE: 'Cập nhật lần cuối trước 60 ngày',
        STATUS_FIELD_NOT_ADDED_FOR_VIEW: 'Bạn chưa thêm bất kỳ trường trạng thái nào để xem.',
        NO_EMAIL_TO_SEND_MAIL: 'Không có địa chỉ email nào sẵn dùng để gửi email.',
        REQUIRED_FIELD_CONVERT_FAILED: 'Chuyển đổi thất bại. Một hoặc nhiều lĩnh vực cần thiết bị thiếu.',
        SELECT_RECORD_FOR_To_EXCEL: 'Vui lòng chọn một bản ghi cho Excel.',
        SELECT_RECORD_FOR_ADD_TASK: 'Please chọn một bản ghi để thêm nhiệm vụ',
        UNIT_TASK_MESSAGE: 'Bạn chỉ có thể thêm nhiệm vụ cho các thiết bị được kết nối trực tiếp với bạn.',
        UNIT_SEND_EMAIL: 'Bạn chỉ có thể mở gửi email đến các đơn vị được kết nối trực tiếp với bạn',
        UNIT_PHONE_NUMBER: 'Bạn chỉ có thể mở Cuộc gọi điện thoại tới các thiết bị được kết nối trực tiếp với bạn',   
        FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED: 'Bạn không được phép xóa bản ghi đính kèm loại FDD hoặc FA.',
        MULTIPLE_SELECT_FDD_FA_MSG_1: 'Ra khỏi ',
        MULTIPLE_SELECT_FDD_FA_MSG_2: ' (các) bản ghi đính kèm đã chọn ',
        MULTIPLE_SELECT_FDD_FA_MSG_3: ' (các) bản ghi đính kèm sẽ không bị xóa vì đó là các bản ghi đính kèm loại FDD hoặc FA. Bạn có chắc không?',

    },
    AB_CAMPAIGNS: {
        NAME_BLANK: 'Nhập tên cho chiến dịch.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Hãy chọn',
        FILTER_TYPE_GROUP: 'một nhóm',
        FILTER_TYPE_CONTACT: 'một bộ lọc liên hệ',
        FILTER_TYPE_ACCOUNT: 'một bộ lọc tài khoản',
        GROUP_FILTER_BLANK: 'Chọn một nhóm.',
        CONTACT_FILTER_BLANK: 'Hãy chọn một bộ lọc liên hệ.',
        ACCOUNT_FILTER_BLANK: 'Vui lòng chọn bộ lọc tài khoản.',
        SUBJECT_BLANK: 'Nhập vào một chủ đề cho thư này.',
        FROM_NAME_BLANK: 'Nhập tên người gửi.',
        FROM_EMAIL_BLANK: 'Nhập email của người gửi.',
        GROUP_NAME_BLANK: 'Tên nhóm không thể trống!',
        AB_DISTRIBUTION: 'Nhập các một / b phân phối.',
        INVALID_AB_DISTRIBUTION: 'Phân phối a/b không hợp lệ.',
        INVALID_WINNING_MARGIN: 'Margin cho người chiến thắng là không hợp lệ.',
        ZERO_WINNING_MARGIN: 'Nhập margin cho người chiến thắng.',
        MESSAGE_B_NOT_FILLED: 'Vui lòng nhập các chi tiết thông báo B.',
        AB_DISTRIBUTION_OUT_OF_RANGE: 'Nhập một giá trị từ 1 đến 99 để thử nghiệm ',
        DAYS_BLANK: 'Vui lòng nhập bao nhiêu ngày bạn muốn đợi trước khi gửi thư đã chiến thắng vào danh sách còn lại.',
        INVALID_DAYS: 'Vui lòng nhập bao nhiêu ngày bạn muốn đợi trước khi gửi thư đã chiến thắng vào danh sách còn lại.',
        SAVE: 'Bạn đã tạo thành công chiến dịch này.',
        UPDATE: 'Bạn đã cập nhật thành công chiến dịch này.',
        EXECUTION_CANNOT_CANCEL_1: 'Thực hiện không có thể được hủy bỏ bây giờ.',
        EXECUTION_CANCEL_MSG: 'Ứng dụng sẽ cố gắng hủy bỏ việc thực hiện ngay lập tức.',
        CONFIRM: 'Xin vui lòng xác nhận.',
        EXECUTION_CANNOT_CANCEL_2: 'Chiến dịch đã bị xử lý không thể bị hủy bỏ.',
        CAMPAIGN_EXECUTE_IN_60_MIN: 'Chiến dịch này sẽ thực thi trong 60 phút.',
        OK_TO_CONFIRM: 'Bấm OK để xác nhận',
        ERROR_MSG_1: 'Vui lòng chọn mẫu.',
    },
    REPEAT_DIALOG: {
        END_DATE_BLANK: 'Ngày kết thúc không thể trống.',
        INVALID_END_DATE: 'Ngày bắt đầu không thể lớn hơn hoặc tương tự như ngày kết thúc.',
        DAY_CHECKBOX_BLANK: 'Ít nhất là kiểm tra một ngày.',
        DELETE_RECUR_TITLE: 'Xóa định kỳ ',
        EDIT_RECUR_TITLE: 'Chỉnh sửa định kỳ ',
        REPEAT: 'Lặp lại'
    },
    BATCH_ACTION: {
        CONVERT_SUCCESSFULL: ' đã được chuyển đổi thành công. ',
        SINGLE_CONVERT_SUCCESSFULL: ' đã được chuyển đổi thành công. ',
        CONVERT_UNSUCCESSFULL: 'Hành động không thể thực hiện',
        CONVERT_SAME_STAGE: ' bản ghi đã có trong ',
        MULTI_SHARE_SUCCESSFULL: ' đã được chia sẻ thành công. ',
        SINGLE_SHARE_SUCCESSFULL: ' đã được chia sẻ thành công. ',
        SHARE_UNSUCCESSFULL: 'Hành động không thể thực hiện',
        MULTI_ASSIGN_SUCCESSFULLY: ' đã được gán thành công.',
        SINGLE_ASSIGN_SUCCESSFULLY: ' đã/đã được chỉ định thành công.',
        ASSIGN_UNSUCCESSFULL: 'Hành động không thể thực hiện',
        COMPLETE_SUCCESSFULL: ' đã được hoàn tất thành công.',
        BATCH_COMPLETE_SUCCESSFULL: ' đã được hoàn tất thành công.',
        COMPLETE_UNSUCCESSFULL: 'Hành động không thể thực hiện',
        GROUP_ADDED_SUCCESSFULLY: 'Được thêm thành công vào (các) Nhóm',
        GROUP_REMOVED_SUCCESSFULLY: 'Loại bỏ thành công khỏi tất cả các (các) nhóm.',
        GROUP_CLICK_TO_CONFIRM: ' sẽ được thêm vào (các) nhóm đã chọn nhấp vào  "OK " để xác nhận.',
        GROUP_BLANK_ADDED_BATCH: 'Hãy chọn ít nhất một nhóm.',
        NOTE_TXT_CANNOT_BE_BLANK: 'Văn bản không thể trống.',
        WORKFLOW_CONFIRMATION: 'Quy trình làm việc sẽ được thay đổi cho hồ sơ này. Nhấp Được để xác nhận.',
        BATCH_WORKFLOW_CONFIRMATION: 'Quy trình làm việc sẽ được thay đổi cho các hồ sơ này. Nhấp Được để xác nhận.',
        WORKFLOW_APPLIED: 'Workflow được áp dụng thành công.',
        RECORDS_WITH_DIFFERENT_WORKFLOW: 'Bạn đã chọn bản ghi với các quy trình công việc khác nhau. Bấm OK để tiếp tục và thực hiện thao tác áp dụng quy trình làm việc hoặc bấm Hủy bỏ để chọn lại các bản ghi.',
        RECORDS_WITH_DIFFERENT_WORKFLOW_STATUS: 'Bạn đã chọn bản ghi với trạng thái khác nhau. Bấm OK để tiếp tục và thực hiện thao tác áp dụng quy trình làm việc hoặc bấm Hủy bỏ để chọn lại các bản ghi.',
        ACTIVE_SUCCESSFULL: 'Trạng thái đã được thay đổi thành hoạt động.',
        INACTIVE_SUCCESSFULL: 'Trạng thái đã được thay đổi để không hoạt động.',
        BATCH_EDIT_BLANK: 'Lĩnh vực giá trị không thể trống!',
        INVALID_WORKFLOW_STAGE: 'Bạn không thể chuyển đổi bản ghi.Status của bản ghi đã chọn không có sẵn trong quy trình làm việc hiện tại. Nhấn Ok để thay đổi trạng thái hoặc quy trình làm việc',
        CONFIRMATION_DELETE_MSG: ' sẽ bị xóa. Bạn có chắc không?',
        CONFIRMATION_REMOVE_MSG: ' sẽ được gỡ bỏ. Bạn có chắc không?',
        CONFIRMATION_UNLINK_MSG: ' sẽ được không liên kết. Bạn có chắc không?',
        CONVERT_ALERT_MSG: ' sẽ được chuyển đổi để ',
        CLICK_TO_CONFIRM: ' Nhấp Được để xác nhận.',
        ASSIGN_ALERT_MSG: ' Record (s) sẽ được gán cho ',
        ROUND_ROBIN_BASIS: ' trên cơ sở một Robin Round. Bạn có chắc không?',
        CONFIRM_MSG: 'Bạn có chắc không?',
        RECORDS: 'Hồ sơ',
        RECORD: 'Ghi lại',
        ALL_RECORDS: 'Tất cả hồ sơ',
        RECORD_WILL_BE_UPDATED: 'Hồ sơ sẽ được Cập Nhật để',
        CONFIRM_UNSUBSCRIBE_RECORD: ' sẽ bị hủy đăng ký. Bạn có chắc không?',
        UNSUBSCRIBE: 'Bạn đã bỏ đăng ký thành công (các) số liên lạc đã chọn.',
        USE_ASSIGN_RULE_ALERT_MSG: ' (các) hồ sơ sẽ được chỉ định cho đại diện bán hàng theo ',
        EMPTY_RECYCLE_BIN: 'Tất cả các hồ sơ sẽ bị xóa vĩnh viễn khỏi Thùng rác. Bạn có chắc không?',
        PERMANENT_DELETE_MSG: ' sẽ bị xóa vĩnh viễn. Bạn có chắc không?',
        INVALID_STATUS_FOR_WORKFLOW_STAGE: 'Trạng thái của bản ghi đã chọn không có sẵn trong dòng công việc hiện tại. Nhấn Ok để thay đổi trạng thái của bản ghi.',
        CONTACT_STATUS_UPDATE_SUCCESS: ' trạng thái đã được cập nhật thành công.',
        PUBLISH_SUCCESSFULL: 'Trạng thái đã được thay đổi thành Xuất bản.',
        UNPUBLISH_SUCCESSFULL: 'Trạng thái đã được thay đổi thành Chưa xuất bản.',
        RECORDS_NOT_IN_WORKFLOW: 'Tất cả các bản ghi đã chọn không có trong quy trình liên hệ.',
        RECORD_NOT_IN_WORKFLOW: 'Bạn không thể chuyển đổi như hồ sơ đã chọn không có trong luồng công việc liên hệ.'
    },
    BATCH_ACTION_VALIDATION: {
        ADDTOGROUP_BLANK: 'Bản ghi đã chọn sẽ bị loại bỏ khỏi tất cả các nhóm. Nhấp vào  "OK " để xác nhận.',
        ADDTOGROUP_NAMEBLANK: 'Tên nhóm không thể trống.',
        SHARE_WITH_BLANKUSER: 'Hãy chọn ít nhất một người dùng.',
        ASSIGN_BLANKUSER: 'Hãy chọn người dùng.',
        CONVERT_SELECT_STATUS: 'Vui lòng chọn trạng thái cho ',
        MAP_INSUFFICIENT_INFO: 'Không đủ thông tin để hiển thị bản đồ',
        GROUP_ALREADY_EXIST: 'Tên nhóm đã tồn tại!',
        ALREADY_ACTIVE: 'Bản ghi đã có trong trạng thái hoạt động.',
        ALREADY_INACTIVE: 'Bản ghi đã có trong trạng thái không hoạt động.',
        ALREADY_PUBLISH: 'Ghi lại trong trạng thái Xuất bản.',
        ALREADY_UNPUBLISH: 'Ghi lại trong trạng thái Unpublish.',
    },
    ATTACHMENT: {
        ATTACHMENT_UPLOAD: 'Tập tin đính kèm được tải lên thành công',
        ATTACHMENT_LINK_ADDED: 'Liên kết tài liệu đã được thêm thành công!',
        ATTACHMENT_UPLOAD_IMAGE: 'Hình ảnh đã tải lên thành công',
        ATTACHMENTS_UPLOAD: 'Các phần đính kèm được tải lên thành công',
        VALIDATE_ATTACHMENT: 'Vui lòng chọn một tập tin để đính kèm  ',
        ATTACHMENT_UPLOAD_FAILURE: ' Tệp này đã tồn tại.',
        VALIDATE_EMPTY_FILE: 'Kích thước tệp tải lên không thể được 0',
        VALIDATE_FILE_SIZE: 'Kích thước tập tin tải lên có thể không được nhiều hơn 5 MB',
        VALIDATE_LOGO_SIZE: 'Ảnh bạn chọn quá lớn để dùng. Kích cỡ tệp tối đa được phép là 500 kB.',
        VALIDATE_BLANK_FILE: 'Bạn không thể tải lên một tệp trống',
        VALIDATE_LOGO_EXTENSION: 'Xin vui lòng chọn một jpg gif hoặc PNG file!',
        ATTACHMENT_LOGO_TEXT: 'Bạn có thể tải lên tệp JPG, GIF hoặc PNG.',
        ATTACHMENT_LOGO_SIZE_TEXT: ' Kích cỡ tệp tối đa được phép là 500 kB.',
        UPLOAD_IMAGE: 'Tải lên một hình ảnh',
        UPLOAD_FILE: 'Chọn tập tin để tải lên (tối đa năm tác phẩm cùng một lúc)',
        ADD_LINK: 'Thêm một liên kết bên ngoài',
        VALIDATE_MAX_ATTACHMENTS: 'Bạn không thể tải lên nhiều hơn năm tập tin',
        SELECT_IMAGE_TO_UPLOAD: 'Chọn một hình ảnh để tải lên',
        MAX_SIZE_2_MB_ALERT: 'Kích cỡ tệp tải lên không thể được nhiều hơn 2 MB',
        VALID_FILE_UPLOAD: 'Bạn có thể tải lên một jpg gif hoặc png tập tin từ máy tính.',
        MAX_SIZE_2_MB: 'Kích thước tệp tối đa được phép là 2 MB.',
        CANNOT_UPLOAD_EXECUTABLE_FILE: 'Bạn không thể tải lên tệp thực thi',
        THE_ATTACHMENT: 'Các tập tin đính kèm',
        DOCUMENT_LINK: 'Liên kết tài liệu', 
        FOLDER_NAME: 'Tên cặp',
        FILE_NAME:'Tên tệp',
        ALREADY_EXIST: 'đã tồn tại.',
        CANNOT_BE_DELETED: 'không thể xóa.',
        CLICK_TO_DELETE: 'Bấm OK để xóa ',
        EMPTY_FOLDER_ERROR_DOWNLOAD: 'Không thể tải xuống khi thư mục trống.',
        EMPTY_FOLDER_ERROR_PUSH: 'Không thể đẩy như thư mục trống.',
        RENAME_SUCCESSFULLY: 'Cặp đổi tên thành công.',
        DELETE_SUCCESSFULLY: 'Cặp đã xóa thành công.',
        ADDED_SUCCESSFULLY: 'Thêm thành công.',
        DOCUMENT_LINK_ADDED_SUCCESSFULLY: 'Liên kết tài liệu đã được thêm thành công.',
        DOCUMENT_LINK_UPDATE_SUCCESSFULLY: 'Liên kết tài liệu được cập nhật thành công.',
        DOCUMENT_LINK_DELETE_SUCCESSFULLY: 'Liên kết tài liệu đã được xóa thành công.',
        FILE_ADDED_SUCCESSFULLY: 'Đã thêm tệp thành công.',
        FILE_RENAME_SUCCESSFULLY: 'Đã đổi tên tệp thành công.',
        FILE_DELETE_SUCCESSFULLY: 'Đã xóa tệp thành công.',
        IMAGE_RENAME_SUCCESSFULLY: 'Hình ảnh đổi tên thành công.',
        IMAGE_NAME: 'Tên hình ảnh',
        MAX_SIZE_50_MB: 'Kích thước tệp tối đa cho phép là 50 MB.',
    },
    AUTOMATION_DESIGNER: {
        STEPPER_BACK_MESSAGE: 'Bạn có muốn vào trang thông tin Mẫu không? Mọi thay đổi chưa được lưu sẽ bị mất.',
        MAX_CONNECTION: "Số tối đa kết nối đã đạt tới.",
        TOUCH_CAMPAIGN_NAME: "Hãy chọn tên cho chiến dịch cảm ứng này",
        SELECT_FILTERS: "Hãy chọn một",
        SELECT_FILTERS_A: "Vui lòng chọn một",
        START_DATE: "Vui lòng chọn ngày bắt đầu cho",
        EMPTY_WEBSITE_LINK: "Bạn không thể thêm liên kết trang web có sản phẩm nào. ",
        CANNOT_START_WITH_DECISSION: 'Bạn không thể bắt đầu với một quyết định. Hãy bắt đầu với bất kỳ tùy chọn nào dưới đầu với trình đơn ở bên trái.',
        CANNOT_START_WITH_ACTION: 'Bạn không thể bắt đầu với một quyết định. Hãy bắt đầu với bất kỳ tùy chọn nào dưới đầu với trình đơn ở bên trái.',
        CONNECT_WITH_PROPER_NODE: 'Kết nối với nút thích hợp',
        FROM_NAME: 'Vui lòng nhập tên cho',
        FROM_EMAIL: 'Vui lòng chọn một Email',
        RESEND_AFTER: 'Xin vui lòng nhập gửi lại sau khi trong ngày (s)',
        SELECT_WEB_FORM: 'Hãy chọn một mẫu web',
        SELECT_GROUP: 'Vui lòng chọn một nhóm.',
        SELECT_GROUP_OPERATION: 'Hãy chọn thao tác nhóm.',
        ENTER_NOTE: 'Xin vui lòng nhập lưu ý của bạn.',
        SELECT_ONE_RECIPIENT: 'Hãy chọn ít nhất một người nhận',
        SELECT_USER: 'Hãy chọn người dùng.',
        ENTER_TASK_NAME: 'Vui lòng nhập tên tác vụ',
        PROVIDE_DUEBY_FIELD: 'Vui lòng cung cấp một giá trị là do trong bởi lĩnh vực!',
        ENTER_LINK: 'Vui lòng nhập liên kết.',
        PROVIDE_VALID_URL: 'Xin vui lòng cung cấp một URL trang web hợp lệ!',
        CATCH_ALL_LINK_CONNECTION: 'Bạn đã có một Catch tất cả các liên kết click điều kiện',
        ENTER_SCORE: 'Vui lòng nhập điểm',
        INVALID_SCORE: 'Bạn đã nhập một điểm không hợp lệ',
        ENTER_SUBJECT: 'Vui lòng nhập Chủ đề',
        ENTER_BODY: 'Vui lòng nhập nội dung cơ thể',
        ENTER_INTERVAL_VALUE: 'Vui lòng nhập giá trị cho khoảng thời gian',
        SELECT_ONE_HOUR: 'Xin vui lòng chọn 1 giờ hoặc nhiều hơn cho khoảng thời gian.',
        BLANK_CAMPAIGN_NAME: 'Tên chiến dịch không thể trống.',
        CAMPAIGN_SAVED_SUCCESSFULLY: 'Chiến dịch này đã được lưu thành công',
        BLANK_CANVAS: 'Bạn không thể lưu vải trống.',
        NOT_ROOT_NODE: 'không thể là một nút gốc.',
        START_DATE_MUST_BE_GREATER: 'Ngày bắt đầu phải lớn hơn mà các chiến dịch liên lạc trước.',
        EDIT_AND_COMPLETE_DETAILS: 'Vui lòng chỉnh sửa và hoàn tất các chi tiết của',
        PROVIDE_WAIT_INTERVAL: 'Xin vui lòng cung cấp một khoảng thời gian chờ đợi hơn bằng không.',
        CHECK_INTERVAL_WITH_PREVIOUS_TOUCH_CAMPAIGN: 'Vui lòng kiểm tra khoảng thời gian với các chiến dịch liên lạc trước đó ngày gửi lại (s).',
        CHECK_START_DATE_OR_WAIT_BEFORE_SAVE_TC: 'Vui lòng kiểm tra khoảng thời gian với các chiến dịch liên lạc trước đó ngày gửi lại (s).',
        PROVIDE_START_DATE: 'Vui lòng cung cấp ngày bắt đầu.',
        INTERVAL_EXPEXT_ZERO: 'Vui lòng cung cấp khoảng thời gian trừ số không.',
        EMPTY_LINK_NODE: 'Bạn không thể thêm nút liên kết trống.',
        DELETE_NODE_CONFIRMATION: 'Nút sẽ bị xóa bỏ. Bạn có muốn tiếp tục không?',
        MSG_CANT_BE_DELETED: 'Thư này đã được gửi và không thể bị xóa bỏ.',
        FIRST_MSG_CANT_BE_DELETED: 'Bạn không thể xoá thư đầu tiên của một chiến dịch cảm ứng',
        FIRST_WAIT_CANT_BE_DELETED: 'Bạn không thể xóa sự chờ đợi đầu tiên của chiến dịch cảm ứng',
        BLANK_INTERVAL: 'Khoảng không thể để trống',
        PROVIDE_POSITIVE_INTERVAL: 'Vui lòng cung cấp một khoảng lớn hơn 0',
        WONT_BE_ABLE_TO_DELETE: 'Bạn sẽ không thể xóa nó.',
        URL_NOT_EXIST: 'URL bạn được cung cấp không có sẵn trong email của cơ thể.',
        GROUP_NAME_BLANK: 'Tên nhóm không thể trống!',
        SELECT_LANDING_PAGE: 'Hãy chọn trang đích',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'Bạn đã có một nhà thiết kế tự động mở, hãy đóng hoặc lưu nó để mở một nhà thiết kế tự động hóa mới.',
        SAVE_DIAGRAM_BEFORE_COPY: 'Xin vui lòng lưu các biểu đồ trước khi thực hiện thao tác copy.',
        NO_TOUCH_FOUND: 'Không tìm thấy chiến dịch cảm ứng.',
        ADD_ONE_FIELD: 'Hãy thêm ít nhất một trường',
        SELECT_FIELD_VALUE: 'Vui lòng chọn giá trị trường',
        INVALID_URL: 'là một URL không hợp lệ.',
        INVALID_INTEGER: 'không phải là số nguyên.',
        INVALID_EMAIL: 'là một địa chỉ thư điện tử không hợp lệ.',
        ALLOWED_UPTO_FOUR_FIELDS: 'Bản Cập Nhật được cho phép lên đến 4 lĩnh vực. Bạn không thể thêm một hàng khác của cặp giá trị trường.',
        WILL_BE_UPDATED: 'sẽ được Cập Nhật. Bạn có chắc không?',
        DUPLICATE_TOUCH_NAME: 'Bạn đã có chiến dịch gửi liên lạc có tên',
        TOOLTIP_MESSAGE_SEND_MAIL: 'Bắt đầu với một chiến dịch cảm ứng mà gửi một loạt các email. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_WEB_FORM: 'Bắt đầu bằng cách xác định những gì sẽ xảy ra sau khi một mẫu web được gửi. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_LANDING_PAGE: 'Bắt đầu bằng cách xác định những gì sẽ xảy ra sau khi một trang đích được gửi. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_WEBSITE_VISIT: 'Bắt đầu bằng cách xác định những gì sẽ xảy ra sau khi một số liên lạc truy cập một trang cụ thể trên trang web của bạn. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_LINK_CLICK: 'Xác định điều gì sẽ xảy ra sau khi một liên kết trong email của bạn được nhấp bởi một số liên lạc. Để sử dụng kéo và thả nó trên vải bên phải và kết nối nó với một tin nhắn cảm ứng.',
        TOOLTIP_MESSAGE_EMAIL_OPEN: 'Xác định điều gì sẽ xảy ra sau khi một thư cảm ứng được mở bởi một số liên lạc. Để sử dụng kéo và thả nó trên vải bên phải và kết nối nó với một tin nhắn cảm ứng.',
        TOOLTIP_MESSAGE_UNSUBSCRIBE: 'Xác định những gì sẽ xảy ra sau khi một unsubscribes liên lạc từ một tin nhắn cảm ứng. Để sử dụng kéo và thả nó trên vải bên phải và kết nối nó với một tin nhắn cảm ứng.',
        TOOLTIP_MESSAGE_SCORE_REACHED: 'Xác định điều gì sẽ xảy ra sau khi một số liên lạc đạt đến một ngưỡng điểm. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_AUTO_MAIL: 'Email gửi sẽ được sử dụng để gửi cả email một lần và chạm. Hành động này có thể được thêm vào sau Chiến dịch cảm ứng hoặc bất kỳ quyết định hoặc bất kỳ hành động nào.',
        TOOLTIP_MESSAGE_CHANGE_GROUP: 'Thêm hoặc loại bỏ trương mục và danh bạ khỏi nhóm. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_ADD_NOTE: 'Thêm ghi chú vào tài khoản. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_NOTIFY: 'Thông báo cho người dùng CRM. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_ADD_TASK: 'Gán tác vụ cho người dùng khác. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_UPDATE_FIELD: 'Cập Nhật giá trị của trường cho tài khoản hoặc liên hệ. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_ASSIGN: 'Gán lại tài khoản cho người dùng khác. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_CONVERT: 'Chuyển đổi tài khoản sang trạng thái mới. Để sử dụng kéo và thả nó trên vải bên phải.',
        TOOLTIP_MESSAGE_SEND_SMS: 'Send SMS to a group, account filter or contact filter. To use this, drag and drop it on the canvas to the right.',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'Bạn đã có một chiến dịch cảm ứng có tên',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Vui lòng nhập tên khác.',
        INTERVAL_MODE_ADD_MSG_1: 'Hãy cung cấp một',
        INTERVAL_MODE_ADD_MSG_2: 'sau khi giá trị trước đó trừ Zero',
        AUTOMATED_CAMPAIGN_TITLE: 'Chiến dịch tự động',
        CANNOT_CONNECT_MULTIPLE: 'Không thể kết nối nhiều',
        CONNECTION_EXIST: 'Bạn đã có kết nối đang hoạt động từ',
        REMOVE_CONNECTION_ALERT: 'Nếu bạn loại bỏ kết nối bạn sẽ mất tất cả các bước sau',
        CONTINUE_ALERT: 'Bạn có muốn tiếp tục không?',
        CANNOT_ADD: 'Bạn không thể thêm',
        DIRECT_UNDER: 'trực tiếp dưới một',
        DIRECT_UNDER_AN: 'trực tiếp dưới một',
        MULTIPLE_TIME_SELECTED_MSG_1: 'Bạn đã chọn các',
        MULTIPLE_TIME_SELECTED_MSG_2: 'Field (s) nhiều lần để cập nhật. Hãy loại bỏ các hàng trùng lặp.',
        CANNOT_BE_LEAF_NODE: 'không thể là một nút lá.',
        CLICK: 'Nhấp vào',
        HERE: 'Ở đây',
        PURCHASE_MORE: 'để mua nhiều hơn nữa.',
        CREDITS_REMAINING: 'tín dụng email còn lại.',
        STOP_CAMPAIGN: 'Dừng chiến dịch để thực hiện thay đổi.',
        SELECT_EMAIL_TEMPLATE: 'Chọn một mẫu email',
        NO_INFO_EXISTS: 'Không có thông tin tồn tại',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Vui lòng chọn một bản ghi để sao.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Hãy chọn một bản ghi để chỉnh sửa.',
        CANNOT_SAVE_CAMPAIGN: "Không thể lưu chiến dịch này. Xem các khối màu đỏ trong chiến dịch cho các lỗi.",
        ADD_GROUP_CLICK: 'Nhấp vào',
        ADD_GROUP_CLICK_HERE: 'Ở đây',
        CLICK_TO_ADD_NEW_GROUP: 'để thêm một nhóm mới',
        MESSAGE_SAVED_SUCCESSFULLY: 'Bạn đã lưu thành công thư này',
        MESSAGE_NOT_SAVED_SUCCESSFULLY: 'Thư chưa được lưu thành công',
        QUERY_FILTER_BLANK: 'Vui lòng chọn một Truy vấn',
        CONTACT_FILTER_BLANK: 'Hãy chọn một bộ lọc liên hệ.',
        ACCOUNT_FILTER_BLANK: 'Vui lòng chọn bộ lọc tài khoản.',
        SAVE_AUTOMATION_FOR_TEST: 'Vui lòng lưu chiến dịch tự động hóa.',
        BIRTHDAY_CAMPAIGN_NAME: 'Hãy chọn tên cho chiến dịch sinh nhật này',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'Bạn đã có chiến dịch sinh nhật có tên',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Vui lòng nhập tên khác.',
        BIRTHDAY_FIRST_MSG_CANT_BE_DELETED: 'Bạn không thể xoá thư đầu tiên của chiến dịch sinh nhật.',
        MIGRATION: 'Chiến dịch tự động này đang được nâng cấp lên trình xây dựng chiến dịch mới. Cảm ơn vì sự kiên nhẫn của bạn.',
        MIGRATION_DIALOG_TITLE: 'Nâng cấp trong tiến trình ...',
        MIGRATION_NOTE: 'Lưu ý: Sau khi cập nhật, chiến dịch tự động hóa sẽ bắt đầu chạy vào ngày bạn thực hiện thao tác Bắt đầu.',
        BIRTHDAY_CAMPAIGN_NO_REPORT: 'Chiến dịch sinh nhật chưa được gửi đến người nhận.',
        RUNNING_CAMPAIGN_CANNOT_CANCEL: 'Một chiến dịch đang chạy không thể bị hủy bỏ.',
        CONFIRM_CANCEL_SCHEDULE: 'Bạn có muốn hủy chiến dịch theo lịch trình?',
        CAMPAIGN_SCHEDULE_SUCCESSFULLY: 'Chiến dịch này đã được lên lịch thành công.',
        SCHEDULED_CAMPAIGN_CANCEL_SUCCESSFULLY: 'Chiến dịch này đã bị hủy thành công.',
        CAMPAIGN_START_IMMEDIATELY: 'Chiến dịch này đã được thiết lập thành công để bắt đầu ngay lập tức.',
        ENTER_FROM_NAME: 'Vui lòng nhập từ tên.',
        ENTER_FROM_EMAIL: 'Vui lòng nhập từ email.'
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME_BLANK: 'Vui lòng cung cấp tên miền để tạo mã theo dõi.',
        DOMAIN_NAME_INVALID: 'Vui lòng cung cấp một tên miền hợp lệ.',
        TRACKING_CODE_BLANK: 'Không có mã theo dõi để sao chép.',
        TRACKING_CODE_ALREADY_GENERATED: 'Mã theo dõi đã được tạo.',
        DONE: 'Tên miền của bạn đã được đăng ký.',
        SET_UP_INFO: 'Sao chép và dán mã này vào mỗi trang của trang web của bạn ngay trước thẻ cơ thể kết thúc. Sau khi bạn đã làm xong trở lại trang này và nhấp vào nút Done.'

    },
    MAIL: {
        SUCCESS: 'Email của bạn đã được gửi.',
        BLANK_FROM_EMAIL: 'Từ email là trống.',
        INVALID_FROM_EMAIL: 'Bạn có một không hợp lệ từ địa chỉ email trong trường từ.',
        INVALID_CC_EMAIL: 'Bạn có địa chỉ email CC không hợp lệ trong trường CC.',
        INVALID_BCC_EMAIL: 'Bạn có địa chỉ email BCC không hợp lệ trong trường Bcc.',
        BLANK_SUBJECT: 'Chủ đề là trống.',
        BLANK_EMAIL_BODY: 'Email của cơ thể là trống.',
        BATCH_SEND_MAIL_MSG_1: 'Ra khỏi các',
        BATCH_SEND_MAIL_MSG_2: 'bạn chỉ có được chọn',
        BATCH_SEND_MAIL_MSG_3: 'đã chọn tham gia để nhận được email của bạn. Chúng tôi sẽ gửi email chỉ đến những ',
        BATCH_SEND_MAIL_MSG_4: 'Không có những',
        BATCH_SEND_MAIL_MSG_5: 'đã chọn tham gia để nhận email của bạn. Chúng tôi sẽ không gửi email này. Hãy đóng tab này',
        NO_RECIPIENT: 'Bạn chưa nhập bất kỳ người nhận nào cho email này',
        NO_RECIPIENT_FOUND: 'Không có người nhận tìm thấy cho email này.',
        BLANK_TO_EMAIL: 'Không có địa chỉ email trong trường Đến.',
        INVALID_TO_EMAIL: 'Địa chỉ email không hợp lệ trong trường Đến.',
        SCHEDULE_SUCCESS: 'Email của bạn đã được lên lịch thành công.',
        RESCHEDULE_SUCCESS: 'Email đã được dời thành công',
        TEMPLATE_NOT_SELECTED: 'Please select a template',
        NO_ACTIVE_AGREEMENT_TEMPLATE_MSG: 'Bạn không có bất kỳ mẫu thỏa thuận hoạt động nào.',
        SELECT_DIFFERENT_SIGNER: "Please select a different signer.",
        SELECT_ALL_SIGNERS: 'Please select all the signer(s) to send the agreement email.',
    },
    APPOINTMENTS_TASKS: {
        DELETE: 'Bản ghi này sẽ bị xóa bỏ. Bạn có chắc không?',
        INVALID_START_END_DATE: 'Ngày bắt đầu không thể lớn hơn hoặc tương tự như ngày kết thúc.',
        RECURR_DELETE_DIALOG_ONLY_BTN_INFO: 'Tất cả các hồ sơ khác trong chuỗi sẽ vẫn còn.',
        RECURR_DELETE_DIALOG_FOLLOWING_BTN_INFO: 'Điều này và tất cả các hồ sơ sau đây sẽ bị xóa.',
        RECURR_DELETE_DIALOG_ALL_BTN_INFO: 'Tất cả các bản ghi trong chuỗi sẽ bị xóa.',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_1: 'Tất cả các',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_2: 'trong chuỗi sẽ vẫn như nhau.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_1: 'Điều này và tất cả sau đây',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_2: 'sẽ được thay đổi.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_3: 'Mọi thay đổi đối với tương lai',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_4: 'sẽ bị mất.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_1: 'Tất cả',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_2: 'trong chuỗi sẽ được thay đổi.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_3: 'Bất kỳ thay đổi nào được thực hiện',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_4: 'sẽ được giữ.',
        RESCHEDULE_TASK_SUCCESS: 'Nhiệm vụ đã được dời lại thành công ',
        RESCHEDULE_APPOINTMENT_SUCCESS: 'Cuộc hẹn đã được dời lại thành công ',
        NO_TASK_FOUND: 'Không có tác vụ được tìm thấy ',
        NO_APPOINTMENT_FOR_TODAY: 'Đó là không có cuộc hẹn theo lịch trình có sẵn ngày hôm nay',
        NO_PENDING_TASK_FOR_TODAY: 'Không có công việc chờ giải quyết hiện nay',
        NO_TASK_APPOINTMENT_FOUND: 'Không có gì đang chờ.',
        NO_TASK_APPOINTMENT_FOUND_FOR_COMPLETED: 'Không có gì hoàn thành.',
        BLANK_START_END_DATE: 'Ngày bắt đầu và ngày kết thúc không thể để trống',
        BLANK_START_DATE: 'Ngày bắt đầu không thể trống',
        BLANK_END_DATE: 'Ngày kết thúc không thể để trống',
        BLANK_NO_OF_DAYS: "Không. ngày không thể trống.",
        ZERO_NO_OF_DAYS: "Số ngày không thể là 0.",
        BLANK_SPECIFIC_DATE: "Ngày không thể trống.",
        INVALID_DUEBY_END_DATE: 'Do ngày không thể lớn hơn hoặc tương tự như ngày kết thúc lặp lại.',
        SEND_EMAIL_PROGRESS_MSG: 'Gửi email đang được tiến hành, nó sẽ mất một lúc.',
        INVALID_DUEBY_START_DATE: 'Đến hạn theo ngày không thể lớn hơn hoặc giống như ngày bắt đầu.',
        SYNC_STARTED_MSG: 'Đồng bộ bắt đầu. Vui lòng làm mới lịch để xem các cuộc hẹn mới.',
        SYNC_STARTED_MSG_FOR_SETUP: 'Đồng bộ bắt đầu. Lịch của bạn sẽ sớm được cập nhật.',
        ENABLE_SYNC_CAL: 'Vui lòng bật tùy chọn đồng bộ hóa lịch trong thiết lập tích hợp trước khi đồng bộ hóa.',
        PRIVATE_APPOINTMENT_ALERT:"Đây là một cuộc hẹn riêng tư; bạn không thể mở cái này.",
        START_END_DATE_VALIDATE:'không thể lớn hơn hoặc bằng',
        CONVERT_CONFIRMATION_MESSAGE: 'Một hoặc nhiều nhiệm vụ không được kiểm tra. Bạn có muốn tiếp tục thao tác Chuyển đổi mà không cần kiểm tra các nhiệm vụ đó không?'
    },
    TASKS:{
        DELETE_CANCELLED_STATAUS_RESTRICT_MSG:'Bạn không được phép hủy bất kỳ tác vụ hành động khắc phục nào.',
    },
    HOME: {
        FILTER_ADD: 'Bộ lọc được thêm thành công',
        FILTER_NOT_FOUND: 'Bạn chưa cấu hình bộ lọc nào',
        FILTER_DELETE_RECORD: 'Bộ lọc sẽ bị xóa. Bạn có chắc không?',
        FILTER_DELETE: 'Bộ lọc đã loại bỏ thành công',
        FILTER_ON_MAX_FIELD: 'Bạn đã đạt đến điều kiện trường tối đa. Chúng tôi cho phép tối đa 10 trường.',
        ASSIGNMENT_RULES_ON_MAX_FIELD: 'Bạn đã đạt đến điều kiện trường tối đa. Chúng tôi cho phép tối đa 20 trường.',
        // not found in doc
        FILTER_NAME_BLANK: 'Vui lòng cung cấp tên bộ lọc',
        FILTER_NAME_MAX_LENGTH: 'Tên bộ lọc không được nhiều hơn 64 ký tự.',
        FILTER_CRITERIA_EMPTY: 'Vui lòng cung cấp ít nhất một tiêu chí.',
        FILTER_SQL_ERROR_NO_VALUE: ' không thể chừa trống.',
        FILTER_SQL_ERROR_INTEGER: ' không phải là số nguyên',
        FILTER_SQL_ERROR_FLOAT: ' không phải là một phao.  ',
        FILTER_SQL_ERROE_ALREADY_EXISTS: 'Tên bộ lọc đã tồn tại!',
        FILTER_DELETE_MSG_TC: '(Các) Chiến dịch Chạm sau đây ',
        FILTER_DELETE_MSG_BC: ' (Các) Chiến dịch Quảng bá sau ',
        FILTER_DELETE_MSG_AB: '(Các) Chiến dịch A / B sau',
        FILTER_DELETE_MSG_AND: ' và ',
        FILTER_DELETE_MSG_BEING_USED: ' không thể xóa vì nó đang được sử dụng trong ',
        ADD_GROUP_SUCCESS: 'Nhóm được thêm thành công.'
    },
    DOCUMENT_LIBRARY: {
        INVALID_UPLOAD_ERROR: 'Chọn một tập tin hình ảnh!',
        CONFIRM_DELETE_MSG: 'Bạn có chắc bạn muốn xóa bỏ ảnh này không?',
        OVER_MAX_SIZE: 'Tệp bạn đã chọn quá lớn để sử dụng. Kích thước tệp tối đa cho phép là 50 MB.',
        OVER_MAX_SIZE_IMAGE: "Các tập tin bạn đã chọn là quá lớn để sử dụng. Kích thước tệp tối đa được phép là 500 KB",
        INVALID_DOCUMENT_UPLOAD_ERROR: 'exe bat sh tài liệu không được phép tải lên!',
        DOCUMENT_FILE_EMPTY_ERROR: 'Xin vui lòng chọn một tài liệu!',
        ASSETS_ALREADY_OPEN_ALERT: 'Bạn đã có tab Thư viện mở trong mô-đun hoạt động.',
        DUPLICATE_IMAGE_NAME: 'Hình ảnh đã tồn tại với tên này. Hãy chọn một hình ảnh khác!',
        DUPLICATE_DOCUMENT_NAME: 'Tài liệu đã tồn tại với tên này. Hãy chọn một tài liệu khác!',
        UPLOAD_IMAGE: 'Chọn hình ảnh để tải lên (tối đa năm tệp cùng một lúc).',
        ASSETS_ALREADY_OPEN_ALERT_HUB: 'Bạn đã mở tab Thư viện bên dưới mô-đun Hub.',
        BLANK_TITLE_ERROR : 'Tiêu đề không được để trống',
        BLANK_URL_ERROR : 'Liên kết không được để trống',
        INVALID_URL_ERROR : 'Vui lòng cung cấp URL hợp lệ',
        CONFIRM_FILE_TYPE_CHANGE: {
            LN1: 'Bạn có muốn thoát khỏi phần hiện tại không? Mọi thay đổi chưa được lưu sẽ bị mất.',
            LN2: 'Nhấp vào Ok để xác nhận và thoát.',
            LN3: 'Nhấp Hủy để ở lại phần hiện tại.',
        },
    },
    MERGE: {
        NOT_URL: "là một URL không hợp lệ.",
        NOT_FLOAT: "không phải là một phao.",
        NOT_INTEGER: "không phải là số nguyên.",
        NOT_EMAIL: "là một địa chỉ thư điện tử không hợp lệ.",
        MERGE_TENANT_EMAIL_ALERT_MESSAGE: 'Bạn không thể hợp nhất những hồ sơ này. Như bạn đã chọn một tài khoản trả phí để xóa!',
    },
    SCHEDULE: {
        EMPTY: 'Vui lòng chọn ngày lập lịch',
        CAMPAIGN_SCHEDULE: 'Chiến dịch này sẽ chạy trên',
        CONFIRM: 'Nhấp Được để xác nhận.',
        SMS_SCHEDULE: 'Tin nhắn văn bản này sẽ gửi'
    },
    RELATIONSHIP: {
        BLANK_RECORD: "Vui lòng chọn mối quan hệ",
        BLANK_PARENT_RELATION: "Hãy chọn ít nhất một mối quan hệ cha mẹ.",
        BLANK_CHILD_RELATION: "Hãy chọn ít nhất một quan hệ con",
        NO_PARENT_RELATION: "Bạn không có bất kỳ mối quan hệ. Hãy tạo mối quan hệ theo tuỳ chỉnh từ tất cả các cài đặt",
        NO_PARENT_RELATION_FOR_USER: "Xin lỗi là không có liên quan. Xin vui lòng liên hệ với người quản trị ",
        NO_RELATIONSHIP_SETUP: "Bạn chưa thiết lập bất kỳ loại mối quan hệ nào",
        PLACEHOLDERTEXT_COMPANY: "nhập tên công ty và nhấn enter",
        PLACEHOLDERTEXT_NAME: "Nhập tên đầy đủ và nhấn enter",
        PLACEHOLDERTEXT_EMAIL: "Nhập email và nhấn enter",
    },
    GROUP: {
        NO_TOUCH_CAMPAIGN: "Không được sử dụng trong bất kỳ chiến dịch cảm ứng.",
        NO_BROADCAST_CAMPAIGN: "Không được sử dụng trong bất kỳ chiến dịch phát sóng.",
        NO_AB_CAMPAIGN: "Không được sử dụng trong bất kỳ chiến dịch A/B.",
        NO_WEBFORM: "Không được sử dụng trong bất kỳ hình thức web.",
        NO_LANDINGPAGE: "Không được sử dụng trong bất kỳ trang đích.",
        DELETE_MSG: '(các) nhóm sau không thể xóa',
        USED_IN_TOUCH_CAMPAIGN: ' được sử dụng trong các chiến dịch liên lạc sau đây',
        USED_IN_BROADCAST_CAMPAIGN: ' được sử dụng trong các chiến dịch phát sóng sau đây',
        USED_IN_AB_CAMPAIGN: ' được sử dụng trong chiến dịch sau A/B (s)',
        USED_IN_WEBFORM: ' được sử dụng trong các mẫu web sau đây',
        USED_IN_LANDINGPAGE: ' được sử dụng trong trang đích sau đây',
        SELECT_GROUP: 'Chọn một hoặc nhiều nhóm',
        NO_GROUP_AVAILABLE: 'Không có nhóm có sẵn. Nhấp vào',
        SELECT_RECORDS: 'Hồ sơ đã chọn',
        ALL_RECORDS: 'Tất cả hồ sơ',
        SELECT_OPTIONS: 'Chọn tùy chọn',
        ADD_NEW_GROUP: 'để thêm một nhóm mới',
        GROUP_DELETE_RECORD: 'Nhóm sẽ bị xóa. Bạn có chắc không?',
        COMMON_DELETE_MSG:" không thể xóa vì nó đang được sử dụng trong (các) chiến dịch sau:",
    },
    LOGIN: {
        VALIDATE_EMPTY_USER_NAME: 'Xin vui lòng nhập email và mật khẩu của bạn',
        VALIDATE_EMPTY_PASSWORD: 'Vui lòng nhập mật khẩu của bạn',
        VALIDATE_USERNAME_PASSWORD: 'Bạn đã nhập một email hoặc mật khẩu không đúng',
        INVALID_RESPONSE: 'Phản hồi không hợp lệ',
        LOGIN_COPYRIGHTS_TEXT_1: 'Bản quyền ',
        LOGIN_COPYRIGHTS_TEXT_2: 'Soffront software Inc. Tất cả các quyền.',
        BEST_VIEWED: 'Xem tốt nhất trong Google Chrome Mozilla Firefox và Safari.'
    },
    AUDIT_LEADSCORE: {
        NO_AUDIT_DATA: 'Không có đường mòn kiểm toán hồ sơ tìm thấy',
        NO_SCORE_HISTORY: 'Không có lịch sử để hiển thị.'
    },
    DETAIL_VIEW: {
        CHANGE_PRIMARY_CONTACT_ALERT_1: 'Bạn đã chọn',
        CHANGE_PRIMARY_CONTACT_ALERT_2: 'như số liên lạc chính cho',
        CLICK_TO_CONFIRM: 'Nhấp Được để xác nhận.',
        MAX_SELECTED_FIELD_LIMIT: 'Bạn đạt tới giới hạn tối đa 50 trường đã chọn',
        SELECT_ATLEAST_ONE_FIELD: 'Hãy chọn ít nhất một trường ',
        SELECT_ATLEAST_ONE_CHILD_OBJECT: 'Hãy chọn ít nhất một đối tượng con.',
        MAX_SELECTED_CHILD_OBJECT_LIMIT: 'Bạn đạt đến giới hạn tối đa 10 đối tượng được lựa chọn.',
        NO_ASSOCIATED_GROUPS_FOUND: 'Không có nhóm được liên kết nào',
        CONFIRM_UNSUBSCRIBE_RECORD: 'Bản ghi sẽ bị hủy đăng ký. Bạn có chắc không?',
        BLANK_SPECIFIC_DATE: 'Ngày không thể để trống.',
        CONFIGURE_CHILD_SETUP: 'Vui lòng cấu hình thiết lập con.',
        CHILD_SETUP_SELECT_ATLEAST_ONE_FIELD: 'Vui lòng chọn ít nhất một trường để thiết lập con.',
        EMAIL_FORMAT_MSG: "- Định dạng email không hợp lệ.",
        URL_FORMAT_MSG: "- Định dạng URL không hợp lệ.",
        FLOAT_FORMAT_MSG: "- Định dạng Float không hợp lệ.",
        INTEGER_FORMAT_MSG: "- Định dạng số nguyên không hợp lệ.",
        ERROR_MSG: "Bạn có muốn rời đi mà không lưu những thay đổi?",
    },
    CHARTS: {
        NOTHING_TO_SHOW: 'Không có gì để hiển thị cho khung thời gian này'
    },
    ADVANCE_SEARCH: {
        SEARCH_FIELDS_NOT_FOUND: 'Không có gì để tìm kiếm. Vui lòng nhập giá trị.',
        MAX_ADD_FIELD_ALERT:'Bạn không thể tìm kiếm với hơn 10 trường',
        MIN_ADD_FIELD_ALERT:'Ít nhất một trường được yêu cầu để tìm kiếm.',
        SELECT_FIELD_ALERT:'Vui lòng chọn trường trước khi nhập giá trị.',
        ADVANCE_SEARCH: 'Tìm kiếm nâng cao',
        EMPTY_DATE: 'Vui lòng nhập ngày.',
        INVALID_DATE_RANGE: 'Từ ngày không được lớn hơn Ngày tháng',
        EMPTY_FROM_TO_DATE: 'Vui lòng nhập Từ ngày và Đến ngày.',
        EMPTY_FROM_DATE: 'Vui lòng nhập Từ ngày.',
        EMPTY_TO_DATE: 'Vui lòng nhập Đến ngày.'
    },
    PUSH: {
        PUSH_SUCCESS_MSG_1: 'Bạn đã đẩy thành công',
        PUSH_SUCCESS_MSG_2: 'cho các công ty được chọn.',
        PUSH_SUCCESS_MSG_2_1: 'sẽ được đẩy',
        PUSH_SUCCESS_MSG_3: 'Một thông báo email đã được gửi đến các công ty được lựa chọn.',
        PUSH_SUCCESS_MSG_4: 'Bạn đã chọn nhiều hơn 20 bản ghi. Bạn có thể đẩy tối đa 20 tài khoản tại một thời điểm.',
        PUSH_SUCCESS_MSG_4_1: 'tại một thời điểm.',
        PUSH_SUCCESS_MSG_5: 'Vui lòng chọn một công ty để đẩy',
        PUSH_SUCCESS_MSG_6: 'Không có không có kết nối khách hàng có sẵn để đẩy tài khoản.',
        PUSH_SUCCESS_MSG_7: 'Đẩy thành công.',
        PUSH_ALERT_MSG_8: 'Vui lòng chọn ít nhất một công ty để đẩy Email mẫu ',
        PUSH_ALERT_MSG_9: 'Hãy chọn ít nhất một công ty để đẩy các chiến dịch tự động hóa',
        PUSH_ALERT_MSG_10: 'Vui lòng chọn ít nhất một Công ty để đẩy Chiến dịch quảng bá',
        PUSH_SUCCESS_MSG_11: 'đến công ty được chọn.',
        PUSH_SUCCESS_MSG_11_2: 'Bạn có chắc không?',
        PUSH_SUCCESS_MSG_12: 'Một thông báo email đã được gửi đến công ty được chọn.',
        PUSH_SUCCESS_MSG_13: 'Vui lòng chọn ít nhất một Đơn vị để đẩy Khách hàng tiềm năng',
        PUSH_SUCCESS_MSG_14: 'Vui lòng chọn ít nhất một Đơn vị để đẩy quy trình công việc',
        PUSH_FOLDER: 'Thư mục',
        FAIL_USE_ASSIGNMENT_RULE_FOR_SALES_LEADS: 'Không có quy tắc chuyển nhượng nào được thỏa mãn để thúc đẩy doanh số bán hàng',
        PUSH_ALERT_MSG_12: 'Vui lòng chọn ít nhất một Đơn vị để đẩy Mẫu thỏa thuận.',
        LANDING_PAGES: 'trang đích',
    },
    PROFILE_DETAILS: {
        UNMATCH_NEW_CONFIRM_PASSWORD: 'Xác nhận mật khẩu không khớp với mật khẩu mới.',
        NO_NEW_PASSWORD: 'Nhập mật khẩu hợp lệ và xác nhận nó.',
        NO_CONFIRM_PASSWORD: 'Xác nhận mật khẩu mới.',
        VALIDATE_PROFILE_IMAGE: 'Chọn hình ảnh',
        PROFILE_IMAGE_UPLOAD: 'Hình ảnh đã được vào hồ sơ thành công',
        PROFILE_IMAGE_UPLOAD_FAILURE: 'Hình ảnh không thể lưu được vào hồ sơ',
        PROFILE_UPDATE: 'Hồ sơ được cập nhật thành công',
        VALIDATE_PROFILE_IMAGE_SIZE: 'Ảnh bạn chọn quá lớn để dùng. Kích cỡ tệp tối đa được phép là 500 kB.',
        PROFILE_IMAGE_ATTACHMENT_LOGO_TEXT: 'Bạn có thể tải lên một jpg gif hoặc PNG file.',
        PROFILE_IMAGE_SIZE_TEXT: ' Kích cỡ tệp tối đa được phép là 500 kB.',
        PROFILE_SIGNATURE_AUTOSAVED: 'Chữ ký được lưu thành công.'
    },
    CUSTOMIZATION: {
        LOCATION_VAL_NOT_MATCH: 'Giá trị vị trí không khớp.',
        LOCATION_VAL_BLANK: 'Không có giá trị nào sẵn có trong trường vị trí.'
    },
    IMPORT: {
        SELECT_FILE: 'Vui lòng chọn một tập tin',
        SELECT_GROUP: 'Vui lòng chọn một nhóm.',
        SELECT_WORKFLOW: 'Hãy chọn một luồng công việc',
        SELECT_STATUS: 'Vui lòng chọn tình trạng',
        SELECT_SOURCE: 'Vui lòng chọn nguồn',
        SOURCR_NAME_EXISTS: 'Giá trị đã tồn tại trong danh sách',
        SOURCR_NAME_BLANK: 'Tên nguồn không thể trống!',
        IMPORT_FILE_EXT: 'Hãy chọn tệp có phần mở rộng. CSV hoặc. xls/xlsx',
        NO_RECORD_TO_IMPORT: 'Không có hồ sơ để nhập khẩu!',
        NO_MAPPING: 'Có là không có lập bản đồ cho  ',
        NOT_ALL_MAPPED: 'Một hoặc nhiều cột không được ánh xạ để CRM. Bạn có muốn tiếp tục không có nhập các cột không?',
        IMPORT_CONFIRM_MSG: 'phần thâu sẽ được nhập vào',
        IMPORT_CONFIRM_MSG_1: 'hồ sơ sẽ được nhập vào',
        CONFIRM: 'Xin vui lòng xác nhận.',
        SELECT_LINK_USING: 'Hãy chọn một tùy chọn cho liên kết bằng cách sử dụng',
        SELECT_LINK_WITH: 'Hãy chọn một tùy chọn cho liên kết với',
        SELECT_DUPLICATE_FIELD: 'Hãy chọn ít nhất một tùy chọn cho trường trùng lặp',
        SOURCE_ALERT_MSG_1: 'Xin vui lòng bản đồ tài khoản "nguồn" trường vào một cột có chứa các nguồn tiếp thị của bạn.',
        SOURCE_ALERT_MSG_2: 'Bạn đã chọn nguồn từ danh sách thả xuống cũng như từ phần lập bản đồ. Bạn có thể chọn giá trị trường nguồn ở một nơi không ở hai nơi.',
        NO_MAPPING_AVAILABLE: 'Có sẵn bản đồ không có. ',
        BLANK_MAP_NAME: 'Xin vui lòng cung cấp một tên bản đồ!',
        VALID_MAP_NAME_LENGTH: 'Tên bản đồ không nên lớn hơn 32 ký tự!',
        DUPLICATE_MAP_NAME: 'Tên bản đồ đã tồn tại!!',
        MAP_SAVE_SUCCESS: 'Lập bản đồ lĩnh vực của bạn đã được lưu thành công!',
        NO_PREVIOUS_MAPPING: 'Không có Bản đồ trước nào!',
        MAP_NAME: 'Bản đồ tên',
        DELETE_MAP_ALERT: 'Bạn có chắc chắn muốn xóa bỏ',
        DELETE_MAP_SUCCESS: 'Lập bản đồ đã xóa thành công!',
        IMPORT_SUCCESS: 'Chuyển nhập thành công.',
        WARNING: 'Cảnh báo!',
        WARNING_TEXT: 'Vui lòng không đóng trang này cho đến khi việc nhập hoàn tất.',
        IMPORTING_RECORDS: 'Chuyển nhập các bản ghi của bạn',
        SUCCESS_IMPORT_COUNT: 'Record(s) đã được nhập khẩu ',
        FAIL_IMPORT_COUNT: 'Record(s) thất bại trong việc nhập khẩu ',
        FOUND: 'Chúng tôi tìm thấy',
        DUPLICATES: 'Bị trùng',
        APPLY_WORKFLOW_TEXT: 'vào các bản ghi đã nhập',
        APPLYING_WORKFLOW: 'Áp dụng qui trình làm việc',
        APPLIED_WORKFLOW: 'Hoàn thành quy trình làm việc',
        APPLIED_WORKFLOW_TO: 'Áp dụng qui trình làm việc cho',
        MAX_IMPORT_RECORDS_MSG_1: 'Có',
        MAX_IMPORT_RECORDS_MSG_2: 'Hồ sơ trong tập tin. Hiện nay bạn có thể nhập 10000 hồ sơ tại một thời điểm!',

        // not found in doc
        VERIFY_CONTACTS_MSG_1: 'Vui lòng xác minh rằng các địa chỉ liên hệ này đã cung cấp cho bạn',
        VERIFY_CONTACTS_MSG_2: 'cho phép gửi email cho họ. Chúng tôi không cho phép thuê hoặc',
        VERIFY_CONTACTS_MSG_3: 'danh sách đã mua. Những liên hệ này phải được yêu cầu',
        VERIFY_CONTACTS_MSG_4: 'để nhận email từ bạn hoặc có mối quan hệ chặt chẽ với ',
        VERIFY_CONTACTS_MSG_5: 'tổ chức của bạn.',

        FILE_TYPE_MSG: 'Hàng đầu tiên của tệp CSV hoặc XLS / XLSX phải chứa tên trường.',
        DATE_FORMAT_MSG: 'Ngày phải ở định dạng MM / DD / YYYY ',
        ACCOUNTS_AND_CONTACTS: 'Tài khoản và Danh bạ',
        IMPORT_CSV_FILE: 'Vui lòng chọn tệp có phần mở rộng .csv.',
        RECORDS_IMPORT_SUCCESS: 'Hồ sơ đã được chuyển nhập thành công.',
        GREEN_LIGHT_STATUS_ERROR: 'Đã có một lần nhập đang diễn ra, do đó bạn chỉ có thể bắt đầu một lần nhập mới sau khi hoàn tất quá trình nhập hiện tại.',
        GREEN_LIGHT_STATUS_SUCCESS: 'Quá trình nhập hiện tại của bạn đang diễn ra. Chúng tôi sẽ gửi cho bạn một email xác nhận đến email đã đăng ký của các bên nhận quyền liên quan sau khi hoàn tất.',
    },
    YOUTUBE_VIDEO: {
        EMPTY_VIDEO_LINK: 'Hãy dán URL của video cho YouTube',
        INVALID_VIDEO_LINK: 'URL bạn nhập vào không hợp lệ',
        INSERT_TITLE: 'Chèn video từ YouTube',
        VIDEO_LINK_PLACEHOLDER: 'Dán URL video từ YouTube'
    },
    WISTIA_VIDEO: {
        EMPTY_VIDEO_LINK: 'Hãy dán một URL của video cho wistia',
        INVALID_VIDEO_LINK: 'URL bạn nhập vào không hợp lệ',
        INSERT_TITLE: 'Chèn video từ wistia',
        VIDEO_LINK_PLACEHOLDER: 'Dán một URL video từ wistia'
    },
    INXPRESS_REPORT: {
        VALIDATE_EMPTY_FROM_DATE: 'Vui lòng chọn từ ngày.',
        VALIDATE_EMPTY_TO_DATE: 'Vui lòng chọn ngày.',
        VALIDATE_EMPTY_COUNTRY: 'Vui lòng chọn quốc gia.',
        VALIDATE_EMPTY_FRANCHISE: 'Vui lòng chọn Nhượng quyền thương mại.',
        VALIDATE_EMPTY_WORKFLOW: 'Vui lòng chọn qui trình làm việc.',
        VALIDATE_EMPTY_SALES_REP: 'Vui lòng chọn Đại diện bán hàng.',
        VALIDATE_EMPTY_OBJECT_NAME: 'Vui lòng chọn Báo cáo trên.',
        NO_RECORD_EXPORT: 'Không có bản ghi nào để xuất.',
        REPORT_NOT_READY_FOR_EXPORT: 'Vui lòng chờ trong khi báo cáo đang tải, bạn không thể xuất ngay bây giờ.',
        BLANK_FROM_TO_DATE: 'Ngày không được để trống',
        VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16: 'Vui lòng chọn một quốc gia để tiếp tục.',
        VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT: 'Vui lòng chọn một quy trình làm việc để tiếp tục.',
        VALIDATE_EMPTY_REGION_INXPRESS_REPORT: 'Vui lòng chọn một khu vực để tiếp tục.',
        VALIDATE_EMPTY_DATE_RANGE: 'Vui lòng chọn một phạm vi ngày để tiếp tục.',
        VALIDATE_EMPTY_WORKFLOW_STAGES: 'Vui lòng chọn một giai đoạn quy trình công việc để tiếp tục.',
        VALIDATE_EMPTY_CHART_TYPE: 'Vui lòng chọn loại biểu đồ để tiếp tục.',
        VALIDATE_EMPTY_SHOW_TYPE: 'Vui lòng chọn một loại chương trình để tiếp tục.',
        VALIDATE_EMPTY_ACTIVITY_TYPE: 'Vui lòng chọn một loại hoạt động để tiếp tục.',
    },

    OTHER_VIEW: {
        TENANT_MESSAGE: 'Đăng ký của bạn không bao gồm $ $ @ @ # #PRODUCT_NAME # # @ @ $ $',
        USER_MESSAGE: 'Vui lòng liên hệ với người quản trị của bạn để thêm $ $ @ @ # #PRODUCT_NAME # # @ @ $ $ để thuê bao của bạn',
        AFTER_SUBSCRIPTION_VENDASTA_NOT_CONFIGURED: 'Chúng tôi đã không thiết lập tài khoản của bạn cho $ $ @ @ # #PRODUCT_NAME # # @ @ $ $ được nêu ra. Chúng tôi sẽ thông báo cho bạn ngay khi nó có sẵn.'
    },

    MY_INTEGRATION: {
        SAVE_SUCCESS: 'Cài đặt của bạn đã được lưu thành công.',
        APPEND_INFO: 'Thêm công ty và thông tin liên hệ vào các cuộc hẹn được thêm từ Soffront',
        DISCONNECT_CONNECTION: 'Kết nối sẽ bị xóa. Bạn có chắc không?',
        SAVE_FAIL: 'Cài đặt của bạn chưa được lưu.',
        NO_CALENDAR: 'Lịch không được chọn.',
        NO_SYNC_CHECKED: 'Bạn chưa chọn bất kỳ tùy chọn đồng bộ hóa nào. Bạn có thể chọn đồng bộ hóa email, lịch hoặc cả hai.',
        SYNC_EMAIL_INFO_1: ' Chọn tùy chọn này để tự động lưu các email từ hộp thư G Suite / Office 365 của bạn vào CRM.',
        SYNC_EMAIL_INFO_2: 'Các email sẽ được lưu vào một tài khoản phù hợp hoặc hồ sơ liên lạc.',
        SYNC_EMAIL_INFO_3: 'Các email sẽ được đồng bộ sau mỗi 5 phút.',
        SYNC_CONTACT_INFO_1: 'Chọn tùy chọn này để tự động thêm liên hệ CRM của bạn vào G Suite / Office 365.',
        SYNC_CONTACT_INFO_2: ' Mọi thay đổi bạn thực hiện cho các liên hệ trong CRM sẽ được tự động cập nhật trong G Suite / Office 365.',
        SYNC_CONTACT_INFO_3: ' Các số liên lạc sẽ được đồng bộ sau mỗi 5 phút.',
        SYNC_CALENDAR_INFO_1: ' Chọn tùy chọn này để tự động đồng bộ hóa các cuộc hẹn giữa lịch Soffront của bạn với lịch từ tài khoản G Suite / Office 365 của bạn.',
        SYNC_CALENDAR_INFO_2: 'Nếu bạn có nhiều lịch G Suite / Office 365, bạn sẽ cần phải chọn lịch mà bạn muốn đồng bộ hóa.',
        SYNC_CALENDAR_INFO_3: ' Các lịch sẽ được đồng bộ sau mỗi 5 phút.',
        NO_CALENDAR_MESSAGE: 'Chúng tôi đang chờ đợi để có được thông tin chi tiết về lịch của bạn. Vui lòng đóng màn hình này và quay lại sau vài phút. Nếu bạn tiếp tục thấy thông báo này, vui lòng ngắt kết nối và kết nối lại tài khoản của bạn.',
        NO_PERMISSION: 'Tích hợp của tôi không có sẵn trong gói hiện tại của bạn. Vui lòng đăng ký gói thích hợp hoặc liên hệ với bộ phận hỗ trợ tại smbsupport@soffront.com.',
        SELECTED_CALENDAR_NOT_FOUND: ' Chúng tôi không thể tìm thấy lịch đã chọn trước đó của bạn.Vui lòng chọn một lịch khác và lưu.',
        INVALID_ACCOUNT_SYNC_STATE1:'Của bạn',
        INVALID_ACCOUNT_SYNC_STATE2:'kết nối không còn hợp lệ. Vui lòng ngắt kết nối và kết nối lại một lần nữa.',
        SEND_SALES_EMAILS_VALIDATION1: 'Để có thể gửi email bằng',
        SEND_SALES_EMAILS_VALIDATION2: 'bạn sẽ cần kết nối bằng cách sử dụng',
        SEND_SALES_EMAILS_VALIDATION3: 'Vui lòng ngắt kết nối và kết nối lại bằng',    
    },

    DOCUSIGN: {
        PROVIDE_USERNAME: 'Vui lòng cung cấp tên người dùng hợp lệ.',
        PROVIDE_PASSWORD: 'Vui lòng cung cấp mật khẩu hợp lệ.',
        PROVIDE_INTEGRATOR_KEY: 'Vui lòng cung cấp khóa tích hợp hợp lệ.',
        SUCCESSFULLY_SAVED: 'Bạn đã cấu hình thành công tài khoản DocuSign của mình.',
        FAILED_TO_SAVED: 'Không thể định cấu hình tài khoản DocuSign của bạn.',
        DOCUSIGN_SETUP_TITLE_1: 'Chọn một tài liệu và gửi nó ',
        DOCUSIGN_SETUP_TITLE_2: 'đến ',
        DOCUSIGN_SETUP_TITLE_3: ' cho chữ ký',
        PROVIDE_RECIPIENT_NAME: 'Vui lòng cung cấp tên đầy đủ của người nhận',
        PROVIDE_RECIPIENT_EMAIL: 'Vui lòng cung cấp email người nhận',
        PLEASE_SELECT_TEMPLATE: 'Vui lòng chọn mẫu.',
        NO_TEMPLATES_FOUND: 'Không tìm thấy mẫu nào.',
        UNABLE_TO_SEND: "Không thể gửi mẫu vì tài khoản DocuSign của bạn đã hết hạn.",
        TEMPLATE_NAME_LENTH: 'Tên mẫu không được vượt quá 65 ký tự.',
        PLEASE_SELECT_CONTACT: 'Vui lòng chọn ít nhất một liên hệ.',
        DOCUSIGN_CONTACT_MESSAGE_1: 'Không có email nào cho các liên hệ sau: ',
        DOCUSIGN_CONTACT_MESSAGE_2: 'Email sẽ không được gửi đến (các) liên hệ này.',
        DOCUSIGN_CONNECTED_MESSAGE_1: 'Xin lỗi, bạn không có sự cho phép để thực hiện thao tác này. Vui lòng liên hệ soffront.',
    },

    TWILIO: {
        CALL_OVER_SECURED_NETWORK: 'Bạn chỉ có thể thực hiện cuộc gọi qua mạng được bảo mật',
        SAME_TO_AND_FROM_NUMBER: 'Để số và từ số là như nhau. Không thể bắt đầu cuộc gọi.',
        CALLING_SUPPORT: 'Gọi điện không được hỗ trợ ở quốc gia của bạn hiện tại.',
        OUTBOUND_PHONE_NOT_VERIFIED: 'Số điện thoại đi của bạn không được xác minh. Vui lòng xác minh nó trước khi thực hiện cuộc gọi.',
        CALL_RECORDING_SAVE: 'Đã lưu thành công. Vui lòng đăng xuất và đăng nhập lại để xem thay đổi.',
        CALL_RECORDING_LAWS_AND_REGULATION_ALERT:'Hãy đảm bảo rằng các phương pháp ghi âm cuộc gọi của bạn tuân thủ các luật và quy định hiện hành.',
        NOTE_SAVE: 'Đã lưu thành công ghi chú',
        PHONE_LOW_BALANCE1: 'Số dư trên điện thoại của bạn hiện là ',
        PHONE_LOW_BALANCE2: ' phút.',
        PHONE_LOW_BALANCE3: 'Bạn cần cân bằng hơn 5 phút để thực hiện cuộc gọi.',

        PHONE_BLANK_MESSAGE: 'Vui lòng nhập số điện thoại.',
        PHONE_ALREADY_CONFIGURE: 'Số điện thoại này đã được cấu hình.',
        DELETE_PHONE_NUMBER: 'Bạn có chắc chắn xóa bỏ con số này không?',
        VERIFY_PHONE_NUMBER: 'Vui lòng kiểm chứng trước khi thêm một số khác.',
        PHONE_LIMIT: 'Bạn không thể thêm nhiều hơn 3 số điện thoại.',
        VERIFICATION_CODE: 'Tại dấu nhắc, vui lòng nhập mã xác minh.',
        VERIFICATION_MESSAGE_1: 'Chúng tôi sẽ gọi',
        VERIFICATION_MESSAGE_2: 'để xác minh số điện thoại.',
        FIELDS_ADD_LIMIT:'Bạn không thể thêm nhiều hơn 3 trường.',
        SETUP_UPDATED_SUCCESSFULLY:'Cập nhật thành công.',
        SETUP_UPDATED_FIELDS:'Cập nhật không thành công.'
    },
    CALL_SCRIPT: {
        BLANK_NAME: 'Vui lòng nhập tên cho tập lệnh này.',
        BLANK_DETAILS: 'Vui lòng nhập chi tiết tập lệnh.',
        SAVE_SUCCESS: 'Bạn đã lưu thành công tập lệnh này.',
        UPDATE: 'Bạn đã cập nhật thành công tập lệnh này.',
        DELETE: 'Bạn đã xóa thành công tập lệnh này.',
        NO_RECORD: 'Không có bản ghi nào',
        'DUPLICATE_SCRIPT_NAME': 'Tên tập lệnh này đã tồn tại. Không cho phép tên tập lệnh trùng lặp.',
        'EMPTY_SCRIPT_NAME_DETAILS': 'Tên tập lệnh và chi tiết tập lệnh không được để trống.',
        'RECORD_NOT_FOUND_UPDATE': 'Không tìm thấy bản ghi để cập nhật.',
        DELETE_CONFIRMATION: 'Bạn có chắc chắn muốn xóa tập lệnh này không?'
    },
    SMS_INTEGRATION: {
        PURCHASED_NUMBER: 'Bạn đã mua thành công số',
        SELECT_USER: 'Vui lòng chọn ít nhất một đại diện bán hàng để tiếp tục.',
        ENTER_ZIP_OR_AREA: 'Vui lòng nhập mã zip hoặc mã vùng.',
        ENTER_AREA: 'Vui lòng nhập mã vùng.',
        ENTER_VALID_ZIP: 'Vui lòng nhập một mã zip hợp lệ.',
        ENTER_VALID_AREA_CODE: 'Vui lòng nhập mã vùng hợp lệ.',
        NO_AVAILABLE_PHONE_NUMBERS: 'Không có số điện thoại nào có sẵn cho Mã vùng / mã vùng đã nhập.',
        NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP: 'Không có số điện thoại nào có sẵn cho Mã vùng đã nhập.',
        REMOVE_NUMBER_CONFIRMATION: 'Bạn có chắc chắn muốn xóa số này không?',
        SMS_SUPPORT: 'Gửi tin nhắn văn bản không được hỗ trợ ở quốc gia của bạn.',
        SELECT_NUMBER: 'Vui lòng chọn một số.',
        SELECT_AT_LEAST_ONE_NUMBER:'Vui lòng chọn ít nhất một số để nhập',
        TWILIO_NUMBER_IMPORTED_SUCCESS:'(Các) số twilio của bạn đã được nhập thành công',
        TWILIO_NUMBER_IMPORTED_ERROR:'Không thể nhập (các) số twilio của bạn.',
        PHONE_NUMBER_CANNOT_BE_PURCHASED:'Số điện thoại không thể mua được. Vui lòng liên hệ bộ phận hỗ trợ của Soffront để biết thêm chi tiết.',
        ENTER_SMS_TEXT: 'Vui lòng nhập văn bản sms.',
        SMS_LOW_BALANCE_1: 'Số dư sms của bạn bây giờ là ',
        SMS_LOW_BALANCE_2: 'Bạn cần phải mua tín dụng sms để gửi sms.',
        TWILIO_NUMBER_PURCHASE_MSG_1: 'Tài khoản của bạn sẽ được lập hóa đơn',
        TWILIO_NUMBER_PURCHASE_MSG_2: 'tháng.',
        TWILIO_NUMBER_PURCHASE_MSG_3: 'cho số điện thoại này. Bạn có thể xóa số khỏi tài khoản của mình bất kỳ lúc nào để ngừng thanh toán.',
        SMS_TOOLTIP_MESSAGE: 'Tìm bởi zip hoặc mã vùng',
        SMS_TOOLTIP_MESSAGE_AREA_CODE: 'Tìm theo mã vùng',
        INSERT_IMAGE_FILE: 'Vui lòng chỉ chọn tập tin jpg, jpeg, gif hoặc png',
        BATCH_SEND_SMS_MESSAGE: 'Tin nhắn được gửi thành công',
        'INSUFFICIENT_BALANCE': 'Số dư SMS không đủ',
        'BATCH_SMS_SENT': 'Tin nhắn được gửi thành công',
        'BATCH_SMS_FAILS': 'Không thể gửi tin nhắn văn bản',
        NO_FROM_NUMBER_MESSAGE: 'Bạn sẽ cần mua một số điện thoại để gửi tin nhắn văn bản. Vui lòng mua số điện thoại từ menu Tích hợp.',
        MAX_ATTACHMENT_FILE_FOR_SMS: 'Tối đa 10 tệp đính kèm được phép.',
        'NO_INPUT_STREAM_FOUND': 'Không tìm thấy luồng đầu vào',
        'NO_ATTACHMENT_FOUND': 'Không tìm thấy tệp đính kèm ',
        'FILE_SIZE_ERROR': 'Kích thước tệp phải nhỏ hơn 5MB.',
        IMAGE_SELECT: 'Chọn một hình ảnh',
        MERGE_FIELD: 'Chọn các trường hợp nhất',
        DUPLICATE_FILE_NAME: 'Tập tin này đã tồn tại. Vui lòng chọn một tệp khác.',
        RECORD_UNSUBSCRIBE_ALERT: 'Bản ghi được chọn là hủy đăng ký từ các văn bản. Bạn không thể gửi tin nhắn văn bản.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG: 'trong số các hồ sơ được chọn đã hủy đăng ký từ văn bản. Tin nhắn văn bản không thể được gửi đến (các) bản ghi chưa đăng ký. Nhấn OK để tiếp tục.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG_1: 'trong số các hồ sơ được chọn đã hủy đăng ký từ văn bản. Tin nhắn văn bản không thể được gửi đến (các) bản ghi chưa đăng ký. Nhấn OK để tiếp tục.',
        'BATCH_SMS_SCHEDULE': 'Tin nhắn đã được lên lịch thành công',
        'BATCH_SMS_SCHEDULE_FAIL': 'Không thể lên lịch Tin nhắn văn bản',
        BATCH_SEND_SMS_MSG_1: 'Ra khỏi',
        BATCH_SEND_SMS_MSG_2: 'bạn đã chọn, chỉ',
        BATCH_SEND_SMS_MSG_3: 'đã chọn tham gia nhận tin nhắn văn bản của bạn và có điện thoại di động không. và thuộc về',
        BATCH_SEND_SMS_MSG_4: '. Chúng tôi chỉ gửi tin nhắn văn bản đến các',
        BATCH_SEND_SMS_MSG_5: 'Nhấn OK để tiếp tục.',
        SINGLE_SEND_SMS_MSG_1: 'Vui lòng kiểm tra nếu được chọn',
        SINGLE_SEND_SMS_MSG_2: 'đã chọn tham gia nhận tin nhắn văn bản của bạn hoặc có điện thoại di động không. hoặc thuộc về',
        RESCHEDULE_SUCCESS: 'SMS đã được dời thành công',
        AUTOMATION_SMS_ADD_MSG: 'Bạn đã lưu thành công tin nhắn văn bản này',
        WORKFLOW_STATUS_MSG: 'Bạn có một hoặc nhiều quy trình công việc đang sử dụng số này để gửi tin nhắn văn bản. Vui lòng cập nhật các quy trình công việc đó để sử dụng một số khác trước khi xóa số này.',
        SCHEDULED_STATUS_MSG: 'Bạn có một hoặc nhiều sms theo giai đoạn đang sử dụng số này. Bấm OK để hủy tất cả sms theo giai đoạn của bạn và xóa số.',
        REMOVE_SUCCESS_MSG: 'Số đã được xóa thành công.',
        NUMBER_SAVED_SUCCESS_MSG: 'Đã lưu số thành công.',
        NO_LINKED_MOBILE_NUMBER_MSG:'Bản ghi được liên kết không có số điện thoại di động. Bạn không thể gửi Tin nhắn Văn bản.',
        NUMBER_INVALID_MSG:"Số điện thoại di động của người liên hệ không chính xác!",
        CANNOT_DELETE_UNITS_NUMBER: 'Bạn không được phép xóa số này vì số này đã được gán cho một Đơn vị.',
    },
    EVENTBRITE: {
        NO_ORGANISATION_SELECTED: 'Vui lòng chọn ít nhất một tổ chức',
        NO_MAPPING_FOUND: 'Không có ánh xạ cho các trường liên hệ CRM và Eventbrite.'
    },
    QUICKBOOKS: {
        NO_MAPPING_FOUND: 'Không có ánh xạ cho tài khoản CRM và trường Khách hàng Sách Nhanh.'
    },
    XERO: {
        SELECT_ONE_INTEGRATION_ITEM_AND_TYPE: 'Vui lòng chọn một mục và loại tích hợp để tích hợp.',
        SELECT_ONE_INTEGRATION_ITEM: 'Vui lòng chọn một mục để tích hợp.',
        SELECT_INTEGRATION_TYPE: 'Vui lòng chọn loại tích hợp.',
    },
    REPORT_SCHEDULE_DIALOG: {
        START_DATE_BLANK: 'Ngày bắt đầu không thể trống.',
        INVALID_END_DATE: 'Ngày bắt đầu không thể lớn hơn hoặc tương tự như ngày kết thúc.',
        DAY_CHECKBOX_BLANK: 'Atleast kiểm tra một ngày.',
        SEND_TO_BLANK: 'Atleast chọn một người sử dụng.',
        SAVE_SUCCESS: 'Đã lưu thành công.'
    },
    DOCUMENT_PUSH: {
        ALERT_MSG_1: 'Vui lòng chọn ít nhất một Công ty để đẩy Tài liệu',
        DOCUMENTS: 'Các tài liệu)',
        FAIL: 'Không thể đẩy'
    },
    OPERATIONS_REPORT: {
        NO_CUSTOM_REPORT_MESSAGE: 'Bạn không có bất kỳ báo cáo tùy chỉnh.'
    },
    PINNED_NOTES: {
        CONFIRM_MESSAGE: 'Điều này sẽ thay thế các ghi chú được ghim hiện có. Nhấp vào Ok để tiếp tục hoặc Hủy bỏ để loại bỏ.'
    },
    ASSIGNMENT_RULES: {
        NO_ASSIGNMENT_RULES: 'Không có quy tắc nào có sẵn.',
        NO_NAME: 'Vui lòng cung cấp tên quy tắc gán.',
        NAME_MAX_LENGTH: 'Gán tên quy tắc không thể nhiều hơn 64 ký tự.',
        NO_CRITERIA: 'Vui lòng cung cấp ít nhất một tiêu chí.',
        NO_USER: 'Vui lòng cung cấp ít nhất một người dùng.',
        RULE_SAVE_SUCCESS: 'Quy tắc chuyển nhượng đã lưu thành công.',
        RULE_NAME_EXISTS: 'Tên đã tồn tại!',
        ACTIVE_RULE_EXCEEDS: 'Bạn đã có 60 quy tắc phân công đang hoạt động',
        SELECT_UNIT_MAXIMUM_FIELD: 'Bạn chỉ có thể chọn một đơn vị',
        SELECT_A_UNIT_WORKFLOW: 'Vui lòng chọn một quy trình làm việc đơn vị.',
        NO_UNIT_USER: 'vui lòng cung cấp ít nhất một đơn vị.'
    },
    SETTINGS: {
        EMPTY_NAME: 'Vui lòng nhập',
        DUPLICATES_SOURCE_NAME: 'Tên nguồn đã tồn tại!',
        CORPORATE_CURRENCY: 'Loại tiền được chọn đã được cập nhật thành công.',
        INVALID_CURRENCY_NAME: 'Vui lòng chọn một tên tiền tệ công ty hợp lệ',
        SYSTEM_NOTE_TYPE_EDIT_ALERT: 'Bạn không thể chỉnh sửa Loại ghi chú này.',
        SYSTEM_NOTE_TYPE_DELETE_ALERT: 'Bạn không thể xóa Loại ghi chú này.',
        SYSTEM_CAMPAIGN_TYPE_DELETE_ALERT: 'Bạn không thể xóa Loại chiến dịch này.',
        CORPORATE_CURRENCY_ALERT: 'vui lòng chọn một loại tiền tệ của công ty.',
        CORPORATE_CURRENCY_CONFIRM: 'Loại tiền được chọn sẽ được lưu lại. Bạn có chắc không?',
        'CORPORATECURRENCY': 'Chọn một loại tiền tệ của công ty',
        'DUPLICATE_NOTE_TYPES': 'Loại ghi chú này đã tồn tại. Không được phép tạo các loại ghi chú trùng lặp.',
        'INCORRECT_RECORD_ID': 'Bản ghi không chính xác Id.Update không thành công.',
        'RECORD_DOES_NOT_EXISTS': 'Bản ghi không tồn tại. Thao tác không thành công.',
        'RECORD_CANNOT_DELETED': 'Các loại ghi chú hệ thống hoặc mặc định không thể bị xóa.',
        'DUPLICATE_CAMPAIGN_TYPES': 'Loại chiến dịch này đã tồn tại. Không được phép tạo các loại chiến dịch trùng lặp.',
        'DUPLICATE_RELATIONS': 'Tên quan hệ đã tồn tại!',
        RELATION_LINK_INFO_ALERT_MSG: 'Có hồ sơ theo mối quan hệ này. Hủy liên kết các bản ghi tương ứng trước theo mối quan hệ này và sau đó bạn sẽ có thể xóa mối quan hệ này.'
    },
    RECYCLE_BIN: {
        SELECT_WORKFLOW: 'Vui lòng chọn một quy trình làm việc',
        SELECT_STAGE: 'Vui lòng chọn một giai đoạn',
        NOTE: 'Lưu ý: Hồ sơ liên kết của hồ sơ gốc sẽ không được khôi phục.',
        RESTORE: 'Khôi phục hồ sơ thành công'
    },
    UNSUBSCRIBE: {
        SELECTED_RECORD: 'Bản ghi này sẽ được hủy đăng ký từ ',
        ARE_YOU_SURE: '. Bạn có chắc không?',
        SELECTED_BOTH_RECORD: 'Bản ghi này sẽ được hủy đăng ký khỏi Email và văn bản. Bạn có chắc không?',
        'EMAILS_UNSUBSCRIBE': ' sẽ được hủy đăng ký khỏi Email. Bạn có chắc không?',
        'TEXTS_UNSUBSCRIBE': ' sẽ được hủy đăng ký từ văn bản. Bạn có chắc không?',
        'BOTHS_UNSUBSCRIBE': ' sẽ được hủy đăng ký khỏi Email và văn bản. Bạn có chắc không?',
    },
    WORKFLOWS: {
        STATUS_CHANGED: 'Trạng thái đã được thay đổi thành ',
        'SAME_STATUS': 'Ghi lại trong',
        'FAILED_TO_CHANGE_WORKFLOW_STATUS': 'không thể thay đổi trạng thái Dòng công việc.',
        ONLY_ONE_ACTIVE_WORKFLOW: 'Bạn không thể làm cho công việc này không hoạt động. Đây là quy trình làm việc duy nhất có sẵn. Một bảng phải có ít nhất một luồng công việc đang hoạt động.',
        ONLY_ONE_ACTIVE_WORKFLOW_CANNOT_DELETE: 'Bạn không thể xoá công việc này. Đây là luồng công việc hoạt động chỉ có sẵn. Một bảng phải có ít nhất một luồng công việc hoạt động.',
        DELETED_WORKFLOW: 'Luồng công việc không tồn tại. Chọn OK để chọn một quy trình làm việc mới.',
        STATUS_BLANK_ALERT:'trạng thái không được để trống',
        SALES_REP_BLANK_ALERT : 'Đại diện bán hàng không được để trống',
    },
    CHANGE_LOGO: {
        SELECT_IMAGE_TO_UPLOAD: 'Chọn một hình ảnh để thay đổi Logo',
        LOGO_IMAGE_UPLOAD_FAILURE: 'Logo công ty không thể được lưu.',
        LOGO_IMAGE_UPLOAD: 'Logo công ty đã lưu thành công.',
        VALIDATE_LOGO_IMAGE: 'Chọn một tập tin hình ảnh.',
        VALIDATE_LOGO_IMAGE_SIZE: 'Ảnh bạn đã chọn quá lớn để sử dụng. Kích thước tệp tối đa được phép là 500 KB.',
        LOGO_IMAGE_ATTACHMENT_LOGO_TEXT: 'Bạn có thể tải lên tệp jpg, gif hoặc png.',
        LOGO_IMAGE_SIZE_TEXT: 'Kích thước tệp tối đa được phép là 500 KB.',
        WEBSITE_URL: 'Trang mạng',
        VALIDATE_LOGO_WEBSITE_URL: 'Bạn có muốn tải lên logo mà không có bất kỳ URL hợp lệ?'
    },
    COUPONS: {
        CREATE_COUPON_NAME: 'Tạo một tên nội bộ cho phiếu giảm giá của bạn',
        CREATE_DISCOUNT_TYPE: 'Loại giảm giá nào bạn muốn tạo ra?',
        FIXED_AMOUNT: 'Số tiền cố định',
        PERCENTAGE: 'Tỷ lệ phần trăm',
        PREFIX_COUPON_CODE: 'Chỉ định tiền tố cho mã phiếu giảm giá',
        CAN_CLAIM_MORE_THAN_ONCE: 'Có thể được sử dụng nhiều lần bởi cùng một tài khoản?',
        CALIM_LIMIT_ONE: 'Chỉ một lần',
        CLAIM_LIMIT_UNLIMITED: 'Vô hạn',
        WHYEN_CLAIM_CODE: 'Ngày hết hạn phiếu giảm giá',
        CLAIM_ANYTIME: 'Không bao giờ hết hạn',
        CLAIM_BEFORE_DATE: 'Ngày hết hạn',
        SAVE_SUCCESSFULLY: 'Coupon đã lưu thành công',
        ACTIVE_SUCCESSFULL: 'Trạng thái đã được thay đổi thành Hoạt động.',
        INACTIVE_SUCCESSFULL: 'Trạng thái đã được thay đổi thành Không hoạt động.',
        DELETE_CONFIRMATION: 'Phiếu giảm giá sẽ bị xóa. Bạn có chắc không?',
        INVALID: 'là không hợp lệ',
        DUPLICATE_COUPONS: 'Tên phiếu giảm giá đã tồn tại.',
        NEGATIVE_COUPONS: 'không thể phủ định.',
        SPECIFY_DATE: 'Vui lòng ghi rõ ngày.',
        PODUCT_SERVICE: 'Sản phẩm / Dịch vụ',

    },
    NOTIFICATION_REMINDERS: {
        SAVE_SUCCESS: 'Nhắc nhở được thêm thành công',
        INVALID_DAY: 'Nhập ngày hợp lệ (s)',
        DUPLICATE_REMINDER: 'Đây là bản sao Reminder, xin vui lòng thay đổi',
        INVALID_DATE_FIELD: 'Vui lòng chọn trường ngày',
        DELETE_SUCCESS: 'Nhắc nhở đã xóa thành công',
        UPDATE_SUCCESS: 'Nhắc nhở đã cập nhật thành công',
    },
    EMAIL_INTEGRATION: {
        DUPLICATE_EMAIL: 'Địa chỉ email này đã tồn tại!.',
        EMAIL_SAVE_SUCCESS: 'địa chỉ email đã lưu thành công ',
        SEND_VERIFICATION_EMAIL_SUCCESS_1: 'Chúng tôi đã gửi email xác minh. Vui lòng kiểm tra hộp thư đến và nhấp vào ',
        SEND_VERIFICATION_EMAIL_SUCCESS_2: 'xác minh địa chỉ email này. ',
        SEND_VERIFICATION_EMAIL_SUCCESS_3: 'Sau đó quay lại và nhấp vào biểu tượng làm mới để xem trạng thái của địa chỉ email của bạn. ',
        EMAIL_INTEGRATION_VERIFIED: 'địa chỉ email này đã được xác minh!',
        CONFIRM_DELETE_EMAIL: '(Các) địa chỉ email đã chọn sẽ bị xóa. Bạn có chắc không?',
        INVALID_EMAIL: 'Địa chỉ email không hợp lệ',
        EMAIL_CANT_BLANK: 'Địa chỉ email không thể trống!',
        EDIT_EMAIL_SAVE_SUCCESS: 'Email đã lưu thành công',

    },
    NEWS: {
        SAVE_ERROR: 'Không thể lưu lại.',
        VALIDATE_CATEGORY: 'Thể loại không thể để trống.',
        VALIDATE_STATUS: 'Tình trạng không thể để trống.',
        VALIDATE_TITLE: 'Tiêu đề không thể để trống.',
        VALIDATE_SUMMARY: 'Tóm tắt không thể để trống.',
        VALIDATE_EXTERNAL_LINK: 'Liên kết ngoài không thể để trống.',
        VALIDATE_DETAILS: 'Thông tin chi tiết không thể để trống.',
        CATEGORY_NAME_EXISTS: 'Giá trị đã tồn tại trong Danh sách.',
        DUPLICATE_TITLE: "Tiêu đề này đã tồn tại!",
    },
    USEFUL_LINKS: {
        CATEGORY: 'Mục',
        LINK: 'Liên kết',
        TITLE: 'Tiêu đề',
        VALID_CATEGORY: 'Vui lòng chọn danh mục hợp lệ',
        DUPLICATE_CATEGORY: 'Đây là bản sao thể loại, xin vui lòng thay đổi',
        DUPLICATE_TITLE: 'Đây là tiêu đề trùng lặp trong cùng một danh mục. Vui lòng thay đổi.',
        CONFIRM_DELETE_CATEGORY: 'Danh mục sẽ bị xóa. Bạn có chắc không?',
        CONFIRM_DELETE_LINK: 'Liên kết sẽ bị xóa. Bạn có chắc không?',
        VALID_URL: 'Vui lòng nhập một URL hợp lệ.',
        EXISTING_LINKS_CATGORY: 'Bạn có liên kết hiện có trong thể loại này. ',
        PLEASE_MOVE_LINKS: 'Vui lòng di chuyển các liên kết đến',
        CLICK_TO_CONFIRM: ' Nhấp OK để xác nhận.',
    },
    CASE: {
        CASE_CLOSED_SUCCESS: 'Trường hợp đóng thành công'
    },
    ORTHOTIC_REPORT: {
        VALIDATE_EMPTY_PRODUCT: 'Vui lòng chọn một sản phẩm.'
    },
    USER_REPORT: {
        REPORT_DELETE_RECORD: 'Báo cáo sẽ bị xóa. Bạn có chắc không?',
        REPORT_DELETE: 'Báo cáo đã xóa thành công',
        INVALID_START_END_DATE: 'Ngày bắt đầu không thể lớn hơn ngày kết thúc.'
    },
    AUTO_FILL_VALUES: {
        CHNAGE_PARENT_OBJECT: 'Bạn có thực sự muốn thay đổi đối tượng cha không?',
        CHANGE_CHILD_OBJECT: 'Bạn có thực sự muốn thay đổi đối tượng trẻ em không?',
        SELECT_PARENT_OBJECT: 'Chọn một đối tượng cha.',
        SELECT_CHILD_OBJECT: 'Chọn một đối tượng con.',
        NO_MAPPING_FIELDS: 'Không có trường ánh xạ nào giữa ',
        NO_MAPPING_FIELDS_AND: 'Và',
        CHILD_FIELD_SIZESHOULD_GREATER: 'Kích thước trường con phải lớn hơn kích thước trường cha.',
        MAPPING_FROM: ' ánh xạ từ ',
        MAPPING_TO: ' đến ',
        NO_AVAILBLE_FIELDS_FOR_MAPPING: '. Không có trường phổ biến nào để ánh xạ mặc định.',
        NO_AVAILABLE_PARENT_CHILD_MAPPING: 'Không có bản đồ trường cha-con có sẵn.',
        FIELD_MAPPING_DONE_SUCCESS: 'Thực hiện ánh xạ trường thành công.',
        MAP_FIELDS_TO_AUTOFILL: 'Bản đồ các trường để tự động điền ',
        MAP_FIELDS_TO_AUTOFILL_FROM: ' Từ ',
        SELECT_PARENT_FIELD: 'Chọn trường cha.',
        SELECT_CHILD_FIELD: 'Chọn trường con.',
        MAPPING_NOT_POSSIBLE: ' là không thể.'
    },
    LANDING_PAGE: {
        INVALID_NAME: 'Vui lòng nhập tên trang đích.',
        INVALID_URL_WARNING: 'Vui lòng nhập tên URL.',
        SAVE: 'Trang đích được lưu thành công.',
        UPDATE: 'Trang đích được cập nhật thành công.',
        INVALID_FORM_BLOCK_EDIT_MSG: 'Khối hoạt động hiện tại không có trường biểu mẫu để chỉnh sửa, vui lòng chọn khối trường biểu mẫu để chỉnh sửa.',
        DUPLICATE_NAME: 'Trang đích đã tồn tại với tên này. Vui lòng nhập một tên khác và thử lại.',
        DUPLICATE_URL: 'Trang đích đã tồn tại với URL này. Vui lòng nhập một tên khác và thử lại.',
        INPUT_DATA_LOSS_MSG: 'Tất cả các dữ liệu đã nhập sẽ bị xóa. Bạn có chắc không?',
        EMAIL_REQUIRED_MSG: 'Email thì cần thiết.',
        ATLEAST_ONE_BLOCK_ADD_MSG: 'Vui lòng thêm ít nhất một Khối thiết kế để lưu trang này.',
        PREVIEW_ALERT_MSG: 'Vui lòng lưu trước khi xem trước trang đích.',
        OPEN_URL_ALERT_MSG: 'Vui lòng lưu các thay đổi trước khi thực hiện hành động.',
        COPY_TO_CLIPBOARD: 'Liên kết trang đích được sao chép vào clipboard.',
        FORM_ADDED_MSG: 'Đã thêm biểu mẫu thành công.',
        FORM_UPDATED_MSG: 'Đã cập nhật biểu mẫu thành công.',
        INVALID_URL: 'Url không hợp lệ.',
        LABEL_FIELD_CANNOT_BLANK: 'trường nhãn không được để trống.',
        FIELD_IS_ALREADY_ADDED: 'Trường đã được thêm.',
        MINIMUM_ONE_FIELD: 'Tối thiểu một trường là bắt buộc.',
        NO_MORE_FIELD_AVAILABEL: 'Không còn trường nào nữa.'
    },
    DOWNLOAD_LEADS: {
        SELECT_CRITERIA: 'Vui lòng chọn atleast một tiêu chí.',
        SELECT_WORKFLOW: 'Vui lòng chọn một quy trình làm việc.',
        SELECT_GROUP: 'Vui lòng chọn một nhóm.',
        SELECT_STATUS: 'Vui lòng chọn trạng thái.',
        SELECT_SALESREP: 'Vui lòng chọn một salesrep.',
        LEAD_EXIST_MSG_1: 'Bạn đã có những dẫn này trong tài khoản của bạn- ',
        LEAD_EXIST_MSG_2: '. Nhấp vào OK để dừng tải xuống và xóa các dẫn này khỏi lựa chọn của bạn.'
    },
    SCHEDULER: {
        BLANK_APPOINTMENT_NAME: 'Vui lòng nhập tên cuộc hẹn.',
        BLANK_LOCATION: 'Vui lòng nhập vị trí.',
        SAVE_SUCCESS: 'Thiết lập bộ lập lịch đã lưu thành công.',
        SAVE_FAIL: 'Thiết lập bộ lập lịch chịu được lưu.',
        NO_SCHEDULER: 'Vui lòng tạo lịch hẹn trước khi sử dụng liên kết lập lịch.',
        VALIDATE_TIME: 'Thời gian bắt đầu không thể lớn hơn hoặc tương tự như thời gian kết thúc.',
        NO_START_TIME: 'Vui lòng chọn thời gian bắt đầu.',
        NO_END_TIME: 'Vui lòng chọn thời gian kết thúc.',
        SCHEDULER_LINK_COPIED_SUCCESS: 'Lập lịch liên kết sao chép vào clipboard.',
        BLANK_SUBMIT_BUTTON: 'Nhãn nút không thể trống.',
        CALENDAR_SYNC_MSG: 'Lịch CRM của bạn không được kết nối với GSuite hoặc Office 365 calendae của bạn.',
        CALENDAR_SYNC_LINK: 'Click vào đây để thiết lập nó.',
        SCHEDULER_UNSAVE_msg: 'Bạn có muốn rời khỏi mẫu này không? Thay đổi bạn thực hiện sẽ không được lưu.',
    },
    RELATION_REPORT: {
        RANGE_FIELD_BLANK: 'Vui lòng chọn một trường ngày.',
        ROW_FIELD_BLANK: 'Vui lòng chọn một trường hàng.',
        COL_FIELD_BLANK: 'Vui lòng chọn một trường cột.',
        REPORT_NAME_BLANK: 'Vui lòng nhập tên báo cáo.',
        REPORT_SAVE_SUCCESS: 'Báo cáo được lưu thành công.',
        DUE_BY_BLANK: 'Vui lòng chọn một phạm vi ngày.',
        NO_ROW_FIELD_VALUE: 'Vui lòng chọn một giá trị cho nhãn hàng.',
        NO_COLUMN_VALUE: 'Vui lòng chọn một giá trị cho tiêu đề cột.',
        SAME_ROW_COLUMN_FIELD: 'Trường hàng và trường cột không thể giống nhau.',
        INVALID_END_DATE: 'Ngày bắt đầu không thể lớn hơn ngày kết thúc.',
        'Report Name already exists': 'Tên báo cáo đã tồn tại.',
        VALID_REPORT_NAME: 'Vui lòng nhập Tên báo cáo hợp lệ'
    },
    PAYMENTS: {
        NOT_ADDED_QB: 'Các báo giá/hóa đơn không được thêm vào Quickbook chưa!',
        NO_LINKED_AVAILABLE: 'Không có báo giá liên kết/đơn có sẵn!',
        PAYMENT_VALUE_ALERT_MSG: 'Giá trị thanh toán không thể bằng không.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'Bạn có một tab báo giá / hóa đơn đang mở. Vui lòng đóng tab đó trước khi mở hoặc thêm báo giá / hóa đơn mới.',
        AMOUNT_EXCEED_BALANCE_DUE: 'Khoản thanh toán bạn đang nhập vượt quá số dư đến hạn cho hóa đơn này. Bấm OK để tiếp tục thanh toán. Nhấp vào Hủy để thay đổi số tiền thanh toán.',

    },
    PRODUCTS: {
        DUPLICATE_PRODUCT: 'Tên sản phẩm đã tồn tại.',
        ADDED_TO_QUICKBOOK: 'Thêm thành công vào QuickBooks.',
        PULLED_FROM_QUICKBOOKS: 'Thành công kéo từ QuickBooks.',
        PRODUCT_PRICE_VALUE_ALERT_MSG: 'Giá trị giá không thể bằng 0.',
        PRODUCT_NAME_COULDNOT_CONTAIN_COLON_MSG: 'không được chứa dấu hai chấm (:).',
        NEGATIVE_PRICE: 'Giá không thể âm đối với loại Khoảng không quảng cáo.'
    },
    COMPANY_PROFILE: {
        BLANK_BUSINESS_NAME: 'Vui lòng nhập tên doanh nghiệp.',
        BLANK_BUSINESS_ADDRESS: 'Vui lòng nhập địa chỉ doanh nghiệp.',
        BLANK_BUSINESS_PHONE: 'Vui lòng nhập điện thoại doanh nghiệp.'
    },
    EXPORT_SETTINGS: {
        CONSIDERATION: 'Sự xem xét:',
        CONSIDERATION_MSG1: 'Chúng tôi hỗ trợ các tệp có định dạng EXCEL & CSV.',
        CONSIDERATION_MSG2: 'Một ô trong Excel có thể chứa tối đa 32.767 ký tự, do đó trong tệp excel đã xuất, chúng tôi hiển thị tối đa 32.767 ký tự cho một ô và loại trừ các ký tự còn lại.',
        NO_RECORDS_FOUND: 'Không có dữ liệu được tìm thấy!',
        FILE_NAME_IS_EMPTY: 'Tên tệp trống.',
        PLEASE_SELECT_THE_FILE_TYPE: 'Vui lòng chọn loại tập tin.',
        PLEASE_SELECT_THE_PARENT_OBJECT: 'Vui lòng chọn đối tượng chính.',
        PLEASE_SELECT_THE_QUERY_NAME: 'Vui lòng chọn tên truy vấn.',
        PLEASE_SELECT_THE_PARENT_FIELDS: 'Vui lòng chọn các trường mẹ.'
    },
    DATA_BASE: {
        FAILURE_MSG: 'Lưu thất bại.',
        SUCCESS_MSG: 'Cài đặt của bạn đã được lưu thành công. Vui lòng đăng xuất và đăng nhập lại để xem các thay đổi.',
        VALIDATION_MSG: 'Vui lòng chọn ít nhất một đối tượng con cho ',
        VALIDATION_MSG1: "Bạn đã đạt đến giới hạn tối đa là 15 đối tượng đã chọn.",
        SUCCESS_MSG1: 'Cài đặt của bạn đã được lưu thành công.',
    },
    UNITS: {
        BLANK_FIRST_NAME: 'Vui lòng nhập tên.',
        BLANK_LAST_NAME: 'Vui lòng nhập họ.',
        BLANK_PASSWORD: 'Xin vui lòng nhập mật khẩu.',
        UNMATCH_RETYPE_PASSWORD: 'Nhập lại mật khẩu không khớp với mật khẩu.',
        BLANK_RETYPE_PASSWORD: 'Vui lòng nhập lại mật khẩu.',
        BLANK_EMAIL: 'Vui lòng nhập email.',
        BLANK_PHONE: 'Vui lòng nhập số điện thoại.',
        BLANK_COMPANY: 'Vui lòng nhập công ty.',
        UNIT_SAVED: 'Đã lưu thành công.',
        UNIT_NOT_SAVED: 'Không thể lưu đơn vị.',
        DUPLICATE_EMAIL: 'Bạn đã có (các) bản ghi có cùng id email.',
        DUPLICATE_COMPANY: 'Tên công ty đã tồn tại.',
        SUBSCRIPTION_NOT_VALID: 'Đăng ký không hợp lệ cho đơn vị đã chọn. Vui lòng liên hệ với bộ phận hỗ trợ của Soffront để biết thêm chi tiết.',
        NO_UNIT_TASKS: 'Không có nhiệm vụ nào có sẵn cho Chủ đơn vị.',
        NO_ACCOUNT_TASKS: 'Không có nhiệm vụ nào có sẵn cho Tài khoản.',
        BLANK_FAMILY_INDUSTRY: 'Vui lòng chọn một ngành gia đình',
        NO_FAMILY_INDUSTRY: 'Bạn sẽ cần một mẫu cơ sở trước khi có thể thêm các đơn vị mới. Vui lòng liên hệ với bộ phận hỗ trợ của BrandWide để thiết lập mẫu cơ sở.',
        BLANK_PLAN_NAME: 'Vui lòng chọn một Tên kế hoạch.',
        NO_AVAILABLE_PLANS: 'Không có kế hoạch có sẵn nào có thể được đăng ký cho đơn vị này.',
        NO_BRANCH_WORKFLOW: 'Không có quy trình làm việc chi nhánh có sẵn.',
        PLEASE_SELECT_TYPE: 'Vui lòng chọn một Loại để tiếp tục.',
        NO_BASE_LOGIN: 'A base não existe ou não está configurada! Corrija o problema e tente novamente.',
    },
    SHARE: {
        SHARE_SUCCESS_MSG_1: 'Chia sẻ thông tin được cập nhật thành công.',
        SHARE_SUCCESS_MSG_SL: 'Thông tin chia sẻ được cập nhật thành công cho các cấp độ bảo mật đã chọn nếu có.',
        SHARE_SUCCESS_MSG_2: 'Thông báo qua email sẽ được gửi đến các Đơn vị mới được chọn nếu có.',
        SHARE_SUCCESS_MSG_3: 'Thông báo qua email sẽ được gửi đến Đơn vị mới được chọn nếu có.',
        SHARE_SUCCESS_MSG_4: 'Bạn có chắc không?',
        SHARE_SUCCESS_MSG_5: 'sẽ được chia sẻ cho Đơn vị đã chọn.',
        SHARE_SUCCESS_MSG_6: 'sẽ được chia sẻ cho các Đơn vị đã chọn.',
        SHARE_FAILED_MSG: 'Không chia sẻ được.',
        SHARE_SELECT_CORPORATE_DOCUMENT: 'Vui lòng chọn ít nhất một Người dùng doanh nghiệp để chia sẻ Tài liệu.',
        SHARE_SELECT_CORPORATE_IMAGE: 'Vui lòng chọn ít nhất một Người dùng doanh nghiệp để chia sẻ Hình ảnh.',
        SHARE_SELECT_UNIT_DOCUMENT: 'Vui lòng chọn ít nhất một Đơn vị để chia sẻ Tài liệu.',
        SHARE_SELECT_UNIT_IMAGE: 'Vui lòng chọn ít nhất một Đơn vị để chia sẻ Hình ảnh.',
        NO_CONNECTED_CLIENT: 'Không có máy khách được kết nối nào sẵn dùng để chia sẻ Tài liệu.',
    },
    SECURITY_LEVEL: {
        ERROR_MSG_1: 'Giáo sư! Đã xảy ra lỗi. Vui lòng thử lại sau.',
        ERROR_MSG_2: 'Mô tả không được để trống.',
        SUCCESS_MSG_1: 'Cài đặt của bạn đã được lưu thành công.',
    },
    ROYALTY_REPORT: {
        BLANK_ROYALTY_PERCENT: 'Vui lòng nhập phần trăm tiền bản quyền.',
        REPORT_SAVE_SUCCESS: 'Báo cáo được lưu thành công.',
        SETUP_SAVE_SUCCESS: 'Thiết lập bản quyền được lưu thành công.'
    },
    MENU: {
        ATLEAST_ONE_MODULE_ACTIVE: 'Xin lỗi, ít nhất một mô-đun phải hoạt động.',
        ATLEAST_ONE_MODULE_ITEM_ACTIVE: 'Xin lỗi, ít nhất một mục mô-đun phải hoạt động.',
        MODULE_LABEl_CHAR_LIMIT_MSG: 'Tên không được vượt quá 32 ký tự.',
        DUPLICATE_NAME: 'tên mô-đun đã tồn tại.'
    },
    SALES_PURCHASE_ORDER: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD: 'Bạn có một tab đơn đặt hàng đang mở. Vui lòng đóng tab đó trước khi thêm đơn đặt hàng mới.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD: 'Bạn có một tab đơn đặt hàng bán hàng đang mở. Vui lòng đóng tab đó trước khi thêm đơn hàng bán hàng mới.',
        SAVE_PURCHASE_SUCCESS: 'Đơn đặt hàng đã được lưu thành công.',
        SAVE_SALES_SUCCESS: 'Đơn đặt hàng đã được lưu thành công.',
        SALES_REP_NAME_CANT_BLANK: 'Tên đại diện bán hàng không được để trống.',
        SELECT_ONE_ITEM: 'Vui lòng chọn một thông tin mặt hàng.',
        DISCOUNT_AMOUNT_CANT_GREATER: "Số tiền chiết khấu không được lớn hơn tổng phụ.",
        SPECIFY_TRANSC_AMOUNT: 'Bạn phải chỉ định số tiền giao dịch bằng 0 hoặc lớn hơn.',
        SETUP_COMPANY_PROFILE: 'Vui lòng thiết lập hồ sơ công ty của bạn trước khi thêm báo giá. Vui lòng liên hệ với quản trị viên CRM của bạn để thiết lập hồ sơ.'
    },
    JOBS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD: 'Bạn có một tab công việc đang mở. Vui lòng đóng tab đó trước khi thêm công việc mới.',
        FIELDS_CANNOT_BLANK: ' trường không được để trống.',
        PRODUCT_NAME_NOT_CONTAIN: 'Tên sản phẩm không được chứa dấu hai chấm (:).',
        JOB_SAVE_SUCCESS: 'Đã lưu công việc thành công.',
        PRODUCT : 'Sản phẩm',
        ALREADY_EXIST: 'đã tồn tại.',
    },
   POWER_CALLING :{
        YOU_DONT_HAVE_CALL_SCRIPT: "Bạn không có bất kỳ tập lệnh cuộc gọi nào.",
        ALERT_MSG_1: 'Ra khỏi ',
        ALERT_MSG_2: ' bạn đã chọn, chỉ ',
        ALERT_MSG_3: ' có số địa phương hợp lệ. Bạn sẽ có thể gọi những ',
        ALERT_MSG_4: 'Nói chuyện với ',
        ALERT_MSG_5: "Không có nhiều",
        ALERT_MSG_6: "trong hàng đợi để thực hiện cuộc gọi.",
        ALERT_MSG_7: 'Bạn không có bất kỳ kết nối nào đang hoạt động.',
        ALERT_MSG_8: 'bạn đã chọn, không ai trong số họ có số di động hoặc điện thoại.',
        ALERT_MSG_9: 'Bạn đã có một Trình quay số nguồn đang mở, vui lòng đóng lại để mở Trình quay số nguồn mới.',
        ALERT_MSG_10 :'Để đóng tab, hãy nhấp vào biểu tượng đóng ở đầu tab.'
    },
    LEAD_SCORE_SETUP: {
        ALREDY_DEFINED_SCORE: 'Você já definiu uma pontuação para esta ação.',
        SAVE_SUCCESSFULLY: 'A pontuação foi salva com sucesso.',
        CAN_NOT_UPDATE: 'As configurações de pontuação não podem ser atualizadas. Modifique as configurações de pontuação corretamente. ',
        SELECT_ONE_ACTION: 'Selecione pelo menos uma ação',
        SELECT_ACTION: 'Selecione uma ação',
        SELECT_OBJECT: 'Selecione um objeto',
        SELECT_WORKFLOW: 'Selecione um fluxo de trabalho',
        CONVERT_FROM_CANT_BLANK: 'Convertido de não pode ficar em branco',
        CONVERT_TO_CANT_BLANK: 'Convertido para não pode ficar em branco',
        CHOOSE_DIFF_STATUS: 'Você não pode converter o mesmo status. Escolha um status diferente. ',
        SELECT_GROUP: 'Selecione um grupo',
        SELECT_FIELD: 'Selecione um campo',
        PROVIDE_VALUE_TO_UPDATE: 'Forneça um valor que deseja atualizar',
        ENTER_SCORE: 'Por favor, insira uma pontuação entre 1-99',
        EXIST_IN_LIST: 'Valor já existe na lista',
        SCORE_DELETED: 'Esta pontuação será deletada. Tem certeza?',
                REMOVE_MSG: 'loại bỏ thành công'

    },
    DUPLICATE_RULE: {
        ERROR_MSG_DEFAULT: 'Bạn không thể tắt quy tắc mặc định.',
        ERROR_MSG_Active: 'Bạn không thể Deactive một quy tắc mặc định.',
        ERROR_MSG_FOR_FIELDS: 'Chọn tất cả các lĩnh vực từ một khối đặc biệt để lưu các quy tắc.',
        ERROR_MSG_1: 'Một cái gì đó đã đi sai. Vui lòng thử lại sau.',
        SUCCESS_MSG_1: 'quy tắc trùng lặp của bạn (s) đã được lưu thành công.',
        ERROR_MSG_SAME_FIELD: ' đã được chọn. Vui lòng chọn trường khác.',
    },
    REQUIRED_FORM: {
        FIELD_FILL_MESG:'Các trường này cần được điền trước ',
        CAN_BE_SUBMITTED:' có thể được gửi.'
    },
    GREENLIGHT: {
        COMPANY_NAME_REQUIRED: 'Tên Công ty không được nhiều hơn 30 ký tự.',
        COMPANY_NAME_BLANK: 'Vui lòng nhập tên công ty.',
        ADDRESS1_REQUIRED: 'Địa chỉ 1 là bắt buộc.',
        ADDRESS1_ADDRESS2_LIMIT: 'Address1 + Address2 kết hợp không được nhiều hơn 30 ký tự.',
        PHONE_NUMBER_REQUIRED: 'Xin vui lòng nhập một số điện thoại hợp lệ.',
        PHONE_NUMBER_LIMIT: 'Số điện thoại không được nhiều hơn 10 chữ số.',
        NET_ANNUAL_LIMIT: 'Net Anual không được để trống và không được bằng 0.',
        NET_ANNUAL_INVALID: 'Vui lòng nhập số tiền hợp lệ trong Net Anual.'
    },
    AUDIT_QUESTIONS: {
        QUESTION_REQUIRED: 'Câu hỏi là bắt buộc',
        ENTER_VALID_SCORE: "Vui lòng nhập điểm hợp lệ",
        ENTER_VALID_CHOICES: "Vui lòng nhập các lựa chọn hợp lệ",
        SUCCESS: 'Sự thành công',
        ERROR: 'Lỗi',
        NO_QUESTIONS: "Không tìm thấy câu hỏi nào",
        THANK_YOU: "Cảm ơn!",
        SOMETHING_WRONG: "Một cái gì đó đã sai trong khi gửi phản hồi của bạn, vui lòng thử lại!",
        FEATURE_NOT_AVAILABLE: "Tính năng này không có sẵn.",
        RECEIVED_FEEDBACK_1: "Làm tốt! Bạn đã lưu thành công câu trả lời cho",
        RECEIVED_FEEDBACK_2:"loại.",
        RECEIVED_FEEDBACK_3:"Hãy nhớ rằng, bạn luôn có thể cập nhật câu trả lời của mình cho danh mục này trước khi gửi bài cuối cùng.",
        MAIL_SENT_MESSAGE:'Thư đã gửi.',
        SUBMIT_AUDIT_1:'Có những câu hỏi bắt buộc chưa được trả lời trong các loại sau: ',
        SUBMIT_AUDIT_2:'Vui lòng trả lời những câu hỏi bắt buộc đó và sau đó Gửi Kiểm toán.',
        SUBMIT_AUDIT_3:'Bạn đã gửi kiểm toán thành công!',
        SUBMIT_AUDIT_4:'Bạn có thể xem các câu trả lời kiểm tra đã gửi và điểm số của mình trong CRM trong HUB > Kiểm tra.',
        SUBMIT_AUDIT_5:'You will receive email notification, if Franchisor provides any feedback on your audit.',
        SUBMIT_AUDIT_6:'Bạn cũng có thể xem phản hồi do Bên nhượng quyền đưa ra và thực hiện các hành động khắc phục từ HUB > Kiểm tra.',
        SAVE_ALERT_1:'Chú ý! Bạn sắp rời khỏi đây ',
        SAVE_ALERT_2:'danh mục mà không lưu các thay đổi.',
        SAVE_ALERT_3:'Vui lòng nhấp vào OK để tiếp tục sau khi lưu hoặc đóng thông báo này để ở lại đây và tiếp tục với danh mục này.',
        REQUEST_CORRECTIVE_SUCCESS:'Cảm ơn! Phản hồi của bạn đã được gửi qua email thành công.',
        SUBMIT_CORRECTIVE_ACTION:'Cảm ơn! Thông tin hành động khắc phục của bạn đã được gửi qua email thành công.',
        EMPTY_REQUEST_CORRECTIVE_MESSAGE:'Bạn chưa thêm phản hồi của mình cho bất kỳ câu hỏi nào.'+'\n'+'Vui lòng thêm phản hồi trước rồi nhấp vào “Yêu cầu hành động khắc phục”',
        EMPTY_SUBMIT_CORRECTIVE_MESSAGE:'Bạn chưa thêm hành động khắc phục nào đối với bất kỳ phản hồi nào.' + '\n' + 'Vui lòng thêm hành động khắc phục trước rồi nhấp vào “Gửi hành động khắc phục”.',
        EMPTY_AUDIT_BY_NAME_AND_EMAIL:'Trường Tên và Địa chỉ email của bạn đang trống. Vui lòng nhập giá trị vào các trường đó.',
        EMPTY_AUDIT_BY_NAME:'Trường Tên của bạn đang trống. Vui lòng nhập giá trị vào các trường đó.',
        EMPTY_AUDIT_BY_EMAIL:'Trường địa chỉ email của bạn đang trống. Vui lòng nhập giá trị vào các trường đó.',
        AUDIT_TYPE_SELECT_VALIDATION:'Vui lòng chọn một loại Kiểm toán để tạo liên kết.',
        INVALID_AUDIT_EMAIL:'Vui lòng nhập email hợp lệ.',
        INVALID_AUDIT_NAME:'Vui lòng nhập tên hợp lệ.',
    },
    AUDIT_ANSWERS:{
        NO_RECORD_FOUND:'Không tìm thấy câu trả lời có câu hỏi sửa lỗi',
    },
    AUDIT_QUESTION_TYPES:{
        MAX_EXCEED:'Vượt quá tối đa',
    },
    COMPLIANCE: {
        SINGLE_RECORD_EDIT: 'Vui lòng chọn 1 bản ghi để chỉnh sửa.',
    },
    SALES_AND_REFUND: {
        ALL_OPTIONS_REQUIRED: 'Vui lòng chọn tất cả các bộ lọc có sẵn và thử lại.',
    },
    INTAKE_FORM : {
        RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM:'You have an open Intake form tab. Please close that tab before opening or adding a new Intake form.',
        INTAKE_FORM_SUCCESSFULLY_SAVE: 'Saved Successfully.',
        THANK_YOU: "Thank You!",
    },
    EXCEPTION: {
        EXCEPTION_SAVE_SUCESS: 'Saved Successfully.',
    },
    PHONE_NUMBERS: {
        USE_FOR_NOTIFICATION_SAVE: 'Từ Số được cập nhật cho Thông báo.',
        USE_NOTIFICATION: 'Số thuê này sẽ được sử dụng để thông báo. Vui lòng nhấn OK để xác nhận.',
        REMOVE_NOTIFICATION: 'Số đã thuê này sẽ bị xóa để thông báo. Vui lòng nhấn OK để xác nhận.',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        SAVE_SUCCESS: 'Cài đặt của bạn đã được lưu thành công.',
        SAVE_FAIL: 'Cài đặt của bạn chưa được lưu.',
        NO_PROVIDER: 'Chưa có nhà cung cấp nào được chọn cho tính năng Điện thoại và Tin nhắn.\n Trước tiên, vui lòng chọn một nhà cung cấp để sử dụng các tính năng Điện thoại và Tin nhắn.',
        NO_TWILIO_NUMBERS_FOUND: 'Không có số Điện thoại nào trong Tài khoản Twilio được kết nối của bạn.\n Vui lòng thêm số này trước vào Tài khoản Twilio của bạn sau đó thực hiện thao tác nhập.',
        NONE_SELECT_CONFIRM: 'Điều này sẽ vô hiệu hóa tùy chọn Điện thoại và Tin nhắn trong CRM ở mọi nơi.\n Nếu bạn có bất kỳ Chiến dịch quy trình công việc hoặc Tự động hóa nào mà Văn bản đã được định cấu hình, vui lòng thay đổi các tùy chọn đó cho phù hợp vì nó sẽ không hoạt động nữa.'
    }
}
export default message