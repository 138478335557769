 const message = {
    COMMON: {
        NO_ACTIONS_AVAILABLE: '没有可用于快速添加操作的对象',
        NOTES_REMOVE_MESSAGE:'选定的注释将被删除。 你确定吗？',
        SELECTED_NOTE_MESSAGE:'必须至少选择一个注释才能执行此操作。',
        BR_WINDOW_OPEN_CONFORMATION: '我们不支持广告系列的拖放功能。你想开放这个活动吗？',
        CONFIRM_DIALOG_TITLE: '确认对话框',
        ALERT_DIALOG_TITLE: '报警对话框',
        XMS_SUCCESS_ALERT_DIALOG_TITLE: '成功',
        XMS_ERROR_ALERT_DIALOG_TITLE: '错误',
        SAVE: '已成功保存。',
        MERGE: '已成功合并。',
        UPDATE: '已成功更新。',
        DELETE: '已成功删除。',
        REMOVE: '已成功移动',
        UNLINK: '已成功取消链接。',
        COPY_EMAIL:'电子邮件 ID 已复制到您的剪贴板',
        EMAIL_VALIDATION:'请输入有效电子邮件。',
        EMPTY_EMAIL:'没有可供复制的电子邮件',
        ERROR: '系统未响应。',
        ACCESS_DENIED: '对不起, 您没有执行此操作的权限。请与管理员联系。',
        NOTIFICATION_INFO_EMPTY: '没有可用的通知',
        /** START:  Validation message */
        BLANK: '空白',
        EMAIL: '是无效的电子邮件地址。',
        VALID_EMAIL: '您输入的电子邮件地址不象是个有效的地址。请重试。 提示: 请确保电子邮件地址具有电子邮件 @domain. com 格式。',
        URL: '是无效的 url。',
        VALID_URL: '请输入有效的 url。',
        INTEGER: '不是整数。',
        VALID_INTEGER: '请输入一个有效的整数。',
        FLOAT: '不是浮点数。',
        VALID_FLOAT: '请输入有效的浮动。',
        VALID_PHONE: '电话号码必须是数字!!   ',
        VALID_LIST: '请选择一个值。',
        TOO_BIG_SIZE: '您输入的值对于此字段来说太大。输入较低的值。',
        NO_RECORD: '找不到记录。',
        VALIDATE_BLANK: '不能为空。',
        VALIDATE_INTEGER: '必须是一个整数。',
        VALIDATE_DECIMAL: '必须是十进制。',
        VALIDATE_POSITIVE_DECIMAL: '必须大于0和十进制。',
        VALIDATE_URL: '无效的 URL 格式。示例-www.soffront.com',
        VALIDATE_EMAIL: '电子邮件格式无效。',
        VALUE: '价值',
        LIBRARY_DIALOG_TITLE: 'Soffront 在线图书馆',
        BLANK_EMAIL: '电子邮件为空',
        INVALID_EMAIL: '请提供有效的电子邮件!',
        BLANK_NUMBER : '号码为空',
        INVALID_NUMBER :'请提供有效的号码',
        EMPTY_SEARCH_CRITERIA: '请输入搜索条件',
        VALIDATE_MORE_CHECKBOX_SELECTION: '如果选择了多个复选框, 则无法使用 <*> 执行搜索!',
        VALIDATE_NO_CHECKBOX_SELECTION: '如果选择了多于 个复选框, 则无法使用 <*> 执行搜索!',
        NO_DATA: '未找到数据',
        DISCONNECT_ALERT_MSG: '是否确实要断开此客户的联系？',
        DISCONNECT_SUCCESS: '您已成功断开与客户的联系。',
        CONFIRM_DELETE_RECORD: '记录将被删除。是否确定？',
        DELETE_SUCCESS: '已成功删除。',
        COULDNOT_LOCATE: "我们找不到",
        DELETED_OR_ACCESS_DENIED: " 它可能已被删除, 或者您无权访问它, 或者您已经打开了它。",
        CLICK: '点击',
        HERE: '这里',
        MERGE_ALERT_1: '是否确实要合并',
        WITH: '与',
        MERGE_ALERT_2: '和删除',
        DATA_ACCESS_REQUEST_SEND_SUCCESS: '数据访问请求发送至',
        DATA_ACCESS_REQUEST_SEND_FAILURE: '数据访问请求无法发送到  "',
        REQUEST_DATA_ACCESS: '请求数据访问从',
        WE_WILL_EMAIL: '我们会发邮件',
        AT: '在',
        TO_APPROVE_REQUEST: '批准您的请求',
        HAVE_BEEN: '已经被',
        REMINDER_VALUE: '提醒值不能为空',
        DUPLICATE_EMAIL: '您已有具有相同电子邮件 id 的记录。单击 "确定" 继续, 或单击 "取消" 更改电子邮件 id。',
        DUPLICATE_CONTACT_EMAIL: '您已有具有相同电子邮件 id 的记录。请输入不同的电子邮件 id。',
        CLIENT_CONNECT_MESSAGE: '此客户在 Soffront 在线没有此电子邮件的帐户. 首先要求客户创建具有相同电子邮件的帐户。',
        BLANK_CAMPAIGN_TYPE: '市场活动类型不能为空。',
        DUPLICATE_CAMPAIGN_TYPE: '市场活动类型已存在。',
        CAMPAIGN_TYPE_SAVE_SUCCESS: '已成功保存市场活动类型。',
        /** END:  Validation message */
        STEPPER_BACK_MESSAGE: '你想去模板信息页吗？任何未保存的更改都将丢失。',
        CENCEL_MOSAICO_MESSAGE: '确实要取消而不保存吗？',
        NO_RESULTS_FOUND: '未找到结果',
        FOR: '对于',
        ENABLE_MAILING_SUCCESS: '已成功启用电子邮件发送',
        DISABLE_MAILING_SUCCESS: '已成功禁用电子邮件发送',
        MAILING_FAILURE: '此帳戶不是CRM租戶',
        TEST: '測試',
        SELECT_RECORD_FOR_DELETE: '请选择要删除的记录。',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: '请选择一个要编辑的记录。',
        SELECT_RECORD_FOR_DELETE_ONE_RECORD: '请选择一个要删除的记录。',
        ASSETMENU_DIALOG_TITLE: '选择一个资产',
        UNIT_SELECT_ALERT_MESSAGE: '一次可以选择一个单元。',
        LOOKUP_MULTI_SELECT: '请只选择一条记录。',
        LOOKUP_SELECT_A_RECORD: '请选择一条记录。',
        BOTH_COMPANY_AND_WORKFLOW_SELECTED: '您已同时选择了公司和工作流。',
        VALIDATE_ZERO: '不能为零。',
        GREENLIGHT_EXPORT_NO_RECORD: '在此日期範圍內，Greenlight工作流程中沒有任何帳戶。',
        WITHOUT_SAVE_MESSAGE: '你想继续不要拯救变化吗？',
        DELETE_API_KEY: '这将删除 CRM 中的 API 密钥。您要继续吗？',
        UNIT_ALERT: '请选择一个记录进行登录。',
        THIS_FIELD_CANNOT_BLANK: '该字段不能为空。',
        CANNOT_DELETE_ALL_ITEM: '您必须在移动菜单下至少拥有一个项目。',
        DUPLICATE_MSG_1: '你已经有一个相同的结果 ',
        DUPLICATE_MSG_2: '。点击OK继续，或者单击取消更改 ',
        DUPLICATE_MSG_3: ' 和 ',
        DUPLICATE_CONTACT_EMAIL_1: '你已经有一个相同的结果 ',
        DUPLICATE_CONTACT_EMAIL_2: '。请输入不同 ',
        INVALID_TIME_ERROR_MSG: '请提供有效的时间间隔。',
        DOESNT_HAVE_ASSOCIATED_1:'这 ',
        DOESNT_HAVE_ASSOCIATED_2: " 没有关联的联系人。", 
        PHONE_CALL_NOT_ENABLED: '未启用电话。',
        NOT_CONFG_CALL_SETUP : '您尚未配置呼叫设置。',
        NOT_CONFG_CALL_SMS_SETUP:'您尚未配置呼叫/短信设置。',
        SEARCH_NO_CHECKBOX_SELECTION: '未选中复选框时，您无法执行搜索！',
        VALID_SOURCE_LIST: '请选择源字段。',
        LMS_USER_NOT_AVAILABLE: 'LMS 尚未激活。请联系您的管理员。',   
        ADD_BRANCH_BILLINGMASSAGE:'您目前没有许可证。您将以 40.00 美元/月的价格为本单元购买新许可证。此外，还将收取 100.00 美元的安装费来安装和培训新租户。'
    },
    SALES_TEMPLATE: {
        NAME_BLANK: '请输入名称以标识此模板。',
        SUBJECT_BLANK: '请输入此模板的主题。',
        DUPLICATE_NAME: '您已经有一个名为%% 的模板。我们不支持重复的模板。请键入一个不同的名称并重试。',
        DELETE: '模板将被删除。是否确定？',
        TEMPLATE_DELETED: '模板已成功删除',
        TEMPLATE_NAME_ERROR: '您已有具有此名称的现有模板。请输入其他名称并重试。',
    },
    AGREEMENT_TEMPLATE: {
        NAME_BLANK: '请输入名称以标识此模板。',
        // SUBJECT_BLANK: 'Please enter a subject for this template.',
        DUPLICATE_NAME: '您已经有一个名为 %% 的模板。我们不支持重复模板。请键入其他名称，然后重试。',
        DELETE: '模板将被删除。是否确定？',
        TEMPLATE_DELETED: '模板已成功删除',
        TEMPLATE_NAME_ERROR: '您已有具有此名称的现有模板。请输入其他名称并重试。',
        DUPLICATE_TEMPLAE_EXIST: '此模板名称已存在。',
        RECORD_NOT_EXIST: '此记录不存在。',
        SAVE_TO_DOWNLOAD: '您尚未保存协议模板。请先保存协议模板以下载它。',
        TEMPLATE_CAN_NOT_EMPTY: '协议模板名称不能为空。',
        SELECT_TEMPLATE: '请选择一个模板。',
        SENT_OR_SIGNED: '您已经发送过一次本协议。您无法再次发送。',
        SIGNATURE_TAGS_NOT_SEQUENCED_1: '请按顺序添加签名标签。',
        SIGNATURE_TAGS_NOT_SEQUENCED_2: '看来您在添加签名标签时跳过了顺序。',
        SIGNATURE_TAGS_MISSING: '协议正文中没有可用的签名标签。协议正文应至少有一个签​​名标签。',
        SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING: '看来您已使用签名的合并字段而不使用签名标签。请添加签名标签以继续。',
        AGREEMENT_EDIT: '您只能对协议记录进行编辑，而不能对其他附件记录或已发送的协议记录进行编辑。',
        AGREEMENT_EXPIRED_1: '本协议已到期 ',
        AGREEMENT_EXPIRED_2: '请在发送前延长协议有效期。',
    },
    SMS_TEMPLATE: {
        BLANK_MESSAGE: '请输入一条消息以保存此模板。',
        SAVE_MESSAGE: '已成功保存 SMS。',
    },
    MARKETING_TEMPLATE: {
        NAME_BLANK: '请输入名称以标识此模板。',
        SUBJECT_BLANK: '请输入此模板的主题。',
        DUPLICATE_NAME: '您已经有一个名为%% 的模板。我们不支持重复的模板。请键入一个不同的名称并重试。',
        DELETE: '模板将被删除。是否确定？',
        BACK: '如果选择返回, 您将失去未保存的更改。是否要继续？',
        SELECT_DESIGN: '从设计中选择',
        TEMPLATE_UPDATE: '已更新市场营销模板',
        CONFIRM1: '的',
        CONFIRM2: '记录将更新为',
        CONFIRM3: '是否确定？',
        ALERT1: '出的',
        FROM_NAME_BLANK: '请从电子邮件中选择一个',
        FROM_EMAIL_BLANK: '请从电子邮件中选择一个。',
        DUPLICATE_TEMPLATE_NAME: '模板名称已经存在。'
    },
    BROADCAST_CAMPAIGNS: {
        NAME_BLANK: '请输入名称以标识此广播营销活动。',
        GROUP_FILTER_BLANK_COMMON_MSG: '请选择',
        FILTER_TYPE_GROUP: '一组',
        FILTER_TYPE_CONTACT: '联系人筛选器',
        FILTER_TYPE_ACCOUNT: '帐户筛选器',
        SUBJECT_BLANK: '请输入主题',
        FROM_NAME_BLANK: '请输入名称来源',
        FROM_EMAIL_BLANK: '请输入Email来源',
        GROUP_NAME_BLANK: '组名不能为空!',
        SAVE: '您已成功创建此营销活动。',
        UPDATE: '您已成功更新此营销活动。',
        GROUP_NAME_SIZE_LIMIT: '组名称不能超过128个字符。',
        EXECUTION_CANNOT_CANCEL_1: '现在不能取消执行。',
        EXECUTION_CANCEL_MSG: '应用程序将尝试立即取消执行。',
        CONFIRM: '请确认。',
        EXECUTION_CANNOT_CANCEL_2: '已经在进行中的营销活动不能被取消。',
        PURCHASE_MORE: '购买更多。',
        CREDITS_REMAINING: '剩余的电子邮件余额。',
        VERIFY_BLANK_EMAIL: '必须输入电子邮件地址作为验证。',
        VERIFY_INVALID_EMAIL: '是无效的电子邮件地址。',
        SEND_VERIFICATION_EMAIL_SUCCESS: '我们已经发送了验证电子邮件。请检查您的收件箱, 然后单击“验证此电子邮件地址”。然后返回此处并单击刷新图标以查看您的新电子邮件地址。',
        EMAIL_INTEGRATION_NEW: '此电子邮件地址已提交验证!请检查您的收件箱, 然后单击“验证此电子邮件地址”。然后返回此处并单击刷新图标以查看您的新电子邮件地址。',
        EMAIL_INTEGRATION_VERIFIED: '此电子邮件地址已被验证!',
        BD_NAME_COPY_ERROR: '您有具有此名称的现有市场活动。请为此活动输入其他名称',
        STEPPER_BACK_MESSAGE: '你想去广播信息页吗？任何未保存的更改都将丢失。'
    },
    SPACE: ' ',
    LIST_VIEW: {
        SELECT_RECORD: '请选择一个记录以执行更多操作。',
        SELECT_RECORD_FOR_CONVERT: '请选择要转换的记录。',
        SELECT_RECORD_FOR_EDIT: '请选择要编辑的记录。',
        SELECT_RECORD_FOR_COPY: '请选择要复制的记录。',
        SELECT_RECORD_FOR_SEND_EMAIL: '请选择要发送电子邮件的记录。',
        MULTIPLE_RECORD_FOR_EDIT: '请选择一个记录进行编辑。',
        MULTIPLE_RECORD_FOR_COPY: '请选择一个用于复制的记录',
        MULTIPLE_RECORD_FOR_MORE: '请选择一条记录以获取更多信息。',
        CANNOT_COPY_APPOINTMENT_WORKFLOW: '不能复制约会工作流。',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: '请选择一个要复制的记录',
        SELECT_MAXIMUM_FIELD_FOR_SETUP: '您达到了总共15个选定字段的最大限制。',
        CONVERT_CONTACT_TO_ACCOUNT_CONFIRM: '此联系人已与一个帐户关联。单击 "确定" 查看此帐户的详细信息。',
        SHARE_PERMISSION: '你无权分享这份记录',
        NOT_ADDED: '您尚未添加',
        FIELDS_TO_VIEW: '要查看的字段。',
        SETUP_MINIMUM_FIELD_ALERT: '请添加另一个字段。对于视图, 我们至少需要两个字段。',
        SELECT_ANOTHER_TABLE: '请选择另一个表并重试。',
        WEB_FORM_ALERT1: '出的',
        WEB_FORM_ALERT2: '选定的 web 窗口无法删除因为其处理自动设计器中。',
        SETUP_WORKFLOWSTAGE_MINIMUM_FIELD_ALERT: '请为列添加字段。我们至少需要 1 个字段才能看到视图。',
        SELECT_RECORD_FOR_SEND_SMS: '请选择要发送短信的记录。',
        SELECT_RECORD_FOR_CALL: '请选择要拨打的记录。',
        SELECT_MORE_RECORD_ACTION_ALERT: '请选择一条记录以执行更多操作。',
        SELECT_RECORD_FOR_DOWNLOAD: '请选择"下载帐户"的记录。',
        CHECKBOX_COLOR_GREEN_TITLE: '30天内的最新更新',
        CHECKBOX_COLOR_YELLOW_TITLE: '30-60天内的最新更新',
        CHECKBOX_COLOR_RED_TITLE: '60天前的最新更新',
        STATUS_FIELD_NOT_ADDED_FOR_VIEW: '您尚未添加要查看的任何状态字段。',
        NO_EMAIL_TO_SEND_MAIL: '沒有電子郵件地址可供發送電子郵件。',
        REQUIRED_FIELD_CONVERT_FAILED: '转换失败。缺少一个或多个需要的字段。',
        SELECT_RECORD_FOR_To_EXCEL: '请选择excel的记录。',
        SELECT_RECORD_FOR_ADD_TASK: '普莱 ase 选择一条记录添加任务',
        UNIT_TASK_MESSAGE: '您只能将任务添加到与您直接连接的单位。',
        UNIT_SEND_EMAIL: '您可以打开仅向与您直接连接的设备发送电子邮件',
        UNIT_PHONE_NUMBER: '您只能向与您直接连接的设备打开电话呼叫',
        FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED: '您不能删除FDD或FA类型的附件记录 ',
        MULTIPLE_SELECT_FDD_FA_MSG_1: '出于 ',
        MULTIPLE_SELECT_FDD_FA_MSG_2: ' 选定的附件记录 ',
        MULTIPLE_SELECT_FDD_FA_MSG_3: ' 附件记录不会被删除，因为它们是 FDD 或 FA 类型的附件记录。你确定吗？',
    },
    AB_CAMPAIGNS: {
        NAME_BLANK: '输入营销活动的名称。',
        GROUP_FILTER_BLANK_COMMON_MSG: '请选择',
        FILTER_TYPE_GROUP: '一组',
        FILTER_TYPE_CONTACT: '联系人筛选器',
        FILTER_TYPE_ACCOUNT: '帐户筛选器',
        GROUP_FILTER_BLANK: '选择一个组。',
        CONTACT_FILTER_BLANK: '请选择一个联系人筛选器。',
        ACCOUNT_FILTER_BLANK: '请选择帐户筛选器。',
        SUBJECT_BLANK: '输入此消息的主题。',
        FROM_NAME_BLANK: '输入发件人名称。',
        FROM_EMAIL_BLANK: '输入发件人的电子邮件。',
        GROUP_NAME_BLANK: '组名不能为空!',
        AB_DISTRIBUTION: '输入 a/b 分布。',
        INVALID_AB_DISTRIBUTION: '无效的 a/b 分布。',
        INVALID_WINNING_MARGIN: '获奖者的保证金无效。',
        ZERO_WINNING_MARGIN: '为获奖者输入保证金。',
        MESSAGE_B_NOT_FILLED: '请输入消息 B 的详细信息。',
        AB_DISTRIBUTION_OUT_OF_RANGE: '为测试输入介于1到99之间的值',
        DAYS_BLANK: '请输入您希望在将获奖消息发送到剩余列表之前等待多少天。',
        INVALID_DAYS: '无效的输入。请输入您希望在将获奖消息发送到剩余列表之前等待多少天。',
        SAVE: '您已成功创建此营销活动。',
        UPDATE: '您已成功更新此营销活动。',
        EXECUTION_CANNOT_CANCEL_1: '现在不能取消执行。',
        EXECUTION_CANCEL_MSG: '应用程序将尝试立即取消执行。',
        CONFIRM: '请确认。',
        EXECUTION_CANNOT_CANCEL_2: '已在进行中的活动不能被取消',
        CAMPAIGN_EXECUTE_IN_60_MIN: '此活动将在60分钟内执行。',
        OK_TO_CONFIRM: '单击OK以确认',
        ERROR_MSG_1: '请选择模板。',
    },
    REPEAT_DIALOG: {
        END_DATE_BLANK: '结束日期不能为空',
        INVALID_END_DATE: '开始日期不能大于或与结束日期相同。',
        DAY_CHECKBOX_BLANK: '请至少选择一天',
        DELETE_RECUR_TITLE: '删除周期性',
        EDIT_RECUR_TITLE: '编辑周期性',
        REPEAT: '重复'
    },
    BATCH_ACTION: {
        CONVERT_SUCCESSFULL: ' 已被成功转换。',
        SINGLE_CONVERT_SUCCESSFULL: ' 已被成功转换。',
        CONVERT_UNSUCCESSFULL: '无法执行的操作',
        CONVERT_SAME_STAGE: ' 记录已存在 ',
        MULTI_SHARE_SUCCESSFULL: ' 已成功共享。',
        SINGLE_SHARE_SUCCESSFULL: ' 已成功共享。',
        SHARE_UNSUCCESSFULL: ' 无法执行的操作',
        MULTI_ASSIGN_SUCCESSFULLY: ' 已成功分配。',
        SINGLE_ASSIGN_SUCCESSFULLY: ' 已成功分配。',
        ASSIGN_UNSUCCESSFULL: ' 无法执行的操作',
        COMPLETE_SUCCESSFULL: ' 已成功完成。',
        BATCH_COMPLETE_SUCCESSFULL: '已成功完成。',
        COMPLETE_UNSUCCESSFULL: ' 无法执行的操作',
        GROUP_ADDED_SUCCESSFULLY: '已成功添加到组中',
        GROUP_REMOVED_SUCCESSFULLY: '已成功从所有组中删除。',
        GROUP_CLICK_TO_CONFIRM: '将添加到所选组中。单击 "OK" 以确认。',
        GROUP_BLANK_ADDED_BATCH: '请至少选择一个组。',
        NOTE_TXT_CANNOT_BE_BLANK: '文本不能为空。',
        WORKFLOW_CONFIRMATION: '将为此记录更改工作流。单击OK以确认。',
        BATCH_WORKFLOW_CONFIRMATION: '将为这些记录更改工作流。单击OK以确认。',
        WORKFLOW_APPLIED: '已成功应用工作流。',
        RECORDS_WITH_DIFFERENT_WORKFLOW: '您选择了具有不同工作流的记录。单击"确定"以继续并执行应用工作流操作，或单击"取消"以再次选择记录。',
        RECORDS_WITH_DIFFERENT_WORKFLOW_STATUS: '您选择了状态不同的记录。单击"确定"以继续并执行应用工作流操作，或单击"取消"以再次选择记录。',
        ACTIVE_SUCCESSFULL: '状态已更改为 "活动"。',
        INACTIVE_SUCCESSFULL: '状态已更改为 "非活动"。',
        BATCH_EDIT_BLANK: '值字段不能为空!',
        INVALID_WORKFLOW_STAGE: '您无法转换记录。当前工作流程中无法使用所选记录的状态。按确定更改状态或工作流程',
        CONFIRMATION_DELETE_MSG: ' 将被删除。是否确定？',
        CONFIRMATION_REMOVE_MSG: ' 将被移去，是否确定？',
        CONFIRMATION_UNLINK_MSG: ' 将被取消链接。是否确定？',
        CONVERT_ALERT_MSG: ' 将被转换为 ',
        CLICK_TO_CONFIRM: ' 单击确定以确认',
        ASSIGN_ALERT_MSG: ' 记录将被分配给 ',
        ROUND_ROBIN_BASIS: ' 在循环的基础上。是否确定？',
        CONFIRM_MSG: '是否确定？',
        RECORDS: '记录',
        RECORD: '记录',
        ALL_RECORDS: '所有记录',
        RECORD_WILL_BE_UPDATED: '记录将更新为',
        CONFIRM_UNSUBSCRIBE_RECORD: ' 将取消订阅。是否确定？',
        UNSUBSCRIBE: '您已成功取消订阅选定的联系人。',
        USE_ASSIGN_RULE_ALERT_MSG: ' 记录将按照 ',
        EMPTY_RECYCLE_BIN: '所有记录将从回收站中永久删除。你确定吗？',
        PERMANENT_DELETE_MSG: ' 将被永久删除。你确定吗？',
        INVALID_STATUS_FOR_WORKFLOW_STAGE: '所选记录的状态在当前工作流程中不可用。按确定更改记录的状态。',
        CONTACT_STATUS_UPDATE_SUCCESS: ' 状态已成功更新。',
        PUBLISH_SUCCESSFULL: '状态已更改为发布。',
        UNPUBLISH_SUCCESSFULL: '状态已更改为取消发布。',
        RECORDS_NOT_IN_WORKFLOW: '所有选定的记录不在联系人工作流中。',
        RECORD_NOT_IN_WORKFLOW: '不能转换，因为所选记录不在联系人工作流中。'
    },
    BATCH_ACTION_VALIDATION: {
        ADDTOGROUP_BLANK: '选定的记录将从所有组中删除。单击 "O,K" 以确认。',
        ADDTOGROUP_NAMEBLANK: '组名称不能为空。',
        SHARE_WITH_BLANKUSER: '请至少选择一个用户。',
        ASSIGN_BLANKUSER: '请选择用户。',
        CONVERT_SELECT_STATUS: '请选择一个状态用于',
        MAP_INSUFFICIENT_INFO: '信息不足, 无法显示地图',
        GROUP_ALREADY_EXIST: '组名已经存在!',
        ALREADY_ACTIVE: '记录已处于活动状态。',
        ALREADY_INACTIVE: '记录已处于非活动状态。',
        ALREADY_PUBLISH: '记录已处于发布状态。',
        ALREADY_UNPUBLISH: '记录已处于未发布状态。',
    },
    ATTACHMENT: {
        ATTACHMENT_UPLOAD: ' 附件已成功上传',
        ATTACHMENT_LINK_ADDED: '文档链接已添加成功！',
        ATTACHMENT_UPLOAD_IMAGE: '成功上传图片',
        ATTACHMENTS_UPLOAD: ' 已成功上传附件',
        VALIDATE_ATTACHMENT: '请选择要附加的文件 ',
        ATTACHMENT_UPLOAD_FAILURE: '此文件已存在。',
        VALIDATE_EMPTY_FILE: ' 上载文件大小不能为0 ',
        VALIDATE_FILE_SIZE: '上载文件大小不能超过 5 mb',
        VALIDATE_LOGO_SIZE: '您选择的照片太大, 无法使用。允许的最大文件大小为 500 KB',
        VALIDATE_BLANK_FILE: '不能上载空白文件',
        VALIDATE_LOGO_EXTENSION: '请选择 jpg、gif 或 png 文件!',
        ATTACHMENT_LOGO_TEXT: '您可以上传 jpg、gif 或 png 文件。',
        ATTACHMENT_LOGO_SIZE_TEXT: '允许的最大文件大小为 500 KB。',
        UPLOAD_IMAGE: '上传图像',
        UPLOAD_FILE: '选择要上载的文件 (一次最多五个文件)',
        ADD_LINK: '添加外部链接',
        VALIDATE_MAX_ATTACHMENTS: '您不能上传超过五个文件',
        SELECT_IMAGE_TO_UPLOAD: '选择要上载的图像',
        MAX_SIZE_2_MB_ALERT: '上载文件大小不能超过 2 mb',
        VALID_FILE_UPLOAD: '您可以从计算机上选择 jpg，gif 或 png 文件上传。',
        MAX_SIZE_2_MB: '允许的最大文件大小为 2 MB。',
        CANNOT_UPLOAD_EXECUTABLE_FILE: '不能上载可执行文件',
        THE_ATTACHMENT: '附件',
        DOCUMENT_LINK: '文档链接', 
        FOLDER_NAME: '文件夹名称',
        FILE_NAME:'文件名',
        ALREADY_EXIST: '已存在。',
        CANNOT_BE_DELETED: '无法删除。',
        CLICK_TO_DELETE: '单击 "确定" 删除 ',
        EMPTY_FOLDER_ERROR_DOWNLOAD: '无法下载, 因为文件夹为空。',
        EMPTY_FOLDER_ERROR_PUSH: '无法推送, 因为文件夹为空。',
        RENAME_SUCCESSFULLY: '文件夹已成功重命名。',
        DELETE_SUCCESSFULLY: '文件夹已成功删除。',
        ADDED_SUCCESSFULLY: '已成功添加。',
        DOCUMENT_LINK_ADDED_SUCCESSFULLY: '文档链接添加成功。',
        DOCUMENT_LINK_UPDATE_SUCCESSFULLY: '文档链接更新成功。',
        DOCUMENT_LINK_DELETE_SUCCESSFULLY: '文档链接删除成功。',
        FILE_ADDED_SUCCESSFULLY: '文件添加成功。',
        FILE_RENAME_SUCCESSFULLY: '文件重命名成功。',
        FILE_DELETE_SUCCESSFULLY: '文件删除成功。',
        IMAGE_RENAME_SUCCESSFULLY: '已成功重命名映像。',
        IMAGE_NAME: '图像名称',
        MAX_SIZE_50_MB: '允许的最大文件大小为50 MB。',
    },
    AUTOMATION_DESIGNER: {
        STEPPER_BACK_MESSAGE: '你想去模板信息页吗？任何未保存的更改都将丢失。',
        MAX_CONNECTION: "已经达到最大连接数。",
        TOUCH_CAMPAIGN_NAME: "请为此自动触发营销活动选择一个名称",
        SELECT_FILTERS: "请选择一个",
        SELECT_FILTERS_A: "请选择一个",
        START_DATE: "请选择开始日期",
        EMPTY_WEBSITE_LINK: "您不能添加空的网站链接。",
        CANNOT_START_WITH_DECISSION: '您不能从一个判断开始。请从左侧的 "开始" 菜单下的任一选项开始。',
        CANNOT_START_WITH_ACTION: '您不能从一个行动开始。请从左侧的 "开始" 菜单下的任一选项开始。',
        CONNECT_WITH_PROPER_NODE: '连接正确的节点',
        FROM_NAME: '请输入发件人姓名',
        FROM_EMAIL: '请选择发件人电子邮件',
        RESEND_AFTER: '请输入再次发送邮件的间隔天数',
        SELECT_WEB_FORM: '请选择 web 窗体',
        SELECT_GROUP: '请选择一个组。',
        SELECT_GROUP_OPERATION: '请选择组操作。',
        ENTER_NOTE: '请输入您的备注信息。',
        SELECT_ONE_RECIPIENT: '请至少选择一个收件人',
        SELECT_USER: '请选择用户。',
        ENTER_TASK_NAME: '请输入任务名称',
        PROVIDE_DUEBY_FIELD: '请为 "到期" 字段提供一个值!',
        ENTER_LINK: '请输入链接。',
        PROVIDE_VALID_URL: '请提供一个有效的网站网址!',
        CATCH_ALL_LINK_CONNECTION: '您已经有一个 “捕获所有 链接”点击的条件',
        ENTER_SCORE: '请输入分数',
        INVALID_SCORE: '您输入的分数无效',
        ENTER_SUBJECT: '请输入主题',
        ENTER_BODY: '请输入正文内容',
        ENTER_INTERVAL_VALUE: '请输入时间间隔的值',
        SELECT_ONE_HOUR: '请选择2小时或以上的小时时间间隔。',
        BLANK_CAMPAIGN_NAME: '营销活动名称不能为空。',
        CAMPAIGN_SAVED_SUCCESSFULLY: '已成功保存此营销活动',
        BLANK_CANVAS: '您无法保存空白画布。',
        NOT_ROOT_NODE: '不能是根节点。',
        START_DATE_MUST_BE_GREATER: '开始日期必须晚于前一个营销活动。',
        EDIT_AND_COMPLETE_DETAILS: '请编辑并完成详细的',
        PROVIDE_WAIT_INTERVAL: '请提供一个大于零的等待间隔。',
        CHECK_INTERVAL_WITH_PREVIOUS_TOUCH_CAMPAIGN: '请检查与上一活动重新发送日的时间间隔。',
        CHECK_START_DATE_OR_WAIT_BEFORE_SAVE_TC: '请在保存活动之前检查开始日期或等待。',
        PROVIDE_START_DATE: '请提供开始日期。',
        INTERVAL_EXPEXT_ZERO: '请输入数字零以外的时间间隔数值',
        EMPTY_LINK_NODE: '不能添加空链接节点。',
        DELETE_NODE_CONFIRMATION: '节点将被删除。要继续吗？',
        MSG_CANT_BE_DELETED: '此消息已发送, 无法删除。',
        FIRST_MSG_CANT_BE_DELETED: '你不能删除自动触发营销活动的第一条信息',
        FIRST_WAIT_CANT_BE_DELETED: '您无法删除触摸广告系列的首次等待',
        BLANK_INTERVAL: '间隔不能为空',
        PROVIDE_POSITIVE_INTERVAL: '请提供大于0的间隔',
        WONT_BE_ABLE_TO_DELETE: '您将无法删除它。',
        URL_NOT_EXIST: '您提供的 URL 在电子邮件正文中不可用。',
        GROUP_NAME_BLANK: '组名不能为空!',
        SELECT_LANDING_PAGE: '请选择一个登录页',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: '您已经有一个打开的自动化设计器, 请关闭或保存它以打开新的自动化设计器。',
        SAVE_DIAGRAM_BEFORE_COPY: '请在执行复制操作之前先保存。',
        NO_TOUCH_FOUND: '未找到自动触发营销活动。',
        ADD_ONE_FIELD: '请至少添加一个字段',
        SELECT_FIELD_VALUE: '请选择字段值',
        INVALID_URL: '是无效的 url。',
        INVALID_INTEGER: '不是整数。',
        INVALID_EMAIL: '是无效的电子邮件地址。',
        ALLOWED_UPTO_FOUR_FIELDS: '最多允许更新4字段。不能添加另一行字段值对。',
        WILL_BE_UPDATED: '将更新。是否确定？',
        DUPLICATE_TOUCH_NAME: '您已经有一个名为 "Send Touch" 的自动触发营销活动',
        TOOLTIP_MESSAGE_SEND_MAIL: '从一个发送一系列电子邮件的触摸广告系列开始。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_WEB_FORM: '首先定义提交Web表单后发生的情况。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_LANDING_PAGE: '首先定义提交目标网页后发生的情况。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_WEBSITE_VISIT: '首先，定义联系人访问您网站上的特定页面后发生的情况。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_LINK_CLICK: '定义联系人点击电子邮件中的链接后发生的情况。要使用此功能，请将其拖放到右侧的画布上，并使用触摸消息进行连接。',
        TOOLTIP_MESSAGE_EMAIL_OPEN: '定义联系人打开触摸消息后发生的情况。要使用此功能，请将其拖放到右侧的画布上，并使用触摸消息进行连接。',
        TOOLTIP_MESSAGE_UNSUBSCRIBE: '定义联系人取消订阅触摸消息后发生的情况。要使用此功能，请将其拖放到右侧的画布上，并使用触摸消息进行连接。',
        TOOLTIP_MESSAGE_SCORE_REACHED: '定义联系达到分数阈值后发生的情况。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_AUTO_MAIL: '发送电子邮件将用于发送一次性和触摸式电子邮件。可以在触摸广告系列或任何决策或任何操作后添加此操作。',
        TOOLTIP_MESSAGE_CHANGE_GROUP: '在组中添加或删除帐户和联系人。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_ADD_NOTE: '在帐户中添加备注。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_NOTIFY: '通知CRM用户。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_ADD_TASK: '将任务分配给另一个用户。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_UPDATE_FIELD: '更新帐户或联系人字段的值。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_ASSIGN: '将帐户重新分配给其他用户。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_CONVERT: '将帐户转换为新状态。要使用它，请将其拖放到右侧的画布上。',
        TOOLTIP_MESSAGE_SEND_SMS: 'Send SMS to a group, account filter or contact filter. To use this, drag and drop it on the canvas to the right.',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_1: '您已经有一个自动触发营销活动名为',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_2: '请输入其他名称。 ',
        INTERVAL_MODE_ADD_MSG_1: '请提供一个',
        INTERVAL_MODE_ADD_MSG_2: '除零以外的上一个值之后的值',
        AUTOMATED_CAMPAIGN_TITLE: '自动化的营销活动',
        CANNOT_CONNECT_MULTIPLE: '无法连接多个 ',
        CONNECTION_EXIST: '您已经有一个活动的连接从 ',
        REMOVE_CONNECTION_ALERT: '如果您删除连接, 您将其后所有的步骤',

        CONTINUE_ALERT: '要继续吗？',
        CANNOT_ADD: '不能添加',
        DIRECT_UNDER: '直接在一个',
        DIRECT_UNDER_AN: '直接在一个',
        MULTIPLE_TIME_SELECTED_MSG_1: '您选择了',
        MULTIPLE_TIME_SELECTED_MSG_2: '字段被多次更新。请删除重复的行。',
        CANNOT_BE_LEAF_NODE: '不能是叶节点。',
        CLICK: '点击',
        HERE: '这里',
        PURCHASE_MORE: '购买更多。',
        CREDITS_REMAINING: '剩余的电子邮件余额。',
        STOP_CAMPAIGN: '停止活动以进行更改。',
        SELECT_EMAIL_TEMPLATE: '选择电子邮件模板',
        NO_INFO_EXISTS: '不存在任何信息',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: '请选择一个要复制的记录。',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: '请选择一个要编辑的记录。',
        CANNOT_SAVE_CAMPAIGN: "无法保存此营销活动。请参阅红色块中的内容以了解错误详情。",
        ADD_GROUP_CLICK: '点击',
        ADD_GROUP_CLICK_HERE: '这里',
        CLICK_TO_ADD_NEW_GROUP: '添加新组',
        MESSAGE_SAVED_SUCCESSFULLY: '您已成功保存此消息',
        MESSAGE_NOT_SAVED_SUCCESSFULLY: '消息未成功保存',
        CONTACT_FILTER_BLANK: '请选择联系人筛选器。',
        ACCOUNT_FILTER_BLANK: '请选择帐户筛选器。',
        QUERY_FILTER_BLANK: '请选择一个查询',
        SAVE_AUTOMATION_FOR_TEST: '请保存自动化广告系列',
        BIRTHDAY_CAMPAIGN_NAME: '请选择此生日活动的名称',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_1: '你已经有了一个生日活动',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_2: '请输入其他名称。',
        BIRTHDAY_FIRST_MSG_CANT_BE_DELETED: '不能删除生日市场活动的第一条消息。',
        MIGRATION: '此自动广告系列正在升级为新的广告系列构建器。感谢您的耐心等待。',
        MIGRATION_DIALOG_TITLE: '正在升级......',
        MIGRATION_NOTE: '注意：更新后，自动化广告系列将在您执行“开始”操作的那一天开始运行。',
        BIRTHDAY_CAMPAIGN_NO_REPORT: '生日活动尚未发送给收件人',
        RUNNING_CAMPAIGN_CANNOT_CANCEL: '正在投放的广告系列无法取消。',
        CONFIRM_CANCEL_SCHEDULE: '您要取消预定的广告系列吗？',
        CAMPAIGN_SCHEDULE_SUCCESSFULLY: '此广告系列已成功安排。',
        SCHEDULED_CAMPAIGN_CANCEL_SUCCESSFULLY: '此广告系列已成功取消。',
        CAMPAIGN_START_IMMEDIATELY: '此广告系列已成功设置为立即启动。',
        ENTER_FROM_NAME: '请从名称中输入。',
        ENTER_FROM_EMAIL: '请通过电子邮件输入。'
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME_BLANK: '请提供一个域名来生成跟踪代码。',
        DOMAIN_NAME_INVALID: '请提供有效的域名。',
        TRACKING_CODE_BLANK: '没有要复制的跟踪代码。',
        TRACKING_CODE_ALREADY_GENERATED: '跟踪代码已生成。',
        DONE: '您的域名已注册。',
        SET_UP_INFO: '将此代码复制并粘贴到站点每一页的正文标记之前。完成后, 返回此页面并单击完成按钮。'

    },
    MAIL: {
        SUCCESS: '您的电子邮件已发送。',
        BLANK_FROM_EMAIL: '发件人Email地址是空的',
        INVALID_FROM_EMAIL: '在“发件人”字段中没有有效的email地址。',
        INVALID_CC_EMAIL: '在CC字段中没有有效的抄送者的Email地址。',
        INVALID_BCC_EMAIL: '在BCC字段中没有有效的密送者的Email地址。',
        BLANK_SUBJECT: '邮件主题为空。',
        BLANK_EMAIL_BODY: '电子邮件正文为空。',
        BATCH_SEND_MAIL_MSG_1: '在外面',
        BATCH_SEND_MAIL_MSG_2: '您已选择, 仅',
        BATCH_SEND_MAIL_MSG_3: '已选择接收您的电子邮件。我们只会把电子邮件发送到这些',
        BATCH_SEND_MAIL_MSG_4: '没有一个',
        BATCH_SEND_MAIL_MSG_5: '已选择接收你的电子邮件。我们不会本电子邮件。请关闭标签页。',
        NO_RECIPIENT: '您尚未输入此电子邮件的任何收件人',
        NO_RECIPIENT_FOUND: '找不到此电子邮件的收件人。',
        BLANK_TO_EMAIL: '“收件人”字段中没有电子邮件地址',
        INVALID_TO_EMAIL: '“收件人”字段中的电子邮件地址无效。',
        SCHEDULE_SUCCESS: '您的电子邮件已成功安排。',
        RESCHEDULE_SUCCESS: '已成功重新安排电子邮件',
        TEMPLATE_NOT_SELECTED: 'Please select a template',
        NO_ACTIVE_AGREEMENT_TEMPLATE_MSG: '您没有任何活动协议模板。',
        SELECT_DIFFERENT_SIGNER: "Please select a different signer.",
        SELECT_ALL_SIGNERS: 'Please select all the signer(s) to send the agreement email.',
    },
    APPOINTMENTS_TASKS: {
        DELETE: '此记录将被删除。是否确定？',
        INVALID_START_END_DATE: '开始日期不能大于或与结束日期相同。',
        RECURR_DELETE_DIALOG_ONLY_BTN_INFO: '系列中的所有其他记录将保留。',
        RECURR_DELETE_DIALOG_FOLLOWING_BTN_INFO: '本条和其下所有记录将被删除。',
        RECURR_DELETE_DIALOG_ALL_BTN_INFO: '序列中的所有记录都将被删除。',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_1: '所有其他',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_2: '在该系列将保持不变。',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_1: '本记录和所有其下记录',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_2: '将被更改。',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_3: '对未来的任何更改',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_4: '将丢失。',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_1: '所有',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_2: '在该系列中的将被更改。',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_3: '对于其他的任何更改',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_4: '将被保留。',
        RESCHEDULE_TASK_SUCCESS: '任务已成功重新排定时间表',
        RESCHEDULE_APPOINTMENT_SUCCESS: '已成功重新排定约会时间',
        NO_TASK_FOUND: '找不到任务',
        NO_APPOINTMENT_FOR_TODAY: '今天没有预定的约会',
        NO_PENDING_TASK_FOR_TODAY: '今天没有任务待完成任务',
        NO_TASK_APPOINTMENT_FOUND: '没有待完成任务',
        NO_TASK_APPOINTMENT_FOUND_FOR_COMPLETED: '尚未完成。',
        BLANK_START_END_DATE: '开始日期和结束日期不能为空',
        BLANK_START_DATE: '开始日期不能为空',
        BLANK_END_DATE: '结束日期不能为空',
        BLANK_NO_OF_DAYS: "天数不能为空。",
        ZERO_NO_OF_DAYS: "天数不能为0。",
        BLANK_SPECIFIC_DATE: "日期不能为空。",
        INVALID_DUEBY_END_DATE: '到期日期不能大于或等于重复结束日期。',
        SEND_EMAIL_PROGRESS_MSG: '电子邮件发送正在进行中，需要一些时间。',
        INVALID_DUEBY_START_DATE: '按日期到期日期不能大於或與開始日期相同。',
        SYNC_STARTED_MSG: '同步已启动。请刷新日历以查看新约会。',
        SYNC_STARTED_MSG_FOR_SETUP: '同步已启动。您的日历将很快更新。',
        ENABLE_SYNC_CAL: '请在进行同步之前在集成设置下启用同步日历选项。',
        PRIVATE_APPOINTMENT_ALERT:"这是私人约会；您无法打开它。",
        START_END_DATE_VALIDATE:'不能大于或等于',
        CONVERT_CONFIRMATION_MESSAGE: '不检查一项或多个任务。您是否希望在不检查这些任务的情况下继续转换操作？'
    },
    TASKS:{
        DELETE_CANCELLED_STATAUS_RESTRICT_MSG:'您不得取消任何纠正措施任务。',
    },
    HOME: {
        FILTER_ADD: '筛选器已成功添加',
        FILTER_NOT_FOUND: '您尚未配置任何筛选器',
        FILTER_DELETE_RECORD: '过滤器将被删除。是否确定？',
        FILTER_DELETE: '筛选器已成功删除',
        FILTER_ON_MAX_FIELD: '您已达到最大值。我们最多允许10个字段。',
        ASSIGNMENT_RULES_ON_MAX_FIELD: '您已达到最大现场条件。我们允许最多 20 个字段。',
        // not found in doc
        FILTER_NAME_BLANK: '请提供筛选器名称。',
        FILTER_NAME_MAX_LENGTH: '筛选器名称不能超过64个字符。',
        FILTER_CRITERIA_EMPTY: '请至少提供一个指标。',
        FILTER_SQL_ERROR_NO_VALUE: ' 不能为空。',
        FILTER_SQL_ERROR_INTEGER: ' 不是整数。',
        FILTER_SQL_ERROR_FLOAT: ' 不是浮点数。  ',
        FILTER_SQL_ERROE_ALREADY_EXISTS: '筛选器名称已存在!',
        FILTER_DELETE_MSG_TC: '以下自动触发营销活动 ',
        FILTER_DELETE_MSG_BC: ' 以下广播营销 ',
        FILTER_DELETE_MSG_AB: ' 以下A/B营销活动 ',
        FILTER_DELETE_MSG_AND: ' 和 ',
        FILTER_DELETE_MSG_BEING_USED: ' 无法删除, 因为它正在使用中 ',
        ADD_GROUP_SUCCESS: '组已成功添加。'
    },
    DOCUMENT_LIBRARY: {
        INVALID_UPLOAD_ERROR: '请选择图像文件!',
        CONFIRM_DELETE_MSG: '确实要删除图像吗？',
        OVER_MAX_SIZE: '所选文件太大，无法使用。允许的最大文件大小为 50 MB。',
        OVER_MAX_SIZE_IMAGE: '您选择的文件太大而无法使用。允许的最大文件大小为500 kB',
        INVALID_DOCUMENT_UPLOAD_ERROR: 'exe, 蝙蝠, sh 文件不允许上传!',
        DOCUMENT_FILE_EMPTY_ERROR: '请选择一个文档!',
        ASSETS_ALREADY_OPEN_ALERT: '已在操作模块下打开"库"选项卡。',
        DUPLICATE_IMAGE_NAME: '此名称已存在映像。请选择其他图像！',
        DUPLICATE_DOCUMENT_NAME: '此名称的文档已存在。请选择其他文档！',
        UPLOAD_IMAGE: '选择要上传的图像（一次最多五个文件）。',
        ASSETS_ALREADY_OPEN_ALERT_HUB: '在"集线器"模块下已打开"库"选项卡。',
        BLANK_TITLE_ERROR : '标题不能为空',
        BLANK_URL_ERROR : '链接不能为空',
        INVALID_URL_ERROR : '请提供有效的网址',
        CONFIRM_FILE_TYPE_CHANGE: {
            LN1: '您想退出当前部分吗？ 任何未保存的更改都将丢失。',
            LN2: '点击确定确认并退出。',
            LN3: '单击“取消”以保留在当前部分。',
        },
    },
    MERGE: {
        NOT_URL: "是无效的 url。",
        NOT_FLOAT: "不是浮点数。",
        NOT_INTEGER: "不是整数。",
        NOT_EMAIL: "是无效的电子邮件地址。",
        MERGE_TENANT_EMAIL_ALERT_MESSAGE: '您不能合并这些记录。由于您选择了要删除的付费帐户！',
    },
    SCHEDULE: {
        EMPTY: '请选择计划日期',
        CAMPAIGN_SCHEDULE: '此活动将运行在',
        CONFIRM: '单击OK以确认。',
        SMS_SCHEDULE: '此短信将继续发送'
    },
    RELATIONSHIP: {
        BLANK_RECORD: "请选择关系为",
        BLANK_PARENT_RELATION: "请至少选择一个父关系。",
        BLANK_CHILD_RELATION: "请至少选择一个子关系",
        NO_PARENT_RELATION: "你没有任何关系。请从ALL Setting下创建自定义下的关系",
        NO_PARENT_RELATION_FOR_USER: "对不起，没有可用的关系。请联系您的管理员",
        NO_RELATIONSHIP_SETUP: "您尚未设置任何关系类型,",
        PLACEHOLDERTEXT_COMPANY: "键入公司名称并按 enter 键",
        PLACEHOLDERTEXT_NAME: "键入全名并按 enter 键",
        PLACEHOLDERTEXT_EMAIL: "键入电子邮件并按 enter 键",
    },
    GROUP: {
        NO_TOUCH_CAMPAIGN: "未在任何自动触发营销活动中使用。",
        NO_BROADCAST_CAMPAIGN: "未在任何广播活动中使用。",
        NO_AB_CAMPAIGN: "未在任何 a/b 营销活动中使用。",
        NO_WEBFORM: "未在任何 Web 窗体中使用。",
        NO_LANDINGPAGE: "未在任何着登录页面中使用。",
        DELETE_MSG: '无法删除以下组',
        USED_IN_TOUCH_CAMPAIGN: '在以下自动触发营销活动中被使用',
        USED_IN_BROADCAST_CAMPAIGN: '在以下广播活动中被使用',
        USED_IN_AB_CAMPAIGN: '在以下A/B活动中被使用',
        USED_IN_WEBFORM: ' 在以下Web表单中被使用',
        USED_IN_LANDINGPAGE: '在以下登录页中被使用',
        SELECT_GROUP: '选择一个或多个组',
        NO_GROUP_AVAILABLE: '没有可用的组。点击',
        SELECT_RECORDS: '所选记录',
        ALL_RECORDS: '所有记录',
        SELECT_OPTIONS: '选择选项',
        ADD_NEW_GROUP: '添加新组',
        GROUP_DELETE_RECORD: '组将被删除。是否确定？',
        COMMON_DELETE_MSG:" 无法删除，因为它正在用于以下活动：",
    },
    LOGIN: {
        VALIDATE_EMPTY_USER_NAME: '请输入您的电子邮件和密码',
        VALIDATE_EMPTY_PASSWORD: '请输入您的密码',
        VALIDATE_USERNAME_PASSWORD: '您输入了不正确的电子邮件或密码',
        INVALID_RESPONSE: '无效的响应',
        LOGIN_COPYRIGHTS_TEXT_1: '版权',
        LOGIN_COPYRIGHTS_TEXT_2: 'Soffront 软件公司保留所有权利。',
        BEST_VIEWED: '最好在谷歌Chrome浏览器, 火狐浏览器和 Safari 中查看。'
    },
    AUDIT_LEADSCORE: {
        NO_AUDIT_DATA: '未找到审核跟踪记录',
        NO_SCORE_HISTORY: '没有要显示的历史记录。'
    },
    DETAIL_VIEW: {
        CHANGE_PRIMARY_CONTACT_ALERT_1: '您选择了',
        CHANGE_PRIMARY_CONTACT_ALERT_2: '作为主要联系人',
        CLICK_TO_CONFIRM: '单击OK以确认。',
        MAX_SELECTED_FIELD_LIMIT: '您达到了50个选定字段的最大限制',
        SELECT_ATLEAST_ONE_FIELD: '请至少选择一个字段 ',
        SELECT_ATLEAST_ONE_CHILD_OBJECT: '请至少选择一个子对象。',
        MAX_SELECTED_CHILD_OBJECT_LIMIT: '您达到了10个选定对象的最大限制。',
        NO_ASSOCIATED_GROUPS_FOUND: '没有可用的关联组',
        CONFIRM_UNSUBSCRIBE_RECORD: '记录将被取消订阅。是否确定？',
        BLANK_SPECIFIC_DATE: '日期不能為空。',
        CONFIGURE_CHILD_SETUP: '請配置子設置。',
        CHILD_SETUP_SELECT_ATLEAST_ONE_FIELD: '請至少選擇一個字段進行子設置。',
        EMAIL_FORMAT_MSG: " - 无效的电子邮件格式。",
        URL_FORMAT_MSG: " - 无效的URL格式。",
        FLOAT_FORMAT_MSG: "- 浮点格式无效。",
        INTEGER_FORMAT_MSG: "- 无效的整数格式。",
        ERROR_MSG: "你想在没有拯救变化的情况下离开吗？",
    },
    CHARTS: {
        NOTHING_TO_SHOW: '这个时间框架下没有值'
    },
    ADVANCE_SEARCH: {
        SEARCH_FIELDS_NOT_FOUND: '没有什么可搜索的。 请输入一个值。',
        MAX_ADD_FIELD_ALERT:'您不能搜索超过 10 个字段。',
        MIN_ADD_FIELD_ALERT:'搜索至少需要一个字段。',
        SELECT_FIELD_ALERT:'请在输入值之前选择字段。',
        ADVANCE_SEARCH: '高级搜索',
        EMPTY_DATE: '请输入起始日期。',
        INVALID_DATE_RANGE: '起始日期不能大于结束日期',
        EMPTY_FROM_TO_DATE: '请输入起始日期和结束日期。',
        EMPTY_FROM_DATE: '请输入日期。',
        EMPTY_TO_DATE: '请输入结束日期。'
    },
    PUSH: {
        PUSH_SUCCESS_MSG_1: '您已成功推送',
        PUSH_SUCCESS_MSG_2: '到选定的公司。',
        PUSH_SUCCESS_MSG_2_1: '將被推',
        PUSH_SUCCESS_MSG_3: '已向选定的公司发送电子邮件通知。',
        PUSH_SUCCESS_MSG_4: '您选择了超过20条记录。一次最多可以推送20个帐户。',
        PUSH_SUCCESS_MSG_4_1: '一次.',
        PUSH_SUCCESS_MSG_5: '请选择要推送的公司',
        PUSH_SUCCESS_MSG_6: '推送账户没有可用的联系人。',
        PUSH_SUCCESS_MSG_7: '成功推送。',
        PUSH_ALERT_MSG_8: '请至少选择一个公司来推送电子邮件模板',
        PUSH_ALERT_MSG_9: '请至少选择一个公司来推动自动营销活动',
        PUSH_ALERT_MSG_10: '请选择至少一家公司来推广广播活动',
        PUSH_SUCCESS_MSG_11: '到选定的公司。',
        PUSH_SUCCESS_MSG_11_2: '你確定嗎?',
        PUSH_SUCCESS_MSG_12: '已向选定公司发送电子邮件通知。',
        PUSH_SUCCESS_MSG_13: '请选择至少一个单位来推销销售线索',
        PUSH_SUCCESS_MSG_14: '请选择至少一个单位来推送工作流程',
        PUSH_FOLDER: '文件夹',
        FAIL_USE_ASSIGNMENT_RULE_FOR_SALES_LEADS: '推销销售线索不符合任何分配规则',
        PUSH_ALERT_MSG_12: '请至少选择一个单位来推送协议模板。',
        LANDING_PAGES: '登陆页面',
    },
    PROFILE_DETAILS: {
        UNMATCH_NEW_CONFIRM_PASSWORD: '确认的密码与新密码不匹配。',
        NO_NEW_PASSWORD: '输入有效密码并进行确认。',
        NO_CONFIRM_PASSWORD: '确认新密码。',
        VALIDATE_PROFILE_IMAGE: '选择一个图像文件。',
        PROFILE_IMAGE_UPLOAD: '已成功保存照片。',
        PROFILE_IMAGE_UPLOAD_FAILURE: '无法成功保存照片。',
        PROFILE_UPDATE: '照片已成功更新。',
        VALIDATE_PROFILE_IMAGE_SIZE: '您选择的照片太大, 无法使用。允许的最大文件大小为 500 KB。',
        PROFILE_IMAGE_ATTACHMENT_LOGO_TEXT: '您可以上传 jpg、gif 或 png 文件。',
        PROFILE_IMAGE_SIZE_TEXT: ' 允许的最大文件大小为 500 KB。',
        PROFILE_SIGNATURE_AUTOSAVED: '簽名已成功保存。'
    },
    CUSTOMIZATION: {
        LOCATION_VAL_NOT_MATCH: '位置值不匹配。',
        LOCATION_VAL_BLANK: '"位置" 字段中没有可用的值。'
    },
    IMPORT: {
        SELECT_FILE: '请选择一个文件',
        SELECT_GROUP: '请选择一个组',
        SELECT_WORKFLOW: '请选择工作流',
        SELECT_STATUS: '请选择状态',
        SELECT_SOURCE: '请选择源',
        SOURCR_NAME_EXISTS: '值已存在于列表中',
        SOURCR_NAME_BLANK: '源名称不能为空!',
        IMPORT_FILE_EXT: '请选择带有. csv 或. xls/xlsx 扩展名的文件',
        NO_RECORD_TO_IMPORT: '没有用于导入的记录!',
        NO_MAPPING: '没有映射可用于 ',
        NOT_ALL_MAPPED: '一个或多个列未映射到 CRM。是否继续, 而不导入这些列？',
        IMPORT_CONFIRM_MSG: '记录将被导入到',
        IMPORT_CONFIRM_MSG_1: '将导入录制',
        CONFIRM: '请确认。',
        SELECT_LINK_USING: '请选择链接使用选项',
        SELECT_LINK_WITH: '请选择用于链接的选项',
        SELECT_DUPLICATE_FIELD: '请至少选择一个选项为重复字段',
        SOURCE_ALERT_MSG_1: '请将帐户 "源" 字段映射到包含营销源的列。',
        SOURCE_ALERT_MSG_2: '您已从下拉列表以及 "映射" 部分选择了源。您可以在一个位置而不是两个位置选择源字段值。',
        NO_MAPPING_AVAILABLE: '没有可用的映射。',
        BLANK_MAP_NAME: '请提供映射名称!',
        VALID_MAP_NAME_LENGTH: '映射名称不应大于32个字符!',
        DUPLICATE_MAP_NAME: '映射名称已存在!',
        MAP_SAVE_SUCCESS: '您的字段映射已成功保存!',
        NO_PREVIOUS_MAPPING: '没有以前的映射存在!',
        MAP_NAME: '映射名称',
        DELETE_MAP_ALERT: '是否确实要删除',
        DELETE_MAP_SUCCESS: '已成功删除映射!',
        IMPORT_SUCCESS: '成功导入。',
        WARNING: '警告！',
        WARNING_TEXT: '请不要关闭此页, 直到导入完成。',
        IMPORTING_RECORDS: '正在导入您的记录',
        SUCCESS_IMPORT_COUNT: '记录已导入',
        FAIL_IMPORT_COUNT: '无法导入记录',
        FOUND: '我们发现',
        DUPLICATES: '重复记录',
        APPLY_WORKFLOW_TEXT: '已导入记录',
        APPLYING_WORKFLOW: '应用工作流',
        APPLIED_WORKFLOW: '已完成应用工作流',
        APPLIED_WORKFLOW_TO: '把工作流应用到',
        MAX_IMPORT_RECORDS_MSG_1: '有',
        MAX_IMPORT_RECORDS_MSG_2: '记录在文件中。目前，您一次可以导入10000条记录！',

        // not found in doc
        VERIFY_CONTACTS_MSG_1: '请验证这些联系人是否已为您提供了其',
        VERIFY_CONTACTS_MSG_2: '发送电子邮件的权限。我们不允许租赁或 ',
        VERIFY_CONTACTS_MSG_3: '购买的列表。这些联系人必须已经经过询问',
        VERIFY_CONTACTS_MSG_4: '愿意接收您的电子邮件或您的组织与他们有密切的关系。 ',
        VERIFY_CONTACTS_MSG_5: '您的组织。',

        FILE_TYPE_MSG: 'CSV 或 XLS/XLSX 文件的第一行必须包含字段名称。',
        DATE_FORMAT_MSG: '日期必须为 MM/DD/YYYY 格式 ',
        ACCOUNTS_AND_CONTACTS: '帐户和联系人',
        IMPORT_CSV_FILE: '请选择具有 .csv 扩展名的文件。',
        RECORDS_IMPORT_SUCCESS: '已成功导入记录。',
        GREEN_LIGHT_STATUS_ERROR: '已经进行了一次导入，因此，只有在当前导入过程完成后才能开始新的导入。',
        GREEN_LIGHT_STATUS_SUCCESS: '您当前的导入正在进行中。完成后，我们会向您发送确认电子邮件到相关加盟商的注册电子邮件。',
    },
    YOUTUBE_VIDEO: {
        EMPTY_VIDEO_LINK: '请为 YouTube 粘贴视频网址',
        INVALID_VIDEO_LINK: '您输入的 URL 无效',
        INSERT_TITLE: '从 YouTube 插入视频',
        VIDEO_LINK_PLACEHOLDER: '从 YouTube 粘贴视频网址'
    },
    WISTIA_VIDEO: {
        EMPTY_VIDEO_LINK: '请为 Wistia 粘贴视频网址',
        INVALID_VIDEO_LINK: '您输入的 URL 无效',
        INSERT_TITLE: '从 Wistia 插入视频',
        VIDEO_LINK_PLACEHOLDER: '从 Wistia 粘贴视频 URL'
    },
    INXPRESS_REPORT: {
        VALIDATE_EMPTY_FROM_DATE: '请选择开始日期.',
        VALIDATE_EMPTY_TO_DATE: '请选择结束日期.',
        VALIDATE_EMPTY_COUNTRY: '请选择国家/地区.',
        VALIDATE_EMPTY_FRANCHISE: '请选择特许经营商.',
        VALIDATE_EMPTY_WORKFLOW: '请选择工作流.',
        VALIDATE_EMPTY_SALES_REP: '请选择销售代表',
        VALIDATE_EMPTY_OBJECT_NAME: '请选择 "报告"。',
        NO_RECORD_EXPORT: '没有可用于导出的记录。',
        REPORT_NOT_READY_FOR_EXPORT: '正在加载报表, 请稍候, 现在无法导出。',
        BLANK_FROM_TO_DATE: '日期不能为空。',
        VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16: '請選擇一個國家以繼續。',
        VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT: '请选择一个工作流程以继续。',
        VALIDATE_EMPTY_REGION_INXPRESS_REPORT: '请选择一个继续的区域。',
        VALIDATE_EMPTY_DATE_RANGE: '请选择一个日期范围要继续。',
        VALIDATE_EMPTY_WORKFLOW_STAGES: '请选择一个工作流阶段继续。',
        VALIDATE_EMPTY_CHART_TYPE: '请选择要继续的图表类型。',
        VALIDATE_EMPTY_SHOW_TYPE: '请选择以继续进行的表演类型。',
        VALIDATE_EMPTY_ACTIVITY_TYPE: '请选择一个活动类型继续。',
    },

    OTHER_VIEW: {
        TENANT_MESSAGE: '您的订阅不包括 $ @@ # #PRODUCT_NAME @@ # $',
        USER_MESSAGE: '请与管理员联系, 向您的订阅添加 $ @@ # #PRODUCT_NAME @@ # $ $',
        AFTER_SUBSCRIPTION_VENDASTA_NOT_CONFIGURED: '我们还没有为您的帐户设置 $ @@ # #PRODUCT_NAME @@ # $ $。我们会尽快通知您。'
    },

    MY_INTEGRATION: {
        SAVE_SUCCESS: '您的设置已成功保存。',
        APPEND_INFO: '将公司和联系信息追加到从 Soffront 添加的约会',
        DISCONNECT_CONNECTION: '连接将被删除。是否确定？',
        SAVE_FAIL: '您的设置未保存。',
        NO_CALENDAR: '未选择日历。',
        NO_SYNC_CHECKED: '您尚未选择任何同步选项。您可以选择同步您的电子邮件、日历或两者。',
        SYNC_EMAIL_INFO_1: '选择此选项可自动将您的 G suite/Office 365 邮箱中的电子邮件保存到 CRM 中。',
        SYNC_EMAIL_INFO_2: '电子邮件将根据匹配的帐户或联系人记录进行保存。',
        SYNC_EMAIL_INFO_3: '电子邮件将每5分钟同步一次。',
        SYNC_CONTACT_INFO_1: '选择此选项可自动将 CRM 联系人添加到 G suite/办公室365。',
        SYNC_CONTACT_INFO_2: '您对 CRM 中的联系人所做的任何更改都将在 G suite/Office 365 中自动更新。',
        SYNC_CONTACT_INFO_3: '联系人将每5分钟同步一次。',
        SYNC_CALENDAR_INFO_1: '选择此选项可自动同步您的 Soffront 日历与您的 G suite/Office 365 帐户中的日历之间的约会。',
        SYNC_CALENDAR_INFO_2: '如果您有多个 G suite/办公室366日历, 您将需要选择一个作为要同步的对象。',
        SYNC_CALENDAR_INFO_3: '日历将每5分钟同步一次。',
        NO_CALENDAR_MESSAGE: '我们正在等待获取您的日历的详细信息。请关闭此屏幕，然后在几分钟后返回。如果您仍然看到此消息，请断开连接并重新连接您的帐户。',
        NO_PERMISSION: '“我的集成”在您当前的方案中不可用。请订阅相应的使用方案，或者发邮件给 smbsupport@soffront 的联系支持。',
        SELECTED_CALENDAR_NOT_FOUND: '我们找不到您之前选择的日历。请选择另一个并保存。',
        INVALID_ACCOUNT_SYNC_STATE1:'你的',
        INVALID_ACCOUNT_SYNC_STATE2:'连接不再有效。请断开连接并重新连接。',
        SEND_SALES_EMAILS_VALIDATION1: '为了能够使用发送电子邮件',
        SEND_SALES_EMAILS_VALIDATION2: '您将需要使用',
        SEND_SALES_EMAILS_VALIDATION3: '请断开并重新连接使用',    
    },

    DOCUSIGN: {
        PROVIDE_USERNAME: '请提供有效的用户名。',
        PROVIDE_PASSWORD: '请提供有效的密码。',
        PROVIDE_INTEGRATOR_KEY: '请提供有效的集成密钥。',
        SUCCESSFULLY_SAVED: '您已成功配置您的 DocuSign 帐户。',
        FAILED_TO_SAVED: '无法配置您的 DocuSign 帐户。',
        DOCUSIGN_SETUP_TITLE_1: '选择文档并将其发送 ',
        DOCUSIGN_SETUP_TITLE_2: '至 ',
        DOCUSIGN_SETUP_TITLE_3: ' 用于签名',
        PROVIDE_RECIPIENT_NAME: '请提供收件人全名',
        PROVIDE_RECIPIENT_EMAIL: '请提供收件人电子邮件',
        PLEASE_SELECT_TEMPLATE: '请选择模板。',
        NO_TEMPLATES_FOUND: '找不到模板。',
        UNABLE_TO_SEND: "由于您的 DocuSign 帐户已过期, 无法发送模板。",
        TEMPLATE_NAME_LENTH: '模板名称不得超过65个字符。',
        PLEASE_SELECT_CONTACT: '请至少选择一个联系人。',
        DOCUSIGN_CONTACT_MESSAGE_1: '没有可用于以下联系人的电子邮件: ',
        DOCUSIGN_CONTACT_MESSAGE_2: '电子邮件不会发送给这些联系人。',
        DOCUSIGN_CONNECTED_MESSAGE_1: '对不起，您无权执行此操作。请联系Soffront。',
    },

    TWILIO: {
        CALL_OVER_SECURED_NETWORK: '只能通过安全网络进行呼叫',
        SAME_TO_AND_FROM_NUMBER: '发起人号码与接收人号码相同，无法启动呼叫。',
        CALLING_SUPPORT: '目前您所在的国家/地区不支持通话。',
        OUTBOUND_PHONE_NOT_VERIFIED: '您的外拨电话号码未经验证。拨打电话之前，请先进行验证。',
        CALL_RECORDING_SAVE: '已成功保存。请注销并重新登录以查看更改。',
        CALL_RECORDING_LAWS_AND_REGULATION_ALERT:'请确保您的通话录音做法符合适用的法律和法规。',
        NOTE_SAVE: '备注已成功保存',
        PHONE_LOW_BALANCE1: '您现在的手机余额为 ',
        PHONE_LOW_BALANCE2: ' 分钟。',
        PHONE_LOW_BALANCE3: '您需要超过5分钟的通话时间。',

        PHONE_BLANK_MESSAGE: '请输入一个电话号码。',
        PHONE_ALREADY_CONFIGURE: '此电话号码已配置。',
        DELETE_PHONE_NUMBER: '是否确实要删除此号码？',
        VERIFY_PHONE_NUMBER: '请在添加其他号码之前验证。',
        PHONE_LIMIT: '您不能添加超过3电话号码。',
        VERIFICATION_CODE: '在提示符下, 请输入验证码。',
        VERIFICATION_MESSAGE_1: '我们会打电话给',
        VERIFICATION_MESSAGE_2: '验证号码。',
        FIELDS_ADD_LIMIT:'您不能添加超過 3 個字段。',
        SETUP_UPDATED_SUCCESSFULLY:'更新成功。',
        SETUP_UPDATED_FIELDS:'更新失敗。'
    },
    CALL_SCRIPT: {
        BLANK_NAME: '请输入此脚本的名称。',
        BLANK_DETAILS: '请输入脚本详细信息。',
        SAVE_SUCCESS: '您已成功保存此脚本。',
        UPDATE: '您已成功更新此脚本。',
        DELETE: '您已成功删除此脚本。',
        NO_RECORD: '没有可用的记录',
        'DUPLICATE_SCRIPT_NAME': '此脚本名称已存在。不允许重复的脚本名称。',
        'EMPTY_SCRIPT_NAME_DETAILS': '脚本名称和脚本详细信息不能为空。',
        'RECORD_NOT_FOUND_UPDATE': '未找到要更新的记录。',
        DELETE_CONFIRMATION: '是否确实要删除此脚本？'
    },
    SMS_INTEGRATION: {
        PURCHASED_NUMBER: '您已成功购买了该号码',
        SELECT_USER: '请选择至少一名销售代表以继续操作。',
        ENTER_ZIP_OR_AREA: '请输入邮政编码或区号。',
        ENTER_AREA: '请输入区号。',
        ENTER_VALID_ZIP: '请输入有效的邮政编码。',
        ENTER_VALID_AREA_CODE: '请输入有效区号。',
        NO_AVAILABLE_PHONE_NUMBERS: '没有电话号码可用于输入的邮政编码。',
        NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP: '输入的区号没有可用的电话号码。',
        REMOVE_NUMBER_CONFIRMATION: '您确定要删除此号码吗？',
        SMS_SUPPORT: '您所在的国家/地区不支持发送短信。',
        SELECT_NUMBER: '请选择一个号码.',
        SELECT_AT_LEAST_ONE_NUMBER:'请至少选择一个号码进行导入',
        TWILIO_NUMBER_IMPORTED_SUCCESS:'您的 twilio 号码已成功导入',
        TWILIO_NUMBER_IMPORTED_ERROR:'无法导入您的 twilio 号码',
        PHONE_NUMBER_CANNOT_BE_PURCHASED:'电话号码无法购买。 请联系 Soffront 支持人员了解更多详细信息。',
        ENTER_SMS_TEXT: '请输入 sms 文本。',
        SMS_LOW_BALANCE_1: '您现在的短信余额为 ',
        SMS_LOW_BALANCE_2: '为发送短信您需要购买短信额度。',
        TWILIO_NUMBER_PURCHASE_MSG_1: '您的帐户将被计账',
        TWILIO_NUMBER_PURCHASE_MSG_2: '月',
        TWILIO_NUMBER_PURCHASE_MSG_3: '这个电话号码。您可以随时从您的帐户中删除该号码以停止计费。',
        SMS_TOOLTIP_MESSAGE: '按邮政编码或区号查找',
        SMS_TOOLTIP_MESSAGE_AREA_CODE: '按地区代码查找',
        INSERT_IMAGE_FILE: '请只选择jpg，jpeg，gif或png文件',
        BATCH_SEND_SMS_MESSAGE: '短信成功发送',
        'INSUFFICIENT_BALANCE': '短信余额不足',
        'BATCH_SMS_SENT': '短信成功发送',
        'BATCH_SMS_FAILS': '无法发送短信',
        NO_FROM_NUMBER_MESSAGE: '您需要购买电话号码才能发送短信。请从“集成”菜单中购买电话号码。',
        MAX_ATTACHMENT_FILE_FOR_SMS: '允许最多10个附件。',
        'NO_INPUT_STREAM_FOUND': '找不到输入流',
        'NO_ATTACHMENT_FOUND': '找不到附件',
        'FILE_SIZE_ERROR': '文件大小应小于5MB。',
        IMAGE_SELECT: '选择图像',
        MERGE_FIELD: '选择合并字段',
        DUPLICATE_FILE_NAME: '此文件已存在。请选择其他文件。',
        RECORD_UNSUBSCRIBE_ALERT: '所选记录取消订阅文本。您无法发送短信。',
        UNSUBSCRIBE_LIST_CONFIRM_MSG: '已取消选定的记录已从文本中取消订阅。短信无法发送到未订阅的记录。按“确定”继续。',
        UNSUBSCRIBE_LIST_CONFIRM_MSG_1: '已选择的记录已从文本中取消订阅。短信无法发送到未订阅的记录。按“确定”继续。',
        'BATCH_SMS_SCHEDULE': '短信已成功安排',
        'BATCH_SMS_SCHEDULE_FAIL': '无法安排短信',
        BATCH_SEND_SMS_MSG_1: '出',
        BATCH_SEND_SMS_MSG_2: '您已选择，仅',
        BATCH_SEND_SMS_MSG_3: '已选择接收您的短信，并且有手机号。和属于',
        BATCH_SEND_SMS_MSG_4: '.我们将只发送短信到这些',
        BATCH_SEND_SMS_MSG_5: '按"确定"继续。',
        SINGLE_SEND_SMS_MSG_1: '请检查是否选中',
        SINGLE_SEND_SMS_MSG_2: '已选择接收您的短信或具有移动电话。或属于',
        RESCHEDULE_SUCCESS: '已成功重新安排 SMS',
        AUTOMATION_SMS_ADD_MSG: '您已成功保存此短信',
        WORKFLOW_STATUS_MSG: '您有一个或多个活动的工作流程正在使用此号码发送短信。在删除该号码之前，请更新这些工作流程以使用其他号码。',
        SCHEDULED_STATUS_MSG: '您有一个或多个正在使用此号码的分段短信。单击“确定”取消所有暂存的短信并删除号码。',
        REMOVE_SUCCESS_MSG: '该号码已成功删除。',
        NUMBER_SAVED_SUCCESS_MSG: '号码已成功保存。',
        NO_LINKED_MOBILE_NUMBER_MSG:'关联记录没有手机号码。您无法发送短信。',
        NUMBER_INVALID_MSG:"聯絡人手機號碼不正確！",
        CANNOT_DELETE_UNITS_NUMBER: '您不能删除此号码，因为此号码已分配给某个单元。',
    },
    EVENTBRITE: {
        NO_ORGANISATION_SELECTED: '请至少选择一个组织',
        NO_MAPPING_FOUND: 'CRM Contact和Eventbrite字段没有映射'
    },
    QUICKBOOKS: {
        NO_MAPPING_FOUND: '没有用于 CRM 帐户和Quick Books客户字段的映射。'
    },
    XERO: {
        SELECT_ONE_INTEGRATION_ITEM_AND_TYPE: '请选择要集成的项目和集成类型。',
        SELECT_ONE_INTEGRATION_ITEM: '请选择要集成的项目。',
        SELECT_INTEGRATION_TYPE: '请选择集成类型。',
    },
    REPORT_SCHEDULE_DIALOG: {
        START_DATE_BLANK: '开始日期不能为空。',
        INVALID_END_DATE: '开始日期不能大于或与结束日期相同。',
        DAY_CHECKBOX_BLANK: '至少一天检查一次。',
        SEND_TO_BLANK: '至少选择一个用户。',
        SAVE_SUCCESS: '已成功保存。'
    },
    DOCUMENT_PUSH: {
        ALERT_MSG_1: '请选择至少一家公司来推送文件',
        DOCUMENTS: '（多个）文档',
        FAIL: '无法推动'
    },
    OPERATIONS_REPORT: {
        NO_CUSTOM_REPORT_MESSAGE: '你没有任何自定义报告。'
    },
    PINNED_NOTES: {
        CONFIRM_MESSAGE: '这将取代现有的固定音符。单击“确定”继续，或单击“取消”放弃。'
    },
    ASSIGNMENT_RULES: {
        NO_ASSIGNMENT_RULES: '没有可用的规则。',
        NO_NAME: '请提供分配规则名称。',
        NAME_MAX_LENGTH: '分配规则名称不能超过64个字符。',
        NO_CRITERIA: '请至少提供一项标准。',
        NO_USER: '请提供至少一个用户。',
        RULE_SAVE_SUCCESS: '已成功保存分配规则。',
        RULE_NAME_EXISTS: '名称已存在!',
        ACTIVE_RULE_EXCEEDS: '您已有 60 条有效的分配规则',
        SELECT_UNIT_MAXIMUM_FIELD: '您只能选择一个单位',
        SELECT_A_UNIT_WORKFLOW: '请选择单位工作流程。',
        NO_UNIT_USER: '请至少提供一个单元。'
    },
    SETTINGS: {
        EMPTY_NAME: '请输入',
        DUPLICATES_SOURCE_NAME: '源名称已存在！',
        CORPORATE_CURRENCY: '所选货币已成功更新。',
        INVALID_CURRENCY_NAME: '请选择有效的公司货币名称',
        SYSTEM_NOTE_TYPE_EDIT_ALERT: '您无法编辑此音符类型。',
        SYSTEM_NOTE_TYPE_DELETE_ALERT: '您无法删除此笔记类型。',
        SYSTEM_CAMPAIGN_TYPE_DELETE_ALERT: '您无法删除此广告系列类型。',
        CORPORATE_CURRENCY_ALERT: '请选择公司货币。',
        CORPORATE_CURRENCY_CONFIRM: '选定的货币将被保存。你确定吗？',
        'CORPORATECURRENCY': '选择公司货币',
        'DUPLICATE_NOTE_TYPES': '此注释类型已存在。重复注意不允许创建类型。',
        'INCORRECT_RECORD_ID': '不正确的记录Id.Update失败。',
        'RECORD_DOES_NOT_EXISTS': '记录不存在。删除操作失败。',
        'RECORD_CANNOT_DELETED': '系统或默认注释类型无法删除。',
        'DUPLICATE_CAMPAIGN_TYPES': '系统或默认注释类型无法删除。',
        'DUPLICATE_RELATIONS': '关系名称已经存在！',
        RELATION_LINK_INFO_ALERT_MSG: '有记录遵循这种关系。首先取消关联此关系的相应记录，然后您就可以删除此关系。'
    },
    RECYCLE_BIN: {
        SELECT_WORKFLOW: '请选择工作流程',
        SELECT_STAGE: '请选择一个舞台',
        NOTE: '注意：不会恢复原始记录的链接记录。',
        RESTORE: '记录还原成功'
    },
    UNSUBSCRIBE: {
        SELECTED_RECORD: '此记录将取消订阅 ',
        ARE_YOU_SURE: '。你确定吗？',
        SELECTED_BOTH_RECORD: '此记录将取消订阅电子邮件和文本。你确定吗？',
        'EMAILS_UNSUBSCRIBE': ' 将取消订阅电子邮件。你确定吗？',
        'TEXTS_UNSUBSCRIBE': ' 将取消订阅文本。你确定吗？',
        'BOTHS_UNSUBSCRIBE': ' 将取消订阅电子邮件和文本。你确定吗？',
    },
    WORKFLOWS: {
        STATUS_CHANGED: '状态已更改为 ',
        'SAME_STATUS': '记录已经在',
        'FAILED_TO_CHANGE_WORKFLOW_STATUS': '无法更改工作流程状态。',
        ONLY_ONE_ACTIVE_WORKFLOW: '您无法使此工作流程无效。这是唯一可用的活动工作流程。表必须至少有一个活动工作流。',
        ONLY_ONE_ACTIVE_WORKFLOW_CANNOT_DELETE: '您不能删除此工作流。这是唯一可用的活动工作流。表必须至少有一个活动工作流。',
        DELETED_WORKFLOW: '工作流不存在。选择"确定"以选择新工作流。',
        STATUS_BLANK_ALERT:'状态不能为空白',
        SALES_REP_BLANK_ALERT : '销售代表不能为空',

    },
    CHANGE_LOGO: {
        SELECT_IMAGE_TO_UPLOAD: '选择要更改徽标的图像',
        LOGO_IMAGE_UPLOAD_FAILURE: '公司徽标无法保存。',
        LOGO_IMAGE_UPLOAD: '公司徽标已成功保存。',
        VALIDATE_LOGO_IMAGE: '选择图像文件。',
        VALIDATE_LOGO_IMAGE_SIZE: '您选择的照片太大而无法使用。允许的最大文件大小为500 KB。',
        LOGO_IMAGE_ATTACHMENT_LOGO_TEXT: '您可以上传jpg，gif或png文件。',
        LOGO_IMAGE_SIZE_TEXT: '允许的最大文件大小为500 KB。',
        WEBSITE_URL: '网站',
        VALIDATE_LOGO_WEBSITE_URL: '您是否要在没有任何有效网址的情况下上传徽标？'
    },

    COUPONS: {
        CREATE_COUPON_NAME: '为优惠券创建内部名称',
        CREATE_DISCOUNT_TYPE: '您想要创建什么类型的折扣？',
        FIXED_AMOUNT: '定额，固定金额',
        PERCENTAGE: '百分比',
        PREFIX_COUPON_CODE: '指定优惠券代码的前缀',
        CAN_CLAIM_MORE_THAN_ONCE: '我可以使用同一帐户多次使用它吗？',
        CALIM_LIMIT_ONE: '只有一次',
        CLAIM_LIMIT_UNLIMITED: '无限',
        WHYEN_CLAIM_CODE: '优惠券到期日',
        CLAIM_ANYTIME: '永不过期',
        CLAIM_BEFORE_DATE: '截止日期',
        SAVE_SUCCESSFULLY: '已成功保存优惠券',
        ACTIVE_SUCCESSFULL: '状态已更改为“活动”。',
        INACTIVE_SUCCESSFULL: '状态已更改为“非活动”。',
        DELETE_CONFIRMATION: '优惠券将被删除。你确定吗？',
        INVALID: '是无效的',
        DUPLICATE_COUPONS: '优惠券名称已存在。',
        NEGATIVE_COUPONS: '不能否定。',
        SPECIFY_DATE: '請指定日期。',
        PODUCT_SERVICE: '产品/服务',
    },
    NOTIFICATION_REMINDERS: {
        SAVE_SUCCESS: '已成功添加提醒',
        INVALID_DAY: '输入有效日期',
        DUPLICATE_REMINDER: '这是重复的提醒,请更改',
        INVALID_DATE_FIELD: '请选择"日期"字段',
        DELETE_SUCCESS: '已成功更新提醒',
        UPDATE_SUCCESS: '已成功更新提醒',
    },
    EMAIL_INTEGRATION: {
        DUPLICATE_EMAIL: '此电子邮件地址已存在！',
        EMAIL_SAVE_SUCCESS: '已成功保存电子邮件地址',
        SEND_VERIFICATION_EMAIL_SUCCESS_1: '我们已经发送了验证电子邮件。请检查您的收件箱，然后单击 ',
        SEND_VERIFICATION_EMAIL_SUCCESS_2: '验证此电子邮件地址。',
        SEND_VERIFICATION_EMAIL_SUCCESS_3: '然后回来，单击刷新图标以查看您的电子邮件地址的状态。',
        EMAIL_INTEGRATION_VERIFIED: '此电子邮件地址已验证！',
        CONFIRM_DELETE_EMAIL: '选定的电子邮件地址将被删除。你确定吗？',
        INVALID_EMAIL: '无效的电子邮件地址',
        EMAIL_CANT_BLANK: '电子邮件地址不能为空!',
        EDIT_EMAIL_SAVE_SUCCESS: '电子邮件保存成功',
    },

    NEWS: {
        SAVE_ERROR: '无法保存。',
        VALIDATE_CATEGORY: '类别不能为空。',
        VALIDATE_STATUS: '状态不能为空。',
        VALIDATE_TITLE: '标题不能为空。',
        VALIDATE_SUMMARY: '总结不能空白。',
        VALIDATE_EXTERNAL_LINK: '外部链接不能为空。',
        VALIDATE_DETAILS: '细节不能为空。',
        CATEGORY_NAME_EXISTS: '值已存在于List中。',
        DUPLICATE_TITLE: "这个标题已经存在！",
    },
    USEFUL_LINKS: {
        CATEGORY: '类别',
        LINK: '链接',
        TITLE: '标题',
        VALID_CATEGORY: '请选择有效的类别',
        DUPLICATE_CATEGORY: '这是重复的类别，请更改',
        DUPLICATE_TITLE: '这是同一类别中的重复标题，请更改。',
        CONFIRM_DELETE_CATEGORY: '类别将被删除。是否确定？',
        CONFIRM_DELETE_LINK: '链接将被删除。是否确定？',
        VALID_URL: '请输入有效的 URL。',
        EXISTING_LINKS_CATGORY: '此类别下有现有链接。 ',
        PLEASE_MOVE_LINKS: '请将链接移动到',
        CLICK_TO_CONFIRM: ' 单击"确定"进行确认。',
    },
    CASE: {
        CASE_CLOSED_SUCCESS: '已成功关闭案例'
    },
    ORTHOTIC_REPORT: {
        VALIDATE_EMPTY_PRODUCT: '請選擇一個產品。'
    },
    USER_REPORT: {
        REPORT_DELETE_RECORD: '報告將被刪除。你確定嗎？',
        REPORT_DELETE: '报告已成功删除',
        INVALID_START_END_DATE: '開始日期不能大於結束日期。'
    },
    AUTO_FILL_VALUES: {
        CHNAGE_PARENT_OBJECT: '您真的想更改父对象吗？',
        CHANGE_CHILD_OBJECT: '您真的想更改子对象吗？',
        SELECT_PARENT_OBJECT: '选择父对象。',
        SELECT_CHILD_OBJECT: '选择子对象。',
        NO_MAPPING_FIELDS: '之间没有可用的映射字段',
        NO_MAPPING_FIELDS_AND: '和',
        CHILD_FIELD_SIZESHOULD_GREATER: '子字段大小应大于父字段大小。',
        MAPPING_FROM: '映射从 ',
        MAPPING_TO: ' 自 ',
        NO_AVAILBLE_FIELDS_FOR_MAPPING: '。没有用于默认映射的可用公共字段。',
        NO_AVAILABLE_PARENT_CHILD_MAPPING: '没有可用的父子字段映射。',
        FIELD_MAPPING_DONE_SUCCESS: '已成功完成字段映射。',
        MAP_FIELDS_TO_AUTOFILL: '将字段映射到自动填充 ',
        MAP_FIELDS_TO_AUTOFILL_FROM: ' 从 ',
        SELECT_PARENT_FIELD: '选择父字段。',
        SELECT_CHILD_FIELD: '选择子字段。',
        MAPPING_NOT_POSSIBLE: ' 是不可能的。.'
    },
    LANDING_PAGE: {
        INVALID_NAME: '请输入登录页面名称。',
        INVALID_URL_WARNING: '请输入网址名称。',
        SAVE: '登陆页面已成功保存。',
        UPDATE: '登陆页面已成功更新。',
        INVALID_FORM_BLOCK_EDIT_MSG: '当前活动块没有要编辑的表单字段，请选择表单字段进行编辑。',
        DUPLICATE_NAME: '该名称的登录页面已存在。请输入其他名称，然后重试。',
        DUPLICATE_URL: '该网址已存在目标网页。请输入其他名称，然后重试。',
        INPUT_DATA_LOSS_MSG: '所有输入的数据将被删除。你确定吗？',
        EMAIL_REQUIRED_MSG: '电子邮件为必填项。',
        ATLEAST_ONE_BLOCK_ADD_MSG: '请添加至少一个设计模块以保存此页面。',
        PREVIEW_ALERT_MSG: '请保存，然后预览登录页面。',
        OPEN_URL_ALERT_MSG: '请先保存更改，然后再执行操作。',
        COPY_TO_CLIPBOARD: '着陆页链接已复制到剪贴板。',
        FORM_ADDED_MSG: '表格已成功添加。',
        FORM_UPDATED_MSG: '表格更新成功。',
        INVALID_URL: '无效的网址。',
        LABEL_FIELD_CANNOT_BLANK: '标签字段不能为空。',
        FIELD_IS_ALREADY_ADDED: '字段已添加。',
        MINIMUM_ONE_FIELD: '至少需要一个字段。',
        NO_MORE_FIELD_AVAILABEL: '没有更多字段可用。'
    },
    DOWNLOAD_LEADS: {
        SELECT_CRITERIA: '请至少选择一个条件。',
        SELECT_WORKFLOW: '请选择工作流。',
        SELECT_GROUP: '请选择一个组。',
        SELECT_STATUS: '请选择状态。',
        SELECT_SALESREP: '请选择销售代表。',
        LEAD_EXIST_MSG_1: '您的帐户中已有这些潜在顾客 - ',
        LEAD_EXIST_MSG_2: '.单击"确定"以停止下载并从所选内容中删除这些潜在顾客。'
    },
    SCHEDULER: {
        BLANK_APPOINTMENT_NAME: '请输入预约名称。',
        BLANK_LOCATION: '请输入位置。',
        SAVE_SUCCESS: '计划程序设置已成功保存。',
        SAVE_FAIL: '无法保存计划程序设置。',
        NO_SCHEDULER: '请在使用计划程序链接之前创建约会计划程序。',
        VALIDATE_TIME: '开始时间不能大于或与结束时间相同。',
        NO_START_TIME: '请选择开始时间。',
        NO_END_TIME: '请选择结束时间。',
        SCHEDULER_LINK_COPIED_SUCCESS: '计划程序链接复制到剪贴板。',
        BLANK_SUBMIT_BUTTON: '按钮标签不能为空。',
        CALENDAR_SYNC_MSG: '您的 CRM 日历未连接到您的 GSuite 或 Office 365 卡伦达。',
        CALENDAR_SYNC_LINK: '单击此处进行设置。',
        SCHEDULER_UNSAVE_msg: '你想离开这张表格吗？您所做的更改将不会保存。',
    },
    RELATION_REPORT: {
        RANGE_FIELD_BLANK: '請選擇一個日期字段。',
        ROW_FIELD_BLANK: '請選擇一個行字段。',
        COL_FIELD_BLANK: '請選擇一個列字段。',
        REPORT_NAME_BLANK: '請輸入報告名稱。',
        REPORT_SAVE_SUCCESS: '報告已成功保存。',
        DUE_BY_BLANK: '請選擇一個日期範圍。',
        NO_ROW_FIELD_VALUE: '請為行標籤選擇一個值。',
        NO_COLUMN_VALUE: '請為列標題選擇一個值。',
        SAME_ROW_COLUMN_FIELD: '行字段和列字段不能相同。',
        INVALID_END_DATE: '開始日期不能大於結束日期。',
        'Report Name already exists': '報告名稱已存在。',
        VALID_REPORT_NAME: '请输入有效的报告名称'
    },
    PAYMENTS: {
        NOT_ADDED_QB: '报价/发票尚未添加到快速手册中！',
        NO_LINKED_AVAILABLE: '没有链接的报价/发票可用！',
        PAYMENT_VALUE_ALERT_MSG: '付款值不能为零。',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: '您有一个打开的报价/发票选项卡。 请在打开或添加新报价/发票之前关闭该选项卡。',
        AMOUNT_EXCEED_BALANCE_DUE: '您輸入的付款超過此發票的餘額。單擊"確定"以繼續付款。按一下「取消」以更改付款金額。',

    },
    PRODUCTS: {
        DUPLICATE_PRODUCT: '產品名稱已存在。',
        ADDED_TO_QUICKBOOK: '已成功添加到快速图书。',
        PULLED_FROM_QUICKBOOKS: '已成功从 Quickbook 中拉出。',
        PRODUCT_PRICE_VALUE_ALERT_MSG: '价格值不能为零。',
        PRODUCT_NAME_COULDNOT_CONTAIN_COLON_MSG: '不能包含冒号（:)。',
        NEGATIVE_PRICE: '库存类型的价格不能为负值。'
    },
    COMPANY_PROFILE: {
        BLANK_BUSINESS_NAME: '请输入企业名称。',
        BLANK_BUSINESS_ADDRESS: '请输入营业地址。',
        BLANK_BUSINESS_PHONE: '请输入商务电话。'
    },
    EXPORT_SETTINGS: {
        CONSIDERATION: '考虑:',
        CONSIDERATION_MSG1: '我们支持EXCEL和CSV格式的文件。',
        CONSIDERATION_MSG2: 'Excel中的一个单元格最多可以包含32,767个字符，因此在导出的excel文件中，我们为一个单元格最多显示32,767个字符，并排除其余字符。',
        NO_RECORDS_FOUND: '没有找到记录！',
        FILE_NAME_IS_EMPTY: '文件名为空。',
        PLEASE_SELECT_THE_FILE_TYPE: '请选择文件类型。',
        PLEASE_SELECT_THE_PARENT_OBJECT: '请选择父对象。',
        PLEASE_SELECT_THE_QUERY_NAME: '请选择查询名称。',
        PLEASE_SELECT_THE_PARENT_FIELDS: '请选择父字段。'
    },
    DATA_BASE: {
        FAILURE_MSG: '保存失败。',
        SUCCESS_MSG: '您的设置已成功保存。请注销并重新登录以查看更改。',
        VALIDATION_MSG: '请选择至少一个子对象 ',
        VALIDATION_MSG1: "您已达到15个所选对象的最大限制。",
        SUCCESS_MSG1: '您的设置已成功保存。',
    },
    UNITS: {
        BLANK_FIRST_NAME: '請輸入名字。',
        BLANK_LAST_NAME: '請輸入姓氏。',
        BLANK_PASSWORD: '請輸入密碼。',
        UNMATCH_RETYPE_PASSWORD: '重新輸入密碼與密碼不匹配。',
        BLANK_RETYPE_PASSWORD: '請輸入重新輸入的密碼。',
        BLANK_EMAIL: '請輸入電子郵件。',
        BLANK_PHONE: '請輸入電話。',
        BLANK_COMPANY: '請輸入公司。',
        UNIT_SAVED: '保存成功。',
        UNIT_NOT_SAVED: '無法保存單位。',
        DUPLICATE_EMAIL: '您已經有具有相同電子郵件ID的記錄。',
        DUPLICATE_COMPANY: '公司名稱已存在。',
        SUBSCRIPTION_NOT_VALID: '订阅对所选单元无效。请联系Soffront支持以获取更多详细信息。',
        NO_UNIT_TASKS: '没有可供单位所有者使用的任务。',
        NO_ACCOUNT_TASKS: '该帐户没有可用的任务。',
        BLANK_FAMILY_INDUSTRY: '请选择一个家族行业',
        NO_FAMILY_INDUSTRY: '在添加新单位之前，您需要一个基本模板。请联系 BrandWide 支持以设置基本模板。',
        BLANK_PLAN_NAME: '请选择计划名称。',
        NO_AVAILABLE_PLANS: '没有可以为此单元注册的可用计划。',
        NO_BRANCH_WORKFLOW: '没有可用的分支工作流程。',
        PLEASE_SELECT_TYPE: '请选择类型以继续。',
        NO_BASE_LOGIN: '基地不存在或未配置！修复问题并重试。',
    },
    SHARE: {
        SHARE_SUCCESS_MSG_1: '共享信息已成功更新。',
        SHARE_SUCCESS_MSG_SL: '所选安全级别的共享信息已成功更新（如果有）。',
        SHARE_SUCCESS_MSG_2: '电子邮件通知将发送到新选择的单位（如果有）。',
        SHARE_SUCCESS_MSG_3: '电子邮件通知将发送到新选择的单位（如果有）。',
        SHARE_SUCCESS_MSG_4: '是否确定？',
        SHARE_SUCCESS_MSG_5: '将共享给所选单位。',
        SHARE_SUCCESS_MSG_6: '将共享到所选单位。',
        SHARE_FAILED_MSG: '无法共享。',
        SHARE_SELECT_CORPORATE_DOCUMENT: '请至少选择一名企业用户来共享文档。',
        SHARE_SELECT_CORPORATE_IMAGE: '请至少选择一位企业用户来共享图像。',
        SHARE_SELECT_UNIT_DOCUMENT: '请选择至少一个单位来共享文档。',
        SHARE_SELECT_UNIT_IMAGE: '请选择至少一个要共享图像的单位。',
        NO_CONNECTED_CLIENT: '没有可用于共享文档的已连接客户端。',
        
    },
    SECURITY_LEVEL: {
        ERROR_MSG_1: '糟糕！出了些问题。请稍后再试。',
        ERROR_MSG_2: '说明不能为空。',
        SUCCESS_MSG_1: '您的设置已成功保存。',
    },
    ROYALTY_REPORT: {
        BLANK_ROYALTY_PERCENT: '请输入版税百分比。',
        REPORT_SAVE_SUCCESS: '已成功保存报告。',
        SETUP_SAVE_SUCCESS: '成功保存版税设置。'
    },
    MENU: {
        ATLEAST_ONE_MODULE_ACTIVE: '抱歉，至少一个模块应该处于活动状态。',
        ATLEAST_ONE_MODULE_ITEM_ACTIVE: '抱歉，至少一个模块项目应该处于活动状态。',
        MODULE_LABEl_CHAR_LIMIT_MSG: '名称不能超过32个字符。',
        DUPLICATE_NAME: '模块名称已经存在。'
    },
    SALES_PURCHASE_ORDER: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD: '您有一个打开的采购订单标签。在添加新的采购订单之前，请关闭该标签。',
        RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD: '您有一个打开的销售订单标签。在添加新的销售订单之前，请关闭该标签。',
        SAVE_PURCHASE_SUCCESS: '采购订单已成功保存。',
        SAVE_SALES_SUCCESS: '销售订单已成功保存。',
        SALES_REP_NAME_CANT_BLANK: '销售代表姓名不能为空。',
        SELECT_ONE_ITEM: '请选择一项信息。',
        DISCOUNT_AMOUNT_CANT_GREATER: "折扣金额不能大于小计。",
        SPECIFY_TRANSC_AMOUNT: '您必须指定 0 或更大的交易金额。',
        SETUP_COMPANY_PROFILE: '请在添加报价之前设置您的公司简介。 请联系您的 CRM 管理员以设置配置文件。'
    },
    JOBS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD: '您有一个打开的作业选项卡。在添加新作业之前，请关闭该选项卡。',
        FIELDS_CANNOT_BLANK: ' 字段不能为空。',
        PRODUCT_NAME_NOT_CONTAIN: '产品名称不能包含冒号（:)。',
        JOB_SAVE_SUCCESS: '作业保存成功。',
        PRODUCT : '产品',
        ALREADY_EXIST: '已经存在。',
    },
   POWER_CALLING :{
        YOU_DONT_HAVE_CALL_SCRIPT: "您没有任何通话脚本。",
        ALERT_MSG_1: '出了 ',
        ALERT_MSG_2: ' 您仅选择了 ',
        ALERT_MSG_3: ' 有有效的本地号码。您将可以打电话给那些 ',
        ALERT_MSG_4: '与说话 ',
        ALERT_MSG_5: "没有了",
        ALERT_MSG_6: "在队列中拨打电话。",
        ALERT_MSG_7: '您没有任何活动的连接。',
        ALERT_MSG_8: '您选择的人都没有手机或电话号码。',
        ALERT_MSG_9: '您已经有一个打开的Power Dialer，请关闭以打开一个新的Power Dialer。',
        ALERT_MSG_10 :'要关闭选项卡，请单击选项卡顶部的关闭图标。'
    },
    LEAD_SCORE_SETUP:{
        ALREDY_DEFINED_SCORE: '您已经为此操作定义了分数。',
        SAVE_SUCCESSFULLY: '分数已成功保存。',
        CAN_NOT_UPDATE: '分数设置无法更新。 请正确修改分数设置。',
        SELECT_ONE_ACTION: '请选择至少一项操作',
        SELECT_ACTION: '请选择一个动作',
        SELECT_OBJECT: '请选择一个对象',
        SELECT_WORKFLOW: '请选择一个工作流程',
        CONVERT_FROM_CANT_BLANK: '转换自不能为空',
        CONVERT_TO_CANT_BLANK: '转换为不能为空',
        CHOOSE_DIFF_STATUS: '您不能转换相同的状态。 请选择其他状态。',
        SELECT_GROUP: '请选择一个组',
        SELECT_FIELD : '请选择一个字段',
        PROVIDE_VALUE_TO_UPDATE :'请提供您要更新的值',
        ENTER_SCORE: '请输入介于1-99之间的分数',
        EXIST_IN_LIST: '值已存在于列表中',
        SCORE_DELETED: '此分数将被删除。 你确定吗？',
        REMOVE_MSG: '删除成功',
        UPDATE_MSG: '更新成功'
    },
    DUPLICATE_RULE: {
        ERROR_MSG_DEFAULT: '你不能关闭默认的规则。',
        ERROR_MSG_Active: '你无法取消激活默认规则。',
        ERROR_MSG_FOR_FIELDS: '选择特定块的所有字段保存规则。',
        ERROR_MSG_1: '出问题了。请稍后再试。',
        SUCCESS_MSG_1: '你重复的规则（S）已成功保存。',
        ERROR_MSG_SAME_FIELD: ' 已经被选中。请选择另一个领域。',
    },
    REQUIRED_FORM: {
        FIELD_FILL_MESG:'这些字段需要在此之前填写 ',
        CAN_BE_SUBMITTED:' 可以提交。'
    },
    GREENLIGHT: {
        COMPANY_NAME_REQUIRED: '公司名稱不能超過 30 個字符。',
        COMPANY_NAME_BLANK: '請輸入公司名稱。',
        ADDRESS1_REQUIRED: '地址 1 是必需的。',
        ADDRESS1_ADDRESS2_LIMIT: '地址 1 + 地址 2 組合不能超過 30 個字符。',
        PHONE_NUMBER_REQUIRED: '請輸入一個有效的電話號碼。',
        PHONE_NUMBER_LIMIT: '電話號碼不能超過 10 位數字。',
        NET_ANNUAL_LIMIT: 'Net Anual 不能為空，也不能為零。',
        NET_ANNUAL_INVALID: '請在 Net Anual 中輸入有效金額。'
    },
    AUDIT_QUESTIONS: {
        QUESTION_REQUIRED: '问题是必要的',
        ENTER_VALID_SCORE: "请输入有效分数",
        ENTER_VALID_CHOICES: "请输入有效的选择",
        SUCCESS: '成功',
        ERROR: '错误',
        NO_QUESTIONS: "没有找到问题",
        THANK_YOU: "谢谢你！",
        SOMETHING_WRONG: "提交您的反馈时出现问题，请再试一次！",
        FEATURE_NOT_AVAILABLE: "此功能不可用。",
        RECEIVED_FEEDBACK_1: "做得好！ 您已成功保存以下问题的答案",
        RECEIVED_FEEDBACK_2:"类别。",
        RECEIVED_FEEDBACK_3:"请记住，您随时可以在最终提交之前更新此类别的答案。",
        MAIL_SENT_MESSAGE:'邮件已发送。',
        SUBMIT_AUDIT_1:'以下类别中有未回答的必答问题：',
        SUBMIT_AUDIT_2:'请回答这些必填问题，然后提交审核。',
        SUBMIT_AUDIT_3:'您已成功提交审核！',
        SUBMIT_AUDIT_4:'您可以在 CRM 中的 HUB > 审核下查看您提交的审核答案和分数。',
        SUBMIT_AUDIT_5:'如果特许人对您的审核提供任何反馈，您将收到电子邮件通知。',
        SUBMIT_AUDIT_6:'您还可以通过 HUB > 审核查看特许人给出的反馈并采取纠正措施。',
        SAVE_ALERT_1:'注意力！ 你即将离开这个 ',
        SAVE_ALERT_2:'类别而不保存更改。',
        SAVE_ALERT_3:'保存后请单击“确定”继续，或关闭此警报以留在此处并继续此类别。',
        REQUEST_CORRECTIVE_SUCCESS:'谢谢！ 您的反馈已成功通过电子邮件发送。',
        SUBMIT_CORRECTIVE_ACTION:'谢谢！ 您的纠正措施信息已成功通过电子邮件发送。',
        EMPTY_REQUEST_CORRECTIVE_MESSAGE:'您尚未添加对任何问题的反馈。'+'\n'+'请先添加反馈，然后单击“请求纠正措施”',
        EMPTY_SUBMIT_CORRECTIVE_MESSAGE:'您尚未针对任何反馈添加纠正措施。' + '\n' + '请先添加纠正措施，然后单击“提交纠正措施”。',  
        EMPTY_AUDIT_BY_NAME_AND_EMAIL:'您的姓名和电子邮件地址字段为空。请在这些字段中输入值。',
        EMPTY_AUDIT_BY_NAME:'您的姓名字段为空。请在这些字段中输入值。',
        EMPTY_AUDIT_BY_EMAIL:'您的电子邮件地址字段为空。请在这些字段中输入值。',
        AUDIT_TYPE_SELECT_VALIDATION:'请选择一种审计类型来生成链接。',
        INVALID_AUDIT_EMAIL:'请输入有效的电子邮件。',
        INVALID_AUDIT_NAME:'请输入有效的名称。',
    },
    AUDIT_ANSWERS:{
        NO_RECORD_FOUND:'未找到带纠正问题的答案',
    },
    AUDIT_QUESTION_TYPES:{
        MAX_EXCEED:'马西莫·超级',
    },
    COMPLIANCE: {
        SINGLE_RECORD_EDIT: '请选择1条编辑。',
    },
    SALES_AND_REFUND: {
        ALL_OPTIONS_REQUIRED: '请选择所有可用的过滤器，然后重试。',
    },
    INTAKE_FORM : {
        RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM:'You have an open Intake form tab. Please close that tab before opening or adding a new Intake form.',
        INTAKE_FORM_SUCCESSFULLY_SAVE: 'Saved Successfully.',
        THANK_YOU: "Thank You!",
    },
    EXCEPTION: {
        EXCEPTION_SAVE_SUCESS: 'Saved Successfully.',
    },
    PHONE_NUMBERS: {
        USE_FOR_NOTIFICATION_SAVE: '来自已更新通知的号码。',
        USE_NOTIFICATION: '该租用号码将用于通知。请单击“确定”进行确认。',
        REMOVE_NOTIFICATION: '该租用号码将被删除以供通知。请单击“确定”进行确认。',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        SAVE_SUCCESS: '您的设置已成功保存。',
        SAVE_FAIL: '您的设置未保存。',
        NO_PROVIDER: '尚未为电话和短信功能选择提供商。\n 请先选择提供商才能使用电话和短信功能。',
        NO_TWILIO_NUMBERS_FOUND: '您连接的 Twilio 帐户中没有可用的电话号码。\n 请先在您的 Twilio 帐户中添加该号码，然后执行导入操作。',
        NONE_SELECT_CONFIRM: '这将从所有位置禁用 CRM 中的电话和文本选项。\n如果您有任何已配置文本的工作流程或自动化营销活动，请相应地更改它们，因为它将不再起作用。'
    }
}
export default message