import React from 'react';
import { connect } from 'react-redux';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { Button, Menu, Popover, TextField, Badge, MenuItem, FormControl, Icon, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as HTTPClient from '../../../services/helper/httpClient';
import { setHeader } from '../../../services/actions/headerActions';
import { endPoints } from '../../../services/constants/endPoints';
import { styles } from '../../../services/constants/styles';
import { getStringParam, isValidParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as sfDialogs from '../components/sfDialogs';
import { getSMS, getRemainingSMSCount, sendSMS, getAllocatePhoneNumbers, getAllToNumbers, smsReadcountUpdate, updateSelectedContact, updateSelectedToNumber, updateSelectedFromNumber, updateSMSData, refreshSmsInbox, updateFromNoScrollPosition, updateToNoScrollPosition } from '../../../services/actions/smsAction';
import { COUNTRY_LIST_FOR_PHONE_SMS, TABLEID_OBJECT_MAP, constants } from '../../../services/constants/constants';
import Pagination from '../components/pagination';
import { addTab, TYPE_DETAIL_VIEW } from '../../../services/helper/sfTabManager';
import moment from 'moment';
import SMSBody from '../components/SMSBody';
import { timeZone, dateTimeFormat } from '../../../services/helper/utils';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alpha, styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -12,
        top: 5,
    },
}));



const SMSInbox = () => {
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [allocatePhoneNumbers, setAllocatePhoneNumbers] = useState([]);
    const [fromNumber, setFromNumber] = useState(null);
    const [smsToNumbers, setSmsToNumbers] = useState(null);
    const [selectedToNumber, setSelectedToNumber] = useState(null);
    const [selectedToContactName, setselectedToContactName] = useState(null);
    const [recordId, setRecordId] = useState(0);
    const [tableId, setTableId] = useState(0);
    const [templatesList, setTemplatesList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(0);
    const [isSMSSupport, setIsSMSSupport] = useState(true);
    const [arrSMS, setArrSMS] = useState([]);
    const [smsFromNumber, setSmsFromNumber] = useState(null);
    const [smsText, setSmsText] = useState(null);
    const [attachFiles, setAttachFiles] = useState([]);
    const [smsShrink, setSmsShrink] = useState(false);
    const [smsData, setSmsData] = useState(null);
    const [searchOption, setSearchOption] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [isShowOpen, setIsShowOpen] = useState(true);
    const [openLis, setOpenLis] = useState([]);
    const [isAccessible, setIsAccessible] = useState(true);
    const [isFromNumberAvailablee, setIsFromNumberAvailablee] = useState(true);
    const [lastsms, setLastsms] = useState([]);
    const [schedule_date, setSchedule_date] = useState('');
    const [moreActionOpen, setMoreActionOpen] = useState(false);
    const [anchorMoreActionEl, setAnchorMoreActionEl] = useState(null);
    const [addOrOpenButtonMenueOpen, setAddOrOpenButtonMenueOpen] = useState(false);
    const [addOrOpenButtonMenueAnchorEl, setAddOrOpenButtonMenueAnchorEl] = useState(null);
    const [shouldAdd, setshouldAdd] = useState(false);


    const appState = useSelector((state) => state.app);
    const sms = useSelector((state) => state.sms);

    useEffect(() => {
        dispatch(setHeader(constants.INBOX_OBJECT, 'SMS Inbox', null, false, true));
        if (appState.me.phone_setup.is_twilio_sms) {
            getTenantCountry('mount');
        } else {
            setIsAccessible(false);
            setIsLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (sms.selectedContact !== null) {
            let tempSmsToNumbers = smsToNumbers;
            let _value = sms.selectedContact;
            let _foundIndex = tempSmsToNumbers.findIndex((_contactDetail)=>Number(_contactDetail.record_id) === Number(sms.selectedContact.record_id))
            if(_foundIndex > -1) {
                _value = tempSmsToNumbers[_foundIndex];
                tempSmsToNumbers = tempSmsToNumbers.filter((element, index, array) => index !== _foundIndex );
                tempSmsToNumbers.unshift(_value);
            }else{
                _value = sms.selectedContact;
                tempSmsToNumbers.unshift(sms.selectedContact);
            }
            setSmsToNumbers(tempSmsToNumbers);
            setToPhoneNumbers(_value);
            updateSelectedContact(null);
        }
        else if (sms.isRefresh) {
            if (appState.me.phone_setup.is_twilio_sms) {
                getTenantCountry('refresh');
            }
            refreshSmsInbox(false);
        }
    }, [sms]);

    const handleAddMessage = () => {
        let tempallocatePhoneNumbers = allocatePhoneNumbers;
        if (tempallocatePhoneNumbers.length > 0) {
            let data = {};
            let label = 'Select a Contact';
            // getAppDialog(true, constants.SMS_CONTACT_PICKING_DIALOG, label, null, data, null);
            dispatch(getAppDialog(true, constants.SMS_CONTACT_PICKING_DIALOG, label, null, data, null));


        } else {
            let msg = 'There is no allocated phone numbers for you. Please contact Admin.'
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
        }
    }
    const getTenantCountry = (callFrom) => {
        let tenantCountry = getStringParam(appState.me.tenant_country);
        let countryRecord = getStringParam('India');
        let gblCountry = null;
        if (countryRecord === '') {
            gblCountry = tenantCountry;
            setIsSMSSupport(true);
        } else {
            if (COUNTRY_LIST_FOR_PHONE_SMS.indexOf(tenantCountry) > -1) {
                gblCountry = tenantCountry;
                setIsSMSSupport(true);
            } else {
                setIsSMSSupport(true);
                setIsLoaded(false);
            }
        }

        if (gblCountry !== null && gblCountry !== '' && isSMSSupport) {
            getFromPhoneNumbers(callFrom);
        }

    }
    const handleScroll = (event, callFrom) => {
        let scrollTop = event.currentTarget.scrollTop;
        if (callFrom === 'fromNo') {
            updateFromNoScrollPosition(scrollTop);
        } else {
            updateToNoScrollPosition(scrollTop);
        }
    }
    const getFromPhoneNumbers = (callFrom) => {
        try {
            let params = {};
            params.call_from = 'Inbox';
            let tempPhoneNumbersArr = null;
            let tempfromNumber = fromNumber;
            let finalPhoneNumbersArr = [];
            let promise = Promise.resolve(getAllocatePhoneNumbers(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let twilioNumbersArr = getArrayParam(response.twilio_numbers);
                        if (twilioNumbersArr !== null && twilioNumbersArr.length > 0) {
                            tempPhoneNumbersArr = twilioNumbersArr.filter(f => {
                                let availableToArr = f.available_to.split(',');
                                if (availableToArr.indexOf(String(-9999)) > -1) {
                                    return true;
                                } else if (availableToArr.indexOf(String(appState.me.id)) > -1) {
                                    return true;
                                }
                            });
                            if (response.unread_count_details !== 0) {
                                let unreadCountDetails = getArrayParam(response.unread_count_details);
                                tempPhoneNumbersArr.map((items, Index) => {
                                    let tempObj = items;
                                    unreadCountDetails.map((data, Index) => {
                                        if (items.phone_number === data.to_phone) {
                                            tempObj.unread_count = data.unread_count;
                                        };
                                        finalPhoneNumbersArr.push(tempObj);
                                    })
                                })
                            } else {
                                finalPhoneNumbersArr = tempPhoneNumbersArr;
                            }
                            let selectedNo = sms.selectedFromNumer;
                            if (finalPhoneNumbersArr.length > 0) {
                                if (selectedNo === null || callFrom === 'refresh') {
                                    tempfromNumber = finalPhoneNumbersArr[0].phone_number;
                                    setFromNumber(tempfromNumber);

                                    updateSelectedFromNumber(finalPhoneNumbersArr[0].phone_number);

                                } else {
                                    finalPhoneNumbersArr.map((items, Index) => {
                                        if (selectedNo === items.phone_number) {
                                            tempfromNumber = items.phone_number;
                                            setFromNumber(tempfromNumber);
                                        }
                                    });
                                }
                            } else {
                                setIsFromNumberAvailablee(false);
                                setIsLoaded(true);
                            }
                        }
                        setAllocatePhoneNumbers(finalPhoneNumbersArr);
                        setSearchText(null);
                        setIsLoaded(true);
                        getToPhoneNumbers('getFromPhoneNumbers', callFrom, tempfromNumber, 0, 200);

                    }
                });
            }
        } catch (error) {
            console.error("Error in 'sendSMS.js -> getFromPhoneNumbers()':" + error);
        }
    }
    const setSearchTextValue = (value) => {
        setSearchText(value);
    }
    const handleKeyUp = (event) => {
        let search_text = event.target.value;

        if (search_text.toString().length > 0) {
            setSearchText(search_text);
        } else {
            setSearchText(search_text);
        }
        if (event.keyCode === 13) {
            getToPhoneNumbers('search', 'search', fromNumber, 0, 200);
        }
    }
    const getToPhoneNumbers = (callFrom, callOf, fromNumberValue, startIndex, pageSize) => {
        let params = {};
        params.fromNumber = getStringParam(fromNumberValue);
        params.startIndex = startIndex;
        if (callFrom === 'search') {
            params.searchText = searchText;
        }
        else if (callFrom === 'pagination' && searchText !== null) {
            params.searchText = searchText;
        }
        let promise = Promise.resolve(getAllToNumbers(params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let smsData = {};
                    let data = {};
                    let total = response.length > 0 && response[0].hasOwnProperty('total_records') ? response[0].total_records : 0
                    data.total_count = total;
                    data.records = total > 0 ? response[1].records : [];
                    data.is_show_view_count = false;
                    data.page_size = pageSize;
                    smsData.data = data;
                    let smsToNumbers = total > 0 ? response[1].records : [];
                    let smsToNumbersUnreadCount = response.length > 2 ? response[2].hasOwnProperty('unread_count') ? response[2].unread_count : [] : [];
                    smsToNumbers.map(tn => {
                        let unread_count = 0;
                        smsToNumbersUnreadCount.map(tnrc => {
                            if (tn.to_phone === tnrc.to_phone) {
                                unread_count = tnrc.count;
                            }
                        });
                        tn.unread_count = unread_count;
                        return tn;
                    });
                    setSmsToNumbers(smsToNumbers);
                    setselectedToContactName(null);
                    setSmsData(smsData);
                    setArrSMS([]);
                    let toNumber = sms.selectedToNumber;
                    let noObject = null;
                    let noIndex = total > 0 ? response[1].records.findIndex(f => f.to_phone === toNumber) : -1;
                    if (toNumber === null || noIndex === -1 || callOf === 'refresh' || callOf === 'setFromNumber') {
                        noObject = total > 0 ? response[1].records[0] : null
                    } else {
                        noObject = total > 0 ? response[1].records.find((obj) => { return obj.to_phone === toNumber }) : null;
                    }
                    setToPhoneNumbers(noObject);
                    loadTemplateList(noObject);
                }
            });
        }
    }

    const setFromNumberData = (e, items) => {
        let value = getStringParam(items.phone_number);
        setFromNumber(value);
        setSearchText(null);
        updateSelectedFromNumber(value);
        getToPhoneNumbers('setFromNumber', 'setFromNumber', value, 0, 200);
    }
    const setToPhoneNumbers = (value) => {
        if (value !== null) {
            setSelectedToNumber(value.to_phone);
            setTableId(value?.table_id);
            setRecordId(value.record_id);
            updateSelectedToNumber(value.to_phone);
            readcountUpdate(value.to_phone);
            getSMSData(value);
            let params = {};
            params.value = selectedTemplate;
            handleTemplateChange(params, value?.table_id, value?.record_id);
        } else {
            let array = [];
            updateSMSData(array);
            setArrSMS(array);
            setSelectedToNumber(null);
            setTableId(0);
            setRecordId(0);
            updateSelectedToNumber(null);
        }
    }
    const readcountUpdate = (toNumber) => {
        let params = {};
        params.fromNumber = getStringParam(fromNumber);
        params.toNumber = getStringParam(toNumber);
        let countUpdate = false
        allocatePhoneNumbers.map((items, Index) => {
            if (items.phone_number === fromNumber && isValidParam(items.unread_count) && items.unread_count > 0) {
                countUpdate = true;
            }
        })
        if (countUpdate) {
            let promise = Promise.resolve(smsReadcountUpdate(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response && response.data > 0)) {
                        let tempallocatePhoneNumbers = allocatePhoneNumbers.map((items, Index) => {
                            let tempObj = items;
                            if (items.phone_number === fromNumber) {
                                tempObj.unread_count -= response.data;
                            };
                            return tempObj
                        })
                        let tempsmsToNumbers = smsToNumbers.map(tn => {
                            if (tn.to_phone === toNumber) {
                                tn.unread_count = 0;
                            }
                            return tn;
                        });
                        setAllocatePhoneNumbers(tempallocatePhoneNumbers);
                        setSmsToNumbers(tempsmsToNumbers);
                    }
                }
                );

            }

        }
    }
    const openLinkDetails = (recordId, object, labelName) => {
        let url = '/' + object + '/detailview/' + recordId;
        let tabParams = {
            label: labelName,
            object: object,
            type: TYPE_DETAIL_VIEW,
            url: url,
        }
        addTab(tabParams, true);
    }
    const getSMSData = (value) => {
        let params = {};
        params.from_number = getStringParam(fromNumber);
        params.to_number = getStringParam(value.to_phone);
        params.record_id = getIntParam(value?.record_id);
        params.table_id = getIntParam(value?.table_id);
        params.call_from = 'Inbox';
        let promise = Promise.resolve(getSMS(params));
        if (isValidParam(promise)) {
            promise.then(response => {
                let arrSMS = getArrayParam(response.smsList);
                let openLis = [];
                let tempIsShowOpen = false;
                let objectList = appState.objectList;
                let selectedToContactName = null;
                if (response.hasOwnProperty('accounts')) {
                    let labelName = 'Empty';
                    let objectIndex = objectList.findIndex(f => f.name === constants.ACCOUNTS_OBJECT);
                    if (response.accounts.companyName !== null && response.accounts.companyName !== '') {
                        labelName = response.accounts.companyName;
                    }
                    let obj = {};
                    obj.recordId = response.accounts.recordId;
                    obj.objectIconImage = appState.objectIconImageMap[constants.ACCOUNTS_OBJECT]
                    obj.object = constants.ACCOUNTS_OBJECT;
                    obj.label = `${objectList[objectIndex].label} (${labelName})`;
                    obj.labelName = labelName;
                    openLis.push(obj);
                }
                if (response.hasOwnProperty('contacts')) {
                    let labelName = 'Empty';
                    let objectIndex = objectList.findIndex(f => f.name === constants.CONTACTS_OBJECT);
                    if (response.contacts.contactName !== null && response.contacts.contactName !== '') {
                        labelName = response.contacts.contactName;
                        selectedToContactName = labelName;
                    }
                    let obj = {};
                    obj.recordId = response.contacts.recordId
                    obj.object = constants.CONTACTS_OBJECT;
                    obj.objectIconImage = appState.objectIconImageMap[constants.CONTACTS_OBJECT]
                    obj.label = `${objectList[objectIndex].label} (${labelName})`;
                    obj.labelName = labelName;
                    openLis.push(obj);
                }
                if (response.hasOwnProperty('opportunities')) {
                    let labelName = 'Empty';
                    let objectIndex = objectList.findIndex(f => f.name === constants.OPPORTUNITIES_OBJECT);
                    response.opportunities.map(object => {
                        if (object.oppName !== null && object.oppName !== '') {
                            labelName = object.oppName;
                        }
                        let obj = {};
                        obj.recordId = object.recordId;
                        obj.objectIconImage = appState.objectIconImageMap[constants.OPPORTUNITIES_OBJECT]
                        obj.object = constants.OPPORTUNITIES_OBJECT;
                        obj.label = `${objectList[objectIndex].label} (${labelName})`;
                        obj.labelName = labelName;
                        openLis.push(obj);
                    });
                }
                if (openLis.length > 0) {
                    tempIsShowOpen = true;
                }

                updateSMSData(arrSMS);
                setArrSMS(arrSMS);
                setselectedToContactName(selectedToContactName);
                setOpenLis(openLis);
                setIsShowOpen(tempIsShowOpen);
            });
        }
    }
    const loadTemplateList = (value) => {
        let promise = Promise.resolve(HTTPClient.get(endPoints.SMS_TEMPLATE.GET_TEMPLATE, null));
        promise.then((response) => {
            if (isValidParam(response) && response.status === 0) {
                let responseData = getArrayParam(response.data);
                let templateList = [];
                responseData.forEach(template => {
                    let duplicateIndex = templateList.findIndex((i) => i.value == template.id);
                    if (duplicateIndex == -1) {
                        templateList.push({ label: template.name, value: template.id, title: template.name, sms_message: template.sms_message, image_object: template.image_object });

                    }
                });

                setIsLoading(false);
                setTemplatesList(templateList);
                if (templateList.length > 0) {
                    setSelectedTemplate(templateList[0].value);
                    handleTemplateChange(templateList[0], value?.table_id, value?.record_id);
                }

                if (response.alert) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.alert, null);
                }
            }
        });
    }
    const handleTemplateChange = (option, tableId, recordId) => {
        let selectedId = getIntParam(option.value);
        if (selectedId > 0) {
            let params = {};
            params.record_id = getIntParam(recordId);
            params.table_id = getIntParam(tableId);
            params.object = TABLEID_OBJECT_MAP[getIntParam(tableId)];
            params.templateId = getIntParam(option.value);
            let promise = Promise.resolve(HTTPClient.get(endPoints.SMS_TEMPLATE.GET_TEMPLATE_DATA, params));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    let data = response.data;
                    let sms_message = getStringParam(data.sms_message);
                    let tempAttach = [];
                    if (data.image_object !== null && data.image_object !== "") {
                        let attach = [];
                        attach = data.image_object.split('###');
                        if (attach.length > 0) {
                            attach.forEach(element => {
                                let obj = {};
                                let elm = element.split('@#@');
                                obj.name = elm[0];
                                obj.src = elm[1];
                                tempAttach.push(obj);
                            });
                        }
                    }
                    setSmsText(sms_message);
                    setSelectedTemplate(selectedId);
                    setAttachFiles(tempAttach);
                    setSmsShrink(true);
                }
            });
        }
    }

    const checkPhoneTextProvider = () => {

        let phone_text_provider = appState.me.phone_text_provider ?? constants.NONE;
        if (phone_text_provider == constants.SOFFRONT) {
            sendWithCheckMessage();
        } else if (phone_text_provider == constants.TWILIO) {
            sendMessage();
        }

    }


    const sendMessage = (date = null) => {
        let msg = getLocalizedStrings().message.SMS_INTEGRATION['BATCH_SMS_SENT'];
        let temparrSMS = arrSMS;
        let isSchedule = false;
        let tempFromNumber = getStringParam(fromNumber).trim();
        let tempSmsText = getStringParam(smsText).trim();
        let tempAttachFiles = getArrayParam(attachFiles);
        try {
            updateSelectedFromNumber(tempFromNumber);
            let params = {};
            params.from_number = tempFromNumber;
            params.to_number = getStringParam(selectedToNumber);
            params.record_id = getIntParam(recordId);
            params.sms_text = tempSmsText;
            params.table_id = getIntParam(tableId);
            params.template_id = selectedTemplate;
            if (tempAttachFiles.length > 0) {
                let imgObj = {
                    'image_object': tempAttachFiles
                }
                Object.assign(params, imgObj)
            }
            if (isValidParam(date) && date != null && date !== '') {
                params.schedule_date = date;
                isSchedule = true;
                msg = getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SMS_SCHEDULE;
            }
            let promise = Promise.resolve(sendSMS(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    let isSMSSend = response.data.isSendSMS;
                    if (isSMSSend) {
                        let smsDetails = {};
                        smsDetails.id = -1;
                        smsDetails.sms_text = response.data.smsText;
                        smsDetails.is_sent = true;
                        smsDetails.is_received = false;
                        smsDetails.date_time = moment().tz(timeZone[appState.me.timezone]).format("YYYY-MM-DD HH:mm");
                        if (getArrayParam(tempAttachFiles).length > 0) {
                            let urlArr = [];
                            tempAttachFiles.forEach(e => urlArr.push(e.src));
                            if (isValidParam(urlArr) && urlArr.length > 0) {
                                smsDetails.t_attachment = urlArr;
                            }
                        }
                        temparrSMS.push(smsDetails);
                        if (getArrayParam(tempAttachFiles).length > 0) {
                            let _attachFiles = attachFiles.splice(0, tempAttachFiles.length);
                            setAttachFiles(_attachFiles);
                        }
                        setArrSMS([...temparrSMS]);
                        setSmsText('');
                        setSelectedTemplate(0);
                        updateSMSData([...temparrSMS]);
                        updateToNumbersLastMessage(response.data.tempSmsText, selectedToNumber)
                        foucusInputField('smsTextField')
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    } else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.NUMBER_INVALID_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    if (isSchedule) {
                        setSchedule_date(null);
                        setSmsText('');
                        foucusInputField('smsTextField');
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                });
            }
        } catch (error) {

        }
    }

    const sendWithCheckMessage = (date = null) => {
        if (validateSMSForm()) {
            let smsCountPromise = Promise.resolve(getRemainingSMSCount());
            if (isValidParam(smsCountPromise)) {
                smsCountPromise.then((response) => {
                    if (isValidParam(response)) {
                        let remainingSMSCount = response.remaining_sms_count;
                        if (remainingSMSCount > 0) {
                            sendMessage();
                        } else {
                            let msg = getLocalizedStrings().message.SMS_INTEGRATION.SMS_LOW_BALANCE_1 + remainingSMSCount + '.\n' + getLocalizedStrings().message.SMS_INTEGRATION.SMS_LOW_BALANCE_2
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                        }
                    }
                });
            }
        }
    }
    const updateToNumbersLastMessage = (text, toNo) => {
        let tempsmsToNumbers = smsToNumbers;
        tempsmsToNumbers.map((data, Index) => {
            if (data.to_phone === toNo) {
                data.sms_text = text;
            };
            return data;
        });
        setSmsToNumbers(tempsmsToNumbers);
    }
    const updateSmsHistry = (data) => {
        let tempArrSMS = arrSMS;
        tempArrSMS.push(data);
        setArrSMS(tempArrSMS);
    }
    const foucusInputField = (fieldId) => {
        let inputElement = document.getElementById(fieldId);
        if (isValidParam(inputElement) && inputElement !== null) {
            inputElement.focus();
        }
    }
    const validateSMSForm = () => {
        if (fromNumber === null || fromNumber === '') {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'There is no allocated phone numbers for you. Please contact Admin.', null);
            return false;
        }
        else if (selectedToNumber === null || selectedToNumber === '') {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.SELECT_NUMBER, null);
            return false;
        } else if (smsText === null || smsText === '') {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.ENTER_SMS_TEXT, null);
            return false;
        } else {
            return true;
        }
    }
    const openAttachment = () => {
        let data = {
            contentStyle: { width: '80%', maxWidth: 'none' },
            type: "link",
            fullWidth: true,
            maxWidth: 'md',
        }
        data.callFrom = constants.SEND_SMS;
        data.files = getArrayParam(attachFiles);
        dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.ASSETMENU_DIALOG_TITLE, (sendData) => {

            let attachFile = {};
            attachFile.name = sendData.name;
            attachFile.src = sendData.src;
            setAttachFiles(...attachFiles, attachFile)
            getAppDialog(false, null, null, null, null, null);
        }, data, constants.SEND_SMS));
    }
    const getAttachmetFiles = () => {
        let files = getArrayParam(attachFiles);
        var fullWidth = window.innerWidth;
        let fileWidthP = styles.sf_7.width
        var dw = fileWidthP.split("%")[0];
        var fileWidth = ((fullWidth * 0.01 * parseInt(dw)) - 180);
        let ul = null;
        let li = files.map((f, index) => {
            return <li key={f.name + index} style={{ display: 'flex' }}><span style={{
                float: 'left', textAlign: 'left', overflow: 'hidden',
                textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', maxWidth: '130px', marginTop: '-2px'
            }} title={f.fileName}> {f.name} </span>
                &nbsp;  <i className="fa fa-times" aria-hidden="true" style={{ padding: '3px 11px 0 11px', cursor: 'pointer' }} onClick={() => {
                    let tempattachFiles = attachFiles.splice(index, 1);
                    setAttachFiles(tempattachFiles);
                }} ></i>
            </li>
        });
        if (li !== null && li.length > 0) {
            ul = <ul style={{ display: 'flex', margin: 0, padding: 0, overflowX: 'auto', width: fileWidth }}>{li}</ul>;
        }
        return ul;
    }
    const handleMoreActionOpen = (event) => {
        event.stopPropagation();
        setMoreActionOpen(true);
        setAnchorMoreActionEl(event.currentTarget);
    }
    const handleMoreActionClose = () => {
        setMoreActionOpen(false);
        setAnchorMoreActionEl(null);
    }
    const handleSchedule = () => {
        handleMoreActionClose();
        if (validateSMSForm()) {
            dispatch(getAppDialog(true, constants.SMS_SCHEDULE_DIALOG, getLocalizedStrings().label.BATCH_SMS.SCHEDULE_SMS, (event) => schedule(event), null, null));
        }
    }
    const schedule = (obj) => {
        let momentObj = obj.date;
        let db_date = momentObj.format('YYYY-MM-DD HH:mm:ss');
        let user_date = momentObj.format(dateTimeFormat[appState.me.date_format]);
        let data = { user_date: user_date, db_date: db_date };
        dispatch(getAppDialog(false, constants.SMS_SCHEDULE_DIALOG, null, null, null, null));
        dispatch(getAppDialog(true, constants.SMS_CONFIRM_SCHEDULE_DIALOG, getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, (event) => confirmSchedule(event), data, constants.SMS_OBJECT));
    }
    const confirmSchedule = (obj) => {
        let tempSchedule_date = obj.schedule_date;
        setSchedule_date(tempSchedule_date);
        checkPhoneTextProvider(tempSchedule_date);
        dispatch(getAppDialog(false, constants.SMS_CONFIRM_SCHEDULE_DIALOG, null, null, null, null));
    }
    const getActionButton = () => {
        return <div style={{ marginLeft: 9, marginTop: '5px' }} >
            <div style={{ ...styles.emailSendBtn, width: '100px' }}>
                <div style={{ float: 'left', width: '60%', margin: 5, textAlign: 'center' }} onClick={() => checkPhoneTextProvider()}>{getLocalizedStrings().label.BATCH_SMS.SEND}</div>
                <div style={{ float: 'right', width: '20%', borderLeft: '1px solid rgba(159, 46, 10, 0.6)' }}
                    onClick={(event) => handleMoreActionOpen(event)}
                >
                    <i className="fa fa-angle-down" aria-hidden="true" style={{ margin: 5, fontSize: 20, marginLeft: '2px' }}></i>
                </div>
            </div>
            <Menu
                open={moreActionOpen}
                anchorEl={anchorMoreActionEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={() => handleMoreActionClose()}
            >
                <MenuItem value={'Schedule'} style={styles.popoverMenuItem} onClick={() => handleSchedule()} >{getLocalizedStrings().label.BATCH_SMS.SEND_LATER}</MenuItem>
            </Menu>
        </div>
    }
    const getFromPhoneNumbersList = () => {
        let iconStyle = {
            fontSize: '14px', color: '#717171', cursor: 'pointer', paddingRight: '20px'
        }
        let noStyle = {
            marginTop: '-3px', fontSize: '12.5px', letterSpacing: '1.1px'
        }
        let numbers = [];
        let element = <div></div>
        let tempAllocatePhoneNumbers = allocatePhoneNumbers;
        if (tempAllocatePhoneNumbers.length > 0) {
            tempAllocatePhoneNumbers.map((items, Index) => {

                element = <div id={'phNo' + Index} style={{ width: '100%', cursor: 'pointer', background: fromNumber === items.phone_number ? '#f4f4f4' : 'transparent', padding: 10 }} onClick={(e) => setFromNumberData(e, items)}>
                    {isValidParam(items.unread_count) && items.unread_count > 0 ? <StyledBadge badgeContent={items.unread_count} max={99} color="error" >
                        <div style={{ ...styles.row, padding: '0px' }}>
                            <Icon style={iconStyle} >textsms</Icon>
                            <div style={{ ...noStyle }}>
                                {items.phone_number}
                            </div>
                        </div>
                    </StyledBadge> : <div style={{ ...styles.row, padding: '0px' }}>
                        <Icon style={iconStyle} >textsms</Icon>
                        <div style={{ ...noStyle }}>
                            {items.phone_number}
                        </div>
                    </div>}
                </div>
                numbers.push(element);
            });
        }
        else {
            element = <div style={{ width: '100%', padding: '15 10' }}>There is no allocated phone numbers for you. Please contact Admin.</div>
            numbers.push(element);
        }
        return numbers;
    }
    const getToNumbersList = () => {
        let iconStyle = {
            fontSize: '17px', color: '#717171', cursor: 'pointer'
        }
        let smsTextStyle = { color: '#787774', display: 'inline-block', width: 'auto', cursor: 'pointer', paddingBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
        let numbers = [];
        let element = <div></div>
        let tempsmsToNumbers = smsToNumbers;
        if (tempsmsToNumbers !== null && tempsmsToNumbers.length > 0) {
            tempsmsToNumbers.map((items, Index) => {
                element = <div id={'phNo' + Index} style={{ width: '100%', cursor: 'pointer', background: selectedToNumber === items.to_phone ? '#f4f4f4' : 'transparent', padding: '15 10', borderBottom: '1px solid #f1f0f0' }} onClick={() => setToPhoneNumbers(items)}>
                    <div style={{ ...styles.row, padding: '0px' }}>
                        <div style={{ fontSize: items.unread_count > 0 ? '14px' : "13px", color: 'black', fontWeight: items.unread_count > 0 ? 'bold' : 'normal' }}>
                            {items.name !== null ? items.name : items.to_phone}
                        </div>
                    </div>
                    <div style={{ ...styles.row, paddingLeft: 0 }}>
                        <div style={{ ...smsTextStyle, fontSize: items.unread_count > 0 ? '13px' : "12px", fontWeight: items.unread_count > 0 ? '600' : '400' }} title={items.sms_text}>
                            {items.sms_text}
                        </div>
                    </div>
                </div>
                numbers.push(element);
            });
        } else {
            element = <div style={{ width: '100%', padding: '15 10' }}>No messages found</div>
            numbers.push(element);
        }

        return numbers;
    }
    const setSMSBody = (event) => {
        let value = event.target.value
        value = getStringParam(value);
        setSmsText(value);
    }
    const onBlurShrink = () => {
        if (smsText === null || smsText === '') {
            setSmsShrink(false);
        }
    }
    const openAndCloseAddAndOpenButton = (event, type) => {
        if (type === 'open') {
            if (isShowOpen) {
                event.stopPropagation();
                setAddOrOpenButtonMenueOpen(true);
                setAddOrOpenButtonMenueAnchorEl(event.currentTarget);
            } else {
                openFrom()
            }
        } else {
            setAddOrOpenButtonMenueOpen(false);
            setAddOrOpenButtonMenueAnchorEl(null);
        }
    }
    const openFrom = () => {

    }
    const getAddOrOpenButton = () => {
        let buttonLable = null;
        if (isShowOpen) {
            buttonLable = selectedToContactName;
            if (buttonLable !== null && buttonLable.length > 13) {
                buttonLable = buttonLable.substring(0, 12) + '...'
            }
        }

        let element = <div key={'getAddAndUpdateButton'} >
            {selectedToContactName !== null && buttonLable !== null &&
                <div style={{ border: '1px solid #c4c4c4', color: '#c4c4c4', width: '165px', display: 'flex', borderRadius: '4px', height: "35", marginTop: '8', cursor: 'pointer' }} onClick={(e) => openAndCloseAddAndOpenButton(e, 'open')}>
                    <span style={{ float: 'left', textAlign: 'justify', fontSize: "14.2px", fontWeight: "400", width: '100%', padding: '6.5px 6px 6.5px  10px', color: '#000' }}>{buttonLable}</span>
                    <span style={{ float: 'right', padding: '6 5 6 0' }}>{<KeyboardArrowDownIcon />}</span>
                </div>}
            <Popover
                open={addOrOpenButtonMenueOpen}
                anchorEl={addOrOpenButtonMenueAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={(e) => openAndCloseAddAndOpenButton(e, 'close')}
            >
                {openLis.map((f, i) => {

                    return <MenuItem value={f.recordId} style={{ ...styles.popoverMenuItem, paddingLeft: 12 }} onClick={() => openLinkDetails(f.recordId, f.object, f.labelName)} ><div><i className={f.objectIconImage} style={{ fontSize: '11px' }}></i> {f.labelName}</div></MenuItem>
                })}
            </Popover >
        </div>
        return element;
    }
    let pageHeight = window.innerHeight - 200;
    let top = (pageHeight - 10) / 2
    let smsInboxHeight = (pageHeight);
    if (isLoaded && isAccessible) {
        let tempAttachFiles = getArrayParam(attachFiles);
        let tenantCountry = appState.me.tenant_country;
        let showAttachment = false;
        if (tenantCountry === 'USA' || tenantCountry === 'Canada') {
            showAttachment = false;
        }
        if (sms.fromNoScrollPosition !== null) {
            let element = document.getElementById('fromPhoneNumbersList');
            if (element !== undefined && element !== null) {
                element.scrollTop = sms.fromNoScrollPosition;
            }
        }
        if (sms.toNoScrollPosition !== null) {
            let element = document.getElementById('toPhoneNumbersList');
            if (element !== undefined && element !== null) {
                element.scrollTop = sms.toNoScrollPosition;
            }
        }

        return (
            <div >
                <div style={{ padding: '0px 15px 0px 15px' }}>
                    <div style={{ ...styles.row, padding: '0px' }}>
                        <div className="card" style={{ ...styles.sf_1o5, verticalAlign: 'middle', height: smsInboxHeight, maxHeight: smsInboxHeight }}>
                            <div style={{ paddingBottom: '12px', paddingLeft: 12, width: '160px' }}>
                                <Button key='newMessage' style={{ ...styles.primaryButton1, height: "35", marginTop: '8' }} disableRipple onClick={() => handleAddMessage()} >New Message</Button>
                            </div>
                            <div style={{ height: "31px", color: 'black', paddingLeft: 12, marginTop: '9px' }}>
                                <span>My Numbers</span>
                            </div>
                            <div id='fromPhoneNumbersList' style={{ overflowY: 'auto', verticalAlign: 'middle', height: smsInboxHeight - 97, maxHeight: smsInboxHeight - 97 }} onScroll={(e) => handleScroll(e, "fromNo")}>
                                {getFromPhoneNumbersList()}
                            </div>
                        </div>
                        <div className="card" style={{ ...styles.sf_3, verticalAlign: 'middle', height: smsInboxHeight, maxHeight: smsInboxHeight }}>
                            <div style={{ padding: '0 5px 5px 5px', borderBottom: '1px solid #c4c4c4' }}>
                                <div style={{ ...styles.row, padding: '0px' }}>
                                    <div style={{ height: '50px' }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ ...styles.sf_12 }}>
                                                {searchOption && <FormControl className="test" noValidate autoComplete="off">
                                                    <TextField
                                                        variant="outlined"
                                                        key='-9999'
                                                        id={'smsSearchField'}
                                                        label={getLocalizedStrings().label.COMMON.SEARCH}
                                                        fullWidth={true}
                                                        name={'smsSearchField'}
                                                        value={searchText}
                                                        onChange={(e) => setSearchTextValue(e.target.value)}
                                                        onKeyUp={(e) => handleKeyUp(e)}
                                                        className="form-field-search-icon"
                                                        autoComplete="new-password"
                                                        margin='dense'
                                                        size='small'
                                                        style={{ height: '38px', background: '#f7f7f7' }}
                                                        InputLabelProps={{ classes: { paddingTop: '30px' } }} />
                                                </FormControl>}
                                            </div>
                                            {searchOption && <div style={{ zIndex: 1 }}>
                                                <span className="search-field-icon fa fa-search" style={{ paddingTop: '17px', marginLeft: ' -25px' }} onClick={() => getToPhoneNumbers('search', fromNumber, 0, 200)}></span>
                                            </div>}
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', paddingTop: '13px' }} >
                                        {smsData !== null && smsData.data.total_count > 0 &&
                                            <Pagination
                                                totalRecordCount={smsData.data.total_count}
                                                object={constants.SMS_OBJECT}
                                                listView={smsData}
                                                isLookup={false}
                                                listViewCallBack={(object, params) => getToPhoneNumbers('pagination', 'pagination', fromNumber, params.hasOwnProperty("start_index") ? getIntParam(params.start_index) : 0, params.hasOwnProperty("page_size") ? getIntParam(params.page_size) : 200)}
                                            />}
                                    </div>
                                </div>

                            </div>
                            <div id='toPhoneNumbersList' style={{ overflowY: 'auto', verticalAlign: 'middle', height: smsInboxHeight - 65, maxHeight: smsInboxHeight - 65 }} onScroll={(e) => handleScroll(e, "toNo")}>
                                {getToNumbersList()}
                            </div>
                        </div>
                        <div className="card" style={{ ...styles.sf_7, verticalAlign: 'middle', height: smsInboxHeight, maxHeight: smsInboxHeight }}>
                            <div style={{ padding: ' 0 5px 5px 5px', borderBottom: '1px solid #c4c4c4', height: '56px' }} >
                                <div style={{ float: 'left' }}>
                                    {getAddOrOpenButton()}
                                </div>
                                <div style={{ float: 'right' }}>
                                    <FormControl style={{ width: '300' }} className="test" noValidate autoComplete="off">
                                        {templatesList.length > 0 && <Autocomplete
                                            name="template"
                                            value={templatesList.find(v => v.value === selectedTemplate)}
                                            options={templatesList}
                                            onChange={(e, option) => handleTemplateChange(option, tableId, recordId)}
                                            className="sendMail"
                                            style={{ height: 42 }}
                                            getOptionLabel={(option) => option.label}
                                            disableClearable={false}
                                            renderInput={(params) => <TextField {...params} placeholder={getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_TEMPLATE} margin='dense' size='small' className={"sf-fields-bg"} />}
                                        />}

                                    </FormControl>
                                </div>
                            </div>
                            <div style={{ padding: ' 5px 5px 0px 5px' }}>
                                <div>
                                    <div style={{ overflowY: 'scroll', minHeight: smsInboxHeight - 236, maxHeight: smsInboxHeight - 236 }}><div style={{ margin: '10 0' }}>{arrSMS.length > 0 && arrSMS?.map((smsDetails, index) => { return <SMSBody key={'row-view-sms-' + index} details={smsDetails} /> })}</div></div>

                                </div>
                                <div>
                                    <div style={{ width: '100%', marginBottom: '2px' }}>
                                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                            <TextField
                                                variant="outlined"
                                                key='send-sms-text-box'
                                                multiline={true}
                                                id={'smsTextField'}
                                                rows={3}
                                                fullWidth={true}
                                                label={getLocalizedStrings().label.TWILIO_SMS.YOUR_MESSAGE}
                                                name="sendSMSTextBox"
                                                className='sf-sms-inbox-text-box'
                                                style={{ width: '100%', marginTop: '7px' }}
                                                value={smsText}
                                                onChange={(e) => setSMSBody(e)}
                                                onFocus={() => setSmsShrink(true)}
                                                onBlur={() => onBlurShrink()}
                                                InputLabelProps={{
                                                    shrink: smsShrink,
                                                }}
                                            /></FormControl>
                                    </div>
                                    <span style={{ fontSize: '11px', color: '#787774' }}>{smsText !== null ? smsText.length : 0}{getLocalizedStrings().label.BATCH_SMS.CHARS}</span>
                                    <div>
                                        {/* {tempAttachFiles.length > 0 && <div style={{ float: "left", marginTop: 10 }}>
                                                {getAttachmetFiles()}
                                            </div>} */}
                                        <div style={{ float: 'right', display: 'flex' }}>
                                            {showAttachment && <span onClick={() => openAttachment()} style={{ cursor: 'pointer', marginLeft: 9, marginTop: 10, color: 'gray' }} title={getLocalizedStrings().message.SMS_INTEGRATION.IMAGE_SELECT}><i className="fa fa-file-image" style={{ fontSize: '20px' }} ></i></span>}
                                            <div style={{ marginTop: '0px' }}>{getActionButton()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (isLoaded && !isAccessible) {
        return (<div className="loaderh">
            {<div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '38%' }}>
                    <div >
                        Please configure SMS feature before using Inbox
                    </div>
                </div>
            </div>}
        </div>);
    }
    else if (isLoaded && !isFromNumberAvailablee && allocatePhoneNumbers.length === 0) {
        return (<div className="loaderh">
            {<div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '38%' }}>
                    <div >
                        Please  lease phone numbers before using Inbox feature.
                    </div>
                </div>
            </div>}
        </div>);
    }
    else {
        return (<div className="loaderh">
            {<div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>}
        </div>);
    }
}

export default SMSInbox;