import React from 'react';
import { Link } from 'react-router-dom';
import { isValidParam, getBooleanParam, getArrayParam, getIntParam, getObjectParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { deleteCatagory } from '../../../services/actions/usefulLinksAction';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { constants } from '../../../services/constants/constants';
import { getAppDrawer, getAppDialog } from '../../../services/actions/appContainerActions';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import PopOver from './PopOver';
import { getObjectLabelByObject } from '../../../services/helper/common';
import NoRecords from '../components/noRecord';
import Button from '@mui/material/Button';
import { Icon } from '@mui/material';
import { setHeader } from '../../../services/actions/headerActions';
import { useEffect, useState } from "react";
import { getUsefulLinks } from '../../../services/actions/usefulLinksAction';
import { useDispatch, useSelector } from "react-redux";

const UsefulLinksContainer =({object,type,onMenuChange})=> {
    const dispatch = useDispatch();
    const [id, setId] = useState(0);
    const [title, setTitle] = useState('');
    const [items, setItems] = useState('');
    const [mouseOverId, setMouseOverId] = useState('');
    const [movedToCategory, setMovedToCategory] = useState('');

    const appState = useSelector((state) => state.app);
    const usefulLink = useSelector((state) => state.usefulLink);
    const userProjectId = useSelector((state) => state.app.me.projectId);
    const is_tenant = useSelector((state) => state.app.me.is_tenant);

    useEffect(() => {
        setHeaderData();
        getUsefulLinksRecors();
    }, []);

    const getUsefulLinksRecors = () => {
        let tempSelectedModuleName = getStringParam(appState.selectedModuleName);
        let params = {};
        if (tempSelectedModuleName === constants.MODULE_HUB) {
            params.is_franchise = true;
        }
        getUsefulLinks(params);
    }

    const setHeaderData = () => {
        let showAddIcon = false;
        if (getBooleanParam(is_tenant)) {
            showAddIcon = true;
        }
        let label = getObjectLabelByObject(object);
        dispatch(setHeader(object, label, null, showAddIcon, true));
    }

    const getLink = (props) =>{
        let element = null;
        let rootStyle = { maxHeight: 20, display: 'flex', alignItems: 'center', padding: '0px 10px' };
        let style = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '91%' };
        let linkStyle = { fontSize: '20px', lineHeight: '32px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };

        props = getObjectParam(props);
        let mouseOverelements = null;
        if (getStringParam(props.elementId) !== '' && getBooleanParam(is_tenant) && getIntParam(props.t_projectid) === getIntParam(userProjectId)) {
            mouseOverelements = getMouseOverIcons(props);
        }
        let type = getStringParam(props.t_type);
        if (type === 'category') {
            rootStyle.maxHeight = 20;
            linkStyle.color = '#555555';
            element = <span style={{ display: 'flex' }}> <span style={{ ...linkStyle }} title={props.category_name}>{props.category_name}</span> </span>;
        } else if (type === 'link') {
            linkStyle.fontSize = '14px';
            let linkProps = { key: props.title, to: '#', style: { cursor: 'pointer' } };
            if (isValidParam(onMenuChange)) {
            linkProps.onClick = () => onMenuChange(props);
            }
            element = <Link {...linkProps}>
            <span style={linkStyle} title={props.title} onClick={()=>openUsefulLink(props)} >{props.title}</span>

            </Link>;
        } else {
            rootStyle.height = 10;
        }
        element = [<div style={style} > {element}</div>, mouseOverelements];
        element = <div id={props.elementId} style={rootStyle} onMouseOver={()=>handleMouseOver(props.elementId)} onMouseLeave={()=>handleMouseOut(props.elementId)}> {element} </div>;
        return element;
    }

  const openUsefulLink = (props) => {
    let websiteAddress = props.link;
    if ((websiteAddress.toLowerCase().indexOf("http:") !== -1) || (websiteAddress.toLowerCase().indexOf("https:") !== -1)) {
      window.open(websiteAddress);
    } else {
      window.open('http://' + websiteAddress);
    }
  }

  const handleMouseOver = (elmentId) => {
      if (isValidParam(elmentId) && mouseOverId !== elmentId) {
        setMouseOverId(elmentId);
      }
  }

  const handleMouseOut = (elmentId, event) => {
    setMouseOverId('');
  }

  const handleEdit = (props, event) => {
      let type = getStringParam(props.t_type);
      let label = getLocalizedStrings().label.COMMON.EDIT + " " + getLocalizedStrings().label.USEFUL_LINKS[type.toUpperCase()];
      dispatch(getAppDrawer(true, label, constants.USEFUL_LINK_OBJECT, props, null));
  }

  const getCategriesForDelete = (category_name) => {
    let list = [];
      let _usefulLinks = Object.assign([], getArrayParam(usefulLink));
      _usefulLinks = _usefulLinks.filter(f => { return f.t_type === 'category' && f.category_name !== category_name });
      list = _usefulLinks.map(m => { return { value: m.category_name, label: m.category_name }; });
      let tempMovedToCategory = getStringParam(movedToCategory);
      if (tempMovedToCategory === '') {
        setMovedToCategory(list.length > 0 ? list[0].label : '')
      }
    return list;
  }

  const onChange = (event) => {
    let currentValue = event.target.value;
    setMovedToCategory(currentValue);
  }

  const handleDelete = (props) => {
      let type = getStringParam(props.t_type).toLowerCase();
      let confirmMessage = '';
      let successDeleteMessage = '';
      let isEmptyCategory = false;
      let data = null;
      if (type === 'category') {
        confirmMessage = <div className="sf-dialog-content" style={{paddingTop:20}} >
        {getLocalizedStrings().message.USEFUL_LINKS.CONFIRM_DELETE_CATEGORY}
        </div>;
        successDeleteMessage = getLocalizedStrings().message.USEFUL_LINKS.CATEGORY + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
        let items = getArrayParam(props.items);
        if (isValidParam(items) && items.length > 0 && usefulLink.length > 1) {
          let list = getCategriesForDelete(props.category_name);
          if (list.length > 0) {
            setMovedToCategory(list[0].value);
          }
          confirmMessage = <div className="sf-dialog-content" style={{paddingTop:20}} >
            <div>{getLocalizedStrings().message.USEFUL_LINKS.EXISTING_LINKS_CATGORY}</div>
            <div style={{display:'flex'}} >

            <div style={{}}>{getLocalizedStrings().message.USEFUL_LINKS.PLEASE_MOVE_LINKS} &nbsp; </div>
            <PopOver
              id={'menu-usefullink'}
              key={'menu-usefullink'}
              name={'menu-usefullink'}
              btnType={'text'}
              labelposition={"before"}
              options={list}
              width={'inherit'}
              containerStyle = {{ width: 'inherit' }}
              onclickAction={(event)=>onChange(event)}
              value={movedToCategory} />
            <div>. {getLocalizedStrings().message.USEFUL_LINKS.CLICK_TO_CONFIRM}</div>
            </div>
          </div>;
        } else if ((isValidParam(items) && items.length === 0) || (isValidParam(items) && usefulLink.length === 1)) {
          isEmptyCategory = true;
        }
      } else if (type === 'link') {
        confirmMessage = getLocalizedStrings().message.USEFUL_LINKS.CONFIRM_DELETE_LINK;
        successDeleteMessage = getLocalizedStrings().message.USEFUL_LINKS.LINK + getLocalizedStrings().message.COMMON.DELETE_SUCCESS;
      }
      data = <div>
        <div>
          {confirmMessage}
        </div>
        <div style={{ marginTop: 30, float: 'right' }}>
          <Button
            key='close'
            onClick={(e) => closeDialog(e)}
            style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px', disabled: 'disabled', verticalAlign: 'top' }}
          >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
          <Button
            key='close'
            onClick={() => deleteLinks(props, successDeleteMessage)}
            style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px', disabled: 'disabled', verticalAlign: 'top' }}
          >{getLocalizedStrings().label.COMMON.OK}</Button>
        </div>
      </div>
      if (isEmptyCategory) {
        deleteLinks(props, successDeleteMessage);
      } else {
        dispatch(getAppDialog(true, constants.USEFUL_LINK_OBJECT_CATEGORY, getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, null, data, constants.USEFUL_LINK_OBJECT_CATEGORY));
      }
  }
  const closeDialog = () => {
    dispatch(getAppDialog(false, constants.USEFUL_LINK_OBJECT_CATEGORY, null, null, null, null));
  }
  const deleteLinks = (props, successDeleteMessage) => {
      let type = getStringParam(props.t_type).toLowerCase();
      let params = {};
      params.t_pk = getIntParam(props.t_pk);
      params.t_type = type;
      params.category_name = getStringParam(props.category_name);
      params.moved_to_category = getStringParam(movedToCategory);
      let promise1 = null;
      promise1 = deleteCatagory(params);
      promise1.then((response) => {
        if (isValidParam(response) && response.status === 0) {
          closeDialog();
          dispatch(showCustomSnackBar(successDeleteMessage, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
          getUsefulLinks();
          setMovedToCategory('');
        }
      })
  }

  const getMouseOverIcons = (props) => {
    let mouseOverElements = null;
      let style = { display: 'none', color: '#717171', verticalAlign: 'top' };
      let type = getStringParam(props.t_type);
      if (getStringParam(props.elementId) === getStringParam(mouseOverId)) {
        style.display = 'inline-block';
      }
      mouseOverElements = <div style={{ float: 'right', verticalAlign: 'top', padding: '2.2% 0px', width: '50px' }} >
        {/* {type !== 'category' && */}
          < div style={{ ...style, ...{ paddingTop: 4 } }}>
            <span style={{ cursor: 'pointer', }} title={getLocalizedStrings().label.COMMON.EDIT} onClick={()=>handleEdit(props)} >
              <Icon key={props.t_pk} style={{ fontSize: '0.9em' }} >edit</Icon>
            </span>
          </div>
        {/* } */}
        < div style={{ ...style, ...{ paddingTop: 2 } }}>
          <span style={{ cursor: 'pointer', }} title={getLocalizedStrings().label.COMMON.DELETE} onClick={() =>handleDelete(props)}>
            <Icon key={props.t_pk} style={{ fontSize: '1.2em', fontWeight: 'normal' }} >close</Icon>
          </span>
        </div>

      </div>
    return mouseOverElements;
  }

  const getMenu = (menuItems) => {

    let menuElements = [];
      menuItems.forEach((category, index) => {
        let items = getArrayParam(category.items);
        sortArrayObjectByProperty(items, 'title');
        menuElements.push(<div >
          {getLink({ ...category, elementId: 'category-' + index })}
          {getLink()}
          {items.map((item, itemIndex) => {
            return getLink({ category_name: category.category_name, ...item, elementId: 'link-' + index + '-' + itemIndex });
          })}
          {getLink()}
        </div>);
      });
    return menuElements;
  }
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;
    let { mounted, usefulLinks } = usefulLink;

    let sortedMenuItems = getArrayParam(usefulLinks);
    sortArrayObjectByProperty(sortedMenuItems, 'category_name');
    return <div style={{ width: '98%', paddingLeft: 20 }}>
      {!mounted && <div style={{ width: '100%', height: contentHeight }}>
        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
          <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
          </div>
        </div>
      </div>}
      {mounted && sortedMenuItems.length === 0 &&
        <NoRecords
          key={'no-records'}
          object={constants.USEFUL_LINK_OBJECT}
        />
      }
      {mounted && sortedMenuItems.length > 0 && <div id="links" style={{ display: 'grid', gridTemplateColumns: '33.33% 33.33% 33.33%', gridGap: '10px' }} >
        {getMenu(sortedMenuItems)}
      </div>}
    </div>
}

export default UsefulLinksContainer;


