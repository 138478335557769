import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { isValidParam, getStringParam, getObjectParam, isUrl } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { doRefreshAssetsTree, updateisAssetsUpdated, updateDocumentLibrary } from '../../../services/actions/documentLibraryActions';
import { doRefreshAssets } from '../../../services/actions/headerActions';
import { getAssetsTreeItem, getAssetsTree } from '../../../services/helper/common';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { constants } from '../../../services/constants/constants';

/**
 * 
 * @Author  : Malay G.
 * @Date:   : 6-May-2019
 */

const AssetsCreateFolder = () => {

    const [title, setTitle] = useState(null);
    const [url, setUrl] = useState(null);
    const [ext, seExt] = useState(null);
    const [isError, setIsError] = useState(false);
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const documentLibrary = useSelector((state) => state.documentLibrary);

    const dispatch = useDispatch();

    useEffect(() => {
        if ((title != null && title != undefined) || (url != null && url != undefined)) {
            dispatch(setAppDialogActions(getActions(false)));
        } else {
            let _data = getObjectParam(appContainer.dialogProps.data);
            if (_data.key == 'NewFolder') {
                setTitle(_data.title);
            }
            if (_data.t_type == "external link" || _data.t_type == "folder" || _data.selectedLibrary == constants.IMAGES_LIBRARY) {
                setTitle(_data.title);
            } else {
                const lastDotIndex = _data.title.lastIndexOf('.');
                if (lastDotIndex > 0) {
                    setTitle(_data.title.slice(0, lastDotIndex))
                    seExt(_data.title.slice(lastDotIndex + 1))
                }
            }
            if (_data.t_type == "external link") {
                setUrl(_data.url ?? null);
            }
        }
    }, [title, url]);

    const getActions = (disabled) => {
        let primaryBtn = '';
        let btnStyle = styles.primaryButton;
        if (disabled) {
            btnStyle = styles.secondaryButton;
        }
        if (appContainer.dialogProps.eventName === 'rename') {
            primaryBtn = <Button
                primary={true}
                onClick={() => renameFolder()}
                style={btnStyle}
                disabled={disabled}
            >{getLocalizedStrings().label.COMMON.UPDATE}</Button>
        } else {
            primaryBtn = <Button
                primary={true}
                onClick={() => addFolder()}
                style={btnStyle}
                disabled={disabled}
            >{getLocalizedStrings().label.COMMON.CREATE}</Button>
        }
        let secondaryBtn = <Button
            primary={true}
            onClick={() => dispatch(getAppDialog(false, null, null, null, null, null))}
            style={styles.secondaryButton}
        // disabled={disabled}
        >{getLocalizedStrings().label.COMMON.CLOSE}</Button>

        const actions = [primaryBtn, secondaryBtn];
        return actions;
    }

    const onChange = (type, event) => {
        if (type == 'title') {
            setTitle(event.target.value);
        } else {
            setUrl(event.target.value);
        }
    }

    const addFolder = () => {
        let type = "folder";
        let _title = getStringParam(title).trim();
        let key = '';
        try {
            let _data = getObjectParam(appContainer.dialogProps.data);
            let params = {};
            params.key = _data.key;
            params.title = _data.title;
            params.type = type;
            params.shareId = _data.shareId;
            params.parentId = _data.parentId;
            //params.childId = _data.childId;
            let shareId = getStringParam(_data.shareId);
            if (_title.length == 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.FOLDER_NAME + ' ' + getLocalizedStrings().message.COMMON.VALIDATE_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                dispatch(setAppDialogActions(getActions(true)));
                params.title = _title;
                params.type = type;
                var promise = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.ASSETS_FOLDER_SAVE, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (response.status == 0) {
                            dispatch(getAppDialog(false, null, null, null, null, null));
                            key = response.data.folderId;
                            let bucketId = response.data.bucketId;
                            let _childId = response.data.childId;
                            let _maindata = [...documentLibrary.mainData];
                            _maindata.push({ key: key, uniqueKeys: key, accessType: "Write", bucketId: bucketId, title: _title, name: _title, t_type: type, isLeaf: false, parentId: _data.parentId, childId: _childId, shareId: shareId, isShared: false });
                            dispatch(updateDocumentLibrary([..._maindata]))
                            dispatch(updateisAssetsUpdated(true));
                            dispatch(showCustomSnackBar('"' + _title + '" ' + getLocalizedStrings().message.ATTACHMENT.ADDED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        else {
                            if (response.status == -1 && response.error.message == "Duplicate") {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.FOLDER_NAME + ' "' + _title + '" ' + getLocalizedStrings().message.ATTACHMENT.ALREADY_EXIST, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            } else {
                                dispatch(showCustomSnackBar('Somthing went wrong', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error on assetsCreateFolder.js >> addFolder: " + error);
        }
    }

    const renameFolder = () => {
        let _title = getStringParam(title).trim();
        let _url = getStringParam(url).trim();
        let type = "folder";
        try {
            let _data = getObjectParam(appContainer.dialogProps.data);
            console.log(_data);
            let params = {};
            params.key = _data.key;
            let isFile = false;
            let isExtLink = _data.t_type == "external link" ? true : false;
            if (appContainer.dialogProps.dialogTitle == getLocalizedStrings().label.DOCUMENT_LIBRARY.RENAME_FILE) {
                isFile = true;
                type = "file";
            }
            if (_title.length == 0) {
                let msgText = getLocalizedStrings().message.ATTACHMENT.FOLDER_NAME + ' ' + getLocalizedStrings().message.COMMON.VALIDATE_BLANK;
                if (_data.selectedLibrary == constants.IMAGES_LIBRARY) {
                    msgText = getLocalizedStrings().message.ATTACHMENT.IMAGE_NAME + ' ' + getLocalizedStrings().message.COMMON.VALIDATE_BLANK;
                }
                if (_data.t_type == "external link") {
                    msgText = getLocalizedStrings().message.DOCUMENT_LIBRARY.BLANK_TITLE_ERROR
                }
                setIsError(true);
                dispatch(showCustomSnackBar(msgText, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (_data.t_type == "external link" && _url.length == 0) {
                setIsError(true);
                let msgText = getLocalizedStrings().message.DOCUMENT_LIBRARY.BLANK_URL_ERROR;
                dispatch(showCustomSnackBar(msgText, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (_data.t_type == "external link" && !isUrl(_url)) {
                setIsError(true);
                dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_URL_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                setIsError(false);
                let url = endPoints.DOCUMENT_LIBRARY.ASSETS_FOLDER_RENAME;
                if (_data.selectedLibrary == constants.IMAGES_LIBRARY) {
                    type = "file";
                    _title = _title + '.' + _data.ext;
                    params.ext = _data.ext;
                    params.title = title;
                    params.image_name = _title;
                    params.type = type;
                    params.id = _data.id
                    url = endPoints.DOCUMENT_LIBRARY.EDIT;
                } else {
                    if (_data.t_type == "external link" || _data.t_type == "folder") {
                        params.title = _title;
                        if (_data.t_type === "external link") {
                            params.url = _url;
                            params.type = _data.t_type;
                        } else {
                            params.parentId = _data.parentId;
                            params.type = _data.t_type;
                        }
                    } else {
                        _title = _title + '.' + ext;
                        params.title = _title;
                        params.type = type;
                    }
                }
                var promise = Promise.resolve(HTTPClient.post(url, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (response.status == 0) {
                            let msg = null;
                            let _maindata = [...documentLibrary.mainData];
                            let _finalMmaindata = [];
                            let _
                            if (_data.selectedLibrary == constants.DOCUMENT_LIBRARY && !isFile && !isExtLink) {
                                msg = getLocalizedStrings().message.ATTACHMENT.RENAME_SUCCESSFULLY;
                                _finalMmaindata = _maindata.map((item) => {
                                    if (item?.key == _data.key) {
                                        item.title = _title;
                                        item.name = _title;
                                    }
                                    return item;
                                });

                            } else if (_data.selectedLibrary == constants.DOCUMENT_LIBRARY && isFile && !isExtLink) {
                                msg = getLocalizedStrings().message.ATTACHMENT.FILE_RENAME_SUCCESSFULLY;
                                _finalMmaindata = _maindata.map((item) => {
                                    if (item?.key == _data.key) {
                                        item.title = _title;
                                        item.name = _title;
                                    }
                                    return item;
                                });
                            } else if (_data.selectedLibrary == constants.IMAGES_LIBRARY && !isExtLink) {
                                msg = getLocalizedStrings().message.ATTACHMENT.IMAGE_RENAME_SUCCESSFULLY;
                                _finalMmaindata = _maindata.map((item) => {
                                    if (item?.key == _data.key) {
                                        item.title = _title;
                                        item.name = _title;
                                    }
                                    return item;
                                });
                            } else if (isExtLink) {
                                msg = getLocalizedStrings().message.ATTACHMENT.DOCUMENT_LINK_UPDATE_SUCCESSFULLY;
                                _finalMmaindata = _maindata.map((item) => {
                                    if (item?.key == _data.key) {
                                        item.title = _title;
                                        item.name = _title;
                                        item.url = _url;
                                    }
                                    return item;
                                });
                            }
                            dispatch(updateDocumentLibrary([..._finalMmaindata]));
                            dispatch(updateisAssetsUpdated(true));
                            dispatch(getAppDialog(false, null, null, null, null, null));
                            dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        } else {
                            if (response.status == -1 && (response.error.message == "Duplicate" || !response.data.is_edit)) {
                                if (_data.selectedLibrary == constants.IMAGES_LIBRARY) {
                                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.IMAGE_NAME + ' "' + _title + '" ' + getLocalizedStrings().message.ATTACHMENT.ALREADY_EXIST, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                } else {
                                    let editeType = getLocalizedStrings().message.ATTACHMENT.FOLDER_NAME;
                                    if (isFile) {
                                        editeType = getLocalizedStrings().message.ATTACHMENT.FILE_NAME;
                                    }
                                    if (isExtLink) {
                                        editeType = getLocalizedStrings().message.ATTACHMENT.DOCUMENT_LINK;
                                    }

                                    dispatch(showCustomSnackBar(editeType + ' "' + _title + '" ' + getLocalizedStrings().message.ATTACHMENT.ALREADY_EXIST, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                }
                            } else {
                                dispatch(showCustomSnackBar('Somthing went wrong', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        }
                    })
                }
            }
        } catch (error) {
            console.error("Error on assetsCreateFolder.js >> renameFolder: " + error);
        }
    }

    let params = getObjectParam(appContainer.dialogProps.data);
    let floatingLabel = getLocalizedStrings().message.ATTACHMENT.FOLDER_NAME;
    if (params.selectedLibrary === constants.IMAGES_LIBRARY) {
        floatingLabel = getLocalizedStrings().message.ATTACHMENT.IMAGE_NAME;
    }
    if (appContainer.dialogProps.dialogTitle === getLocalizedStrings().label.DOCUMENT_LIBRARY.RENAME_FILE) {
        floatingLabel = getLocalizedStrings().message.ATTACHMENT.FILE_NAME;
    }
    if (params.t_type === "external link") {
        floatingLabel = getLocalizedStrings().label.DOCUMENT_LIBRARY.TITLE_REQUIRED;
    }
    let linkLabel = getLocalizedStrings().label.DOCUMENT_LIBRARY.LINK_REQUIRED;
    console.log(params);
    return (
        <div className='sf-add-asset-folder'>
            <div style={{ width: '100%' }} >
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        key='title'
                        name='title'
                        value={title}
                        error={isError && (!title || title == "")}
                        onChange={(event) => onChange('title', event)}
                        label={floatingLabel}
                        InputLabelProps={{
                            style: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: '96%',
                            }
                        }}
                        fullWidth={true}
                        className={"sf-fields-bg"}
                        autoFocus={true}
                        autoComplete="new-password"
                        margin='dense'
                        size="small"
                    />
                </FormControl>
            </div>
            {params?.t_type === "external link" && <div style={{ width: '100%' }} >
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        key='url'
                        name='url'
                        value={url}
                        error={isError && (!url || url == "" || !isUrl(url))}
                        onChange={(event) => onChange('url', event)}
                        label={linkLabel}
                        InputLabelProps={{
                            style: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: '96%',
                            }
                        }}
                        fullWidth={true}
                        className={"sf-fields-bg"}
                        autoFocus={true}
                        autoComplete="new-password"
                        margin='dense'
                        size="small"
                    />
                </FormControl>
            </div>}

        </div>
    )
}

export default AssetsCreateFolder;

const loop = (data, key, callback) => {
    data.forEach((item, index, arr) => {
        if (item.key === key) {
            callback(item, index, arr);
            return;
        }
        if (item.children) {
            loop(item.children, key, callback);
        }
    });
};

