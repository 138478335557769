
import React from 'react';
import { Link } from 'react-router-dom';
import { isValidParam, getStringParam, getArrayParam, getObjectParam, getBooleanParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { getUniqueId } from '../../../services/helper/utils';
import { getMultiColumnValues, getMultiColumnTitles } from '../../../services/helper/common';
import Button from '@mui/material/Button';
import { styles } from '../../../services/constants/styles';
import { endPoints } from '../../../services/constants/endPoints';
import { TASKS_REPORTS_MAP, constants } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { connect } from 'react-redux';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { LinkToType } from '../../../services/constants/link';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Checkbox from '@mui/material/Checkbox';
import PopOver from './PopOver';
import { getAppCustomDrawer } from '../../../services/actions/appContainerActions';
/**
 * 
 * @Author  : Pradyut.s 
 * @Date:   : 2019-01-21
 */
const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        },
        getAppCustomDrawer: (isOpen, dialogName, actionType, style, data, headerProps, minimizable) => {
            dispatch(getAppCustomDrawer(isOpen, dialogName, actionType, style, data, headerProps, minimizable));
        },
    }
};

class SFDataTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            rows: [],
            rowSort: null,
            callfrom: null,
            customEvent: () => { },
            rowSelection: null
        }
        this.uniqueId = getUniqueId();
        this.setDefaultProps(this.props);
    }

    componentDidMount() {
        try {
            this.setDefaultProps(this.props);

        } catch (error) {
            console.error("Error in 'sfDataTable.js -> componentDidMount()':" + error);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        try {
            this.setDefaultProps(nextProps);

        } catch (error) {
            console.error("Error in 'sfDataTable.js -> componentDidMount()':" + error);
        }
    }

    setDefaultProps = (props) => {
        try {
            let columns = getArrayParam(props.columns);
            let rows = getArrayParam(props.rows);
            let rowSort = getObjectParam(props.rowSort);
            let callfrom = isValidParam(props.callfrom) ? props.callfrom : null;
            if (columns.length > 0 && !isValidParam(this.state.rowSort)) {
                rowSort = {
                    enableAllColumn: getBooleanParam(rowSort.enableAllColumn),
                    sortFieldName: getStringParam(rowSort.sortFieldName),
                    isDescending: isValidParam(rowSort.isDescending) ? getBooleanParam(rowSort.isDescending) : true,
                    onSort: rowSort.onSort instanceof Function ? rowSort.onSort : () => { }
                };
            } else {
                rowSort = this.state.rowSort;
            }
            let customEvent = isValidParam(props.customEvent) && props.customEvent instanceof Function ? props.customEvent : () => { };
            let rowSelection = getObjectParam(props.rowSelection);
            if (!isValidParam(this.state.rowSelection)) {
                rowSelection = {
                    showCheckbox: getBooleanParam(rowSelection.showCheckbox),
                    onRowsSelected: rowSelection.onRowsSelected instanceof Function ? rowSelection.onRowsSelected : () => { },
                    selectedIndexes: getArrayParam(this.state.selectedIndexes)
                }
            } else {
                rowSelection = this.state.rowSelection;
            }

            let data = { columns: columns, rows: rows, rowSort: rowSort, customEvent: customEvent, rowSelection, callfrom: callfrom };
            this.state = { ...this.state, ...data };
        } catch (error) {
            console.error("Error in 'sfDataTable.js -> setDefaultProps()':" + error);
        }
    }

    handleRowSelect = (rowIndex, event, isInputChecked) => {
        let rowSelection = getObjectParam(this.state.rowSelection);
        let selectedIndexes = getArrayParam(rowSelection.selectedIndexes);
        let rows = [...getArrayParam(this.state.rows)];
        if (rowIndex == -1) {
            selectedIndexes = isInputChecked ? rows.map((r, i) => { return i }) : [];
            if (isInputChecked) {
                selectedIndexes.unshift(-1);
            }

        } else {
            selectedIndexes = selectedIndexes.filter(f => { return f != rowIndex });
            if (isInputChecked) {
                selectedIndexes.push(rowIndex);
            }
        }

        if (rowIndex > -1) {
            if (isInputChecked) {
                if (rows.length == selectedIndexes.length) {
                    selectedIndexes.unshift(-1);
                }
            } else {
                selectedIndexes = selectedIndexes.filter(f => { return f != -1 });
            }
        }
        rowSelection.selectedIndexes = selectedIndexes.sort();
        this.setState({ rowSelection });

        if (isValidParam(rowSelection.onRowsSelected)) {
            let rowIndexes = selectedIndexes.filter(f => { return f != -1 });
            let selectedRows = new Array();

            rows.forEach((r, i) => {
                if (rowIndexes.indexOf(i) > -1) {
                    selectedRows.push(r);
                }
            });
            rowSelection.onRowsSelected(rowIndexes, selectedRows);
        }
    }

    getRowSelectCheckBox = (rowKey, index, isHeader) => {
        let element = null;
        let colProps = null;
        let chkBoxProps = null;
        try {
            index = getIntParam(index);
            isHeader = getBooleanParam(isHeader);
            let key = isHeader ? 'th-chekbox-header' : 'td-chekbox-row';
            colProps = new Object();
            colProps.key = key + '-' + rowKey;
            colProps.id = key + '-' + rowKey;
            colProps.name = key;
            colProps.style = { border: '1px solid #ddd', padding: 4, cursor: 'pointer', width: 20 };

            chkBoxProps = new Object();
            chkBoxProps.iconStyle = { marginRight: 0, width: 20, height: 20, };
            chkBoxProps.labelStyle = {};
            chkBoxProps.onChange = this.handleRowSelect.bind(this, index);
            chkBoxProps.checked = this.state.rowSelection.selectedIndexes.indexOf(index) > -1 ? true : false;
            chkBoxProps.value = this.state.rowSelection.selectedIndexes.indexOf(index) > -1 ? true : false;
            chkBoxProps.color = "default"
            element = <th {...colProps}><Checkbox {...chkBoxProps} /></th>;
        } catch (error) {
            console.error("Error in 'sfDataTable.js -> getRowSelectCheckBox()':" + error);
        }
        return element;
    }

    getHeader = (columns) => {
        let arrTh = new Array();
        let header = null;
        try {
            columns = getArrayParam(columns);
            let colProps = null;
            let style = null;
            let sortIcon = null;

            let rowSort = getObjectParam(this.state.rowSort);
            let showCheckbox = false
            if (this.state.rowSelection)
                showCheckbox = getBooleanParam(this.state.rowSelection.showCheckbox);
            if (showCheckbox) {
                arrTh.push(this.getRowSelectCheckBox(-1, -1, true));
            }

            columns.forEach((column, index) => {
                style = { border: '1px solid #ddd', padding: 6, fontWeight: 'bold', width: '10%', ...getObjectParam(column.style) };
                let width = getIntParam(column.width);
                if (width > 0) {
                    style.width = width;
                }

                colProps = new Object();
                colProps.key = 'th-' + column.name + '-' + index;
                colProps.name = column.name;
                colProps.title = isValidParam(getLocalizedStrings().label.UNITS[column.label]) ? getLocalizedStrings().label.UNITS[column.label] : column.label;
                colProps.style = style;

                if ((getBooleanParam(rowSort.enableAllColumn) || getBooleanParam(column.is_sortable)) && (!column.is_button)) {
                    colProps.onClick = this.sortEvent.bind(this, column);
                    colProps.style.cursor = 'pointer';
                }
                
                if (rowSort.sortFieldName == column.name) {
                    sortIcon = this.getSortIconElement(rowSort.isDescending);
                    if (!getBooleanParam(rowSort.enableAllColumn)) {
                        colProps.onClick = this.sortEvent.bind(this, column);
                        colProps.style.cursor = 'pointer';
                    }
                } else {
                    sortIcon = null;
                }
                if (column.hasOwnProperty('color')) {
                    colProps.style.color = column.color;
                }
                if (column.hasOwnProperty('is_currency') && column.is_currency) {
                    colProps.style.textAlign = 'right';
                }
                arrTh.push(<th {...colProps}>
                    {isValidParam(getLocalizedStrings().label.UNITS[column.label]) ? getLocalizedStrings().label.UNITS[column.label] : column.label}
                    {sortIcon}
                </th>);
            });
            if (arrTh.length > 0) {
                header = <thead key={this.uniqueId + '-thead'} style={{ backgroundColor: '#ffffff', textAlign:'left' }}><tr>{arrTh}</tr></thead>;
            }
        } catch (error) {
            console.error("Error in 'sfDataTable.js -> getHeader()':" + error);
        }
        return header;
    }

    getBody = (columns, rows) => {
        let arrTrs = new Array();
        let body = null;
        let arrTds = null;
        let button = null;
        let buttonProps = null;
        let showCheckbox = false;
        try {
            if (this.state.rowSelection)
                showCheckbox = getBooleanParam(this.state.rowSelection.showCheckbox);
            columns = getArrayParam(columns);
            rows = getArrayParam(rows);

            rows.forEach((row, rowIndex) => {
                arrTds = new Array();

                if (showCheckbox) {
                    let key = isValidParam(row.id) ? row.id : rowIndex + new Date().getTime()
                    arrTds.push(this.getRowSelectCheckBox(key, rowIndex));
                }
                columns.forEach((col, colId) => {
                    arrTds.push(this.getTd(colId, col, row));
                });
                let style = new Object();
                if (rowIndex % 2 == 0) {
                    style.backgroundColor = '#ffffff';
                }
                if (row.hasOwnProperty('franchisee')) {
                    if (row['franchisee'] == 'Total') {
                        style.backgroundColor = 'gainsboro';
                        style.fontWeight = 'bold';
                    }

                }
                if (row.hasOwnProperty('isTextBold') && row.isTextBold) {
                    style.backgroundColor = 'white';
                    style.fontWeight = 'bold';
                }
                style.borderBottom = '1px solid #ddd';
                arrTrs.push(<tr style={style}>{arrTds}</tr>);
            });

            if (arrTrs.length > 0) {
                body = <tbody key={this.uniqueId + '-tbody'}>{arrTrs}</tbody>;
            }
        } catch (error) {
            console.error("Error in 'sfDataTable.js -> getBody()':" + error);
        }
        return body;
    }

    OpenCorrectiveActionReportDetails = (column,rowData) =>{
        let data = {};
        data.eventType = "is_detail_link";
        data.column = column;
        data.row = rowData;
        data.object = 'correactive_action_details'
        let style = {};
        style.width = '80%';
        let type = constants.CORRECTIVE_ACTIONS_REPORT_DETAILS;
        let label = getLocalizedStrings().label.TASK_REPORT.CORRECTIVE_ACTION_REPORT_DETAILS;
        this.props.getAppCustomDrawer(true, label, type, style, data, null, false);
    }

    getTd = (colIndex, column, rowData) => {

        let tdElement = null;
        let valueElenent = {};
        let stylePDiv = {};
        try {
            let style = { padding: 6, width: getIntParam(column.width) };
            let colName = getStringParam(column.name);
            let value = getMultiColumnValues(column.name, rowData);
            let title = getMultiColumnTitles(column.name, rowData);
            value = value === "" ? "" : value;
            title = title === "" ? "" : title;
            if(colName === 'custom_field_date9') {
                value = value === "" ? "Empty" : value;
            }

            if (rowData[column.name] === "") {
                rowData[column.name] = "";
            }

            let isLink = this.isLink(column);
            if (rowData.hasOwnProperty('franchisee')) {
                if (rowData['franchisee'] == 'Total') {
                    isLink = false;
                }
            }
            if (isLink) {
                let linkProps = new Object();
                linkProps.title = title;
                linkProps.to = '#';

                let eventType = '';
                if (getBooleanParam(column.is_popover)) {
                    if (column.is_mobile_field) {
                        eventType = 'is_mobile_field';
                    }
                    value = [<span onClick={this.state.customEvent.bind(this, eventType, column, rowData, "")}>{value}</span>, this.state.customEvent('is_popover', column, rowData)];
                }
                else if (column.name === "completed" && this.props?.report_type === "corrective_action_report") {
                    let linkProps = new Object();
                    linkProps.title = title;
                    linkProps.to = '#';
                    linkProps.onClick = this.OpenCorrectiveActionReportDetails.bind(this,column,rowData);
                    value = <div {...valueElenent} className= 'aproved-row'><Link {...linkProps} style={{color:'green'}}>{value}</Link></div>;
                }else if (column.name === "overdue" && this.props?.report_type === "corrective_action_report") {
                    let linkProps = new Object();
                    linkProps.title = title;
                    linkProps.to = '#';
                    linkProps.onClick = this.OpenCorrectiveActionReportDetails.bind(this,column,rowData);
                    value = <div {...valueElenent} className= 'rejected-row'><Link {...linkProps} style={{color:'red'}} >{value}</Link></div>;
                }
                else {
                    if (column.is_detail_link) {
                        eventType = 'is_detail_link';
                    } else if (column.is_email_field) {
                        eventType = 'is_email_field';
                    } else if (column.is_phone_field) {
                        eventType = 'is_phone_field';
                    } else if (column.is_mobile_field) {
                        eventType = 'is_mobile_field';
                    }
                    linkProps.onClick = this.state.customEvent.bind(this, eventType, column, rowData, "");
                }
                value = <div {...valueElenent}><Link {...linkProps}  >{value}</Link></div>;
            }else if(column.name == 'photo_url'){
                value = <img src= {rowData.photoUrl} alt="" width="auto" height="20"/>;
            }else if(column.name == 'name'){
                value = <div title style={{width:'15rem',overflow:'hidden',textOverflow:'ellipsis'}} {...valueElenent}><span >{value}</span></div>;
            } else if (getBooleanParam(column.is_button)) {

                if (column.name == 'invoice') {
                    let status = rowData['t_status'];
                    if (status === "New") {
                        if (this.props.hasOwnProperty("calculateType")) {
                            if (this.props.calculateType === "Monthly") {
                                let eventType = 'is_button';
                                value = column.event_details.label;
                                title = value;

                                let props = new Object();
                                props.style = { ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top' }
                                props.label = getLocalizedStrings().label.ROYALTY_REPORT.ADD_INVOICES;
                                props.labelStyle = { padding: 0 };
                                props.onClick = this.state.customEvent.bind(this, eventType, column, rowData, "");
                                value = <Button {...props}>{props.label}</Button>;
                            } else {
                                let eventType = 'is_button';
                                let itemsArr = []
                                itemsArr.push({ value: 'with', label: getLocalizedStrings().label.ROYALTY_REPORT.WITH, rightIconClassName: null });
                                itemsArr.push({ value: 'without', label: getLocalizedStrings().label.ROYALTY_REPORT.WITH_OUT, rightIconClassName: null });
                                value = <PopOver
                                    id={"PopOver"}
                                    key={"PopOver"}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ ...styles.listViewPrimaryButton }}
                                    labelposition={"before"}
                                    buttonLabel={getLocalizedStrings().label.ROYALTY_REPORT.ADD_INVOICES}
                                    options={itemsArr}
                                    onclickAction={(value, e) => { this.state.customEvent(eventType, column, rowData, value, e); }}
                                    buttonLineOrigin={[28, -2]} />
                                    ;
                            }
                        } else {
                            let eventType = 'is_button';
                            value = column.event_details.label;
                            title = value;

                            let props = new Object();
                            props.style = { ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top' }
                            props.label = getLocalizedStrings().label.ROYALTY_REPORT.ADD_INVOICES;
                            props.labelStyle = { padding: 0 };
                            props.onClick = this.state.customEvent.bind(this, eventType, column, rowData, "");
                            value = <Button {...props}>{props.label}</Button>;
                        }
                    } else if (status != "New" && status != "") {

                        let linkProps = new Object();
                        linkProps.title = title;
                        linkProps.to = '#';

                        let eventType = '';
                        eventType = 'is_detail_link';

                        linkProps.onClick = this.state.customEvent.bind(this, eventType, column, rowData, "");

                        value = <div {...valueElenent}><Link {...linkProps}  >{value}</Link></div>;
                }

            } else {
                let eventType = 'is_button';
                value = column.event_details.label;
                title = value;

                let props = new Object();
                props.style = { ...styles.listViewPrimaryButton, fontSize: 14, height: '20px', lineHeight: '20px', marginTop: '0px', marginRight: '0px', minWidth: 55 };
                props.label = value;
                props.labelStyle = { padding: 0 };
                props.onClick = this.state.customEvent.bind(this, eventType, column, rowData, "");
                value = <Button {...props}>{props.label}</Button>;
            }

            } else if (getBooleanParam(column.is_link) && getBooleanParam(column.is_WorkflowStage)) {
                if (value == 'Completed') {
                    let iconStyle = { color: '#469748' };
                    value = <i className={'fa fa-check'} aria-hidden="true" style={iconStyle} title="Completed" />;
                } else if (value == 'Inprogress') {
                    value = <span><Link to={'#'} style={{ color: '#337ab7', cursor: 'pointer' }} onClick={this.allPendingTaskDetails.bind(this, rowData.id, rowData.status, rowData.clientId)} title='In Progress' >In Progress</Link></span>;
                } else if (isValidParam(value)) {
                    let countDetailsLinkStyle = { color: '#337ab7', cursor: 'pointer' };
                    let tasksValue = '';
                    let tasksLabel = '';
                    value.map(data => {
                        let counter = data.split('##');
                        let taskStstus = counter[0] == 'Done' ? constants.TASK_STATUS_COMPLETED : (counter[0] == 'Open' ? constants.TASK_STATUS_PENDING : '');
                        let statusLabel = getLocalizedStrings().label.UNITS.hasOwnProperty(counter[0]) ? getLocalizedStrings().label.UNITS[counter[0]] : counter[0];
                        if (counter[1] > 0) {
                            tasksValue = <span><span>{tasksValue}</span> <span><Link to={'#'} id='TaskShowTagId' onClick={this.allTaskDetails.bind(this, taskStstus, rowData.id, counter[1], rowData.status)} style={countDetailsLinkStyle} title={statusLabel + ' (' + counter[1] + ')'}>{statusLabel + ' (' + counter[1] + ')'}</Link></span>&nbsp;&nbsp;</span>;
                        } else {
                            tasksValue = <span><span>{tasksValue}</span> <span><Link to={'#'} id='TaskShowTagId' style={{ cursor: 'default', textDecoration: 'none' }} title={statusLabel + ' (' + counter[1] + ')'}>{statusLabel + ' (' + counter[1] + ')'}</Link></span>&nbsp;&nbsp;</span>;
                        }

                        tasksLabel = tasksLabel + statusLabel + ' (' + counter[1] + ') ';
                    });
                    value = <div>{tasksValue}</div>;
                    title = tasksLabel;
                }
            }
            let divStyle = new Object();
            if (rowData.hasOwnProperty('isTextBold') && rowData.isTextBold) {
                divStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold', ...getObjectParam(column.style) };
            } else {
                divStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', ...getObjectParam(column.style) };
            }
            if (column.hasOwnProperty('is_currency') && column.is_currency) {
                divStyle = { ...divStyle, textAlign: 'right' };
            }
            tdElement = <td key={'td-' + colName + '-' + colIndex} name={colName} title={title} style={style}>
                <div style={divStyle}>{value}</div>
            </td>;
        } catch(error) {
            console.error("Error in 'sfDataTable.js -> getTd()':" + error);
        }
        return tdElement;
    }

allTaskDetails = (status, recordId, count, stageName) => {
    let params = {
        id: recordId,
        status: status,
        stageName: stageName,
    };
    let actionType = LinkToType.TYPE_SF_CUSTOM_DATA_TABLE;
    let data = new Object();
    try {
        if (count > 0) {
            let url = endPoints.TASK.ONBOARDING_TASK_GET;
            var promise = Promise.resolve(HTTPClient.get(url, params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        data.records = response.record;
                        data.columns = tasksColumns.map(col => {
                            col.label = getLocalizedStrings().label.UNITS.hasOwnProperty(col.label) ? getLocalizedStrings().label.UNITS[col.label] : col.label;
                            return col;
                        });
                        this.props.getAppDrawer(true, getLocalizedStrings().label.UNITS.TASK_DETAILS, actionType, data, null);
                    }
                });
            }
        }
    } catch (error) {
        console.error("Error in 'sfDataTable.js -> allTaskDetails()':" + error);
    }
}

allPendingTaskDetails = (recordId, stageName, clientId) => {
    let params = {
        id: recordId,
        stageName: stageName
    };
    let actionType = LinkToType.TYPE_SF_CUSTOM_DATA_TABLE;
    let data = new Object();
    try {
        if (clientId > 0) {
            let url = endPoints.TASK.ONBOARDING_PENDING_TASK_GET;
            var promise = Promise.resolve(HTTPClient.get(url, params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let records = getArrayParam(response.record);
                        records.sort(function (a, b) {
                            var key1 = new Date(a.dueBy);
                            var key2 = new Date(b.dueBy);

                            if (key1 < key2) {
                                return -1;
                            } else if (key1 == key2) {
                                return 0;
                            } else {
                                return 1;
                            }
                        });
                        data.records = records;
                        data.columns = tasksColumns.map(col => {
                            col.label = getLocalizedStrings().label.UNITS.hasOwnProperty(col.label) ? getLocalizedStrings().label.UNITS[col.label] : col.label;
                            return col;
                        });
                        if (clientId > 0) {
                            data.msg = getLocalizedStrings().message.UNITS.NO_UNIT_TASKS;
                        } else {
                            data.msg = getLocalizedStrings().message.UNITS.NO_ACCOUNT_TASKS;
                        }

                        this.props.getAppDrawer(true, getLocalizedStrings().label.UNITS.TASK_DETAILS, actionType, data, null);

                    }
                });
            }
        } else {
            data.records = [];
            data.columns = tasksColumns.map(col => {
                col.label = getLocalizedStrings().label.UNITS.hasOwnProperty(col.label) ? getLocalizedStrings().label.UNITS[col.label] : col.label;
                return col;
            });
            data.msg = getLocalizedStrings().message.UNITS.NO_ACCOUNT_TASKS;
            this.props.getAppDrawer(true, getLocalizedStrings().label.UNITS.TASK_DETAILS, actionType, data, null);
        }

    } catch (error) {
        console.error("Error in 'sfDataTable.js -> allPendingTaskDetails()':" + error);
    }
}


isLink = (column) => {
    let isLink = false;
    try {
        let colName = getStringParam(column.name);

        let isPhoneField = getBooleanParam(column.is_phone_field);
        let isMobileField = getBooleanParam(column.is_mobile_field);
        let isClickToCall = getBooleanParam(column.is_click_to_call);
        let isTwilio = getBooleanParam(column.is_twilio);
        let isTwilioSms = getBooleanParam(column.is_twilio_sms);
        let isRingCentral = getBooleanParam(column.is_ring_central);

        if (isClickToCall) {
            if (isPhoneField && (isTwilio || isRingCentral)) {
                isLink = true;
            } else if (isMobileField && (isTwilio || isRingCentral || isTwilioSms)) {
                isLink = true;
            }
        } else if (getBooleanParam(column.is_detail_link) || getBooleanParam(column.is_email_field) || getBooleanParam(column.is_click_to_call)) {
            isLink = true;
        }
    } catch (error) {
        console.error("Error in 'sfDataTable.js -> isLink()':" + error);
    }
    return isLink;
}

getSortIconElement = (isDescending) => {
    let element = null;
    let ascendingIcon = '▲';
    let descendingIcon = '▼';
    try {
        let rowSort = getObjectParam(this.state.rowSort);
        let icon = getBooleanParam(isDescending) ? descendingIcon : ascendingIcon;
        element = <span key={rowSort.sortFieldName} style={{ float: 'right' }}>{icon}</span>;
    } catch (error) {
        console.error("Error in 'sfDataTable.js -> getSortIconElement()':" + error);
    }
    return element;
}

sortEvent = (column) => {
     
        let rowSort = getObjectParam(this.state.rowSort);
        let isDescending = rowSort.isDescending ? false : true;
        let sortFieldName = getStringParam(column.name);
        if (sortFieldName != '' && rowSort.sortFieldName != sortFieldName) {
            isDescending = true;
            rowSort.sortFieldName = sortFieldName;
        }

        rowSort = { ...rowSort, isDescending: isDescending };

        if (isValidParam(this.state.rowSelection)) {
            this.state.rowSelection.selectedIndexes = [];
        }

        this.setState({ rowSort: rowSort });

        if (isValidParam(rowSort.onSort)) {
            rowSort.onSort(sortFieldName, isDescending, this.state.rows)
        }
   
}

getTable = () => {
    let tableElement = null;
    try {
        let columns = [...getArrayParam(this.state.columns)];
        let rows = [...getArrayParam(this.state.rows)];
        columns = columns.filter(f => { return !f.hasOwnProperty('is_hidden') || !f.is_hidden });
        let style = {
            width: '98%',
            borderCollapse: 'collapse',
            fontStyle: 'normal',
            fontSize: 14,
            border: '1px solid #ddd',
            marginLeft: '10px',
         };
        tableElement = <table key={this.uniqueId + '-table'} ref='sfCustomTable' id="sfCustomTable" style={style}>
            {this.getHeader(columns)}
            {this.getBody(columns, rows)}
        </table>;

    } catch (error) {
        console.error("Error in 'sfDataTable.js -> getTable()':" + error);
    }
    return tableElement;
}

render() {
    let contentHeight = document.body.clientHeight - 250;
    if (!this.state.callfrom || this.state.callfrom == TASKS_REPORTS_MAP.TYPE_TASKS_REPORT || this.state.callfrom == constants.ROYALTY_REPORT_OBJECT_V2) {
        contentHeight = '';
    }
    let style = { maxHeight: contentHeight };
    style = { ...style, ...getObjectParam(this.props.style) };

    return <div key={this.uniqueId + 'sfDataTableContainer'} ref='sfDataTableContainer' style={style}>
        {this.getTable()}
    </div>;
}
}

export default connect(mapStateToProps, mapDispatchToProps)(SFDataTable);


const tasksColumns = [
    { name: 'name', width: 200, label: 'Tasks Name' },
    { name: 'dueBy', width: 150, label: 'Due By' },
    { name: 'status', width: 80, label: 'Status' },
    { name: 'owner', width: 100, label: 'Assigned To' }
];