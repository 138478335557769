import React, { useState } from 'react';
import { Button, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { getAppDialog, updateAppDialogTitle } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants } from '../../../services/constants/constants';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import * as sfDialogs from "../components/sfDialogs";
import { getAssetsTree, getAssetsTreeItem } from '../../../services/helper/common';
import { doRefreshAssetsTree, updateisAssetsUpdated, updateDocumentLibrary } from '../../../services/actions/documentLibraryActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getStringParam, isUrl } from '../../../services/helper/parameterVerifier';
import Icon from '@mui/material/Icon';
import ShowCircularProgress from '../components/circularProgress';
import { useDispatch, useSelector } from 'react-redux';

const UploadDocument = ({ }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const documentLibrary = useSelector((state) => state.documentLibrary);
    const [addDocument, setAddDocument] = useState(false);
    const [fileType, setFileType] = useState('file');
    const [fileTitle, setFileTitle] = useState('');
    const [fileLink, setFileLink] = useState('');
    const [isError, setIsError] = useState(false);
    const [file, setFile] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [files, setFiles] = useState([]);
    const [selectedLibrary, setSelectedLibrary] = useState(appContainer.dialogProps.data.selectedLibrary);


    const iconStyles = {
        marginLeft: 5,
    };

    const inlinestyle = {
        button: {
            marginRight: 20,
        },
        exampleImageInput: {
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            width: '100%',
            opacity: 0,
        }
    };

    const documentUpload = (files) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i].file);
        }
        formData.append('input_param', JSON.stringify({
            documentId: 0,
            parentId: appContainer.dialogProps.data.parentId,
            //childId: appContainer.dialogProps.data.childId,
            shareId: appContainer.dialogProps.data.shareId,
        }));
        let url = '';
        if (selectedLibrary == constants.IMAGES_LIBRARY) {
            url = endPoints.DOCUMENT_LIBRARY.UPLOAD;
        } else {
            url = endPoints.DOCUMENT_LIBRARY.UPLOAD_DOCUMENT;
        }
        return HTTPClient.postFormData(url, formData);
    }

    const uploadDocument = () => {
        try {
            let _selectedLibrary = isValidParam(selectedLibrary) ? selectedLibrary : null;
            let treeData = documentLibrary.mainData;
            if (files.length == 0 || files == null) {
                if (_selectedLibrary == constants.IMAGES_LIBRARY) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_UPLOAD_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.DOCUMENT_FILE_EMPTY_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                return false;
            } else {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].file.size >= 1024) {
                        let kb = (files[i].file.size / 1024).toFixed(2);
                        if (kb > (1024 * 50)) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.OVER_MAX_SIZE, styles.snackbarBodyStyleError, styles.snackBarStyleLongMsg));
                            return false;
                        }

                    }
                }
            }
            let shareId = getStringParam(appContainer.dialogProps.data.shareId);
            setInProgress(true);
            setAddDocument(true);
            setSelectedLibrary(_selectedLibrary);
            let promise = Promise.resolve(documentUpload(files));

            promise.then((response) => {
                if (response.data.status == 0) {

                    setInProgress(false);
                    setAddDocument(false);
                    setSelectedLibrary(_selectedLibrary);
                    let _maindata = [...documentLibrary.mainData];
                    for (let i = 0; i < response.data.fileDataList.length; i++) {
                        let newItem = {};
                        newItem.key = response.data.fileDataList[i].key;
                        newItem.name = response.data.fileDataList[i].name;
                        newItem.title = response.data.fileDataList[i].name;
                        newItem.t_type = response.data.fileDataList[i].t_type;
                        newItem.size = response.data.fileDataList[i].size;
                        newItem.creator = response.data.fileDataList[i].creator;
                        newItem.date = response.data.fileDataList[i].date;
                        newItem.parentId = appContainer.dialogProps.data.parentId;
                        newItem.childId = appContainer.dialogProps.data.childId;
                        newItem.isLeaf = true;
                        newItem.userid = app.me.id;
                        if (selectedLibrary == constants.IMAGES_LIBRARY) {
                            newItem.id = response.data.fileDataList[i].ImageId;
                            newItem.src = response.data.fileDataList[i].src;
                        } else {
                            newItem.id = response.data.fileDataList[i].id;
                            newItem.bucketId = response.data.fileDataList[i].bucketId
                            if (response.data.fileDataList[i].hasOwnProperty('src')) {
                                newItem.url = response.data.fileDataList[i].src;
                            }
                        }
                        newItem.shareId = shareId;
                        newItem.isShared = true;
                        newItem.isShared = false;
                        if (selectedLibrary == constants.IMAGES_LIBRARY) {
                            _maindata.unshift(newItem);
                        } else {
                            _maindata.push(newItem);
                        }
                    }
                    dispatch(updateDocumentLibrary([..._maindata]));
                    dispatch(updateisAssetsUpdated(true));
                    handleClose();
                    let uploadMsg = getLocalizedStrings().message.ATTACHMENT.FILE_ADDED_SUCCESSFULLY;
                    if (selectedLibrary == constants.IMAGES_LIBRARY) {
                        uploadMsg = getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD_IMAGE;
                    }
                    dispatch(showCustomSnackBar(uploadMsg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
                else if (response.data.status == -1 && response.data.error.message != "") {
                    dispatch(showCustomSnackBar(response.data.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    setInProgress(false);

                }
            });
        } catch (error) {
            console.error("Error in 'uploadDocument.js -> uploadDocument()':" + error);
        }
    }
    const addDocumentLink = () => {
        try {
            if (!fileTitle || fileTitle == "") {
                setIsError(true);
                dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.BLANK_TITLE_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            else if (!fileLink || fileLink == "") {
                setIsError(true);
                dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.BLANK_URL_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            else if (!isUrl(fileLink)) {
                setIsError(true);
                dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_URL_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            else {
                setIsError(false);
                let _selectedLibrary = isValidParam(selectedLibrary) ? selectedLibrary : null;
                setInProgress(true);
                setAddDocument(true);
                setSelectedLibrary(_selectedLibrary);
                const shareId = getStringParam(appContainer.dialogProps.data.shareId);
                let param = {
                    documentId: 0,
                    parentId: appContainer.dialogProps.data.parentId,
                    shareId,
                    file_url: fileLink,
                    file_title: fileTitle,
                    type: 'external link',

                };
                const url = endPoints.DOCUMENT_LIBRARY.UPLOAD_DOCUMENT;

                let promise = Promise.resolve(HTTPClient.post(url, param));

                promise.then((response) => {
                    if (response.status == 0) {

                        setInProgress(false);
                        setAddDocument(false);
                        setSelectedLibrary(_selectedLibrary);
                        let _maindata = [...documentLibrary.mainData];
                        for (let i = 0; i < response.fileDataList.length; i++) {
                            let newItem = {};
                            newItem.key = response.fileDataList[i].key;
                            newItem.name = response.fileDataList[i].name;
                            newItem.title = response.fileDataList[i].name;
                            newItem.t_type = response.fileDataList[i].t_type;
                            newItem.size = 0;
                            newItem.creator = response.fileDataList[i].creator;
                            newItem.date = response.fileDataList[i].date;
                            newItem.parentId = appContainer.dialogProps.data.parentId;
                            newItem.childId = appContainer.dialogProps.data.childId;
                            newItem.isLeaf = true;
                            newItem.userid = app.me.id;
                            newItem.id = 0;
                            newItem.bucketId = 0;
                            newItem.url = response.fileDataList[i].src;
                            newItem.shareId = shareId;
                            newItem.isShared = true;
                            newItem.isShared = false;
                            _maindata.push(newItem);
                        }
                        dispatch(updateDocumentLibrary([..._maindata]));
                        dispatch(updateisAssetsUpdated(true));
                        handleClose();
                        let uploadMsg = getLocalizedStrings().message.ATTACHMENT.DOCUMENT_LINK_ADDED_SUCCESSFULLY;
                        dispatch(showCustomSnackBar(uploadMsg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                    else if (response.status == -1 && response.error.message != "") {
                        let errorMsg = `${fileTitle} already exists`;
                        dispatch(showCustomSnackBar(errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        setInProgress(false);

                    }
                });
            }
        } catch (error) {
            console.error("Error in 'uploadDocument.js -> addDocumentLink()':" + error);
        }
    }
    const handleClose = () => {
        setFile([]);
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const fileOpen = (e) => {
        let treeData = documentLibrary.mainData;
        let totalFileCount = e.target.files.length + files.length;
        if (e.target.files.length > 5 || totalFileCount > 5) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_MAX_ATTACHMENTS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            e.target.value = null;
            return false;
        } else if (totalFileCount <= 5) {
            let filesArray = [];
            let filesName = null;
            let overSizeFileName = null;
            for (let i = 0; i < e.target.files.length; i++) {
                var file = e.target.files[i];
                let kb = (file.size / 1024).toFixed(2);
                let fileName = file.name;
                fileName = fileName.replace(/'/g, "");
                let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
                if (kb <= (1024 * 50)) {
                    if (appContainer.dialogProps.data.selectedLibrary === constants.IMAGES_LIBRARY) {
                        if (fileext.toLowerCase() === 'jpg' || fileext.toLowerCase() === 'jpeg' || fileext.toLowerCase() === 'gif' || fileext.toLowerCase() === 'png') {
                            let isDuplicate = false;
                            let uploadFileName = file.name;
                            getAssetsTreeItem(treeData, (item) => {
                                if (item.name === uploadFileName) {
                                    isDuplicate = true;
                                }
                            });
                            if (isDuplicate) {
                                if (filesName === null) {
                                    filesName = uploadFileName;
                                } else {
                                    filesName += ", " + uploadFileName;
                                }
                            } else {
                                let fileDetails = {};
                                fileDetails.fileName = file.name;
                                fileDetails.file = e.target.files[i];
                                filesArray.push(fileDetails);
                            }
                        } else {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_UPLOAD_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            return false;
                        }
                    } else {
                        if (fileext.toLowerCase() === 'exe' || fileext.toLowerCase() === 'bat' || fileext.toLowerCase() === 'sh') {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_DOCUMENT_UPLOAD_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            return false;
                        } else {
                            let isDuplicate = false;
                            let uploadFileName = file.name;
                            getAssetsTreeItem(treeData, (item) => {
                                if (item.name === uploadFileName) {
                                    isDuplicate = true;
                                }
                            });
                            if (isDuplicate) {
                                if (filesName === null) {
                                    filesName = uploadFileName;
                                } else {
                                    filesName += ", " + uploadFileName;
                                }
                            } else {
                                let fileDetails = {};
                                fileDetails.fileName = file.name;
                                fileDetails.file = e.target.files[i];
                                filesArray.push(fileDetails);
                            }
                        }
                    }
                } else {
                    if (overSizeFileName === null) {
                        overSizeFileName = fileName;
                    } else {
                        overSizeFileName = " ," + fileName;
                    }
                }
            }

            setFiles([...files, ...filesArray]);
            setInProgress(false);
            if (filesName !== null) {
                let errorMsg = '';
                if (appContainer.dialogProps.data.selectedLibrary === constants.IMAGES_LIBRARY) {
                    errorMsg = filesName + " " + getLocalizedStrings().message.DOCUMENT_LIBRARY.DUPLICATE_IMAGE_NAME;
                } else {
                    errorMsg = filesName + " " + getLocalizedStrings().message.DOCUMENT_LIBRARY.DUPLICATE_DOCUMENT_NAME;
                }
                dispatch(showCustomSnackBar(errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            if (overSizeFileName !== null) {
                let overSizeError = overSizeFileName + " " + getLocalizedStrings().message.DOCUMENT_LIBRARY.OVER_MAX_SIZE;
                dispatch(showCustomSnackBar(overSizeError, styles.snackbarBodyStyleError, styles.snackBarStyleLongMsg));
                return false;
            }
            e.target.value = null;
        }
    }

    const getFilesForRender = () => {
        let ul = null;
        let li = files.map((f, index) => {
            return <li key={f.fileName + index}><div key={'div' + index} style={{ width: '100%' }}><span key={'span' + index} style={{
                float: 'left', textAlign: 'left', overflow: 'hidden',
                wordBreak: 'break-all'
            }} title={f.fileName}> {f.fileName} </span> <span onClick={(e) => { e.preventDefault(); handleFileRemove(index); }}><Icon key={f.fileName + index} style={iconStyles}>close</Icon> </span></div></li>
        });
        if (li !== null && li.length > 0) {
            ul = <ul style={{ paddingLeft: '22px' }}>{li}</ul>;
        }
        return ul;
    }

    const handleFileRemove = (indexValue) => {
        let newFiles = [];
        for (let i = 0; i < files.length; i++) {
            if (i !== indexValue) {
                newFiles.push(files[i]);
            }
        }
        if (newFiles.length === 0) {
            setFiles([]);

        } else {
            setFiles(newFiles);

        }
    }

    const onFileTypeChange = (value) => {
        setFileType(value);
        dispatch(updateAppDialogTitle(`${getLocalizedStrings().message.ATTACHMENT[value == 'link' ? 'ADD_LINK' : 'UPLOAD_FILE']}`));
    }

    const onConfirmFileTypeChange = (value) => {
        setFiles([]);
        setFileTitle("");
        setFileLink("");
        onFileTypeChange(value);
    };

    const handleFileTypeChange = (value) => {
        if (files.length > 0 || (fileTitle && fileTitle !== "") || (fileLink && fileLink !== "")) {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.DOCUMENT_LIBRARY.CONFIRM_FILE_TYPE_CHANGE.LN1 + '<br/>' + getLocalizedStrings().message.DOCUMENT_LIBRARY.CONFIRM_FILE_TYPE_CHANGE.LN2 + '<br/>' + getLocalizedStrings().message.DOCUMENT_LIBRARY.CONFIRM_FILE_TYPE_CHANGE.LN3, () => onConfirmFileTypeChange(value), null);
        } else {
            onFileTypeChange(value)
        }
    }

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    let themeColor = app.me.background;
    if (inProgress === false) {
        return (
            <div >
                {selectedLibrary !== constants.IMAGES_LIBRARY && <FormControl>
                    <RadioGroup
                        row
                        name="file-type"
                        value={fileType}
                        defaultValue={fileType}
                        onChange={(event) => { handleFileTypeChange(event.target.value) }}
                    >
                        <FormControlLabel value="file" control={<Radio color="default" />} label="File" />
                        <FormControlLabel value="link" control={<Radio color="default" />} label="External Link" />
                    </RadioGroup>
                </FormControl>}

                <div>
                    <div style={{ ...styles.sf_12, border: '1px solid #ccc' }}>
                        {fileType === 'file' && <div id="attachmentUpload">
                            <div style={{ ...styles.row, padding: '0px' }}>
                                <div style={{ ...styles.sf_3, padding: 15, backgroundColor: '#fff', width: '210px' }}>
                                    <h3>{getLocalizedStrings().label.ATTACHMENT.MY_COMPUTER}</h3>
                                    <Button
                                        variant="contained"
                                        backgroundColor={'gray'}
                                        labelstyle={{ color: 'white' }}
                                        style={{ ...styles.button, width: '180px' }}
                                        endIcon={<Icon style={iconStyles} color={'primary'}>computer</Icon>}
                                    >
                                        {getLocalizedStrings().label.ATTACHMENT.BROWSE}
                                        <input type="file" style={inlinestyle.exampleImageInput} onChange={(e) => fileOpen(e)} multiple />
                                    </Button>

                                </div>
                                <div style={{ ...styles.sf_10, verticalAlign: 'middle', backgroundColor: '#efefef' }}>
                                    {getFilesForRender()}
                                </div>
                            </div>
                        </div>}
                        {fileType === 'link' && <div id="attachmentLink">
                            <div >
                                <div style={{ ...styles.sf_12, padding: '5 15' }}>
                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            label={getLocalizedStrings().label.DOCUMENT_LIBRARY.TITLE_REQUIRED}
                                            fullWidth={true}
                                            value={fileTitle}
                                            error={isError && (!fileTitle || fileTitle == "")}
                                            onChange={(e) => { setFileTitle(e.target.value) }}
                                            autoComplete="new-password"
                                            margin='dense'
                                            size='small'
                                            autoFocus={true}
                                            className={"sf-fields-bg"}
                                        />
                                    </FormControl>
                                </div>
                                <div style={{ ...styles.sf_12, padding: '5 15' }}>
                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            label={getLocalizedStrings().label.DOCUMENT_LIBRARY.LINK_REQUIRED}
                                            fullWidth={true}
                                            value={fileLink}
                                            error={isError}
                                            onChange={(e) => { setFileLink(e.target.value) }}
                                            autoComplete="new-password"
                                            margin='dense'
                                            size='small'
                                            className={"sf-fields-bg"}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div style={{
                        ...styles.sf_12, display: 'flex',
                        justifyContent: 'flex-end', float: 'right', padding: '0px', paddingTop: '15px'
                    }}>

                        {fileType === 'file' && <div><Button variant="contained"
                            style={{
                                color: '#ffffff',
                                border: 'solid 1px #d3d6d6',
                                marginRight: '13px',
                                boxShadow: 'none',
                                backgroundColor: themeColor
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                uploadDocument();
                            }}

                        >{getLocalizedStrings().label.ATTACHMENT.UPLOAD}</Button>

                            &nbsp;</div>}
                        {fileType === 'link' && <div><Button variant="contained"
                            style={{
                                color: '#ffffff',
                                border: 'solid 1px #d3d6d6',
                                marginRight: '13px',
                                boxShadow: 'none',
                                backgroundColor: themeColor
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                addDocumentLink();
                            }}

                        >{getLocalizedStrings().label.COMMON.ADD}</Button>
                            &nbsp;</div>}
                        <Button

                            primary={true}
                            onClick={() => handleClose()}
                            style={{ ...styles.secondaryButton, height: '36px' }}
                        > {getLocalizedStrings().label.COMMON.CANCEL}</Button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}


export default UploadDocument;