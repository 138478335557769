import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	isValidParam,
	getStringParam,
	getObjectParam,
	getArrayParam,
} from "../../../services/helper/parameterVerifier";
import { DEFAULT_PAGE_SIZE, getLists } from "../../../services/actions/listsActions";
import CardsContainer from "../containers/CardsContainer";
import {
	getActiveTabInfo,
	getActiveTab,
	updateActiveTab,
} from "../../../services/helper/sfTabManager";
import { constants } from "../../../services/constants/constants";
import { setHeader } from "../../../services/actions/headerActions";
import { getSFFormFieldsPromise } from "../../../services/actions/sfFormActions";
import {
	updateColumnViewHorizontalScrollPosition,
	updateColumnViewScrollPosition,
	removeColumnViewScrollPosition,
	removeColumnViewHorizontalScrollPosition,
} from "../../../services/actions/listsActions";
import * as ListsActions from "../../../services/actions/listsActions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { styles } from "../../../services/constants/styles";
import ShowCircularProgress from "../components/circularProgress";
import { openListView } from "../../../services/actions/listViewAcions";
import { getTasksRefresh } from "../../../services/actions/taskActions";
import { getAllCampaignTypes } from "../../../services/actions/campaignTypeActions";
import Button from "@mui/material/Button";
import { LinkToType } from "../../../services/constants/link";
import { getAppCustomDrawer } from "../../../services/actions/appContainerActions";
import { Icon } from "@mui/material";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { Menu } from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import filter from 'lodash/filter';

const mapStateToProps = (state) => {
	return {
		app: state.app,
		selectedModuleName: state.app.selectedModuleName,
		me: state.app.me,
		tab: state.tab,
		userList: getArrayParam(state.userlist.data),
		lists: state.lists,
		isFetching: state.lists.isFetching,
		fields: state.lists.taskFields,
		selectedFields: state.lists.selectedFields,
		totalCount: state.lists.totalCount,
		sfForm: state.sfForm,
		campaignTypes: state.campaignTypes,
		queueList: state.queueList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators(ListsActions, dispatch),
		setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
			dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
		},
		getTasksRefresh: (isrefresh) => {
			dispatch(getTasksRefresh(isrefresh));
		},
		getAllCampaignTypes: () => {
			dispatch(getAllCampaignTypes());
		},
		getAppCustomDrawer: (isOpen, dialogName, actionType, style, data, headerProps, minimizable) => {
			dispatch(getAppCustomDrawer(isOpen, dialogName, actionType, style, data, headerProps, minimizable));
		},
		updateColumnViewScrollPosition: (pos, obj) => {
			dispatch(updateColumnViewScrollPosition(pos, obj));
		},
		updateColumnViewHorizontalScrollPosition: (pos, obj) => {
			dispatch(updateColumnViewHorizontalScrollPosition(pos, obj));
		},
		removeColumnViewScrollPosition: (obj) => {
			dispatch(removeColumnViewScrollPosition(obj));
		},
		removeColumnViewHorizontalScrollPosition: (obj) => {
			dispatch(removeColumnViewHorizontalScrollPosition(obj));
		},
		showCustomSnackBar: (message, bodyStyle, style) => {
			dispatch(showCustomSnackBar(message, bodyStyle, style));
		},
	}
};


class TasksPage extends React.Component {
	constructor(props) {
		super(props);
		this.statusList = [{ id: 'Pending', label: getLocalizedStrings().label.APPOINTMENTS_TASKS['Pending'] }, { id: 'Completed', label: getLocalizedStrings().label.APPOINTMENTS_TASKS['Completed'] }, { id: 'Cancelled', label: getLocalizedStrings().label.APPOINTMENTS_TASKS['Cancelled'] }];
		this.sortList = [{ id: 'Date', label: getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_SORT_1 }, { id: 'Priority', label: getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_SORT_2 }];
		this.state = {
			isScrolling: false,
			userName: this.props.cookieGetSalesRepOption(),
			status: ['Pending', 'In-progress'],
			userList: [],
			browserName: '',
			previousProps: null,
			menuAncher:{
				userlist: null,
			}
		}
		this.moveCard = this.moveCard.bind(this);
		this.moveList = this.moveList.bind(this);
		this.scrollRight = this.scrollRight.bind(this);
		this.scrollLeft = this.scrollLeft.bind(this);
		this.stopScrolling = this.stopScrolling.bind(this);
		this.startScrolling = this.startScrolling.bind(this);
		this.canMove = this.canMove.bind(this);
	}

	componentDidMount() {
		//this.setHeader(this.props);
		this.initPage(this.props);
		if (this.props.app.selectedModuleName === constants.MODULE_MARKETING) {
			this.getAllCampaignTypes();
		}
		let isLogin = getStringParam(localStorage.getItem('isLogin'));
		
		let tab = getActiveTab();
		tab.info = { ...tab.info, userName: this.state.userName, status: this.state.status, callFrom: 'task_listview' };
		updateActiveTab(tab);
		
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		 
		// let isEqual = _.isEqual(this.props, nextProps);
		// if (!isEqual) {
		let { mounted, taskLists } = this.props.lists;
		let selectedModuleName = getStringParam(this.props.selectedModuleName);
		let nextSelectedModuleName = getStringParam(nextProps.selectedModuleName);
		let accountsFileds = getObjectParam(nextProps.sfForm.data)['accounts'];
		let contactsFileds = getObjectParam(nextProps.sfForm.data)['contacts'];
		if ((selectedModuleName != '' && nextSelectedModuleName != '' && selectedModuleName != nextSelectedModuleName) || (accountsFileds && contactsFileds && !this.state.initLoad)) {
			let activeTab = getObjectParam(getActiveTab());
			if (activeTab.object == constants.TASKS && taskLists.length === 0) {
				//this.setHeader(nextProps);
				this.initPage(nextProps);
			}
		} else if (taskLists && taskLists.length > 0 && !mounted ) {
			ListsActions.setKanbanMounted(true)
		}
		let info = getActiveTabInfo();
		if (!isValidParam(info.getTaskPageData)) {
			info.getTaskPageData = this.getListData;
		}
		// }
	}

	setHeader = (props) => {
		let _state = this.state;
		let selectedModuleName = getStringParam(props.selectedModuleName);
		let kanbanObject = {};
		kanbanObject.currentView = 'taskPage';
		kanbanObject.show = selectedModuleName == constants.MODULE_HUB ? false : true;
		let isEqual = _.isEqual(this.state.previousProps, props);
		if (!isEqual) {
			this.props.setHeader(this.props.object, getLocalizedStrings().label.HEADER.TASKS_PAGE, kanbanObject, true, true);
			_state.previousProps = props;
			this.setState(_state);
		}
	}

	initPage = (props) => {
		let _state = this.state;
		let info = getActiveTabInfo();
		info.getTaskPageData = this.getListData;

		if (info.hasOwnProperty('userName')) {
			this.state.userName = getStringParam(info.userName);
		}

		if (info.hasOwnProperty('status')) {
			this.state.status = getArrayParam(info.status);
		}

		
		let lists = this.props.lists;
		let object = lists.object;
		if (object != props.object) {
			let accountsFileds = getObjectParam(props.sfForm.data)['accounts'];
			let contactsFileds = getObjectParam(props.sfForm.data)['contacts'];
				this.getListData(props.object);
				this.state.initLoad = true;
			
		}else if(info.userName !=  lists.salesRep ){
			this.getListData(props.object);
			this.setState({});
		} else {
			this.props.lists.mounted = true;
			info.userName = lists.salesRep;
			info.status = this.state.status;
			_state.userName = lists.salesRep;
			this.setState(_state);
		}
		this.getBrowserName();
		//this.getFormFields();
	}

	getFormFields = () => {
		try {
			let accountsFileds = this.props.sfForm.data['accounts'];
			let contactsFileds = this.props.sfForm.data['contacts'];

			if (!isValidParam(accountsFileds)) {
				const accountsPromise = getSFFormFieldsPromise('accounts');
				accountsPromise.then((response) => {
					if (isValidParam(response)) {
						this.props.sfForm.data['accounts'] = response;
					}
				});
			}

			if (!isValidParam(contactsFileds)) {
				let contactsPromise = getSFFormFieldsPromise('contacts');
				contactsPromise.then((response) => {
					if (isValidParam(response)) {
						this.props.sfForm.data['contacts'] = response;
					}
				});
			}
		} catch (error) {
			console.error("Error in 'calenderTask.js -> getFormFields()':" + error);
		}
	}

	getListData = (object) => {
		 
		try {
			let userName = this.state.userName;
			let status = getArrayParam(this.state.status);
			let sortOrder = this.props.app.me.calendar_setup.length > 0? this.props.app.me.calendar_setup[0].sortOrder:'Date';
			let info = getObjectParam(getActiveTabInfo());
			info.userName = userName;
			info.status = status;
			info.sortOrder = sortOrder;
			let tab = getActiveTab();
			tab.info = { ...tab.info, userName: userName, status: status, callFrom: 'task_listview' };
			updateActiveTab(tab);
			let params = {};
			params.call_from = constants.COLUMN_VIEW;
			params.is_column_view = true;
			params.start_index = 0;
			params.fetch_size = ListsActions.DEFAULT_PAGE_SIZE;
			params.sales_rep = userName;
			params.status = status.join();
			params.sort_order = sortOrder;
			this.props.getTaskLists(object, params);
			this.props.setListsObject(object)
		} catch (error) {
			console.error("Error in 'tasksPage.js -> getListData()':" + error);
		}
	}



	getBrowserName = () => {
		let browserName = null;
		if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
			browserName = 'Opera';
		} else if (navigator.userAgent.indexOf("Chrome") !== -1) {
			browserName = 'Chrome';
		} else if (navigator.userAgent.indexOf("Safari") !== -1) {
			browserName = 'Safari';
		} else if (navigator.userAgent.indexOf("Firefox") !== -1) {
			browserName = 'Firefox';
		} else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) //IF IE > 10
		{
			browserName = 'IE';
		} else {
			browserName = 'unknown';
		}
		this.state.browserName = browserName;
	}

	startScrolling(direction) {
		switch (direction) {
			case 'toLeft':
				this.setState({ isScrolling: true }, () => this.scrollLeft());
				break;
			case 'toRight':
				this.setState({ isScrolling: true }, () => this.scrollRight());
				break;
			default:
				break;
		}
	}

	scrollRight() {
		if (document.getElementsByTagName('main').length > 0) {
			function scroll() {
				document.getElementsByTagName('main')[0].scrollLeft += 10;
			}

			this.scrollInterval = setInterval(scroll, 10);
		}
	}

	scrollLeft() {
		if (document.getElementsByTagName('main').length > 0) {
			function scroll() {
				document.getElementsByTagName('main')[0].scrollLeft -= 10;
			}

			this.scrollInterval = setInterval(scroll, 10);
		}
	}

	stopScrolling() {
		this.setState({ isScrolling: false }, clearInterval(this.scrollInterval));
	}

	moveCard(lastX, lastY, nextX, nextY) {
		this.props.moveTaskCard(lastX, lastY, nextX, nextY);
	}

	moveList(listId, nextX) {
		const { lastX } = this.findList(listId);
		this.props.moveTaskList(lastX, nextX);
	}

	canMove(sourceId, targetId) {
		let isMove = false;
		if (sourceId === 0 && targetId !== 3) {
			isMove = true
		} else if (sourceId === 3 && targetId !== 0) {
			isMove = true;
		} else if (sourceId === 1 && targetId !== 0) {
			isMove = true;
		} else if (sourceId === 2 && targetId === 1) {
			isMove = true;
		}
		return isMove;
	}

	getAllCampaignTypes = () => {
		try {
			let campaignTypes = getObjectParam(this.props.campaignTypes.data);

			if (Object.keys(campaignTypes).length == 0) {
				this.props.getAllCampaignTypes();
			}
		} catch (error) {
			console.error("Error in 'tasksPage.js -> getAllCampaignTypes()':" + error);
		}
	}
	
	openSettings = () => {

		let label = getLocalizedStrings().label.CALENDAR.MY_TASK_SETTING;
		let type = LinkToType.TYPE_CALENDAR_SETTINGS;
		let data = {

			type: constants.TASKS,

		}
		let propsTaskApp = {
			callFrom: 'Tasks',
			moduleId: "Today",
			salesRep: this.props.cookieGetSalesRepOption(),
			sortOrder: this.props.app.me.calendar_setup.length > 0? this.props.app.me.calendar_setup[0].sortOrder:'Date',
		}
		data.propsTaskApp = propsTaskApp;

		let style = {};
		style.width = '36%';
		this.props.getAppCustomDrawer(true, label, type, style, data, null, false);

	}
	 handleMenuToggle = (event, data) => {
        let menuAnc = this.state.menuAncher;
        if (menuAnc[data] == null) {
            menuAnc[data] = event.currentTarget;
        } else {
            menuAnc[data] = null;
        }
       this.setState({menuAncher:menuAnc})
    }
	
	 changeUserTask = (value, userName) => {
        this.props.cookieSetSalesRepOption(value);
        let tab = getActiveTab();
        tab.info = { ...tab.info, userName: userName, status: ["Pending"], callFrom: 'task_listview' };
        updateActiveTab(tab);
		this.state.userName = userName;
		this.getListData(this.props.object);
		this.setState({});
    }
	 getMenuItemList = (data) => {
        let List = [];
        switch (data) {
            case 'userOptions':
                let userlist = this.props.userList;
                userlist = userlist.filter((e)=>{return e.value != "Campaigns"});
                userlist.map(ul =>
                    List.push(<MenuItem key={ul.id} value={ul.id} onClick={() => this.changeUserTask(ul.id, ul.value)} style={styles.popoverMenuItem}>{ul.value}</MenuItem>)
                );
                break;
            
            default:
                userlist.map(ul =>
                    List.push(<MenuItem key={ul.id} value={ul.id} style={styles.popoverMenuItem}>{ul.value}</MenuItem>)
                );
        }

        return List;
    }
	render() {
		let isMounted = false;
		let browserName = getStringParam(this.state.browserName);
		let contentHeight = browserName === 'Firefox' ? document.body.clientHeight - 275 : document.body.clientHeight - 230;
		let top = (contentHeight - 10) / 2;
		let { taskLists } = this.props.lists;
		let userList = [];
		let tempuserList = this.props.userList;
		tempuserList = filter(tempuserList, function (userobj, i) {
			return userobj.id !== -8888;
		});
		tempuserList.forEach(element => {
			userList.push(
				<MenuItem key={element.text} id={element.text} value={element.text}>{element.text}</MenuItem>)
		});
		// (m => { return { id: m.text, label: m.text } });
		// <option value="20">Twenty</option>

		if (taskLists?.length > 0) {
			isMounted = true;
		}
		let cardsData = isMounted ? taskLists : defaultData;
		let widthUser = 150;
		let widthStatus = 150;
		if (this.props.app.me.language === "de-DE") {
			widthUser = 170;
		}

		if (isMounted) {
			return (
				<div id="divKanbanView" style={{ height: contentHeight, padding: 0, marginLeft: 5 }}>
					  <div style={{ display: 'flex' }}>
							<div onClick={(e) => this.handleMenuToggle(e, 'userlist')} style={{ border: '1px solid rgb(224, 224, 224)', borderRadius: 3, display: 'flex', width: '10%', height: '35px', padding: 8, cursor: 'pointer' ,position:'absolute',right:'7%'}}>

								<div style={{ display: 'inline-block', width: 160, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#9e9e9e' }} title={this.props.cookieGetSalesRepOption()}>
									<span>{this.props.cookieGetSalesRepOption()}</span>
								</div>
								<i className="material-icons" style={{ cursor: 'pointer' }} >keyboard_arrow_down</i>
								<Menu
									open={Boolean(this.state.menuAncher.userlist)}
									anchorEl={this.state.menuAncher.userlist}
									keepMounted
									name='userList' >
									{this.getMenuItemList('userOptions')}
								</Menu>
							</div>

						
							<span style={{ marginBottom: '0px', fontSize: '14px', marginTop: '1px', float: 'right', cursor: 'pointer', marginLeft: '-41px' }}>

								<IconButton onClick={() => this.props.getAllTaskView("close")} style={{
									paddingTop: '6px',
									paddingBottom: '10px'
								}} >
									<i className='fas fa-chevron-right' style={{ fontSize: '20px' }}></i>
									<i className='fas fa-chevron-right' style={{ fontSize: '20px' }}></i>
								</IconButton>
							</span>
							<div style={{ padding: '4', display: 'flex',position:'absolute',right:'19px', maxWidth:'7%'}}>
							<span style={{ marginBottom: '0px', fontSize: '14px', marginTop: '1px', float: 'right', cursor: 'pointer', marginLeft: '10px' }}>
								<div title={getLocalizedStrings().label.COMMON.ALL_TASKS}
									style={{ backgroundColor: '#ffffff', height: '30px', width: '30px', border: '1px solid rgb(224,224,224)', borderRadius: '4px' }}
									onClick={() => openListView(constants.TASKS, 'All Tasks')}>
									<Icon style={{ color: '#717171', cursor: 'pointer', marginLeft: '1px', marginTop: '2px', fontSize: '25px' }}>list</Icon>
								</div>
							</span>
							<span style={{ marginBottom: '0px', fontSize: '14px', marginTop: '1px', float: 'right', cursor: 'pointer', marginLeft: '10px' }}>
								<Button
									key='settings'
									id="calendar-settings"
									onClick={this.openSettings}
									title= {getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK_SETTINGS}
									style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top', backgroundColor: '#ffffff', minWidth: '4%', width: '30px', height: '30px', fontSize: '17px' }}>
									<i className="fas fa-cog" style={{ color: '#717171' }} />
								</Button>
							</span>
							</div>
							
						

							<span style={{ marginBottom: '0px', fontSize: '14px', marginTop: '1px', float: 'right', cursor: 'pointer', marginLeft: '10px' }}></span>

						
					</div>
					<div className='row' style={{ paddingTop: 5 }}>
						<div className='col-sm-12' style={{ paddingRight: 0 }} >
							<div id="divKanbanView" className="kanbanBord" style={{ display: 'flex', width: '100%', overflowX: 'auto', paddingBottom: '0px', paddingTop: '0px', paddingLeft: '0px', paddingRight: '0px', margin: '0px', height: contentHeight }}>
								{cardsData.map((item, i) =>
									<CardsContainer
										key={item.id}
										id={item.id}
										item={item}
										x={i}
										startScrolling={this.startScrolling}
										stopScrolling={this.stopScrolling}
										isScrolling={this.state.isScrolling}
										object={this.props.object}
										fields={this.props.fields}
										selectedFields={this.props.selectedFields}
										browserName={this.state.browserName}
										canMove={this.canMove}
										selected_sort_option={this.props.app.me.calendar_setup.length > 0? this.props.app.me.calendar_setup[0].sortOrder:'Date'}
										sales_rep={this.state.userName}
										status={this.state.status}
										queueList={this.props.hasOwnProperty('queueList') && this.props.queueList != null ? this.props.queueList : null}
										app={this.props.app}
										showCustomSnackBar={this.props.showCustomSnackBar}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div style={{ width: '100%', height: contentHeight }}>
					<div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '47%' }}>
						<div style={{ ...styles.assetLoaderContainer, height: 55, width: 55, padding: 6 }}>
							<ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
						</div>
					</div>
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksPage);


const defaultData = [
	{
		"cards": [],
		"total_count": "0",
		"name": "Overdue",
		"id": -1,
		"card_type": "columnview"
	},
	{
		"cards": [],
		"total_count": "0",
		"name": "Today",
		"id": -2,
		"card_type": "columnview"
	},
	{
		"cards": [],
		"total_count": "0",
		"name": "Tomorrow",
		"id": -3,
		"card_type": "columnview"
	},
	{
		"cards": [],
		"total_count": "0",
		"name": "Future",
		"id": -4,
		"card_type": "columnview"
	}
];