import { actions } from '..//constants/actions';
import store from '../../../src/services/store';
import { addTab } from '..//helper/sfTabManager';
import { constants } from '..//constants/constants';
import { isValidParam, getStringParam, getIntParam} from '..//helper/parameterVerifier';

import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import { chartInitialData} from '../../app/scripts/components/reportMatrixTable.js';
import { getActiveTab, getActiveTabInfo } from '..//helper/sfTabManager';

/* eslint-disable no-useless-concat */
function setReportRedirection() {
    return function (dispatch) {
        dispatch({
            type: actions.REPORTS_REDUCER_REDIRECTION,
            payload: {
                isRedirect: false,
                redirectUrl: ''
            }
        })
    }
}

function setRecentReportResponseData(reportData, reportParams, reportIndex, reportHeaders, reportObject) {
    return function (dispatch) {

        dispatch({
            type: actions.RECENT_REPORT_RESPONSE_DATA,
            payload: {
                reportData,
                reportParams,
                reportIndex,
                reportHeaders,
                reportObject
            }
        })
    }
}


function setReportHeaderData(reportHeaders, reportObject) {
    return function (dispatch) {

        dispatch({
            type: actions.SET_REPORT_HEADER_DATA,
            payload: {
                reportHeaders,
                reportObject
            }
        })
    }
}

function GetFormattedDate(todayTime1) {
    var todayTime = new Date(todayTime1);
    var month = (todayTime.getMonth() + 1);
    var day = (todayTime.getDate());
    var year = (todayTime.getYear() - 100);
    return month + "/" + day + "/" + year;
}

function drillDownMatrixReport(reportName, clickPosition, headerData, rowJsonData, footerData, clickJsonFieldName, rawData, clickValue) {


    // return false;
    if (clickValue === 0) {
        return
    }
    let object = getActiveTab().object;
    let reportParams = getActiveTabInfo().report_params;

    let _state = store.getState();
    reportParams = object === constants.SALES_OBJECT ? reportParams : _state.report.recentReportParams;

    let tablelabel = reportName;

    if (reportName === 'Activity Matrix') {

        let object_name = "notes";
        let query_id = 405;
        let conditionParam1 = "note_type";
        let conditionParam2 = "note_date";
        let conditionParam3 = "author";
        let condition = "";
        let date = "";
        let hdnDate = "";

        let owner = reportParams.user_names;

        if (clickPosition === "body") {
            if (getIntParam(rowJsonData[clickJsonFieldName]) === 0) {
                return false
            }
            condition = conditionParam1 + "=" + rowJsonData.value;
            // tablelabel = rowJsonData.value + ' Report ' + rowJsonData[clickJsonFieldName]
        } else {
            if(reportParams.activityTypes.includes("All,")){
                condition = conditionParam1 + "=" + 'Grand Total';
            } else {
                condition = conditionParam1 + "=" + reportParams.activityTypes;
            }
            // tablelabel = 'Grand Total';

        }
        let dueBy = reportParams.due_by;
        if (dueBy !== '') {
            condition += constants.RECORDSET_DELIMITER + conditionParam2 + "=" + dueBy;
        }

        if (dueBy === 'cust') {
            var hdnFromDate = reportParams.from_date;
            var hdnToDate = reportParams.to_date;
            var fromDate = GetFormattedDate(reportParams.from_date);
            var toDate = GetFormattedDate(reportParams.to_date);
            if (fromDate !== null && toDate !== null && hdnFromDate !== null && hdnToDate !== null) {
                date = fromDate + " - " + toDate;
                hdnDate = hdnFromDate + " - " + hdnToDate;
                if (date !== '' && hdnDate !== '') {
                    condition += constants.RECORDSET_DELIMITER + "hdnDate=" + hdnDate + constants.RECORDSET_DELIMITER + "date=" + date;
                }
            }
        }
        if (owner !== '') {
            condition += constants.RECORDSET_DELIMITER + conditionParam3 + "=" + owner;
        }
        condition += constants.RECORDSET_DELIMITER + conditionParam3 + "<>System";
        let params = {
            query_id: 405,
            "query_type": "102",
            "query_name": "",
            "report_action_coach_type": "102",
            "search_text": condition,
            "report_type": "102",
            "click_form": "DRILLDOWN"
        }
        addRedirectToTab(object_name, query_id, "", params, tablelabel, rowJsonData.value);

    } else if (reportName === 'Matrix By Status') {

        let query_id = "";
        if (getIntParam(rowJsonData[clickJsonFieldName]) === 0) {
            return false
        }

        let object_name = reportParams.object_name;

        if (object_name === "accounts") {
            query_id = 542;
        } else {
            query_id = 547;
        }


        let conditionParam1 = "name";
        let conditionParam2 = "t_completedate";
        let conditionParam3 = "owner";
        let conditionParam4 = "workflowid";
        let condition = "";
        let date = "";
        let hdnDate = "";

        let owner = reportParams.user_names;

        if (clickPosition === "body") {
            if (getIntParam(rowJsonData[clickJsonFieldName]) === 0) {
                return false
            }
            condition = conditionParam1 + "=" + rowJsonData.value;
            //   tablelabel = rowJsonData.value + ' Report ' + rowJsonData[clickJsonFieldName]
        } else {
            condition = conditionParam1 + "=" + 'Grand Total';
            //  tablelabel = 'Grand Total';

        }
        let dueBy = reportParams.due_by;
        let workflow_id = reportParams.workflow_id;
        if (dueBy !== '') {
            condition += constants.RECORDSET_DELIMITER + conditionParam2 + "=" + dueBy;
        }


        if (dueBy === 'cust') {
            hdnFromDate = reportParams.from_date;
            hdnToDate = reportParams.to_date;
            fromDate = GetFormattedDate(reportParams.from_date);
            toDate = GetFormattedDate(reportParams.to_date);
            if (fromDate !== null && toDate !== null && hdnFromDate !== null && hdnToDate !== null) {
                date = fromDate + " - " + toDate;
                hdnDate = hdnFromDate + " - " + hdnToDate;
                if (date !== '' && hdnDate !== '') {
                    condition += constants.RECORDSET_DELIMITER + "hdnDate=" + hdnDate + constants.RECORDSET_DELIMITER + "date=" + date;
                }
            }
        }
        if (owner !== '') {
            condition += constants.RECORDSET_DELIMITER + conditionParam3 + "=" + owner;
        }
        if (workflow_id > 0) {
            condition += constants.RECORDSET_DELIMITER + conditionParam4 + "=" + workflow_id;
        }
        let params = {
            "report_action_coach_type": "102",
            "search_text": condition,
            "report_type": "102",
            "click_form": "DRILLDOWN",
            "module": "sales",
            query_type: 11
        };

        let selectedFranchise = getIntParam(reportParams.selectedFranchise);
        let selectedFrnProjectId = getIntParam(reportParams.selectedFrnProjectId);
        if (selectedFranchise > 0 && selectedFrnProjectId > 0) {
            params.selectedFranchise = selectedFranchise;
            params.selectedFrnProjectId = selectedFrnProjectId;
        }
        addRedirectToTab(object_name, query_id, "", params, tablelabel, rowJsonData.value);

    } else if (reportName === 'Matrix By Source') {

        let conditionParam1 = "source";
        let conditionParam2 = "created_on";
        let conditionParam3 = "owner";
        let query_id = "";
        let object_name = reportParams.object_name;
        let owner = reportParams.user_names;
        let date = "";
        let hdnDate = "";

        if (object_name === "accounts") {
            query_id = 542;
        } else {
            query_id = 547;
        }
        let condition = "";
        //  let object_name = "accounts";

        condition = conditionParam1 + "=" + rowJsonData.value;
        let dueBy = reportParams.due_by;
        if (dueBy !== '') {
            condition += constants.RECORDSET_DELIMITER + conditionParam2 + "=" + dueBy;
        }

        if (dueBy === 'cust') {
            hdnFromDate = reportParams.from_date;
            hdnToDate = reportParams.to_date;
            fromDate = GetFormattedDate(reportParams.from_date);
            toDate = GetFormattedDate(reportParams.to_date);
            if (fromDate !== null && toDate !== null && hdnFromDate !== null && hdnToDate !== null) {
                date = fromDate + " - " + toDate;
                hdnDate = hdnFromDate + " - " + hdnToDate;
                if (date !== '' && hdnDate !== '') {
                    condition += constants.RECORDSET_DELIMITER + "hdnDate=" + hdnDate + constants.RECORDSET_DELIMITER + "date=" + date;
                }
            }
        }
        if (owner !== '') {
            condition += constants.RECORDSET_DELIMITER + conditionParam3 + "=" + owner;
        }
        condition += constants.RECORDSET_DELIMITER + 'null' + "=" + 1;

        let params = {
            "report_action_coach_type": "102",
            "report_type": "102",
            "search_text": condition,
            query_type: 11,
        }
        let selectedFranchise = getIntParam(reportParams.selectedFranchise);
        let selectedFrnProjectId = getIntParam(reportParams.selectedFrnProjectId);
        if (selectedFranchise > 0 && selectedFrnProjectId > 0) {
            params.selectedFranchise = selectedFranchise;
            params.selectedFrnProjectId = selectedFrnProjectId;
        }
        addRedirectToTab(object_name, query_id, "", params, tablelabel, rowJsonData.value);

    } else if (reportName === 'Support Matrix') {


        let object_name = "cases";
        let query_id = 686;
        let reportData = rawData;
        let xAxisName = reportData.xAxisName;
        let yAxisName = reportData.yAxisName;
        let rowFieldId = reportData.rowFieldId;
        let colFieldId = reportData.colFieldId;
        let dateFieldId = reportData.dateFieldId;

        let reportFromDate = reportParams.from_date;
        let reportToDate = reportParams.to_date;
        let colValue = clickJsonFieldName;
        let rowValue = rowJsonData[yAxisName];

        let clickFrom = '';
        let status = '';
        let value = '';

        let label = "";

        if (clickPosition === "body" && clickJsonFieldName !== "Total count") {

            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "rowColumnTotal";
            label = rowJsonData[yAxisName] + ' - ' + colValue;
        } else if (clickPosition === "body" && clickJsonFieldName === "Total count") {
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "rowTotal";
            colValue = "Sub total";
            label = rowJsonData[yAxisName];
        } else if (clickPosition === "footer" && clickJsonFieldName !== "Total count") {
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "columnTotal";
            label = colValue;
        } else if (clickPosition === "footer" && clickJsonFieldName === "Total count") {

            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "total";
        }
        let params = {
            "click_form": "DRILLDOWN",
            "report_click_from": clickFrom,
            "colFieldId": colFieldId,
            "rowFieldId": rowFieldId,
            "colValue": colValue,
            "rowValue": rowValue,
            "dateFieldId": dateFieldId,
            "dateFilter": 215,
            "queryId": 686,
            "reportFromDate": reportFromDate,
            "reportToDate": reportToDate,
            "report_type": "13",
            query_type: '13',
            "status": status,
            "xAxisName": xAxisName
        }

        addRedirectToTab(object_name, query_id, "", params, tablelabel, label);
    } else if (reportName === 'Customers by Cases') {

        let object_name = "cases";
        let query_id = 686;

        let reportData = rawData;
        let xAxisName = reportData.xAxisName;
        let yAxisName = reportData.yAxisName;
        let rowFieldId = reportData.rowFieldId;
        let colFieldId = reportData.colFieldId;
        let dateFieldId = reportData.dateFieldId;

        let reportFromDate = reportParams.from_date;
        let reportToDate = reportParams.to_date;
        let colValue = clickJsonFieldName;
        let rowValue = rowJsonData[yAxisName];

        let clickFrom = '';
        let status = '';
        let value = '';
        let label = "";
        if (clickPosition === "body" && clickJsonFieldName !== "Total count") {
            if (getIntParam(rowJsonData[clickJsonFieldName]) === 0) {
                return false
            }
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "rowColumnTotal";
            label = rowJsonData[yAxisName] + ' - ' + colValue;
        } else if (clickPosition === "body" && clickJsonFieldName === "Total count") {
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "rowTotal";
            colValue = "Sub total";
            label = rowJsonData[yAxisName];
        } else if (clickPosition === "footer" && clickJsonFieldName !== "Total count") {
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "columnTotal";
            rowValue = "Sub total";
            label = colValue;

        } else if (clickPosition === "footer" && clickJsonFieldName === "Total count") {

            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "total";
            colValue = "Sub total";
            rowValue = "Sub total";
        }
        let params = {
            "click_form": "DRILLDOWN",
            "report_click_from": clickFrom,
            "colFieldId": colFieldId,
            "rowFieldId": rowFieldId,
            "colValue": colValue,
            "rowValue": rowValue,
            "dateFieldId": dateFieldId,
            "dateFilter": 215,
            "queryId": 686,
            "reportFromDate": reportFromDate,
            "reportToDate": reportToDate,
            "report_type": "13",
            query_type: '13',
            "status": status,
            "xAxisName": xAxisName
        }


        addRedirectToTab(object_name, query_id, "", params, tablelabel, label);
    } else if (reportName === 'Issue Matrix') {

        let object_name = "issues";
        let query_id = 1052;
        let reportData = rawData;
        let xAxisName = reportData.xAxisName;
        let yAxisName = reportData.yAxisName;
        let rowFieldId = reportData.rowFieldId;
        let colFieldId = reportData.colFieldId;
        let dateFieldId = reportData.dateFieldId;

        let reportFromDate = reportParams.from_date;
        let reportToDate = reportParams.to_date;
        let colValue = clickJsonFieldName;
        let rowValue = rowJsonData[yAxisName];

        let clickFrom = '';
        let status = '';
        let value = '';
        let label = "";
        if (clickPosition === "body" && clickJsonFieldName !== "Total count") {
            if (getIntParam(rowJsonData[clickJsonFieldName]) === 0) {
                return false
            }
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "rowColumnTotal";
            label = rowJsonData[yAxisName] + ' - ' + colValue;
        } else if (clickPosition === "body" && clickJsonFieldName === "Total count") {
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "rowTotal";
            colValue = "Sub total";
            label = rowJsonData[yAxisName];
        } else if (clickPosition === "footer" && clickJsonFieldName !== "Total count") {
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "columnTotal";
            rowValue = "Sub total";
            label = colValue;
        } else if (clickPosition === "footer" && clickJsonFieldName === "Total count") {

            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "total";
            colValue = "Sub total";
            rowValue = "Sub total";
        }
        let params = {
            "click_form": "DRILLDOWN",
            "report_click_from": clickFrom,
            "colFieldId": colFieldId,
            "rowFieldId": rowFieldId,
            "colValue": colValue,
            "rowValue": rowValue,
            "dateFieldId": dateFieldId,
            "dateFilter": 215,
            "queryId": 686,
            "reportFromDate": reportFromDate,
            "reportToDate": reportToDate,
            "report_type": "13",
            query_type: '13',
            "status": status,
            "xAxisName": xAxisName
        }

        addRedirectToTab(object_name, query_id, "", params, tablelabel, label);
    } else if (reportName === 'Project Matrix') {


        let object_name = "projects";
        let query_id = 1054;
        let reportData = rawData;
        let xAxisName = reportData.xAxisName;
        let yAxisName = reportData.yAxisName;
        let rowFieldId = reportData.rowFieldId;
        let colFieldId = reportData.colFieldId;
        let dateFieldId = reportData.dateFieldId;

        let reportFromDate = reportParams.from_date;
        let reportToDate = reportParams.to_date;
        let colValue = clickJsonFieldName;
        let rowValue = rowJsonData[yAxisName];

        let clickFrom = '';
        let status = '';
        let value = '';
        let label = "";
        if (clickPosition === "body" && clickJsonFieldName !== "Total count") {
            if (getIntParam(rowJsonData[clickJsonFieldName]) === 0) {
                return false
            }
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "rowColumnTotal";
            label = rowJsonData[yAxisName] + ' - ' + colValue;
        } else if (clickPosition === "body" && clickJsonFieldName === "Total count") {
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "rowTotal";
            colValue = "Sub total";
            label = rowJsonData[yAxisName];
        } else if (clickPosition === "footer" && clickJsonFieldName !== "Total count") {
            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "columnTotal";
            rowValue = "Sub total";
            label = colValue;
        } else if (clickPosition === "footer" && clickJsonFieldName === "Total count") {

            status = colValue + "@@" + rowJsonData[colValue] + "===" + 'Sub total' + "@@" + value;
            clickFrom = "total";
            colValue = "Sub total";
            rowValue = "Sub total";
        }
        let params = {
            "click_form": "DRILLDOWN",
            "report_click_from": clickFrom,
            "colFieldId": colFieldId,
            "rowFieldId": rowFieldId,
            "colValue": colValue,
            "rowValue": rowValue,
            "dateFieldId": dateFieldId,
            "dateFilter": 4519,
            "queryId": 686,
            "reportFromDate": reportFromDate,
            "reportToDate": reportToDate,
            "report_type": "13",
            query_type: '13',

            "status": status,
            "xAxisName": xAxisName,
            report_user_names: reportParams.user_names
        }

        addRedirectToTab(object_name, query_id, "", params, tablelabel, label);

    }

}


function addRedirectToTab(object_name, query_id, dueBy, customInputParamObjec, tablabel = "All", reportFieldValue = "") {

    let groupByObject = {};
    if (customInputParamObjec.hasOwnProperty('group_by_field_name')) {
        groupByObject.group_by_field_name = customInputParamObjec.group_by_field_name
        delete customInputParamObjec.group_by_field_name;
    }

    if (customInputParamObjec.hasOwnProperty('group_by_condition')) {
        groupByObject.group_by_condition = customInputParamObjec.group_by_condition
        delete customInputParamObjec.group_by_condition;
    }

    let selectedFranchise = getIntParam(customInputParamObjec.selectedFranchise);
    let selectedFrnProjectId = getIntParam(customInputParamObjec.selectedFrnProjectId);
    if (customInputParamObjec.hasOwnProperty('selectedFranchise') && customInputParamObjec.hasOwnProperty('selectedFrnProjectId')) {
        delete customInputParamObjec.selectedFranchise;
        delete customInputParamObjec.selectedFrnProjectId;
    }

    let tabParams = {
        "imgName": "fa fa-users",
        "index": 0,
        item: getActiveTab().item,
        "info": {
            "filter": {
                "id": query_id,
                "name": "",
                //  "type": query_type
                "type": ""
            },
            "input_param": {
                ...customInputParamObjec
            },
            isReport: true,
            reportFieldValue: reportFieldValue,
            reportLabel: tablabel,
        },
        "isActive": false,
        "label": tablabel,
        /// "object": _state.report.reportObject,
        "object": object_name,
        "type": 'TYPE_LIST_VIEW',
        "url": '/' + object_name + '/queries',
        ...groupByObject
    };

    if (selectedFranchise > 0 && selectedFrnProjectId > 0) {
        tabParams.info.isInXpress = true;
        tabParams.info.inXpressProjectId = selectedFrnProjectId;
    }

    addTab(tabParams, true);
}




function drilldownMarketingReport(reportData, reportName, chartType, reportKeyName) {
    let object = getActiveTab().object;
    let reportParams = getActiveTabInfo().report_params;
    let _state = store.getState();

    let recentReportData = object === constants.SALES_OBJECT ? _state.salesReport.reportData : _state.report.recentReportResponseData;

    let clickReportData = {};
    let x_axis_name = null;
    let object_name = null;
    let query_id = null;
    let query_type = null;
    reportParams = object === constants.SALES_OBJECT ? reportParams : _state.report.recentReportParams;

    recentReportData.report_values.forEach((rpt, index) => {

        if (recentReportData.report_values[index].name === reportKeyName) {

            clickReportData = recentReportData.report_values[index].value;
            x_axis_name = clickReportData.x_axis_field_name;
            object_name = clickReportData.object_name;
            query_id = clickReportData.query_id;
            query_type = clickReportData.query_type;
        }
    });

    // Open Tab 
    if (reportName === "Activities By Sales Rep" || reportName === "Activities By Type"
        || reportName === "Customers By Source" || reportName === "All Conversions By Status"
        || reportName === "Pipeline by Sales Rep" || reportName === "Pipeline Report"
        || reportName === "Deals by Stages" || reportName === "Conversion By Sales Rep"

        || reportName === "Cases by Priority" || reportName === "Cases by Status"
        || reportName === "Projects by Status" || reportName === "Projects by Type"
        || reportName === "Issues by Priority" || reportName === "Issues by Status"
        || reportName === "Top 5 Units by Sales" || reportName === "Bottom 5 Units by Sales"
        || reportName === "Activity Matrix" || reportName === "Activities By Sales Rep"
    ) {
        let extraParams = {};
        if (reportName === "Activities By Sales Rep" || reportName === "Activities By Type") {
            extraParams.report_action_coach_type = "101";
        }

        if (reportName === "All Conversions By Status" || reportName === constants.CONVERSION_BY_SALES_REP) {
            extraParams.report_action_coach_type = "102";
            extraParams.query_type = "11";
        }

        if (reportName === "Customers By Source") {
            extraParams.report_action_coach_type = "102";
             extraParams.query_type = "11";
        }

        if ((reportName === "Pipeline Report" || reportName === constants.DEALS_BY_SALES_REP || reportName === constants.CONVERSION_BY_SALES_REP || reportName === "All Conversions By Status"|| reportName === constants.PIPELINE_REPORT) && reportParams.workflow_id !== undefined && reportParams.workflow_id > 0) {
            extraParams.workflow_id = reportParams.workflow_id;
        }
        if (reportName === "Activity Matrix" || reportName === "Activities By Sales Rep") {
            extraParams.activityTypes = reportParams.activityTypes;
        }
        if (reportName === constants.DEALS_BY_SALES_REP || reportName === constants.CONVERSION_BY_SALES_REP || reportName === constants.PIPELINE_REPORT) {
            // console.log(JSON.stringify(reportData));
            if (clickReportData.chart_type !== "stackedBar" && clickReportData.chart_type !== "stackedColumn") {
                extraParams.stages = reportParams.status;
            } else if ((clickReportData.chart_type === "stackedBar" || clickReportData.chart_type === "stackedColumn") && (reportName === "Conversion By Sales Rep" || reportName === constants.DEALS_BY_SALES_REP)) {
                extraParams.stages = reportData.series.name;
            } else {
                let isTrue = false;
                let index = 0;
                let chartDatas = clickReportData.chart_data;
                for (var j = 0; j <= chartDatas.length; j++) {
                    let dataList = chartDatas[j].hasOwnProperty("sum_by") ? chartDatas[j].sum_by : chartDatas[j].y;
                    for (var i = 0; i <= dataList.length; i++) {
                        if (dataList[i] === reportData.y) {
                            index = i;
                            isTrue = true;
                            break;
                        }
                    }
                    if (isTrue) {
                        break;
                    }
                }
                let workFlowStages = clickReportData.workflow_status[index];
                extraParams.stages = workFlowStages;
            }

        }


        let tabParams = {
            //"imgName": "fa fa-users",
            "index": 0,
            item: object,

            "info": {
                "filter": {
                    "id": query_id,
                    "name": "",
                    "type": query_type
                },
                "input_param": {
                    "report_due_by": reportName === constants.DEALS_BY_SALES_REP && reportParams.pipeline_type === "value" ? "" : reportParams.due_by,
                    "report_user_names": (reportName === constants.DEALS_BY_SALES_REP || reportName === constants.CONVERSION_BY_SALES_REP || reportName === constants.PIPELINE_REPORT) ? "" : reportParams.user_names,
                    "report_from_date": reportName === constants.DEALS_BY_SALES_REP && reportParams.pipeline_type === "value" ? "" : reportParams.from_date,
                    "report_to_date": reportName === constants.DEALS_BY_SALES_REP && reportParams.pipeline_type === "value" ? "" : reportParams.to_date,
                    "group_by_field_name": x_axis_name,
                    "group_by_condition": reportData.hasOwnProperty("name") && reportData.name !== undefined ? reportData.name : reportData.category,
                    "report_type": "11",
                    "query_type": query_type,
                    "query_id": query_id,  // temporarily 
                    ...extraParams
                },
                "groupByFieldName": x_axis_name,
                "groupByCondition": reportData.hasOwnProperty("name") && reportData.name !== undefined ? reportData.name : reportData.category,
                isReport: true,
                reportFieldValue: reportData.name,
                reportLabel: reportName
            },
            "isActive": false,
            "label": reportName,
            "object": object_name,
            "type": 'TYPE_LIST_VIEW',
            "url": '/' + object_name + '/queries'
        };

        let selectedFranchise = getIntParam(reportParams.selectedFranchise);
        let selectedFrnProjectId = getIntParam(reportParams.selectedFrnProjectId);
        if (selectedFranchise > 0 && selectedFrnProjectId > 0) {
            tabParams.info.isInXpress = true;
            tabParams.info.inXpressProjectId = selectedFrnProjectId;
        }

        addTab(tabParams, true);
    }
}

function processChartData(chartConfData, chartType, reportName, currency) {
    let config = JSON.parse(JSON.stringify(chartInitialData));
    let isShowSeparator = false;
    let downloadFileNme = chartConfData === undefined ? "zxcxzv" : chartConfData.title;
    config.plotOptions = {
        series: {
            cursor: 'pointer',
            borderWidth: 0,
            showInLegend: true,
            point: {
                events: {
                    click: function () {

                        drilldownMarketingReport(this, chartConfData.title, chartType === "stackedBar" ? "bar" : chartType === "stackedColumn" ? "column" : chartType, reportName);

                    }
                }
            },
            dataLabels: {
                formatter: function() {
                  return this.y;
                },
                enabled: true,
                color: '#000000',
                shadow: false,
                style: {
                  fontSize: "10px",
                  textShadow: "0px", 
                }
            },
        }
    }
    config.exporting.buttons.contextButton.menuItems = [
        {
            text: 'Print Report',
            onclick: function () {

                this.print();
            }
        },
        {

            separator: true
        }, {
            text: 'Download PNG Image',
            onclick: function () {


                this.exportChart({ type: 'image/png', filename: downloadFileNme });
            }
        },
        {
            text: 'Download JPEG Image',
            onclick: function () {

                this.exportChart({ type: 'image/jpeg', filename: downloadFileNme });
            }
        },
        {
            text: 'Download PDF Image',
            onclick: function () {

                this.exportChart({ type: 'application/pdf', filename: downloadFileNme });
            }
        },
        {
            text: 'Download SVG Image',
            onclick: function () {

                this.exportChart({ type: 'image/svg+xml', filename: downloadFileNme });
            }
        }
    ];

    let propsConfig = chartConfData;
    if (propsConfig !== undefined) {

        config.chart.type = chartType === "stackedBar" ? "bar" : chartType === "stackedColumn" ? "column" : chartType;
        config.title.text = propsConfig.title;
        config.yAxis.title.text = propsConfig.y_axis_name;
        if (propsConfig.chart_data !== undefined && (chartType !== "stackedBar" && chartType !== "stackedColumn")) {
            for (let i = 0; i < propsConfig.chart_data.length; i++) {
                let tempData = propsConfig.chart_data[i];
                config.xAxis.categories.push(tempData.name);
                config.series[0].data.push({ 'name': tempData.name, 'y': tempData.hasOwnProperty("sum_by") ? tempData.sum_by : tempData.y });
                config.series[0].name = tempData.x_axis_name;
                isShowSeparator = tempData.hasOwnProperty("sum_by") ? true : false;
            }
            if (isShowSeparator) {
                if (chartType === "funnel" || chartType === "pie") {
                    
                } else {
                    config.tooltip = {
                        headerFormat: '<b>{point.x}</b><br/>',
                        pointFormat: '{series.name}: ' + currency + ' {point.y:,.2f}'
                    };
                }
            } else {
                if (chartType === "funnel" || chartType === "pie") {
                    config.plotOptions.series = {
                        ...config.plotOptions.series,
                        dataLabels: {
                            formatter: function() {
                                return this.point.name;                              
                            },
                            enabled: true,
                            color: '#000000',
                            shadow: false,
                            style: {
                              fontSize: "10px",
                              textShadow: "0px", 
                            }
                          },
                    };
                } else {
                    config.tooltip = {
                        headerFormat: '<b>{point.x}</b><br/>',
                        pointFormat: '{series.name}: {point.y}'
                    };
                }
            }
            config.yAxis = {
                min: 0,
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: 'gray'
                    }
                }
            };
            if (isShowSeparator) {
                config.yAxis.labels = {
                    formatter: function () {
                        return currency + " " + this.value;
                    }
                };
                config.plotOptions.series = {
                    ...config.plotOptions.series,
                    dataLabels: {
                        enabled: true,
                        format: currency + " {y}",
                    }
                };
            }
        } else if (propsConfig.chart_data !== undefined && (chartType === "stackedBar" || chartType === "stackedColumn")) {
            config.xAxis.categories = propsConfig.categories;
            for (let i = 0; i < propsConfig.workflow_status.length; i++) {
                let configData = Object();
                configData.name = propsConfig.workflow_status[i];
                let data = [];
                propsConfig.chart_data.forEach((chart, index) => {
                    data.push(chart.hasOwnProperty("sum_by") ? chart.sum_by[i] : chart.y[i]);
                    isShowSeparator = chart.hasOwnProperty("sum_by") ? true : false;
                });
                configData.data = data;
                config.series[i] = configData;
            }
            if (isShowSeparator) {
                config.tooltip = {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: ' + currency + ' {point.y:,.2f}<br/>Total: ' + currency + ' {point.stackTotal:,.2f}'
                };
            } else {
                config.tooltip = {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                };
            }
            config.yAxis = {
                min: 0,
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: '400',
                        color: '#000000',
                        shadow: false,
                        fontSize: "10px",
                        textShadow: "0px", 
                    }
                }
            };
            if (chartType === "stackedColumn") {
                config.plotOptions.column = {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                };
            }
            if (chartType === "stackedBar") {
                config.plotOptions.series = {
                    ...config.plotOptions.series,
                    stacking: 'normal',
                };
                config.legend = {
                    ...config.legend,
                    reversed: true
                };
            }
            if (isShowSeparator) {
                config.yAxis.labels = {
                    formatter: function () {
                        return currency + " " + this.value;
                    }
                };
                config.yAxis.stackLabels = {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        color: '#000000'
                    },
                    formatter: function () {
                        return currency + " " + this.total;
                    }
                }
            }
        }

    }

    return config;
}


function isInexpressReport(objectName, reportName) {

    let isInexpress = false;

    if (objectName === 'sales' && (
        reportName === 'Customer Activation Report'
        || reportName === 'Customer Status Report'
        || reportName === 'Customer Service Type Report'
        || reportName === 'Customer Assessment Report'
        || reportName === 'Customer Leads Assessment Report'
        || reportName === 'Sales Pipeline Report'

        || reportName === 'Days in Franchise Workflow Report'
        || reportName === 'Franchise Workflow Report'
        || reportName === 'Franchise Sales Pipeline Report'
        || reportName === 'Franchise Employees Report'
        || reportName === 'Franchise Last Contact Report'
        || reportName === 'InXpress User Baseline Report'

        || reportName === 'CRM Activity by Account'
        || reportName === 'Activity by Franchise'
        || reportName === 'Franchise Activity Report'
        || reportName === 'Franchise User Report'
        || reportName === 'Customer Service and Sales Rep Listing'
        || reportName === 'CRM Account Details'
        || reportName === 'Activity by Coach'
    )) {
        isInexpress = true;
    }
    return isInexpress;
}

function getFranchiseListPromise(params) {
    let url = null;
    var promise = null;
    try {
        if (isValidParam(params)) {
            url = endPoints.INXPRESS_REPORT.FRANCHISE_LIST;
            promise = Promise.resolve(HTTPClient.get(url, params));
        }
    } catch (error) {
        console.error("Error in 'reportActions.js -> getFranchiseListPromise()':" + error);
    }
    return promise;
}

function getFranchiseWFListPromise(params) {
    let url = null;
    var promise = null;
    try {
        if (isValidParam(params)) {
            url = endPoints.INXPRESS_REPORT.FRANCHISE_WF_LIST;
            promise = Promise.resolve(HTTPClient.get(url, params));
        }
    } catch (error) {
        console.error("Error in 'reportActions.js -> getFranchiseWFListPromise()':" + error);
    }
    return promise;
}

function setInXpressFranchiseList(franchiseList) {
    return function (dispatch) {

        dispatch({
            type: actions.SET_INXPRESS_REPORT_FRANCHISE_LIST,
            payload: franchiseList
        })
    }
}

function setInXpressWorkflowList(workflowList) {
    return function (dispatch) {

        dispatch({
            type: actions.SET_INXPRESS_REPORT_WORKFLOW_LIST,
            payload: workflowList

        })
    }
}

function setInXpressReportData(reportData) {
    return function (dispatch) {

        dispatch({
            type: actions.SET_INXPRESS_REPORT_DATA,
            payload: reportData,

        })
    }
}

function getInXpressReports(params) {
    let promise = null;
    let url = null;
    try {
        url = getStringParam(endPoints.INXPRESS_REPORT.INXPRESS_REPORT_GET);
        if (url !== '' && isValidParam(params)) {
            promise = Promise.resolve(HTTPClient.get(url, params));
         }
    } catch (error) {
        console.error("Error in 'reportActions.js -> getInXpressReports()':" + error);
    }
    return promise;
}

function isTasksReport(objectName, reportName) {
    let isTaskReport = false;
    if (getStringParam(reportName) === constants.TASKS_REPORT) {
        isTaskReport = true;
    }
    return isTaskReport;
}

function isEmailReport(objectName, reportName) {
    let isEmailReport = false;
    if (getStringParam(reportName) === constants.EMAIL_REPORT) {
        isEmailReport = true;
    }
    return isEmailReport;
}

function isSMSReport(objectName, reportName) {
    let isSMSReport = false;
    if (getStringParam(reportName) === constants.SMS_REPORT) {
        isSMSReport = true;
    }
    return isSMSReport;
}

function isOrthoticReport(objectName, reportName) {
    let isOrthoticReport = false;
    if (getStringParam(reportName) === constants.ORTHOTIC_REPORT) {
        isOrthoticReport = true;
    }
    return isOrthoticReport;
}

function setReportFilterData(reportData) {
    return function (dispatch) {

        dispatch({
            type: actions.SET_REPORT_FILTER_DATA,
            payload: reportData,

        })
    }
}

function isSalesSummaryReport(objectName, reportName) {
    let isSalesSummaryReport = false;
    if (getStringParam(reportName) === constants.SALES_SUMMARY_REPORT) {
        isSalesSummaryReport = true;
    }
    return isSalesSummaryReport;
}

function isUserReport(objectName, reportType) {
    let isUserReport = false;
    if (getStringParam(reportType) === constants.USER_REPORT_TYPE) {
        isUserReport = true;
    }
    return isUserReport;
}
function getReportType(objectName, reportName) {
    let type = '';
    try {
        if (isInexpressReport(objectName, reportName)) {
            type = constants.INXPRESS_REPORT_TYPE;
        } else if (!isTasksReport(objectName, reportName) && !isEmailReport(objectName, reportName) && !isSalesSummaryReport(objectName, reportName) && !isSMSReport(objectName, reportName) && !isOrthoticReport(objectName, reportName)) {
            type = constants.SALES_SERVICE_REPORT_TYPE;
        } else if (isTasksReport(objectName, reportName)) {
            type = constants.TASK_REPORT_TYPE;

        } else if (isSalesSummaryReport(objectName, reportName)) {
            type = constants.PIPELINE_REPORT_TYPE;

        } else if (isSMSReport(objectName, reportName)) {
            type = constants.SMS_REPORT_TYPE;

        } else if (isOrthoticReport(objectName, reportName)) {
            type = constants.ORTHOTIC_REPORT_TYPE;

        } else if (isInexpressReport(objectName, reportName)) {
            type = constants.INXPRESS_REPORT_TYPE;
        }
        else if (isUserReport(objectName, reportName)) {
            type = constants.USER_REPORT;
        }
        else {
            type = constants.EMAIL_REPORT_TYPE;
        }
    } catch (error) {
        console.error("Error in 'reportActions.js --> getReportType()':" + error);
    }
    return type;
}




export {
    setRecentReportResponseData,
    setReportHeaderData,
    setReportRedirection,
    drillDownMatrixReport,
    processChartData,
    isInexpressReport,
    getFranchiseListPromise,
    getFranchiseWFListPromise,
    setInXpressFranchiseList,
    setInXpressWorkflowList,
    getInXpressReports,
    isTasksReport,
    isEmailReport,
    isSMSReport,
    isSalesSummaryReport,
    isOrthoticReport,
    isUserReport,
    getReportType,
    setReportFilterData,
    setInXpressReportData,
}