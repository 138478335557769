 const message = {
    COMMON: {
        NO_ACTIONS_AVAILABLE: 'Nenhum objeto disponível para ação Quick Add',
        NOTES_REMOVE_MESSAGE:'As notas selecionadas serão excluídas. Tem certeza?',
        SELECTED_NOTE_MESSAGE:'Uma nota deve ser selecionada pelo menos para executar esta ação.',
        BR_WINDOW_OPEN_CONFORMATION: 'Não suportamos recursos de arrastar e soltar para Campanhas. Você gostaria de abrir a campanha?',
        CONFIRM_DIALOG_TITLE: 'Diálogo de confirmação',
        ALERT_DIALOG_TITLE: 'Diálogo de alerta',
        XMS_SUCCESS_ALERT_DIALOG_TITLE: 'Sucesso',
        XMS_ERROR_ALERT_DIALOG_TITLE: 'Erro',
        SAVE: 'Salvo com êxito.',
        MERGE: 'Mesclado com êxito.',
        UPDATE: 'Atualizada com êxito.',
        DELETE: 'Excluído com êxito.',
        REMOVE: 'Removido com êxito.',
        UNLINK: 'Desvinculado com êxito.',
        COPY_EMAIL:'O ID do e-mail foi copiado para sua área de transferência',
        EMAIL_VALIDATION:'Por favor digite um email válido.',
        EMPTY_EMAIL:'Nenhum e-mail está presente para copiar',
        ERROR: 'O sistema não responde.',
        ACCESS_DENIED: 'Desculpe você não tem permissão para executar esta operação. Por favor contacte o seu administrador.',

        /** START:  Validation message */
        BLANK: 'em branco',
        EMAIL: 'é um endereço de email inválido.',
        VALID_EMAIL: 'O endereço de e-mail que você digitou não parece ser um endereço válido. Por favor tente novamente. Dica: por favor verifique se o endereço de e-mail tem o formato \\@domain.com',
        URL: 'é um URL inválido.',
        VALID_URL: 'Por favor insira uma URL válida.',
        INTEGER: 'Não é um número inteiro válido.',
        VALID_INTEGER: 'Digite um número inteiro válido.',
        FLOAT: 'não é um flutuador.',
        VALID_FLOAT: 'Por favor insira um float válido.',
        VALID_PHONE: 'Os números de telefone devem ser numéricos!',
        VALID_LIST: 'Por favor selecione um valor.',
        TOO_BIG_SIZE: 'O valor que você digitou é muito grande para este campo. Digite um valor menor.',
        NO_RECORD: 'Nenhum Registro Encontrado.',
        VALIDATE_BLANK: 'Nao pode estar em branco.',
        VALIDATE_INTEGER: 'tem que ser um inteiro.',
        VALIDATE_DECIMAL: 'tem que ser em decimal.',
        VALIDATE_POSITIVE_DECIMAL: 'tem que ser maior que 0 e em decimal.',
        VALIDATE_URL: 'Formato de URL inválido. Exemplo-www.soffront.com',
        VALIDATE_EMAIL: 'Formato de e-mail inválido.',
        VALUE: 'valor',
        LIBRARY_DIALOG_TITLE: 'Soffront biblioteca online',
        BLANK_EMAIL: 'O email está em branco',
        INVALID_EMAIL: 'Por favor forneça um e-mail válido!',
        BLANK_NUMBER : 'O número está em branco',
        INVALID_NUMBER :'Forneça um número válido',
        EMPTY_SEARCH_CRITERIA: 'Por favor insira critérios de pesquisa',
        NO_DATA: 'Nenhum dado encontrado',
        DISCONNECT_ALERT_MSG: 'Tem certeza de desconectar esse cliente?',
        DISCONNECT_SUCCESS: 'Você desconectou seu cliente com sucesso.',
        CONFIRM_DELETE_RECORD: 'O registro será excluído. Você tem certeza?',
        DELETE_SUCCESS: ' foi excluído com êxito.',
        COULDNOT_LOCATE: "Não conseguimos localizar ",
        DELETED_OR_ACCESS_DENIED: " Ele pode ter sido excluído ou você pode não ter acesso a ele ou você já abriu.",
        CLICK: 'Clique',
        HERE: 'Aqui',
        MERGE_ALERT_1: 'Tem certeza que quer fundir.',
        WITH: 'com',
        MERGE_ALERT_2: 'e exclua',
        DATA_ACCESS_REQUEST_SEND_SUCCESS: 'Pedido de acesso a dados enviado para',
        DATA_ACCESS_REQUEST_SEND_FAILURE: 'A solicitação de acesso a dados não pode ser enviada',
        REQUEST_DATA_ACCESS: 'Solicitar acesso a dados de',
        WE_WILL_EMAIL: 'Nós enviaremos por correio electrónico',
        AT: 'em',
        TO_APPROVE_REQUEST: 'para aprovar o seu pedido',
        HAVE_BEEN: 'têm estado',
        /** END:  Validation message */



        //--not found-- starts
        NOTIFICATION_INFO_EMPTY: 'Nenhuma notificação disponível',
        VALIDATE_MORE_CHECKBOX_SELECTION: 'You can not perform search with <*> when more than one checkbox is selected!',
        VALIDATE_NO_CHECKBOX_SELECTION: 'You can not perform search with <*> when more than no checkbox is selected!',
        /** START:  Validation message */
        REMINDER_VALUE: 'O valor do lembrete não pode estar em branco',
        DUPLICATE_EMAIL: 'Você já tem registro (s) com o mesmo ID de e-mail. Clique em Ok para continuar ou clique em Cancelar para alterar o ID do email.',
        DUPLICATE_CONTACT_EMAIL: 'Você já tem registro (s) com o mesmo ID de e-mail. Por favor, insira um ID de e-mail diferente.',
        /** END:  Validation message */
        //--not found-- ends
        CLIENT_CONNECT_MESSAGE: 'Este cliente não possui uma conta com este e-mail no Soffront Online. Primeiro, peça ao cliente para criar uma conta com o mesmo e-mail.',
        BLANK_CAMPAIGN_TYPE: 'Tipo de campanha não pode estar em branco.',
        DUPLICATE_CAMPAIGN_TYPE: 'Tipo de campanha já existe.',
        CAMPAIGN_TYPE_SAVE_SUCCESS: 'Tipo de campanha salvo com êxito.',
        STEPPER_BACK_MESSAGE: 'Você gosta de ir para a página de informações do modelo? Quaisquer alterações não salvas serão perdidas.',
        CENCEL_MOSAICO_MESSAGE: 'Tem certeza de que deseja cancelar sem salvar?',
        NO_RESULTS_FOUND: 'Não foram encontrados resultados',
        FOR: 'para',
        ENABLE_MAILING_SUCCESS: 'Envio de email habilitado com sucesso',
        DISABLE_MAILING_SUCCESS: 'Envio de email com êxito desativado',
        MAILING_FAILURE: 'Esta conta não é um inquilino CRM',
        TEST: 'Teste',
        SELECT_RECORD_FOR_DELETE: 'Por favor, selecione um registro para excluir.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Por favor, selecione um registro para editar.',
        SELECT_RECORD_FOR_DELETE_ONE_RECORD: 'Por favor selecione um registro para deletar.',
        ASSETMENU_DIALOG_TITLE: 'Selecione um ativo',
        UNIT_SELECT_ALERT_MESSAGE: 'Você pode selecionar uma unidade de cada vez.',
        LOOKUP_MULTI_SELECT: 'Por favor, selecione apenas um registro.',
        LOOKUP_SELECT_A_RECORD: 'Por favor, selecione um registro.',
        BOTH_COMPANY_AND_WORKFLOW_SELECTED: 'Selecionou a empresa e o fluxo de trabalho.',
        VALIDATE_ZERO: 'não pode ser zero.',
        GREENLIGHT_EXPORT_NO_RECORD: 'Não há contas no fluxo de trabalho Greenlight para este intervalo de datas.',
        WITHOUT_SAVE_MESSAGE: 'Você quer continuar sem salvar as alterações?',
        DELETE_API_KEY: 'Isto eliminará a sua Chave API em CRM. Gostaria de continuar?',
        UNIT_ALERT: 'Selecione um registro para fazer o login.',
        THIS_FIELD_CANNOT_BLANK: 'Este campo não pode estar vazio.',
        CANNOT_DELETE_ALL_ITEM: 'Deve ter pelo menos um item no Menu Móvel.',
        DUPLICATE_MSG_1: 'Você já tem registro (s) com o mesmo ',
        DUPLICATE_MSG_2: '. Clique em Ok para continuar ou clique em Cancelar para mudar o ',
        DUPLICATE_MSG_3: ' e ',
        DUPLICATE_CONTACT_EMAIL_1: 'Você já tem registro (s) com o mesmo ',
        DUPLICATE_CONTACT_EMAIL_2: '. Por favor, indique diferente ',
        INVALID_TIME_ERROR_MSG: 'Por favor, forneça um intervalo de tempo válido.',
        DOESNT_HAVE_ASSOCIATED_1:'Esta ',
        DOESNT_HAVE_ASSOCIATED_2: " não tem um contato associado.", 
        PHONE_CALL_NOT_ENABLED: 'A chamada telefônica não está habilitada.',
        NOT_CONFG_CALL_SETUP : 'Você não configurou a chamada.',
        NOT_CONFG_CALL_SMS_SETUP:'Você não configurou a configuração de chamada / sms.',
        SEARCH_NO_CHECKBOX_SELECTION: 'Você não pode realizar a pesquisa quando nenhuma caixa de seleção está marcada!',
        VALID_SOURCE_LIST: 'Selecione o campo de origem.',
        LMS_USER_NOT_AVAILABLE: 'O LMS ainda não está ativado. Entre em contato com seu administrador.',   
        ADD_BRANCH_BILLINGMASSAGE:'Atualmente você não tem licenças. Você comprará uma nova licença para esta unidade por US$ 40,00/mês. Além disso, será cobrada uma taxa de instalação de US$ 100,00 para configurar e treinar o novo inquilino.'
    },
    SALES_TEMPLATE: {
        NAME_BLANK: 'Introduza um nome para identificar este modelo.',
        SUBJECT_BLANK: 'Por favor insira um assunto para este modelo.',
        DUPLICATE_NAME: 'Você já tem um modelo chamado%%. Nós não suportamos modelos duplicados. Por favor digite um nome diferente e tente novamente.',
        DELETE: 'Modelo será excluído. Tem certeza?',
        TEMPLATE_DELETED: 'Modelo excluído com êxito',
        TEMPLATE_NAME_ERROR: 'Você já tem um modelo existente com esse nome. Por favor, insira um nome diferente e tente novamente.',
    },
    AGREEMENT_TEMPLATE: {
        NAME_BLANK: 'Introduza um nome para identificar este modelo.',
        // SUBJECT_BLANK: 'Please enter a subject for this template.',
        DUPLICATE_NAME: 'Já tem um modelo chamado %% . Não apoiamos modelos duplicados. Por favor, escreva um nome diferente e tente novamente.',
        DELETE: 'Modelo será excluído. Tem certeza?',
        TEMPLATE_DELETED: 'Modelo excluído com êxito',
        TEMPLATE_NAME_ERROR: 'Você já tem um modelo existente com esse nome. Por favor, insira um nome diferente e tente novamente.',
        DUPLICATE_TEMPLAE_EXIST: 'Este nome de modelo já existe.',
        RECORD_NOT_EXIST: 'Este recorde não existe.',
        SAVE_TO_DOWNLOAD: 'Ainda não guardou o modelo do acordo. Guarde primeiro o modelo do Contrato para o descarregar.',
        TEMPLATE_CAN_NOT_EMPTY: 'O nome do modelo do acordo não pode ser vazio.',
        SELECT_TEMPLATE: 'Por favor, selecione um modelo.',
        SENT_OR_SIGNED: 'Você já enviou este contrato uma vez. Você não pode enviá-lo novamente.',
        SIGNATURE_TAGS_NOT_SEQUENCED_1: 'Adicione as tags de assinatura em ordem sequencial.',
        SIGNATURE_TAGS_NOT_SEQUENCED_2: 'Parece que você ignorou a ordem sequencial ao adicionar a tag de assinatura.',
        SIGNATURE_TAGS_MISSING: 'Não há etiqueta de assinatura disponível no corpo do Contrato. O órgão do contrato deve ter pelo menos uma etiqueta de assinatura.',
        SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING: 'Parece que você usou campos de mesclagem para uma assinatura sem usar a tag de assinatura. Adicione a tag de assinatura para continuar.',
        AGREEMENT_EDIT: 'Você pode editar apenas registros de contrato e não outros registros de anexo ou registros de contrato já enviados.',
        AGREEMENT_EXPIRED_1: 'Este acordo expirou em ',
        AGREEMENT_EXPIRED_2: 'Prolongue a data de expiração do contrato antes de enviá-lo.',

    },
    SMS_TEMPLATE: {
        BLANK_MESSAGE: 'Por favor, digite uma mensagem para salvar este modelo.',
        SAVE_MESSAGE: 'SMS salvou com sucesso.',
    },
    MARKETING_TEMPLATE: {
        NAME_BLANK: 'Introduza um nome para identificar este modelo.',
        SUBJECT_BLANK: 'Por favor insira um assunto para este modelo.',
        DUPLICATE_NAME: 'Você já tem um modelo chamado%%. Nós não suportamos modelos duplicados. Por favor digite um nome diferente e tente novamente.',
        DELETE: 'Modelo será excluído. Tem certeza?',
        BACK: 'Se você voltar você perderá suas mudanças não salvas. Você deseja continuar?',
        SELECT_DESIGN: 'Selecione a partir de um design',
        CONFIRM2: 'os registros serão atualizados para',
        CONFIRM3: 'Tem certeza?',

        //--not found-- starts
        TEMPLATE_UPDATE: 'Modelo (s) de marketing atualizado',
        CONFIRM1: 'de',
        ALERT1: 'fora de',
        FROM_NAME_BLANK: 'Por favor, insira um nome.',
        FROM_EMAIL_BLANK: 'Por favor, escolha um e-mail.',
        DUPLICATE_TEMPLATE_NAME: 'O nome do modelo já existe.'
    },
    BROADCAST_CAMPAIGNS: {
        NAME_BLANK: 'Por favor insira um nome para identificar esta campanha de difusão.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Por favor selecione',
        FILTER_TYPE_GROUP: 'um grupo',
        FILTER_TYPE_CONTACT: 'um filtro de contato',
        FILTER_TYPE_ACCOUNT: 'um filtro de conta',
        SUBJECT_BLANK: 'Por favor insira um assunto',
        FROM_NAME_BLANK: 'Por favor insira um nome',
        FROM_EMAIL_BLANK: 'Por favor selecione um e-mail',
        GROUP_NAME_BLANK: 'Nome do grupo não pode estar em branco!',
        SAVE: 'Você criou essa campanha com sucesso.',
        UPDATE: 'Você atualizou com sucesso esta campanha.',
        GROUP_NAME_SIZE_LIMIT: 'Nome do grupo não pode ter mais de 128 caracteres.',
        EXECUTION_CANNOT_CANCEL_1: 'execução não pode ser cancelada agora.',
        EXECUTION_CANCEL_MSG: 'A aplicação tentará cancelar a execução imediatamente',
        CONFIRM: 'Por favor confirme',
        EXECUTION_CANNOT_CANCEL_2: 'Uma campanha que já está em processamento não pode ser cancelada.',
        PURCHASE_MORE: 'para comprar mais.',
        CREDITS_REMAINING: 'créditos de e-mail restantes.',
        VERIFY_INVALID_EMAIL: 'é um endereço de email inválido.',

        //--not found-- starts
        VERIFY_BLANK_EMAIL: 'Você deve digitar um endereço de e-mail para verificar.',
        SEND_VERIFICATION_EMAIL_SUCCESS: 'Enviamos o e-mail de verificação. Verifique a sua caixa de entrada e clique em verificar este endereço de e-mail. Em seguida, volte e clique no ícone de atualização para ver seu novo endereço de email.',
        EMAIL_INTEGRATION_NEW: 'Este endereço de e-mail já foi enviado para verificação! Por favor, verifique sua caixa de entrada e clique em Confirmar este endereço de e-mail. Em seguida, volte e clique no ícone de atualização para ver seu novo endereço de e-mail.',
        EMAIL_INTEGRATION_VERIFIED: 'Este endereço de e-mail já foi confirmado!',
        //--not found--ends
        BD_NAME_COPY_ERROR: 'Você tem uma campanha existente com esse nome. Por favor, insira um nome diferente para esta campanha',
        STEPPER_BACK_MESSAGE: 'Você gosta de ir para a página de informações de transmissão? Quaisquer alterações não salvas serão perdidas.'
    },
    SPACE: ' ',
    LIST_VIEW: {
        SELECT_RECORD: 'Por favor, selecione um registro para executar mais uma ação.',
        SELECT_RECORD_FOR_CONVERT: 'Por favor, selecione um registro para converter.',
        SELECT_RECORD_FOR_EDIT: 'Por favor, selecione um registro para editar.',
        SELECT_RECORD_FOR_COPY: 'Selecione um registro para copiar.',
        SELECT_RECORD_FOR_SEND_EMAIL: 'Por favor, selecione um registro para enviar e-mail.',
        MULTIPLE_RECORD_FOR_EDIT: 'Por favor, selecione um registro para editar.',
        MULTIPLE_RECORD_FOR_COPY: 'Selecione um registro para copiar.',
        MULTIPLE_RECORD_FOR_MORE: 'Selecione um registro para MAIS.',
        CANNOT_COPY_APPOINTMENT_WORKFLOW: 'Não é possível copiar o fluxo de trabalho das nomeações.',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Selecione um registro para copiar.',
        SELECT_MAXIMUM_FIELD_FOR_SETUP: 'Você alcançou o limite máximo do total de 15 campos selecionados.',
        CONVERT_CONTACT_TO_ACCOUNT_CONFIRM: 'Esse contato já está associado a uma conta. Clique em OK para ver os detalhes da conta.',
        SHARE_PERMISSION: 'Você não tem permissão para compartilhar esse registro.,',
        NOT_ADDED: 'Você não adicionou,',
        FIELDS_TO_VIEW: 'campos a serem visualizados.',
        SETUP_MINIMUM_FIELD_ALERT: 'Por favor, adicione outro campo. Exigimos um mínimo de dois campos para o modo de exibição.',
        SELECT_ANOTHER_TABLE: 'Por favor, selecione outra tabela e tente novamente.',
        //--not fount--starts
        WEB_FORM_ALERT1: 'fora de',
        WEB_FORM_ALERT2: 'o webform (s) selecionado (s) não é excluído, pois é usado no designer de automação.',
        SETUP_WORKFLOWSTAGE_MINIMUM_FIELD_ALERT: 'Por favor, adicione um campo para a coluna. Exigimos um mínimo de 1 campo para a vista.',
        SELECT_RECORD_FOR_SEND_SMS: 'Por favor, selecione um registro para enviar mensagem de texto.',
        SELECT_RECORD_FOR_CALL: 'Por favor, Selecione um registro para fazer chamada.',
        SELECT_MORE_RECORD_ACTION_ALERT: 'Por favor, selecione um registro para realizar mais ações.',
        SELECT_RECORD_FOR_DOWNLOAD: 'Por favor, selecione um registo para descarregar contas.',
        CHECKBOX_COLOR_GREEN_TITLE: 'Última atualização em 30 dias',
        CHECKBOX_COLOR_YELLOW_TITLE: 'Última atualização dentro de 30 a 60 dias',
        CHECKBOX_COLOR_RED_TITLE: 'Última atualização antes de 60 dias',
        STATUS_FIELD_NOT_ADDED_FOR_VIEW: 'You have not added any status field to view.',
        NO_EMAIL_TO_SEND_MAIL: 'Não existe nenhum endereço de e-mail disponível para enviar um e-mail.',
        REQUIRED_FIELD_CONVERT_FAILED: 'Converter falhou. Faltam um ou mais campos necessários.',
        SELECT_RECORD_FOR_To_EXCEL: 'Por favor, selecione um registro para o Excel.',
        SELECT_RECORD_FOR_ADD_TASK: 'Por favor selecione um registro para adicionar tarefa',
        UNIT_TASK_MESSAGE: 'Você pode adicionar tarefas apenas às unidades que estão diretamente conectadas a você.',
        UNIT_SEND_EMAIL: 'Você pode abrir o envio de e-mail apenas para as unidades que estão diretamente conectadas a você',
        UNIT_PHONE_NUMBER: 'Você pode abrir Chamada Telefônica apenas para as unidades que estão diretamente conectadas a você',
        FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED: 'Você não tem permissão para excluir o registro de anexo do tipo FDD ou FA.',
        MULTIPLE_SELECT_FDD_FA_MSG_1: 'Fora de ',
        MULTIPLE_SELECT_FDD_FA_MSG_2: ' registros de anexo selecionados ',
        MULTIPLE_SELECT_FDD_FA_MSG_3: ' Os registros de anexo não serão excluídos, pois são registros de anexo do tipo FDD ou FA. Tem certeza?',
    },
    AB_CAMPAIGNS: {
        NAME_BLANK: 'Digite um nome para a campanha.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Por favor selecione',
        FILTER_TYPE_GROUP: 'um grupo',
        FILTER_TYPE_CONTACT: 'um filtro de contato',
        FILTER_TYPE_ACCOUNT: 'um filtro de conta',
        GROUP_FILTER_BLANK: 'Selecione um grupo.',
        CONTACT_FILTER_BLANK: 'Por favor selecione um filtro de contato.',
        ACCOUNT_FILTER_BLANK: 'Por favor selecione um filtro de conta.',
        SUBJECT_BLANK: 'Insira um assunto para esta mensagem.',
        FROM_NAME_BLANK: 'Insira o nome do remetente.',
        FROM_EMAIL_BLANK: 'Insira o email do remetente.',
        GROUP_NAME_BLANK: 'Nome do grupo não pode estar em branco!',
        AB_DISTRIBUTION: 'Insira a distribuição a/b.',
        INVALID_AB_DISTRIBUTION: 'Distribuição inválida de a/b.',
        INVALID_WINNING_MARGIN: 'Margem para o vencedor é inválido.',
        MESSAGE_B_NOT_FILLED: 'Por favor insira os detalhes da mensagem B.',
        AB_DISTRIBUTION_OUT_OF_RANGE: 'Insira um valor de 1 a 99 para teste ',
        DAYS_BLANK: 'Por favor insira quantos dias você gostaria de esperar antes de enviar a mensagem vencedora para a lista restante.',
        INVALID_DAYS: 'Registro inválido. Por favor insira quantos dias você gostaria de esperar antes de enviar a mensagem vencedora para a lista restante.',
        SAVE: 'Você criou essa campanha com sucesso.',
        UPDATE: 'Você atualizou com sucesso esta campanha.',
        EXECUTION_CANNOT_CANCEL_1: 'A execução não pode ser cancelada agora.',
        EXECUTION_CANCEL_MSG: 'A aplicação tentará cancelar a execução imediatamente',
        CONFIRM: 'Por favor confirme',
        EXECUTION_CANNOT_CANCEL_2: 'Uma campanha que já está em processamento não pode ser cancelada.',
        CAMPAIGN_EXECUTE_IN_60_MIN: 'Esta campanha será executada em 60 minutos. ',
        OK_TO_CONFIRM: 'clique em OK para confirmar',
        ERROR_MSG_1: 'Por favor, selecione o modelo.',


        //--not found--starts
        ZERO_WINNING_MARGIN: 'Introduza a margem para o vencedor.',

    },
    REPEAT_DIALOG: {

        END_DATE_BLANK: 'A data de término não pode ficar em branco.',
        INVALID_END_DATE: 'Data de início não pode ser maior ou igual à data de término.',
        DAY_CHECKBOX_BLANK: 'Pelo menos verifique um dia.',
        DELETE_RECUR_TITLE: 'Excluir recorrente',
        EDIT_RECUR_TITLE: 'Editar recorrente',
        REPEAT: 'Repetir'

    },
    BATCH_ACTION: {
        CONVERT_SUCCESSFULL: ' foram convertidos com êxito.',
        SINGLE_CONVERT_SUCCESSFULL: ' foi convertido com êxito.',
        CONVERT_UNSUCCESSFULL: 'Ação incapaz de ser executada',
        CONVERT_SAME_STAGE: ' o registro já está em  ',
        MULTI_SHARE_SUCCESSFULL: ' foram compartilhados com sucesso.',
        SINGLE_SHARE_SUCCESSFULL: ' foi compartilhado com sucesso.',
        SHARE_UNSUCCESSFULL: 'Ação incapaz de ser executada',
        ASSIGN_UNSUCCESSFULL: 'Ação incapaz de ser executada',
        COMPLETE_SUCCESSFULL: ' foi concluída com êxito.',
        BATCH_COMPLETE_SUCCESSFULL: ' foram concluídas com sucesso.',
        COMPLETE_UNSUCCESSFULL: 'Ação incapaz de ser executada',
        GROUP_ADDED_SUCCESSFULLY: 'Adicionado com êxito ao grupo (s)',
        GROUP_REMOVED_SUCCESSFULLY: 'Exitosamente removido de todos os grupos.',
        GROUP_CLICK_TO_CONFIRM: ' será adicionado ao (s) grupo (s) selecionado (s) clique em "OK" para confirmar.',
        GROUP_BLANK_ADDED_BATCH: 'Por favor selecione pelo menos um grupo.',
        NOTE_TXT_CANNOT_BE_BLANK: 'O texto não pode estar em branco.',
        WORKFLOW_CONFIRMATION: 'O fluxo de trabalho será alterado para este registro. Clique em OK para confirmar.',
        BATCH_WORKFLOW_CONFIRMATION: 'O fluxo de trabalho será alterado para esses registros. Clique em OK para confirmar.',
        WORKFLOW_APPLIED: 'Fluxo de trabalho aplicado com êxito.',
        RECORDS_WITH_DIFFERENT_WORKFLOW: 'Selecionou registos com diferentes fluxos de trabalho. Clique em Ok para continuar e executar a operação de fluxo de trabalho de aplicação ou clique em Cancelar para selecionar novamente os registos.',
        RECORDS_WITH_DIFFERENT_WORKFLOW_STATUS: 'Selecionou registos com estatuto diferente. Clique em Ok para continuar e executar a operação de fluxo de trabalho de aplicação ou clique em Cancelar para selecionar novamente os registos.',
        ACTIVE_SUCCESSFULL: 'Status foi alterado para ativo.',
        INACTIVE_SUCCESSFULL: 'Status foi alterado para inativo.',
        BATCH_EDIT_BLANK: 'Campo de valor não pode estar em branco!',
        INVALID_WORKFLOW_STAGE: 'Você não pode converter o registro. O status do registro selecionado não está disponível no fluxo de trabalho atual. Pressione Ok para alterar o status ou o fluxo de trabalho',
        CONFIRMATION_DELETE_MSG: ' será excluído. Tem certeza?',
        CONFIRMATION_REMOVE_MSG: ' serão removido. Tem certeza?',
        CONFIRMATION_UNLINK_MSG: ' será desvinculada. Tem certeza?',
        CONVERT_ALERT_MSG: ' será convertido para  ',
        CLICK_TO_CONFIRM: ' Clique em OK para confirmar.',
        ASSIGN_ALERT_MSG: ' registro (s) serão atribuídos a ',
        ROUND_ROBIN_BASIS: ' em uma base de Round Robin. Tem certeza?',
        CONFIRM_MSG: 'Tem certeza?',
        RECORDS: 'registros',
        RECORD: 'registro',
        ALL_RECORDS: 'Todos os registros',
        RECORD_WILL_BE_UPDATED: 'os registros serão atualizados para',

        //--not found--starts
        MULTI_ASSIGN_SUCCESSFULLY: ' foram atribuídos com sucesso.',
        SINGLE_ASSIGN_SUCCESSFULLY: ' foi atribuído com sucesso.',
        CONFIRM_UNSUBSCRIBE_RECORD: ' será anulado. Você tem certeza?',
        UNSUBSCRIBE: 'Você cancelou a inscrição do (s) contato (s) selecionado (s).',
        USE_ASSIGN_RULE_ALERT_MSG: ' registro (s) será atribuído ao representante de vendas de acordo ',
        EMPTY_RECYCLE_BIN: 'Todos os registros serão excluídos permanentemente da Lixeira. Você tem certeza?',
        PERMANENT_DELETE_MSG: ' será excluído permanentemente. Você tem certeza?',
        INVALID_STATUS_FOR_WORKFLOW_STAGE: 'O status do registro selecionado não está disponível no fluxo de trabalho atual. Pressione Ok para alterar o status do registro.',
        CONTACT_STATUS_UPDATE_SUCCESS: ' estado foi atualizado com sucesso.',
        PUBLISH_SUCCESSFULL: 'O status foi alterado para Publicar.',
        UNPUBLISH_SUCCESSFULL: 'O status foi alterado para Cancelar publicação.',
        RECORDS_NOT_IN_WORKFLOW: 'Todos os registos selecionados não estão no fluxo de trabalho dos contactos.',
        RECORD_NOT_IN_WORKFLOW: 'Não é possível converter, uma vez que o registo selecionado não está no fluxo de trabalho dos contactos.'
    },
    BATCH_ACTION_VALIDATION: {
        ADDTOGROUP_BLANK: 'O registro selecionado será removido de todo (s) grupo (s). Clique em "OK" para confirmar.',
        ADDTOGROUP_NAMEBLANK: 'O nome do grupo não pode ficar em branco.',
        SHARE_WITH_BLANKUSER: 'Por favor selecione ao menos um usuário',
        ASSIGN_BLANKUSER: 'Por favor selecione um usuário.',
        CONVERT_SELECT_STATUS: 'Por favor selecione um status ',
        MAP_INSUFFICIENT_INFO: 'Informações insuficientes para mostrar mapa',
        GROUP_ALREADY_EXIST: 'O nome do grupo já existe!',

        //--not found--starts
        ALREADY_ACTIVE: 'Registro já em status ativo.',
        ALREADY_INACTIVE: 'Registro já em status inativo.',
        ALREADY_PUBLISH: 'Grave já no status Publicar.',
        ALREADY_UNPUBLISH: 'Grave já no status Cancelar publicação.',
    },
    ATTACHMENT: {
        ATTACHMENT_UPLOAD: ' Anexo enviado com sucesso',
        ATTACHMENT_LINK_ADDED: 'O link do documento foi adicionado com sucesso!',
        ATTACHMENTS_UPLOAD: ' Anexos arquivados com sucesso',
        VALIDATE_ATTACHMENT: 'Por favor selecione um arquivo para anexar   ',
        ATTACHMENT_UPLOAD_FAILURE: ' Este arquivo já existe.',
        VALIDATE_EMPTY_FILE: ' O tamanho do arquivo para carregar não pode ser 0 ',
        VALIDATE_FILE_SIZE: 'O tamanho do arquivo para carregar não pode ser maior do que 5 MB',
        VALIDATE_LOGO_SIZE: 'A foto que você selecionou é muito grande. O tamanho máximo de arquivo permitido é 500 KB.',
        VALIDATE_BLANK_FILE: 'Você não pode fazer o upload de um arquivo em branco',
        VALIDATE_LOGO_EXTENSION: 'Por favor selecione um arquivo jpg gif ou png!',
        ATTACHMENT_LOGO_TEXT: 'Você pode carregar um arquivo jpg gif ou png.',
        ATTACHMENT_LOGO_SIZE_TEXT: ' O tamanho máximo permitido é 500 KB.',
        UPLOAD_IMAGE: 'Carregar uma imagem',
        VALIDATE_MAX_ATTACHMENTS: 'Você não pode carregar mais de cinco arquivos',
        SELECT_IMAGE_TO_UPLOAD: 'Selecione uma imagem para carregar',
        MAX_SIZE_2_MB_ALERT: 'O tamanho do arquivo a ser carregado não pode ser maior do que 2 MB',
        VALID_FILE_UPLOAD: 'Você pode carregar um arquivo jpg gif ou png do computador.',
        MAX_SIZE_2_MB: 'O tamanho máximo permitido é de 2 MB.',
        CANNOT_UPLOAD_EXECUTABLE_FILE: 'Você não pode carregar o arquivo executável',
        THE_ATTACHMENT: 'O anexo',
        DOCUMENT_LINK: 'Link do documento', 

        //--not found-- starts
        ATTACHMENT_UPLOAD_IMAGE: 'Imagem carregada com sucesso',
        UPLOAD_FILE: 'Selecionar arquivos a serem carregados (até cinco arquivos por vez)',
        ADD_LINK: 'Adicione um link externo',
        FOLDER_NAME: 'Nome da pasta',
        FILE_NAME:'Nome do arquivo',
        ALREADY_EXIST: 'Já existe.',
        CANNOT_BE_DELETED: 'Não pode ser excluído.',
        CLICK_TO_DELETE: 'Clique em OK para excluir ',
        EMPTY_FOLDER_ERROR_DOWNLOAD: 'Não é possível baixar como a pasta está vazia.',
        EMPTY_FOLDER_ERROR_PUSH: 'Não é possível empurrar como a pasta está vazia.',
        RENAME_SUCCESSFULLY: 'Pasta renomeada com êxito.',
        DELETE_SUCCESSFULLY: 'Pasta excluída com êxito.',
        ADDED_SUCCESSFULLY: 'adicionado com êxito.',
        DOCUMENT_LINK_ADDED_SUCCESSFULLY: 'Link do documento adicionado com sucesso.',
        DOCUMENT_LINK_UPDATE_SUCCESSFULLY: 'Link do documento atualizado com sucesso.',
        DOCUMENT_LINK_DELETE_SUCCESSFULLY: 'Link do documento excluído com sucesso.',
        FILE_ADDED_SUCCESSFULLY: 'Arquivo adicionado com sucesso.',
        FILE_RENAME_SUCCESSFULLY: 'Arquivo renomeado com sucesso.',
        FILE_DELETE_SUCCESSFULLY: 'Arquivo excluído com sucesso.',
        IMAGE_RENAME_SUCCESSFULLY: 'Imagem renomeada com sucesso.',
        IMAGE_NAME: 'Nome da imagem',
        MAX_SIZE_50_MB: 'O tamanho máximo de arquivo permitido é 50 MB.',
    },
    AUTOMATION_DESIGNER: {
        STEPPER_BACK_MESSAGE: 'Você gosta de ir para a página de informações do modelo? Quaisquer alterações não salvas serão perdidas.',
        MAX_CONNECTION: "O número máximo de conexões foram alcançadas.",
        TOUCH_CAMPAIGN_NAME: "Por favor selecione um nome para esta campanha Touch",
        SELECT_FILTERS: "Por favor selecione um",
        SELECT_FILTERS_A: "Por favor selecione um",
        START_DATE: "Por favor selecione uma data de início para",
        EMPTY_WEBSITE_LINK: "Você não pode adicionar um link em branco de Website.",
        CANNOT_START_WITH_DECISSION: 'Você não pode começar com uma decisão. Por favor comece com qualquer uma das opções no menu iniciar à esquerda.',
        CANNOT_START_WITH_ACTION: 'Você não pode começar com uma ação. Por favor comece com qualquer uma das opções no menu iniciar à esquerda.',
        CONNECT_WITH_PROPER_NODE: 'Conecte-se com o node apropriado',
        FROM_NAME: 'Digite um remetente para',
        FROM_EMAIL: 'Por favor selecione um De E-mail',
        RESEND_AFTER: 'Por favor insira reenviar depois de dia (s)',
        SELECT_WEB_FORM: 'Por favor selecione um formulário Web',
        SELECT_GROUP: 'Por favor selecione um grupo.',
        SELECT_GROUP_OPERATION: 'Por favor escolha uma operação de grupo.',
        ENTER_NOTE: 'Por favor insira sua nota.',
        SELECT_ONE_RECIPIENT: 'Por favor selecione pelo menos um destinatário',
        SELECT_USER: 'Por favor selecione um usuário.',
        ENTER_TASK_NAME: 'Por favor insira um nome de tarefa',
        PROVIDE_DUEBY_FIELD: 'Por favor forneça um valor com campo Devido a',
        ENTER_LINK: 'Por favor insira um link.',
        PROVIDE_VALID_URL: 'Forneça por favor um URL válido do Website!',
        CATCH_ALL_LINK_CONNECTION: 'Você já tem um catch todos link clique condição',
        ENTER_SCORE: 'Por favor insira uma Pontuação',
        INVALID_SCORE: 'Você inseriu uma pontuação inválida',
        ENTER_SUBJECT: 'Por favor insira um assunto',
        ENTER_BODY: 'Por favor insira o conteúdo do corpo',
        ENTER_INTERVAL_VALUE: 'Por favor insira um valor para o intervalo',
        SELECT_ONE_HOUR: 'Por favor selecione 1 hora ou mais para o intervalo por hora.',
        BLANK_CAMPAIGN_NAME: 'O nome da campanha não pode ficar em branco.',
        CAMPAIGN_SAVED_SUCCESSFULLY: 'Esta campanha foi salva com sucesso',
        BLANK_CANVAS: 'Você não pode salvar uma tela em branco.',
        NOT_ROOT_NODE: 'Não pode ser uma raiz de node.',
        START_DATE_MUST_BE_GREATER: 'A data de início deve ser maior que a campanha Touch anterior.',
        EDIT_AND_COMPLETE_DETAILS: 'Por favor edite e complete os detalhes do',
        PROVIDE_WAIT_INTERVAL: 'Forneça por favor um intervalo da espera maior que zero.',
        CHECK_INTERVAL_WITH_PREVIOUS_TOUCH_CAMPAIGN: 'Verifique o intervalo com o (s) dia (s) de reenvio da campanha Touch anterior.',
        CHECK_START_DATE_OR_WAIT_BEFORE_SAVE_TC: 'Por favor verifique a data de início ou aguarde antes de salvar a campanha Touch.',
        PROVIDE_START_DATE: 'Por favor forneça uma data de início.',
        INTERVAL_EXPEXT_ZERO: 'Forneça um intervalo com exceção do zero.',
        EMPTY_LINK_NODE: 'Você não pode adicionar um node com link em branco.',
        DELETE_NODE_CONFIRMATION: 'Node será excluído. Quer continuar?',
        MSG_CANT_BE_DELETED: 'Esta mensagem foi enviada e não pode ser excluída.',
        FIRST_MSG_CANT_BE_DELETED: 'Não é possível excluir a primeira mensagem de uma campanha touch.',
        FIRST_WAIT_CANT_BE_DELETED: 'Você não pode excluir a primeira espera de uma campanha de toque',
        BLANK_INTERVAL: 'O intervalo não pode ficar em branco',
        PROVIDE_POSITIVE_INTERVAL: 'Por favor, forneça um intervalo maior que 0',
        WONT_BE_ABLE_TO_DELETE: 'Você não será capaz de excluí-lo.',
        URL_NOT_EXIST: 'O URL fornecido não está disponível no corpo do email.',
        GROUP_NAME_BLANK: 'Nome do grupo não pode ser em branco!',
        SELECT_LANDING_PAGE: 'Por favor selecione uma página de destino',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'Você já tem um designer de automação aberto por favor feche ou salve-o para abrir um novo designer de automação.',
        SAVE_DIAGRAM_BEFORE_COPY: 'Por favor salve o diagrama antes de executar a operação de cópia.',
        NO_TOUCH_FOUND: 'Nenhuma campanha touch encontrada.',
        ADD_ONE_FIELD: 'Por favor adicione pelo menos um campo',
        SELECT_FIELD_VALUE: 'Por favor selecione um valor de campo',
        INVALID_URL: 'é um URL inválido.',
        INVALID_INTEGER: 'Não é um inteiro.',
        INVALID_EMAIL: 'é um endereço de email inválido.',
        ALLOWED_UPTO_FOUR_FIELDS: 'Atualização é permitida até 4 campos. Não é possível adicionar outra linha de valor par.',
        WILL_BE_UPDATED: 'Será atualizado. Tem certeza?',
        DUPLICATE_TOUCH_NAME: 'Você já possui uma campanha "Touch enviada" nomeada.',
        TOOLTIP_MESSAGE_SEND_MAIL: 'Comece com uma campanha Touch que envia uma série de e-mails. Para usar isso arraste e solte-o na tela para a direita.',
        TOOLTIP_MESSAGE_WEB_FORM: 'Comece definindo o que acontece depois que um formulário da Web é enviado. Para usar isso arraste e solte-o na tela à direita.',
        TOOLTIP_MESSAGE_LANDING_PAGE: 'Comece definindo o que acontece após a apresentação da página de destino. Para usar isso arraste e solte-o na tela à direita.',
        TOOLTIP_MESSAGE_WEBSITE_VISIT: 'Comece definindo o que acontece depois que um contato visita uma página específica em seu site. Para usar isso arraste e solte-o na tela para a direita.',
        TOOLTIP_MESSAGE_LINK_CLICK: 'Defina o que acontece depois que um link em seu email é clicado por um contato. Para usar isso arraste-o e solte-o na tela para a direita e conecte-o com uma mensagem Touch.',
        TOOLTIP_MESSAGE_EMAIL_OPEN: 'Defina o que acontece depois que uma mensagem Touch é aberta por um contato. Para usar isso arraste-o e solte-o na tela para a direita e conecte-o com uma mensagem Touch.',
        TOOLTIP_MESSAGE_UNSUBSCRIBE: 'Defina o que acontece após um contato cancelar uma mensagem Touch. Para usar isso arraste-o e solte-o na tela para a direita e conecte-o com uma mensagem Touch.',
        TOOLTIP_MESSAGE_SCORE_REACHED: 'Defina o que acontece depois que um contato atingReatribua a conta para outro usuário. Para usar isso arraste e solte-o na tela para a direita.e um limite de pontuação. Para usar isso arraste e solte-o na tela para a direita.',
        TOOLTIP_MESSAGE_AUTO_MAIL: 'O e-mail de envio será usado para enviar e-mails únicos e de toque. Esta ação pode ser adicionada após uma campanha de toque ou qualquer decisão ou ação.',
        TOOLTIP_MESSAGE_CHANGE_GROUP: 'Adicionar ou remover contas e contatos de grupos. Para usar isso arraste e solte-o na tela para a direita.',
        TOOLTIP_MESSAGE_ADD_NOTE: 'Adicione uma nota à conta. Para usar isso arraste e solte-o na tela para a direita.',
        TOOLTIP_MESSAGE_NOTIFY: 'Notifique um usuário do CRM. Para usar isso arraste e solte-o na tela para a direita.',
        TOOLTIP_MESSAGE_ADD_TASK: 'Atribua uma tarefa a outro usuário. Para usar isso arraste e solte-o na tela para a direita.',
        TOOLTIP_MESSAGE_UPDATE_FIELD: 'Atualize o valor de um campo para conta ou contato. Para usar isso arraste e solte-o na tela para a direita.',
        TOOLTIP_MESSAGE_ASSIGN: 'Reatribua a conta para outro usuário. Para usar isso arraste e solte-o na tela para a direita.',
        TOOLTIP_MESSAGE_CONVERT: 'Converta a conta para um noapós o valor anterior exceto zerovo status. Para usar isso arraste e solte-o na tela para a direita.',
        TOOLTIP_MESSAGE_SEND_SMS: 'Send SMS to a group, account filter or contact filter. To use this, drag and drop it on the canvas to the right.',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'Você já tem uma campanha Touch denominada',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Por favor insira um nome diferente. ',
        INTERVAL_MODE_ADD_MSG_1: 'Por favor forneça um',
        INTERVAL_MODE_ADD_MSG_2: 'após o valor anterior exceto zero',
        AUTOMATED_CAMPAIGN_TITLE: 'Campanhas automatizadas',
        CANNOT_CONNECT_MULTIPLE: 'Não é possível conectar múltiplo  ',
        CONNECTION_EXIST: 'Você já possui uma conexão ativa de ',
        REMOVE_CONNECTION_ALERT: 'Se você remover a conexão você perderá todas as etapas seguintes',
        CONTINUE_ALERT: 'Quer continuar?',
        CANNOT_ADD: 'Você não pode adicionar',
        DIRECT_UNDER: 'diretamente sob um',
        DIRECT_UNDER_AN: 'diretamente sob um',
        MULTIPLE_TIME_SELECTED_MSG_1: 'Você selecionou o',
        MULTIPLE_TIME_SELECTED_MSG_2: 'campo (s) várias vezes para atualização. Remova as linhas duplicadas.',
        CANNOT_BE_LEAF_NODE: 'Não pode ser um nó folha.',
        CLICK: 'Clique',
        HERE: 'Aqui',
        PURCHASE_MORE: 'para comprar mais.',
        CREDITS_REMAINING: 'Créditos de e-mail restantes.',
        STOP_CAMPAIGN: 'Pare a campanha para fazer mudanças.',
        SELECT_EMAIL_TEMPLATE: 'Selecione um modelo de e-mail',
        NO_INFO_EXISTS: 'Não existe informação.',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Selecione um registro para copiar.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Por favor, selecione um registro para editar.',
        ADD_GROUP_CLICK: 'Clique',
        ADD_GROUP_CLICK_HERE: 'Aqui',
        CLICK_TO_ADD_NEW_GROUP: 'para adicionar um novo grupo',

        //--not found--starts
        CANNOT_SAVE_CAMPAIGN: "Não pode salvar esta campanha. Veja os blocos vermelhos na campanha por erros.",
        MESSAGE_SAVED_SUCCESSFULLY: 'Você salvou esta mensagem com êxito',
        MESSAGE_NOT_SAVED_SUCCESSFULLY: 'A mensagem não foi salva com êxito',
        QUERY_FILTER_BLANK: 'Por favor, selecione uma consulta',
        CONTACT_FILTER_BLANK: 'Por favor selecione um filtro de contato.',
        ACCOUNT_FILTER_BLANK: 'Por favor selecione um filtro de conta.',
        SAVE_AUTOMATION_FOR_TEST: 'Por favor, salve campanha de automação.',
        BIRTHDAY_CAMPAIGN_NAME: 'Por favor, selecione um nome para esta campanha de aniversário',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'Você já tem uma campanha de aniversário chamada',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Por favor insira um nome diferente.. ',
        BIRTHDAY_FIRST_MSG_CANT_BE_DELETED: 'Não é possível excluir a primeira mensagem de uma campanha de aniversário.',
        MIGRATION: 'Esta campanha automatizada está sendo atualizada para o novo criador de campanhas. Obrigado pela sua paciência.',
        MIGRATION_DIALOG_TITLE: 'Upgrade em andamento ...',
        MIGRATION_NOTE: 'Nota: Após a atualização, a campanha de automação começará a ser executada no dia em que você executar a operação Iniciar.',
        BIRTHDAY_CAMPAIGN_NO_REPORT: 'Campanha de aniversário ainda a ser enviada ao (s) destinatário (s).',
        RUNNING_CAMPAIGN_CANNOT_CANCEL: 'Uma campanha em execução não pode ser cancelada.',
        CONFIRM_CANCEL_SCHEDULE: 'Você gostaria de cancelar a campanha programada?',
        CAMPAIGN_SCHEDULE_SUCCESSFULLY: 'Esta campanha foi agendada com sucesso.',
        SCHEDULED_CAMPAIGN_CANCEL_SUCCESSFULLY: 'Esta campanha foi cancelada com sucesso.',
        CAMPAIGN_START_IMMEDIATELY: 'Esta campanha foi definida para começar imediatamente.',
        ENTER_FROM_NAME: 'Por favor, insira pelo nome.',
        ENTER_FROM_EMAIL: 'Por favor, insira a partir de e-mail.'
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME_BLANK: 'Por favor forneça um nome de domínio para gerar código de rastreamento.',
        DOMAIN_NAME_INVALID: 'Por favor forneça um nome de domínio válido.',
        TRACKING_CODE_BLANK: 'Não há nenhum código de rastreamento para copiar.',
        TRACKING_CODE_ALREADY_GENERATED: 'O código de rastreamento já está gerado.',
        DONE: 'Seu nome de domínio foi registrado.',
        SET_UP_INFO: 'Copie e cole este código em todas as páginas do seu site pouco antes da tag body end. Depois de terminar volte a esta página e clique no botão Done.'

    },
    MAIL: {
        SUCCESS: 'Seu email foi enviado.',
        BLANK_FROM_EMAIL: 'O remetente do email está em branco.',
        INVALID_FROM_EMAIL: 'Você tem um endereço de e-mail inválido no campo De.',
        INVALID_CC_EMAIL: 'Você tem um endereço de e-mail inválido no campo CC.',
        INVALID_BCC_EMAIL: 'Você tem um endereço de email inválido no campo Cco.',
        BLANK_SUBJECT: 'O assunto está em branco.',
        BLANK_EMAIL_BODY: 'O corpo do email está em branco.',
        BATCH_SEND_MAIL_MSG_1: 'Fora de',
        BATCH_SEND_MAIL_MSG_2: 'Você selecionou apenas',
        BATCH_SEND_MAIL_MSG_3: 'optaram por receber os seus e-mails. Gostaríamos de enviar o e-mail apenas para estes',
        BATCH_SEND_MAIL_MSG_4: 'Nenhum dos',
        BATCH_SEND_MAIL_MSG_5: 'optaram por receber os seus e-mails. Não vamos enviar este e-mail. Por favor feche esta aba',
        NO_RECIPIENT: 'Você não digitou nenhum destinatário para este e-mail',
        NO_RECIPIENT_FOUND: 'Nenhum destinatário encontrado para este e-mail.',
        BLANK_TO_EMAIL: 'Nenhum endereço de e-mail no campo Para.',
        INVALID_TO_EMAIL: 'Endereço de email inválido no campo Para.',
        SCHEDULE_SUCCESS: 'Seu email foi agendado com sucesso.',
        RESCHEDULE_SUCCESS: 'O e-mail foi remarcado com sucesso',
        TEMPLATE_NOT_SELECTED: 'Please select a template',
        NO_ACTIVE_AGREEMENT_TEMPLATE_MSG: 'Você não tem nenhum modelo de acordo ativo.',
        SELECT_DIFFERENT_SIGNER: "Please select a different signer.",
        SELECT_ALL_SIGNERS: 'Please select all the signer(s) to send the agreement email.',
    },
    APPOINTMENTS_TASKS: {
        DELETE: 'Este registro será excluído. Tem certeza?',
        INVALID_START_END_DATE: 'Data de início não pode ser maior ou igual à data de término.',
        RECURR_DELETE_DIALOG_ONLY_BTN_INFO: 'Todos os outros registros da série permanecerão.',
        RECURR_DELETE_DIALOG_FOLLOWING_BTN_INFO: 'Este e todos os seguintes registros serão excluídos.',
        RECURR_DELETE_DIALOG_ALL_BTN_INFO: 'Todos os registros da série serão excluídos.',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_1: 'Todos os outros',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_2: 'na série continuará a ser o mesmo.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_1: 'Este e todos os seguintes',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_2: 'será alterada.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_3: 'Quaisquer alterações no futuro',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_4: 'será perdido.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_1: 'Todos',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_2: 'na série será alterada.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_3: 'Qualquer alteração feita a outros',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_4: 'será mantido.',
        RESCHEDULE_TASK_SUCCESS: 'Tarefa foi reagendada com êxito',
        RESCHEDULE_APPOINTMENT_SUCCESS: 'O compromisso foi reprogramado com sucesso',
        NO_TASK_FOUND: 'Nenhuma tarefa encontrada',
        NO_APPOINTMENT_FOR_TODAY: 'Não há compromissos agendados disponíveis hoje',
        NO_PENDING_TASK_FOR_TODAY: 'Não há nenhuma tarefa pendente disponível hoje',
        NO_TASK_APPOINTMENT_FOUND: 'Nada pendente.',
        NO_TASK_APPOINTMENT_FOUND_FOR_COMPLETED: 'Nada concluído.',
        //--not found-- starts
        BLANK_START_END_DATE: 'Data de início e data de término não pode estar em branco',
        BLANK_START_DATE: 'Data de início não pode ser em branco',
        BLANK_END_DATE: 'A data de término não pode ficar em branco.',
        BLANK_NO_OF_DAYS: "O número de dias não pode ficar em branco.",
        ZERO_NO_OF_DAYS: "O número de dias não pode ser 0.",
        BLANK_SPECIFIC_DATE: "A data não pode ficar em branco.",
        INVALID_DUEBY_END_DATE: 'Devido a data não pode ser maior ou igual a data de término de repetição.',
        SEND_EMAIL_PROGRESS_MSG: 'O envio de e-mail está em andamento, levará um momento.',
        INVALID_DUEBY_START_DATE: 'O vencimento por data não pode ser maior ou igual à data de início.',
        SYNC_STARTED_MSG: 'O Sync começou. Por favor, refresque o calendário para ver novas nomeações.',
        SYNC_STARTED_MSG_FOR_SETUP: 'O Sync começou. O seu calendário será atualizado em breve.',
        ENABLE_SYNC_CAL: 'Por favor, ative a opção de calendário de sincronização em configuração de integração antes de fazer sincronização.',
        PRIVATE_APPOINTMENT_ALERT:"Este é um compromisso privado; você não pode abrir isto.",
        START_END_DATE_VALIDATE:'não pode ser maior ou igual a',
        CONVERT_CONFIRMATION_MESSAGE: 'Uma ou mais tarefas não são verificadas. Gostaria de continuar com a operação Converte sem verificar essas tarefas?'
    },
    TASKS:{
        DELETE_CANCELLED_STATAUS_RESTRICT_MSG:'Não tem permissão para cancelar nenhuma tarefa de ações corretivas.',
    },
    HOME: {
        FILTER_ADD: 'Filtro adicionado com êxito',
        FILTER_NOT_FOUND: 'Você não configurou nenhum filtro',
        FILTER_DELETE_RECORD: 'O filtro será excluído. Você tem certeza?',
        FILTER_DELETE: 'Filtro removido com êxito',
        FILTER_ON_MAX_FIELD: 'Você alcançou a condição máxima do campo. Permitimos até 10 campos.',
        ASSIGNMENT_RULES_ON_MAX_FIELD: 'Você atingiu a condição máxima de campo. Permitimos até 20 campos.',
        //not found in doc
        FILTER_NAME_BLANK: 'Por favor, forneça um nome de filtro.',
        FILTER_NAME_MAX_LENGTH: 'O nome do filtro não pode ter mais de 64 caracteres.',
        FILTER_CRITERIA_EMPTY: 'Por favor, forneça pelo menos um critério.',
        FILTER_SQL_ERROR_NO_VALUE: ' nao pode estar em branco.',
        FILTER_SQL_ERROR_INTEGER: ' não é um inteiro. ',
        FILTER_SQL_ERROR_FLOAT: ' não é um flutuador.  ',
        FILTER_SQL_ERROE_ALREADY_EXISTS: 'O nome do filtro já existe!',
        FILTER_DELETE_MSG_TC: 'Campanha (s) de toque a seguir ',
        FILTER_DELETE_MSG_BC: ' Campanha de Transmissão (s) seguinte (s) ',
        FILTER_DELETE_MSG_AB: ' as seguintes campanhas A / B ',
        FILTER_DELETE_MSG_AND: ' e ',
        FILTER_DELETE_MSG_BEING_USED: ' não pode ser excluído, pois está sendo usado ',
        ADD_GROUP_SUCCESS: 'O grupo acrescentou com sucesso.'
    },
    DOCUMENT_LIBRARY: {
        INVALID_UPLOAD_ERROR: 'Selecione um arquivo de imagem!',
        CONFIRM_DELETE_MSG: 'Tem certeza de que deseja excluir esta imagem?',
        OVER_MAX_SIZE: 'O ficheiro selecionado é demasiado grande para ser utilizado. O tamanho máximo do ficheiro permitido é de 50 MB.',
        OVER_MAX_SIZE_IMAGE: 'O arquivo que você selecionou é muito grande para usar. O tamanho máximo do arquivo permitido é de 500 kb',
        INVALID_DOCUMENT_UPLOAD_ERROR: 'exe bat documentos sh não estão autorizados a ser carregado!',
        DOCUMENT_FILE_EMPTY_ERROR: 'Por favor selecione um documento!',
        ASSETS_ALREADY_OPEN_ALERT: 'Você já tem guia biblioteca aberta o módulo de operações.',
        DUPLICATE_IMAGE_NAME: 'A imagem já existe com esse nome. Por favor, selecione uma imagem diferente!',
        DUPLICATE_DOCUMENT_NAME: 'Documento já existe com este nome. Por favor, selecione um documento diferente!',
        UPLOAD_IMAGE: 'Selecione as imagens para fazer upload (até cinco arquivos por vez).',
        ASSETS_ALREADY_OPEN_ALERT_HUB: 'Já tem o separador Library aberto no módulo Hub.',
        BLANK_TITLE_ERROR : 'Título não pode ficar em branco',
        BLANK_URL_ERROR : 'O link não pode ficar em branco',
        INVALID_URL_ERROR : 'Forneça um URL válido',
        CONFIRM_FILE_TYPE_CHANGE: {
            LN1: 'Você gostaria de sair da seção atual? Quaisquer alterações não salvas serão perdidas.',
            LN2: 'Clique em Ok para confirmar e sair.',
            LN3: 'Clique em Cancelar para permanecer na seção atual.',
        },
    },
    MERGE: {
        NOT_URL: "é um URL inválido.",
        NOT_FLOAT: "não é um flutuador.",
        NOT_INTEGER: "Não é um inteiro.",
        NOT_EMAIL: "é um endereço de email inválido.",
        MERGE_TENANT_EMAIL_ALERT_MESSAGE: 'Você não pode mesclar esses registros. Como você escolheu uma conta paga para excluir!',
    },
    SCHEDULE: {
        EMPTY: 'Por favor selecione uma data de agendamento',
        CAMPAIGN_SCHEDULE: 'Esta campanha será executada em',
        CONFIRM: 'Clique em OK para confirmar.',
        SMS_SCHEDULE: 'Esta mensagem de texto será enviada em'
    },
    RELATIONSHIP: {
        BLANK_RECORD: "Por favor selecione a relação para",
        BLANK_PARENT_RELATION: "Por favor selecione pelo menos um parentesco.",
        BLANK_CHILD_RELATION: "Por favor selecione pelo menos uma relação filho",
        NO_PARENT_RELATION: "Você não tem nenhuma relação. Por favor crie relação em personalização de todas as configurações",
        NO_PARENT_RELATION_FOR_USER: "Desculpe não há nenhuma relação disponível. Entre em contato com seu administrador",
        NO_RELATIONSHIP_SETUP: "Você não configurou nenhum tipo de relacionamento ainda",
        PLACEHOLDERTEXT_COMPANY: "Digite o nome da empresa e pressione Enter",
        PLACEHOLDERTEXT_NAME: "Digite um nome completo e pressione Enter",
        PLACEHOLDERTEXT_EMAIL: "Digite um e-mail e pressione Enter",
    },
    GROUP: {
        NO_TOUCH_CAMPAIGN: "Não é usado em qualquer campanha Touch.",
        NO_BROADCAST_CAMPAIGN: "Não é usado em nenhuma campanha de transmissão.",
        NO_AB_CAMPAIGN: "Não é usado em qualquer campanha A/B.",
        NO_WEBFORM: "Não é usado em qualquer formulário Web.",
        NO_LANDINGPAGE: "Não é usado em qualquer página de destino.",
        DELETE_MSG: 'O (s) seguinte (s) grupo (s) não podem ser excluídos',
        USED_IN_TOUCH_CAMPAIGN: 'é usado na seguinte campanha Touch (s)',
        USED_IN_BROADCAST_CAMPAIGN: 'é usado na seguinte campanha (s) de difusão',
        USED_IN_AB_CAMPAIGN: 'é usado na (s) seguinte (s) campanha (s) A / B',
        USED_IN_WEBFORM: ' é usado nos seguintes formulário (s) da Web',
        USED_IN_LANDINGPAGE: 'é usado na página de destino a seguir (s)',
        SELECT_GROUP: 'Selecionar um ou mais grupos',
        NO_GROUP_AVAILABLE: 'Nenhum grupo disponível. Clique',
        SELECT_RECORDS: 'Registros selecionados',
        ALL_RECORDS: 'Todos os registros',
        SELECT_OPTIONS: 'Selecionar opções',
        ADD_NEW_GROUP: 'para adicionar um novo grupo',
        GROUP_DELETE_RECORD: 'Grupo será excluído. Você tem certeza?',
        COMMON_DELETE_MSG:" não pode ser excluído porque está sendo usado nas seguintes campanhas:",
    },
    LOGIN: {
        VALIDATE_EMPTY_USER_NAME: 'Por favor insira seu e-mail e senha',
        VALIDATE_EMPTY_PASSWORD: 'Por favor insira sua senha',
        VALIDATE_USERNAME_PASSWORD: 'Você digitou um e-mail ou senha incorreta',
        INVALID_RESPONSE: 'Resposta inválida',
        LOGIN_COPYRIGHTS_TEXT_1: 'direito autoral',
        LOGIN_COPYRIGHTS_TEXT_2: 'Soffront software Inc. Todos os direitos reservados.',
        BEST_VIEWED: 'Melhor visualizado no Google Chrome Mozilla Firefox e Safari.'
    },
    AUDIT_LEADSCORE: {
        NO_AUDIT_DATA: 'Nenhum registro de trilha de auditoria encontrado',
        NO_SCORE_HISTORY: 'Sem histórico para exibir.'
    },
    DETAIL_VIEW: {
        CHANGE_PRIMARY_CONTACT_ALERT_1: 'Você selecionou',
        CHANGE_PRIMARY_CONTACT_ALERT_2: 'como o principal contato para',
        CLICK_TO_CONFIRM: 'Clique em OK para confirmar.',
        MAX_SELECTED_FIELD_LIMIT: 'Você alcançou o limite máximo de 50 campos selecionados',
        SELECT_ATLEAST_ONE_FIELD: 'Por favor selecione pelo menos um campo  ',
        SELECT_ATLEAST_ONE_CHILD_OBJECT: 'Por favor selecione pelo menos um objeto filho.',
        MAX_SELECTED_CHILD_OBJECT_LIMIT: 'Você alcançou o limite máximo de 10 objetos selecionados.',

        //--not found -- starts
        UNSUBSCRIBE: 'Você cancelou a inscrição deste contato com sucesso.',
        CONFIRM_UNSUBSCRIBE_RECORD: 'O registro será cancelado. Você tem certeza?',
        NO_ASSOCIATED_GROUPS_FOUND: 'Nenhum grupo associado está disponível',
        BLANK_SPECIFIC_DATE: 'A data não pode ficar em branco.',
        CONFIGURE_CHILD_SETUP: 'Por favor, configure o filho.',
        CHILD_SETUP_SELECT_ATLEAST_ONE_FIELD: 'Selecione pelo menos um campo para configuração infantil.',
        EMAIL_FORMAT_MSG: "- Formato de email inválido.",
        URL_FORMAT_MSG: "- Formato de URL inválido.",
        FLOAT_FORMAT_MSG: "- Formato flutuante inválido.",
        INTEGER_FORMAT_MSG: "- Formato inteiro inválido.",
        ERROR_MSG: "Você quer sair sem salvar as alterações?",
    },
    CHARTS: {
        NOTHING_TO_SHOW: 'Nada para mostrar para este período de tempo'
    },
    ADVANCE_SEARCH: {
        SEARCH_FIELDS_NOT_FOUND: 'Nada para procurar. Por favor, insira um valor.',
        MAX_ADD_FIELD_ALERT:'Você não pode pesquisar com mais de 10 campos',
        MIN_ADD_FIELD_ALERT:'Pelo menos um campo é necessário para pesquisar.',
        SELECT_FIELD_ALERT:'Selecione o campo antes de inserir o valor.',
        ADVANCE_SEARCH: 'Pesquisa avançada',
        EMPTY_DATE: 'Por favor, insira a data.',
        INVALID_DATE_RANGE: 'A partir da data não pode ser maior do que até a data',
        EMPTY_FROM_TO_DATE: 'Por favor, insira a partir de data e até a data.',
        EMPTY_FROM_DATE: 'Por favor, insira a partir da data.',
        EMPTY_TO_DATE: 'Por favor, insira a data.'
    },
    PUSH: {
        PUSH_SUCCESS_MSG_1: 'Você tem empurrado com sucesso',
        PUSH_SUCCESS_MSG_2: 'para as empresas selecionadas.',
        PUSH_SUCCESS_MSG_2_1: 'será empurrado',
        PUSH_SUCCESS_MSG_3: 'Uma notificação por e-mail foi enviada para as empresas selecionadas.',
        PUSH_SUCCESS_MSG_4: 'Você selecionou mais de 20 registros. Você pode carregar no máximo 20 contas de cada vez.',
        PUSH_SUCCESS_MSG_4_1: 'de uma vez.',
        PUSH_SUCCESS_MSG_5: 'Por favor, selecione uma empresa para empurrar',
        PUSH_SUCCESS_MSG_6: 'Não há clientes conectados disponíveis para enviar contas. ',
        PUSH_SUCCESS_MSG_7: 'Empurrado com sucesso.',
        PUSH_ALERT_MSG_8: 'Por favor, selecione pelo menos uma empresa para enviar modelos de e-mail',
        PUSH_ALERT_MSG_9: 'Selecione, pelo menos, uma empresa para impulsionar campanhas de automação',
        PUSH_ALERT_MSG_10: 'Por favor, selecione pelo menos uma empresa para impulsionar campanhas de difusão',
        PUSH_SUCCESS_MSG_11: 'para a empresa selecionada.',
        PUSH_SUCCESS_MSG_11_2: 'Você tem certeza?',
        PUSH_SUCCESS_MSG_12: 'Uma notificação por email foi enviada para a empresa selecionada.',
        PUSH_SUCCESS_MSG_13: 'Selecione pelo menos uma unidade para enviar leads de vendas',
        PUSH_SUCCESS_MSG_14: 'Selecione pelo menos uma unidade para enviar fluxos de trabalho',
        PUSH_FOLDER: 'Pasta',
        FAIL_USE_ASSIGNMENT_RULE_FOR_SALES_LEADS: 'Nenhuma regra de atribuição é atendida para enviar leads de vendas',
        PUSH_ALERT_MSG_12: 'Selecione pelo menos uma unidade para enviar modelos de contrato.',
        LANDING_PAGES: 'páginas de destino',
    },
    PROFILE_DETAILS: {
        UNMATCH_NEW_CONFIRM_PASSWORD: 'A senha confirmada não coincide com a nova senha.',
        NO_NEW_PASSWORD: 'Insira uma senha válida e confirme-a.',
        NO_CONFIRM_PASSWORD: 'Confirme a nova senha.',
        PROFILE_IMAGE_ATTACHMENT_LOGO_TEXT: 'Você pode carregar um arquivo jpg gif ou png.',
        PROFILE_IMAGE_SIZE_TEXT: ' O tamanho máximo permitido é 500 KB.',

        //not found in doc 
        VALIDATE_PROFILE_IMAGE: 'Selecione um arquivo de imagem.',
        PROFILE_IMAGE_UPLOAD: 'Imagem de perfil salva com êxito.',
        PROFILE_IMAGE_UPLOAD_FAILURE: 'Imagem de perfil não pôde ser salva.',
        PROFILE_UPDATE: 'Perfil atualizado com êxito.',
        VALIDATE_PROFILE_IMAGE_SIZE: 'A foto que você selecionou é muito grande. O tamanho máximo de arquivo permitido é 500 KB.',
        PROFILE_SIGNATURE_AUTOSAVED: 'Assinatura salva com sucesso.'

    },
    CUSTOMIZATION: {
        LOCATION_VAL_NOT_MATCH: 'O valor da localização não coincide.',
        LOCATION_VAL_BLANK: 'Não há nenhum valor disponível no campo Localização.'
    },
    IMPORT: {
        SELECT_FILE: 'Por favor, selecione um arquivo',
        SELECT_GROUP: 'Por favor, selecione um grupo',
        SELECT_WORKFLOW: 'Por favor, selecione um fluxo de trabalho',
        SELECT_STATUS: 'Por favor, selecione um status',
        SELECT_SOURCE: 'Por favor, selecione uma fonte',
        SOURCR_NAME_EXISTS: 'O valor já existe na lista',
        SOURCR_NAME_BLANK: 'Nome da fonte não pode estar em branco!',
        IMPORT_FILE_EXT: 'Por favor, selecione o arquivo com extensão .csv ou .xls / xlsx',
        NO_RECORD_TO_IMPORT: 'Nenhum registro para importação!',
        NO_MAPPING: 'Não há nenhum mapeamento para ',
        NOT_ALL_MAPPED: 'Uma ou mais colunas não estão mapeadas para o CRM. Gostaria de continuar sem importar essas colunas?',
        IMPORT_CONFIRM_MSG: 'os registos serão importados para',
        IMPORT_CONFIRM_MSG_1: 'registro será importado para',
        CONFIRM: 'Por favor confirme.',

        //not found in doc
        SELECT_LINK_USING: 'Por favor, selecione uma opção para link usando',
        SELECT_LINK_WITH: 'Por favor, selecione uma opção para link com',
        SELECT_DUPLICATE_FIELD: 'Selecione, pelo menos, uma opção para o campo duplicado',
        SOURCE_ALERT_MSG_1: 'Por favor, mapeie a conta "Source" campo para uma coluna que contém suas fontes de marketing.',
        SOURCE_ALERT_MSG_2: 'Você selecionou Source na lista suspensa, bem como na seção mapeamento. Você pode escolher o valor do campo de origem em um lugar não em dois lugares.',
        NO_MAPPING_AVAILABLE: 'Não há nenhum mapeamento disponível.',
        BLANK_MAP_NAME: 'Por favor, forneça um nome de mapa!',
        VALID_MAP_NAME_LENGTH: 'Nome do mapa não deve ser maior do que 32 caracteres!',
        DUPLICATE_MAP_NAME: 'Nome do mapa já existe!',
        MAP_SAVE_SUCCESS: 'Seu mapeamento de campo foi salvo com êxito!',
        NO_PREVIOUS_MAPPING: 'Nenhum mapeamento anterior presente!',
        MAP_NAME: 'Nome do mapa',
        DELETE_MAP_ALERT: 'Tem certeza de que deseja excluir',
        DELETE_MAP_SUCCESS: 'Mapeamento excluído com sucesso!',
        IMPORT_SUCCESS: 'Importado com sucesso.',
        WARNING: 'Aviso!',
        WARNING_TEXT: 'Por favor, não feche esta página até que a importação seja concluída.',
        IMPORTING_RECORDS: 'Importando seu registro (s)',
        SUCCESS_IMPORT_COUNT: 'registro (s) foram importados',
        FAIL_IMPORT_COUNT: 'registro (s) falha ao importar',
        FOUND: 'Encontramos',
        DUPLICATES: 'duplicata (s)',
        APPLY_WORKFLOW_TEXT: 'aos registros importados',
        APPLYING_WORKFLOW: 'Aplicando fluxo de trabalho',
        APPLIED_WORKFLOW: 'Concluído aplicando fluxo de trabalho',
        APPLIED_WORKFLOW_TO: 'Fluxo de trabalho aplicado para',
        MAX_IMPORT_RECORDS_MSG_1: 'Existem',
        MAX_IMPORT_RECORDS_MSG_2: 'registros no arquivo. Atualmente você pode importar 10000 registros de cada vez!',

        //not found in doc 

        //not found in doc
        VERIFY_CONTACTS_MSG_1: 'Por favor, verifique se esses contatos deram a você',
        VERIFY_CONTACTS_MSG_2: 'permissão para enviá-las por email. Nós não permitimos alugar ou',
        VERIFY_CONTACTS_MSG_3: 'listas compradas. Esses contatos devem ter perguntado',
        VERIFY_CONTACTS_MSG_4: 'para receber e-mails de você ou ter um relacionamento próximo com',
        VERIFY_CONTACTS_MSG_5: 'sua organização.',
        FILE_TYPE_MSG: 'A primeira linha do arquivo CSV ou XLS / XLSX deve conter os nomes dos campos',
        DATE_FORMAT_MSG: 'A data deve estar em Formato MM/DD/YYYY',
        ACCOUNTS_AND_CONTACTS: 'Contas e Contatos',
        IMPORT_CSV_FILE: 'Por favor, selecione ficheiro com extensão .csv.',
        RECORDS_IMPORT_SUCCESS: 'Os registos foram importados com sucesso.',
        GREEN_LIGHT_STATUS_ERROR: 'Uma importação já está em andamento, portanto, você pode iniciar uma nova importação somente após a conclusão do processo de importação atual.',
        GREEN_LIGHT_STATUS_SUCCESS: 'Sua importação atual está em andamento. Enviaremos a você um e-mail de confirmação para o e-mail registrado dos franqueados em questão assim que for concluído.',
    },
    YOUTUBE_VIDEO: {
        EMPTY_VIDEO_LINK: 'Por favor, Cole um URL de vídeo para o YouTube',
        INVALID_VIDEO_LINK: 'A URL que você digitou não é válida',
        INSERT_TITLE: 'Inserir vídeo do YouTube',
        VIDEO_LINK_PLACEHOLDER: 'Colar um URL de vídeo do YouTube'
    },
    WISTIA_VIDEO: {
        EMPTY_VIDEO_LINK: 'Por favor, Cole uma URL de vídeo para Wistia',
        INVALID_VIDEO_LINK: 'A URL que você digitou não é válida',
        INSERT_TITLE: 'Inserir vídeo de Wistia',
        VIDEO_LINK_PLACEHOLDER: 'Colar um URL de vídeo de Wistia'
    },
    INXPRESS_REPORT: {
        VALIDATE_EMPTY_FROM_DATE: 'Por favor, selecione a partir da data.',
        VALIDATE_EMPTY_TO_DATE: 'Por favor, selecione até a data.',
        VALIDATE_EMPTY_COUNTRY: 'Por favor, selecione país.',
        VALIDATE_EMPTY_FRANCHISE: 'Por favor, selecione Franquia.',
        VALIDATE_EMPTY_WORKFLOW: 'Por favor, selecione fluxo de trabalho.',
        VALIDATE_EMPTY_SALES_REP: 'Por favor selecione Representante de Vendas.',
        VALIDATE_EMPTY_OBJECT_NAME: 'Por favor selecione Report On.',
        NO_RECORD_EXPORT: 'Nenhum registro disponível para exportação.',
        REPORT_NOT_READY_FOR_EXPORT: 'Aguarde enquanto o relatório está sendo carregado, você não pode exportar agora.',
        BLANK_FROM_TO_DATE: 'Data não pode ficar em branco',
        VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16: 'Selecione um país para continuar.',
        VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT: 'Selecione um fluxo de trabalho para continuar.',
        VALIDATE_EMPTY_REGION_INXPRESS_REPORT: 'Selecione uma região para continuar.',
        VALIDATE_EMPTY_DATE_RANGE: 'Selecione um intervalo para continuar.',
        VALIDATE_EMPTY_WORKFLOW_STAGES: 'Selecione um estágio de fluxo de trabalho para continuar.',
        VALIDATE_EMPTY_CHART_TYPE: 'Selecione um tipo de gráfico para continuar.',
        VALIDATE_EMPTY_SHOW_TYPE: 'Selecione um tipo de programa para continuar.',
        VALIDATE_EMPTY_ACTIVITY_TYPE: 'Selecione um tipo de atividade para continuar.',
    },
    OTHER_VIEW: {
        TENANT_MESSAGE: 'Sua assinatura não inclui $$@@##PRODUCT_NAME##@@$$',
        USER_MESSAGE: 'Entre em contato com seu administrador para adicionar $$@@##PRODUCT_NAME##@@$$ à sua assinatura',
        AFTER_SUBSCRIPTION_VENDASTA_NOT_CONFIGURED: 'Ainda não definimos sua conta para $$@@##PRODUCT_NAME##@@$$. Vamos notificá-lo assim que estiver disponível.'
    },
    MY_INTEGRATION: {
        SAVE_SUCCESS: 'Sua configuração foi salva com sucesso.',
        APPEND_INFO: 'Anexar informações da empresa e de contato aos compromissos adicionados pelo Soffront',
        DISCONNECT_CONNECTION: 'A conexão será removida. Você tem certeza?',
        SAVE_FAIL: 'Sua configuração não é salva.',
        NO_CALENDAR: 'O calendário não está selecionado.',
        NO_SYNC_CHECKED: 'Você não selecionou nenhuma das opções de sincronização. Você pode optar por sincronizar seus e-mails, agenda ou ambos.',
        SYNC_EMAIL_INFO_1: 'Selecione esta opção para salvar automaticamente os e-mails da caixa de correio do G Suite / Office 365 no CRM.',
        SYNC_EMAIL_INFO_2: 'Os emails serão salvos em uma conta ou registro de contato correspondente.',
        SYNC_EMAIL_INFO_3: 'Os emails serão sincronizados a cada 5 minutos.',
        SYNC_CONTACT_INFO_1: 'Selecione essa opção para adicionar automaticamente seus contatos do CRM ao G Suite / Office 365.',
        SYNC_CONTACT_INFO_2: 'Todas as alterações feitas nos contatos no CRM serão atualizadas automaticamente no G Suite / Office 365.',
        SYNC_CONTACT_INFO_3: 'Os contatos serão sincronizados a cada 5 minutos.',
        SYNC_CALENDAR_INFO_1: 'Selecione esta opção para sincronizar automaticamente os compromissos entre sua agenda Soffront com uma agenda da sua conta do G Suite / Office 365.',
        SYNC_CALENDAR_INFO_2: 'Se você tiver vários calendários do G Suite / Office 365, precisará escolher um que gostaria de sincronizar.',
        SYNC_CALENDAR_INFO_3: 'Os calendários serão sincronizados a cada 5 minutos.',
        NO_CALENDAR_MESSAGE: 'Estamos aguardando os detalhes do seu calendário. Feche esta tela e volte em alguns minutos. Se você continuar vendo esta mensagem, desconecte e reconecte sua conta.',
        NO_PERMISSION: 'Minha integração não está disponível em seu plano atual. Por favor, assine um plano apropriado ou contate o suporte em smbsupport@soffront.com.',
        SELECTED_CALENDAR_NOT_FOUND: 'Não conseguimos encontrar o seu calendário selecionado anterior.Por favor, selecione outro e guarde.',
        INVALID_ACCOUNT_SYNC_STATE1:'Sua',
        INVALID_ACCOUNT_SYNC_STATE2:'a conexão não é mais válida. Desconecte e conecte novamente.',
        SEND_SALES_EMAILS_VALIDATION1: 'Para poder enviar e-mails usando',
        SEND_SALES_EMAILS_VALIDATION2: 'você precisará se conectar usando',
        SEND_SALES_EMAILS_VALIDATION3: 'Desconecte e conecte novamente usando',   
     },
    DOCUSIGN: {
        PROVIDE_USERNAME: 'Por favor, forneça um nome de usuário válido.',
        PROVIDE_PASSWORD: 'Por favor, forneça uma senha válida.',
        PROVIDE_INTEGRATOR_KEY: 'Por favor, forneça uma chave de integrador válida.',
        SUCCESSFULLY_SAVED: 'Você configurou com sucesso sua conta DocuSign.',
        FAILED_TO_SAVED: 'Não é possível configurar sua conta do DocuSign.',
        DOCUSIGN_SETUP_TITLE_1: 'Selecione um documento e envie-o ',
        DOCUSIGN_SETUP_TITLE_2: 'para ',
        DOCUSIGN_SETUP_TITLE_3: ' para assinatura',
        PROVIDE_RECIPIENT_NAME: 'Por favor, forneça o nome completo do destinatário',
        PROVIDE_RECIPIENT_EMAIL: 'Por favor, forneça o email do destinatário',
        PLEASE_SELECT_TEMPLATE: 'Por favor, selecione um modelo.',
        NO_TEMPLATES_FOUND: 'Nenhum modelo encontrado.',
        UNABLE_TO_SEND: "Não é possível enviar o modelo desde que sua conta do DocuSign tenha expirado.",
        TEMPLATE_NAME_LENTH: 'O nome do modelo não deve exceder 65 caracteres.',
        PLEASE_SELECT_CONTACT: 'Por favor, selecione pelo menos um contato.',
        DOCUSIGN_CONTACT_MESSAGE_1: 'Não há e-mail disponível para o seguinte contato (s): ',
        DOCUSIGN_CONTACT_MESSAGE_2: 'O email não será enviado a estes contato (s).',
        DOCUSIGN_CONNECTED_MESSAGE_1: 'Desculpe, você não tem permissão para realizar esta operação. Entre em contato com Soffront.',
    },

    TWILIO: {
        CALL_OVER_SECURED_NETWORK: 'Você pode fazer uma chamada apenas por meio de uma rede segura.',
        SAME_TO_AND_FROM_NUMBER: 'Para número e de número é o mesmo. Chamada não pode ser iniciada.',
        CALLING_SUPPORT: 'No momento, não há suporte para chamadas em seu país.',
        OUTBOUND_PHONE_NOT_VERIFIED: 'O seu número de telefone de saída não foi verificado. Verifique-o antes de fazer uma ligação.',
        CALL_RECORDING_SAVE: 'Salvo com sucesso. Por favor, saia e entre novamente para ver a mudança.',
        CALL_RECORDING_LAWS_AND_REGULATION_ALERT:'Certifique-se de que suas práticas de gravação de chamadas estejam em conformidade com as leis e regulamentos aplicáveis.',
        NOTE_SAVE: 'Nota salva com sucesso',
        PHONE_LOW_BALANCE1: 'O saldo do seu telefone agora ',
        PHONE_LOW_BALANCE2: ' minuto(s).',
        PHONE_LOW_BALANCE3: 'Você precisa de mais de 5 minutos de saldo para fazer uma chamada.',

        PHONE_BLANK_MESSAGE: 'Por favor, digite um número de telefone.',
        PHONE_ALREADY_CONFIGURE: 'Este número de telefone já está configurado.',
        DELETE_PHONE_NUMBER: 'Tem a certeza de apagar este número?',
        VERIFY_PHONE_NUMBER: 'Por favor, verifique antes de adicionar outro número.',
        PHONE_LIMIT: 'Não é possível adicionar mais de 3 números de telefone.',
        VERIFICATION_CODE: 'No prompt, por favor, digite o código de verificação.',
        VERIFICATION_MESSAGE_1: 'Nós chamaremos',
        VERIFICATION_MESSAGE_2: 'para verificar o número.',
        FIELDS_ADD_LIMIT:'Você não pode adicionar mais de 3 campos.',
        SETUP_UPDATED_SUCCESSFULLY:'Atualizado com sucesso.',
        SETUP_UPDATED_FIELDS:'Atualização falhou.'
    },
    CALL_SCRIPT: {
        BLANK_NAME: 'Por favor, insira um nome para este script.',
        BLANK_DETAILS: 'Por favor, insira os detalhes do script.',
        SAVE_SUCCESS: 'Você salvou com sucesso este script.',
        UPDATE: 'Você atualizou com sucesso este script.',
        DELETE: 'Você excluiu com sucesso este script.',
        NO_RECORD: 'Nenhum registro disponível',
        'DUPLICATE_SCRIPT_NAME': 'Este nome de script já existe. O nome do script duplicado não é permitido.',
        'EMPTY_SCRIPT_NAME_DETAILS': 'O nome do script e os detalhes do script não podem estar vazios.',
        'RECORD_NOT_FOUND_UPDATE': 'Registro não encontrado para atualizar.',
        DELETE_CONFIRMATION: 'Tem certeza de excluir este script?'
    },
    SMS_INTEGRATION: {
        PURCHASED_NUMBER: 'Você comprou o número com sucesso',
        SELECT_USER: 'Selecione pelo menos um representante de vendas para prosseguir.',
        ENTER_ZIP_OR_AREA: 'Por favor, insira um CEP ou código de área.',
        ENTER_AREA: 'Por favor, insira um código de campo.',
        ENTER_VALID_ZIP: 'Por favor insira um código postal válido.',
        ENTER_VALID_AREA_CODE: 'Por favor, insira um código de área válido.',
        NO_AVAILABLE_PHONE_NUMBERS: 'Não há número de telefone disponível para o código de área postal inserido.',
        NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP: 'Não há número de telefone disponível para o código de área inserido.',
        REMOVE_NUMBER_CONFIRMATION: 'Tem certeza de remover este número?',
        SMS_SUPPORT: 'O envio de mensagens de texto não é suportado em seu país.',
        SELECT_NUMBER: 'Por favor, selecione um número.',
        SELECT_AT_LEAST_ONE_NUMBER:'Selecione pelo menos um número para importar',
        TWILIO_NUMBER_IMPORTED_SUCCESS:'Seu(s) número(s) twilio importado(s) com sucesso',
        TWILIO_NUMBER_IMPORTED_ERROR:'Não foi possível importar seu(s) número(s) twilio(s).',
        PHONE_NUMBER_CANNOT_BE_PURCHASED:'O número de telefone não pode ser comprado. Entre em contato com o suporte da Soffront para obter mais detalhes.',
        ENTER_SMS_TEXT: 'Por favor, insira o texto sms.',
        SMS_LOW_BALANCE_1: 'Seu saldo de SMS é agora ',
        SMS_LOW_BALANCE_2: 'Você precisa comprar créditos sms para enviar sms.',
        TWILIO_NUMBER_PURCHASE_MSG_1: 'Sua conta será cobrada',
        TWILIO_NUMBER_PURCHASE_MSG_2: 'mês.',
        TWILIO_NUMBER_PURCHASE_MSG_3: 'para este número de telefone. Você pode remover o número da sua conta a qualquer momento para interromper o faturamento.',
        SMS_TOOLTIP_MESSAGE: 'Localizar por CEP ou código de área',
        SMS_TOOLTIP_MESSAGE_AREA_CODE: 'Localizar por código de área',
        INSERT_IMAGE_FILE: 'Por favor selecione apenas jpg, jpeg, gif ou png',
        BATCH_SEND_SMS_MESSAGE: 'mensagem enviada com sucesso',
        'INSUFFICIENT_BALANCE': 'mensagem enviada com sucesso',
        'BATCH_SMS_SENT': 'mensagem enviada com sucesso',
        'BATCH_SMS_FAILS': 'Falha ao enviar mensagem de texto',
        NO_FROM_NUMBER_MESSAGE: 'Você precisará comprar um número de telefone para enviar mensagens de texto. Por favor, compre o número de telefone no menu Integrações.',
        MAX_ATTACHMENT_FILE_FOR_SMS: 'Máximo de 10 arquivos de anexos permitidos.',
        'NO_INPUT_STREAM_FOUND': 'Nenhum fluxo de entrada foi encontrado',
        'NO_ATTACHMENT_FOUND': 'Nenhum anexo encontrado',
        'FILE_SIZE_ERROR': 'O tamanho do arquivo deve ser menor que 5MB.',
        IMAGE_SELECT: 'Selecione uma imagem',
        MERGE_FIELD: 'Selecione campos de mesclagem',
        DUPLICATE_FILE_NAME: 'Este arquivo já existe. Por favor, selecione um arquivo diferente.',
        RECORD_UNSUBSCRIBE_ALERT: 'O registro selecionado é retirado de textos. Você não pode enviar mensagem de texto.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG: 'de registros selecionados foi cancelado de Textos. A mensagem de texto não pode ser enviada para o (s) registro (s) não assinado (s). Pressione OK para continuar.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG_1: 'de registros selecionados foram cancelados de Textos. A mensagem de texto não pode ser enviada para o (s) registro (s) não assinado (s). Pressione OK para continuar.',
        'BATCH_SMS_SCHEDULE': 'A mensagem de texto foi agendada com sucesso',
        'BATCH_SMS_SCHEDULE_FAIL': 'Falha ao agendar Mensagem de Texto',
        BATCH_SEND_SMS_MSG_1: 'Fora do',
        BATCH_SEND_SMS_MSG_2: 'Você selecionou, apenas',
        BATCH_SEND_SMS_MSG_3: 'ter optado por receber a sua mensagem de texto e tem o celular não. e pertence a',
        BATCH_SEND_SMS_MSG_4: '. Gostaríamos de enviar a mensagem de texto apenas para estes',
        BATCH_SEND_SMS_MSG_5: 'Pressione OK para prosseguir.',
        SINGLE_SEND_SMS_MSG_1: 'Por favor, verifique se selecionado',
        SINGLE_SEND_SMS_MSG_2: 'Optou por receber suas mensagens de texto ou tem o celular não. ou pertence a',
        RESCHEDULE_SUCCESS: 'SMS foi remarcado com sucesso',
        AUTOMATION_SMS_ADD_MSG: 'Você salvou esta mensagem de texto com sucesso',
        WORKFLOW_STATUS_MSG: 'Você tem um ou mais fluxos de trabalho ativos que usam esse número para enviar mensagens de texto. Atualize esses fluxos de trabalho para usar um número diferente antes de remover este número. ',
        SCHEDULED_STATUS_MSG: 'Você tem um ou mais sms encenados que usam esse número. Clique em OK para cancelar todos os seus sms encenados e remover o número.',
        REMOVE_SUCCESS_MSG: 'O número foi removido com sucesso.',
        NUMBER_SAVED_SUCCESS_MSG: 'Número salvo com sucesso.',
        NO_LINKED_MOBILE_NUMBER_MSG:'O registro associado não possui número de celular. Você não pode enviar mensagem de texto.',
        NUMBER_INVALID_MSG:"O número do celular do contato está incorreto!",
        CANNOT_DELETE_UNITS_NUMBER: 'Você não tem permissão para remover este número, pois ele foi atribuído a uma Unidade.',
    },
    EVENTBRITE: {
        NO_ORGANISATION_SELECTED: 'Vui lòng chọn ít nhất một tổ chức',
        NO_MAPPING_FOUND: 'Não há mapeamento para os campos CRM Contact e Eventbrite.'
    },
    QUICKBOOKS: {
        NO_MAPPING_FOUND: 'Não há mapeamento para o campo Contas de CRM e Cliente de Livros Rápidos.'
    },
    XERO: {
        SELECT_ONE_INTEGRATION_ITEM_AND_TYPE: 'Selecione um item e um tipo de integração para integrar.',
        SELECT_ONE_INTEGRATION_ITEM: 'Selecione um item para integrar.',
        SELECT_INTEGRATION_TYPE: 'Selecione o tipo de integração.',
    },
    REPORT_SCHEDULE_DIALOG: {
        START_DATE_BLANK: 'Data de início não pode estar em branco.',
        INVALID_END_DATE: 'Data de início não pode ser maior ou igual a data de término.',
        DAY_CHECKBOX_BLANK: 'Pelo menos cheque um dia.',
        SEND_TO_BLANK: 'Pelo menos selecione um usuário.',
        SAVE_SUCCESS: 'Salvo com êxito.'
    },
    DOCUMENT_PUSH: {
        ALERT_MSG_1: 'Selecione pelo menos uma empresa para enviar documentos',
        DOCUMENTS: 'Documento (s)',
        FAIL: 'Falha ao empurrar'
    },
    OPERATIONS_REPORT: {
        NO_CUSTOM_REPORT_MESSAGE: 'Você não tem nenhum relatório personalizado.'
    },
    PINNED_NOTES: {
        CONFIRM_MESSAGE: 'Isso substituirá a nota fixa existente. Clique em OK para continuar ou em Cancelar para descartar.'
    },
    ASSIGNMENT_RULES: {
        NO_ASSIGNMENT_RULES: 'Não há regras disponíveis.',
        NO_NAME: 'Forneça o nome da regra de atribuição.',
        NAME_MAX_LENGTH: 'Nome da regra de atribuição não pode ser mais de 64 caracteres.',
        NO_CRITERIA: 'Por favor, forneça pelo menos um critério.',
        NO_USER: 'Por favor, forneça pelo menos um usuário.',
        RULE_SAVE_SUCCESS: 'Regra de atribuição salva com êxito.',
        RULE_NAME_EXISTS: 'O nome já existe!',
        ACTIVE_RULE_EXCEEDS: 'Você já tem 25 regras de atribuição ativas.',
        SELECT_UNIT_MAXIMUM_FIELD: 'Você pode selecionar apenas uma unidade',
        SELECT_A_UNIT_WORKFLOW: 'Por favor, selecione um fluxo de trabalho da unidade.',
        NO_UNIT_USER: 'forneça pelo menos uma unidade.'
    },
    SETTINGS: {
        EMPTY_NAME: 'Por favor, insira',
        DUPLICATES_SOURCE_NAME: 'O nome da fonte já existe!',
        CORPORATE_CURRENCY: 'A moeda selecionada foi atualizada com sucesso.',
        INVALID_CURRENCY_NAME: 'Por favor, selecione um nome válido da moeda corporativa',
        SYSTEM_NOTE_TYPE_EDIT_ALERT: 'Você não pode editar este tipo de nota.',
        SYSTEM_NOTE_TYPE_DELETE_ALERT: 'Você não pode excluir este tipo de nota.',
        SYSTEM_CAMPAIGN_TYPE_DELETE_ALERT: 'Você não pode excluir este tipo de campanha.',
        CORPORATE_CURRENCY_ALERT: 'Por favor, selecione uma moeda corporativa.',
        CORPORATE_CURRENCY_CONFIRM: 'A moeda selecionada será salva. Você tem certeza?',
        'CORPORATECURRENCY': 'Selecione uma moeda corporativa',
        'DUPLICATE_NOTE_TYPES': 'Este tipo de nota já existe. A criação de tipos de notas duplicados não é permitida.',
        'INCORRECT_RECORD_ID': 'O registro incorreto Id.Update falhou.',
        'RECORD_DOES_NOT_EXISTS': 'O registro não existe. A operação de exclusão falhou.',
        'RECORD_CANNOT_DELETED': 'Tipos de notas do sistema ou padrão não podem ser excluídos.',
        'DUPLICATE_CAMPAIGN_TYPES': 'Este tipo de campanha já existe. A criação de tipos de campanha duplicados não é permitida.',
        'DUPLICATE_RELATIONS': 'Nome da relação já existe!',
        RELATION_LINK_INFO_ALERT_MSG: 'Existem registros que seguem essa relação. Desvincule os respectivos registros primeiro que seguem esta relação e então você poderá deletar esta relação.'
    },
    RECYCLE_BIN: {
        SELECT_WORKFLOW: 'Por favor, selecione um fluxo de trabalho',
        SELECT_STAGE: 'Por favor, selecione um estágio',
        NOTE: 'Nota: Os registros vinculados do registro original não serão restaurados.',
        RESTORE: 'Registrar a restauração com sucesso'
    },
    UNSUBSCRIBE: {
        SELECTED_RECORD: 'Este registro será anulado de ',
        ARE_YOU_SURE: '. Você tem certeza?',
        SELECTED_BOTH_RECORD: 'Este registro será anulado de e-mails e textos. Você tem certeza?',
        'EMAILS_UNSUBSCRIBE': ' será anulado de e-mails. Você tem certeza?',
        'TEXTS_UNSUBSCRIBE': ' será anulado de textos. Você tem certeza?',
        'BOTHS_UNSUBSCRIBE': ' será anulado de e-mails e textos. Você tem certeza?',
    },
    WORKFLOWS: {
        STATUS_CHANGED: 'O status foi alterado para ',
        'SAME_STATUS': 'Record já em',
        'FAILED_TO_CHANGE_WORKFLOW_STATUS': 'Falha ao alterar o status do fluxo de trabalho.',
        ONLY_ONE_ACTIVE_WORKFLOW: 'Você não pode tornar este fluxo de trabalho inativo. Este é o único fluxo de trabalho ativo disponível. Uma tabela deve ter pelo menos um fluxo de trabalho ativo.',
        ONLY_ONE_ACTIVE_WORKFLOW_CANNOT_DELETE: 'Não é possível eliminar este fluxo de trabalho. Este é o único fluxo de trabalho ativo disponível. Uma mesa deve ter pelo menos um fluxo de trabalho ativo.',
        DELETED_WORKFLOW: 'O fluxo de trabalho não existe. Selecione Ok para escolher um novo fluxo de trabalho.',
        STATUS_BLANK_ALERT:'o status não pode ficar em branco',
        SALES_REP_BLANK_ALERT : 'Sales_rep não pode ficar em branco',
    },
    CHANGE_LOGO: {
        SELECT_IMAGE_TO_UPLOAD: 'Select an image to Change Logo',
        LOGO_IMAGE_UPLOAD_FAILURE: 'O logotipo da empresa não pôde ser salvo.',
        LOGO_IMAGE_UPLOAD: 'Logotipo da empresa salvo com sucesso.',
        VALIDATE_LOGO_IMAGE: 'Selecione um arquivo de imagem.',
        VALIDATE_LOGO_IMAGE_SIZE: 'A foto que você selecionou é muito grande para ser usada. O tamanho máximo de arquivo permitido é de 500 KB.',
        LOGO_IMAGE_ATTACHMENT_LOGO_TEXT: 'Você pode fazer o upload de um arquivo jpg, gif ou png.',
        LOGO_IMAGE_SIZE_TEXT: 'O tamanho máximo de arquivo permitido é de 500 KB.',
        WEBSITE_URL: 'Local na rede Internet',
        VALIDATE_LOGO_WEBSITE_URL: 'Gostaria de fazer o upload do logotipo sem um URL válido?'
    },
    ACCOUNTS: {
        DUPLICATE_MAIL_DOMAIN: 'Existe uma conta existente para essa empresa. Você gostaria de adicionar um contato?'
    },
    COUPONS: {
        CREATE_COUPON_NAME: 'Crie um nome interno para o seu cupom',
        CREATE_DISCOUNT_TYPE: 'Que tipo de desconto você gostaria de criar?',
        FIXED_AMOUNT: 'Quantia fixa',
        PERCENTAGE: 'Percentagem',
        PREFIX_COUPON_CODE: 'Especifique um prefixo para o código do cupom',
        CAN_CLAIM_MORE_THAN_ONCE: 'Pode ser usado mais de uma vez pela mesma conta?',
        CALIM_LIMIT_ONE: 'Apenas uma vez',
        CLAIM_LIMIT_UNLIMITED: 'Ilimitado',
        WHYEN_CLAIM_CODE: 'Data de validade do cupom',
        CLAIM_ANYTIME: 'Nunca expira',
        CLAIM_BEFORE_DATE: 'Data de validade',
        SAVE_SUCCESSFULLY: 'Cupom salvo com sucesso',
        ACTIVE_SUCCESSFULL: 'Status foi alterado para Ativo.',
        INACTIVE_SUCCESSFULL: 'O status foi alterado para Inativo.',
        DELETE_CONFIRMATION: 'Cupons serão excluídos. Você tem certeza?',
        INVALID: 'é inválido',
        DUPLICATE_COUPONS: 'O nome do cupom já existe.',
        NEGATIVE_COUPONS: 'não pode ser negativo.',
        SPECIFY_DATE: 'Por favor especifique uma data.',
        PODUCT_SERVICE: 'Produto / Serviço',
    },
    NOTIFICATION_REMINDERS: {
        SAVE_SUCCESS: 'Lembrete adicionado com sucesso',
        INVALID_DAY: 'Insira um dia (s) válido',
        DUPLICATE_REMINDER: 'Este é duplicado lembrete, por favor, mude',
        INVALID_DATE_FIELD: 'Por favor, selecione um campo de data',
        DELETE_SUCCESS: 'Lembrete excluído com sucesso',
        UPDATE_SUCCESS: 'Lembrete atualizado com êxito',
    },
    EMAIL_INTEGRATION: {
        DUPLICATE_EMAIL: 'Este endereço de e-mail já existe!',
        EMAIL_SAVE_SUCCESS: 'Endereço de e-mail salvo com sucesso',
        SEND_VERIFICATION_EMAIL_SUCCESS_1: 'Enviamos o e-mail de verificação. Por favor, verifique sua caixa de entrada e clique em ',
        SEND_VERIFICATION_EMAIL_SUCCESS_2: 'Verifique este endereço de e-mail. ',
        SEND_VERIFICATION_EMAIL_SUCCESS_3: 'Em seguida, volte e clique no ícone de atualização para ver o status do seu endereço de e-mail.',
        EMAIL_INTEGRATION_VERIFIED: 'Este endereço de e-mail já foi verificado!',
        CONFIRM_DELETE_EMAIL: 'Os endereços de e-mail selecionados serão excluídos. Tem certeza?',
        INVALID_EMAIL: 'Endereço de e-mail inválido',
        EMAIL_CANT_BLANK: 'O endereço de e-mail não pode estar em branco!',
        EDIT_EMAIL_SAVE_SUCCESS: 'E-mail salvo com sucesso',
    },
    NEWS: {
        SAVE_ERROR: 'Incapaz de salvar.',
        VALIDATE_CATEGORY: 'A categoria não pode ficar em branco.',
        VALIDATE_STATUS: 'O status não pode ficar em branco.',
        VALIDATE_TITLE: 'O título não pode ficar em branco.',
        VALIDATE_SUMMARY: 'O resumo não pode ficar em branco.',
        VALIDATE_EXTERNAL_LINK: 'Link externo não pode ficar em branco.',
        VALIDATE_DETAILS: 'Os detalhes não podem ficar em branco.',
        CATEGORY_NAME_EXISTS: 'O valor já existe na lista.',
        DUPLICATE_TITLE: "Este título já existe!",
    },
    USEFUL_LINKS: {
        CATEGORY: 'Categoria',
        LINK: 'Link',
        TITLE: 'Título',
        VALID_CATEGORY: 'Por favor, selecione uma categoria válida',
        DUPLICATE_CATEGORY: 'Esta é a categoria duplicada, por favor mude',
        DUPLICATE_TITLE: 'Este é um título duplicado na mesma categoria.',
        CONFIRM_DELETE_CATEGORY: 'Categoria será excluída. Tem certeza?',
        CONFIRM_DELETE_LINK: 'Link será excluído. Tem certeza?',
        VALID_URL: 'Por favor, insira um URL válido.',
        EXISTING_LINKS_CATGORY: 'Você tem links existentes nesta categoria. ',
        PLEASE_MOVE_LINKS: 'Por favor, mova os links para',
        CLICK_TO_CONFIRM: ' Clique em OK para confirmar.',
    },
    CASE: {
        CASE_CLOSED_SUCCESS: 'Caso encerrado com sucesso'
    },
    ORTHOTIC_REPORT: {
        VALIDATE_EMPTY_PRODUCT: 'Por favor selecione um produto.'
    },
    USER_REPORT: {
        REPORT_DELETE_RECORD: 'O relatório será excluído. Você tem certeza?',
        REPORT_DELETE: 'Relatório excluído com sucesso',
        INVALID_START_END_DATE: 'A data de início não pode ser maior que a data de término.'
    },
    AUTO_FILL_VALUES: {
        CHNAGE_PARENT_OBJECT: 'Você realmente quer mudar o objeto pai?',
        CHANGE_CHILD_OBJECT: 'Você realmente quer mudar o objeto infantil?',
        SELECT_PARENT_OBJECT: 'Selecione um objeto pai.',
        SELECT_CHILD_OBJECT: 'Selecione um objeto de criança.',
        NO_MAPPING_FIELDS: 'Não há campos de mapeamento disponíveis entre ',
        NO_MAPPING_FIELDS_AND: 'E',
        CHILD_FIELD_SIZESHOULD_GREATER: 'O tamanho do campo da criança deve ser maior do que o tamanho do campo do pai.',
        MAPPING_FROM: 'Mapeamento de ',
        MAPPING_TO: ' Para ',
        NO_AVAILBLE_FIELDS_FOR_MAPPING: '. Não há campo comum disponível para mapeamento padrão.',
        NO_AVAILABLE_PARENT_CHILD_MAPPING: 'Não há mapeamento de campo pai-filho disponível.',
        FIELD_MAPPING_DONE_SUCCESS: 'Mapeamento de campo feito com sucesso.',
        MAP_FIELDS_TO_AUTOFILL: 'Mapeie os campos para preenchimento automático ',
        MAP_FIELDS_TO_AUTOFILL_FROM: ' De ',
        SELECT_PARENT_FIELD: 'Selecione um campo de pais.',
        SELECT_CHILD_FIELD: 'Selecione um campo infantil.',
        MAPPING_NOT_POSSIBLE: ' não é possível.'
    },
    LANDING_PAGE: {
        INVALID_NAME: 'Digite o nome da página de destino.',
        INVALID_URL_WARNING: 'Digite o nome do URL.',
        SAVE: 'Página de destino salva com sucesso.',
        UPDATE: 'Página de destino atualizada com sucesso.',
        INVALID_FORM_BLOCK_EDIT_MSG: 'O bloco ativo atual não possui campos de formulário para editar, selecione o bloco de campos de formulário para editar.',
        DUPLICATE_NAME: 'A página de destino já existe com esse nome. Digite um nome diferente e tente novamente.',
        DUPLICATE_URL: 'A página de destino já existe com este URL. Digite um nome diferente e tente novamente.',
        INPUT_DATA_LOSS_MSG: 'Todos os dados inseridos serão apagados. Você tem certeza?',
        EMAIL_REQUIRED_MSG: 'E-mail é obrigatório.',
        ATLEAST_ONE_BLOCK_ADD_MSG: 'Adicione pelo menos um bloco de design para salvar esta página.',
        PREVIEW_ALERT_MSG: 'Salve antes de visualizar a página de destino.',
        OPEN_URL_ALERT_MSG: 'Salve as alterações antes de executar a ação.',
        COPY_TO_CLIPBOARD: 'Link da página de destino copiado para a área de transferência.',
        FORM_ADDED_MSG: 'Formulário adicionado com sucesso.',
        FORM_UPDATED_MSG: 'Formulário atualizado com sucesso.',
        INVALID_URL: 'URL inválida.',
        LABEL_FIELD_CANNOT_BLANK: 'o campo da etiqueta não pode ficar em branco.',
        FIELD_IS_ALREADY_ADDED: 'O campo já foi adicionado.',
        MINIMUM_ONE_FIELD: 'É obrigatório no mínimo um campo.',
        NO_MORE_FIELD_AVAILABEL: 'Não há mais campos disponíveis.'
    },
    DOWNLOAD_LEADS: {
        SELECT_CRITERIA: 'Por favor, selecione pelo menos um critério.',
        SELECT_WORKFLOW: 'Por favor, selecione um fluxo de trabalho.',
        SELECT_GROUP: 'Por favor, selecione um grupo.',
        SELECT_STATUS: 'Por favor, selecione o estado.',
        SELECT_SALESREP: 'Por favor, selecione um vendedor.',
        LEAD_EXIST_MSG_1: 'Já tem estas pistas na sua conta - ',
        LEAD_EXIST_MSG_2: '. Clique em Ok para parar o download e remova estes cabos da sua seleção.'
    },
    SCHEDULER: {
        BLANK_APPOINTMENT_NAME: 'Por favor, insira o nome da nomeação.',
        BLANK_LOCATION: 'Por favor, insira o local.',
        SAVE_SUCCESS: 'A configuração do programador salvou com sucesso.',
        SAVE_FAIL: 'A configuração do programador não pôde ser salva.',
        NO_SCHEDULER: 'Por favor, crie um agendador de marcação antes de utilizar o link do programador.',
        VALIDATE_TIME: 'A hora de início não pode ser maior ou igual ao tempo de fim.',
        NO_START_TIME: 'Por favor, selecione a hora de início.',
        NO_END_TIME: 'Por favor, selecione o tempo final.',
        SCHEDULER_LINK_COPIED_SUCCESS: 'Ligação do programador copiada para a área de prancheta.',
        BLANK_SUBMIT_BUTTON: 'A etiqueta do botão não pode ficar em branco.',
        CALENDAR_SYNC_MSG: 'O seu calendário crm não está ligado ao seu Calendae GSuite ou Office 365.',
        CALENDAR_SYNC_LINK: 'Clique aqui para configurar.',
        SCHEDULER_UNSAVE_msg: 'Quer deixar este formulário? As alterações efetuadas não serão guardadas.',
    },
    RELATION_REPORT: {
        RANGE_FIELD_BLANK: 'Por favor, selecione um campo de data.',
        ROW_FIELD_BLANK: 'Por favor, selecione um campo de linha.',
        COL_FIELD_BLANK: 'Por favor, selecione um campo de coluna.',
        REPORT_NAME_BLANK: 'Digite o nome do relatório.',
        REPORT_SAVE_SUCCESS: 'Relatório salvo com sucesso.',
        DUE_BY_BLANK: 'Selecione um período.',
        NO_ROW_FIELD_VALUE: 'Selecione um valor para o rótulo da linha.',
        NO_COLUMN_VALUE: 'Por favor, selecione um valor para o cabeçalho da coluna.',
        SAME_ROW_COLUMN_FIELD: 'Os campos da linha e da coluna não podem ser iguais.',
        INVALID_END_DATE: 'A data de início não pode ser maior que a data de término.',
        'Report Name already exists': 'O nome do relatório já existe.',
        VALID_REPORT_NAME: 'Insira um nome de relatório válido',
    },
    PAYMENTS: {
        NOT_ADDED_QB: 'A Cotação/Fatura ainda não foi adicionada ao Quickbook!',
        NO_LINKED_AVAILABLE: 'Não existe nenhuma Cotação/Fatura ligada disponível!',
        PAYMENT_VALUE_ALERT_MSG: 'O valor do pagamento não pode ser zero.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'Já tem uma Cotação/Fatura aberta, por favor feche-a para abrir uma nova Cotação/Fatura.',
        AMOUNT_EXCEED_BALANCE_DUE: 'O pagamento que está a introduzir excede o saldo devido a esta fatura. Clique em OK para continuar o pagamento. Clique em Cancelar para alterar o valor do pagamento.'

    },
    PRODUCTS: {
        DUPLICATE_PRODUCT: 'O nome do produto já existe.',
        ADDED_TO_QUICKBOOK: 'Adicionado com sucesso aos QuickBooks.',
        PULLED_FROM_QUICKBOOKS: 'Conseguiu tirar dos Quickbooks com sucesso.',
        PRODUCT_PRICE_VALUE_ALERT_MSG: 'O valor do preço não pode ser zero.',
        PRODUCT_NAME_COULDNOT_CONTAIN_COLON_MSG: 'não pode conter dois pontos (:).',
        NEGATIVE_PRICE: 'O preço não pode ser negativo para o tipo de inventário.'
    },
    COMPANY_PROFILE: {
        BLANK_BUSINESS_NAME: 'Por favor, insira o nome comercial.',
        BLANK_BUSINESS_ADDRESS: 'Por favor, insira o endereço de negócio.',
        BLANK_BUSINESS_PHONE: 'Por favor, insira o telefone comercial.'
    },
    EXPORT_SETTINGS: {
        CONSIDERATION: 'Consideração:',
        CONSIDERATION_MSG1: 'Suportamos arquivos no formato EXCEL e CSV.',
        CONSIDERATION_MSG2: 'Uma célula no Excel pode conter no máximo 32.767 caracteres; portanto, no arquivo exportado do Excel, mostramos no máximo 32.767 caracteres para uma célula e excluímos o restante dos caracteres.',
        NO_RECORDS_FOUND: 'Sem registros encontrados!',
        FILE_NAME_IS_EMPTY: 'O nome do arquivo está vazio.',
        PLEASE_SELECT_THE_FILE_TYPE: 'Selecione o tipo de arquivo.',
        PLEASE_SELECT_THE_PARENT_OBJECT: 'Selecione o objeto pai.',
        PLEASE_SELECT_THE_QUERY_NAME: 'Selecione o nome da consulta.',
        PLEASE_SELECT_THE_PARENT_FIELDS: 'Selecione os campos pai.'
    },
    DATA_BASE: {
        FAILURE_MSG: 'Falha ao salvar.',
        SUCCESS_MSG: 'Sua configuração foi salva com sucesso. Saia e faça login novamente para ver as alterações.',
        VALIDATION_MSG: 'Selecione pelo menos um objeto filho para ',
        VALIDATION_MSG1: "Você atingiu o limite máximo de 15 objetos selecionados.",
        SUCCESS_MSG1: 'Sua configuração foi salva com sucesso.',
    },
    UNITS: {
        BLANK_FIRST_NAME: 'Por favor, insira o primeiro nome.',
        BLANK_LAST_NAME: 'Por favor, insira o sobrenome.',
        BLANK_PASSWORD: 'Por favor, digite a senha.',
        UNMATCH_RETYPE_PASSWORD: 'Digite novamente a senha não corresponde à senha.',
        BLANK_RETYPE_PASSWORD: 'Por favor, digite a senha novamente.',
        BLANK_EMAIL: 'Por favor, insira o email.',
        BLANK_PHONE: 'Por favor, insira o telefone.',
        BLANK_COMPANY: 'Por favor, insira a empresa.',
        UNIT_SAVED: 'Salvo com sucesso.',
        UNIT_NOT_SAVED: 'Unidade não pôde ser salva.',
        DUPLICATE_EMAIL: 'Você já tem registro (s) com o mesmo id de email.',
        DUPLICATE_COMPANY: 'O nome da empresa já existe.',
        SUBSCRIPTION_NOT_VALID: 'A assinatura não é válida para a unidade selecionada. Entre em contato com o suporte do Soffront para obter mais detalhes.',
        NO_UNIT_TASKS: 'Não há tarefas disponíveis para o proprietário da unidade.',
        NO_ACCOUNT_TASKS: 'Não há tarefa disponível para a conta.',
        BLANK_FAMILY_INDUSTRY: 'Selecione uma indústria familiar',
        NO_FAMILY_INDUSTRY: 'Você precisaria de um modelo básico antes de adicionar novas unidades. Entre em contato com o suporte da BrandWide para configurar um modelo básico.',
        BLANK_PLAN_NAME: 'Selecione um nome de plano.',
        NO_AVAILABLE_PLANS: 'Não há planos disponíveis que possam ser inscritos nesta unidade.',
        NO_BRANCH_WORKFLOW: 'Não há fluxo de trabalho de filial disponível.',
        PLEASE_SELECT_TYPE: 'Selecione um tipo para continuar.',
        NO_BASE_LOGIN: 'A base não existe ou não está configurada! Corrija o problema e tente novamente.',
    },
    SHARE: {
        SHARE_SUCCESS_MSG_1: 'As informações de compartilhamento são atualizadas com sucesso.',
        SHARE_SUCCESS_MSG_SL: 'As informações de compartilhamento são atualizadas com êxito para os níveis de segurança selecionados, se houver algum.',
        SHARE_SUCCESS_MSG_2: 'A notificação por e-mail será enviada para as Unidades recém-selecionadas, se houver alguma.',
        SHARE_SUCCESS_MSG_3: 'A notificação por e-mail será enviada para a unidade recém-selecionada, se houver alguma.',
        SHARE_SUCCESS_MSG_4: 'Tem certeza?',
        SHARE_SUCCESS_MSG_5: 'será compartilhado com a Unidade selecionada.',
        SHARE_SUCCESS_MSG_6: 'serão compartilhadas com as Unidades selecionadas.',
        SHARE_FAILED_MSG: 'Não conseguiu compartilhar.',
        SHARE_SELECT_CORPORATE_DOCUMENT: 'Selecione pelo menos um usuário corporativo para compartilhar documentos.',
        SHARE_SELECT_CORPORATE_IMAGE: 'Selecione pelo menos um usuário corporativo para compartilhar a imagem.',
        SHARE_SELECT_UNIT_DOCUMENT: 'Selecione pelo menos uma Unidade para compartilhar documentos.',
        SHARE_SELECT_UNIT_IMAGE: 'Selecione pelo menos uma unidade para compartilhar imagem.',
        NO_CONNECTED_CLIENT: 'Não há Clientes Conectados disponíveis para compartilhar documentos.',
    },
    SECURITY_LEVEL: {
        ERROR_MSG_1: 'Ops! Algo deu errado. Por favor tente mais tarde.',
        ERROR_MSG_2: 'A descrição não pode ficar em branco.',
        SUCCESS_MSG_1: 'Sua configuração foi salva com sucesso.',
    },
    ROYALTY_REPORT: {
        BLANK_ROYALTY_PERCENT: 'Por favor, insira a realeza por cento.',
        REPORT_SAVE_SUCCESS: 'Relatório salvo com sucesso.',
        SETUP_SAVE_SUCCESS: 'Configuração da realeza guardada com sucesso.'
    },
    MENU: {
        ATLEAST_ONE_MODULE_ACTIVE: 'Desculpe, pelo menos um módulo deve estar ativo.',
        ATLEAST_ONE_MODULE_ITEM_ACTIVE: 'Desculpe, pelo menos um item do módulo deve estar ativo.',
        MODULE_LABEl_CHAR_LIMIT_MSG: 'O nome não pode ter mais de 32 caracteres.',
        DUPLICATE_NAME: 'nome do módulo já existe.'
    },
    SALES_PURCHASE_ORDER: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD: 'Você tem uma guia de pedido de compra aberta. Feche essa guia antes de adicionar um novo pedido de compra.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD: 'Você tem uma guia de pedido de venda aberta. Feche essa guia antes de adicionar um novo pedido de venda.',
        SAVE_PURCHASE_SUCCESS: 'Pedido de compra salvo com sucesso.',
        SAVE_SALES_SUCCESS: 'Pedido de venda salvo com sucesso.',
        SALES_REP_NAME_CANT_BLANK: 'O nome do representante de vendas não pode ser apagado.',
        SELECT_ONE_ITEM: 'Selecione uma informação de item.',
        DISCOUNT_AMOUNT_CANT_GREATER: "O valor do desconto não pode ser maior que o subtotal.",
        SPECIFY_TRANSC_AMOUNT: 'Você deve especificar um valor de transação igual ou superior a 0.',
        SETUP_COMPANY_PROFILE: 'Configure o perfil da sua empresa antes de adicionar cotações. Entre em contato com o administrador do CRM para configurar o perfil.'

    },
    JOBS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD: 'Você tem uma guia de empregos aberta. Feche essa guia antes de adicionar um novo trabalho.',
        FIELDS_CANNOT_BLANK: ' o campo não pode ficar em branco.',
        PRODUCT_NAME_NOT_CONTAIN: 'O nome do produto não pode conter dois pontos (:).',
        JOB_SAVE_SUCCESS: 'Trabalho salvo com sucesso.',
        PRODUCT : 'produtos',
        ALREADY_EXIST: 'já existe.',
    },
   POWER_CALLING :{
        YOU_DONT_HAVE_CALL_SCRIPT: "Você não tem nenhum script de chamada.",
        ALERT_MSG_1: 'Fora de ',
        ALERT_MSG_2: ' você selecionou, apenas ',
        ALERT_MSG_3: ' têm números locais válidos. Você será capaz de ligar para aquelese ',
        ALERT_MSG_4: 'Falei com ',
        ALERT_MSG_5: "Não tem mais",
        ALERT_MSG_6: "na fila para fazer uma chamada.",
        ALERT_MSG_7: 'Você não tem nenhuma conexão ativa.',
        ALERT_MSG_8: 'você selecionou, nenhum deles tem número de celular ou telefone.',
        ALERT_MSG_9: 'Você já tem um Power Dialer aberto, feche para abrir um novo Power Dialer.',
        ALERT_MSG_10 :'Para fechar a guia, clique no ícone fechar na parte superior da guia.'
    },
    LEAD_SCORE_SETUP: {
        ALREDY_DEFINED_SCORE: 'Você já definiu uma pontuação para esta ação.',
        SAVE_SUCCESSFULLY: 'A pontuação foi salva com sucesso.',
        CAN_NOT_UPDATE: 'As configurações de pontuação não podem ser atualizadas. Modifique as configurações de pontuação corretamente. ',
        SELECT_ONE_ACTION: 'Selecione pelo menos uma ação',
        SELECT_ACTION: 'Selecione uma ação',
        SELECT_OBJECT: 'Selecione um objeto',
        SELECT_WORKFLOW: 'Selecione um fluxo de trabalho',
        CONVERT_FROM_CANT_BLANK: 'Convertido de não pode ficar em branco',
        CONVERT_TO_CANT_BLANK: 'Convertido para não pode ficar em branco',
        CHOOSE_DIFF_STATUS: 'Você não pode converter o mesmo status. Escolha um status diferente. ',
        SELECT_GROUP: 'Selecione um grupo',
        SELECT_FIELD: 'Selecione um campo',
        PROVIDE_VALUE_TO_UPDATE: 'Forneça um valor que deseja atualizar',
        ENTER_SCORE: 'Por favor, insira uma pontuação entre 1-99',
        EXIST_IN_LIST: 'Valor já existe na lista',
        SCORE_DELETED: 'Esta pontuação será deletada. Tem certeza?',
        REMOVE_MSG: 'remover com sucesso',
        UPDATE_MSG: 'atualização com sucesso'

    },
    DUPLICATE_RULE: {
        ERROR_MSG_DEFAULT: 'Você não pode desligar regra padrão.',
        ERROR_MSG_Active: 'Você não pode Deactive uma regra padrão.',
        ERROR_MSG_FOR_FIELDS: 'Selecione todos os campos da um determinado bloco para salvar a regra.',
        ERROR_MSG_1: 'Algo deu errado. Por favor, tente novamente mais tarde.',
        SUCCESS_MSG_1: 'Sua regra duplicado (s) foi salva com sucesso.',
        ERROR_MSG_SAME_FIELD: ' já está seleccionado. Selecione outro campo.',
    },
    REQUIRED_FORM: {
        FIELD_FILL_MESG:'Esses campos precisam ser preenchidos antes deste ',
        CAN_BE_SUBMITTED:' pode ser submetido.'
    },
    GREENLIGHT: {
        COMPANY_NAME_BLANK: 'Insira o nome de uma empresa.',
        COMPANY_NAME_REQUIRED: 'O nome da empresa não pode ter mais de 30 caracteres.',
        ADDRESS1_REQUIRED: 'O endereço 1 é obrigatório.',
        ADDRESS1_ADDRESS2_LIMIT: 'O endereço 1 + o endereço 2 combinados não podem ter mais de 30 caracteres.',
        PHONE_NUMBER_REQUIRED: 'Por favor insira um número de telefone válido.',
        PHONE_NUMBER_LIMIT: 'Os números de telefone não podem ter mais de 10 dígitos.',
        NET_ANNUAL_LIMIT: 'Net Anual não pode ficar em branco e não pode ser zero.',
        NET_ANNUAL_INVALID: 'Insira um valor válido em Net Anual.'
    },
    AUDIT_QUESTIONS: {
        QUESTION_REQUIRED: 'Pergunta é necessária',
        ENTER_VALID_SCORE: "Por favor insira uma pontuação válida",
        ENTER_VALID_CHOICES: "Por favor, insira escolhas válidas",
        SUCCESS: 'Sucesso',
        ERROR: 'Erro',
        NO_QUESTIONS: "Nenhuma pergunta encontrada",
        THANK_YOU: "Obrigada!",
        SOMETHING_WRONG: "Algo deu errado ao enviar seu feedback, tente novamente!",
        FEATURE_NOT_AVAILABLE: "Esse recurso não está disponível.",
        RECEIVED_FEEDBACK_1: "Bom trabalho! Você salvou com sucesso as respostas para o",
        RECEIVED_FEEDBACK_2:"categoria.",
        RECEIVED_FEEDBACK_3:"Lembre-se, você sempre pode atualizar suas respostas para esta categoria antes do envio final.",
        MAIL_SENT_MESSAGE:'Mail enviado.',
        SUBMIT_AUDIT_1:'Existem perguntas obrigatórias não respondidas nas seguintes categorias:',
        SUBMIT_AUDIT_2:'Responda às perguntas obrigatórias e, em seguida, envie a auditoria.',
        SUBMIT_AUDIT_3:'Você enviou sua auditoria com sucesso!',
        SUBMIT_AUDIT_4:'Você pode visualizar as respostas de auditoria enviadas e suas pontuações no CRM em HUB > Auditorias.',
        SUBMIT_AUDIT_5:'Você receberá uma notificação por e-mail se o Franqueador fornecer algum feedback sobre sua auditoria.',
        SUBMIT_AUDIT_6:'Você também pode ver o feedback dado pelo Franqueador e tomar ações corretivas em HUB > Auditorias.',
        SAVE_ALERT_1:'Atenção! Você está prestes a deixar isso ',
        SAVE_ALERT_2:'categoria sem salvar as alterações.',
        SAVE_ALERT_3:'Clique em OK para continuar após salvar ou feche este alerta para permanecer aqui e continuar com esta categoria.',
        REQUEST_CORRECTIVE_SUCCESS:'Obrigado! Seu feedback foi enviado por e-mail com sucesso.',
        SUBMIT_CORRECTIVE_ACTION:'Obrigado! Suas informações de ação corretiva foram enviadas por e-mail com sucesso.',
        EMPTY_REQUEST_CORRECTIVE_MESSAGE:'Você não adicionou seu feedback para nenhuma das perguntas.'+'\n'+'Adicione o feedback primeiro e depois clique em “Solicitar ações corretivas”',
        EMPTY_SUBMIT_CORRECTIVE_MESSAGE:'Você não adicionou suas ações corretivas em relação a nenhum feedback.' + '\n' + 'Adicione a ação corretiva primeiro e depois clique em “Enviar ações corretivas”.',
        EMPTY_AUDIT_BY_NAME_AND_EMAIL:'Os campos O seu nome e o Seu endereço de e-mail estão em branco. Introduza valores nesses campos.',
        EMPTY_AUDIT_BY_NAME:'O seu campo Nome está em branco. Introduza valores nesses campos.',
        EMPTY_AUDIT_BY_EMAIL:'O campo do seu endereço de e-mail está em branco. Introduza valores nesses campos.',
        AUDIT_TYPE_SELECT_VALIDATION:'Selecione um tipo de auditoria para gerar o link.',
        INVALID_AUDIT_EMAIL:'Por favor, introduza um e-mail válido.',
        INVALID_AUDIT_NAME:'Introduza um nome válido.',
    },
    AUDIT_ANSWERS:{
        NO_RECORD_FOUND:'Nenhuma resposta com perguntas corretivas encontradas',
    },
    AUDIT_QUESTION_TYPES:{
        MAX_EXCEED:'Massimo superato',
    },
    COMPLIANCE: {
        SINGLE_RECORD_EDIT: 'Por favor, selecione 1 registro para editar.',
    },
    SALES_AND_REFUND: {
        ALL_OPTIONS_REQUIRED: 'Selecione todos os filtros disponíveis e tente novamente.',
    },
    INTAKE_FORM : {
        RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM:'You have an open Intake form tab. Please close that tab before opening or adding a new Intake form.',
        INTAKE_FORM_SUCCESSFULLY_SAVE: 'Saved Successfully.',
        THANK_YOU: "Thank You!",
    },
    EXCEPTION: {
        EXCEPTION_SAVE_SUCESS: 'Saved Successfully.',
    },
    PHONE_NUMBERS: {
        USE_FOR_NOTIFICATION_SAVE: 'Do número atualizado para notificações.',
        USE_NOTIFICATION: 'Este número alugado será usado para notificação. Por favor clique em OK para confirmar.',
        REMOVE_NOTIFICATION: 'Este número alugado será removido para notificação. Por favor clique em OK para confirmar.',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        SAVE_SUCCESS: 'Sua configuração foi salva com sucesso.',
        SAVE_FAIL: 'Sua configuração não foi salva.',
        NO_PROVIDER: 'Nenhum provedor foi selecionado para o recurso Telefone e Texto ainda.\n Selecione um provedor primeiro para usar os recursos Telefone e Texto.',
        NO_TWILIO_NUMBERS_FOUND: 'Não há número de telefone disponível na sua conta Twilio conectada.\n Adicione o número primeiro na sua conta Twilio e depois execute a operação de importação.',
        NONE_SELECT_CONFIRM: 'Isso desativará as opções de telefone e texto no CRM em todos os lugares.\n Se você tiver algum fluxo de trabalho ou campanha de automação em que o texto já esteja configurado, altere-os adequadamente, pois não funcionará mais.'
    }
}
export default message