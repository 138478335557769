
import React, { useState, useEffect }  from 'react';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import ShowCircularProgress from '../components/circularProgress';
import { Button,Icon,Step, Stepper, StepLabel, StepButton } from '@mui/material';
import SchedulerInfo from '../components/schedulerInfo';
import SchedulerAvailability from '../components/schedulerAvailability';
import SchedulerIntakeForm from '../components/schedulerIntakeForm';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { saveSchedulingSetup, getSchedulerLinkInfo, getSchedulerDetails, setSchedulerDetails, updateSchedulerLinkDetails, deleteScheduler } from '../../../services/actions/schedulerActions';
import { isValidParam, isInvalidParam, getObjectParam, getArrayParam, getIntParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import * as sfDialogs from '../components/sfDialogs';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getSchedulerWorkflowList } from '../../../services/actions/schedulerActions';
import { getAurinkoConfigDetails,setNylasConfigDetails } from '../../../services/actions/UserListAction';
import {sortArrayObjectByProperty } from '../../../services/helper/utils';
import { useDispatch, useSelector } from "react-redux";



    function Scheduler(object) {

        const dispatch = useDispatch();

        const [tempStepsArr, setTempStepsArr] = useState(['Appointment Info', 'My Availability', 'Intake Form']);
        const [steps, setSteps] = useState(null);
        const [stepIndex, setStepIndex] = useState(0);
        const [isSchedulerMounted, setIsSchedulerMounted] = useState(false);
        const [finished, setFinished] = useState(false);
        const [scheduleInfo, setScheduleInfo] = useState({});
        const [availabilityInfo, setAvailabilityInfo] = useState({});
        const [intakeFormInfo, setIntakeFormInfo] = useState({});
        const [isSavedClicked, setIsSavedClicked] = useState(false);
        const [linkInfo, setLinkInfo] = useState([]);
        const [isStepperMounted, setIsStepperMounted] = useState(false);
        const [schedulerId, setSchedulerId] = useState(0);
        const [isSaved, setIsSaved] = useState(false);
        const [errorFlag, setErrorFlag] = useState(false);
        const [copyTitle, setCopyTitle] = useState(getLocalizedStrings().label.SCHEDULER.COPY_TO_CLIPBOARD);
        const [isIntegration, setIsIntegration] = useState(false);
        const [schedulerLinks, setSchedulerLinks] = useState([]);
        const [isNylasSetup, setIsNylasSetup] = useState(false);
        const [isAddOpen, setIsAddOpen] = useState(false);
        const [isEdit, setIsEdit] = useState(false);
        const [appoType, setAppoType] = useState([]);
        const [scrollUp, setScrollUp] = useState(false);
        const [editScrollUp, setEditScrollUp] = useState(false);

   let app= useSelector((state) => state.app);
   let schedulerDetails= useSelector((state) => state.schedulerDetails);
   let userlist= useSelector((state) => state.userlist);
   let calendar= useSelector((state) => state.calendar);
  
        useEffect(() => {
            dispatch(setHeader(object, getLocalizedStrings().label.SCHEDULER.APPOINTMENT_SCHEDULER, null, false, true));
            callSchedulerLinkInfo();

       getNylasConnection();
         getAppointmentTypes();
      
        getWorkflowListByObject(constants.ACCOUNTS_OBJECT);
    }, []);

    useEffect(() => {
        let AvailabilityInfo = availabilityInfo
        let scheduleObjDetails = getObjectParam(schedulerDetails);
        if (scheduleObjDetails.hasOwnProperty('data')) {
            let scheduleObj = scheduleObjDetails.data;
            if (scheduleObj.hasOwnProperty('availabilityInfo')) {
                AvailabilityInfo.isNylasSetup = schedulerDetails.data.availabilityInfo.isNylasSetup;
                setAvailabilityInfo( AvailabilityInfo);
                setIsIntegration(!isIntegration);
            }
        }
    }, []);

    useEffect(()=>{
        if(schedulerLinks !== null && schedulerLinks.length > 0){
            setIsSaved(true);
        }
    },[])

    const getWorkflowListByObject = (object) => {
        let url = endPoints.WORKFLOWS.LIST_GET + "/" + object;
        let workflowList = getObjectParam(schedulerDetails.workflowList);
        let records = [];
        try {
            if (workflowList.hasOwnProperty(object)) {
                let records = workflowList[object];
            }
            if (records.length === 0) {
                let promise = Promise.resolve(HTTPClient.get(url, null));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let records = getArrayParam(response);
                        if (records.length > 0) {
                            records = records.filter(e => (e.name != 'Onboarding' && e.name != 'Franchisee'));
                            let workflowListObj = {};
                            workflowListObj[object] = records;
                            dispatch(getSchedulerWorkflowList(workflowListObj));
                        }
                    }
                });
            }
        } catch (error) {
            console.log("Error in 'scheduler.js -> getWorkflowListByObject()':" + error)
        }
    }

    const getNylasConnection = () => {
        try {
            let isNylasSetup = false;
            let data = {};
            if (userlist.nylasConfigDetails !== null &&
                userlist.nylasConfigDetails.ConfigDetails.length > 0
               ) {
                if (userlist.nylasConfigDetails.isNylasSetup) {
                    isNylasSetup = true;
                } else {
                    isNylasSetup = false;
                }
                let AvailabilityInfo = availabilityInfo;
                AvailabilityInfo.isNylasSetup = isNylasSetup;
                setAvailabilityInfo(AvailabilityInfo);
                setIsNylasSetup(isNylasSetup);
            } else {
                let param = { onload: 'onload' };
                let promise = getAurinkoConfigDetails(param);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let list = response;
                            if (list !== null && list.length > 0) {
                                data.ConfigDetails = list;
                                data.isNylasSetup = true;
                                isNylasSetup = true;

                            } else {
                                data.ConfigDetails = [{}];
                                data.isNylasSetup = false;
                            }
                        } else {
                            data.ConfigDetails = [{}];
                            data.isNylasSetup = false;
                        }
                        let AvailabilityInfo = availabilityInfo;
                        AvailabilityInfo.isNylasSetup = isNylasSetup;
                        setAvailabilityInfo( AvailabilityInfo);
                        setIsNylasSetup(isNylasSetup);
                    });
                }
                dispatch(setNylasConfigDetails(data));

            }

        } catch (error) {
            console.error("Error in 'scheduler.js -> getNylasConnection()':" + error);
        }
    }
    const getAppointmentTypes =()=>{
        let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
        if (!calendarInfoFromRedux.hasOwnProperty('appTypes') &&  appoType.length === 0) {
            let param = {};
            param.object = constants.APPOINTMENTS;
            param.name = 'event_type';
            param.list_type = 'external';

            let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.APPOINTMENT_TYPE_LIST, param));

            promise.then((response) => {
                if (isValidParam(response)) {
                    let type = getArrayParam(response.record);
                    let appTypes = type.map((f, i) => {
                        let typeObj = {};
                        typeObj.value = f.value;
                        typeObj.label = f.value;
                        typeObj.color = f.color;
                        return typeObj;
                    })
                    sortArrayObjectByProperty(appTypes, 'label');
                    setAppoType([...appTypes]);

                calendar.calendarInfo.appTypes = appTypes;
                }
            });
    }else{
        if(!calendarInfoFromRedux.hasOwnProperty('appTypes')&&  appoType.length >0){
           
        }else{
            setAppoType([...calendarInfoFromRedux.appTypes]);
        }
      

    }
}
    const callSchedulerDetails = (schedulerId) => {
        let params = {
            id: schedulerId,
        }
        let promise = Promise.resolve(getSchedulerDetails(params));

        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {

                    if (response.hasOwnProperty('scheduleInfo')) {
                        setScheduleInfo(response.scheduleInfo);
                            if(!scheduleInfo.hasOwnProperty("appointmentTypes")){
                                let tempdata = response.scheduleInfo ; 
                                tempdata["appointmentTypes"] = appoType;
                                setScheduleInfo(tempdata);
                            }
                        }

                    if (response.hasOwnProperty('availabilityInfo')) {
                        let isNylasSetup = false;
                        if (availabilityInfo.hasOwnProperty('isNylasSetup')) {
                            isNylasSetup = availabilityInfo.isNylasSetup;
                        }
                        response.availabilityInfo.isNylasSetup = isNylasSetup;

                       setAvailabilityInfo(response.availabilityInfo);
                    }

                    if (response.hasOwnProperty('intakeFormInfo')) {
                        setIntakeFormInfo(response.intakeFormInfo);
                    }

                    if (response.hasOwnProperty('schedulerId')) {
                        setSchedulerId(response.schedulerId);
                        setIsSaved(true) ;
                    }

                    if ((response.hasOwnProperty('scheduleInfo')) && response.hasOwnProperty('availabilityInfo') &&
                        response.hasOwnProperty('intakeFormInfo') && response.hasOwnProperty('schedulerId')) {
                            dispatch(setSchedulerDetails(response));
                    }

                    setIsSchedulerMounted( true);
                    setIsSaved(false);
                    setStepIndex( 0);
                    setIsEdit(true);
                    setIsStepperMounted(false);

                }
            });
        }
    }

    const callSchedulerLinkInfo = () => {
        let promise = Promise.resolve(getSchedulerLinkInfo());

        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let linkArr = [];
                    if (response.length > 0) {
                        response.map((scheduler, i) => {
                            let tempObj = {};
                                tempObj.appt_name = scheduler.apptName;
                                tempObj.location = scheduler.location;
                                tempObj.duration = scheduler.duration;
                                tempObj.duration_type = scheduler.durationType;
                                tempObj.min_advance_notice = scheduler.minAdvanceNotice;
                                tempObj.min_advance_notice_type = scheduler.minAdvanceNoticeType;
                                tempObj.description = scheduler.description;
                                tempObj.slug = scheduler.slug;
                                tempObj.id = scheduler.id;
                                tempObj.appointmentType = scheduler.eventType;
                                linkArr.push(tempObj);
                        })
                        setSchedulerLinks(linkArr);
                        setIsSchedulerMounted(true);
                        setIsSaved(true);
                        dispatch(updateSchedulerLinkDetails(linkArr));
                    } else {
                        addNew();
                    }
                } else {
                    addNew();
                }
            });
        }
    }

    const generateStepper = (newindex)=> {
        let schedulerSteps = null;
        let stepsArr = tempStepsArr;
        let themeColor = app.me.background;
        let stepsMap = {};
        stepsMap['Appointment Info'] = getLocalizedStrings().label.SCHEDULER.APPOINTMENT_INFO;
        stepsMap['My Availability'] = getLocalizedStrings().label.SCHEDULER.MY_AVAILABILITY;
        stepsMap['Intake Form'] = getLocalizedStrings().label.SCHEDULER.INTAKE_FORM;

        let tempstepIndex = 0;
        schedulerSteps = stepsArr.map((stage, index) => {
            stage = stepsMap[stage];

            if (index === newindex) {
                tempstepIndex = index;
                return (
                    <Step>
                        <StepLabel>
                        {<div id={index} style={{ color: themeColor, fontWeight: 'bold' }} title={stage}>{stage}</div>}
                        </StepLabel>
                    </Step>
                );
            } else {
                if (index > tempstepIndex && tempstepIndex > 0) {
                    return (
                        <Step>
                            <StepButton value={stage}>
                                {<div id={index} style={{ color: '#000' }} title={stage}>{stage}</div>}
                            </StepButton>
                        </Step>
                    );
                } else {
                    return (
                        <Step>
                            <StepButton value={stage} style={{ cursor: 'default' }}>
                                {<div id={index} style={{ color: '#717171' }} title={stage}>{stage}</div>}
                            </StepButton>
                        </Step>
                    );
                }
            }
        });
        setStepIndex(tempstepIndex);
        setSteps(schedulerSteps);
        setIsStepperMounted(true);
    }

    const getStepContent = (stepIndex)=> {
        switch (stepIndex) {
            case 0:
                return (
                    <SchedulerInfo setScheduleInfoData={setScheduleInfoData} schedulerInfoData={scheduleInfo} errorFlag={errorFlag} schedulerId = {schedulerId} />
                );
            case 1:
                return (
                    <SchedulerAvailability setAvailabilityInfoData={setAvailabilityInfoData} availabilityInfoData={availabilityInfo} errorFlag={errorFlag} isIntegration={isIntegration} />
                );
            case 2:
                return (
                    <SchedulerIntakeForm setIntakeFormInfo={setIntakeFormInfoData} intakeFormInfo={intakeFormInfo} errorFlag={errorFlag} />
                );

            default:
                return 'Soffront Scheduler Module';
        }
    }

    const setScheduleInfoData = (scheduleInfo) => {
        setScheduleInfo(scheduleInfo);
    }

    const setAvailabilityInfoData = (availabilityInfo) => {
        setAvailabilityInfo(availabilityInfo);
    }

    const setIntakeFormInfoData = (intakeFormInfo) => {
        setIntakeFormInfo( intakeFormInfo);
    }

    const handleNext = () => {
        let isValid = false;
        if (stepIndex === 0) {
            isValid = validate(stepIndex);
            if (isValid) {
                let index = stepIndex + 1;
                setStepIndex(index);
                generateStepper(index);

            }
        } else if (stepIndex === 1) {
            isValid = validate(stepIndex);
            if (isValid) {
                let index = stepIndex + 1;
                setStepIndex(index);
                generateStepper(index);
            }
        } else if (stepIndex === 2) {
            isValid = validate(stepIndex);
            if (isValid) {
                saveUpdateSchedulingSetup()
            }

        }

    }

    const validate = (stepIndex) => {
        let isValid = true;
        let msg = "";
        if (stepIndex === 0) {
            let schedulerInfo = getObjectParam(scheduleInfo);
            if (schedulerInfo.hasOwnProperty('error')) {
                delete schedulerInfo.error;
            }
            if (isInvalidParam(schedulerInfo.appt_name)) {
                isValid = false;
                msg = getLocalizedStrings().message.SCHEDULER.BLANK_APPOINTMENT_NAME;
                schedulerInfo.error = "appt_name_error";
                setErrorFlag(!errorFlag );
               }
        } else if (stepIndex === 1) {

            let availabilityInfoObj = getObjectParam(availabilityInfo);

            Object.keys(availabilityInfoObj).map(key => {
                let obj = getObjectParam(availabilityInfoObj[key]);
                if (obj.hasOwnProperty("error")) {
                    delete obj.error;
                }
            });

            let keys = Object.keys(availabilityInfoObj);
            for (var i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (key !== 'isNylasSetup') {
                    let obj = getObjectParam(availabilityInfoObj[key]);
                    let s_time = getIntParam(obj.start_time);
                    let e_time = getIntParam(obj.end_time);

                    if (s_time !== -1 && e_time !== -1) {
                        var startTime = moment(s_time + ":" + "00" + "00", 'h:mm:ss');
                        var endTime = moment(e_time + ":" + "00" + "00", 'h:mm:ss');

                        if (startTime.isAfter(endTime) || startTime.isSame(endTime)) {
                            isValid = false;
                            msg = getLocalizedStrings().message.SCHEDULER.VALIDATE_TIME;
                            obj.error = "start_time_error";
                            setErrorFlag(!errorFlag);
                            break;
                        }
                    }

                    if (s_time === -1 && e_time !== -1) {
                        isValid = false;
                        msg = getLocalizedStrings().message.SCHEDULER.NO_START_TIME;
                        obj.error = "start_time_error";
                        setErrorFlag(!errorFlag);
                        break;
                    }

                    if (e_time === -1 && s_time !== -1) {
                        isValid = false;
                        msg = getLocalizedStrings().message.SCHEDULER.NO_END_TIME;
                        obj.error = "end_time_error";
                        setErrorFlag(!errorFlag);
                        break;
                    }

                }
            }

        } else if (stepIndex === 2) {
            let isDuplicate = false;
            let count = 0;
            let tempintakeFormInfo = getObjectParam(intakeFormInfo);
            if (tempintakeFormInfo.hasOwnProperty('error')) {
                delete tempintakeFormInfo.error;
            }
            let wfSetupDetails  = tempintakeFormInfo.wf_setup_details != null && tempintakeFormInfo.wf_setup_details != '' ? JSON.parse(tempintakeFormInfo.wf_setup_details):null;
            if(wfSetupDetails != null){
                let keys = Object.keys(wfSetupDetails);
            for (var i = 0; i < keys.length; i++) {
                let key = keys[i];
                    let obj = getObjectParam(wfSetupDetails[key]);
                    let cWorkflow = getIntParam(obj.cWorkflow);
                    let nWorkflow = getIntParam(obj.nWorkflow);
                    let cStatus = (obj.cStatus);
                    let nStatus = (obj.nStatus);
                   

                   
                        if (cWorkflow >0 && nWorkflow == 0) {
                            isValid = false;
                            msg = "Please mention the Workflow and Status, where you want the record to be converted. This cannot be blank.";
                            obj.error = "Workflow_name_error";
                            setErrorFlag(!errorFlag);
                            break;
                        }
                        if (cWorkflow == 0 && nWorkflow > 0) {
                            isValid = false;
                            msg = "Please mention the Workflow and Status, from where you want to convert the record. This cannot be blank.";
                            obj.error = "Workflow_name_error";
                            setErrorFlag(!errorFlag);
                            break;
                        }
                    

                    if (cWorkflow > 0 && nWorkflow> 0 && cWorkflow == nWorkflow && cStatus == nStatus ) {
                        isValid = false;
                        msg = "Please mention different Status when you convert the record to same Workflow";
                        obj.error = "duplicate/_error";
                        setErrorFlag(!errorFlag);
                        break;
                    }
                    for (var j = 0; j < keys.length; j++) {
                        let tempkey = keys[j];
                        let tempobj = getObjectParam(wfSetupDetails[tempkey]);
                        let currWorkflow = getIntParam(tempobj.cWorkflow);
                        let currStatus = (tempobj.cStatus);
                        if(currWorkflow > 0  && cWorkflow>0  && currWorkflow == cWorkflow && cStatus == currStatus){
                            count = count +1;
                            if(count > 1){
                                isDuplicate =true;
                            }
                        }
                    }
                    count = 0;
                     if(isDuplicate){
                        isValid = false;
                        msg = "You have defined same condition for two different rules. Each and every Lead conversion rule should have different conditions";
                        obj.error = "duplicate/_error";
                        setErrorFlag(!errorFlag);
                        break;
                     }

                
            }
        }


            if (tempintakeFormInfo.hasOwnProperty("bookingButtonLabel") && isInvalidParam(tempintakeFormInfo.bookingButtonLabel.trim())) {
                isValid = false;
                msg = getLocalizedStrings().message.SCHEDULER.BLANK_SUBMIT_BUTTON;
                tempintakeFormInfo.error = "submit_button_error";
                setErrorFlag(!errorFlag);
            }
        }


        if (!isValid) {
            dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        return isValid;
    }


    const saveUpdateSchedulingSetup = () => {
        try {
            let param = {};
            let appInfo = getObjectParam(scheduleInfo);
            let availabilityInfoObj = getObjectParam(availabilityInfo);
            appInfo.booking_btn_label = intakeFormInfo.bookingButtonLabel;
            appInfo.thank_u_msg = intakeFormInfo.thankYouMsg;
            appInfo.appt_name = appInfo.appt_name.trim();
            appInfo.description = appInfo.description.trim();
            appInfo.workflow_id = intakeFormInfo.workflow_id;
            appInfo.status_name = intakeFormInfo.status_name;
            appInfo.wf_setup_details = (intakeFormInfo.wf_setup_details);
            appInfo.appointmentType = appInfo.appointmentType != null?appInfo.appointmentType : "";
            param.apptInfo = appInfo;

            let keys = Object.keys(availabilityInfoObj);
            let availabilityInfoArr = [];
            keys.map((key) => {
                if (key !== "isNylasSetup") {
                    availabilityInfoArr.push(availabilityInfoObj[key]);
                }
            });
            param.availabilityInfo = availabilityInfoArr;
            param.intakeFormInfo = intakeFormInfo;
            param.schedulerId = getIntParam(schedulerId);

            setIsSavedClicked(true);

            let promise = Promise.resolve(saveSchedulingSetup(param));

            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setIsSavedClicked(false);
                        if (response.status === 0 && response.data.hasOwnProperty('schedulerId')) {
                            let schedulerId = getIntParam(response.data.schedulerId);
                            let slug = getStringParam(response.data.slug);
                            if (schedulerId > 0) {
                                appInfo.slug = slug;
                                let scheduleObj = {
                                    scheduleInfo: Object.assign({}, appInfo),
                                    availabilityInfo: Object.assign({}, availabilityInfoObj),
                                    intakeFormInfo: Object.assign({}, intakeFormInfo),
                                    schedulerId: param.schedulerId
                                };
                                dispatch(setSchedulerDetails(scheduleObj));
                                app.appointment_scheduler_slug = slug;

                                let linkDetails = [];
                                let tempLinks = schedulerLinks.filter(f => { return f.id === schedulerId });
                                appInfo.id = schedulerId;
                                if (tempLinks.length > 0) {
                                    schedulerLinks.map((link, i) => {
                                        if (link.id === schedulerId) {
                                            linkDetails.push(appInfo);
                                        } else {
                                            linkDetails.push(link);
                                        }
                                    })
                                } else {
                                    linkDetails = schedulerLinks;
                                    linkDetails.push(appInfo);
                                }
                                dispatch(updateSchedulerLinkDetails(linkDetails));
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.SCHEDULER.SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                setSchedulerId(schedulerId);
                                setIsSaved(true);
                                setStepIndex( 0);
                                setSchedulerLinks(linkDetails);
                                setIsAddOpen(false);
                                setIsEdit(false);
                            } else {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.SCHEDULER.SAVE_FAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        }
                    }
                });
            }
        } catch (error) {
            console.error("error in scheduler.js -> saveUpdateSchedulingSetup() ::" + error);
        }
    }

    const handleCancel = () => {
        try {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.SCHEDULER.SCHEDULER_UNSAVE_msg,()=>handleCancelOnConvert(),null);
        } catch (error) {
            console.error("error in scheduler.js -> handleCancel() ::" + error);
        }
    }
    const handleCancelOnConvert=()=>{
            setIsAddOpen(false);
            setIsEdit(false);
            setStepIndex(0);
            if(schedulerLinks.length == 0){
                setIsSaved(false);
            }else{
                setIsSaved(true);
            }
            
    }
    const addNew = () => {
        let calendarInfoFromRedux = getObjectParam(calendar.calendarInfo);
          let apoointmentList=[]
        if(calendarInfoFromRedux.hasOwnProperty('appTypes')&&  appoType.length ===0){
            apoointmentList= calendarInfoFromRedux.appTypes;
        }
        else{
            apoointmentList=appoType;
        }
              let _availabilityInfo = {};
        _availabilityInfo.isNylasSetup = availabilityInfo.isNylasSetup;
        let _scheduleInfo = {};
        _scheduleInfo.appointmentTypes = apoointmentList;
            setStepIndex(0);
            generateStepper(stepIndex)
            setIsSchedulerMounted(true);
            setFinished(false);
            setScheduleInfo({..._scheduleInfo});
            setAvailabilityInfo(_availabilityInfo);
           setIntakeFormInfo({});
           setIsSaved(false);
           setIsAddOpen(true);
           setSchedulerId(0);
           setScrollUp (true);
    }

    const handleBack = () => {
        let index = stepIndex - 1;
        setStepIndex(index);
        setIsSavedClicked(false);
        generateStepper(index);
    }

    const generateSchedulerLink = () => {
        let ele = [];
        
        if (schedulerLinks !== null && schedulerLinks.length > 0) {
            let slug = schedulerLinks[0].slug;
            let schedulerURL = constants.APPOINTMENT_SCHEDULER_URL + slug;
                ele.push(<div style={{ float: 'left', paddingLeft: '20px', paddingTop: '10px', paddingBottom: '10px', maxHeight: '200px', overflowY: 'auto', width: '100%', backgroundColor: '#e3e6e8', marginBottom: '10px' }}>
                    <div style={{ fontSize: '20px' }}>{getLocalizedStrings().label.SCHEDULER.MY_SCHEDULAR_LINK}</div>
                    <div>
                        <span style={{ paddingRight: '5px',fontSize: "14px "}}><a href={schedulerURL} target="_new" onClick={() => { }} >{schedulerURL} </a></span>
                        <span title={copyTitle} style={{ height: '27px', width: '28px', marginLeft: '-2px' }}
                            onClick={() => copyToClipboard(schedulerURL)}>
                            <Icon style={{ fontSize: '16px', color: '#717171', cursor: 'pointer' }}>content_copy</Icon>
                        </span>
                    </div>
                </div>);

                schedulerLinks.map((scheduler, i) => {
                    let duration_type = scheduler.duration_type;
                    duration_type = (duration_type === 'hr') ? ((scheduler.duration > 1) ? 'hours' : 'hour') : 'minutes';
                    ele.push(<div style={{ float: 'left', paddingLeft: '20px', paddingTop: '10px', paddingBottom: '5px', maxHeight: '200px', overflowY: 'scroll', width: '100%', backgroundColor: '#e3e6e8' }}>
                        {i === 0 && <div style={{ fontSize: '20px' }}>{getLocalizedStrings().label.SCHEDULER.MY_APPOINTMENT_TYPE}</div>}
                        <div>
                            <span style={{fontSize:'14px'}}> {scheduler.appt_name + ' (' + scheduler.duration + ' ' + duration_type + ')'}</span>
                            <span style={{ cursor: 'pointer', color: 'rgb(113, 113, 113)', marginTop: '6px', paddingLeft: '10px', paddingRight: '5px' }} onClick={() => editSchedulerSetup( scheduler.id)}>
                                <Icon style={{ 'fontSize': '18px' }} title={getLocalizedStrings().label.COMMON.EDIT}>edit</Icon>
                            </span>
                            <span style={{ cursor: 'pointer', color: 'rgb(113, 113, 113)', marginTop: '6px', paddingLeft: '5px', paddingRight: '10px' }} onClick={() => deleteSchedulerSetup(scheduler.id)}>
                                <Icon style={{ 'fontSize': '18px' }} title={getLocalizedStrings().label.COMMON.DELETE}>delete</Icon>
                            </span>
                        </div>
                    </div>);
                });
                

        }
        return ele;
    }

    const editSchedulerSetup = (schedulerId) => {
        setEditScrollUp(true);
        if (isAddOpen || isEdit) {
              sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.SCHEDULER.SCHEDULER_UNSAVE_msg,()=>editSchedulerSetupOnConfirm.bind(isAddOpen,schedulerId),null);
        } else {
            callSchedulerDetails(schedulerId);
        }
    }

    const editSchedulerSetupOnConfirm=(isAddOpen,schedulerId)=>{
        callSchedulerDetails(schedulerId);
        isAddOpen = isAddOpen ? !isAddOpen : isAddOpen;
        setIsAddOpen(isAddOpen);
    }

    const deleteSchedulerSetup = (schedulerId) => {
        try {
            schedulerId = getIntParam(schedulerId);
            if (schedulerId > 0) {
                let scheduler = schedulerLinks.filter(f => { return f.id === schedulerId });
                let msg = "'" + scheduler[0].appt_name + "' " + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, ()=>deleteSchedulerSetupOnConfirm(schedulerId, schedulerLinks),null);
            }
        } catch (error) {
            console.error("error in scheduler.js -> deleteSchedulerSetup :" + error);
        }
    }
    const deleteSchedulerSetupOnConfirm=(schedulerId, schedulerLinks)=>{
        let param = {
            schedulerId: schedulerId
        }
        let promise = Promise.resolve(deleteScheduler(param));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.data.isDelete) {
                        let tempLinks = [];
                        schedulerLinks.map((link, i) => {
                            if (link.id !== schedulerId) {
                                tempLinks.push(link);
                            }
                        });
                        setSchedulerLinks(tempLinks);
                        setIsSaved(true);
                        dispatch(updateSchedulerLinkDetails(tempLinks));
                        if (tempLinks.length === 0) {
                            addNew();
                            app.appointment_scheduler_slug = null;
                        }
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        
                    }
                }
            });
        }
    }
    const copyToClipboard = (schedulerURL) => {
        copy(schedulerURL);
        dispatch(showCustomSnackBar(getLocalizedStrings().message.SCHEDULER.SCHEDULER_LINK_COPIED_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
        setCopyTitle(schedulerURL);
    }

        if (!isStepperMounted) {
            generateStepper(stepIndex);
        }
        let btnDivStyle = { float: 'right', paddingTop: '27px', paddingBottom: '10px' };
        if (stepIndex === 0) {
            btnDivStyle = { ...btnDivStyle, width:schedulerLinks.length > 0? '30%':'24%' };
        } else if (stepIndex === 1) {
            btnDivStyle = { ...btnDivStyle, width: '45%' };
        } else if (stepIndex === 2) {
            btnDivStyle = { ...btnDivStyle, width: '24.5%' };
        }
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;

        if(scrollUp && !isSaved && stepIndex !== 2) {
            let LinkHeight = isValidParam(schedulerLinks) ? (schedulerLinks.length*32):0;
            let totalHeight = LinkHeight + 446 + 70 + 50 + 10;
            let element = document.getElementById('scroll');
            if(element !== undefined && element !== null){
                element.scrollTop = totalHeight;
            }
        }
        if(editScrollUp && stepIndex !== 2) {
            let LinkHeight = isValidParam(schedulerLinks) ? (schedulerLinks.length*32):0;
            let totalHeight = LinkHeight + 446 + 70 + 50 + 10;
            let element = document.getElementById('scroll');
            if(element !== undefined && element !== null){
                element.scrollTop = totalHeight;
            }
        }
        return (
            <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                {isSchedulerMounted ?
                    <div>
                        {isSaved ?
                            <div>

                                <div style={{ float: 'right', marginBottom: '5px' }}>
                                    <Button
                                        startIcon={<Icon style={{ fontWeight: 'bold' }}>add</Icon>}
                                        onClick={() => addNew()}
                                       
                                        title={getLocalizedStrings().label.SCHEDULER.ADD_BUTTON}
                                        
                                        style={styles.listViewPrimaryButton}
                                    > {getLocalizedStrings().label.COMMON.ADD}</Button>
                                </div>
                                  {generateSchedulerLink()}
                            </div> :

                            <div>
                                {generateSchedulerLink()}


                                <div id='sf-scheduler-from'>
                                <div style={{ width: '99%', overflow: 'hidden',backgroundColor:'#f5f5f5' }} id="schedulerStepper"  >
                                    <Stepper nonLinear style={{background: 'transparent', marginTop:'10px', marginLeft:'25px'}} activeStep={stepIndex}>
                                        {steps}
                                    </Stepper>
                                </div> 
                                <div>{getStepContent(stepIndex)}</div>

                                <div style={btnDivStyle}>
                                {(stepIndex === 1  || stepIndex === 2  || schedulerLinks.length > 0) &&
                                    <Button
                                        id="btn"
                                       
                                        onClick={handleCancel}
                                        style={{...styles.secondaryButton , marginRight:'5px'}}
                                    > {getLocalizedStrings().label.COMMON.CANCEL}</Button>
                                }

                                    {(stepIndex === 1 || stepIndex === 2) &&
                                        <Button
                                            id="btn"
                                            
                                            onClick={handleBack}
                                            style={{...styles.secondaryButton,marginRight:'5px'}}
                                        >{getLocalizedStrings().label.COMMON.BACK}</Button>
                                    }
                                    <Button
                                        id="btn"
                                        primary={true}
                                        onClick={handleNext}
                                        style={{...styles.primaryButton , marginLeft : (stepIndex === 0 && schedulerLinks === null) ? '60px' : '0px'}}
                                        disabled={isSavedClicked}
                                    >  {stepIndex === 2 ? getLocalizedStrings().label.COMMON.SAVE : getLocalizedStrings().label.IMPORT.NEXT}</Button>
                                </div>
                                </div>
                            </div>}
                        </div> :
                    <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>}
            </div>);
}

export default Scheduler;

