 const message = {
    COMMON: {
        NO_ACTIONS_AVAILABLE: 'クイック追加アクションに使用できるオブジェクトがありません',
        NOTES_REMOVE_MESSAGE:'選択したノートが削除されます。 本気ですか？',
        SELECTED_NOTE_MESSAGE:'このアクションを実行するには、少なくとも 1 つのノートを選択する必要があります。',
        BR_WINDOW_OPEN_CONFORMATION: 'キャンペーンのドラッグアンドドロップ機能はサポートしていません。あなたはキャンペーンを開きますか？',
        CONFIRM_DIALOG_TITLE: '確認ダイアログ',
        ALERT_DIALOG_TITLE: '警告ダイアログ',
        XMS_SUCCESS_ALERT_DIALOG_TITLE: '成功',
        XMS_ERROR_ALERT_DIALOG_TITLE: 'エラー',
        SAVE: '正常に保存。',
        MERGE: '正常にマージされました。',
        UPDATE: '更新成功。',
        DELETE: '正常に削除されました。',
        REMOVE: '削除されました。',
        UNLINK: 'リンクが正常に終了しました。',
        COPY_EMAIL:'電子メール ID がクリップボードにコピーされました',
        EMAIL_VALIDATION:'正しいメールアドレスを入力してください。',
        EMPTY_EMAIL:'コピーする電子メールが存在しません',
        ERROR: 'システムが応答していません。',
        ACCESS_DENIED: '申し訳ありませんが、この操作を実行する権限がありません。管理者に連絡してください。',
        NOTIFICATION_INFO_EMPTY: '通知なし',
        /** START:  Validation message */
        BLANK: 'ブランク',
        EMAIL: '無効な電子メールアドレスです。',
        VALID_EMAIL: '入力した電子メールアドレスは有効なアドレスではありません。もう一度お試しください。ヒント：電子メールアドレスに電子メール\\ @ domain.com形式があることを確認してください。',
        URL: '無効なURLです。',
        VALID_URL: '有効なURLを入力してください。',
        INTEGER: '整数ではありません。',
        VALID_INTEGER: '有効な整数を入力してください。',
        FLOAT: '浮動小数点ではありません。',
        VALID_FLOAT: '有効なフロートを入力してください。',
        VALID_PHONE: '電話番号は数字でなければなりません!!',
        VALID_LIST: '値を選択してください。',
        TOO_BIG_SIZE: '入力した値がこのフィールドには大きすぎます。より低い値を入力してください。',
        NO_RECORD: 'レコードが見つかりませんでした。',
        VALIDATE_BLANK: '空白にすることはできません。',
        VALIDATE_INTEGER: '整数でなければならない。',
        VALIDATE_DECIMAL: '10進数でなければなりません。',
        VALIDATE_POSITIVE_DECIMAL: '0より大きく、10進数でなければなりません。',
        VALIDATE_URL: '無効なURL形式です。例 -  www.soffront.com',
        VALIDATE_EMAIL: 'メールフォーマットが無効です。',
        VALUE: '値',
        LIBRARY_DIALOG_TITLE: 'Soffront オンラインライブラリ',
        BLANK_EMAIL: 'メールが空です',
        INVALID_EMAIL: '有効なメールアドレスを入力してください！',
        BLANK_NUMBER : '番号が空白です',
        INVALID_NUMBER :'有効な番号を入力してください',
        EMPTY_SEARCH_CRITERIA: '検索条件を入力してください',
        VALIDATE_MORE_CHECKBOX_SELECTION: '複数のチェックボックスが選択されている場合、<*>で検索を実行することはできません！',
        VALIDATE_NO_CHECKBOX_SELECTION: 'チェックボックスが選択されていない場合は、<*>を使用して検索を実行することはできません。',
        NO_DATA: '何もデータが見つかりませんでした',
        DISCONNECT_ALERT_MSG: 'このクライアントを切断してもよろしいですか？',
        DISCONNECT_SUCCESS: 'クライアントの接続が正常に終了しました。',
        CONFIRM_DELETE_RECORD: 'レコードは削除されます。本気ですか？',
        DELETE_SUCCESS: ' 削除されました。',
        COULDNOT_LOCATE: "私たちは見つけられませんでした ",
        DELETED_OR_ACCESS_DENIED: " 削除されている可能性があります。またはアクセス権がないか、すでに開いている可能性があります。",
        CLICK: 'クリック',
        HERE: 'ここに',
        MERGE_ALERT_1: 'マージしてもよろしいですか',
        WITH: '〜と',
        MERGE_ALERT_2: '削除',
        DATA_ACCESS_REQUEST_SEND_SUCCESS: 'に送信されたデータアクセス要求',
        DATA_ACCESS_REQUEST_SEND_FAILURE: 'データアクセス要求を送信できません',
        REQUEST_DATA_ACCESS: 'からのデータアクセスをリクエストする',
        WE_WILL_EMAIL: '我々は電子メールを送信します',
        AT: '〜で',
        TO_APPROVE_REQUEST: 'あなたの要求を承認する',
        HAVE_BEEN: 'なっている',

        //not found in doc

        REMINDER_VALUE: 'リマインダーの値は空白にできません',
        DUPLICATE_EMAIL: 'あなたはすでに同じ電子メールIDを持つレコードを持っています。 [OK]をクリックして続行するか、[キャンセル]をクリックして電子メールIDを変更します。',
        DUPLICATE_CONTACT_EMAIL: 'あなたはすでに同じ電子メールIDを持つレコードを持っています。別のメールIDを入力してください。',
        CLIENT_CONNECT_MESSAGE: 'この顧客はSoffront Onlineにこのメールのアカウントを持っていません。最初に、同じメールでアカウントを作成するようお客様に依頼します。',
        BLANK_CAMPAIGN_TYPE: 'キャンペーンの種類を空白にすることはできません。',
        DUPLICATE_CAMPAIGN_TYPE: 'キャンペーンタイプは既に存在します。',
        CAMPAIGN_TYPE_SAVE_SUCCESS: 'キャンペーンタイプが正常に保存されました。',
        /** END:  Validation message */
        STEPPER_BACK_MESSAGE: 'あなたはテンプレート情報ページに行きたいですか？未保存の変更はすべて失われます。',
        CENCEL_MOSAICO_MESSAGE: '保存せずにキャンセルしますか?',
        NO_RESULTS_FOUND: '結果が見つかりません',
        FOR: 'にとって',
        ENABLE_MAILING_SUCCESS: 'メール送信が有効になりました',
        DISABLE_MAILING_SUCCESS: 'メール送信を無効にしました',
        MAILING_FAILURE: 'このアカウントはCRMテナントではありません',
        TEST: 'テストする',
        SELECT_RECORD_FOR_DELETE: '削除するレコードを選択してください。',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: '編集するレコードを1つ選択してください。',
        SELECT_RECORD_FOR_DELETE_ONE_RECORD: '削除するレコードを1つ選択してください。',
        ASSETMENU_DIALOG_TITLE: '資産を選択',
        UNIT_SELECT_ALERT_MESSAGE: '一度に 1 つのユニットを選択できます。',
        LOOKUP_MULTI_SELECT: '1つのレコードのみを選択してください。',
        LOOKUP_SELECT_A_RECORD: '1つのレコードを選択してください。',
        BOTH_COMPANY_AND_WORKFLOW_SELECTED: '会社とワークフローの両方を選択しました。',
        VALIDATE_ZERO: 'ゼロにすることはできません。',
        GREENLIGHT_EXPORT_NO_RECORD: 'この日付範囲のGreenlightワークフローにはアカウントがありません。',
        WITHOUT_SAVE_MESSAGE: '変更を保存せずに続行しますか？',
        DELETE_API_KEY: 'これにより、CRM の API キーが削除されます。続行しますか?',
        UNIT_ALERT: ' ログインするレコードを選択してください。',
        THIS_FIELD_CANNOT_BLANK: 'このフィールドを空白にすることはできません。',
        CANNOT_DELETE_ALL_ITEM: '[モバイル メニュー] の下に少なくとも 1 つの項目が必要です。',
        DUPLICATE_MSG_1: 'あなたはすでにそれをレコード（複数可）を持っています ',
        DUPLICATE_MSG_2: '。変更には、キャンセルを続行するか、をクリックして[OK]をクリックしてください ',
        DUPLICATE_MSG_3: ' そして ',
        DUPLICATE_CONTACT_EMAIL_1: 'あなたはすでにそれをレコード（複数可）を持っています ',
        DUPLICATE_CONTACT_EMAIL_2: '。異なる入力してください。 ',
        INVALID_TIME_ERROR_MSG: '有効な時間間隔を入力してください。',
        DOESNT_HAVE_ASSOCIATED_1:'この ',
        DOESNT_HAVE_ASSOCIATED_2: " 関連する連絡先がありません.", 
        PHONE_CALL_NOT_ENABLED: '通話が有効になっていません。',
        NOT_CONFG_CALL_SETUP : '通話設定を構成していません。',
        NOT_CONFG_CALL_SMS_SETUP:'通話/ SMSの設定を構成していません。',
    },
    SALES_TEMPLATE: {
        NAME_BLANK: 'このテンプレートを識別するための名前を入力してください。',
        SUBJECT_BLANK: 'このテンプレートの件名を入力してください。',
        DUPLICATE_NAME: 'すでに%%という名前のテンプレートがあります。重複したテンプレートはサポートしていません。別の名前を入力してもう一度お試しください。',
        DELETE: 'テンプレートは削除されます。本気ですか？',
        TEMPLATE_DELETED: 'テンプレートが削除されました',
        TEMPLATE_NAME_ERROR: 'すでにこの名前のテンプレートがあります。別の名前を入力してもう一度お試しください。',
    },
    AGREEMENT_TEMPLATE: {
        NAME_BLANK: 'このテンプレートを識別する名前を入力してください。',
        // SUBJECT_BLANK: 'Please enter a subject for this template.',
        DUPLICATE_NAME: '%% という名前のテンプレートが既に存在します。重複テンプレートはサポートしていません。別の名前を入力して、もう一度やり直してください。',
        DELETE: 'テンプレートは削除されます。本気ですか？',
        TEMPLATE_DELETED: 'テンプレートが削除されました',
        TEMPLATE_NAME_ERROR: 'すでにこの名前のテンプレートがあります。別の名前を入力してもう一度お試しください。',
        DUPLICATE_TEMPLAE_EXIST: 'このテンプレート名は既に存在します。',
        RECORD_NOT_EXIST: 'このレコードは存在しません。',
        SAVE_TO_DOWNLOAD: '契約テンプレートを保存していません。最初に契約テンプレートを保存してダウンロードしてください。',
        TEMPLATE_CAN_NOT_EMPTY: '契約テンプレート名は空にできません。',
        SELECT_TEMPLATE: 'テンプレートを選択してください。',
        SENT_OR_SIGNED: 'この契約書はすでに 1 回送信されています。再度送信することはできません。',
        SIGNATURE_TAGS_NOT_SEQUENCED_1: '署名タグは順番に追加してください。',
        SIGNATURE_TAGS_NOT_SEQUENCED_2: '署名タグを追加するときに順序をスキップしたようです。',
        SIGNATURE_TAGS_MISSING: '契約書本文には署名タグがありません。契約書本文には少なくとも 1 つの署名タグが必要です。',
        SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING: '署名タグを使用せずに署名に差し込みフィールドを使用したようです。続行するには署名タグを追加してください。',
        AGREEMENT_EDIT: '編集は契約レコードに対してのみ実行でき、他の添付ファイル レコードや送信済みの契約レコードに対しては実行できません。',
        AGREEMENT_EXPIRED_1: 'この契約は次の日に期限切れになりました ',
        AGREEMENT_EXPIRED_2: '送信する前に契約有効期限を延長してください。',
    },
    SMS_TEMPLATE: {
        BLANK_MESSAGE: 'このテンプレートを保存するメッセージを入力してください。',
        SAVE_MESSAGE: 'SMS は正常に保存されました。',
    },
    MARKETING_TEMPLATE: {
        NAME_BLANK: 'このテンプレートを識別するための名前を入力してください。',
        SUBJECT_BLANK: 'このテンプレートの件名を入力してください。',
        DUPLICATE_NAME: 'すでに%%という名前のテンプレートがあります。重複したテンプレートはサポートしていません。別の名前を入力してもう一度お試しください。',
        DELETE: 'テンプレートは削除されます。本気ですか？',
        BACK: '元に戻ると、保存されていない変更が失われます。続行しますか？',
        SELECT_DESIGN: 'デザインから選択',


        //not found in doc

        TEMPLATE_UPDATE: 'マーケティングテンプレートが更新されました',
        CONFIRM1: 'の',
        CONFIRM2: 'レコードが更新されます',
        CONFIRM3: '本気ですか？',
        ALERT1: 'から',
        FROM_NAME_BLANK: 'メールから選択してください。',
        FROM_EMAIL_BLANK: 'メールから選択してください。',
        DUPLICATE_TEMPLATE_NAME: 'El nombre de la plantilla ya existe.'
    },
    BROADCAST_CAMPAIGNS: {
        NAME_BLANK: 'このブロードキャストキャンペーンを識別する名前を入力してください。',
        GROUP_FILTER_BLANK_COMMON_MSG: '選んでください',
        FILTER_TYPE_GROUP: 'グループ',
        FILTER_TYPE_CONTACT: 'コンタクトフィルタ',
        FILTER_TYPE_ACCOUNT: 'アカウントフィルタ',
        SUBJECT_BLANK: '件名を入力してください',
        FROM_NAME_BLANK: '名前を入力してください',
        FROM_EMAIL_BLANK: '送信メールを選択してください',
        GROUP_NAME_BLANK: 'グループ名は空白にすることはできません。',
        SAVE: 'このキャンペーンは正常に作成されました。',
        UPDATE: 'このキャンペーンを正常に更新しました。',
        GROUP_NAME_SIZE_LIMIT: 'グループ名は128文字以下にする必要があります。',
        EXECUTION_CANNOT_CANCEL_1: '実行を今キャンセルすることはできません。',
        EXECUTION_CANCEL_MSG: 'アプリケーションはすぐに実行を取り消そうとします。',
        CONFIRM: 'ご確認ください。',
        EXECUTION_CANNOT_CANCEL_2: '既に処理中のキャンペーンはキャンセルできません。',
        PURCHASE_MORE: 'より多くを購入する。',
        CREDITS_REMAINING: '残りの電子メールクレジット',

        //not found in doc

        VERIFY_BLANK_EMAIL: '確認するには、電子メールアドレスを入力する必要があります。',
        VERIFY_INVALID_EMAIL: '無効な電子メールアドレスです。',
        SEND_VERIFICATION_EMAIL_SUCCESS: '確認メールを送信しました。受信トレイを確認して、このメールアドレスの確認をクリックしてください。次に、戻ってきて、更新アイコンをクリックして新しいメールアドレスを表示します。',
        EMAIL_INTEGRATION_NEW: 'このメールアドレスは既に確認のために送信されています。受信トレイを確認し、このメールアドレスの確認をクリックしてください。次に、戻ってきて、更新アイコンをクリックして新しいメールアドレスを表示します。',
        EMAIL_INTEGRATION_VERIFIED: 'このメールアドレスは既に確認済みです！',
        BD_NAME_COPY_ERROR: 'この名前の既存のキャンペーンがあります。このキャンペーンに別の名前を入力してください',
        STEPPER_BACK_MESSAGE: 'あなたは放送情報ページに行きたいですか？未保存の変更はすべて失われます。'
    },
    SPACE: ' ',
    LIST_VIEW: {
        SELECT_RECORD: 'より多くの操作を実行するには、レコードを選択してください。',
        SELECT_RECORD_FOR_CONVERT: '変換するレコードを選択してください。',
        SELECT_RECORD_FOR_EDIT: '編集するレコードを選択してください。',
        SELECT_RECORD_FOR_COPY: 'コピーするレコードを選択してください。',
        SELECT_RECORD_FOR_SEND_EMAIL: 'メールを送信するレコードを選択してください。',
        MULTIPLE_RECORD_FOR_EDIT: '編集するレコードを1つ選択してください。',
        MULTIPLE_RECORD_FOR_COPY: 'コピーするレコードを1つ選択してください。',
        MULTIPLE_RECORD_FOR_MORE: '詳細についてはレコードを選択してください。',
        CANNOT_COPY_APPOINTMENT_WORKFLOW: '予定のワークフローをコピーすることはできません。',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'コピーするレコードを1つ選択してください。',
        SELECT_MAXIMUM_FIELD_FOR_SETUP: '選択された合計15フィールドの最大限に達しました。',
        CONVERT_CONTACT_TO_ACCOUNT_CONFIRM: 'この連絡先は既にアカウントに関連付けられています。 [OK]をクリックすると、アカウントの詳細が表示されます。',
        SHARE_PERMISSION: 'このレコードを共有する許可はありません。',
        NOT_ADDED: 'あなたは追加していません',
        FIELDS_TO_VIEW: '表示するフィールド。',
        SETUP_MINIMUM_FIELD_ALERT: '別のフィールドを追加してください。ビューには最低2つのフィールドが必要です。',
        SELECT_ANOTHER_TABLE: '別のテーブルを選択して、もう一度お試しください。',
        //not found in doc

        WEB_FORM_ALERT1: 'から',
        WEB_FORM_ALERT2: '選択されたWebフォームは、オートメーションデザイナで使用されるため、削除されません。',
        SETUP_WORKFLOWSTAGE_MINIMUM_FIELD_ALERT: '列のフィールドを追加してください。ビューには最低 1 フィールドが必要です。',
        SELECT_RECORD_FOR_SEND_SMS: 'テキストメッセージを送信するレコードを選択してください。',
        SELECT_RECORD_FOR_CALL: '電話をかけるレコードを選択してください',
        SELECT_MORE_RECORD_ACTION_ALERT: 'その他の操作を実行するには、レコードを1つ選択してください。',
        SELECT_RECORD_FOR_DOWNLOAD: '[アカウントをダウンロード] にレコードを選択してください。',
        CHECKBOX_COLOR_GREEN_TITLE: '30日以内の最終更新',
        CHECKBOX_COLOR_YELLOW_TITLE: '30〜60日以内の最終更新',
        CHECKBOX_COLOR_RED_TITLE: '60日前の最終更新',
        STATUS_FIELD_NOT_ADDED_FOR_VIEW: '表示する状態フィールドが追加されていません。',
        NO_EMAIL_TO_SEND_MAIL: '電子メールを送信できる電子メール アドレスがありません。',
        REQUIRED_FIELD_CONVERT_FAILED: '変換に失敗しました。1 つ以上の必須フィールドが見つかりません。',
        SELECT_RECORD_FOR_To_EXCEL: 'Excelにレコードを選択してください。',
        SELECT_RECORD_FOR_ADD_TASK: 'Please select onプルタスクを追加するにはレコードを 1 つ選択してくださいe record to add task',
        UNIT_TASK_MESSAGE: 'タスクを追加できるのは、自分に直接接続されているユニットのみです。',
        UNIT_SEND_EMAIL: '自分に直接接続されているユニットにのみ電子メールの送信を開くことができます',
        UNIT_PHONE_NUMBER: '自分に直接接続されているユニットに対してのみ電話をかけることができます',
        FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED: 'FDD または FA タイプの添付レコードを削除することはできません。',
        MULTIPLE_SELECT_FDD_FA_MSG_1: 'の外へ ',
        MULTIPLE_SELECT_FDD_FA_MSG_2: ' 選択した添付ファイル レコード ',
        MULTIPLE_SELECT_FDD_FA_MSG_3: ' 添付レコードは FDD または FA タイプの添付レコードであるため削除されません。本気ですか？',
    },
    AB_CAMPAIGNS: {
        NAME_BLANK: 'キャンペーンの名前を入力します。',
        GROUP_FILTER_BLANK_COMMON_MSG: '選んでください',
        FILTER_TYPE_GROUP: 'グループ',
        FILTER_TYPE_CONTACT: 'コンタクトフィルタ',
        FILTER_TYPE_ACCOUNT: 'アカウントフィルタ',
        GROUP_FILTER_BLANK: 'グループを選択します。',
        CONTACT_FILTER_BLANK: '連絡先フィルタを選択してください。',
        ACCOUNT_FILTER_BLANK: 'アカウントフィルタを選択してください。',
        SUBJECT_BLANK: 'このメッセージの件名を入力してください。',
        FROM_NAME_BLANK: '送信者名を入力します。',
        FROM_EMAIL_BLANK: '送信者のメールアドレスを入力してください。',
        GROUP_NAME_BLANK: 'グループ名は空白にすることはできません。',
        AB_DISTRIBUTION: 'a / b分布を入力します。',
        INVALID_AB_DISTRIBUTION: 'a / b分布が無効です。',
        INVALID_WINNING_MARGIN: '勝者のマージンが無効です。',
        MESSAGE_B_NOT_FILLED: 'メッセージBの詳細を入力してください。',
        AB_DISTRIBUTION_OUT_OF_RANGE: 'テストのために1から99の間の値を入力してください ',
        DAYS_BLANK: '残りのリストに優勝メッセージを送る前に何日待ちたいか入力してください。',
        INVALID_DAYS: '無効な入力です。残りのリストに優勝メッセージを送る前に何日待ちたいか入力してください。',
        SAVE: 'このキャンペーンは正常に作成されました。',
        UPDATE: 'このキャンペーンを正常に更新しました。',
        EXECUTION_CANNOT_CANCEL_1: '実行を今キャンセルすることはできません。',
        EXECUTION_CANCEL_MSG: 'アプリケーションはすぐに実行を取り消そうとします。',
        CONFIRM: 'ご確認ください。',
        EXECUTION_CANNOT_CANCEL_2: '既に処理中のキャンペーンはキャンセルできません。',
        CAMPAIGN_EXECUTE_IN_60_MIN: 'このキャンペーンは60分後に実行されます。',
        OK_TO_CONFIRM: 'OKをクリックして確認します',
        ERROR_MSG_1: 'テンプレートを選択してください。',

        //not found in doc

        ZERO_WINNING_MARGIN: '勝者のマージンを入力します',


    },
    REPEAT_DIALOG: {
        END_DATE_BLANK: '終了日は空白にすることはできません。',
        INVALID_END_DATE: '開始日は終了日より長くても同じであってもかまいません。',
        DAY_CHECKBOX_BLANK: '少なくとも1日はチェックしてください。',
        DELETE_RECUR_TITLE: '反復削除',
        EDIT_RECUR_TITLE: '繰り返し編集',
        REPEAT: '繰り返す'
    },
    BATCH_ACTION: {
        CONVERT_SUCCESSFULL: ' 正常に変換されました。',
        SINGLE_CONVERT_SUCCESSFULL: ' 変換されました。',
        CONVERT_UNSUCCESSFULL: '実行できないアクション',
        CONVERT_SAME_STAGE: ' レコードはすでに入っています ',
        MULTI_SHARE_SUCCESSFULL: ' 正常に共有されました。',
        SINGLE_SHARE_SUCCESSFULL: ' が正常に共有されました。',
        SHARE_UNSUCCESSFULL: '実行できないアクション',
        MULTI_ASSIGN_SUCCESSFULLY: ' 割り当てられました。',
        SINGLE_ASSIGN_SUCCESSFULLY: ' が正常に割り当てられています。',
        ASSIGN_UNSUCCESSFULL: '実行できないアクション',
        COMPLETE_SUCCESSFULL: ' 正常に完了しました。',
        BATCH_COMPLETE_SUCCESSFULL: ' 正常に完了しました。',
        COMPLETE_UNSUCCESSFULL: '実行できないアクション',
        GROUP_ADDED_SUCCESSFULLY: 'グループに追加されました',
        GROUP_REMOVED_SUCCESSFULLY: 'すべてのグループから削除されました。',
        GROUP_CLICK_TO_CONFIRM: ' 選択したグループに追加されます。 「OK」をクリックして確認します。',
        GROUP_BLANK_ADDED_BATCH: '少なくとも1つのグループを選択してください。',
        NOTE_TXT_CANNOT_BE_BLANK: 'テキストを空白にすることはできません。',
        WORKFLOW_CONFIRMATION: 'このレコードのワークフローが変更されます。 [OK]をクリックして確認します。',
        BATCH_WORKFLOW_CONFIRMATION: 'これらのレコードのワークフローが変更されます。 [OK]をクリックして確認します。',
        WORKFLOW_APPLIED: 'ワークフローが正常に適用されました。',
        RECORDS_WITH_DIFFERENT_WORKFLOW: '異なるワークフローを持つレコードを選択しました。続行してワークフローの適用操作を実行するには [OK] をクリックし、レコードを再度選択する場合は [キャンセル] をクリックします。',
        RECORDS_WITH_DIFFERENT_WORKFLOW_STATUS: '別のステータスのレコードを選択しました。続行してワークフローの適用操作を実行するには [OK] をクリックし、レコードを再度選択する場合は [キャンセル] をクリックします。',
        ACTIVE_SUCCESSFULL: 'ステータスが[アクティブ]に変更されました。',
        INACTIVE_SUCCESSFULL: 'ステータスが[非アクティブ]に変更されました。',
        BATCH_EDIT_BLANK: '値フィールドを空白にすることはできません。',
        INVALID_WORKFLOW_STAGE: 'レコードを変換することはできません。選択したレコードのステータスは、現在のワークフローでは使用できません。 [Ok]を押してステータスまたはワークフローを変更します',
        CONFIRMATION_DELETE_MSG: ' 削除されます。本気ですか？',
        CONFIRMATION_REMOVE_MSG: ' 削除されます。本気ですか？',
        CONFIRMATION_UNLINK_MSG: ' リンクされません。本気ですか？',
        CONVERT_ALERT_MSG: ' に変換されます ',
        CLICK_TO_CONFIRM: '[OK]をクリックして確認します。',
        ASSIGN_ALERT_MSG: ' レコードが割り当てられます。 ',
        ROUND_ROBIN_BASIS: ' ラウンドロビン方式で本気ですか？',
        CONFIRM_MSG: '本気ですか？',
        RECORDS: '記録',
        RECORD: '記録',
        ALL_RECORDS: 'すべてのレコード',
        RECORD_WILL_BE_UPDATED: 'レコードが更新されます',

        //not found in doc

        UNSUBSCRIBE: '選択した連絡先を正常に購読解除しました。',
        USE_ASSIGN_RULE_ALERT_MSG: ' レコードは、営業担当に割り当てられます (s) ',
        EMPTY_RECYCLE_BIN: 'すべてのレコードはごみ箱から完全に削除されます。本気ですか？',
        PERMANENT_DELETE_MSG: ' 完全に削除されます。本気ですか？',
        INVALID_STATUS_FOR_WORKFLOW_STAGE: '選択したレコードのステータスは、現在のワークフローでは使用できません。 [OK]を押して、レコードのステータスを変更します。',
        CONTACT_STATUS_UPDATE_SUCCESS: ' 状態は正常に更新されました。',
        PUBLISH_SUCCESSFULL: 'ステータスが公開に変更されました。',
        UNPUBLISH_SUCCESSFULL: 'ステータスが非公開に変更されました。',
        RECORDS_NOT_IN_WORKFLOW: '選択したすべてのレコードが連絡先ワークフローに含まれていません。',
        RECORD_NOT_IN_WORKFLOW: '選択したレコードが連絡先ワークフローに含まれていないため、変換できません。'
    },
    BATCH_ACTION_VALIDATION: {
        ADDTOGROUP_BLANK: '選択したレコードはすべてのグループから削除されます。 「OK」をクリックして確認します。',
        ADDTOGROUP_NAMEBLANK: 'グループ名を空白にすることはできません。',
        SHARE_WITH_BLANKUSER: '少なくとも1人のユーザーを選択してください。',
        ASSIGN_BLANKUSER: 'ユーザーを選択してください。',
        CONVERT_SELECT_STATUS: 'ステータスを選択してください ',
        MAP_INSUFFICIENT_INFO: 'マップを表示するための情報が不十分です',
        GROUP_ALREADY_EXIST: 'グループ名は既に存在します。',

        //not found in doc

        ALREADY_ACTIVE: 'すでにアクティブ状態になっています。',
        ALREADY_INACTIVE: 'レコードは既に非アクティブ状態です。',
        ALREADY_PUBLISH: 'すでに公開ステータスのレコード。',
        ALREADY_UNPUBLISH: 'レコードはすでに非公開ステータスになっています。',
    },
    ATTACHMENT: {
        ATTACHMENT_UPLOAD: ' 添付ファイルが正常にアップロードされました',
        ATTACHMENT_LINK_ADDED: 'ドキュメントリンクが正常に追加されました。',
        ATTACHMENTS_UPLOAD: ' 添付ファイルが正常にアップロード',
        VALIDATE_ATTACHMENT: '添付するファイルを選択してください  ',
        ATTACHMENT_UPLOAD_FAILURE: ' このファイルは既に存在します。',
        VALIDATE_EMPTY_FILE: ' アップロードファイルのサイズを0にすることはできません ',
        VALIDATE_FILE_SIZE: 'アップロードファイルのサイズは 5 mb 以上にすることはできません',
        VALIDATE_LOGO_SIZE: '選択した写真が大きすぎて使用できません。許可されるファイルの最大サイズは 500 KB です。',
        VALIDATE_BLANK_FILE: 'あなたは、空のファイルをアップロードすることはできません',
        VALIDATE_LOGO_EXTENSION: 'jpg、gif または png ファイルを選択してください!',
        ATTACHMENT_LOGO_TEXT: 'jpg、gif、または png ファイルをアップロードすることができます。',
        ATTACHMENT_LOGO_SIZE_TEXT: ' 許可されるファイルの最大サイズは 500 KB です。',
        UPLOAD_IMAGE: '画像をアップロードする',
        UPLOAD_FILE: 'アップロードするファイルを選択する（一度に5ファイルまで）',
        ADD_LINK: '外部リンクを追加する',
        VALIDATE_MAX_ATTACHMENTS: 'あなたは、5つ以上のファイルをアップロードすることはできません',
        SELECT_IMAGE_TO_UPLOAD: 'アップロードする画像を選択',
        MAX_SIZE_2_MB_ALERT: 'アップロードファイルのサイズは2 MBを超えることはできません',
        VALID_FILE_UPLOAD: 'コンピュータからjpg、gif、またはpngファイルをアップロードできます。',
        MAX_SIZE_2_MB: '許可される最大ファイルサイズは2 MBです。',
        CANNOT_UPLOAD_EXECUTABLE_FILE: '実行可能ファイルをアップロードすることはできません',
        THE_ATTACHMENT: 'アタッチメント',
        DOCUMENT_LINK: 'ドキュメントリンク', 


        //not found in doc

        ATTACHMENT_UPLOAD_IMAGE: '画像が正常にアップロードされました',
        FOLDER_NAME: 'フォルダ名',
        FILE_NAME:'ファイル名',
        ALREADY_EXIST: '既に存在します。',
        CANNOT_BE_DELETED: '削除できません。',
        CLICK_TO_DELETE: '[OK] をクリックして削除 ',
        EMPTY_FOLDER_ERROR_DOWNLOAD: 'フォルダが空であるため、ダウンロードできません。',
        EMPTY_FOLDER_ERROR_PUSH: 'フォルダが空であるため、プッシュできません。',
        RENAME_SUCCESSFULLY: 'フォルダの名前が正しく変更。',
        DELETE_SUCCESSFULLY: 'フォルダが正常に削除。',
        ADDED_SUCCESSFULLY: '正常に追加。',
        DOCUMENT_LINK_ADDED_SUCCESSFULLY: 'ドキュメントリンクが正常に追加されました。',
        DOCUMENT_LINK_UPDATE_SUCCESSFULLY: 'ドキュメント リンクが正常に更新されました。',
        DOCUMENT_LINK_DELETE_SUCCESSFULLY: 'ドキュメント リンクが正常に削除されました。',
        FILE_ADDED_SUCCESSFULLY: 'ファイルが正常に追加されました。',
        FILE_RENAME_SUCCESSFULLY: 'ファイルの名前が正常に変更されました。',
        FILE_DELETE_SUCCESSFULLY: 'ファイルが正常に削除されました。',
        IMAGE_RENAME_SUCCESSFULLY: 'イメージの名前が正常に変更されました。',
        IMAGE_NAME: 'イメージ名',
        MAX_SIZE_50_MB: '許可される最大ファイルサイズは50MBです。',
    },
    AUTOMATION_DESIGNER: {
        STEPPER_BACK_MESSAGE: 'あなたはテンプレート情報ページに行きたいですか？未保存の変更はすべて失われます。',
        MAX_CONNECTION: "最大番号接続の数に達しました。",
        TOUCH_CAMPAIGN_NAME: "このタッチキャンペーンの名前を選択してください",
        SELECT_FILTERS: "を選択してください",
        SELECT_FILTERS_A: "を選択してください",
        START_DATE: "の開始日を選択してください",
        EMPTY_WEBSITE_LINK: "あなたは空のウェブサイトのリンクを追加することはできません。",
        CANNOT_START_WITH_DECISSION: '決定から開始することはできません。左の「スタート」メニューのいずれかのオプションから始めてください。',
        CANNOT_START_WITH_ACTION: 'アクションから開始することはできません。左の「スタート」メニューのいずれかのオプションから始めてください。',
        CONNECT_WITH_PROPER_NODE: '適切なノードで接続する',
        FROM_NAME: 'の名前を入力してください',
        FROM_EMAIL: '差出人メールアドレスを選択してください',
        RESEND_AFTER: '再送信を日の後に入力してください',
        SELECT_WEB_FORM: 'web フォームを選択してください',
        SELECT_GROUP: 'グループを選択してください。',
        SELECT_GROUP_OPERATION: 'グループ操作を選択してください。',
        ENTER_NOTE: 'メモを入力してください。',
        SELECT_ONE_RECIPIENT: '少なくとも1人の受信者を選択してください',
        SELECT_USER: 'ユーザーを選択してください。',
        ENTER_TASK_NAME: 'タスク名を入力してください',
        PROVIDE_DUEBY_FIELD: '[期日] フィールドに値を入力してください。',
        ENTER_LINK: 'リンクを入力してください。',
        PROVIDE_VALID_URL: '有効なウェブサイトの url を提供しなさい!',
        CATCH_ALL_LINK_CONNECTION: 'あなたはすでに "キャッチすべて" リンクをクリックして条件を持っている',
        ENTER_SCORE: 'スコアを入力してください',
        INVALID_SCORE: '無効なスコアを入力しました',
        ENTER_SUBJECT: '件名を入力してください',
        ENTER_BODY: '本文の内容を入力してください',
        ENTER_INTERVAL_VALUE: '間隔の値を入力してください',
        SELECT_ONE_HOUR: '1時間以上の間隔を選択してください。',
        BLANK_CAMPAIGN_NAME: 'キャンペーン名を空白にすることはできません。',
        CAMPAIGN_SAVED_SUCCESSFULLY: 'このキャンペーンは正常に保存されました',
        BLANK_CANVAS: '空白のキャンバスを保存することはできません。',
        NOT_ROOT_NODE: 'ルートノードにすることはできません。',
        START_DATE_MUST_BE_GREATER: '開始日は、以前のタッチキャンペーンよりも大きくする必要があります。',
        EDIT_AND_COMPLETE_DETAILS: '編集して、詳細を完了してください',
        PROVIDE_WAIT_INTERVAL: '待機間隔をゼロより大きくしてください。',
        CHECK_INTERVAL_WITH_PREVIOUS_TOUCH_CAMPAIGN: '以前のタッチキャンペーンの再送信日の間隔を確認してください。',
        CHECK_START_DATE_OR_WAIT_BEFORE_SAVE_TC: 'タッチキャンペーンを保存する前に、開始日を確認してください。',
        PROVIDE_START_DATE: '開始日を入力してください。',
        INTERVAL_EXPEXT_ZERO: 'ゼロ以外の間隔を指定してください。',
        EMPTY_LINK_NODE: '空のリンクノードを追加することはできません。',
        DELETE_NODE_CONFIRMATION: 'ノードが削除されます。続けたいですか？',
        MSG_CANT_BE_DELETED: 'このメッセージは送信されており、削除することはできません。',
        FIRST_MSG_CANT_BE_DELETED: 'タッチキャンペーンの最初のメッセージを削除することはできません',
        FIRST_WAIT_CANT_BE_DELETED: 'タッチキャンペーンの最初の待機を削除することはできません',
        BLANK_INTERVAL: '間隔を空白にすることはできません',
        PROVIDE_POSITIVE_INTERVAL: '0より大きい間隔を指定してください',
        WONT_BE_ABLE_TO_DELETE: 'あなたはそれを削除することはできません。',
        URL_NOT_EXIST: 'あなたが提供しているURLは電子メール本文では利用できません。',
        GROUP_NAME_BLANK: 'グループ名は空白にすることはできません。',
        SELECT_LANDING_PAGE: 'リンク先ページを選択してください',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: '既に1つのオープンオートメーションデザイナがあります。閉じるか保存して、新しいオートメーションデザイナを開きます。',
        SAVE_DIAGRAM_BEFORE_COPY: 'コピー操作を行う前に図を保存してください。',
        NO_TOUCH_FOUND: 'タッチキャンペーンは見つかりませんでした。',
        ADD_ONE_FIELD: '少なくとも1つのフィールドを追加してください',
        SELECT_FIELD_VALUE: 'フィールド値を選択してください',
        INVALID_URL: '無効なURLです。',
        INVALID_INTEGER: '整数ではありません。',
        INVALID_EMAIL: '無効な電子メールアドレスです。',
        ALLOWED_UPTO_FOUR_FIELDS: '更新は4フィールドまで可能です。フィールド値のペアの別の行を追加することはできません。',
        WILL_BE_UPDATED: '更新されます。本気ですか？',
        DUPLICATE_TOUCH_NAME: 'あなたはすでにSend Touchキャンペーンを持っています',
        TOOLTIP_MESSAGE_SEND_MAIL: '一連のメールを送信するタッチキャンペーンから始めましょう。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_WEB_FORM: 'まず、Webフォームの送信後に何が起こるかを定義します。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_LANDING_PAGE: 'ランディングページが送信された後に何が起こるかを定義することから始めます。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_WEBSITE_VISIT: '連絡先があなたのウェブサイトの特定のページにアクセスした後に何が起こるかを定義することから始めます。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_LINK_CLICK: 'あなたの電子メールのリンクが連絡先によってクリックされた後に何が起こるかを定義します。これを使用するには、それをキャンバスの上にドラッグアンドドロップし、タッチメッセージで接続します。',
        TOOLTIP_MESSAGE_EMAIL_OPEN: '連絡先がタッチメッセージを開いた後の処理を定義します。これを使用するには、それをキャンバスの上にドラッグアンドドロップし、タッチメッセージで接続します。',
        TOOLTIP_MESSAGE_UNSUBSCRIBE: '連絡先がタッチメッセージから退会した後に何が起こるかを定義します。これを使用するには、それをキャンバスの上にドラッグアンドドロップし、タッチメッセージで接続します。',
        TOOLTIP_MESSAGE_SCORE_REACHED: '連絡先がスコアのしきい値に達した後に何が起こるかを定義します。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_AUTO_MAIL: '電子メールの送信は、一時電子メールとタッチ電子メールの両方の送信に使用されます。このアクションは、タッチキャンペーン、決定、またはアクションの後に追加できます。',
        TOOLTIP_MESSAGE_CHANGE_GROUP: 'グループからアカウントと連絡先を追加または削除する。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_ADD_NOTE: 'アカウントにメモを追加します。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_NOTIFY: 'CRMユーザに通知します。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_ADD_TASK: 'タスクを別のユーザーに割り当てます。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_UPDATE_FIELD: 'アカウントまたは連絡先のフィールドの値を更新します。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_ASSIGN: 'アカウントを別のユーザーに再割り当てします。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_CONVERT: 'アカウントを新しいステータスに変換します。これを使用するには、キャンバスの上にドラッグアンドドロップします。',
        TOOLTIP_MESSAGE_SEND_SMS: 'Send SMS to a group, account filter or contact filter. To use this, drag and drop it on the canvas to the right.',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'あなたはすでに名前のついたタッチキャンペーンを持っています',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_2: '別の名前を入力してください。 ',
        INTERVAL_MODE_ADD_MSG_1: '提供してください',
        INTERVAL_MODE_ADD_MSG_2: '0以外の前の値の後',
        AUTOMATED_CAMPAIGN_TITLE: '自動化されたキャンペーン',
        CANNOT_CONNECT_MULTIPLE: '複数接続できません ',
        CONNECTION_EXIST: 'あなたはすでにアクティブな接続を持っています ',
        REMOVE_CONNECTION_ALERT: '接続を削除すると、次のすべての手順が失われます',
        CONTINUE_ALERT: '続けたいですか？',
        CANNOT_ADD: 'あなたは追加できません',
        DIRECT_UNDER: '直接の下で',
        DIRECT_UNDER_AN: '直下',
        MULTIPLE_TIME_SELECTED_MSG_1: 'あなたは',
        MULTIPLE_TIME_SELECTED_MSG_2: 'フィールドを複数回更新して更新します。重複する行を削除してください。',
        CANNOT_BE_LEAF_NODE: 'リーフノードにすることはできません。',
        CLICK: 'クリック',
        HERE: 'ここに',
        PURCHASE_MORE: 'より多くを購入する。',
        CREDITS_REMAINING: '残りの電子メールクレジット',
        STOP_CAMPAIGN: '変更を加えるためにキャンペーンを停止します。',
        SELECT_EMAIL_TEMPLATE: '電子メールテンプレートを選択する',
        NO_INFO_EXISTS: '情報がありません',
        QUERY_FILTER_BLANK: 'クエリを選択してください',
        CONTACT_FILTER_BLANK: '連絡先フィルタを選択してください。',
        ACCOUNT_FILTER_BLANK: 'アカウントフィルタを選択してください。',
        //not found in doc

        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'コピーするレコードを1つ選択してください。',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: '編集するレコードを1つ選択してください。',
        CANNOT_SAVE_CAMPAIGN: 'このキャンペーンを保存できません。エラーのキャンペーンの赤いブロックを参照してください.',
        ADD_GROUP_CLICK: 'クリック',
        ADD_GROUP_CLICK_HERE: 'ここに',
        CLICK_TO_ADD_NEW_GROUP: '新しいグループを追加する',
        MESSAGE_SAVED_SUCCESSFULLY: 'このメッセージを正常に保存しました',
        MESSAGE_NOT_SAVED_SUCCESSFULLY: 'メッセージが正常に保存されていません',
        SAVE_AUTOMATION_FOR_TEST: 'オートメーションキャンペーンを保存してください。',
        BIRTHDAY_CAMPAIGN_NAME: 'この誕生日キャンペーンの名前を選択してください',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'あなたはすでに名前の誕生日キャンペーンを持っている',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_2: '別の名前を入力してください。 ',
        BIRTHDAY_FIRST_MSG_CANT_BE_DELETED: '誕生日キャンペーンの最初のメッセージを削除することはできません。',
        MIGRATION: 'この自動キャンペーンは新しいキャンペーンビルダーにアップグレードされています。お待ちいただいてありがとうございます。',
        MIGRATION_DIALOG_TITLE: 'アップグレード中...',
        MIGRATION_NOTE: '注：更新後、自動化キャンペーンは、開始操作を実行した日に実行を開始します。',
        BIRTHDAY_CAMPAIGN_NO_REPORT: 'まだ受信者に送信されていない誕生日キャンペーン',
        RUNNING_CAMPAIGN_CANNOT_CANCEL: '実行中のキャンペーンはキャンセルできません。',
        CONFIRM_CANCEL_SCHEDULE: 'スケジュールされたキャンペーンをキャンセルしますか？',
        CAMPAIGN_SCHEDULE_SUCCESSFULLY: 'このキャンペーンは正常にスケジュールされました。',
        SCHEDULED_CAMPAIGN_CANCEL_SUCCESSFULLY: 'このキャンペーンは正常にキャンセルされました。',
        CAMPAIGN_START_IMMEDIATELY: 'このキャンペーンはすぐに開始されるように正常に設定されました。',
        ENTER_FROM_NAME: '名前から入力してください。',
        ENTER_FROM_EMAIL: 'メールから入力してください。'
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME_BLANK: 'トラッキングコードを生成するためにドメイン名を入力してください。',
        DOMAIN_NAME_INVALID: '有効なドメイン名を入力してください。',
        TRACKING_CODE_BLANK: 'コピーするトラッキングコードはありません。',
        TRACKING_CODE_ALREADY_GENERATED: 'トラッキングコードは既に生成されています。',
        DONE: 'あなたのドメイン名は登録されています。',
        SET_UP_INFO: 'このコードをコピーして、サイトのすべてのページに、最後の本文タグの直前に貼り付けます。完了したら、このページに戻り、完了ボタンをクリックします。'
    },
    MAIL: {
        SUCCESS: 'あなたのメールは送信されました。',
        BLANK_FROM_EMAIL: 'メールからは空白です。',
        INVALID_FROM_EMAIL: '差出人フィールドに無効な電子メールアドレスがあります。',
        INVALID_CC_EMAIL: 'CCフィールドに無効なCC電子メールアドレスがあります。',
        INVALID_BCC_EMAIL: 'BCCフィールドに無効なBCC電子メールアドレスがあります。',
        BLANK_SUBJECT: '件名は空白です。',
        BLANK_EMAIL_BODY: 'メール本文は空白です。',
        BATCH_SEND_MAIL_MSG_1: 'の外へ',
        BATCH_SEND_MAIL_MSG_2: 'あなたが選択しただけです',
        BATCH_SEND_MAIL_MSG_3: 'あなたのメールを受け取ることを選んだ。私たちはこれらのメールだけにメールを送ります',
        BATCH_SEND_MAIL_MSG_4: 'のどれも',
        BATCH_SEND_MAIL_MSG_5: 'あなたのメールを受け取ることを選んだ。このメールは送信しません。このタブを閉じてください',
        NO_RECIPIENT: 'あなたはこのメールの受信者を入力していません',

        //not found in doc

        NO_RECIPIENT_FOUND: 'このメールの受信者は見つかりませんでした。',
        BLANK_TO_EMAIL: '[宛先]フィールドに電子メールアドレスがありません。',
        INVALID_TO_EMAIL: '[宛先]フィールドに無効なメールアドレスがあります。',
        SCHEDULE_SUCCESS: 'メールは正常にスケジュールされました。',
        RESCHEDULE_SUCCESS: '電子メールは正常に再スケジュールされました',
        TEMPLATE_NOT_SELECTED: 'Please select a template',
        NO_ACTIVE_AGREEMENT_TEMPLATE_MSG: '有効な契約テンプレートがありません。',
        SELECT_DIFFERENT_SIGNER: "Please select a different signer.",
        SELECT_ALL_SIGNERS: 'Please select all the signer(s) to send the agreement email.',
    },
    APPOINTMENTS_TASKS: {
        DELETE: 'このレコードは削除されます。本気ですか？',
        INVALID_START_END_DATE: '開始日は終了日より長くても同じであってもかまいません。',
        RECURR_DELETE_DIALOG_ONLY_BTN_INFO: 'シリーズ内の他のすべてのレコードはそのまま残ります。',
        RECURR_DELETE_DIALOG_FOLLOWING_BTN_INFO: 'これと次のすべてのレコードが削除されます。',
        RECURR_DELETE_DIALOG_ALL_BTN_INFO: 'シリーズ内のすべてのレコードが削除されます。',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_1: '他のすべて',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_2: 'シリーズでは同じままです。',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_1: 'これと以下のすべて',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_2: '変更されます。',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_3: '未来への変化',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_4: '失うだろう。',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_1: 'すべて',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_2: 'シリーズでは変更されます。',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_3: '他の変更',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_4: '維持されます。',
        RESCHEDULE_TASK_SUCCESS: 'タスクが正常に再スケジュールされました',
        RESCHEDULE_APPOINTMENT_SUCCESS: 'アポイントメントが正常に再スケジュールされました',
        NO_TASK_FOUND: 'タスクが見つかりません',
        NO_APPOINTMENT_FOR_TODAY: '今日予約可能な予約はありません',
        NO_PENDING_TASK_FOR_TODAY: '今日利用可能な保留タスクはありません',
        NO_TASK_APPOINTMENT_FOUND: '保留中のものはありません。',
        NO_TASK_APPOINTMENT_FOUND_FOR_COMPLETED: '何も完了していません。',
        //not found in doc

        BLANK_START_END_DATE: '開始日と終了日は空白にできません',
        BLANK_START_DATE: '開始日は空白にできません',
        BLANK_END_DATE: '終了日は空白にすることはできません',
        BLANK_NO_OF_DAYS: '日数は空白にすることはできません。',
        ZERO_NO_OF_DAYS: '日数は0にできません。',
        BLANK_SPECIFIC_DATE: '日付は空白にすることはできません。',
        INVALID_DUEBY_END_DATE: '期日によって、繰り返し終了日と同じまたは同じにすることはできません。',
        SEND_EMAIL_PROGRESS_MSG: 'メール送信中です。しばらく時間がかかります。',
        INVALID_DUEBY_START_DATE: '期限日は開始日より大きい、または同じにすることはできません。',
        SYNC_STARTED_MSG: '同期が開始されました。新しい予定を表示するには、カレンダーを更新してください。',
        SYNC_STARTED_MSG_FOR_SETUP: '同期が開始されました。あなたのカレンダーはまもなく更新されます。',
        ENABLE_SYNC_CAL: '同期を行う前に統合設定の下で同期カレンダーオプションを有効にしてください。',
        PRIVATE_APPOINTMENT_ALERT:"これはプライベートアポイントメントです。これを開くことはできません。",
        START_END_DATE_VALIDATE:'より大きいまたは同じにすることはできません',
        SEARCH_NO_CHECKBOX_SELECTION: 'チェックボックスが選択されていない場合、検索は実行できません。',
        CONVERT_CONFIRMATION_MESSAGE: '1 つ以上のタスクがチェックされません。これらのタスクをチェックせずに変換操作を続行しますか?',
        VALID_SOURCE_LIST: 'ソースフィールドを選択してください。',
        LMS_USER_NOT_AVAILABLE: 'LMS はまだ有効化されていません。管理者に連絡してください。',   
        ADD_BRANCH_BILLINGMASSAGE:'Atualmente você não tem licenças. Você comprará uma nova licença para esta unidade por US$ 40,00/mês. Além disso, será cobrada uma taxa de instalação de US$ 100,00 para configurar e treinar o novo inquilino.'
    },
    TASKS:{
        DELETE_CANCELLED_STATAUS_RESTRICT_MSG:'是正措置タスクをキャンセルすることはできません。',
    },
    HOME: {
        FILTER_ADD: 'フィルタを追加しました',
        FILTER_NOT_FOUND: 'フィルタを設定していません',
        FILTER_DELETE: 'フィルタが正常に削除',

        //not found in doc

        FILTER_DELETE_RECORD: 'フィルタは削除されます。本気ですか？',
        FILTER_ON_MAX_FIELD: '最大フィールド条件に達しました。私たちは最大10のフィールドを許可します。',
        ASSIGNMENT_RULES_ON_MAX_FIELD: '最大のフィールド条件に達しました。最大 20 フィールドを許可します。',
        FILTER_NAME_BLANK: 'フィルタ名を入力してください。',
        FILTER_NAME_MAX_LENGTH: 'フィルタ名は64文字以下にする必要があります。',
        FILTER_CRITERIA_EMPTY: '少なくとも1つの基準を指定してください。',
        FILTER_SQL_ERROR_NO_VALUE: ' 空白にすることはできません。',
        FILTER_SQL_ERROR_INTEGER: ' 整数ではありません。',
        FILTER_SQL_ERROR_FLOAT: ' 浮動小数点ではありません  ',
        FILTER_SQL_ERROE_ALREADY_EXISTS: 'フィルタ名が既に存在します。',
        FILTER_DELETE_MSG_TC: '以下のタッチキャンペーン ',
        FILTER_DELETE_MSG_BC: ' 次の放送キャンペーン ',
        FILTER_DELETE_MSG_AB: ' 以下のA / Bキャンペーンは、',
        FILTER_DELETE_MSG_AND: ' そして ',
        FILTER_DELETE_MSG_BEING_USED: ' それが使用されているときに削除することはできません ',
        ADD_GROUP_SUCCESS: 'グループが正常に追加されました。'
    },
    DOCUMENT_LIBRARY: {
        INVALID_UPLOAD_ERROR: 'イメージファイルを選択してください！',
        CONFIRM_DELETE_MSG: 'この画像を削除してもよろしいですか？',
        OVER_MAX_SIZE: '選択したファイルは大きすぎるので、使用できませんでした。最大ファイル サイズは 50 MB です。',
        OVER_MAX_SIZE_IMAGE: '選択したファイルは使用するには大きすぎます。許可されている最大ファイルサイズは500 KBです',
        INVALID_DOCUMENT_UPLOAD_ERROR: 'exe、bat、shドキュメントはアップロードできません！',
        DOCUMENT_FILE_EMPTY_ERROR: 'ドキュメントを選択してください！',
        ASSETS_ALREADY_OPEN_ALERT: '[サーバー構成の管理モジュール] の下の [ライブラリ] タブは既に開いています。',
        DUPLICATE_IMAGE_NAME: 'この名前のイメージは既に存在します。別の画像を選択してください!',
        DUPLICATE_DOCUMENT_NAME: 'この名前のドキュメントは既に存在します。別のドキュメントを選択してください。',
        UPLOAD_IMAGE: 'アップロードする画像を選択します（一度に最大5つのファイル）。',
        ASSETS_ALREADY_OPEN_ALERT_HUB: 'ハブ モジュールの下に [ライブラリ] タブが既に開いています。',
        BLANK_TITLE_ERROR : 'タイトルを空白にすることはできません',
        BLANK_URL_ERROR : 'リンクを空白にすることはできません',
        INVALID_URL_ERROR : '有効な URL を入力してください',
        CONFIRM_FILE_TYPE_CHANGE: {
            LN1: '現在のセクションを終了しますか? 保存されていない変更は失われます。',
            LN2: '[OK] をクリックして確認し、終了します。',
            LN3: '現在のセクションに留まるには、[キャンセル] をクリックしてください。',
        },
    },
    MERGE: {
        NOT_URL: "無効なURLです。",
        NOT_FLOAT: "浮動小数点ではありません。",
        NOT_INTEGER: "整数ではありません。",
        NOT_EMAIL: "無効な電子メールアドレスです。",
        MERGE_TENANT_EMAIL_ALERT_MESSAGE: 'これらのレコードをマージすることはできません。削除する有料アカウントを選択したので！',
    },
    SCHEDULE: {
        EMPTY: 'スケジュールの日付を選択してください',
        CAMPAIGN_SCHEDULE: 'このキャンペーンは',
        CONFIRM: '[OK]をクリックして確認します。',
        SMS_SCHEDULE: 'このテキストメッセージは送信します'
    },
    RELATIONSHIP: {
        BLANK_RECORD: "関係を選択してください",
        BLANK_PARENT_RELATION: "少なくとも1つの親関係を選択してください。",
        BLANK_CHILD_RELATION: "少なくとも1つの子関係を選択してください",
        NO_PARENT_RELATION: "あなたは関係がありません。すべての設定からカスタマイズして関係を作成してください",
        NO_PARENT_RELATION_FOR_USER: "申し訳ありませんが、利用可能な関係はありません。あなたの管理者に連絡してください",
        NO_RELATIONSHIP_SETUP: "まだ関係タイプを設定していない",
        PLACEHOLDERTEXT_COMPANY: "会社名を入力し、Enterキーを押します。",
        PLACEHOLDERTEXT_NAME: "フルネームを入力し、Enterキーを押します。",
        PLACEHOLDERTEXT_EMAIL: "電子メールを入力してEnterを押す",
    },
    GROUP: {
        NO_TOUCH_CAMPAIGN: "タッチキャンペーンでは使用されません。",
        NO_BROADCAST_CAMPAIGN: "ブロードキャストキャンペーンでは使用されません。",
        NO_AB_CAMPAIGN: "A / Bキャンペーンでは使用されません",
        NO_WEBFORM: "Webフォームでは使用されません。",
        NO_LANDINGPAGE: "ランディングページでは使用されません。",
        DELETE_MSG: '次のグループは削除できません',
        USED_IN_TOUCH_CAMPAIGN: '次のタッチキャンペーンで使用されます。',
        USED_IN_BROADCAST_CAMPAIGN: '次のブロードキャストキャンペーンで使用されます。',
        USED_IN_AB_CAMPAIGN: '次のA / Bキャンペーンで使用されます。',
        USED_IN_WEBFORM: ' 次のWebフォームで使用されます。',
        USED_IN_LANDINGPAGE: '次のリンク先ページで使用されます',
        SELECT_GROUP: '1つまたは複数のグループを選択する',
        NO_GROUP_AVAILABLE: '利用可能なグループはありません。クリック',
        SELECT_RECORDS: '選択されたレコード',
        ALL_RECORDS: 'すべてのレコード',
        SELECT_OPTIONS: 'オプションを選択',
        ADD_NEW_GROUP: '新しいグループを追加する',

        //not found in doc

        GROUP_DELETE_RECORD: 'グループは削除されます。本気ですか？',
        COMMON_DELETE_MSG:" 次のキャンペーンで使用されているため削除できません:",
    },
    LOGIN: {
        VALIDATE_EMPTY_USER_NAME: 'あなたのメールアドレスとパスワードを入力してください',
        VALIDATE_EMPTY_PASSWORD: 'パスワードを入力してください',
        VALIDATE_USERNAME_PASSWORD: 'あなたは間違った電子メールまたはパスワードを入力しました',
        INVALID_RESPONSE: '無効な応答',
        LOGIN_COPYRIGHTS_TEXT_1: '著作権',
        LOGIN_COPYRIGHTS_TEXT_2: 'Soffront Software、Inc.すべての権利を保有します。',
        BEST_VIEWED: 'Google Chrome、Mozilla Firefox、Safariで最もよく表示されます。'
    },
    AUDIT_LEADSCORE: {
        NO_AUDIT_DATA: '監査証跡レコードが見つかりません',
        NO_SCORE_HISTORY: '表示する履歴はありません。'
    },
    DETAIL_VIEW: {
        CHANGE_PRIMARY_CONTACT_ALERT_1: '選択した',
        CHANGE_PRIMARY_CONTACT_ALERT_2: '主な連絡先として',
        CLICK_TO_CONFIRM: '[OK]をクリックして確認します。',
        MAX_SELECTED_FIELD_LIMIT: '選択したフィールドの最大数が50に達しました',
        SELECT_ATLEAST_ONE_FIELD: '少なくとも1つのフィールドを選択してください ',
        SELECT_ATLEAST_ONE_CHILD_OBJECT: '少なくとも1つの子オブジェクトを選択してください。',
        MAX_SELECTED_CHILD_OBJECT_LIMIT: '選択したオブジェクトの最大数が10に達しました。',


        //not found in doc

        NO_ASSOCIATED_GROUPS_FOUND: '関連グループはありません',
        UNSUBSCRIBE: 'この連絡先を正常に購読解除しました。',
        BLANK_SPECIFIC_DATE: '日付を空白にすることはできません。',
        CONFIGURE_CHILD_SETUP: '子の設定を構成してください',
        CHILD_SETUP_SELECT_ATLEAST_ONE_FIELD: '子設定用に少なくとも1つのフィールドを選択してください。',
        EMAIL_FORMAT_MSG: " - 無効な電子メールフォーマット。",
        URL_FORMAT_MSG: " - 無効なURLフォーマット。",
        FLOAT_FORMAT_MSG: "- 浮動小数点形式が無効です。",
        INTEGER_FORMAT_MSG: "- 整数形式が無効です。",
        ERROR_MSG: "変更を保存せずに残したいですか？",
    },
    CHARTS: {
        NOTHING_TO_SHOW: 'この時間枠には何も表示されません'
    },
    ADVANCE_SEARCH: {
        SEARCH_FIELDS_NOT_FOUND: '検索するものはありません。値を入力してください。',
        MAX_ADD_FIELD_ALERT:'10フィールド以上で検索することはできません。',
        MIN_ADD_FIELD_ALERT:'検索には少なくとも1つのフィールドが必要です。',
        SELECT_FIELD_ALERT:'値を入力する前にフィールドを選択してください。',
        ADVANCE_SEARCH: '高度な検索',
        EMPTY_DATE: '日付を入力してください。',
        INVALID_DATE_RANGE: '[開始日]は[終了日]より大きくすることはできません',
        EMPTY_FROM_TO_DATE: '開始日と終了日を入力してください。',
        EMPTY_FROM_DATE: '開始日を入力してください。',
        EMPTY_TO_DATE: 'Toの日付を入力してください。'
    },
    PUSH: {
        PUSH_SUCCESS_MSG_1: 'あなたは正常にプッシュしました',
        PUSH_SUCCESS_MSG_2: '選択した会社に送信します。',
        PUSH_SUCCESS_MSG_2_1: 'プッシュされます',
        PUSH_SUCCESS_MSG_3: '選択した会社に電子メール通知が送信されました。',
        PUSH_SUCCESS_MSG_4: '20以上のレコードを選択しました。 一度に最大20のアカウントをプッシュできます。',
        PUSH_SUCCESS_MSG_4_1: '一度に.',
        PUSH_SUCCESS_MSG_5: 'プッシュする会社を選択してください',
        PUSH_SUCCESS_MSG_6: 'プッシュする接続クライアントはありません ',

        //not found in doc

        PUSH_SUCCESS_MSG_7: 'プッシュしました。',
        PUSH_ALERT_MSG_8: '少なくとも1つの会社を選択してメールテンプレートをプッシュしてください',
        PUSH_ALERT_MSG_9: 'オートメーションキャンペーンを推進するために少なくとも1社を選択してください',
        PUSH_ALERT_MSG_10: 'ブロードキャストキャンペーンをプッシュする会社を少なくとも1つ選択してください',
        PUSH_SUCCESS_MSG_11: '選択した会社へ',
        PUSH_SUCCESS_MSG_11_2: '本気ですか?',
        PUSH_SUCCESS_MSG_12: '選択した会社にEメール通知が送信されました。',
        PUSH_SUCCESS_MSG_13: 'セールスリードをプッシュするユニットを少なくとも1つ選択してください',
        PUSH_SUCCESS_MSG_14: '少なくとも1つのユニットを選択して、ワークフローをプッシュしてください',
        PUSH_FOLDER: 'フォルダー',
        FAIL_USE_ASSIGNMENT_RULE_FOR_SALES_LEADS: 'セールスリードをプッシュするための割り当てルールはありません',
        PUSH_ALERT_MSG_12: '契約テンプレートをプッシュするには、少なくとも1つのユニットを選択してください。',
        LANDING_PAGES: 'ランディングページ',
    },
    PROFILE_DETAILS: {
        UNMATCH_NEW_CONFIRM_PASSWORD: '確認されたパスワードが新しいパスワードと一致しません。',
        NO_NEW_PASSWORD: '有効なパスワードを入力して確認します。',
        NO_CONFIRM_PASSWORD: '新しいパスワードを確認します。',

        //not found in doc

        VALIDATE_PROFILE_IMAGE: 'イメージファイルを選択します。',
        PROFILE_IMAGE_UPLOAD: 'プロフィール画像が正常に保存されました。',
        PROFILE_IMAGE_UPLOAD_FAILURE: 'プロフィール画像を保存できませんでした',
        PROFILE_UPDATE: 'プロフィールが正常に更新されました。',
        VALIDATE_PROFILE_IMAGE_SIZE: '選択した写真は大きすぎて使用できません。最大ファイルサイズは500 KBです。',
        PROFILE_IMAGE_ATTACHMENT_LOGO_TEXT: 'jpg、gif、またはpngファイルをアップロードできます。',
        PROFILE_IMAGE_SIZE_TEXT: '最大ファイルサイズは500 KBです。',
        PROFILE_SIGNATURE_AUTOSAVED: '署名を保存しました。'
    },
    CUSTOMIZATION: {
        LOCATION_VAL_NOT_MATCH: '位置の値が一致しません。',
        LOCATION_VAL_BLANK: '場所フィールドには値がありません。'
    },
    IMPORT: {
        SELECT_FILE: 'ファイルを選択してください',
        SELECT_GROUP: 'グループを選択してください',
        SELECT_WORKFLOW: 'ワークフローを選択してください',
        SELECT_STATUS: 'ステータスを選択してください',
        SELECT_SOURCE: 'ソースを選択してください',
        NO_RECORD_TO_IMPORT: 'インポートのためのレコードがありません！',
        NO_MAPPING: 'のマッピングはありません ',
        NOT_ALL_MAPPED: '1つまたは複数の列がCRMにマップされていません。これらの列をインポートせずに続行しますか？',
        IMPORT_CONFIRM_MSG: 'レコードは',
        IMPORT_CONFIRM_MSG_1: 'レコードはインポートされます',
        CONFIRM: 'ご確認ください。',


        //not found in doc

        SOURCR_NAME_EXISTS: '値は既にリストにあります',
        SOURCR_NAME_BLANK: 'ソース名は空白にすることはできません！',
        IMPORT_FILE_EXT: '.csvまたは.xls / xlsx拡張子のファイルを選択してください',
        SELECT_LINK_USING: 'リンクを使用するためのオプションを選択してください',
        SELECT_LINK_WITH: 'リンク先のオプションを選択してください',
        SELECT_DUPLICATE_FIELD: '重複フィールドの少なくとも1つのオプションを選択してください',
        SOURCE_ALERT_MSG_1: 'アカウントの[ソース]フィールドに、マーケティング情報が含まれている列を割り当ててください。',
        SOURCE_ALERT_MSG_2: 'ドロップダウンリストおよびマッピングセクションから[ソース]を選択しました。 Sourceフィールドの値は、2か所ではなく1か所で選択できます。',
        NO_MAPPING_AVAILABLE: '利用可能なマッピングはありません。',
        BLANK_MAP_NAME: '地図の名前を入力してください！',
        VALID_MAP_NAME_LENGTH: '地図の名前は32文字を超えてはいけません。',
        DUPLICATE_MAP_NAME: '地図の名前は既に存在します！',
        MAP_SAVE_SUCCESS: 'フィールドマッピングが正常に保存されました！',
        NO_PREVIOUS_MAPPING: '以前のマッピングはありません！',
        MAP_NAME: '地図の名前',
        DELETE_MAP_ALERT: '消去してもよろしいですか',
        DELETE_MAP_SUCCESS: 'マッピングが正常に削除されました！',
        IMPORT_SUCCESS: 'インポートに成功しました。',
        WARNING: '警告！',
        WARNING_TEXT: 'インポートが完了するまでこのページを閉じないでください',
        IMPORTING_RECORDS: 'あなたのレコードをインポートする',
        SUCCESS_IMPORT_COUNT: 'レコードがインポートされた',
        FAIL_IMPORT_COUNT: 'レコードのインポートに失敗しました',
        FOUND: '私たちが見つかりました',
        DUPLICATES: '重複',
        APPLY_WORKFLOW_TEXT: '輸入レコードへ',
        APPLYING_WORKFLOW: 'ワークフローの適用',
        APPLIED_WORKFLOW: 'ワークフローの適用を完了しました',
        APPLIED_WORKFLOW_TO: '適用されたワークフロー',
        MAX_IMPORT_RECORDS_MSG_1: 'がある',
        MAX_IMPORT_RECORDS_MSG_2: '一度に10000レコードをインポートすることができます。',

        //not found in doc
        VERIFY_CONTACTS_MSG_1: 'これらの連絡先があなたに',
        VERIFY_CONTACTS_MSG_2: '電子メールの許可レンタルまたは ',
        VERIFY_CONTACTS_MSG_3: '購入したリストこれらの連絡先は、',
        VERIFY_CONTACTS_MSG_4: 'あなたからのメールを受信するか、または',
        VERIFY_CONTACTS_MSG_5: 'あなたの組織。',
        FILE_TYPE_MSG: 'CSVファイルまたはXLS / XLSXファイルの最初の行には、フィールド名',
        DATE_FORMAT_MSG: '日付はMM / DD / YYYY形式でなければなりません',
        ACCOUNTS_AND_CONTACTS: 'アカウントと連絡先',
        IMPORT_CSV_FILE: '拡張子が .csv のファイルを選択してください。',
        RECORDS_IMPORT_SUCCESS: 'レコードは正常にインポートされました。',
        GREEN_LIGHT_STATUS_ERROR: 'すでに1つのインポートが進行中であるため、現在のインポートプロセスが完了した後でのみ、新しいインポートを開始できます。',
        GREEN_LIGHT_STATUS_SUCCESS: '現在のインポートが進行中です。完了次第、当該フランチャイジーの登録メールに確認メールをお送りします。',
    },
    YOUTUBE_VIDEO: {

        //not found in doc

        EMPTY_VIDEO_LINK: 'YouTubeの動画URLを貼り付けてください',
        INVALID_VIDEO_LINK: '入力したURLは無効です',
        INSERT_TITLE: 'YouTubeから動画を挿入する',
        VIDEO_LINK_PLACEHOLDER: 'YouTubeの動画URLを貼り付ける'
    },
    WISTIA_VIDEO: {

        //not found in doc

        EMPTY_VIDEO_LINK: 'Wistiaの動画URLを貼り付けてください',
        INVALID_VIDEO_LINK: '入力したURLは無効です',
        INSERT_TITLE: 'Wistiaのビデオを挿入する',
        VIDEO_LINK_PLACEHOLDER: 'Wistiaの動画URLを貼り付ける'
    },
    INXPRESS_REPORT: {

        //not found in doc

        VALIDATE_EMPTY_FROM_DATE: '日付から選択してください',
        VALIDATE_EMPTY_TO_DATE: '今日まで選択してください',
        VALIDATE_EMPTY_COUNTRY: '国を選択してください',
        VALIDATE_EMPTY_FRANCHISE: 'フランチャイズを選択してください',
        VALIDATE_EMPTY_WORKFLOW: 'ワークフローを選択してください',
        VALIDATE_EMPTY_SALES_REP: '営業担当者を選択してください',
        VALIDATE_EMPTY_OBJECT_NAME: 'レポートを選択してください',
        NO_RECORD_EXPORT: '輸出可能なレコードがありません',
        REPORT_NOT_READY_FOR_EXPORT: 'レポートが読み込まれている間お待ちください、今すぐエクスポートできません',
        BLANK_FROM_TO_DATE: '日付は空白にできません',
        VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16: '続行するには国を選択してください。',
        VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT: '続行するワークフローを選択してください。',
        VALIDATE_EMPTY_REGION_INXPRESS_REPORT: '続行する地域を選択してください。',
        VALIDATE_EMPTY_DATE_RANGE: '続行する日付範囲を選択してください。',
        VALIDATE_EMPTY_WORKFLOW_STAGES: '続行するワークフローステージを選択してください。',
        VALIDATE_EMPTY_CHART_TYPE: '続行するチャートタイプを選択してください。',
        VALIDATE_EMPTY_SHOW_TYPE: '続行するショータイプを選択してください。',
        VALIDATE_EMPTY_ACTIVITY_TYPE: '続行するアクティビティタイプを選択してください。',
    },

    OTHER_VIEW: {

        //not found in doc

        TENANT_MESSAGE: 'あなたの購読には$$@@##PRODUCT_NAME##@@$$は含まれていません',
        USER_MESSAGE: '購読に電子$$@@##PRODUCT_NAME##@@$$を追加するには管理者にお問い合わせください',
        AFTER_SUBSCRIPTION_VENDASTA_NOT_CONFIGURED: 'まだ$$@@##PRODUCT_NAME##@@$$のアカウントを設定していません。利用可能になるとすぐにお知らせします。'
    },

    MY_INTEGRATION: {
        SAVE_SUCCESS: 'あなたの設定は正常に保存されました。',
        APPEND_INFO: 'Soffrontから追加された予定に会社と連絡先情報を追加する',
        DISCONNECT_CONNECTION: '接続は削除されます。本気ですか？',
        SAVE_FAIL: 'あなたの設定は保存されません。',
        NO_CALENDAR: 'カレンダーが選択されていない。',
        NO_SYNC_CHECKED: '同期オプションを選択していません。メール、カレンダー、またはその両方を同期するように選択できます。',
        SYNC_EMAIL_INFO_1: 'G Suite / Office 365メールボックスの電子メールを自動的にCRMに保存するには、このオプションを選択します。',
        SYNC_EMAIL_INFO_2: '電子メールは、一致するアカウントまたは連絡先レコードに対して保存されます。',
        SYNC_EMAIL_INFO_3: '電子メールは5分ごとに同期されます。',
        SYNC_CONTACT_INFO_1: 'CRMの連絡先をG Suite / Office 365に自動的に追加するには、このオプションを選択します。',
        SYNC_CONTACT_INFO_2: 'CRMの連絡先に加えた変更は、G Suite / Office 365で自動的に更新されます。',
        SYNC_CONTACT_INFO_3: '連絡先は5分ごとに同期されます。',
        SYNC_CALENDAR_INFO_1: 'このオプションを選択すると、SoffrontカレンダーとG Suite / Office 365のカレンダーの予定を自動的に同期させることができます。',
        SYNC_CALENDAR_INFO_2: 'G Suite / Office 365カレンダーが複数ある場合は、同期するカレンダーを選択する必要があります。',
        SYNC_CALENDAR_INFO_3: 'カレンダーは5分ごとに同期されます。',
        NO_CALENDAR_MESSAGE: 'カレンダーの詳細をお待ちしております。この画面を閉じて、数分後に戻ってください。このメッセージが引き続き表示される場合は、アカウントを切断して再接続してください。',
        NO_PERMISSION: '現在の計画では、私のインテグレーションは利用できません。適切なプランを購読するか、smbsupport@soffront.comまでご連絡ください。',
        SELECTED_CALENDAR_NOT_FOUND: 'あなたのprevoiusly選択されたカレンダーを見つけることができません。別のものを選択して保存してください。',
        INVALID_ACCOUNT_SYNC_STATE1:'君の',
        INVALID_ACCOUNT_SYNC_STATE2: '接続は無効になりました。切断してもう一度接続してください。',
        SEND_SALES_EMAILS_VALIDATION1: 'を使用してメールを送信できるようにするには',
        SEND_SALES_EMAILS_VALIDATION2: 'を使用して接続する必要があります',
        SEND_SALES_EMAILS_VALIDATION3: 'を使用して切断し、再度接続してください',    
    },

    DOCUSIGN: {
        PROVIDE_USERNAME: '有効なユーザー名を入力してください。',
        PROVIDE_PASSWORD: '有効なパスワードを入力してください。',
        PROVIDE_INTEGRATOR_KEY: '有効なインテグレータキーを入力してください。',
        SUCCESSFULLY_SAVED: 'DocuSignアカウントが正常に設定されました。',
        FAILED_TO_SAVED: 'DocuSignアカウントを設定できません。',
        DOCUSIGN_SETUP_TITLE_1: '文書を選択して送信する ',
        DOCUSIGN_SETUP_TITLE_2: ' に',
        DOCUSIGN_SETUP_TITLE_3: ' 署名のための',
        PROVIDE_RECIPIENT_NAME: '受取人のフルネームを入力してください',
        PROVIDE_RECIPIENT_EMAIL: '受信者のメールアドレスを入力してください',
        PLEASE_SELECT_TEMPLATE: 'テンプレートを選択してください。',
        NO_TEMPLATES_FOUND: 'テンプレートは見つかりませんでした。',
        UNABLE_TO_SEND: "DocuSignアカウントの有効期限が切れてからテンプレートを送信できません。",
        TEMPLATE_NAME_LENTH: 'テンプレート名は65文字以内で指定してください。',
        PLEASE_SELECT_CONTACT: '少なくとも 1 つの連絡先を選択してください。',
        DOCUSIGN_CONTACT_MESSAGE_1: '以下の連絡先に使用できる電子メールはありません。',
        DOCUSIGN_CONTACT_MESSAGE_2: '電子メールは、これらの連絡先に送信されません。',
        DOCUSIGN_CONNECTED_MESSAGE_1: '申し訳ありませんが、この操作を実行する権限がありません。 Soffrontにお問い合わせください。',
    },

    TWILIO: {
        CALL_OVER_SECURED_NETWORK: '保護されたネットワーク上でのみ電話をかけることができます。',
        SAME_TO_AND_FROM_NUMBER: '番号と送信元番号は同じです。通話を開始できません。.',
        CALLING_SUPPORT: '現在、お住まいの国では通話はサポートされていません。',
        OUTBOUND_PHONE_NOT_VERIFIED: '発信電話番号は確認されていません。電話をかける前に確認してください。',
        CALL_RECORDING_SAVE: '正常に保存。変更を確認するには、ログアウトして再度ログインしてください。',
        CALL_RECORDING_LAWS_AND_REGULATION_ALERT:'通話録音の慣行が、適用される法律および規制に準拠していることを確認してください。',
        NOTE_SAVE: 'メモを保存しました',
        PHONE_LOW_BALANCE1: 'あなたの電話残高は今です ',
        PHONE_LOW_BALANCE2: ' 分。',
        PHONE_LOW_BALANCE3: '電話をかけるには5分以上の残高が必要です。',

        PHONE_BLANK_MESSAGE: '電話番号を入力してください。',
        PHONE_ALREADY_CONFIGURE: 'この電話番号は既に構成されています。',
        DELETE_PHONE_NUMBER: 'この番号を削除してもよろしいですか?',
        VERIFY_PHONE_NUMBER: '別の番号を追加する前に確認してください。',
        PHONE_LIMIT: '3つ以上の電話番号を追加することはできません。',
        VERIFICATION_CODE: '確認コードを入力してください。',
        VERIFICATION_MESSAGE_1: '私達は呼ぶ',
        VERIFICATION_MESSAGE_2: '番号を確認します。',
        FIELDS_ADD_LIMIT:'3 つ以上のフィールドを追加することはできません。',
        SETUP_UPDATED_SUCCESSFULLY:'更新成功。',
        SETUP_UPDATED_FIELDS:'アップデートに失敗しました。'
    },
    CALL_SCRIPT: {
        BLANK_NAME: 'このスクリプトの名前を入力してください。',
        BLANK_DETAILS: 'スクリプトの詳細を入力してください。',
        SAVE_SUCCESS: 'このスクリプトは正常に保存されました。',
        UPDATE: 'このスクリプトは正常に更新されました。',
        DELETE: 'このスクリプトは正常に削除されました。',
        NO_RECORD: '利用可能な記録がありません',
        'DUPLICATE_SCRIPT_NAME': 'このスクリプト名はすでに存在します。重複したスクリプト名は使用できません。',
        'EMPTY_SCRIPT_NAME_DETAILS': 'スクリプト名とスクリプトの詳細を空にすることはできません。',
        'RECORD_NOT_FOUND_UPDATE': 'レコードが更新されていません。',
        DELETE_CONFIRMATION: 'このスクリプトを削除してもよろしいですか？'
    },
    SMS_INTEGRATION: {
        PURCHASED_NUMBER: 'あなたは正常に番号を購入しました',
        SELECT_USER: '先に進むには、少なくとも 1 人の営業担当者を選択してください。',
        ENTER_ZIP_OR_AREA: '郵便番号または市外局番を入力してください。',
        ENTER_AREA: 'フィールドコードを入力してください。',
        ENTER_VALID_ZIP: '有効な郵便番号を入力してください。',
        ENTER_VALID_AREA_CODE: '有効な市外局番を入力してください。',
        NO_AVAILABLE_PHONE_NUMBERS: '入力された郵便番号には電話番号はありません。',
        NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP: '入力した市外局番には電話番号はありません。',
        REMOVE_NUMBER_CONFIRMATION: 'この番号を削除してもよろしいですか？',
        SMS_SUPPORT: 'お住まいの国では、テキストメッセージの送信はサポートされていません。',
        SELECT_NUMBER: '番号を選択してください。',
        SELECT_AT_LEAST_ONE_NUMBER:'インポートする番号を少なくとも 1 つ選択してください',
        TWILIO_NUMBER_IMPORTED_SUCCESS:'あなたの twilio 番号は正常にインポートされました',
        TWILIO_NUMBER_IMPORTED_ERROR:'twilio 番号をインポートできませんでした。',
        PHONE_NUMBER_CANNOT_BE_PURCHASED:'電話番号は購入できません。 詳細については、Sofffront サポートにお問い合わせください。',
        ENTER_SMS_TEXT: 'SMSテキストを入力してください。',
        SMS_LOW_BALANCE_1: 'あなたのSMSの残高は今です ',
        SMS_LOW_BALANCE_2: 'あなたはsmsを送るためにsmsクレジットを購入する必要があります。',
        TWILIO_NUMBER_PURCHASE_MSG_1: 'あなたのアカウントは請求されます',
        TWILIO_NUMBER_PURCHASE_MSG_2: '月.',
        TWILIO_NUMBER_PURCHASE_MSG_3: 'この電話番号のいつでもアカウントから番号を削除して請求を停止することができます。',
        SMS_TOOLTIP_MESSAGE: '郵便番号または市外局番で検索',
        SMS_TOOLTIP_MESSAGE_AREA_CODE: '市外局番で検索',
        INSERT_IMAGE_FILE: 'jpg、jpeg、gif、またはpngファイルのみを選択してください',
        BATCH_SEND_SMS_MESSAGE: '送信に成功しました',
        'INSUFFICIENT_BALANCE': 'SMS残高不足',
        'BATCH_SMS_SENT': '送信に成功しました',
        'BATCH_SMS_FAILS': 'テキストメッセージを送信できませんでした',
        NO_FROM_NUMBER_MESSAGE: 'テキストメッセージを送信するには、電話番号を購入する必要があります。 [統合]メニューから電話番号を購入してください。',
        MAX_ATTACHMENT_FILE_FOR_SMS: '最大10個の添付ファイルが許可されています。',
        'NO_INPUT_STREAM_FOUND': '入力ストリームが見つかりません',
        'NO_ATTACHMENT_FOUND': '添付ファイルが見つかりません',
        'FILE_SIZE_ERROR': 'ファイルサイズは5MB以下にしてください。',
        IMAGE_SELECT: '画像を選択',
        MERGE_FIELD: '差し込み項目を選択',
        DUPLICATE_FILE_NAME: 'このファイルはすでに存在します。別のファイルを選択してください。',
        RECORD_UNSUBSCRIBE_ALERT: '選択したレコードはテキストから購読解除されます。テキストメッセージを送信できません。',
        UNSUBSCRIBE_LIST_CONFIRM_MSG: '選択したレコードのうち、テキストの購読を解除しました。テキストメッセージを未登録のレコードに送信することはできません。 OKを押して続行します。',
        UNSUBSCRIBE_LIST_CONFIRM_MSG_1: '選択したレコードのうち、テキストの購読を解除しました。テキストメッセージを未登録のレコードに送信することはできません。 OKを押して続行します。',
        'BATCH_SMS_SCHEDULE': 'テキストメッセージが正常にスケジュールされました',
        'BATCH_SMS_SCHEDULE_FAIL': 'テキストメッセージをスケジュールできませんでした',
        BATCH_SEND_SMS_MSG_1: 'のうち、',
        BATCH_SEND_SMS_MSG_2: '選択した場合のみ',
        BATCH_SEND_SMS_MSG_3: 'あなたのテキストメッセージを受信することを選択し、モバイルいいえを持っています。に属する',
        BATCH_SEND_SMS_MSG_4: '.私たちは、これらのみにテキストメッセージを送信します',
        BATCH_SEND_SMS_MSG_5: '[OK] を押して続行します。',
        SINGLE_SEND_SMS_MSG_1: '選択されているかどうかを確認してください',
        SINGLE_SEND_SMS_MSG_2: 'テキスト メッセージを受信することを選択したか、モバイル No を持っています。またはに属する',
        RESCHEDULE_SUCCESS: 'SMS は正常に再スケジュールされました',
        AUTOMATION_SMS_ADD_MSG: 'このテキストメッセージを保存しました',
        WORKFLOW_STATUS_MSG: 'この番号を使用してテキストメッセージを送信している1つ以上のアクティブなワークフローがあります。この番号を削除する前に、別の番号を使用するようにこれらのワークフローを更新してください。',
        SCHEDULED_STATUS_MSG: 'この番号を使用している1つ以上のステージングされたSMSがあります。 [OK]をクリックして、ステージングされたすべてのSMSをキャンセルし、番号を削除します。',
        REMOVE_SUCCESS_MSG: '番号は正常に削除されました。',
        NUMBER_SAVED_SUCCESS_MSG: '番号が正常に保存されました。',
        NO_LINKED_MOBILE_NUMBER_MSG:'関連するレコードには携帯電話番号がありません。テキストメッセージは送信できません。',
        NUMBER_INVALID_MSG:"連絡先の携帯番号が間違っています。",
        CANNOT_DELETE_UNITS_NUMBER: 'この番号はユニットに割り当てられているため、削除することはできません。',
    },
    EVENTBRITE: {
        NO_ORGANISATION_SELECTED: '少なくとも1つの組織を選択してください',
        NO_MAPPING_FOUND: 'CRM ContactフィールドとEventbriteフィールドのマッピングはありません。'
    },
    QUICKBOOKS: {
        NO_MAPPING_FOUND: 'CRMアカウントとクイックブックスの顧客フィールドのマッピングはありません。'
    },
    XERO: {
        SELECT_ONE_INTEGRATION_ITEM_AND_TYPE: '統合するアイテムと統合タイプを選択してください。',
        SELECT_ONE_INTEGRATION_ITEM: '統合するアイテムを選択してください。',
        SELECT_INTEGRATION_TYPE: '統合タイプを選択してください。',
    },
    REPORT_SCHEDULE_DIALOG: {
        START_DATE_BLANK: '開始日を空白にすることはできません。',
        INVALID_END_DATE: '開始日を終了日以上にすることはできません。',
        DAY_CHECKBOX_BLANK: '少なくとも1日チェックしてください。',
        SEND_TO_BLANK: '少なくとも1つのユーザーを選択します。',
        SAVE_SUCCESS: '正常に保存されます。'
    },
    DOCUMENT_PUSH: {
        ALERT_MSG_1: 'ドキュメントをプッシュする会社を少なくとも1つ選択してください',
        DOCUMENTS: '文書',
        FAIL: 'プッシュできませんでした'
    },
    OPERATIONS_REPORT: {
        NO_CUSTOM_REPORT_MESSAGE: 'カスタムレポートはありません。'
    },
    PINNED_NOTES: {
        CONFIRM_MESSAGE: 'これは既存のピン留めノートを置き換えます。続行するには[OK]を、破棄するには[キャンセル]をクリックしてください。'
    },
    ASSIGNMENT_RULES: {
        NO_ASSIGNMENT_RULES: 'ルールを使用できません。',
        NO_NAME: '割り当てルール名を指定してください。',
        NAME_MAX_LENGTH: '代入規則名は64文字以上にすることはできません。',
        NO_CRITERIA: '少なくとも1つの条件を指定してください。',
        NO_USER: '少なくとも1人のユーザーを指定してください。',
        RULE_SAVE_SUCCESS: '割り当てルールが正常に保存されました。',
        RULE_NAME_EXISTS: '名前は既に存在します。',
        ACTIVE_RULE_EXCEEDS: 'すでに 60 個のアクティブな割り当てルールがあります',
        SELECT_UNIT_MAXIMUM_FIELD: '選択できるユニットは1つだけです',
        SELECT_A_UNIT_WORKFLOW: 'ユニットワークフローを選択してください。',
        NO_UNIT_USER: '少なくとも1つのユニットを指定してください。'
    },
    SETTINGS: {
        EMPTY_NAME: '入ってください',
        DUPLICATES_SOURCE_NAME: 'ソース名は既に存在します！',
        CORPORATE_CURRENCY: '選択した通貨は正常に更新されました。',
        INVALID_CURRENCY_NAME: '有効な企業通貨名を選択してください',
        SYSTEM_NOTE_TYPE_EDIT_ALERT: 'このノートタイプは編集できません。',
        SYSTEM_NOTE_TYPE_DELETE_ALERT: 'このノートタイプは削除できません。',
        SYSTEM_CAMPAIGN_TYPE_DELETE_ALERT: 'このキャンペーンの種類は削除できません。',
        CORPORATE_CURRENCY_ALERT: '企業通貨を選択してください。',
        CORPORATE_CURRENCY_CONFIRM: '選択した通貨が保存されます。本気ですか？',
        'CORPORATECURRENCY': '企業通貨を選択してください',
        'DUPLICATE_NOTE_TYPES': 'このノートタイプは既に存在します。ノートタイプの重複作成は許可されていません。',
        'INCORRECT_RECORD_ID': '不正なレコードId.Updateが失敗しました。',
        'RECORD_DOES_NOT_EXISTS': 'レコードが存在しません。削除操作に失敗しました。',
        'RECORD_CANNOT_DELETED': 'システムまたはデフォルトのノートタイプは削除できません。',
        'DUPLICATE_CAMPAIGN_TYPES': 'このキャンペーンタイプは既に存在します。重複するキャンペーンタイプの作成は許可されていません。',
        'DUPLICATE_RELATIONS': '関係名は既に存在します。',
        RELATION_LINK_INFO_ALERT_MSG: 'この関係に従う記録があります。最初にこの関係に従うそれぞれのレコードのリンクを解除すると、この関係を削除することができます。'
    },
    RECYCLE_BIN: {
        SELECT_WORKFLOW: 'ワークフローを選択してください',
        SELECT_STAGE: 'ステージを選択してください',
        NOTE: '注：元のレコードのリンクレコードは復元されません。',
        RESTORE: 'レコードの復元に成功しました'
    },
    UNSUBSCRIBE: {
        SELECTED_RECORD: 'このレコードは、から登録解除されます ',
        ARE_YOU_SURE: '。本気ですか？',
        SELECTED_BOTH_RECORD: 'このレコードは、メールとテキストから購読解除されます。本気ですか？',
        'EMAILS_UNSUBSCRIBE': ' メールの購読を中止します。本気ですか？',
        'TEXTS_UNSUBSCRIBE': ' Textsから購読解除されます。本気ですか？',
        'BOTHS_UNSUBSCRIBE': ' メールとテキストの購読を停止します。本気ですか？',
    },
    WORKFLOWS: {
        STATUS_CHANGED: '状況はに変更されました ',
        'SAME_STATUS': '既に記録しています',
        'FAILED_TO_CHANGE_WORKFLOW_STATUS': 'ワークフローステータスの変更に失敗しました。',
        ONLY_ONE_ACTIVE_WORKFLOW: 'このワークフローを非アクティブにすることはできません。これが利用可能な唯一のアクティブなワークフローです。テーブルには少なくとも1つのアクティブなワークフローが必要です。',
        ONLY_ONE_ACTIVE_WORKFLOW_CANNOT_DELETE: 'このワークフローは削除できません。これは、使用可能な唯一のアクティブなワークフローです。テーブルには、少なくとも 1 つのアクティブ なワークフローが必要です。',
        DELETED_WORKFLOW: 'ワークフローが存在しません。[OK] を選択して新しいワークフローを選択します。',
        STATUS_BLANK_ALERT:'ステータスを空白にすることはできません',
        SALES_REP_BLANK_ALERT : '営業担当者を空白にすることはできません',
    },
    CHANGE_LOGO: {
        SELECT_IMAGE_TO_UPLOAD: 'ロゴを変更する画像を選択',
        LOGO_IMAGE_UPLOAD_FAILURE: '会社のロゴを保存できませんでした。',
        LOGO_IMAGE_UPLOAD: '会社のロゴを保存しました。',
        VALIDATE_LOGO_IMAGE: '画像ファイルを選択してください。',
        VALIDATE_LOGO_IMAGE_SIZE: '選択した写真は大きすぎて使用できません。許容される最大ファイルサイズは500 KBです。',
        LOGO_IMAGE_ATTACHMENT_LOGO_TEXT: 'jpg、gif、またはpngファイルをアップロードできます。',
        LOGO_IMAGE_SIZE_TEXT: '許容される最大ファイルサイズは500 KBです。',
        WEBSITE_URL: 'ウェブサイト',
        VALIDATE_LOGO_WEBSITE_URL: '有効なURLなしでロゴをアップロードしますか？'
    },
    ACCOUNTS: {
        DUPLICATE_MAIL_DOMAIN: 'この会社には既存のアカウントがあります - 代わりに連絡先を追加しますか？'
    },
    COUPONS: {
        CREATE_COUPON_NAME: 'クーポンの内部名を作成してください',
        CREATE_DISCOUNT_TYPE: 'どのような種類の割引を作成しますか？',
        FIXED_AMOUNT: '定額',
        PERCENTAGE: '割合',
        PREFIX_COUPON_CODE: 'クーポンコードのプレフィックスを指定します',
        CAN_CLAIM_MORE_THAN_ONCE: '同じアカウントで複数回使用できますか？',
        CALIM_LIMIT_ONE: '1回だけ',
        CLAIM_LIMIT_UNLIMITED: '無制限',
        WHYEN_CLAIM_CODE: 'クーポンの有効期限',
        CLAIM_ANYTIME: '期限切れにならない',
        CLAIM_BEFORE_DATE: '有効期限',
        SAVE_SUCCESSFULLY: 'クーポンの保存が正常に行われました',
        ACTIVE_SUCCESSFULL: '状況がアクティブに変更されました。',
        INACTIVE_SUCCESSFULL: '状況は「非アクティブ」に変更されました。',
        DELETE_CONFIRMATION: 'クーポンは削除されます。本気ですか？',
        INVALID: '無効です',
        DUPLICATE_COUPONS: 'クーポン名は既に存在します。',
        NEGATIVE_COUPONS: '負にすることはできません。',
        SPECIFY_DATE: '日付を指定してください。',
        PODUCT_SERVICE: '製品/サービス',
    },
    NOTIFICATION_REMINDERS: {
        SAVE_SUCCESS: 'アラームが正常に追加されました',
        INVALID_DAY: '有効な日を入力します。',
        DUPLICATE_REMINDER: 'これは重複するリマインダーです、 変更してください',
        INVALID_DATE_FIELD: '日付フィールドを選択してください',
        DELETE_SUCCESS: 'アラームが正常に削除されました',
        UPDATE_SUCCESS: 'アラームが正常に更新されました',
    },
    EMAIL_INTEGRATION: {
        DUPLICATE_EMAIL: 'この電子メール アドレスは既に存在します。',
        EMAIL_SAVE_SUCCESS: '電子メール アドレスは正常に保存されました',
        SEND_VERIFICATION_EMAIL_SUCCESS_1: '確認メールを送信しました。受信トレイを確認し、',
        SEND_VERIFICATION_EMAIL_SUCCESS_2: 'この電子メール アドレスを確認します。',
        SEND_VERIFICATION_EMAIL_SUCCESS_3: 'その後、戻ってきて、更新アイコンをクリックして、あなたのメールアドレスのステータスを表示します。',
        EMAIL_INTEGRATION_VERIFIED: 'この電子メール アドレスは既に確認されています!',
        CONFIRM_DELETE_EMAIL: '選択したメールアドレスが削除されます。本気ですか？',
        INVALID_EMAIL: '無効な電子メール アドレス',
        EMAIL_CANT_BLANK: '電子メール アドレスを空白にすることはできません。',
        EDIT_EMAIL_SAVE_SUCCESS: '電子メールが正常に保存されました',
    },
    NEWS: {
        SAVE_ERROR: '保存できません。',
        VALIDATE_CATEGORY: 'カテゴリを空白にすることはできません。',
        VALIDATE_STATUS: 'ステータスを空白にすることはできません。',
        VALIDATE_TITLE: 'タイトルを空白にすることはできません。',
        VALIDATE_SUMMARY: '要約は空白にできません。',
        VALIDATE_EXTERNAL_LINK: '外部リンクを空白にすることはできません。',
        VALIDATE_DETAILS: '詳細を空白にすることはできません。',
        CATEGORY_NAME_EXISTS: '値はリストに既に存在します。',
        DUPLICATE_TITLE: "このタイトルはすでに存在しています！",
    },
    USEFUL_LINKS: {
        CATEGORY: 'カテゴリ',
        LINK: 'リンク',
        TITLE: 'タイトル',
        VALID_CATEGORY: '有効なカテゴリを選択してください',
        DUPLICATE_CATEGORY: 'これは重複カテゴリです。',
        DUPLICATE_TITLE: '同じカテゴリ内でタイトルが重複しています。変更してください。',
        CONFIRM_DELETE_CATEGORY: 'カテゴリが削除されます。確かですか。',
        CONFIRM_DELETE_LINK: 'リンクが削除されます。確かですか。',
        VALID_URL: '有効な URL を入力してください。',
        EXISTING_LINKS_CATGORY: 'このカテゴリの下に既存のリンクがあります。 ',
        PLEASE_MOVE_LINKS: 'リンク先を移動してください。',
        CLICK_TO_CONFIRM: ' [OK] をクリックして確認します。',
    },
    CASE: {
        CASE_CLOSED_SUCCESS: 'サポート案件が正常にクローズされました'
    },
    ORTHOTIC_REPORT: {
        VALIDATE_EMPTY_PRODUCT: '製品を選択してください'
    },
    USER_REPORT: {
        REPORT_DELETE_RECORD: 'レポートは削除されます。本気ですか？',
        REPORT_DELETE: 'レポートを削除しました',
        INVALID_START_END_DATE: '開始日は終了日より後にすることはできません。'
    },
    AUTO_FILL_VALUES: {
        CHNAGE_PARENT_OBJECT: '親オブジェクトを変更しますか?',
        CHANGE_CHILD_OBJECT: '子オブジェクトを変更しますか?',
        SELECT_PARENT_OBJECT: '親オブジェクトを選択します。',
        SELECT_CHILD_OBJECT: '子オブジェクトを選択します。',
        NO_MAPPING_FIELDS: '間に使用できるマッピング フィールドがありません ',
        NO_MAPPING_FIELDS_AND: 'そして',
        CHILD_FIELD_SIZESHOULD_GREATER: '子フィールドのサイズは、親フィールドのサイズより大きくする必要があります。',
        MAPPING_FROM: 'マッピング元',
        MAPPING_TO: ' 宛先 ',
        NO_AVAILBLE_FIELDS_FOR_MAPPING: '.既定のマッピングに使用できる共通フィールドがありません。',
        NO_AVAILABLE_PARENT_CHILD_MAPPING: '使用できる親子フィールド マッピングがありません。',
        FIELD_MAPPING_DONE_SUCCESS: 'フィールド マッピングが正常に完了しました。',
        MAP_FIELDS_TO_AUTOFILL: 'フィールドを自動入力にマップする ',
        MAP_FIELDS_TO_AUTOFILL_FROM: ' 差出人 ',
        SELECT_PARENT_FIELD: '親フィールドを選択します。',
        SELECT_CHILD_FIELD: '子フィールドを選択します。',
        MAPPING_NOT_POSSIBLE: ' は不可能です。'
    },
    LANDING_PAGE: {
        INVALID_NAME: 'ランディングページ名を入力してください。',
        INVALID_URL_WARNING: 'URL名を入力してください。',
        SAVE: 'ランディングページが正常に保存されました。',
        UPDATE: 'ランディングページが正常に更新されました。',
        INVALID_FORM_BLOCK_EDIT_MSG: '現在アクティブなブロックには編集するフォームフィールドがありません。編集するフォームフィールドブロックを選択してください。',
        DUPLICATE_NAME: 'この名前のランディングページは既に存在します。別の名前を入力して、もう一度お試しください。',
        DUPLICATE_URL: 'このURLのランディングページは既に存在します。別の名前を入力して、もう一度お試しください。',
        INPUT_DATA_LOSS_MSG: '入力したデータはすべて消去されます。本気ですか？',
        EMAIL_REQUIRED_MSG: 'メールアドレスが必要です。',
        ATLEAST_ONE_BLOCK_ADD_MSG: 'このページを保存するには、少なくとも1つのデザインブロックを追加してください。',
        PREVIEW_ALERT_MSG: 'ランディングページをプレビューする前に保存してください。',
        OPEN_URL_ALERT_MSG: 'リンク先ページのリンクをクリップボードにコピーしました。',
        COPY_TO_CLIPBOARD: 'リンク先ページのリンクをクリップボードにコピーしました。',
        FORM_ADDED_MSG: 'フォームが正常に追加されました。',
        FORM_UPDATED_MSG: 'フォームは正常に更新されました。',
        INVALID_URL: '無効なURL。',
        LABEL_FIELD_CANNOT_BLANK: 'ラベルフィールドは空白にできません。',
        FIELD_IS_ALREADY_ADDED: 'フィールドはすでに追加されています。',
        MINIMUM_ONE_FIELD: '少なくとも1つのフィールドが必要です。',
        NO_MORE_FIELD_AVAILABEL: 'これ以上利用できるフィールドはありません。'
    },
    DOWNLOAD_LEADS: {
        SELECT_CRITERIA: '少なくとも 1 つの条件を選択してください。',
        SELECT_WORKFLOW: 'ワークフローを選択してください。',
        SELECT_GROUP: 'グループを選択してください。',
        SELECT_STATUS: 'ステータスを選択してください。',
        SELECT_SALESREP: '営業担当者を選択してください。',
        LEAD_EXIST_MSG_1: '既にアカウントにこれらのリードがあります - ',
        LEAD_EXIST_MSG_2: '.[OK] をクリックしてダウンロードを停止し、選択したリードを削除します。'
    },
    SCHEDULER: {
        BLANK_APPOINTMENT_NAME: '予定名を入力してください。',
        BLANK_LOCATION: '場所を入力してください。',
        SAVE_SUCCESS: 'スケジューラのセットアップが正常に保存されました。',
        SAVE_FAIL: 'スケジューラのセットアップを保存できませんでした。',
        NO_SCHEDULER: 'スケジューラ リンクを使用する前に、予定スケジューラを作成してください。',
        VALIDATE_TIME: '開始時刻は終了時刻より大きい、または同じにすることはできません。',
        NO_START_TIME: '開始時刻を選択してください。',
        NO_END_TIME: '終了時刻を選択してください。',
        SCHEDULER_LINK_COPIED_SUCCESS: 'スケジューラ リンクをクリップボードにコピーしました。',
        BLANK_SUBMIT_BUTTON: 'ボタンラベルを空白にすることはできません。',
        CALENDAR_SYNC_MSG: 'CRM カレンダーが GSuite または Office 365 calendae に接続されていません。',
        CALENDAR_SYNC_LINK: 'ここをクリックして設定してください。',
        SCHEDULER_UNSAVE_msg: 'このフォームを残しますか?行った変更は保存されません。',
    },
    RELATION_REPORT: {
        RANGE_FIELD_BLANK: '日付フィールドを選択してください。',
        ROW_FIELD_BLANK: '行フィールドを選択してください。',
        COL_FIELD_BLANK: '列フィールドを選択してください。',
        REPORT_NAME_BLANK: 'レポート名を入力してください.',
        REPORT_SAVE_SUCCESS: 'レポートを保存しました。',
        DUE_BY_BLANK: '日付範囲を選択してください。',
        NO_ROW_FIELD_VALUE: '行ラベルの値を選択してください。',
        NO_COLUMN_VALUE: '列ヘッダーの値を選択してください。',
        SAME_ROW_COLUMN_FIELD: '行フィールドと列フィールドを同じにすることはできません。',
        INVALID_END_DATE: '開始日は終了日より後にすることはできません。',
        'Report Name already exists': 'レポート名は既に存在します。',
        VALID_REPORT_NAME: '有効なレポート名を入力してください'
    },
    PAYMENTS: {
        NOT_ADDED_QB: '見積もり/請求書はまだクイックブックに追加されていません!',
        NO_LINKED_AVAILABLE: 'リンクされた見積もり/請求書はありません!',
        PAYMENT_VALUE_ALERT_MSG: '支払い額をゼロにすることはできません。',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: '見積もり/請求書タブが開いています。 新しい見積もり/請求書を開くか追加する前に、そのタブを閉じてください。',
        AMOUNT_EXCEED_BALANCE_DUE: '入力する支払は、この請求書の支払期日残高を超えています。[OK] をクリックして支払を続行します。支払額を変更するには、[キャンセル] をクリックします。',

    },
    PRODUCTS: {
        DUPLICATE_PRODUCT: '製品名はすでに存在しています。',
        ADDED_TO_QUICKBOOK: 'クイックブックに正常に追加されました。',
        PULLED_FROM_QUICKBOOKS: 'クイックブックから正常に引き出されました。',
        PRODUCT_PRICE_VALUE_ALERT_MSG: '価格の値をゼロにすることはできません。',
        PRODUCT_NAME_COULDNOT_CONTAIN_COLON_MSG: 'コロン（:)を含めることはできません。',
        NEGATIVE_PRICE: '在庫タイプの価格を負の値にすることはできません。'
    },
    COMPANY_PROFILE: {
        BLANK_BUSINESS_NAME: '会社名を入力してください。',
        BLANK_BUSINESS_ADDRESS: '勤務先住所を入力してください。',
        BLANK_BUSINESS_PHONE: '勤務電話を入力してください。'
    },
    EXPORT_SETTINGS: {
        CONSIDERATION: '考慮:',
        CONSIDERATION_MSG1: 'EXCEL＆CSV形式のファイルをサポートしています。',
        CONSIDERATION_MSG2: 'Excelのセルには最大32,767文字を含めることができるため、エクスポートされたExcelファイルでは、セルに最大32,767文字を表示し、残りの文字を除外します。',
        NO_RECORDS_FOUND: 'レコードが見つかりません！',
        FILE_NAME_IS_EMPTY: 'ファイル名が空です。',
        PLEASE_SELECT_THE_FILE_TYPE: 'ファイルの種類を選択してください。',
        PLEASE_SELECT_THE_PARENT_OBJECT: '親オブジェクトを選択してください。',
        PLEASE_SELECT_THE_QUERY_NAME: 'クエリ名を選択してください。',
        PLEASE_SELECT_THE_PARENT_FIELDS: '親フィールドを選択してください。'
    },
    DATA_BASE: {
        FAILURE_MSG: '保存できませんでした。',
        SUCCESS_MSG: '設定が正常に保存されました。ログアウトしてから再度ログインして、変更を確認してください。',
        VALIDATION_MSG: 'の子オブジェクトを少なくとも1つ選択してください ',
        VALIDATION_MSG1: "選択したオブジェクトの上限である15に達しました。",
        SUCCESS_MSG1: '設定は正常に保存されました。',
    },
    UNITS: {
        BLANK_FIRST_NAME: '名を入力してください。',
        BLANK_LAST_NAME: '姓を入力してください。',
        BLANK_PASSWORD: 'パスワードを入力してください。',
        UNMATCH_RETYPE_PASSWORD: 'パスワードの再入力がパスワードと一致しません。',
        BLANK_RETYPE_PASSWORD: 'パスワードを再入力してください。',
        BLANK_EMAIL: 'メールアドレスを入力してください。',
        BLANK_PHONE: '電話を入力してください。',
        BLANK_COMPANY: '会社に入ってください。',
        UNIT_SAVED: '正常に保存。',
        UNIT_NOT_SAVED: 'ユニットを保存できませんでした。',
        DUPLICATE_EMAIL: '同じメールIDのレコードがすでにあります。',
        DUPLICATE_COMPANY: '会社名はすでに存在します。',
        SUBSCRIPTION_NOT_VALID: '選択したユニットのサブスクリプションは無効です。詳細については、Soffrontサポートにお問い合わせください。',
        NO_UNIT_TASKS: 'ユニット所有者が利用できるタスクはありません。',
        NO_ACCOUNT_TASKS: 'アカウントで利用できるタスクはありません。',
        BLANK_FAMILY_INDUSTRY: '家族産業を選択してください',
        NO_FAMILY_INDUSTRY: '新しいユニットを追加する前に、基本テンプレートが必要になります。基本テンプレートを設定するには、BrandWideサポートにお問い合わせください。',
        BLANK_PLAN_NAME: 'プラン名を選択してください。',
        NO_AVAILABLE_PLANS: 'このユニットに登録できる利用可能なプランはありません。',
        NO_BRANCH_WORKFLOW: '使用可能なブランチ ワークフローがありません。',
        PLEASE_SELECT_TYPE: '続行するにはタイプを選択してください。',
        NO_BASE_LOGIN: 'ベースが存在しないか、設定されていません。問題を修正して再試行してください。',
    },
    SHARE: {
        SHARE_SUCCESS_MSG_1: '共有情報が正常に更新されました。',
        SHARE_SUCCESS_MSG_SL: '選択したセキュリティ レベルがある場合、共有情報は正常に更新されます。',
        SHARE_SUCCESS_MSG_2: 'メール通知は、新しく選択したユニットに送信されます(存在する場合)。',
        SHARE_SUCCESS_MSG_3: 'メール通知は、新しく選択したユニットに送信されます(存在する場合)。',
        SHARE_SUCCESS_MSG_4: '確かですか。',
        SHARE_SUCCESS_MSG_5: '選択したユニットに共有されます。',
        SHARE_SUCCESS_MSG_6: '選択したユニットと共有されます。',
        SHARE_FAILED_MSG: '共有できませんでした。',
        SHARE_SELECT_CORPORATE_DOCUMENT: 'ドキュメントを共有する企業ユーザーを少なくとも 1 人選択してください。',
        SHARE_SELECT_CORPORATE_IMAGE: '画像を共有する企業ユーザーを少なくとも 1 人選択してください。',
        SHARE_SELECT_UNIT_DOCUMENT: 'ドキュメントを共有する単位を少なくとも 1 つ選択してください。',
        SHARE_SELECT_UNIT_IMAGE: 'イメージを共有する単位を少なくとも 1 つ選択してください。',
        NO_CONNECTED_CLIENT: 'ドキュメントを共有できる接続済みクライアントがありません。',
    },
    SECURITY_LEVEL: {
        ERROR_MSG_1: 'おっと！何かがうまくいかなかった。後でお試しください。',
        ERROR_MSG_2: '説明を空白にすることはできません。',
        SUCCESS_MSG_1: '設定は正常に保存されました。',
    },
    ROYALTY_REPORT: {
        BLANK_ROYALTY_PERCENT: 'ロイヤリティ率を入力してください。',
        REPORT_SAVE_SUCCESS: 'レポートが正常に保存されました。',
        SETUP_SAVE_SUCCESS: 'ロイヤリティ設定が正常に保存されました。'
    },
    MENU: {
        ATLEAST_ONE_MODULE_ACTIVE: '申し訳ありませんが、少なくとも1つのモジュールがアクティブになっている必要があります。',
        ATLEAST_ONE_MODULE_ITEM_ACTIVE: '申し訳ありませんが、少なくとも1つのモジュールアイテムがアクティブになっている必要があります。',
        MODULE_LABEl_CHAR_LIMIT_MSG: '名前は32文字を超えることはできません。',
        DUPLICATE_NAME: 'モジュール名はすでに存在します。'
    },
    SALES_PURCHASE_ORDER: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD: '開いている注文書タブがあります。新しい注文書を追加する前に、そのタブを閉じてください。',
        RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD: '開いている販売注文タブがあります。新しい販売注文を追加する前に、そのタブを閉じてください。',
        SAVE_PURCHASE_SUCCESS: '発注書は正常に保存されました。',
        SAVE_SALES_SUCCESS: '販売注文は正常に保存されました。',
        SALES_REP_NAME_CANT_BLANK: '営業担当者名は空白にできません。',
        SELECT_ONE_ITEM: 'アイテム情報を1つ選択してください。',
        DISCOUNT_AMOUNT_CANT_GREATER: "割引額は小計を超えることはできません。",
        SPECIFY_TRANSC_AMOUNT: '0 以上の取引金額を指定する必要があります。',
        SETUP_COMPANY_PROFILE: '見積もりを追加する前に、会社のプロフィールを設定してください。 プロファイルを設定するには、CRM 管理者に連絡してください。'

    },
    JOBS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD: '開いているジョブタブがあります。新しいジョブを追加する前に、そのタブを閉じてください。',
        FIELDS_CANNOT_BLANK: ' フィールドは空白にできません。',
        PRODUCT_NAME_NOT_CONTAIN: '製品名にコロン（:)を含めることはできません。',
        JOB_SAVE_SUCCESS: 'ジョブは正常に保存されました。',
        PRODUCT : '製品',
        ALREADY_EXIST: 'はすでに存在します。',
    },
   POWER_CALLING :{
        YOU_DONT_HAVE_CALL_SCRIPT: "呼び出しスクリプトはありません。",
        ALERT_MSG_1: 'の外へ ',
        ALERT_MSG_2: ' 選択したのは ',
        ALERT_MSG_3: ' 有効な市内番号があります。あなたはそれらを呼び出すことができるようになります ',
        ALERT_MSG_4: 'と話しました ',
        ALERT_MSG_5: "もうありません",
        ALERT_MSG_6: "キューに入れて電話をかけます。",
        ALERT_MSG_7: 'アクティブな接続がありません。',
        ALERT_MSG_8: 'あなたが選択した、それらのどれも携帯電話または電話番号を持っていません。',
        ALERT_MSG_9: 'すでに開いているパワーダイヤラが1つあります。閉じて、新しいパワーダイヤラを開いてください。',
        ALERT_MSG_10 :'タブを閉じるには、タブの上部にある閉じるアイコンをクリックします。',
    },
    LEAD_SCORE_SETUP:{
        ALREDY_DEFINED_SCORE: 'このアクションのスコアはすでに定義されています。',
        SAVE_SUCCESSFULLY: 'スコアは正常に保存されました。',
        CAN_NOT_UPDATE: 'スコア設定は更新できません。 スコア設定を正しく変更してください。',
        SELECT_ONE_ACTION: '少なくとも1つのアクションを選択してください',
        SELECT_ACTION: 'アクションを選択してください',
        SELECT_OBJECT: 'オブジェクトを選択してください',
        SELECT_WORKFLOW: 'ワークフローを選択してください',
        CONVERT_FROM_CANT_BLANK: '変換元を空白にすることはできません',
        CONVERT_TO_CANT_BLANK: '変換先を空白にすることはできません',
        CHOOSE_DIFF_STATUS: '同じステータスを変換することはできません。 別のステータスを選択してください。',
        SELECT_GROUP: 'グループを選択してください',
        SELECT_FIELD : 'フィールドを選択してください',
        PROVIDE_VALUE_TO_UPDATE :'更新したい値を入力してください',
        ENTER_SCORE: '1〜99のスコアを入力してください',
        EXIST_IN_LIST: '値はすでにリストに存在します',
        SCORE_DELETED: 'このスコアは削除されます。 本気ですか？',
        REMOVE_MSG: '正常に削除',
        UPDATE_MSG: '正常に更新'
    },
    DUPLICATE_RULE: {
        ERROR_MSG_DEFAULT: 'デフォルトのルールをオフにすることはできません。',
        ERROR_MSG_Active: 'あなたは、デフォルトのルールを非アクティブすることはできません。',
        ERROR_MSG_FOR_FIELDS: 'ルールを保存するために特定のブロックからすべてのフィールドを選択します。',
        ERROR_MSG_1: '何かが間違っていました。後ほど再度お試しください。',
        SUCCESS_MSG_1: 'あなたの重複したルール（複数）は正常に保存されています。',
        ERROR_MSG_SAME_FIELD: ' すでに選択されています。別のフィールドを選択してください。',
    },
    REQUIRED_FORM: {
        FIELD_FILL_MESG:'これらのフィールドは、この前に入力する必要があります ',
        CAN_BE_SUBMITTED:' 提出することができます。'
    },
    GREENLIGHT: {
        COMPANY_NAME_BLANK: '会社名を入力してください。',
        COMPANY_NAME_REQUIRED: '会社名は30文字を超えることはできません。',
        ADDRESS1_REQUIRED: 'アドレス1が必要です。',
        ADDRESS1_ADDRESS2_LIMIT: 'Address1 + Address2の合計は30文字を超えることはできません。',
        PHONE_NUMBER_REQUIRED: '有効な電話番号を入力して下さい。',
        PHONE_NUMBER_LIMIT: '電話番号は10桁を超えることはできません。',
        NET_ANNUAL_LIMIT: 'Net Anualを空白にすることはできず、ゼロにすることもできません。',
        NET_ANNUAL_INVALID: 'ネットアニュアルに有効な金額を入力してください。'
    },
    AUDIT_QUESTIONS: {
        QUESTION_REQUIRED: '質問が必要です',
        ENTER_VALID_SCORE: "有効なスコアを入力してください",
        ENTER_VALID_CHOICES: "有効な選択肢を入力してください",
        SUCCESS: '成功',
        ERROR: 'エラー',
        NO_QUESTIONS: "見つかった質問はありません",
        THANK_YOU: "ありがとう！",
        SOMETHING_WRONG: "あなたのフィードバックを送信しながら何かがうまくいかなかったので、もう一度やり直してください！",
        FEATURE_NOT_AVAILABLE: "この機能は利用できません。",
        RECEIVED_FEEDBACK_1: "よくやった！ の回答が正常に保存されました。",
        RECEIVED_FEEDBACK_2:"カテゴリー。",
        RECEIVED_FEEDBACK_3:"最終提出前に、いつでもこのカテゴリの回答を更新できることに注意してください。",
        MAIL_SENT_MESSAGE:'メールが送信されました。',
        SUBMIT_AUDIT_1:'次のカテゴリには未回答の必須の質問があります。',
        SUBMIT_AUDIT_2:'これらの必須の質問に答えてから、監査を送信してください。',
        SUBMIT_AUDIT_3:'監査は正常に送信されました。',
        SUBMIT_AUDIT_4:'提出した監査回答とスコアは、CRM の [HUB] > [監査] で確認できます。',
        SUBMIT_AUDIT_5:'You will receive email notification, if Franchisor provides any feedback on your audit.',
        SUBMIT_AUDIT_6:'また、フランチャイザーから提供されたフィードバックを確認し、[HUB] > [監査] から修正措置を講じることもできます。',
        SAVE_ALERT_1:'注意！ あなたはここを離れようとしています ',
        SAVE_ALERT_2:'変更を保存せずにカテゴリを削除します。',
        SAVE_ALERT_3:'保存後に [OK] をクリックして続行するか、このアラートを閉じてここに留まり、このカテゴリを続行してください。',
        REQUEST_CORRECTIVE_SUCCESS:'ありがとう！ フィードバックは電子メールで正常に送信されました。',
        SUBMIT_CORRECTIVE_ACTION:'ありがとう！ 修正措置情報は電子メールで正常に送信されました。',
        EMPTY_REQUEST_CORRECTIVE_MESSAGE:'どの質問に対してもフィードバックが追加されていません。'+'\n'+'まずフィードバックを追加してから、「修正措置をリクエスト」をクリックしてください。',
        EMPTY_SUBMIT_CORRECTIVE_MESSAGE:'どのフィードバックに対しても修正措置を追加していません。' + '\n' + '最初に修正措置を追加してから、[修正アクションを送信] をクリックしてください。',
        EMPTY_AUDIT_BY_NAME_AND_EMAIL:'「名前」と「メールアドレス」のフィールドが空白です。これらのフィールドに値を入力してください。',
        EMPTY_AUDIT_BY_NAME:'名前フィールドが空白です。これらのフィールドに値を入力してください。',
        EMPTY_AUDIT_BY_EMAIL:'電子メール アドレス フィールドが空白です。これらのフィールドに値を入力してください。',
        AUDIT_TYPE_SELECT_VALIDATION:'リンクを生成するには、監査タイプを 1 つ選択してください。',
        INVALID_AUDIT_EMAIL:'有効なメールアドレスを入力してください。',
        INVALID_AUDIT_NAME:'有効な名前を入力してください。',
    },
    AUDIT_ANSWERS:{
        NO_RECORD_FOUND:'修正質問を含む回答が見つかりません',
    },
    AUDIT_QUESTION_TYPES:{
        MAX_EXCEED:'Massimo superato',
    },
    COMPLIANCE: {
        SINGLE_RECORD_EDIT: '編集するには1レコードを選択してください。',
    },
    SALES_AND_REFUND: {
        ALL_OPTIONS_REQUIRED: '使用可能なフィルタをすべて選択して、再試行してください。',
    },
    INTAKE_FORM : {
        RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM:'You have an open Intake form tab. Please close that tab before opening or adding a new Intake form.',
        INTAKE_FORM_SUCCESSFULLY_SAVE: 'Saved Successfully.',
        THANK_YOU: "Thank You!",
    },
    EXCEPTION: {
        EXCEPTION_SAVE_SUCESS: 'Saved Successfully.',
    },
    PHONE_NUMBERS: {
        USE_FOR_NOTIFICATION_SAVE: '通知用に更新された発信者番号。',
        USE_NOTIFICATION: 'このリース番号は通知に使用されます。 「OK」をクリックして確認してください。',
        REMOVE_NOTIFICATION: 'このリース番号は通知のために削除されます。 「OK」をクリックして確認してください。',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        SAVE_SUCCESS: '設定は正常に保存されました。',
        SAVE_FAIL: '設定は保存されません。',
        NO_PROVIDER: '電話とテキスト機能用のプロバイダがまだ選択されていません。\n 電話とテキスト機能を使用するには、最初にプロバイダを選択してください。',
        NO_TWILIO_NUMBERS_FOUND: '接続されている Twilio アカウントに使用可能な電話番号がありません。\n まず Twilio アカウントに番号を追加してから、インポート操作を実行してください。',
        NONE_SELECT_CONFIRM: 'これにより、CRM のすべての場所から電話とテキストのオプションが無効になります。\n テキストがすでに構成されているワークフロー キャンペーンまたはオートメーション キャンペーンがある場合は、機能しなくなるため、それらを適宜変更してください。'
    }
}
export default message
