
import React from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { Chip, FormControl, Menu, Select, MenuItem, TextField, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styles } from '../../../services/constants/styles';
import * as sfDialogs from '../components/sfDialogs';
import { isValidParam, getStringParam, getArrayParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { getSMS, getRemainingSMSCount, sendSMS, getAllocatePhoneNumbers } from '../../../services/actions/smsAction';
import { OBJECT_TABLEID_MAP, COUNTRY_LIST_FOR_PHONE_SMS } from '../../../services/constants/constants';
import ShowCircularProgress from '../components/circularProgress';
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { getAppDialog, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { constants } from '../../../services/constants/constants';
import { dateTimeFormat } from '../../../services/helper/utils';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import moment from 'moment';
import { timeZone } from '../../../services/helper/utils';
import SMSBody from '../components/SMSBody'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveTab } from '../../../services/helper/sfTabManager';


const SendSMS = ({ object, data, type }) => {
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSMSSupport, setIsSMSSupport] = useState(true);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [arrSMS, setArrSMS] = useState([]);
    const [fromNumber, setFromNumber] = useState(null);
    const [smsFromNumber] = useState(null);
    const [smsText, setSmsText] = useState(null);
    const [attachFiles, setAttachFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState(0);
    const [templatesList, setTemplatesList] = useState([]);
    const [smsShrink, setSmsShrink] = useState(false);
    const [smsArrDetails, setSmsArrDetails] = useState([]);
    const [schedule_date, setSchedule_date] = useState(null);
    const [moreActionOpen, setMoreActionOpen] = useState(false);
    const [anchorMoreActionEl, setAnchorMoreActionEl] = useState(false);
    const [changeLog, setchangeLog] = useState({});
    const [dummyData, setDummyData] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [smslength, setSmslength] = useState('0');
    const [stateObject, setStateObject] = useState(null);

    const appState = useSelector((state) => state.app);

    useEffect(() => {
        setObject();
    }, []);

    useEffect(() => {
        if (stateObject) {
            getTenantCountry();
        }
    }, [stateObject]);

    const getTenantCountry = () => {
        let tenantCountry = getStringParam(appState.me.tenant_country);
        let countryRecord = getStringParam(data.country);
        let gblCountry = null;

        if (countryRecord === '') {
            gblCountry = tenantCountry;
            setIsSMSSupport(true);
        } else {
            if (COUNTRY_LIST_FOR_PHONE_SMS.indexOf(tenantCountry) > -1) {
                gblCountry = tenantCountry;
                setIsSMSSupport(true);
            } else {
                setIsSMSSupport(true);
                setIsLoaded(false);
            }
        }

        if (gblCountry !== null && gblCountry !== '' && isSMSSupport) {
            getFromPhoneNumbers();
        }

    }

    const setObject = () => {
        let tempObject;
		if (object === constants.SEARCH_RESULTS) {
			let activeTab = getObjectParam(getActiveTab());
			let activeTabInfo = getObjectParam(activeTab.info);
			let search = getObjectParam(activeTabInfo.search);
			tempObject = getStringParam(search.object);
		} else if (object === constants.ADVANCE_SEARCH_RESULTS) {
			let activeTab = getObjectParam(getActiveTab());
			let advanceSearch = getObjectParam(activeTab.info.advanceSearch);
			tempObject = getStringParam(advanceSearch.object);
		} else {
			tempObject = object;
		}
		setStateObject(tempObject);
    }

    const getFromPhoneNumbers = () => {
        try {
            let tempPhoneNumbersArr = null;
            let params = {};
            params.call_from = 'sendSMS';
            let promise = Promise.resolve(getAllocatePhoneNumbers(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let fromNumber;
                        let twilioNumbersArr = getArrayParam(response.twilio_numbers);
                        if (twilioNumbersArr !== null && twilioNumbersArr.length > 0) {
                            tempPhoneNumbersArr = twilioNumbersArr.filter(f => {
                                let temp;
                                let availableToArr = f.available_to.split(',');
                                if (availableToArr.indexOf(String(-9999)) > -1) {
                                    temp = true;
                                } else if (availableToArr.indexOf(String(appState.me.id)) > -1) {
                                    temp = true;
                                }
                                return temp;
                            })
                            let cookieSmsNo = cookieGetSmsFromPhoneNo();
                            if (arrSMS.length > 0) {
                                fromNumber = smsFromNumber;
                            } else {
                                if (tempPhoneNumbersArr.length > 0) {
                                    if (cookieSmsNo === null || cookieSmsNo === "") {
                                        fromNumber = tempPhoneNumbersArr[0].phone_number;
                                    } else {
                                        tempPhoneNumbersArr.forEach((items, Index) => {
                                            if (cookieSmsNo === items.phone_number) {
                                                fromNumber = items.phone_number;
                                            }
                                        });
                                    }
                                }
                            }
                        }
                        let smsText, smsShrink;
                        if (data.callFor === constants.OMW) {
                            smsText = data.textMsg;
                            smsShrink = true;
                        }
                        setPhoneNumbers(tempPhoneNumbersArr);
                        setFromNumber(fromNumber);
                        let log = changeLog;
                        log.fromNumber = fromNumber;
                        log.phoneNumbers = tempPhoneNumbersArr;
                        setchangeLog({ ...log });
                        if (smsText != undefined) {
                            setSmslength(smsText.length);
                        }
                        setSmsText(smsText);
                        setSmsShrink(smsShrink);
                        setIsLoaded(true);
                        fnGetSMS(log);
                        loadTemplateList();
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'sendSMS.js -> getFromPhoneNumbers()':" + error);
        }
    }

    const fnGetSMS = (log) => {
        try {
            let tempPhoneNumbers = getArrayParam(log.phoneNumbers).map(m => m.phone_number);
            let params = {};
            params.to_number = getStringParam(data.toNumber);
            params.record_id = getIntParam(data.id);
            params.table_id = OBJECT_TABLEID_MAP[getStringParam(object)];
            let promise = Promise.resolve(getSMS(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    response = getArrayParam(response.smsList);
                    let _arrSMS = [];
                    var smsDetails = [];
                    if (response.length > 0) {
                        smsDetails = tempPhoneNumbers.map(phoneNumber => {
                            let smsList = response.filter(f => f.from_phone === phoneNumber || f.to_phone === phoneNumber);
                            return { phoneNumber, smsList };
                        });
                    }
                    let cookieSmsNo = cookieGetSmsFromPhoneNo();
                    if (cookieSmsNo === null || cookieSmsNo === "") {
                        _arrSMS = smsDetails.length > 0 ? smsDetails[0].smsList : [];
                    } else {
                        smsDetails.forEach((items, Index) => {
                            if (cookieSmsNo === items.phoneNumber) {
                                _arrSMS = items.smsList;
                            }
                        });
                    }
                    setArrSMS(_arrSMS);
                    let _log = log;
                    _log.arrSMS = _arrSMS;
                    setchangeLog({ ..._log });
                    setSmsArrDetails(smsDetails);
                });
            }
        } catch (error) {
            console.error("Error in 'sendSMS.js -> getSMS()':" + error);
        }
    }

    const getPhoneNumberMenuItems = () => {
        let phoneNumberMenuItems = [];
        try {
            let tempPhoneNumbers = getArrayParam(phoneNumbers);
            phoneNumberMenuItems = tempPhoneNumbers.map((listValue, listValueIndex) => {
                return <MenuItem value={listValue.phone_number}
                    key={'sms-phone-item-' + listValue.id + listValueIndex}
                    id={listValue.id}
                    title={listValue.phone_number}
                    style={{ ...styles.popoverMenuItem }} >{listValue.phone_number}</MenuItem>
            })
        } catch (error) {
            console.error("Error in 'sendSMS.js -> getPhoneNumberMenuItems()':" + error);
        }
        return phoneNumberMenuItems;
    }

    const tempsetFromNumber = (event) => {
        setIsSend(false);
        let value = getStringParam(event.target.value);
        let smsDetails = [];
        smsDetails = getArrayParam(smsArrDetails).filter(f => f.phoneNumber === value);
        smsDetails = smsDetails.length > 0 ? smsDetails[0].smsList : [];
        setFromNumber(value);
        setArrSMS(smsDetails);
        let _log = changeLog;
        _log.arrSMS = smsDetails;
        setchangeLog({ ..._log });
    }

    const setSMSBody = (event) => {
        setIsSend(false);
        let value = event.target.value
        setSmslength(value.length);
        try {
            value = getStringParam(value);
            setSmsText(value);
        } catch (error) {
            console.error("Error in 'sendSMS.js -> setSMSBody()':" + error);
        }
    }

    const validateSMSForm = () => {
        let tempFromNumber = getStringParam(fromNumber).trim();
        let tempSmsText = getStringParam(smsText).trim();
        try {
            if (tempFromNumber === '') {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.SELECT_NUMBER, null);
                return false;
            } else if (tempSmsText === '') {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.ENTER_SMS_TEXT, null);
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error("Error in 'sendSMS.js -> validateSMSForm()':" + error);
        }
    }

    const cookieSetSmsFromPhoneNo = (value) => {
        let userid = appState.me.id;
        let cVarName = "smsFromPhoneNo_" + userid
        document.cookie = cVarName + "=" + value;
    }

    const checkPhoneTextProvider = () => {
        if (!isSend) {
            setIsSend(true);
            let phone_text_provider = appState.me.phone_text_provider ?? constants.NONE;
            if (phone_text_provider == constants.SOFFRONT) {
                saveSMSAfterRemainingCountCheck();
            } else if (phone_text_provider == constants.TWILIO) {
                sendMessage();
            }
        }
    }

    const cookieGetSmsFromPhoneNo = () => {
        let userid = appState.me.id;
        let cVarName = "smsFromPhoneNo_" + userid + "=";
        let cookieVar = decodeURIComponent(document.cookie).split(';')
        for (var i = 0; i < cookieVar.length; i++) {
            var c = cookieVar[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cVarName) === 0) {
                return c.substring(cVarName.length, c.length);
            }
        }
        return "";
    }

    const saveSMSAfterRemainingCountCheck = () => {

        try {
            if (validateSMSForm()) {
                let smsCountPromise = Promise.resolve(getRemainingSMSCount());
                if (isValidParam(smsCountPromise)) {
                    smsCountPromise.then((response) => {
                        if (isValidParam(response)) {
                            let remainingSMSCount = response.remaining_sms_count;
                            if (remainingSMSCount > 0) {
                                sendMessage();
                            } else {
                                let msg = getLocalizedStrings().message.SMS_INTEGRATION.SMS_LOW_BALANCE_1 + remainingSMSCount + '.\n' + getLocalizedStrings().message.SMS_INTEGRATION.SMS_LOW_BALANCE_2
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'sendSMS.js -> saveSMSAfterRemainingCountCheck()':" + error);
        }
    }

    const sendMessage = () => {
        let msg = getLocalizedStrings().message.SMS_INTEGRATION['BATCH_SMS_SENT'];
        let isSchedule = false;
        try {
            let tempFromNumber = getStringParam(fromNumber).trim();
            let tempSmsText = getStringParam(smsText).trim();
            let tempAttachFiles = getArrayParam(attachFiles);
            cookieSetSmsFromPhoneNo(tempFromNumber);
            let params = {};
            params.from_number = tempFromNumber;
            params.to_number = getStringParam(data.toNumber);
            params.record_id = getIntParam(data.id);
            params.sms_text = tempSmsText;
            params.table_id = getIntParam(OBJECT_TABLEID_MAP[getStringParam(data.object)]);
            params.template_id = selectedTemplate;
            if (data.hasOwnProperty('opportunity_info') && data.opportunity_info !== null &&
                data.opportunity_info !== undefined && data.opportunity_info !== '') {
                params.opportunity_info = data.opportunity_info;
            }
            if (tempAttachFiles.length > 0) {
                let imgObj = {
                    'image_object': tempAttachFiles
                }
                Object.assign(params, imgObj)
            }
            if (isValidParam(changeLog.schedule_date) && changeLog.schedule_date !== '') {
                params.schedule_date = changeLog.schedule_date;
                isSchedule = true;
                msg = getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SMS_SCHEDULE;
            }
            let promise = Promise.resolve(sendSMS(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    let isSMSSend = response.data.isSendSMS;
                    if (isSMSSend) {
                        let smsDetails = {};
                        smsDetails.id = -1;
                        smsDetails.sms_text = response.data.smsText;
                        smsDetails.is_sent = true;
                        smsDetails.is_received = false;
                        smsDetails.date_time = moment().tz(timeZone[appState.me.timezone]).format("YYYY-MM-DD HH:mm");
                        if (getArrayParam(tempAttachFiles).length > 0) {
                            let urlArr = [];
                            tempAttachFiles.forEach(e => urlArr.push(e.src));
                            if (isValidParam(urlArr) && urlArr.length > 0) {
                                smsDetails.t_attachment = urlArr;
                            }
                        }
                        let _arrSMS = arrSMS;
                        _arrSMS.push(smsDetails);
                        setArrSMS(_arrSMS);
                        let log = changeLog;
                        log.arrSMS = _arrSMS;
                        setchangeLog({ ...log });
                        setSmsText('');
                        setSmslength(0);
                        setSelectedTemplate(0);

                        if (data.isDetailView) {
                            dispatch(refreshDetailViewListViewData(true));
                        }
                        if (getArrayParam(tempAttachFiles).length > 0) {
                            tempAttachFiles.splice(0, attachFiles.length);
                            setAttachFiles(tempAttachFiles);

                        }
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    } else {
                        setIsSend(false);
                        //dispatch(showCustomSnackBar("SMS cannot be sent due to invalid number or country code.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                    if (isSchedule) {
                        let schedule_date = null;
                        setSmsText('');
                        setSmslength(0);
                        closeDrawer();
                        dispatch(getTasksRefresh(true));
                        setSchedule_date(schedule_date);
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                });
                document.getElementsByName('sendSMSTextBox')[0].value = null;
                onBlurShrink()
            }
        } catch (error) {
            console.error("Error in 'sendSMS.js -> sendSMS()':" + error);
        }

    }

    const openAttachment = () => {
        setIsSend(false);
        let data = {
            contentStyle: { width: '80%', maxWidth: 'none' },
            type: "link",
            fullWidth: true,
            maxWidth: 'md',
        }
        data.callFrom = constants.SEND_SMS;
        data.files = getArrayParam(attachFiles);
        dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.ASSETMENU_DIALOG_TITLE, (sendData) => {

            let attachFile = {};
            attachFile.name = sendData.name;
            attachFile.src = sendData.src;
            let tempattachFiles = [...attachFiles, attachFile];
            dispatch(getAppDialog(false, null, null, null, null, null));
            setAttachFiles(tempattachFiles);
            setDummyData(true)
        }, data, constants.SEND_SMS));
    }

    const getAttachmetFiles = () => {
        let files = getArrayParam(attachFiles);
        let ul = null;
        let li = files.map((f, index) => {
            return <li key={f.name + index}><span style={{
                float: 'left', textAlign: 'left', overflow: 'hidden',
                textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', width: '150px'
            }} title={f.fileName}> {f.name} </span>
                &nbsp;  <i className="fa fa-times" aria-hidden="true" style={{ paddingRight: '5px', cursor: 'pointer' }} onClick={() => {
                    let tempAttachFiles = attachFiles;
                    tempAttachFiles.splice(index, 1);
                    dispatch(getAppDialog(false, null, null, null, null, null));
                    setAttachFiles(tempAttachFiles);
                    setIsSend(false);
                }} ></i>
            </li>
        });
        if (li != null && li.length > 0) {
            ul = <ul>{li}</ul>;
        }
        return ul;
    }

    const closeDrawer = () => {
        try {
            dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
        } catch (error) {
            console.error("Error in 'sendSMS.js -> closeDrawer()':" + error);
        }
    }

    const handleMoreActionOpen = (event) => {
        setMoreActionOpen(true);
        setAnchorMoreActionEl(event.currentTarget);
    }

    const handleMoreActionClose = () => {
        setMoreActionOpen(false);
        setAnchorMoreActionEl(null);
    }

    const confirmSchedule = (obj) => {
        try {
            setSchedule_date(obj.schedule_date);
            let log = changeLog;
            log.schedule_date = obj.schedule_date;
            setchangeLog({ ...log });
            dispatch(getAppDialog(false, constants.SMS_CONFIRM_SCHEDULE_DIALOG, null, null, null, null));
            checkPhoneTextProvider();
        } catch (error) {
            console.error("Error in 'sendSMS.js -> confirmSchedule()':" + error);
        }
    }

    const schedule = (obj) => {
        try {
            let momentObj = obj.date;
            let db_date = momentObj.format('YYYY-MM-DD HH:mm:ss');
            let user_date = momentObj.format(dateTimeFormat[appState.me.date_format]);
            let data = { user_date: user_date, db_date: db_date, object: constants.INBOX_OBJECT };
            dispatch(getAppDialog(false, constants.SMS_SCHEDULE_DIALOG, null, null, null, null));

            dispatch(getAppDialog(true,
                constants.SMS_CONFIRM_SCHEDULE_DIALOG,
                getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmSchedule, data, constants.SMS_OBJECT));
        } catch (error) {
            console.error("Error in 'sendSMS.js -> schedule()':" + error);
        }
    }

    const handleSchedule = () => {
        if (validateSMSForm()) {
            let data = { object: constants.INBOX_OBJECT };
            dispatch(getAppDialog(true,
                constants.SMS_SCHEDULE_DIALOG,
                getLocalizedStrings().label.BATCH_SMS.SCHEDULE_SMS,
                schedule, data, null));
        }
    }
    const getActionButton = () => {
        try {
            return <div style={{ float: "left", marginLeft: 9, marginTop: '58px' }} >
                <div style={{ ...styles.emailSendBtn, width: '100px', background: appState.me.background }}>
                    <div style={{ float: 'left', width: '60%', margin: 5, textAlign: 'center' }} aria-disabled onClick={() => checkPhoneTextProvider()}>{getLocalizedStrings().label.BATCH_SMS.SEND}</div>
                    <div style={{ float: 'right', width: '10%', borderLeft: '1px solid rgba(159, 46, 10, 0.6)', marginLeft: '5px' }}
                        onClick={(event) => handleMoreActionOpen(event)}
                    >
                        <i className="fa fa-angle-down" aria-hidden="true" style={{ margin: 5, fontSize: 20, marginLeft: '5px' }}></i>
                    </div>
                </div>
                <Menu
                    open={moreActionOpen}
                    anchorEl={anchorMoreActionEl}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onClose={() => handleMoreActionClose()}
                >
                    <MenuItem value={'Schedule'} style={styles.popoverMenuItem} onClick={() => handleSchedule()} >{getLocalizedStrings().label.BATCH_SMS.SEND_LATER}</MenuItem>
                </Menu>
            </div>

        } catch (error) {
            console.error("Error in 'sendSMS.js -> getActionButton()':" + error);
        }
    }

    const loadTemplateList = () => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.SMS_TEMPLATE.GET_TEMPLATE, null));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    let responseData = getArrayParam(response.data);
                    let templateList = [];
                    responseData.forEach(template => {
                        templateList.push({ label: template.name, value: template.id, title: template.name, sms_message: template.sms_message, image_object: template.image_object });
                    });
                    setIsLoading(false);
                    templateList = templateList.filter((obj, index) => {
                        return index === templateList.findIndex(o => obj.value.toString() === o.value.toString());
                    });
                    setTemplatesList(templateList);
                    if (templateList.length > 0) {
                        setSelectedTemplate(templateList[0].value);
                        handleTemplateChange(null, templateList[0]);

                    }


                    if (response.alert) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.alert, null);
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'sendSMS.js -> loadTemplateList()':" + error);
        }
    }

    const handleTemplateChange = (event, option) => {
        setIsSend(false);
        let selectedId = getIntParam(option.value);
        try {
            //let data = getObjectParam(data);
            let params = {};
            params.record_id = getIntParam(data.id);
            params.table_id = OBJECT_TABLEID_MAP[getStringParam(stateObject)];
            params.object = getIntParam(data.object);
            params.templateId = getIntParam(option.value);
            let promise = Promise.resolve(HTTPClient.get(endPoints.SMS_TEMPLATE.GET_TEMPLATE_DATA, params));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    let data = response.data;
                    let sms_message = getStringParam(data.sms_message);
                    let tempAttach = [];
                    if (data.image_object !== null && data.image_object !== "") {
                        let attach = [];
                        attach = data.image_object.split('###');
                        if (attach.length > 0) {
                            attach.forEach(element => {
                                let obj = {};
                                let elm = element.split('@#@');
                                obj.name = elm[0];
                                obj.src = elm[1];
                                tempAttach.push(obj);
                            });
                        }
                    }
                    setSmsText(sms_message);
                    setSmslength(sms_message.length);
                    setSelectedTemplate(selectedId);
                    setAttachFiles(tempAttach);
                    setSmsShrink(true);
                }
            });
        } catch (error) {
            console.error("Error in 'sendSMS.js -> handleTemplateChange()':" + error);
        }
    }
    const onBlurShrink = () => {
        setIsSend(false);
        if (smsText === null || smsText === '') {
            setSmsShrink(false);
        }
    }


    let smBodyHeight = window.innerHeight - 343;
    let tempAttachFiles = getArrayParam(attachFiles);
    let _getAttachmetFiles = getAttachmetFiles();
    if (isLoaded) {
        let tenantCountry = appState.me.tenant_country;
        let showAttachment = false;
        let fieldWidth = '75.7%'
        if (tenantCountry === 'USA' || tenantCountry === 'Canada') {
            showAttachment = false;
            fieldWidth = '75.7%';
        }
        return <div style={{ paddingLeft: 10, paddingRight: 10, marginTop: 14, display: 'box', height: 'fit-content' }}>
            <div style={{ width: '100%', height: 60, maxHeight: 60 }}>
                <div style={{ float: 'left', width: '49%', marginTop: '10px', paddingBottom: '10px' }}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="sf-sendsms-simple-select-outlined-label" className="sf-sendsms">From</InputLabel>
                        <Select
                            key='from-number'
                            label={'From'}
                            value={fromNumber}
                            title={fromNumber}
                            onChange={(event) => tempsetFromNumber(event)}
                            style={{ height: '38px', backgroundColor: '#fff' }}
                            className="sf-sendsmsbg"
                        >
                            {getPhoneNumberMenuItems()}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ float: 'right', width: '49%', marginTop: '2px', paddingBottom: '10px' }}>
                    {templatesList.length > 0 && <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <Autocomplete
                            name="template"
                            defaultValue={templatesList.find(v => v.value === selectedTemplate)}
                            options={templatesList}
                            onChange={(event, option) => handleTemplateChange(event, option)}
                            getOptionLabel={(option) => option.label}
                            style={{ height: 42 }}
                            className="sendMail"
                            disableClearable={false}
                            renderInput={(params) => <TextField {...params} placeholder={getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_TEMPLATE} margin='dense' size='small' className={"sf-fields-bg"} />}
                        />
                    </FormControl>
                    }
                </div>
            </div>
            <div>
                <div style={{ overflowY: 'scroll', minHeight: smBodyHeight, maxHeight: smBodyHeight }}><div style={{}}>{changeLog.arrSMS != undefined && changeLog.arrSMS.length > 0 && changeLog.arrSMS.map((smsDetails, index) => { return <SMSBody key={'row-view-sms-' + index} details={smsDetails} /> })}</div></div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ float: 'left', width: fieldWidth }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key='send-sms-text-box'
                            multiline={true}
                            rows={4}
                            fullWidth={true}
                            label={getLocalizedStrings().label.TWILIO_SMS.YOUR_MESSAGE}
                            name="sendSMSTextBox"
                            className='sf-sendsmsbgchange'
                            style={{ width: '100%', marginTop: '7px' }}
                            value={smsText}
                            onChange={(event) => setSMSBody(event)}
                            onFocus={() => setSmsShrink(true)}
                            onBlur={() => onBlurShrink()}
                            InputLabelProps={{
                                shrink: smsShrink,
                            }}
                        /></FormControl>

                </div>
                {/* {showAttachment && <span onClick={()=>openAttachment()} style={{ cursor: 'pointer', marginLeft: 9, marginTop: 35, color: 'gray', height:'50px' }} title={getLocalizedStrings().message.SMS_INTEGRATION.IMAGE_SELECT}><i className="fa fa-file-image" style={{ fontSize: '55px' }} ></i></span>} */}
                <div style={{ border: '0px solid purple', marginTop: '0px' }}>{getActionButton()}</div>
            </div>
            {/* {tempAttachFiles.length > 0 && <div style={{ float: "left" ,marginTop:'-5px',height:'auto' }}>
                    <span className="row" style={{ height: 'auto' }}>
                        {_getAttachmetFiles}
                    </span>
                </div>} */}
            <div style={{ height: '30px', marginLeft: '14px', marginTop: '5px' }}>
                <span style={{ fontSize: '14px' }}>{smslength}{getLocalizedStrings().label.BATCH_SMS.CHARS}</span>
            </div>
        </div>;

    } else if (!isSMSSupport) {
        return (<div style={{ marginTop: '10px' }}>

            {getLocalizedStrings().message.SMS_INTEGRATION.SMS_SUPPORT}

        </div>

        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
}
export default SendSMS;