 const message = {
    COMMON: {
        NO_ACTIONS_AVAILABLE: 'Nessun oggetto disponibile per l\'azione Aggiunta rapida',
        NOTES_REMOVE_MESSAGE:'Le note selezionate verranno eliminate. Sei sicuro?',
        SELECTED_NOTE_MESSAGE:'Per eseguire questa azione è necessario selezionare almeno una nota.',
        BR_WINDOW_OPEN_CONFORMATION: 'Non supportiamo la funzione di trascinamento della selezione per le campagne. Vuoi aprire la Campagna?',
        CONFIRM_DIALOG_TITLE: 'Finestra di dialogo di conferma',
        ALERT_DIALOG_TITLE: 'Finestra di dialogo Avviso',
        XMS_SUCCESS_ALERT_DIALOG_TITLE: 'Successo',
        XMS_ERROR_ALERT_DIALOG_TITLE: 'Errore',
        SUCCESS_ALERT_DIALOG_TITLE: 'Successo',
        WARNING_DIALOG_TITLE: 'Avvertimento!',
        COPY_EMAIL:'L\'ID e-mail è stato copiato negli appunti',
        EMAIL_VALIDATION:'Inserisci una email valida.',
        EMPTY_EMAIL:'Non è presente alcuna e-mail da copiare',
        SAVE: 'Salvato con successo.',
        MERGE: 'Unione riuscita.',
        UPDATE: 'Aggiornato correttamente.',
        DELETE: 'Eliminato con successo.',
        REMOVE: 'Rimosso con successo.',
        UNLINK: 'Scollegato correttamente.',
        ERROR: 'Il sistema non risponde.',
        ACCESS_DENIED: 'Spiacenti, non si dispone dell\'autorizzazione per eseguire questa operazione. Contattare l\'amministratore.',
        NOTIFICATION_INFO_EMPTY: 'Nessuna notifica disponibile.',
        /** START:  Validation message */
        BLANK: 'vuoto',
        EMAIL: 'è un indirizzo e-mail non valido.',
        VALID_EMAIL: 'L\'indirizzo e-mail inserito non sembra essere un indirizzo valido. Si prega di riprovare.  Suggerimento: assicurati che l\'indirizzo e-mail abbia il formato e-mail @domain.com.',
        URL: 'è un URL non valido.',
        VALID_URL: 'Inserisci un URL valido',
        INTEGER: 'non è un numero intero.',
        VALID_INTEGER: 'Immettere un numero intero valido.',
        FLOAT: 'non è un galleggiante.',
        VALID_FLOAT: 'Inserisci un float valido.',
        VALID_PHONE: 'I numeri di telefono devono essere numerici!',
        VALID_LIST: 'Selezionare un valore.',
        TOO_BIG_SIZE: 'Il valore immesso è troppo grande per questo campo. Immettere un valore inferiore',
        NO_RECORD: 'Nessun record trovato',
        VALIDATE_BLANK: 'non può essere vuoto',
        VALIDATE_INTEGER: 'deve essere un numero intero.',
        VALIDATE_DECIMAL: 'deve essere in decimale.',
        VALIDATE_POSITIVE_DECIMAL: 'deve essere maggiore di 0 e in decimale.',
        VALIDATE_URL: 'Formato URL non valido. Esempio - www.soffront.com',
        VALIDATE_EMAIL: 'Formato e-mail non valido.',
        VALUE: 'valore',
        LIBRARY_DIALOG_TITLE: 'Biblioteca online di Soffront',
        BLANK_EMAIL: 'L\'e-mail è vuota',
        BLANK_NUMBER : 'Il numero è vuoto',
        INVALID_NUMBER :'Si prega di fornire un numero valido ',
        INVALID_EMAIL: 'Si prega di fornire un indirizzo e-mail valido!',
        EMPTY_SEARCH_CRITERIA: 'Inserisci i criteri di ricerca',
        VALIDATE_MORE_CHECKBOX_SELECTION: 'Non è possibile eseguire la ricerca con <*> quando è selezionata più di una casella di controllo!',
        VALIDATE_NO_CHECKBOX_SELECTION: 'Non è possibile eseguire la ricerca con <*> quando è selezionata più di nessuna casella di controllo!',
        NO_DATA: 'Nessun dato trovato',
        DISCONNECT_ALERT_MSG: 'Sei sicuro di voler disconnettere questo client?',
        DISCONNECT_SUCCESS: 'Il client è stato disconnesso.',
        CONFIRM_DELETE_RECORD: 'Il record verrà eliminato. Sei sicuro?',
        DELETE_SUCCESS: ' è stato eliminato correttamente.',
        COULDNOT_LOCATE: "Non siamo riusciti a localizzare ",
        DELETED_OR_ACCESS_DENIED: " Potrebbe essere stato eliminato o potresti non avervi accesso o averlo già aperto.",
        CLICK: 'Clic',
        HERE: 'qui',
        MERGE_ALERT_1: 'Sei sicuro di unirti',
        WITH: 'con',
        MERGE_ALERT_2: 'e cancella',
        DATA_ACCESS_REQUEST_SEND_SUCCESS: 'Richiesta di accesso ai dati inviata a',
        DATA_ACCESS_REQUEST_SEND_FAILURE: 'La richiesta di accesso ai dati non può essere inviata a',
        REQUEST_DATA_ACCESS: 'Richiedere l\'accesso ai dati da',
        WE_WILL_EMAIL: 'Invieremo un\'e-mail',
        AT: 'a',
        TO_APPROVE_REQUEST: 'per approvare la tua richiesta',
        HAVE_BEEN: 'È stato',
        REMINDER_VALUE: 'Il valore del promemoria non può essere vuoto',
        DUPLICATE_EMAIL: 'Disponi già di uno o più record con lo stesso ID e-mail. Fare clic su OK per continuare o fare clic su Annulla per modificare l\'ID e-mail.',
        DUPLICATE_CONTACT_EMAIL: 'Disponi già di uno o più record con lo stesso ID e-mail. Inserisci un ID e-mail diverso.',
        CLIENT_CONNECT_MESSAGE: 'Questo cliente non ha un account con questo indirizzo e-mail in Soffront Online. Per prima cosa chiedi al cliente di creare un account con la stessa email.',
        BLANK_CAMPAIGN_TYPE: 'Il tipo di campagna non può essere vuoto.',
        DUPLICATE_CAMPAIGN_TYPE: 'Il tipo di campagna esiste già.',
        CAMPAIGN_TYPE_SAVE_SUCCESS: 'Il tipo di campagna è stato salvato correttamente.',
        /** END:  Validation message */
        STEPPER_BACK_MESSAGE: 'Ti piace andare alla pagina delle informazioni sul modello? Tutte le modifiche non salvate andranno perse.',
        CENCEL_MOSAICO_MESSAGE: 'Sei sicuro di voler annullare senza salvare?',
        NO_RESULTS_FOUND: 'Nessun risultato trovato',
        FOR: 'per ',
        ENABLE_MAILING_SUCCESS: 'L\'invio di e-mail è stato abilitato correttamente',
        DISABLE_MAILING_SUCCESS: 'Disabilitato con successo l\'invio di e-mail',
        MAILING_FAILURE: 'Questo account non è un tenant CRM',
        TEST: 'Test',
        SELECT_RECORD_FOR_DELETE: 'Selezionare un record da eliminare.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Seleziona un record da modificare.',
        SELECT_RECORD_FOR_DELETE_ONE_RECORD: 'Selezionare un record da eliminare.',
        ASSETMENU_DIALOG_TITLE: 'Seleziona una risorsa',
        EMPTY_RECYCLE_BIN_MSG: 'Deselezionare i record per svuotare il cestino.',
        UNIT_SELECT_ALERT_MESSAGE: 'È possibile selezionare un\'unità alla volta.',
        LOOKUP_MULTI_SELECT: 'Selezionare un solo record.',
        LOOKUP_SELECT_A_RECORD: 'Seleziona un record.',
        TITLE_FIELD_BLANK: 'Il campo del titolo non può essere vuoto',
        BOTH_COMPANY_AND_WORKFLOW_SELECTED: 'Sono stati selezionati sia l\'azienda che il flusso di lavoro.',
        VALIDATE_ZERO: 'non può essere zero.',
        GREENLIGHT_EXPORT_NO_RECORD: 'Non ci sono account nel flusso di lavoro Greenlight per questo intervallo di date.',
        WITHOUT_SAVE_MESSAGE: 'Vuoi continuare senza salvare le modifiche?',
        DELETE_API_KEY: 'Questo eliminerà la tua chiave API in CRM. Ti piacerebbe continuare?',
        API_KEY_MSG_1: 'La chiave API è una stringa crittografata univoca assegnata da',
        API_KEY_MSG_2: 'che identifica l\'utente che effettua una chiamata API. Sostituisce l\'autenticazione di base con nome utente/password.',
        API_KEY_MSG_3: 'Genera la chiave API!',
        API_KEY_MSG_4: 'Chiave API generata',
        SOFFRONT: 'Soffront',
        UNIT_ALERT: 'Seleziona un record per accedere.',
        UNIT_PLAN_NAME_ALERT: 'Seleziona un record per aggiungere il nome del piano.',
        THIS_FIELD_CANNOT_BLANK: 'Questo campo non può essere vuoto.',
        CANNOT_DELETE_ALL_ITEM: 'È necessario disporre di almeno una voce nel menu Mobile.',
        DUPLICATE_MSG_1: 'Hai già uno o più record con lo stesso ',
        DUPLICATE_MSG_2: '. Fare clic su OK per continuare o su Annulla per modificare la proprietà ',
        DUPLICATE_MSG_3: ' e ',
        DUPLICATE_CONTACT_EMAIL_1: 'Hai già uno o più record con lo stesso ',
        DUPLICATE_CONTACT_EMAIL_2: '. Si prega di inserire diversi ',
        INVALID_TIME_ERROR_MSG: 'Si prega di fornire un intervallo di tempo valido.',
        DOESNT_HAVE_ASSOCIATED_1:'Questa ',
        DOESNT_HAVE_ASSOCIATED_2: " non ha un contatto associato.", 
        PHONE_CALL_NOT_ENABLED: 'La telefonata non è abilitata.',
        NOT_CONFG_CALL_SETUP : 'L\'impostazione delle chiamate non è stata configurata.',
        NOT_CONFG_CALL_SMS_SETUP:'Non è stata configurata l\'impostazione di chiamata/sms.',
        SEARCH_NO_CHECKBOX_SELECTION: 'Non è possibile eseguire la ricerca quando non è selezionata alcuna casella di controllo!',
        VALID_SOURCE_LIST: 'Seleziona il campo di origine.',
        LMS_USER_NOT_AVAILABLE: 'LMS non è ancora attivato. Contatta il tuo amministratore.',   
        ADD_BRANCH_BILLINGMASSAGE:'Al momento non disponi di licenze. Acquisterai una nuova licenza per questa unità a $ 40,00 al mese. Inoltre, verrà addebitata una tariffa di installazione di $ 100,00 per impostare e formare il nuovo inquilino.'        
    },
    SALES_TEMPLATE: {
        NAME_BLANK: 'Inserisci un nome per identificare questo modello.',
        SUBJECT_BLANK: 'Inserisci un oggetto per questo modello.',
        DUPLICATE_NAME: 'Hai già un modello denominato %% . Non supportiamo modelli duplicati. Digita un nome diverso e riprova.',
        DELETE: 'Il modello verrà eliminato. Sei sicuro?',
        TEMPLATE_DELETED: 'Modello eliminato con successo',
        TEMPLATE_NAME_ERROR: 'Hai già un modello esistente con questo nome. Inserisci un nome diverso e riprova.',
    },
    AGREEMENT_TEMPLATE: {
        NAME_BLANK: 'Inserisci un nome per identificare questo modello.',
        // SUBJECT_BLANK: 'Inserisci un oggetto per questo modello.',
        DUPLICATE_NAME: 'Hai già un modello denominato %% . Non supportiamo modelli duplicati. Digita un nome diverso e riprova.',
        DELETE: 'Il modello verrà eliminato. Sei sicuro?',
        TEMPLATE_DELETED: 'Modello eliminato con successo',
        TEMPLATE_NAME_ERROR: 'Hai già un modello esistente con questo nome. Inserisci un nome diverso e riprova.',
        DUPLICATE_TEMPLAE_EXIST: 'Il nome del modello esiste già.',
        RECORD_NOT_EXIST: 'Questo record non esiste.',
        SAVE_TO_DOWNLOAD: 'Il modello di accordo non è ancora stato salvato. Salva prima il modello di accordo per scaricarlo.',
        TEMPLATE_CAN_NOT_EMPTY: 'Il nome del modello di accordo non può essere vuoto.',
        SELECT_TEMPLATE: 'Seleziona un modello.',
        SENT_OR_SIGNED: 'Hai già inviato questo accordo una volta. Non puoi inviarlo di nuovo.',
        SIGNATURE_TAGS_NOT_SEQUENCED_1: 'Aggiungi i tag della firma in ordine sequenziale.',
        SIGNATURE_TAGS_NOT_SEQUENCED_2: 'Sembra che tu abbia saltato l\'ordine sequenziale durante l\'aggiunta del tag della firma.',
        SIGNATURE_TAGS_MISSING: 'Nel corpo dell\'accordo non è disponibile alcun tag di firma. Il corpo dell\'accordo deve avere almeno un tag di firma.',
        SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING: 'Sembra che tu abbia utilizzato i campi di unione per una firma senza utilizzare il tag firma. Aggiungi il tag della firma per procedere.',
        AGREEMENT_EDIT: 'È possibile eseguire la modifica solo sui record dell\'accordo e non sugli altri record degli allegati o sui record dell\'accordo già inviati.',
        AGREEMENT_EXPIRED_1: 'Questo accordo è scaduto il ',
        AGREEMENT_EXPIRED_2: 'Si prega di prorogare la data di scadenza del contratto prima di inviarlo.',
    },
    SMS_TEMPLATE: {
        BLANK_MESSAGE: 'Inserisci un messaggio per salvare questo modello.',
        SAVE_MESSAGE: 'SMS salvato con successo.',
        SUCCESS : 'Il tuo sms è stato inviato.'
    },
    MARKETING_TEMPLATE: {
        NAME_BLANK: 'Inserisci un nome per identificare questo modello.',
        SUBJECT_BLANK: 'Inserisci un oggetto per questo modello.',
        DUPLICATE_NAME: 'Hai già un modello denominato %% . Non supportiamo modelli duplicati. Digita un nome diverso e riprova.',
        DELETE: 'Il modello verrà eliminato. Sei sicuro?',
        BACK: 'Se torni indietro, perderai le modifiche non salvate. Vuoi procedere?',
        SELECT_DESIGN: 'Seleziona da un disegno',
        TEMPLATE_UPDATE: 'Modelli di marketing aggiornati',
        CONFIRM1: 'di',
        CONFIRM2: 'I record saranno aggiornati a',
        CONFIRM3: 'Sei sicuro?',
        ALERT1: 'da',
        FROM_NAME_BLANK: 'Inserisci un nome da.',
        FROM_EMAIL_BLANK: 'Si prega di scegliere un\'e-mail da.',
        DUPLICATE_TEMPLATE_NAME: 'Il nome del modello esiste già.'
    },
    BROADCAST_CAMPAIGNS: {
        NAME_BLANK: 'Inserisci un Nome per identificare questa campagna broadcast.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Si prega di selezionare',
        FILTER_TYPE_GROUP: 'un Gruppo',
        FILTER_TYPE_CONTACT: 'a Filtro contatti',
        FILTER_TYPE_ACCOUNT: 'un filtro dell\'account',
        SUBJECT_BLANK: 'Inserisci un oggetto',
        FROM_NAME_BLANK: 'Inserisci un Nome mittente',
        FROM_EMAIL_BLANK: 'Si prega di selezionare un\'e-mail mittente',
        GROUP_NAME_BLANK: 'Il nome del gruppo non può essere vuoto!',
        SAVE: 'Hai creato con successo questa campagna.',
        UPDATE: 'Hai aggiornato correttamente questa campagna.',
        GROUP_NAME_SIZE_LIMIT: 'Il nome del gruppo non può contenere più di 128 caratteri.',
        EXECUTION_CANNOT_CANCEL_1: 'L\'esecuzione non può essere annullata ora.',
        EXECUTION_CANCEL_MSG: 'L\'applicazione tenterà di annullare immediatamente l\'esecuzione.',
        CONFIRM: 'Si prega di confermare.',
        EXECUTION_CANNOT_CANCEL_2: 'Una campagna già in fase di elaborazione non può essere annullata.',
        PURCHASE_MORE: 'per acquistarne altri.',
        CREDITS_REMAINING: 'crediti e-mail rimanenti.',
        VERIFY_BLANK_EMAIL: 'È necessario inserire un indirizzo e-mail per verificare.',
        VERIFY_INVALID_EMAIL: 'è un indirizzo e-mail non valido.',
        SEND_VERIFICATION_EMAIL_SUCCESS: 'Abbiamo inviato l\'e-mail di verifica. Controlla la tua casella di posta e fai clic su Verifica questo indirizzo e-mail. Quindi torna indietro e fai clic sull\'icona di aggiornamento per visualizzare il tuo nuovo indirizzo e-mail.',
        EMAIL_INTEGRATION_NEW: 'Questo indirizzo e-mail è già stato inviato per la verifica! Controlla la tua casella di posta e fai clic su Verifica questo indirizzo e-mail. Quindi torna indietro e fai clic sull\'icona di aggiornamento per visualizzare il tuo nuovo indirizzo e-mail.',
        EMAIL_INTEGRATION_VERIFIED: 'Questo indirizzo email è già stato verificato!',
        BD_NAME_COPY_ERROR: 'Hai una campagna esistente con questo nome. Inserisci un nome diverso per questa campagna',
        STEPPER_BACK_MESSAGE: 'Ti piace andare alla pagina delle informazioni sulla trasmissione? Tutte le modifiche non salvate andranno perse.'
    },
    SPACE: ' ',
    LIST_VIEW: {
        SELECT_RECORD: 'Selezionare un record per eseguire ulteriori azioni.',
        SELECT_RECORD_FOR_CONVERT: 'Seleziona un record da convertire.',
        SELECT_RECORD_FOR_EDIT: 'Seleziona un record da modificare.',
        SELECT_RECORD_FOR_COPY: 'Selezionare un record da copiare.',
        SELECT_RECORD_FOR_SEND_EMAIL: 'Selezionare un record da copiare.',
        MULTIPLE_RECORD_FOR_EDIT: 'Selezionare un record da modificare.',
        MULTIPLE_RECORD_FOR_COPY: 'Selezionare un record da copiare.',
        MULTIPLE_RECORD_FOR_MORE: 'Selezionare un record e riprovare.',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Selezionare un record da copiare.',
        CANNOT_COPY_APPOINTMENT_WORKFLOW: 'Non è possibile copiare questo flusso di lavoro.',
        SELECT_RECORD_FOR_ATTACH_ONE_RECORD: 'Selezionare un singolo allegato.',
        SELECT_MAXIMUM_FIELD_FOR_SETUP: 'È stato raggiunto il limite massimo di 15 campi selezionati.',
        SELECT_MAXIMUM_FIELD_FOR_LISTVIEW_SETUP: 'È stato raggiunto il limite massimo di 30 campi selezionati.',
        CONVERT_CONTACT_TO_ACCOUNT_CONFIRM: 'Questo contatto è già associato a un account. Fare clic su OK per visualizzare i dettagli dell\'account.',
        SHARE_PERMISSION: 'Non si dispone dell\'autorizzazione per condividere questo record.',
        NOT_ADDED: 'Non hai aggiunto',
        FIELDS_TO_VIEW: 'campi da visualizzare.',
        SETUP_MINIMUM_FIELD_ALERT: 'Si prega di aggiungere un altro campo. Richiediamo un minimo di due campi per la vista.',
        SELECT_ANOTHER_TABLE: 'Selezionare un\'altra tabella e riprovare.',
        WEB_FORM_ALERT1: 'da',
        WEB_FORM_ALERT2: 'I moduli Web selezionati non vengono eliminati in quanto vengono utilizzati in Progettazione automazioni.',
        SETUP_WORKFLOWSTAGE_MINIMUM_FIELD_ALERT: 'Si prega di aggiungere un campo per la colonna. È richiesto un minimo di 1 campo per la vista.',
        SELECT_RECORD_FOR_SEND_SMS: 'Seleziona un record per inviare un messaggio di testo.',
        SELECT_RECORD_FOR_CALL: 'Selezionare un record per effettuare la chiamata.',
        SELECT_MORE_RECORD_ACTION_ALERT: 'Selezionare un record per eseguire altre azioni.',
        SELECT_RECORD_FOR_DOWNLOAD: 'Seleziona un record per scaricare gli account.',
        CHECKBOX_COLOR_GREEN_TITLE: 'Ultimo aggiornamento entro 30 giorni',
        CHECKBOX_COLOR_YELLOW_TITLE: 'Ultimo aggiornamento entro 30-60 giorni',
        CHECKBOX_COLOR_RED_TITLE: 'Ultimo aggiornamento prima di 60 giorni',
        STATUS_FIELD_NOT_ADDED_FOR_VIEW: 'Non è stato aggiunto alcun campo di stato da visualizzare.',
        SELECT_MAXIMUM_FIELD_FOR_COLUMN_SETUP: 'You reached the maximum limit of total 5 selected fields.',
        NO_EMAIL_TO_SEND_MAIL: 'Non è disponibile un indirizzo e-mail per inviare un\'e-mail.',
        REQUIRED_FIELD_CONVERT_FAILED: 'Conversione non riuscita. Mancano uno o più campi obbligatori.',
        SELECT_RECORD_FOR_To_EXCEL: 'Si prega di selezionare un record To Excel.',
        SELECT_RECORD_FOR_ADD_PLAN_NAMES_ONE_RECORD: 'Seleziona un record per aggiungere i nomi dei piani.',
        SELECT_RECORD_FOR_ADD_TASK: "Per favore selezionare un record per aggiungere l'attività'",
        UNIT_TASK_MESSAGE: 'Puoi aggiungere attività solo alle unità direttamente collegate a te.',
        UNIT_SEND_EMAIL: "Puoi aprire l'invio di e-mail solo alle unità direttamente collegate a te",
        UNIT_PHONE_NUMBER: 'Puoi aprire la chiamata telefonica solo alle unità direttamente collegate a te',
        FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED: 'Non è consentito eliminare il record dell\'allegato di tipo FDD o FA.',
        MULTIPLE_SELECT_FDD_FA_MSG_1: 'Fuori da ',
        MULTIPLE_SELECT_FDD_FA_MSG_2: ' record allegati selezionati ',
        MULTIPLE_SELECT_FDD_FA_MSG_3: ' I record di allegati non verranno eliminati poiché si tratta di record di allegati di tipo FDD o FA. Sei sicuro?',
    },
    AB_CAMPAIGNS: {
        NAME_BLANK: 'Inserisci un nome per la campagna.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Si prega di selezionare',
        FILTER_TYPE_GROUP: 'un Gruppo',
        FILTER_TYPE_CONTACT: 'a Filtro contatti',
        FILTER_TYPE_ACCOUNT: 'un filtro dell\'account',
        GROUP_FILTER_BLANK: 'Selezionare un gruppo.',
        CONTACT_FILTER_BLANK: 'Seleziona un filtro contatti.',
        ACCOUNT_FILTER_BLANK: 'Seleziona un filtro account.',
        SUBJECT_BLANK: 'Immettere un oggetto per il messaggio.',
        FROM_NAME_BLANK: 'Immettere il nome del mittente.',
        FROM_EMAIL_BLANK: 'Inserisci l\'indirizzo e-mail del mittente.',
        GROUP_NAME_BLANK: 'Il nome del gruppo non può essere vuoto!',
        AB_DISTRIBUTION: 'Immettere la distribuzione a/b.',
        INVALID_AB_DISTRIBUTION: 'Distribuzione a/b non valida.',
        INVALID_WINNING_MARGIN: 'Il margine per il vincitore non è valido.',
        ZERO_WINNING_MARGIN: 'Immettere il margine per il vincitore.',
        MESSAGE_B_NOT_FILLED: 'Inserisci i dettagli del messaggio B.',
        AB_DISTRIBUTION_OUT_OF_RANGE: 'Immettere un valore compreso tra 1 e 99 per il test ',
        DAYS_BLANK: 'Inserisci quanti giorni desideri attendere prima di inviare il messaggio vincente alla lista rimanente.',
        INVALID_DAYS: 'Voce non valida. Inserisci quanti giorni desideri attendere prima di inviare il messaggio vincente alla lista rimanente.',
        SAVE: 'Hai creato con successo questa campagna.',
        UPDATE: 'Hai aggiornato correttamente questa campagna.',
        EXECUTION_CANNOT_CANCEL_1: 'L\'esecuzione non può essere annullata ora.',
        EXECUTION_CANCEL_MSG: 'L\'applicazione tenterà di annullare immediatamente l\'esecuzione.',
        CONFIRM: 'Si prega di confermare.',
        EXECUTION_CANNOT_CANCEL_2: 'Una campagna già in fase di elaborazione non può essere annullata.',
        CAMPAIGN_EXECUTE_IN_60_MIN: 'Questa campagna verrà eseguita in 60 minuti.',
        OK_TO_CONFIRM: 'Fare clic su OK per confermare',
        ERROR_MSG_1: 'Seleziona il modello.',
    },
    REPEAT_DIALOG: {
        END_DATE_BLANK: 'La data di fine non può essere vuota.',
        INVALID_END_DATE: 'La data di inizio non può essere maggiore o uguale alla data di fine.',
        DAY_CHECKBOX_BLANK: 'Almeno controllare un giorno.',
        DELETE_RECUR_TITLE: 'Elimina ricorrente',
        EDIT_RECUR_TITLE: 'Modifica ricorrente',
        REPEAT: 'Ripetere'
    },
    BATCH_ACTION: {
        CONVERT_SUCCESSFULL: ' sono stati convertiti con successo.',
        SINGLE_CONVERT_SUCCESSFULL: ' è stato convertito correttamente.',
        CONVERT_UNSUCCESSFULL: 'Azione non possibile da eseguire',
        CONVERT_SAME_STAGE: ' record è già in ',
        MULTI_SHARE_SUCCESSFULL: ' sono stati condivisi con successo.',
        SINGLE_SHARE_SUCCESSFULL: ' è stato condiviso con successo.',
        SHARE_UNSUCCESSFULL: 'Azione non possibile da eseguire',
        MULTI_ASSIGN_SUCCESSFULLY: ' sono stati assegnati con successo.',
        SINGLE_ASSIGN_SUCCESSFULLY: ' è stato assegnato con successo.',
        ASSIGN_UNSUCCESSFULL: 'Azione non possibile da eseguire',
        COMPLETE_SUCCESSFULL: ' è stato completato con successo.',
        BATCH_COMPLETE_SUCCESSFULL: ' sono stati completati con successo.',
        COMPLETE_UNSUCCESSFULL: 'Azione non possibile da eseguire',
        GROUP_ADDED_SUCCESSFULLY: 'Aggiunto con successo ai gruppi',
        GROUP_REMOVED_SUCCESSFULLY: 'Rimosso con successo da tutti i gruppi.',
        GROUP_CLICK_TO_CONFIRM: ' verrà aggiunto ai gruppi selezionati. Fare clic su "OK" per confermare.',
        GROUP_BLANK_ADDED_BATCH: 'Seleziona almeno un gruppo.',
        NOTE_TXT_CANNOT_BE_BLANK: 'Il testo non può essere vuoto.',
        WORKFLOW_CONFIRMATION: 'Il flusso di lavoro verrà modificato per questo record. Fare clic su OK per confermare.',
        BATCH_WORKFLOW_CONFIRMATION: 'Il flusso di lavoro verrà modificato per questi record. Fare clic su OK per confermare.',
        WORKFLOW_APPLIED: 'Il flusso di lavoro è stato applicato correttamente.',
        RECORDS_WITH_DIFFERENT_WORKFLOW: 'Sono stati selezionati record con flussi di lavoro diversi. Fare clic su OK per continuare ed eseguire l\'operazione di applicazione del flusso di lavoro oppure fare clic su Annulla per selezionare nuovamente i record.',
        RECORDS_WITH_DIFFERENT_WORKFLOW_STATUS: 'Sono stati selezionati record con stato diverso. Fare clic su OK per continuare ed eseguire l\'operazione di applicazione del flusso di lavoro oppure fare clic su Annulla per selezionare nuovamente i record.',
        ACTIVE_SUCCESSFULL: 'Lo stato è stato modificato in Attivo.',
        INACTIVE_SUCCESSFULL: 'Lo stato è stato modificato in Inattivo.',
        BATCH_EDIT_BLANK: 'Il campo del valore non può essere vuoto!',
        INVALID_WORKFLOW_STAGE: 'Non è possibile convertire il record. Lo stato del record selezionato non è disponibile nel flusso di lavoro corrente. Premere OK per modificare lo stato o il flusso di lavoro',
        CONFIRMATION_DELETE_MSG: ' saranno cancellati. Sei sicuro?',
        CONFIRMATION_REMOVE_MSG: ' verrà rimosso. Sei sicuro?',
        CONFIRMATION_UNLINK_MSG: ' verrà scollegato. Sei sicuro?',
        CONVERT_ALERT_MSG: ' saranno convertiti in ',
        CLICK_TO_CONFIRM: ' Fare clic su OK per confermare.',
        ASSIGN_ALERT_MSG: ' I record saranno assegnati a ',
        ROUND_ROBIN_BASIS: ' I record saranno assegnati a su base round robin. Sei sicuro?',
        CONFIRM_MSG: 'Sei sicuro?',
        RECORDS: 'record',
        RECORD: 'documentazione',
        ALL_RECORDS: 'Tutti i record',
        RECORD_WILL_BE_UPDATED: 'I record saranno aggiornati a',
        CONFIRM_UNSUBSCRIBE_RECORD: ' verrà annullata l\'iscrizione. Sei sicuro?',
        UNSUBSCRIBE: 'Hai annullato con successo l\'iscrizione ai contatti selezionati.',
        USE_ASSIGN_RULE_ALERT_MSG: ' I record saranno assegnati ai rappresentanti di vendita come da ',
        EMPTY_RECYCLE_BIN: 'Tutti i record verranno eliminati definitivamente dal Cestino. Sei sicuro?',
        PERMANENT_DELETE_MSG: ' verrà eliminato definitivamente. Sei sicuro?',
        INVALID_STATUS_FOR_WORKFLOW_STAGE: 'Lo stato del record selezionato non è disponibile nel flusso di lavoro corrente. Premere OK per modificare lo stato del record.',
        CONTACT_STATUS_UPDATE_SUCCESS: ' Lo stato è stato aggiornato correttamente.',
        PUBLISH_SUCCESSFULL: 'Lo stato è stato modificato in Pubblicato.',
        UNPUBLISH_SUCCESSFULL: 'Lo stato è stato modificato in Non pubblicato.',
        RECORDS_NOT_IN_WORKFLOW: 'Tutti i record selezionati non sono presenti nel flusso di lavoro dei contatti.',
        RECORD_NOT_IN_WORKFLOW: 'Non è possibile eseguire la conversione perché il record selezionato non è presente nel flusso di lavoro dei contatti.',
    },
    BATCH_ACTION_VALIDATION: {
        ADDTOGROUP_BLANK: 'Il record selezionato verrà rimosso da tutti i gruppi. Fare clic su "OK" per confermare.',
        ADDTOGROUP_NAMEBLANK: 'Il nome del gruppo non può essere vuoto.',
        SHARE_WITH_BLANKUSER: 'Si prega di selezionare almeno un utente.',
        ASSIGN_BLANKUSER: 'Seleziona un utente.',
        CONVERT_SELECT_STATUS: 'Seleziona uno stato per ',
        MAP_INSUFFICIENT_INFO: 'Informazioni insufficienti per mostrare la mappa ',
        GROUP_ALREADY_EXIST: 'Il nome del gruppo esiste già!',
        ALREADY_ACTIVE: 'Record già in stato Attivo.',
        ALREADY_INACTIVE: 'Record già in stato Inattivo.',
        ALREADY_PUBLISH: 'Record già in stato Pubblicato.',
        ALREADY_UNPUBLISH: 'Record già in stato Non pubblicato.',
    },
    ATTACHMENT: {
        ATTACHMENT_UPLOAD: ' Allegato caricato correttamente',
        ATTACHMENT_LINK_ADDED: 'Il collegamento al documento è stato aggiunto con successo!',
        ATTACHMENT_UPLOAD_IMAGE: 'Immagine caricata con successo',
        ATTACHMENTS_UPLOAD: ' Allegati caricati con successo',
        VALIDATE_ATTACHMENT: 'Seleziona un file da allegare  ',
        ATTACHMENT_UPLOAD_FAILURE: ' Questo file esiste già.',
        VALIDATE_EMPTY_FILE: ' La dimensione del file di caricamento non può essere 0 ',
        VALIDATE_FILE_SIZE: 'La dimensione del file di caricamento non può essere superiore a 5 MB',
        VALIDATE_LOGO_SIZE: 'La foto selezionata è troppo grande per essere utilizzata. La dimensione massima consentita per il file è di 500 KB',
        VALIDATE_BLANK_FILE: 'Non è possibile caricare un file vuoto',
        VALIDATE_LOGO_EXTENSION: 'Seleziona un file jpg, gif o png!',
        VALIDATE_FAVICON_EXTENSION: 'Seleziona un file ico, gif o png!',
        ATTACHMENT_LOGO_TEXT: 'Puoi caricare un file jpg, gif o png.',
        ATTACHMENT_LOGO_SIZE_TEXT: ' La dimensione massima consentita del file è 500 KB.',
        UPLOAD_IMAGE: 'Carica un\'immagine',
        UPLOAD_FILE: 'Selezionare i file da caricare (fino a cinque file alla volta)',
        ADD_LINK: 'Aggiungi un collegamento esterno',
        VALIDATE_MAX_ATTACHMENTS: 'Non è possibile caricare più di cinque file',
        SELECT_IMAGE_TO_UPLOAD: 'Seleziona un\'immagine da caricare',
        MAX_SIZE_2_MB_ALERT: 'La dimensione del file di caricamento non può essere superiore a 2 MB',
        VALID_FILE_UPLOAD: 'Puoi caricare un file jpg, gif o png dal computer.',
        MAX_SIZE_2_MB: 'La dimensione massima consentita per il file è di 2 MB.',
        CANNOT_UPLOAD_EXECUTABLE_FILE: 'Non è possibile caricare il file eseguibile',
        THE_ATTACHMENT: 'L\'allegato',
        DOCUMENT_LINK: 'Collegamento al documento', 
        FOLDER_NAME: 'Nome della cartella',
        FILE_NAME:'Nome del file',
        ALREADY_EXIST: 'esiste già.',
        CANNOT_BE_DELETED: 'non può essere eliminato.',
        CLICK_TO_DELETE: 'Fare clic su OK per eliminare ',
        EMPTY_FOLDER_ERROR_DOWNLOAD: 'Impossibile eseguire il download perché la cartella è vuota.',
        EMPTY_FOLDER_ERROR_PUSH: 'Non è possibile eseguire il push poiché la cartella è vuota.',
        RENAME_SUCCESSFULLY: 'La cartella è stata rinominata correttamente.',
        DELETE_SUCCESSFULLY: 'Cartella eliminata correttamente.',
        ADDED_SUCCESSFULLY: 'aggiunto correttamente.',
        DOCUMENT_LINK_ADDED_SUCCESSFULLY: 'Collegamento al documento aggiunto correttamente.',
        DOCUMENT_LINK_UPDATE_SUCCESSFULLY: 'Collegamento al documento aggiornato correttamente.',
        DOCUMENT_LINK_DELETE_SUCCESSFULLY: 'Collegamento al documento eliminato correttamente.',
        FILE_ADDED_SUCCESSFULLY: 'File aggiunto con successo.',
        FILE_RENAME_SUCCESSFULLY: 'Il file è stato rinominato correttamente.',
        FILE_DELETE_SUCCESSFULLY: 'File eliminato correttamente.',
        IMAGE_DELETE_SUCCESSFULLY: 'Image eliminato con successo.',
        IMAGE_RENAME_SUCCESSFULLY: 'L\'immagine è stata rinominata correttamente.',
        IMAGE_NAME: 'Nome dell\'immagine',
        MAX_SIZE_50_MB: 'La dimensione massima consentita per il file è di 50 MB.',
        MAX_LIMIT: 'La lunghezza massima consentita per il file è 500, selezionare un\'altra cartella',
    },
    AUTOMATION_DESIGNER: {
        STEPPER_BACK_MESSAGE: 'Ti piace andare alla pagina delle informazioni sul modello? Tutte le modifiche non salvate andranno perse.',
        MAX_CONNECTION: 'Massimo no. di connessioni.',
        TOUCH_CAMPAIGN_NAME: "Seleziona un Nome per questa campagna touch",
        SELECT_FILTERS: "Si prega di selezionare un",
        SELECT_FILTERS_A: "Seleziona un",
        START_DATE: "Seleziona una data di inizio per",
        EMPTY_WEBSITE_LINK: "Non è possibile aggiungere un collegamento al sito Web vuoto.",
        CANNOT_START_WITH_DECISSION: 'Non si può iniziare con una decisione. Inizia con una delle opzioni nel menu "Inizia con" a sinistra.',
        CANNOT_START_WITH_ACTION: 'Non è possibile iniziare con un\'azione. Inizia con una delle opzioni nel menu "Inizia con" a sinistra.',
        CONNECT_WITH_PROPER_NODE: 'Connettiti con il nodo appropriato',
        FROM_NAME: 'Immettere un Nome mittente per',
        FROM_EMAIL: 'Si prega di selezionare un\'e-mail mittente',
        RESEND_AFTER: 'Si prega di inserire Resend After in giorno/i',
        SELECT_WEB_FORM: 'Si prega di selezionare un modulo web',
        SELECT_GROUP: 'Seleziona un gruppo.',
        SELECT_GROUP_OPERATION: 'Si prega di scegliere un\'operazione di gruppo.',
        ENTER_NOTE: 'Inserisci la tua nota.',
        SELECT_ONE_RECIPIENT: 'Seleziona almeno un destinatario',
        SELECT_USER: 'Seleziona un utente.',
        ENTER_TASK_NAME: 'Inserisci un nome per l\'attività',
        PROVIDE_DUEBY_FIELD: 'Specificare un valore nel campo Scadenza da!',
        ENTER_LINK: 'Inserisci un link.',
        PROVIDE_VALID_URL: 'Si prega di fornire un URL del sito Web valido!',
        CATCH_ALL_LINK_CONNECTION: 'Hai già una condizione di clic sul link "prendi tutto',
        ENTER_SCORE: 'Si prega di inserire un punteggio',
        INVALID_SCORE: 'Hai inserito un punteggio non valido',
        ENTER_SUBJECT: 'Inserisci un oggetto',
        ENTER_BODY: 'Inserisci il contenuto del corpo',
        ENTER_INTERVAL_VALUE: 'Immettere un valore per l\'intervallo',
        SELECT_ONE_HOUR: 'Selezionare 1 ora o più per l\'intervallo orario.',
        BLANK_CAMPAIGN_NAME: 'Il nome della campagna non può essere vuoto.',
        CAMPAIGN_SAVED_SUCCESSFULLY: 'Questa campagna è stata salvata con successo',
        BLANK_CANVAS: 'Non è possibile salvare un\'area di lavoro vuota.',
        NOT_ROOT_NODE: 'non può essere un nodo radice.',
        START_DATE_MUST_BE_GREATER: 'La data di inizio deve essere maggiore di quella della campagna touch precedente.',
        EDIT_AND_COMPLETE_DETAILS: 'Si prega di modificare e completare i dettagli del file',
        PROVIDE_WAIT_INTERVAL: 'Si prega di fornire un intervallo di attesa superiore a zero.',
        CHECK_INTERVAL_WITH_PREVIOUS_TOUCH_CAMPAIGN: 'Controlla l\'intervallo con i giorni di reinvio della campagna di contatto precedenti.',
        CHECK_START_DATE_OR_WAIT_BEFORE_SAVE_TC: 'Controlla la data di inizio o attendi prima di salvare la campagna touch.',
        PROVIDE_START_DATE: 'Si prega di fornire una data di inizio.',
        INTERVAL_EXPEXT_ZERO: 'Specificare un intervallo diverso da zero.',
        EMPTY_LINK_NODE: 'Non è possibile aggiungere un nodo di collegamento vuoto.',
        DELETE_NODE_CONFIRMATION: 'Il nodo verrà eliminato. Vuoi continuare?',
        MSG_CANT_BE_DELETED: 'Questo messaggio è stato inviato e non può essere eliminato.',
        FIRST_MSG_CANT_BE_DELETED: 'Non è possibile eliminare il primo messaggio di una campagna touch',
        FIRST_WAIT_CANT_BE_DELETED: 'Non è possibile eliminare la prima attesa di una campagna touch',
        BLANK_INTERVAL: 'L\'intervallo non può essere vuoto',
        PROVIDE_POSITIVE_INTERVAL: 'Specificare un intervallo maggiore di 0',
        WONT_BE_ABLE_TO_DELETE: 'Non sarai in grado di eliminarlo.',
        URL_NOT_EXIST: 'L\'URL fornita non è disponibile nel corpo dell\'e-mail.',
        GROUP_NAME_BLANK: 'Il nome del gruppo non può essere vuoto!',
        SELECT_LANDING_PAGE: 'Seleziona una pagina di destinazione',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'Hai già un Automation Designer aperto, chiudilo o salvalo per aprire un nuovo Automation Designer.',
        SAVE_DIAGRAM_BEFORE_COPY: 'Si prega di salvare il diagramma prima di eseguire l\'operazione di copia.',
        NO_TOUCH_FOUND: 'Campagna No Touch trovata.',
        ADD_ONE_FIELD: 'Si prega di aggiungere almeno un campo',
        SELECT_FIELD_VALUE: 'Seleziona un valore di campo',
        INVALID_URL: 'è un URL non valido.',
        INVALID_INTEGER: 'non è un numero intero.',
        INVALID_EMAIL: 'è un indirizzo e-mail non valido.',
        ALLOWED_UPTO_FOUR_FIELDS: 'L\'aggiornamento è consentito fino a 4 campi. Non è possibile aggiungere un\'altra riga di una coppia di valori di campo.',
        WILL_BE_UPDATED: 'verrà aggiornato. Sei sicuro?',
        DUPLICATE_TOUCH_NAME: 'Hai già una campagna Send Touch denominata',
        TOOLTIP_MESSAGE_SEND_MAIL: 'Inizia con una campagna touch che invia una serie di e-mail. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_WEB_FORM: 'Inizia definendo cosa succede dopo l\'invio di un modulo web. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_LANDING_PAGE: 'Inizia definendo cosa succede dopo l\'invio di una pagina di destinazione. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_WEBSITE_VISIT: 'Inizia definendo cosa succede dopo che un contatto visita una pagina specifica del tuo sito web. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_LINK_CLICK: 'Definisci cosa succede dopo che un contatto fa clic su un link nella tua email. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra e collegalo con un messaggio touch.',
        TOOLTIP_MESSAGE_EMAIL_OPEN: 'Definisci cosa succede dopo l\'apertura di un messaggio tocco da parte di un contatto. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra e collegalo con un messaggio touch.',
        TOOLTIP_MESSAGE_UNSUBSCRIBE: 'Definisci cosa succede dopo che un contatto annulla l\'iscrizione a un messaggio touch. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra e collegalo con un messaggio touch.',
        TOOLTIP_MESSAGE_SCORE_REACHED: 'Definisci cosa succede dopo che un contatto raggiunge una soglia di punteggio. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_SMS_REPLY: 'Definisci cosa succede dopo che un contatto ha risposto a un sms. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_AUTO_MAIL: 'L\'e-mail di invio verrà utilizzata per inviare e-mail una tantum e e-mail touch. Questa azione può essere aggiunta dopo una campagna touch o qualsiasi decisione o azione.',
        TOOLTIP_MESSAGE_CHANGE_GROUP: 'Aggiungere o rimuovere account e contatti dai gruppi. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_ADD_NOTE: 'Aggiungi una nota all\'account. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_NOTIFY: 'Inviare una notifica a un utente CRM. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_ADD_TASK: 'Assegnare un\'attività a un altro utente. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_UPDATE_FIELD: 'Aggiorna il valore di un campo per l\'account o il contatto. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_ASSIGN: 'Riassegnare l\'account a un altro utente. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_CONVERT: 'Converti l\'account in un nuovo stato. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOOLTIP_MESSAGE_SEND_SMS: 'Invia SMS a un gruppo, a un filtro account o a un filtro contatti. Per usarlo, trascinalo e rilascialo sull\'area di lavoro a destra.',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'Hai già una campagna touch denominata',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Inserisci un nome diverso. ',
        INTERVAL_MODE_ADD_MSG_1: 'Si prega di fornire un',
        INTERVAL_MODE_ADD_MSG_2: 'dopo il valore precedente tranne zero',
        AUTOMATED_CAMPAIGN_TITLE: 'Campagne automatizzate',
        CANNOT_CONNECT_MULTIPLE: 'Impossibile connettere più  ',
        CONNECTION_EXIST: 'Hai già una connessione attiva da  ',
        REMOVE_CONNECTION_ALERT: 'Se rimuovi la connessione, perderai tutti i passaggi seguenti',
        CONTINUE_ALERT: 'Vuoi continuare?',
        CANNOT_ADD: 'Non è possibile aggiungere',
        DIRECT_UNDER: 'direttamente sotto un',
        DIRECT_UNDER_AN: 'direttamente sotto un',
        MULTIPLE_TIME_SELECTED_MSG_1: 'È stata selezionata l\'opzione',
        MULTIPLE_TIME_SELECTED_MSG_2: 'campi più volte per l\'aggiornamento. Rimuovi le righe duplicate.',
        CANNOT_BE_LEAF_NODE: 'non può essere un nodo foglia.',
        CLICK: 'Clic',
        HERE: 'qui',
        PURCHASE_MORE: 'per acquistarne altri.',
        CREDITS_REMAINING: 'crediti e-mail rimanenti.',
        STOP_CAMPAIGN: 'Interrompi la campagna per apportare modifiche.',
        SELECT_EMAIL_TEMPLATE: 'Seleziona un modello di e-mail',
        NO_INFO_EXISTS: 'Non esistono informazioni',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Selezionare un record da copiare.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Seleziona un record da modificare.',
        CANNOT_SAVE_CAMPAIGN: "Impossibile salvare questa campagna. Vedi i blocchi rossi nella campagna per gli errori.",
        ADD_GROUP_CLICK: 'Clic',
        ADD_GROUP_CLICK_HERE: 'qui',
        CLICK_TO_ADD_NEW_GROUP: 'Per aggiungere un nuovo gruppo',
        MESSAGE_SAVED_SUCCESSFULLY: 'Questo messaggio è stato salvato',
        MESSAGE_NOT_SAVED_SUCCESSFULLY: 'Il messaggio non è stato salvato correttamente',
        CONTACT_FILTER_BLANK: 'Seleziona un filtro contatti.',
        ACCOUNT_FILTER_BLANK: 'Seleziona un filtro account.',
        QUERY_FILTER_BLANK: 'Seleziona una Query',
        SAVE_AUTOMATION_FOR_TEST: 'Si prega di salvare la campagna di automazione.',
        BIRTHDAY_CAMPAIGN_NAME: 'Seleziona un Nome per questa campagna di compleanno',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'Hai già una campagna di compleanno denominata',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Inserisci un nome diverso. ',
        BIRTHDAY_FIRST_MSG_CANT_BE_DELETED: 'Non puoi eliminare il primo messaggio di una campagna di compleanno',
        MIGRATION: 'Questa campagna automatizzata è in fase di aggiornamento al nuovo generatore di campagne. Grazie per la vostra pazienza.',
        MIGRATION_DIALOG_TITLE: 'Aggiornamento in corso...',
        MIGRATION_NOTE: 'Dopo l\'aggiornamento, la campagna di automazione inizierà a essere eseguita il giorno in cui si esegue l\'operazione di avvio.',
        BIRTHDAY_CAMPAIGN_NO_REPORT: 'Campagna di compleanno ancora da inviare ai destinatari.',
        RUNNING_CAMPAIGN_CANNOT_CANCEL: 'Una campagna in corso non può essere annullata.',
        CONFIRM_CANCEL_SCHEDULE: 'Vuoi annullare la campagna programmata?',
        CAMPAIGN_SCHEDULE_SUCCESSFULLY: 'Questa campagna è stata programmata con successo.',
        SCHEDULED_CAMPAIGN_CANCEL_SUCCESSFULLY: 'Questa campagna è stata annullata con successo.',
        CAMPAIGN_START_IMMEDIATELY: 'Questa campagna è stata impostata con successo per iniziare immediatamente.',
        ENTER_FROM_NAME: 'Si prega di inserire il nome del mittente',
        ENTER_FROM_EMAIL: 'Si prega di inserire dall\'e-mail.'
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME_BLANK: 'Fornisci un nome di dominio per generare il codice di monitoraggio.',
        DOMAIN_NAME_INVALID: 'Fornisci un nome di dominio valido.',
        TRACKING_CODE_BLANK: 'Non c\'è alcun codice di monitoraggio da copiare.',
        TRACKING_CODE_ALREADY_GENERATED: 'Il codice di monitoraggio è già stato generato.',
        DONE: 'Il tuo nome di dominio è stato registrato.',
        SET_UP_INFO: 'Copia e incolla questo codice in ogni pagina del tuo sito, appena prima del tag body di fine. Al termine, torna a questa pagina e fai clic sul pulsante Fine.'
    },
    MAIL: {
        SUCCESS: 'La tua email è stata inviata.',
        BLANK_FROM_EMAIL: 'L\'e-mail di Da è vuota.',
        INVALID_FROM_EMAIL: 'L\'indirizzo e-mail del mittente non è valido nel campo Da.',
        INVALID_CC_EMAIL: 'Hai un indirizzo e-mail CC non valido nel campo CC.',
        INVALID_BCC_EMAIL: 'Hai un indirizzo e-mail Ccn non valido nel campo Ccn.',
        BLANK_SUBJECT: 'L\'oggetto è vuoto.',
        BLANK_EMAIL_BODY: 'Il corpo dell\'e-mail è vuoto.',
        BATCH_SEND_MAIL_MSG_1: 'Fuori dal',
        BATCH_SEND_MAIL_MSG_2: 'hai selezionato, solo',
        BATCH_SEND_MAIL_MSG_3: 'hanno scelto di ricevere le tue e-mail. Invieremo l\'e-mail solo a questi',
        BATCH_SEND_MAIL_MSG_4: 'Nessuno dei',
        BATCH_SEND_MAIL_MSG_5: 'hanno scelto di ricevere le tue e-mail. Non invieremo questa e-mail. Si prega di chiudere questa scheda',
        NO_RECIPIENT: 'Non hai inserito alcun destinatario per questa e-mail',
        NO_RECIPIENT_FOUND: 'Nessun destinatario trovato per questa e-mail.',
        BLANK_TO_EMAIL: 'Nessun indirizzo e-mail nel campo A.',
        INVALID_TO_EMAIL: 'Indirizzo e-mail non valido nel campo A.',
        SCHEDULE_SUCCESS: 'La tua email è stata programmata con successo.',
        RESCHEDULE_SUCCESS: 'L\'e-mail è stata riprogrammata con successo',
        TEMPLATE_NOT_SELECTED: 'Seleziona un modello',
        NO_ACTIVE_AGREEMENT_TEMPLATE_MSG: "Non disponi di alcun modello di accordo attivo.",
        SELECT_DIFFERENT_SIGNER: "Please select a different signer.",
        SELECT_ALL_SIGNERS: 'Please select all the signer(s) to send the agreement email.',
    },
    APPOINTMENTS_TASKS: {
        DELETE: 'Questo record verrà eliminato. Sei sicuro?',
        INVALID_START_END_DATE: 'La data di inizio non può essere maggiore o uguale alla data di fine.',
        RECURR_DELETE_DIALOG_ONLY_BTN_INFO: 'Tutti gli altri record della serie rimarranno.',
        RECURR_DELETE_DIALOG_FOLLOWING_BTN_INFO: 'Questo e tutti i seguenti record verranno eliminati.',
        RECURR_DELETE_DIALOG_ALL_BTN_INFO: 'Tutti i record della serie verranno eliminati.',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_1: 'Tutti gli altri',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_2: 'nella serie rimarrà lo stesso.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_1: 'Questo e tutto ciò che segue',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_2: 'verrà modificato.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_3: ' Eventuali modifiche future',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_4: 'andrà perso.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_1: 'Tutto',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_2: 'nella serie sarà cambiato.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_3: ' Eventuali modifiche apportate ad altri',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_4: 'saranno conservati.',
        RESCHEDULE_TASK_SUCCESS: 'L\'attività è stata riprogrammata con successo',
        RESCHEDULE_APPOINTMENT_SUCCESS: 'L\'appuntamento è stato riprogrammato con successo',
        NO_TASK_FOUND: 'Nessuna attività trovata',
        NO_APPOINTMENT_FOR_TODAY: 'Non ci sono appuntamenti programmati disponibili oggi',
        NO_PENDING_TASK_FOR_TODAY: 'Al momento non è disponibile alcuna attività in sospeso',
        NO_TASK_APPOINTMENT_FOUND: 'Niente in sospeso',
        NO_TASK_APPOINTMENT_FOUND_FOR_COMPLETED: 'Niente di completato.',
        BLANK_START_END_DATE: 'La data di inizio e la data di fine non possono essere vuote',
        BLANK_START_DATE: 'La data di inizio non può essere vuota',
        BLANK_END_DATE: 'La data di fine non può essere vuota',
        BLANK_NO_OF_DAYS: "No. di giorni non può essere vuoto.",
        ZERO_NO_OF_DAYS: "No. di giorni non può essere 0.",
        BLANK_SPECIFIC_DATE: "La data non può essere vuota.",
        INVALID_DUEBY_END_DATE: 'La data di scadenza non può essere maggiore o uguale alla data di fine della ripetizione.',
        SEND_EMAIL_PROGRESS_MSG: 'L\'invio dell\'e-mail è in corso, ci vorrà un attimo.',
        INVALID_DUEBY_START_DATE: 'La data di scadenza non può essere maggiore o uguale alla data di inizio.',
        SYNC_STARTED_MSG: 'Sincronizzazione avviata. Aggiorna il calendario per vedere i nuovi appuntamenti.',
        SYNC_STARTED_MSG_FOR_SETUP: 'Sincronizzazione avviata. Il tuo calendario verrà aggiornato a breve.',
        ENABLE_SYNC_CAL: 'Abilita l\'opzione di sincronizzazione del calendario in Configurazione dell\'integrazione prima di eseguire la sincronizzazione.',
        PRIVATE_APPOINTMENT_ALERT: "Questo è un appuntamento privato; Non è possibile aprirlo.",
        PRIVATE_APPOINTMENT_DRAGE_ALERT:"Questo è un appuntamento privato; Non è possibile trascinarlo.",
        CAMPAIGN_DRAGE_ALERT:"Non è possibile trascinare una campagna",
        START_END_DATE_VALIDATE:'non può essere maggiore o uguale a',
        CONVERT_CONFIRMATION_MESSAGE: 'Una o più attività non vengono controllate. Si desidera continuare con l\'operazione di conversione senza controllare tali attività?'
    },
    TASKS:{
        DELETE_CANCELLED_STATAUS_RESTRICT_MSG:'Non è consentito annullare alcuna attività di azione correttiva.',
    },
    HOME: {
        FILTER_ADD: 'Filtro aggiunto con successo',
        FILTER_NOT_FOUND: 'Non è stato configurato alcun filtro',
        FILTER_DELETE_RECORD: 'Il filtro verrà eliminato. Sei sicuro?',
        FILTER_DELETE: 'Filtro rimosso con successo',
        FILTER_ON_MAX_FIELD: 'È stata raggiunta la condizione massima del campo. Sono consentiti fino a 10 campi.',
        ASSIGNMENT_RULES_ON_MAX_FIELD: 'È stata raggiunta la condizione massima del campo. Sono consentiti fino a 20 campi.',
        // not found in doc
        FILTER_NAME_BLANK: 'Specificare un nome per il filtro.',
        FILTER_NAME_MAX_LENGTH: 'Il nome del filtro non può contenere più di 64 caratteri.',
        FILTER_CRITERIA_EMPTY: 'Si prega di fornire almeno un criterio.',
        FILTER_SQL_ERROR_NO_VALUE: ' non può essere vuoto.',
        FILTER_SQL_ERROR_INTEGER: ' non è un numero intero.',
        FILTER_SQL_ERROR_FLOAT: ' non è un galleggiante.  ',
        FILTER_SQL_ERROE_ALREADY_EXISTS: 'Il nome del filtro esiste già!',
        FILTER_DELETE_MSG_TC: ' le seguenti Campagne Touch ',
        FILTER_DELETE_MSG_BC: ' le seguenti Campagne Radiotelevisive ',
        FILTER_DELETE_MSG_AB: ' le seguenti campagne A/B ',
        FILTER_DELETE_MSG_AND: ' e ',
        FILTER_DELETE_MSG_BEING_USED: ' non può essere eliminato in quanto viene utilizzato in ',
        ADD_GROUP_SUCCESS: 'Gruppo aggiunto correttamente.'
    },
    DOCUMENT_LIBRARY: {
        INVALID_UPLOAD_ERROR: 'Seleziona un file immagine!',
        CONFIRM_DELETE_MSG: 'Sei sicuro di voler eliminare questa immagine?',
        OVER_MAX_SIZE: 'Il file selezionato è troppo grande per essere utilizzato. La dimensione massima consentita per il file è di 50 MB',
        OVER_MAX_SIZE_IMAGE: 'Il file selezionato è troppo grande per essere utilizzato. La dimensione massima consentita per il file è di 500 KB',
        INVALID_DOCUMENT_UPLOAD_ERROR: 'I documenti EXE, Bat, SH non possono essere caricati!',
        DOCUMENT_FILE_EMPTY_ERROR: 'Seleziona un documento!',
        ASSETS_ALREADY_OPEN_ALERT: 'La scheda Libreria è già aperta nel modulo Operazioni.',
        DUPLICATE_IMAGE_NAME: 'L\'immagine esiste già con questo nome. Seleziona un\'immagine diversa!',
        DUPLICATE_DOCUMENT_NAME: 'Il documento esiste già con questo nome. Si prega di selezionare un documento diverso!',
        UPLOAD_IMAGE: 'Seleziona le immagini da caricare (fino a cinque file alla volta).',
        ASSETS_ALREADY_OPEN_ALERT_HUB: 'La scheda Libreria è già aperta nel modulo Hub.',
        BLANK_TITLE_ERROR : 'Il titolo non può essere vuoto',
        BLANK_URL_ERROR : 'Il collegamento non può essere vuoto',
        INVALID_URL_ERROR : 'Fornisci un URL valido',
        CONFIRM_FILE_TYPE_CHANGE: {
            LN1: 'Vuoi uscire dalla sezione corrente? Eventuali modifiche non salvate andrebbero perse.',
            LN2: "Fare clic su OK per confermare e uscire.",
            LN3: 'Fare clic su Annulla per rimanere nella sezione corrente.',
        },
    },
    MERGE: {
        NOT_URL: "è un URL non valido.",
        NOT_FLOAT: "non è un galleggiante.",
        NOT_INTEGER: "non è un numero intero",
        NOT_EMAIL: "è un indirizzo e-mail non valido.",
        MERGE_TENANT_EMAIL_ALERT_MESSAGE: 'Non è possibile unire questi record. Dato che hai scelto un account a pagamento da eliminare!',
    },
    SCHEDULE: {
        EMPTY: 'Si prega di selezionare una data di programmazione',
        CAMPAIGN_SCHEDULE: 'Questa campagna si svolgerà il',
        CONFIRM: 'Fare clic su OK per confermare.',
        SMS_SCHEDULE: 'Questo messaggio di testo verrà inviato su'
    },
    RELATIONSHIP: {
        BLANK_RECORD: "Si prega di selezionare la relazione per",
        BLANK_PARENT_RELATION: "Si prega di selezionare almeno una relazione genitore.",
        BLANK_CHILD_RELATION: "Si prega di selezionare almeno una relazione figlio",
        NO_PARENT_RELATION: "Non hai alcuna relazione. Si prega di creare una relazione in fase di personalizzazione da Tutte le impostazioni",
        NO_PARENT_RELATION_FOR_USER: "Spiacenti, non c'è alcuna relazione disponibile. Contatta il tuo amministratore",
        NO_RELATIONSHIP_SETUP: "Non hai ancora impostato alcun tipo di relazione",
        PLACEHOLDERTEXT_COMPANY: "Digita il nome di un'azienda e premi invio",
        PLACEHOLDERTEXT_NAME: "Digita un nome completo e premi invio",
        PLACEHOLDERTEXT_EMAIL: "Digita un'e-mail e premi invio",
    },
    GROUP: {
        NO_TOUCH_CAMPAIGN: "Non utilizzato in nessuna campagna touch.",
        NO_BROADCAST_CAMPAIGN: "Non utilizzato in nessuna campagna televisiva.",
        NO_AB_CAMPAIGN: "Non utilizzato in nessuna campagna A/B.",
        NO_WEBFORM: "Non utilizzato in alcun modulo Web.",
        NO_LANDINGPAGE: "Non utilizzato in nessuna pagina di destinazione.",
        DELETE_MSG: 'I seguenti gruppi non possono essere eliminati',
        USED_IN_TOUCH_CAMPAIGN: 'viene utilizzato nelle seguenti campagne touch',
        USED_IN_BROADCAST_CAMPAIGN: 'viene utilizzato nelle seguenti campagne di trasmissione',
        USED_IN_AB_CAMPAIGN: 'viene utilizzato nelle seguenti campagne A/B',
        USED_IN_WEBFORM: ' viene utilizzato nei seguenti moduli Web',
        USED_IN_LANDINGPAGE: 'viene utilizzato nelle seguenti pagine di destinazione',
        SELECT_GROUP: 'Selezionare uno o più gruppi',
        NO_GROUP_AVAILABLE: 'Nessun gruppo disponibile. Clic',
        SELECT_RECORDS: 'Record selezionati',
        ALL_RECORDS: 'Tutti i record',
        SELECT_OPTIONS: 'Seleziona le opzioni',
        ADD_NEW_GROUP: 'Per aggiungere un nuovo gruppo',
        GROUP_DELETE_RECORD: 'Il gruppo verrà eliminato. Sei sicuro?',
        COMMON_DELETE_MSG:" non può essere eliminato poiché viene utilizzato nelle seguenti campagne:",
    },
    LOGIN: {
        VALIDATE_EMPTY_USER_NAME: 'Inserisci la tua email e la tua password',
        VALIDATE_EMPTY_PASSWORD: 'Inserisci la tua password',
        VALIDATE_USERNAME_PASSWORD: 'Hai inserito un indirizzo e-mail o una password errati',
        INVALID_RESPONSE: 'Risposta non valida',
        LOGIN_COPYRIGHTS_TEXT_1: 'Diritto d’autore',
        LOGIN_COPYRIGHTS_TEXT_2: 'Soffront Software, Inc. Tutti i diritti riservati.',
        BEST_VIEWED: 'Visualizzazione ottimale in Google Chrome, Mozilla Firefox e Safari'
    },
    AUDIT_LEADSCORE: {
        NO_AUDIT_DATA: 'Non è stato trovato alcun record di audit trail',
        NO_SCORE_HISTORY: 'Nessuna cronologia da visualizzare.'
    },
    DETAIL_VIEW: {
        CHANGE_PRIMARY_CONTACT_ALERT_1: 'Hai selezionato',
        CHANGE_PRIMARY_CONTACT_ALERT_2: 'come contatto principale per',
        CLICK_TO_CONFIRM: 'Fare clic su OK per confermare.',
        MAX_SELECTED_FIELD_LIMIT: 'Hai raggiunto il limite massimo di 50 campi selezionati',
        SELECT_ATLEAST_ONE_FIELD: 'Si prega di selezionare almeno un campo ',
        SELECT_ATLEAST_ONE_CHILD_OBJECT: 'Selezionare almeno un oggetto figlio.',
        MAX_SELECTED_CHILD_OBJECT_LIMIT: 'È stato raggiunto il limite massimo di 10 oggetti selezionati.',
        MAX_SELECTED_CHILD_OBJECT_LIMIT_FOR_15_OBJECTS:'È stato raggiunto il limite massimo di 15 oggetti selezionati.',
        NO_ASSOCIATED_GROUPS_FOUND: 'Non sono disponibili gruppi associati',
        CONFIRM_UNSUBSCRIBE_RECORD: 'L\'iscrizione al record verrà annullata. Sei sicuro?',
        BLANK_SPECIFIC_DATE: 'La data non può essere vuota.',
        CONFIGURE_CHILD_SETUP: 'Si prega di configurare la configurazione del bambino.',
        CHILD_SETUP_SELECT_ATLEAST_ONE_FIELD: 'Selezionare almeno un campo per l\'impostazione del bambino.',
        EMAIL_FORMAT_MSG: "- Formato e-mail non valido.",
        URL_FORMAT_MSG: "- Formato URL non valido.",
        FLOAT_FORMAT_MSG: "- Formato float non valido.",
        INTEGER_FORMAT_MSG: "- Formato intero non valido.",
        ERROR_MSG: "Vuoi andartene senza salvare le modifiche?",
    },
    CHARTS: {
        NOTHING_TO_SHOW: 'Niente da mostrare per questo lasso di tempo'
    },
    ADVANCE_SEARCH: {
        SEARCH_FIELDS_NOT_FOUND: 'Niente da cercare. Inserisci un valore.',
        MAX_ADD_FIELD_ALERT:'Non è possibile effettuare la ricerca con più di 10 campi',
        MIN_ADD_FIELD_ALERT:'Per effettuare la ricerca è richiesto almeno un campo.',
        SELECT_FIELD_ALERT:'Seleziona il campo prima di inserire il valore.',
        ADVANCE_SEARCH: 'Ricerca Avanzata',
        EMPTY_DATE: 'Inserisci la data.',
        INVALID_DATE_RANGE: 'Dalla data non può essere maggiore di Ad oggi',
        EMPTY_FROM_TO_DATE: 'Inserisci Dalla data e Fino alla data.',
        EMPTY_FROM_DATE: 'Inserisci la data dalla data.',
        EMPTY_TO_DATE: 'Inserisci fino ad oggi.'
    },
    PUSH: {
        //PUSH_SUCCESS_MSG_1: 'This document will be pushed',
        PUSH_SUCCESS_MSG_1: 'Questo documento verrà inviato',
        //PUSH_SUCCESS_MSG_1: 'You have successfully pushed',
        PUSH_SUCCESS_MSG_2: 'alle Unità selezionate.',
        PUSH_SUCCESS_MSG_2_1: 'verrà spinto',

        PUSH_SUCCESS_MSG_3: 'Una notifica email è stata inviata alle Unità selezionate.',

        PUSH_SUCCESS_MSG_4: 'Hai selezionato più di 20 record. Puoi spingerne al massimo 20',
        PUSH_SUCCESS_MSG_4_1: 'Al tempo.',
        PUSH_SUCCESS_MSG_5: 'Seleziona un\'unità da inviare',
        PUSH_SUCCESS_MSG_6: 'Non sono disponibili client connessi per il push ',
        PUSH_SUCCESS_MSG_7: 'Spinto con successo.',
        PUSH_ALERT_MSG_8: 'Seleziona almeno un\'unità per inviare modelli di email',
        PUSH_ALERT_MSG_9: 'Seleziona almeno un\'unità per promuovere le campagne di automazione',
        PUSH_ALERT_MSG_10: 'Seleziona almeno un\'unità per promuovere le campagne broadcast',
        PUSH_SUCCESS_MSG_11: 'all\'Unità selezionata.',
        //Added by Anindya
        PUSH_SUCCESS_MSG_11_2: 'Sei sicuro?',
        //End
        PUSH_SUCCESS_MSG_12: 'Una notifica email è stata inviata all\'Unità selezionata.',
        PUSH_SUCCESS_MSG_13: 'Seleziona almeno un\'unità per inviare lead di vendita',
        PUSH_SUCCESS_MSG_14: 'Seleziona almeno un\'unità per inviare flussi di lavoro',
        PUSH_FOLDER: 'Cartella',
        FAIL_USE_ASSIGNMENT_RULE_FOR_SALES_LEADS: 'Nessuna regola di assegnazione è soddisfatta per spingere le iniziative di vendita',
        PUSH_ALERT_MSG_12: 'Seleziona almeno un\'unità per inviare i modelli di accordo',
        PUSH_ALERT_MSG_13: 'Seleziona almeno un\'unità per inviare pagine di destinazione',
        LANDING_PAGES: 'pagine di destinazione',
        PUSH_COMPLIANCE_MSG: 'sarà spinto a tutti i franchising collegati dei Franchisor configurati.'
    },
    PROFILE_DETAILS: {
        UNMATCH_NEW_CONFIRM_PASSWORD: 'La password confermata non corrisponde alla nuova password.',
        NO_NEW_PASSWORD: 'Inserisci una password valida e confermala.',
        NO_CONFIRM_PASSWORD: 'Conferma la nuova password.',
        VALIDATE_PROFILE_IMAGE: 'Seleziona un file immagine.',
        PROFILE_IMAGE_UPLOAD: 'Immagine del profilo salvata correttamente.',
        PROFILE_IMAGE_UPLOAD_FAILURE: 'Impossibile salvare l\'immagine del profilo.',
        PROFILE_UPDATE: 'Profilo aggiornato con successo.',
        VALIDATE_PROFILE_IMAGE_SIZE: 'La foto che hai selezionato è troppo grande per essere utilizzata. La dimensione massima consentita del file è 500 KB.',
        PROFILE_IMAGE_ATTACHMENT_LOGO_TEXT: 'Puoi caricare un file jpg, gif o png.',
        PROFILE_IMAGE_SIZE_TEXT: ' La dimensione massima consentita del file è 500 KB.',
        PROFILE_SIGNATURE_AUTOSAVED: 'Firma salvata con successo.'
    },
    CUSTOMIZATION: {
        LOCATION_VAL_NOT_MATCH: 'Il valore della posizione non corrisponde.',
        LOCATION_VAL_BLANK: 'Non è disponibile alcun valore nel campo della posizione.'
    },
    IMPORT: {
        SELECT_FILE: 'Seleziona un file',
        SELECT_GROUP: 'Seleziona un gruppo',
        SELECT_WORKFLOW: 'Seleziona un flusso di lavoro',
        SELECT_STATUS: 'Seleziona uno stato',
        SELECT_SOURCE: 'Seleziona una fonte',
        SOURCR_NAME_EXISTS: 'Il valore esiste già nell\'elenco',
        SOURCR_NAME_BLANK: 'Il nome della fonte non può essere vuoto!',
        IMPORT_FILE_EXT: 'Seleziona il file con estensione .csv o .xls/xlsx',
        NO_RECORD_TO_IMPORT: 'Nessun record per l\'importazione!',
        NO_MAPPING: 'Non esiste una mappatura per ',
        NOT_ALL_MAPPED: 'Una o più colonne non sono mappate a CRM. Continuare senza importare tali colonne?',
        IMPORT_CONFIRM_MSG: 'i record verranno importati in',
        IMPORT_CONFIRM_MSG_1: 'il record verrà importato',
        CONFIRM: 'Per favore conferma.',
        SELECT_LINK_USING: 'Seleziona un\'opzione per Collegamento tramite',
        SELECT_LINK_WITH: 'Seleziona un\'opzione per Collega con',
        SELECT_DUPLICATE_FIELD: 'Seleziona almeno un\'opzione per il campo duplicato',
        SOURCE_ALERT_MSG_1: 'Mappa il campo "Origine" dell\'account su una colonna che contiene le tue origini di marketing.',
        SOURCE_ALERT_MSG_2: 'Hai scelto Sorgente dall\'elenco a discesa e dalla sezione di mappatura. Puoi scegliere il valore del campo Origine in un posto e non in due posti.',
        NO_MAPPING_AVAILABLE: 'Non è disponibile alcuna mappatura.',
        BLANK_MAP_NAME: 'Si prega di fornire un nome per la mappa!',
        VALID_MAP_NAME_LENGTH: 'Il nome della mappa non deve contenere più di 32 caratteri!',
        DUPLICATE_MAP_NAME: 'Il nome della mappa esiste già!!',
        MAP_SAVE_SUCCESS: 'La mappatura del tuo campo è stata salvata con successo!',
        NO_PREVIOUS_MAPPING: 'Nessuna mappatura precedente presente!',
        MAP_NAME: 'Nome della mappa',
        DELETE_MAP_ALERT: 'Sei sicuro di voler eliminare',
        DELETE_MAP_SUCCESS: 'Mappatura eliminata con successo!',
        IMPORT_SUCCESS: 'Importazione riuscita.',
        WARNING: 'Avvertimento!',
        WARNING_TEXT: 'Non chiudere questa pagina fino al completamento dell\'importazione.',
        IMPORTING_RECORDS: 'Importare i tuoi record',
        SUCCESS_IMPORT_COUNT: 'i record sono stati importati',
        FAIL_IMPORT_COUNT: 'Impossibile importare i record',
        FOUND: 'Abbiamo trovato',
        DUPLICATES: 'duplicato(i)',
        APPLY_WORKFLOW_TEXT: 'ai record importati',
        APPLYING_WORKFLOW: 'Applicazione del flusso di lavoro',
        APPLIED_WORKFLOW: 'Flusso di lavoro di applicazione completato',
        APPLIED_WORKFLOW_TO: 'Flusso di lavoro applicato a',
        MAX_IMPORT_RECORDS_MSG_1: 'Ci sono',
        MAX_IMPORT_RECORDS_MSG_2: 'record nel file. Attualmente puoi importare 10000 record alla volta!',

        // not found in doc
        VERIFY_CONTACTS_MSG_1: 'Verifica che questi contatti ti abbiano fornito i loro',
        VERIFY_CONTACTS_MSG_2: 'il permesso di inviargli un\'e-mail. Non permettiamo l\'affitto o ',
        VERIFY_CONTACTS_MSG_3: 'liste acquistate. Questi contatti devono aver chiesto',
        VERIFY_CONTACTS_MSG_4: 'per ricevere e-mail da te o con cui avere stretti rapporti ',
        VERIFY_CONTACTS_MSG_5: 'la tua organizzazione.',

        FILE_TYPE_MSG: 'La prima riga del file CSV o XLS/XLSX deve contenere i nomi dei campi.',
        DATE_FORMAT_MSG: 'La data deve essere nel formato MM/GG/AAAA ',
        ACCOUNTS_AND_CONTACTS: 'Account e contatti',
        IMPORT_CSV_FILE: 'Seleziona il file con estensione .csv.',
        RECORDS_IMPORT_SUCCESS: 'I record sono stati importati con successo.',
        GREEN_LIGHT_STATUS_ERROR: 'È già in corso un\'importazione, pertanto è possibile avviare una nuova importazione solo dopo il completamento del processo di importazione corrente.',
        GREEN_LIGHT_STATUS_SUCCESS: 'L\'importazione corrente è in corso. Una volta terminato, ti invieremo un\'e-mail di conferma all\'indirizzo e-mail registrato degli affiliati interessati.',
    },
    YOUTUBE_VIDEO: {
        EMPTY_VIDEO_LINK: 'Incolla l\'URL del video per YouTube',
        INVALID_VIDEO_LINK: 'L\'URL che hai inserito non è valido',
        INSERT_TITLE: 'Inserisci video da YouTube',
        VIDEO_LINK_PLACEHOLDER: 'Incolla l\'URL di un video da YouTube'
    },
    WISTIA_VIDEO: {
        EMPTY_VIDEO_LINK: 'Incolla l\'URL del video per Wistia',
        INVALID_VIDEO_LINK: 'L\'URL che hai inserito non è valido',
        INSERT_TITLE: 'Inserisci video da Wistia',
        VIDEO_LINK_PLACEHOLDER: 'Incolla l\'URL di un video da Wistia'
    },
    INXPRESS_REPORT: {
        VALIDATE_EMPTY_FROM_DATE: 'Seleziona dalla data.',
        VALIDATE_EMPTY_TO_DATE: 'Seleziona fino ad oggi.',
        VALIDATE_EMPTY_COUNTRY: 'Seleziona il paese.',
        VALIDATE_EMPTY_FRANCHISE: 'Seleziona Franchising.',
        VALIDATE_EMPTY_WORKFLOW: 'Seleziona il flusso di lavoro.',
        VALIDATE_EMPTY_SALES_REP: 'Seleziona rappresentante di vendita.',
        VALIDATE_EMPTY_OBJECT_NAME: 'Seleziona Segnala su.',
        NO_RECORD_EXPORT: 'Nessun record disponibile per l\'esportazione..',
        REPORT_NOT_READY_FOR_EXPORT: 'Attendi il caricamento del report, non puoi esportare adesso.',
        BLANK_FROM_TO_DATE: 'La data non può essere vuota.',
        VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16: 'Seleziona un paese per continuare.',
        VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT: 'Seleziona un flusso di lavoro per continuare.',
        VALIDATE_EMPTY_REGION_INXPRESS_REPORT: 'Seleziona una regione per continuare.',
        VALIDATE_EMPTY_DATE_RANGE: 'Seleziona un intervallo di date per continuare.',
        VALIDATE_EMPTY_WORKFLOW_STAGES: 'Seleziona una fase del flusso di lavoro per continuare.',
        VALIDATE_EMPTY_CHART_TYPE: 'Seleziona un tipo di grafico per continuare.',
        VALIDATE_EMPTY_SHOW_TYPE: 'Seleziona un tipo di spettacolo per continuare.',
        VALIDATE_EMPTY_ACTIVITY_TYPE: 'Seleziona un tipo di attività per continuare.',
    },

    OTHER_VIEW: {
        TENANT_MESSAGE: 'Il tuo abbonamento non include $$@@##PRODUCT_NAME##@@$$',
        USER_MESSAGE: 'Contatta il tuo amministratore per aggiungere $$@@##PRODUCT_NAME##@@$$ al tuo abbonamento',
        AFTER_SUBSCRIPTION_VENDASTA_NOT_CONFIGURED: 'Non abbiamo ancora configurato il tuo account per $$@@##PRODUCT_NAME##@@$$. Ti avviseremo non appena sarà disponibile.'
    },

    MY_INTEGRATION: {
        SAVE_SUCCESS: 'Le tue impostazioni sono state salvate con successo.',
        APPEND_INFO: 'Aggiungi l\'azienda e le informazioni di contatto agli appuntamenti aggiunti da Soffront',
        DISCONNECT_CONNECTION: 'La connessione verrà rimossa. Sei sicuro?',
        SAVE_FAIL: 'La tua impostazione non è stata salvata.',
        NO_CALENDAR: 'Il calendario non è selezionato.',
        NO_SYNC_CHECKED: 'Non hai selezionato nessuna delle opzioni di sincronizzazione. Puoi scegliere di sincronizzare le e-mail, il calendario o entrambi.',
        SYNC_EMAIL_INFO_1: 'Seleziona questa opzione per salvare automaticamente le email dalla tua casella di posta G Suite/Office 365 in CRM.',
        SYNC_EMAIL_INFO_2: 'Le e-mail verranno salvate rispetto a un account o record di contatto corrispondente.',
        SYNC_EMAIL_INFO_3: 'L\'e-mail verrà sincronizzata ogni 5 minuti.',
        SYNC_CONTACT_INFO_1: 'Seleziona questa opzione per aggiungere automaticamente i tuoi contatti CRM a G Suite/Office 365.',
        SYNC_CONTACT_INFO_2: 'Qualsiasi modifica apportata ai contatti in CRM verrà automaticamente aggiornata in G Suite/Office 365.',
        SYNC_CONTACT_INFO_3: 'I contatti verranno sincronizzati ogni 5 minuti.',
        SYNC_CALENDAR_INFO_1: 'Seleziona questa opzione per sincronizzare automaticamente gli appuntamenti tra il tuo calendario Soffront con un calendario del tuo account G Suite/Office 365.',
        SYNC_CALENDAR_INFO_2: 'Se disponi di più calendari G Suite/Office 365, dovrai sceglierne uno che desideri sincronizzare.',
        SYNC_CALENDAR_INFO_3: 'I calendari verranno sincronizzati ogni 5 minuti.',
        NO_CALENDAR_MESSAGE: 'Aspettiamo di avere i dettagli del vostro calendario. Per favore chiudi questa schermata e torna tra qualche minuto. Se continui a visualizzare questo messaggio, disconnetti e ricollega il tuo account.',
        NO_PERMISSION: 'L\'integrazione non è disponibile nel tuo piano attuale. Sottoscrivi un piano appropriato o contatta l\'assistenza all\'indirizzo smbsupport@soffront.com.',
        SELECTED_CALENDAR_NOT_FOUND: 'Impossibile trovare il calendario selezionato in precedenza. Selezionane un altro e salva.',
        INVALID_ACCOUNT_SYNC_STATE1:'Tuo',
        INVALID_ACCOUNT_SYNC_STATE2:'la connessione non è più valida. Per favore disconnettiti e riconnettiti ancora una volta.',
        SEND_SALES_EMAILS_VALIDATION1: 'Per poter inviare e-mail utilizzando',
        SEND_SALES_EMAILS_VALIDATION2: 'dovrai connetterti utilizzando',
        SEND_SALES_EMAILS_VALIDATION3: 'Disconnettersi e riconnettersi utilizzando',

    },

    DOCUSIGN: {
        PROVIDE_USERNAME: 'Si prega di fornire un nome utente valido.',
        PROVIDE_PASSWORD: 'Si prega di fornire una password valida.',
        PROVIDE_INTEGRATOR_KEY: 'Si prega di fornire una chiave di integrazione valida.',
        SUCCESSFULLY_SAVED: 'Hai configurato correttamente il tuo account DocuSign.',
        FAILED_TO_SAVED: 'Impossibile configurare il tuo account DocuSign.',
        DOCUSIGN_SETUP_TITLE_1: 'Seleziona un documento e invialo ',
        DOCUSIGN_SETUP_TITLE_2: 'A ',
        DOCUSIGN_SETUP_TITLE_3: ' per la firma',
        PROVIDE_RECIPIENT_NAME: 'Si prega di fornire il nome completo del destinatario',
        PROVIDE_RECIPIENT_EMAIL: 'Si prega di fornire l\'e-mail del destinatario',
        PLEASE_SELECT_TEMPLATE: 'Seleziona un modello.',
        NO_TEMPLATES_FOUND: 'Nessun modello trovato.',
        UNABLE_TO_SEND: "Impossibile inviare il modello poiché il tuo account DocuSign è scaduto.",
        TEMPLATE_NAME_LENTH: 'Il nome del modello non deve superare i 65 caratteri.',
        PLEASE_SELECT_CONTACT: 'Seleziona almeno un contatto.',
        DOCUSIGN_CONTACT_MESSAGE_1: 'Non è disponibile alcuna email per i seguenti contatti:',
        DOCUSIGN_CONTACT_MESSAGE_2: 'L\'e-mail non verrà inviata a questi contatti.',
        DOCUSIGN_CONNECTED_MESSAGE_1: 'Siamo spiacenti, non hai i permessi per eseguire questa operazione. Si prega di contattare Soffront.',
    },

    TWILIO: {
        CALL_OVER_SECURED_NETWORK: 'È possibile effettuare una chiamata solo su una rete protetta',
        SAME_TO_AND_FROM_NUMBER: 'Al numero e Dal numero sono gli stessi. Impossibile avviare la chiamata.',
        CALLING_SUPPORT: 'Al momento le chiamate non sono supportate nel tuo Paese.',
        OUTBOUND_PHONE_NOT_VERIFIED: 'Il tuo numero di telefono in uscita non è verificato. Si prega di verificarlo prima di effettuare una chiamata.',
        CALL_RECORDING_SAVE: 'Salvato con successo. Esci e accedi nuovamente per vedere la modifica.',
        CALL_RECORDING_LAWS_AND_REGULATION_ALERT:'Assicurati che le tue pratiche di registrazione delle chiamate siano conformi alle leggi e ai regolamenti applicabili.',
        NOTE_SAVE: 'Nota salvata con successo',
        PHONE_LOW_BALANCE1: 'Il saldo del tuo telefono è ora',
        PHONE_LOW_BALANCE2: ' minuti).',
        PHONE_LOW_BALANCE3: 'Sono necessari più di 5 minuti di saldo per effettuare una chiamata.',
        PHONE_BLANK_MESSAGE: 'Inserisci un numero di telefono.',
        PHONE_ALREADY_CONFIGURE: 'Questo numero di telefono è già configurato.',
        DELETE_PHONE_NUMBER: 'Sei sicuro di eliminare questo numero?',
        VERIFY_PHONE_NUMBER: 'Verifica prima di aggiungere un altro numero.',
        PHONE_LIMIT: 'Non è possibile aggiungere più di 3 numeri di telefono.',
        VERIFICATION_CODE: 'Quando richiesto, inserisci il codice di verifica.',
        VERIFICATION_MESSAGE_1: 'Chiameremo',
        VERIFICATION_MESSAGE_2: 'per verificare il numero.',
        FIELDS_ADD_LIMIT:'Non puoi aggiungere più di 3 campi.',
        SETUP_UPDATED_SUCCESSFULLY:'Aggiornato con successo.',
        SETUP_UPDATED_FIELDS:'Aggiornamento non riuscito.',
        SETUP_MINIMUM_SELECTFIELDS:'Seleziona almeno un campo'
    },
    CALL_SCRIPT: {
        BLANK_NAME: 'Inserisci un nome per questo script.',
        BLANK_DETAILS: 'Inserisci i dettagli dello script.',
        SAVE_SUCCESS: 'Hai salvato con successo questo script.',
        UPDATE: 'Hai aggiornato con successo questo script.',
        DELETE: 'Hai eliminato con successo questo script.',
        NO_RECORD: 'Nessun record disponibile',
        'DUPLICATE_SCRIPT_NAME': 'Questo nome di script esiste già. Non è consentito duplicare il nome dello script.',
        'EMPTY_SCRIPT_NAME_DETAILS': 'Il nome e i dettagli dello script non possono essere vuoti.',
        'RECORD_NOT_FOUND_UPDATE': 'Record non trovato da aggiornare.',
        DELETE_CONFIRMATION: 'Sei sicuro di eliminare questo script?'
    },
    SMS_INTEGRATION: {
        PURCHASED_NUMBER: 'Hai acquistato con successo il numero',
        SELECT_USER: 'Seleziona almeno un rappresentante di vendita per procedere ulteriormente.',
        ENTER_ZIP_OR_AREA: 'Inserisci un codice postale o un prefisso.',
        ENTER_AREA: 'Inserisci un prefisso.',
        ENTER_VALID_ZIP: 'Per favore, inserisci un codice postale valido.',
        ENTER_VALID_AREA_CODE: 'Inserisci un prefisso valido.',
        NO_AVAILABLE_PHONE_NUMBERS: 'Non è disponibile alcun numero di telefono per il codice postale/area inserito.',
        NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP: 'Non è disponibile alcun numero di telefono per il prefisso inserito.',
        NO_AVAILABLE_TOLL_FREE_NUMBERS: 'Non è disponibile alcun numero verde per il Paese selezionato.',
        REMOVE_NUMBER_CONFIRMATION: 'Sei sicuro di rimuovere questo numero?',
        SMS_SUPPORT: 'L invio di messaggi di testo non è supportato nel tuo Paese.',
        SELECT_NUMBER: 'Seleziona un numero.',
        SELECT_AT_LEAST_ONE_NUMBER:'Seleziona almeno un numero da importare',
        TWILIO_NUMBER_IMPORTED_SUCCESS:'I tuoi numeri twilio sono stati importati correttamente',
        TWILIO_NUMBER_IMPORTED_ERROR:'Non è stato possibile importare i tuoi numeri twilio.',
        PHONE_NUMBER_CANNOT_BE_PURCHASED:'Non è possibile acquistare il numero di telefono. Si prega di contattare il supporto Soffront per maggiori dettagli.',
        ENTER_SMS_TEXT: 'Inserisci il testo dell\'SMS.',
        SMS_LOW_BALANCE_1: 'Il tuo saldo SMS è ora ',
        SMS_LOW_BALANCE_2: 'È necessario acquistare crediti SMS per inviare SMS.',
        TWILIO_NUMBER_PURCHASE_MSG_1: 'L\'importo verrà addebitato sul tuo account',
        TWILIO_NUMBER_PURCHASE_MSG_2: 'mese',
        TWILIO_NUMBER_PURCHASE_MSG_3: 'per questo numero di telefono. Puoi rimuovere il numero dal tuo account in qualsiasi momento per interrompere la fatturazione.',
        SMS_TOOLTIP_MESSAGE: 'Trova per codice postale o prefisso',
        SMS_TOOLTIP_MESSAGE_AREA_CODE: 'Trova per prefisso',
        TOLL_FREE_TOOLTIP_MESSAGE: 'Trova il numero verde gratuito',
        INSERT_IMAGE_FILE: 'Seleziona solo file jpg, jpeg, gif o png',
        BATCH_SEND_SMS_MESSAGE: 'messaggio inviato con successo',
        'INSUFFICIENT_BALANCE': 'Saldo SMS insufficiente',
        'BATCH_SMS_SENT': 'messaggio inviato con successo',
        'BATCH_SMS_FAILS': 'Impossibile inviare il messaggio di testo',
        NO_FROM_NUMBER_MESSAGE: 'Sarà necessario acquistare un numero di telefono per inviare messaggi di testo. Si prega di acquistare il numero di telefono dal menu Integrazioni.',
        MAX_ATTACHMENT_FILE_FOR_SMS: 'Sono consentiti al massimo 10 file allegati.',
        'NO_INPUT_STREAM_FOUND': 'Nessun flusso di input trovato ',
        'NO_ATTACHMENT_FOUND': 'Nessun allegato trovato',
        'FILE_SIZE_ERROR': 'La dimensione del file deve essere inferiore a 5 MB.',
        IMAGE_SELECT: 'Seleziona un\'immagine',
        MERGE_FIELD: 'Seleziona unisci campi',
        DUPLICATE_FILE_NAME: 'Questo file esiste già. Seleziona un file diverso.',
        RECORD_UNSUBSCRIBE_ALERT: 'Il record selezionato è stato annullato dalla sottoscrizione ai Testi. Non è possibile inviare messaggi di testo.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG: 'dei record selezionati ha annullato l\'iscrizione ai Testi. Il messaggio di testo non può essere inviato ai record non sottoscritti. Premere OK per procedere.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG_1: 'dei record selezionati hanno annullato l\'iscrizione ai Testi. Il messaggio di testo non può essere inviato ai record non sottoscritti. Premere OK per procedere.',
        'BATCH_SMS_SCHEDULE': 'Il messaggio di testo è stato pianificato correttamente',
        'BATCH_SMS_SCHEDULE_FAIL': 'Impossibile pianificare il messaggio di testo',
        BATCH_SEND_SMS_MSG_1: 'Fuori da',
        BATCH_SEND_SMS_MSG_2: 'hai selezionato, solo',
        BATCH_SEND_SMS_MSG_3: 'hanno aderito alla ricezione del tuo messaggio di testo e hanno il numero di cellulare. ',
        BATCH_SEND_SMS_MSG_4: 'Invieremo l\'SMS solo a questi',
        BATCH_SEND_SMS_MSG_5: 'Premere OK per procedere.',
        SINGLE_SEND_SMS_MSG_1: 'Controlla se selezionato',
        SINGLE_SEND_SMS_MSG_2: 'ha scelto di ricevere i tuoi messaggi di testo o ha il numero di cellulare.',
        RESCHEDULE_SUCCESS: 'L\'SMS è stato riprogrammato con successo',
        AUTOMATION_SMS_ADD_MSG: 'Hai salvato con successo questo messaggio di testo',
        WORKFLOW_STATUS_MSG: 'Hai uno o più flussi di lavoro attivi che utilizzano questo numero per inviare messaggi di testo. Aggiorna tali flussi di lavoro per utilizzare un numero diverso prima di rimuovere questo numero. ',
        SCHEDULED_STATUS_MSG: 'Hai uno o più SMS programmati che utilizzano questo numero. Fare clic su OK per annullare tutti gli SMS programmati e rimuovere il numero.',
        REMOVE_SUCCESS_MSG: 'Il numero è stato rimosso con successo.',
        NUMBER_SAVED_SUCCESS_MSG: 'Numero salvato con successo.',
        NO_LINKED_MOBILE_NUMBER_MSG:'Il record associato non ha numero di cellulare. Non è possibile inviare messaggi di testo.',
        NUMBER_INVALID_MSG:"Il numero di cellulare del contatto non è corretto!",
        CANNOT_DELETE_UNITS_NUMBER: 'Non è consentito rimuovere questo numero poiché questo numero è stato assegnato a un\'unità.',
    },
    EVENTBRITE: {
        NO_ORGANISATION_SELECTED: 'Seleziona almeno un\'organizzazione',
        NO_MAPPING_FOUND: 'Non esiste una mappatura per i campi Contatto CRM ed Eventbrite.'
    },
    QUICKBOOKS: {
        NO_MAPPING_FOUND: 'Non esiste una mappatura per gli account CRM e il campo Cliente Quick Books.',
        COONECT_QB_MSG: 'Non hai collegato il tuo account Quickbooks. Fai clic su OK per connettere il tuo account.'
    },
    XERO: {
        SELECT_ONE_INTEGRATION_ITEM_AND_TYPE: 'Seleziona un elemento e un tipo di integrazione da integrare.',
        SELECT_ONE_INTEGRATION_ITEM: 'Seleziona un elemento da integrare.',
        SELECT_INTEGRATION_TYPE: 'Seleziona il tipo di integrazione.',
    },
    REPORT_SCHEDULE_DIALOG: {
        START_DATE_BLANK: 'La data di inizio non può essere vuota.',
        INVALID_END_DATE: 'La data di inizio non può essere maggiore o uguale alla data di fine.',
        DAY_CHECKBOX_BLANK: 'Almeno controlla un giorno.',
        SEND_TO_BLANK: ' Seleziona almeno un utente.',
        SAVE_SUCCESS: "Salvato con successo."
    },
    DOCUMENT_PUSH: {
        ALERT_MSG_1: 'Seleziona almeno un\'azienda per inviare i documenti',
        DOCUMENTS: 'Documenti)',
        FAIL: 'Impossibile spingere'
    },
    OPERATIONS_REPORT: {
        NO_CUSTOM_REPORT_MESSAGE: 'Non hai alcun rapporto personalizzato.'
    },
    PINNED_NOTES: {
        CONFIRM_MESSAGE: 'Ciò sostituirà la nota appuntata esistente. Fare clic su Ok per procedere o su Annulla per ignorare.'
    },
    SETTINGS: {
        EMPTY_NAME: 'Prego entra',
        DUPLICATES_SOURCE_NAME: 'Il nome della fonte esiste già!',
        DUPLICATE_TASKS_NAME:'Inserisci un nome diverso',
        CORPORATE_CURRENCY: 'La valuta selezionata è stata aggiornata con successo.',
        INVALID_CURRENCY_NAME: 'Seleziona un nome di valuta aziendale valido',
        SYSTEM_NOTE_TYPE_EDIT_ALERT: 'Non puoi modificare questo tipo di nota.',
        SYSTEM_APPOINTMENT_TYPE_EDIT_ALERT: 'Non puoi modificare questo tipo di appuntamento',
        SYSTEM_NOTE_TYPE_DELETE_ALERT: 'Non puoi eliminare questo tipo di nota.',
        SYSTEM_APPOINTMENT_TYPE_DELETE_ALERT: 'Non puoi eliminare questo tipo di appuntamento.',
        SYSTEM_CAMPAIGN_TYPE_DELETE_ALERT: 'Non puoi eliminare questo tipo di campagna.',
        CORPORATE_CURRENCY_ALERT: 'Seleziona una valuta aziendale.',
        CORPORATE_CURRENCY_CONFIRM: 'La valuta selezionata verrà salvata. Sei sicuro?',
        'CORPORATECURRENCY': 'Seleziona una valuta aziendale',
        'DUPLICATE_NOTE_TYPES': 'Questo tipo di nota esiste già. La creazione di tipi di nota duplicati non è consentita.',
        'DUPLICATE_APPOINTMENT_TYPES': 'Questo tipo di appuntamento esiste già. La creazione di tipi di appuntamento duplicati non è consentita.',
        'INCORRECT_RECORD_ID': 'Id record errato. Aggiornamento non riuscito.',
        'RECORD_DOES_NOT_EXISTS': 'Il record non esiste. Operazione di eliminazione non riuscita.',
        'RECORD_CANNOT_DELETED': 'I NoteType di sistema o predefiniti non possono essere eliminati.',
        'DUPLICATE_CAMPAIGN_TYPES': 'Questo tipo di campagna esiste già. La creazione di tipi di campagna duplicati non è consentita.',
        'DUPLICATE_RELATIONS': 'Il nome della relazione esiste già!',
        RELATION_LINK_INFO_ALERT_MSG: 'Ci sono documenti che seguono questa relazione. Scollega prima i rispettivi record che seguono questa relazione e poi sarai in grado di eliminare questa relazione.'
    },
    RECYCLE_BIN: {
        SELECT_WORKFLOW: 'Seleziona un flusso di lavoro',
        SELECT_STAGE: 'Seleziona una fase',
        NOTE: 'Nota: i record collegati al record originale non verranno ripristinati.',
        RESTORE: 'Ripristino della registrazione riuscito'
    },
    UNSUBSCRIBE: {
        SELECTED_RECORD: 'L\'iscrizione a questo record verrà annullata ',
        ARE_YOU_SURE: '. Sei sicuro?',
        SELECTED_BOTH_RECORD: 'Questo record verrà annullato dall\'invio di e-mail e SMS. Sei sicuro?',
        'EMAILS_UNSUBSCRIBE': ' verrà annullata l\'iscrizione alle e-mail. Sei sicuro?',
        'TEXTS_UNSUBSCRIBE': 'verrà cancellata l\'iscrizione ai Testi. Sei sicuro?',
        'BOTHS_UNSUBSCRIBE': ' verrà annullata l\'iscrizione alle e-mail e ai messaggi. Sei sicuro?',
    },
    WORKFLOWS: {
        STATUS_CHANGED: 'Lo stato è stato modificato in ',
        'SAME_STATUS': 'Registra già',
        'FAILED_TO_CHANGE_WORKFLOW_STATUS': 'impossibile modificare lo stato del flusso di lavoro.',
        ONLY_ONE_ACTIVE_WORKFLOW: 'Non è possibile rendere inattivo questo flusso di lavoro. Questo è l\'unico flusso di lavoro attivo disponibile. Una tabella deve avere almeno un flusso di lavoro attivo.',
        ONLY_ONE_ACTIVE_WORKFLOW_CANNOT_DELETE: 'Non puoi eliminare questo flusso di lavoro. Questo è l\'unico flusso di lavoro attivo disponibile. Una tabella deve avere almeno un flusso di lavoro attivo.',
        DELETED_WORKFLOW: 'Il flusso di lavoro non esiste. Seleziona Ok per scegliere un nuovo flusso di lavoro.',
        WORKFLOW_BLANK_ALERT : 'Il flusso di lavoro non può essere vuoto.',
        STATUS_BLANK_ALERT:'lo stato non può essere vuoto',
    },
    WORKFLOW_DESIGNER: {
        AUTOMATED_WORKFLOW_TITLE: 'Progettista del flusso di lavoro',
    },
    CHANGE_LOGO: {
        SELECT_IMAGE_TO_UPLOAD: 'Seleziona un\'immagine per cambiare logo',
        LOGO_IMAGE_UPLOAD_FAILURE: 'Impossibile salvare il logo aziendale.',
        LOGO_IMAGE_UPLOAD: 'Logo aziendale salvato correttamente.',
        VALIDATE_LOGO_IMAGE: 'Seleziona un file immagine.',
        VALIDATE_LOGO_IMAGE_SIZE: 'La foto che hai selezionato è troppo grande per essere utilizzata. La dimensione massima consentita del file è 500 KB.',
        VALIDATE_FAVICON_IMAGE_SIZE: 'L\'icona che hai selezionato è troppo grande per essere utilizzata. La dimensione massima consentita del file è 16x16.',
        LOGO_IMAGE_ATTACHMENT_LOGO_TEXT: 'Puoi caricare un file jpg, gif o png.',
        LOGO_IMAGE_SIZE_TEXT: ' La dimensione massima consentita del file è 500 KB.',
        WEBSITE_URL: 'Sito web',
        VALIDATE_LOGO_WEBSITE_URL: 'Desideri caricare il logo senza alcun URL valido?'
    },
    ACCOUNTS: {
        DUPLICATE_MAIL_DOMAIN: 'Esiste un account esistente per questa azienda: desideri invece aggiungere un contatto?',
        DUPLICATE_FRANCHISE: 'Questo è un ID affiliato duplicato. Modificalo',
        ALERT_MULTI_DRAWER_OPEN : 'Una finestra di dialogo di conversione è già aperta in modalità ridotta a icona.'

    },
    ASSIGNMENT_RULES: {
        NO_ASSIGNMENT_RULES: 'Nessuna regola disponibile.',
        NO_NAME: 'Fornisci il nome della regola di assegnazione.',
        NAME_MAX_LENGTH: 'Il nome della regola di assegnazione non può contenere più di 64 caratteri.',
        NO_CRITERIA: 'Si prega di fornire almeno un criterio.',
        NO_USER: 'Fornisci almeno un utente.',
        RULE_SAVE_SUCCESS: 'Regola di assegnazione salvata correttamente.',
        RULE_NAME_EXISTS: 'Il nome esiste già!',
        ACTIVE_RULE_EXCEEDS: 'Hai già 60 regole di assegnazione attive.',
        SELECT_UNIT_MAXIMUM_FIELD: 'È possibile selezionare solo un\'unità',
        SELECT_A_UNIT_WORKFLOW: 'Seleziona un flusso di lavoro unitario.',
        NO_UNIT_USER: 'Si prega di fornire almeno un\'unità.'

    },
    COUPONS: {
        CREATE_COUPON_NAME: 'Crea un nome interno per il tuo coupon',
        CREATE_DISCOUNT_TYPE: 'Che tipo di sconto vorresti creare?',
        FIXED_AMOUNT: 'Importo fisso',
        PERCENTAGE: 'Percentuale',
        PREFIX_COUPON_CODE: 'Specificare un prefisso per il codice coupon',
        CAN_CLAIM_MORE_THAN_ONCE: 'Può essere utilizzato più di una volta dallo stesso account?',
        CALIM_LIMIT_ONE: 'Solo una volta',
        CLAIM_LIMIT_UNLIMITED: 'Illimitata',
        WHYEN_CLAIM_CODE: 'Data di scadenza del buono',
        CLAIM_ANYTIME: 'Non scade mai',
        CLAIM_BEFORE_DATE: 'Data di scadenza',
        SAVE_SUCCESSFULLY: 'Coupon salvato con successo',
        ACTIVE_SUCCESSFULL: 'Lo stato è stato cambiato in Attivo.',
        INACTIVE_SUCCESSFULL: 'Lo stato è stato cambiato in Inattivo.',
        DELETE_CONFIRMATION: 'I coupon verranno eliminati. Sei sicuro?',
        INVALID: 'è un invalido',
        DUPLICATE_COUPONS: 'Il nome del coupon esiste già.',
        NEGATIVE_COUPONS: 'non può essere negativo.',
        SPECIFY_DATE: 'Si prega di specificare una data.',
        PODUCT_SERVICE: 'Prodotto/Servizio',
    },
    NOTIFICATION_REMINDERS: {
        SAVE_SUCCESS: 'Promemoria aggiunto correttamente',
        INVALID_DAY: 'Inserisci uno o più giorni validi',
        DUPLICATE_REMINDER: 'Questo è un promemoria duplicato, per favore cambia',
        INVALID_DATE_FIELD: 'Seleziona un campo Data', 
        DELETE_SUCCESS: 'Promemoria eliminato correttamente',
        UPDATE_SUCCESS: 'Promemoria aggiornato correttamente',
    },
    EMAIL_INTEGRATION: {
        DUPLICATE_EMAIL: 'Questo indirizzo email esiste già!',
        EMAIL_SAVE_SUCCESS: 'Indirizzo e-mail salvato correttamente',
        SEND_VERIFICATION_EMAIL_SUCCESS_1: 'Abbiamo inviato l\'e-mail di verifica. Controlla la tua casella di posta e fai clic ',
        SEND_VERIFICATION_EMAIL_SUCCESS_2: 'Verifica questo indirizzo email. ',
        SEND_VERIFICATION_EMAIL_SUCCESS_3: 'Quindi torna indietro e fai clic sull\'icona di aggiornamento per vedere lo stato del tuo indirizzo email.',
        EMAIL_INTEGRATION_VERIFIED: 'Questo indirizzo email è già stato verificato!',
        CONFIRM_DELETE_EMAIL: 'Gli indirizzi email selezionati verranno eliminati. Sei sicuro?',
        INVALID_EMAIL: 'Indirizzo email non valido',
        EMAIL_CANT_BLANK: 'L\'indirizzo e-mail non può essere vuoto!',
        EDIT_EMAIL_SAVE_SUCCESS: 'E-mail salvata con successo',
    },
    NEWS: {
        SAVE_ERROR: 'Non in grado di salvare.',
        VALIDATE_CATEGORY: 'La categoria non può essere vuota.',
        VALIDATE_STATUS: 'Lo stato non può essere vuoto.',
        VALIDATE_TITLE: 'Il titolo non può essere vuoto.',
        VALIDATE_SUMMARY: 'Il riepilogo non può essere vuoto.',
        VALIDATE_EXTERNAL_LINK: 'Il collegamento esterno non può essere vuoto.',
        VALIDATE_DETAILS: 'I dettagli non possono essere vuoti.',
        CATEGORY_NAME_EXISTS: 'Il valore esiste già nell\'elenco.',
        DUPLICATE_TITLE: "Questo titolo esiste già!",
    },
    USEFUL_LINKS: {
        CATEGORY: 'Categoria',
        LINK: 'Collegamento',
        TITLE: 'Titolo',
        VALID_CATEGORY: 'Seleziona una categoria valida',
        DUPLICATE_CATEGORY: 'Questa è una categoria duplicata, modificala',
        DUPLICATE_TITLE: 'Questo è un titolo duplicato nella stessa categoria. Si prega di modificarlo.',
        CONFIRM_DELETE_CATEGORY: 'La categoria verrà eliminata. Sei sicuro?',
        CONFIRM_DELETE_LINK: 'Il collegamento verrà eliminato. Sei sicuro?',
        VALID_URL: 'Per favore, inserisci un URL valido.',
        EXISTING_LINKS_CATGORY: 'Hai collegamenti esistenti in questa categoria. ',
        PLEASE_MOVE_LINKS: 'Si prega di spostare i collegamenti in',
        CLICK_TO_CONFIRM: ' Fare clic su Ok per confermare.',
    },
    HUB_SETUP: {
        VALID_URL: 'Inserisci un valore valido',
        URL: 'URL.',
        COMMUNITY: 'URL della comunità.',
        MANUAL: 'URL manuale.',
        TRAINING: 'URL della formazione.',
        INVALID_HUB_NAME: 'Inserisci un nome hub valido',
    },
    CASE: {
        CASE_CLOSED_SUCCESS: 'Caso chiuso con successo'
    },
    ORTHOTIC_REPORT: {
        VALIDATE_EMPTY_PRODUCT: 'Seleziona un prodotto.'
    },
    USER_REPORT: {
        REPORT_DELETE_RECORD: 'Il rapporto verrà eliminato. Sei sicuro?',
        REPORT_DELETE: 'Rapporto eliminato con successo',
        INVALID_START_END_DATE: 'La data di inizio non può essere successiva alla data di fine.'
    },
    AUTO_FILL_VALUES: {
        CHNAGE_PARENT_OBJECT: 'Vuoi davvero cambiare l\'oggetto genitore?',
        CHANGE_CHILD_OBJECT: 'Vuoi davvero cambiare l\'oggetto figlio?',
        SELECT_PARENT_OBJECT: 'Seleziona un oggetto principale.',
        SELECT_CHILD_OBJECT: 'Seleziona un oggetto figlio.',
        NO_MAPPING_FIELDS: 'Nessun campo di mappatura disponibile tra ',
        NO_MAPPING_FIELDS_AND: 'E',
        CHILD_FIELD_SIZESHOULD_GREATER: 'La dimensione del campo figlio deve essere maggiore della dimensione del campo padre.',
        MAPPING_FROM: 'Mappatura da',
        MAPPING_TO: ' A ',
        NO_AVAILBLE_FIELDS_FOR_MAPPING: '. Non esiste un campo comune disponibile per la mappatura predefinita.',
        NO_AVAILABLE_PARENT_CHILD_MAPPING: 'Non è disponibile la mappatura dei campi padre-figlio.',
        FIELD_MAPPING_DONE_SUCCESS: 'Mappatura dei campi completata con successo.',

        MAP_FIELDS_TO_AUTOFILL: 'Mappa i campi da compilare automaticamente',
        MAP_FIELDS_TO_AUTOFILL_FROM: ' da ',
        SELECT_PARENT_FIELD: 'Seleziona un campo principale.',
        SELECT_CHILD_FIELD: 'Seleziona un campo figlio.',
        MAPPING_NOT_POSSIBLE: ' non è possibile.'
    },
    LANDING_PAGE: {
        INVALID_NAME: 'Inserisci il nome della pagina di destinazione.',
        INVALID_URL_WARNING: 'Inserisci il nome dell\'URL.',
        SAVE: 'Pagina di destinazione salvata correttamente.',
        UPDATE: 'Pagina di destinazione aggiornata correttamente.',
        INVALID_FORM_BLOCK_EDIT_MSG: 'Il blocco attivo corrente non ha campi modulo da modificare, seleziona il blocco dei campi modulo da modificare.',
        DUPLICATE_NAME: 'La pagina di destinazione esiste già con questo nome. Inserisci un nome diverso e riprova.',
        DUPLICATE_URL: 'La pagina di destinazione esiste già con questo URL. Inserisci un URL diverso e riprova.',
        INPUT_DATA_LOSS_MSG: 'Tutti i dati inseriti verranno cancellati. Sei sicuro?',
        EMAIL_REQUIRED_MSG: 'L\'e-mail è obbligatoria.',
        ATLEAST_ONE_BLOCK_ADD_MSG: 'Aggiungi almeno un Blocco di progettazione per salvare questa pagina.',
        PREVIEW_ALERT_MSG: 'Salva prima di visualizzare l\'anteprima della pagina di destinazione.',
        OPEN_URL_ALERT_MSG: 'Salvare le modifiche prima di eseguire l\'azione.',
        COPY_TO_CLIPBOARD: 'Link al modello della pagina di destinazione copiato negli appunti.',
        FORM_ADDED_MSG: 'Modulo aggiunto con successo.',
        FORM_UPDATED_MSG: 'Modulo aggiornato con successo.',
        INVALID_URL: 'URL non valido.',
        LABEL_FIELD_CANNOT_BLANK: 'il campo dell\'etichetta non può essere vuoto.',
        FIELD_IS_ALREADY_ADDED: 'Il campo è già aggiunto.',
        MINIMUM_ONE_FIELD: 'È richiesto almeno un campo.',
        NO_MORE_FIELD_AVAILABEL: 'Non sono più disponibili campi.',
    },
    DOWNLOAD_LEADS: {
        SELECT_CRITERIA: 'Seleziona almeno un criterio.',
        SELECT_WORKFLOW: 'Seleziona un flusso di lavoro.',
        SELECT_GROUP: 'Seleziona un gruppo.',
        SELECT_STATUS: 'Seleziona lo stato.',
        SELECT_SALESREP: 'Seleziona un rappresentante.',
        LEAD_EXIST_MSG_1: 'Hai già questi lead nel tuo account: ',
        LEAD_EXIST_MSG_2: '.Fare clic su OK per interrompere il download e rimuovere questi contatti dalla selezione.'
    },
    SCHEDULER: {
        BLANK_APPOINTMENT_NAME: 'Inserisci il nome dell\'appuntamento.',
        BLANK_LOCATION: 'Inserisci la posizione.',
        SAVE_SUCCESS: 'Configurazione dello scheduler salvata correttamente.',
        SAVE_FAIL: 'Impossibile salvare la configurazione dello scheduler.',
        NO_SCHEDULER: 'Si prega di creare un programmatore di appuntamenti prima di utilizzare il collegamento del programmatore.',
        VALIDATE_TIME: 'L \'ora di inizio non può essere maggiore o uguale all\'ora di fine.',
        NO_START_TIME: 'Seleziona l\'ora di inizio.',
        NO_END_TIME: 'Seleziona l\'ora di fine.',
        SCHEDULER_LINK_COPIED_SUCCESS: 'Collegamento allo scheduler copiato negli appunti.',
        BLANK_SUBMIT_BUTTON: 'L\'etichetta del pulsante non può essere vuota.',
        CALENDAR_SYNC_MSG: 'Il tuo calendario CRM non è connesso al tuo calendario GSuite o Office 365.',
        CALENDAR_SYNC_LINK: 'Clicca qui per configurarlo.',
        SCHEDULER_UNSAVE_msg: 'Vuoi lasciare questo modulo? Le modifiche non verranno salvate.',
    },
    RELATION_REPORT: {
        RANGE_FIELD_BLANK: 'Seleziona un campo data.',
        ROW_FIELD_BLANK: 'Seleziona un campo riga.',
        COL_FIELD_BLANK: 'Seleziona un campo colonna.',
        REPORT_NAME_BLANK: 'Inserisci il nome del rapporto.',
        REPORT_SAVE_SUCCESS: 'Rapporto salvato con successo.',
        DUE_BY_BLANK: 'Seleziona un intervallo di date.',
        NO_ROW_FIELD_VALUE: 'Seleziona un valore per l\'etichetta della riga.',
        NO_COLUMN_VALUE: 'Seleziona un valore per l\'intestazione della colonna.',
        SAME_ROW_COLUMN_FIELD: 'I campi riga e colonna non possono essere uguali.',
        INVALID_END_DATE: 'La data di inizio non può essere successiva alla data di fine.',
        'Report Name already exists': 'Il nome del rapporto esiste già.',
        VALID_REPORT_NAME: 'Inserisci un nome rapporto valido',

    },
    PAYMENTS: {
        NOT_ADDED_QB: 'Il preventivo/fattura non è ancora stato aggiunto al Quickbook!',
        NO_LINKED_AVAILABLE: 'Non è disponibile alcun preventivo/fattura collegata!',
        PAYMENT_VALUE_ALERT_MSG: 'Il valore del pagamento non può essere zero.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'Hai una scheda preventivo/fattura aperta. Si prega di chiudere la scheda prima di aprire o aggiungere un nuovo preventivo/fattura.',
        AMOUNT_EXCEED_BALANCE_DUE: 'Il pagamento che stai inserendo supera il saldo dovuto per questa fattura. Fare clic su OK per continuare il pagamento. Fare clic su Annulla per modificare l\'importo del pagamento.',
        PAID_AMOUNT_EXCEED: 'Il pagamento che stai inserendo per il rimborso supera l\'importo pagato per questa fattura. Fare clic su OK per modificare l\'importo del pagamento.',
        REFUND_AMOUNT_EXCEED: 'Il pagamento che stai inserendo per il rimborso supera l\'importo del rimborso rimasto per questa fattura. Fare clic su OK per modificare \'importo del pagamento.',
        REFUND_COMPLETED:'Rimborso già completato.'

    },
    PRODUCTS: {
        DUPLICATE_PRODUCT: 'Il nome del prodotto esiste già.',
        ADDED_TO_QUICKBOOK: 'Aggiunto con successo a QuickBooks.',
        PULLED_FROM_QUICKBOOKS: 'Estratto con successo da Quickbooks.',
        PRODUCT_PRICE_VALUE_ALERT_MSG: 'Il valore del prezzo non può essere Zero.',
        PRODUCT_NAME_COULDNOT_CONTAIN_COLON_MSG: 'non può contenere i due punti (:).',
        NEGATIVE_PRICE: 'Il prezzo non può essere negativo per il tipo di inventario.'
    },
    COMPANY_PROFILE: {
        BLANK_BUSINESS_NAME: 'Inserisci il nome dell\'azienda.',
        BLANK_BUSINESS_ADDRESS: 'Inserisci l\'indirizzo aziendale.',
        BLANK_BUSINESS_PHONE: 'Inserisci il telefono aziendale.'
    },
    EXPORT_SETTINGS: {
        CONSIDERATION: 'Considerazione:',
        CONSIDERATION_MSG1: 'Supportiamo file in formato EXCEL e CSV.',
        CONSIDERATION_MSG2: 'Una cella in Excel può contenere un massimo di 32.767 caratteri, quindi nel file Excel esportato mostriamo un massimo di 32.767 caratteri per una cella ed escludiamo il resto dei caratteri.',
        NO_RECORDS_FOUND: 'Nessun record trovato!',
        FILE_NAME_IS_EMPTY: 'Il nome del file è vuoto.',
        PLEASE_SELECT_THE_FILE_TYPE: 'Seleziona il tipo di file.',
        PLEASE_SELECT_THE_PARENT_OBJECT: 'Seleziona l\'oggetto principale.',
        PLEASE_SELECT_THE_QUERY_NAME: 'Seleziona il nome della query.',
        PLEASE_SELECT_THE_PARENT_FIELDS: 'Seleziona i campi principali.',
    },
    DATA_BASE: {
        FAILURE_MSG: 'Impossibile salvare.',
        SUCCESS_MSG: 'La tua impostazione è stata salvata con successo. Esci e accedi nuovamente per vedere le modifiche.',
        VALIDATION_MSG: 'Seleziona almeno un oggetto figlio per ',
        VALIDATION_MSG1: "Hai raggiunto il limite massimo di 15 oggetti selezionati.",
        SUCCESS_MSG1: 'La tua impostazione è stata salvata con successo.',
    },
    UNITS: {
        BLANK_FIRST_NAME: 'Per favore inserisci il nome.',
        BLANK_LAST_NAME: 'Per favore inserisci il cognome.',
        BLANK_PASSWORD: 'Per favore, inserisci la password.',
        UNMATCH_RETYPE_PASSWORD: 'La nuova password non corrisponde alla password.',
        BLANK_RETYPE_PASSWORD: 'Inserisci nuovamente la password.',
        BLANK_EMAIL: 'Inserisci l\'e-mail.',
        BLANK_PHONE: 'Inserisci il telefono.',
        BLANK_COMPANY: 'Inserisci l\'azienda.',
        BLANK_FA_EXPIRATION_DATE:"La Data di scadenza FA non è valida. Si prega di aggiornarlo con una data valida.",
        UNIT_SAVED: 'Salvato con successo.',
        UNIT_NOT_SAVED: 'Impossibile salvare l\'unità.',
        DUPLICATE_EMAIL: 'Hai già uno o più record con lo stesso ID email.',
        DUPLICATE_COMPANY: 'Il nome dell\'azienda esiste già.',
        SUBSCRIPTION_NOT_VALID: 'L\'abbonamento non è valido per l\'unità selezionata. Si prega di contattare il supporto Soffront per maggiori dettagli.',
        BLANK_ROYALTY_PERCENT: 'Inserisci la percentuale di royalties.',
        NO_UNIT_TASKS: 'Non sono disponibili attività per il proprietario dell\'unità.',
        NO_ACCOUNT_TASKS: 'Non sono disponibili attività per l\'account.',
        BLANK_FAMILY_INDUSTRY: 'Seleziona un settore familiare',
        NO_FAMILY_INDUSTRY: 'Avresti bisogno di un modello di base prima di poter aggiungere nuove unità. Contatta il supporto BrandWide per impostare un modello di base.',
        BLANK_PLAN_NAME: 'Seleziona un nome per il piano.',
        NO_AVAILABLE_PLANS: 'Non ci sono piani disponibili che possono essere registrati per questa unità.',
        NO_BRANCH_WORKFLOW: 'Non sono disponibili flussi di lavoro della filiale.',
        PLEASE_SELECT_TYPE: 'Seleziona un tipo per continuare.',
        NO_BASE_LOGIN: 'La base non esiste o non è configurata! Risolvi il problema e riprova.',
    },
    SHARE: {
        SHARE_SUCCESS_MSG_1: 'Le informazioni sulla condivisione sono state aggiornate correttamente.',
        SHARE_SUCCESS_MSG_SL: 'Le informazioni sulla condivisione vengono aggiornate correttamente per i livelli di sicurezza selezionati, se presenti.',
        SHARE_SUCCESS_MSG_2: 'La notifica via email verrà inviata alle unità appena selezionate, se presenti.',
        SHARE_SUCCESS_MSG_3: 'La notifica via email verrà inviata alle unità appena selezionate, se presenti..',
        SHARE_SUCCESS_MSG_4: 'Sei sicuro?',
        SHARE_SUCCESS_MSG_5: 'verranno condivisi con l\'Unità selezionata.',
        SHARE_SUCCESS_MSG_6: 'verranno condivisi con le Unità selezionate.',
        SHARE_FAILED_MSG: 'Impossibile condividere.',
        SHARE_SELECT_CORPORATE_DOCUMENT: 'Seleziona almeno un utente aziendale per condividere i documenti.',
        SHARE_SELECT_CORPORATE_IMAGE: 'Seleziona almeno un utente aziendale per condividere l\'immagine.',
        SHARE_SELECT_UNIT_DOCUMENT: 'Seleziona almeno un\'unità per condividere i documenti.',
        SHARE_SELECT_UNIT_IMAGE: 'Seleziona almeno un\'unità per condividere l\'immagine.',
        NO_CONNECTED_CLIENT: 'Non sono disponibili client connessi per condividere documenti.',
    },
    SECURITY_LEVEL: {
        ERROR_MSG_1: 'Ops! Qualcosa è andato storto. Per favore prova più tardi.',
        ERROR_MSG_2: 'La descrizione non può essere vuota.',
        SUCCESS_MSG_1: 'La tua impostazione è stata salvata con successo.',
    },
    WHITE_LABEL: {
        LINK_SAVE: 'Il collegamento è stato salvato con successo.',
        NOT_VALID_URL: 'non è un URL valido'
    },
    ROLL_UP: {
        SELECT_A_CHILD_OBJECT: 'Seleziona un oggetto figlio',
        SELECT_A_PARENT_OBJECT: 'Seleziona un oggetto principale',
        NO_CHILD_FIELD_SELECTED: 'Nessun campo figlio selezionato',
        NO_PARENT_FIELD_SELECTED: 'Nessun campo padre selezionato',
        SAME_TYPE_FIELD: 'Seleziona lo stesso tipo di campi per gli oggetti figlio e padre',
        ROLL_UP_EXIST: 'La stessa combinazione di campi roll up esiste già',
        PARENT_FIELD_EXIST: 'È già stato configurato un Roll up nel campo Oggetto Madre selezionato.',

    },
    ROYALTY_REPORT: {
        BLANK_ROYALTY_PERCENT: 'Inserisci la percentuale di royalties.',
        REPORT_SAVE_SUCCESS: 'Rapporto salvato con successo.',
        SETUP_SAVE_SUCCESS: 'Configurazione royalty salvata correttamente.'
    },
    MENU: {
        ATLEAST_ONE_MODULE_ACTIVE: 'Spiacenti, almeno un modulo dovrebbe essere attivo.',
        ATLEAST_ONE_MODULE_ITEM_ACTIVE: 'Spiacenti, almeno un elemento del modulo dovrebbe essere attivo.',
        MODULE_LABEl_CHAR_LIMIT_MSG: 'Il nome non può superare i 32 caratteri.',
        DUPLICATE_NAME: 'il nome del modulo esiste già.'
    },
    SALES_PURCHASE_ORDER: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD: 'Hai una scheda ordine di acquisto aperta. Chiudi la scheda prima di aggiungere un nuovo ordine di acquisto.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD: 'Hai una scheda ordine cliente aperta. Chiudi la scheda prima di aggiungere un nuovo ordine cliente.',
        SAVE_PURCHASE_SUCCESS: 'Ordine d\'acquisto salvato con successo.',
        SAVE_SALES_SUCCESS: 'Ordine di vendita salvato con successo.',
        SALES_REP_NAME_CANT_BLANK: 'Il nome del rappresentante di vendita non può essere vuoto.',
        SELECT_ONE_ITEM: 'Seleziona le informazioni su un articolo.',
        DISCOUNT_AMOUNT_CANT_GREATER: "L'importo dello sconto non può essere superiore al totale parziale.",
        SPECIFY_TRANSC_AMOUNT: 'È necessario specificare un importo della transazione pari a 0 o superiore.',
        SETUP_COMPANY_PROFILE: 'Imposta il profilo della tua azienda prima di aggiungere preventivi. Contatta l\'amministratore CRM per configurare il profilo.'
    },
    JOBS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD: 'Hai una scheda di lavoro aperta. Chiudi la scheda prima di aggiungere un nuovo lavoro.',
        FIELDS_CANNOT_BLANK: ' il campo non può essere vuoto.',
        PRODUCT_NAME_NOT_CONTAIN: 'Il nome del prodotto non può contenere i due punti (:).',
        JOB_SAVE_SUCCESS: 'Lavoro salvato con successo.',
        PRODUCT : 'Prodotto',
        ALREADY_EXIST: 'esiste già.',

    },
    POWER_CALLING: {
        ALERT: {
            WARNING: 'Sei sicuro',
        },
        YOU_DONT_HAVE_CALL_SCRIPT: "Non hai script di chiamata.",
        ALERT_MSG_1: 'Fuori da',
        ALERT_MSG_2: ' hai selezionato, solo',
        ALERT_MSG_3: ' avere numeri locali validi. Potrai chiamarli',
        ALERT_MSG_4: 'Parlato con',
        ALERT_MSG_5: "Non ce ne sono altri",
        ALERT_MSG_6: "in coda per effettuare una chiamata.",
        ALERT_MSG_7: 'Non hai alcuna connessione attiva.',
        ALERT_MSG_8: 'hai selezionato, nessuno di loro ha un numero di cellulare o di telefono.',
        ALERT_MSG_9: 'Hai già un Power Dialer aperto, chiudilo per aprire un nuovo Power Dialer. ',
        ALERT_MSG_10 :'Per chiudere la scheda, fare clic sull\'icona di chiusura nella parte superiore della scheda.'
    },
    LEAD_SCORE_SETUP: {
        ALREDY_DEFINED_SCORE: 'Hai già definito un punteggio per questa azione.',
        SAVE_SUCCESSFULLY: 'Il punteggio è stato salvato con successo.',
        CAN_NOT_UPDATE: 'Le impostazioni del punteggio non possono essere aggiornate. Modificare correttamente le impostazioni del punteggio.',
        SELECT_ONE_ACTION: 'Seleziona almeno un\'azione',
        SELECT_ACTION: 'Seleziona un\'azione',
        SELECT_OBJECT: 'Seleziona un oggetto',
        SELECT_WORKFLOW: 'Seleziona un flusso di lavoro',
        CONVERT_FROM_CANT_BLANK: 'Convertito da non può essere vuoto',
        CONVERT_TO_CANT_BLANK: 'Convertito in non può essere vuoto',
        CHOOSE_DIFF_STATUS: 'Non è possibile convertire lo stesso stato. Scegli uno stato diverso.',
        SELECT_GROUP: 'Seleziona un gruppo',
        SELECT_FIELD: 'Seleziona un campo',
        PROVIDE_VALUE_TO_UPDATE: 'Fornisci un valore che desideri aggiornare',
        ENTER_SCORE: 'Inserisci un punteggio compreso tra 1 e 99',
        EXIST_IN_LIST: 'Il valore esiste già nell\'elenco',
        SCORE_DELETED: 'Questo punteggio verrà eliminato. Sei sicuro?',
        REMOVE_MSG: 'Rimuovi con successo',
        UPDATE_MSG: 'Aggiornamento riuscito',
        CHAR_ERROR: 'Il carattere non è consentito'
    },
    DUPLICATE_RULE: {
        ERROR_MSG_DEFAULT: 'Non puoi disattivare la regola predefinita.',
        ERROR_MSG_Active: 'Non è possibile disattivare una regola predefinita.',
        ERROR_MSG_FOR_FIELDS: 'Seleziona tutti i campi da un particolare blocco per salvare la regola.',
        ERROR_MSG_1: 'Qualcosa è andato storto. Per favore riprova più tardi.',
        SUCCESS_MSG_1: 'Le tue regole duplicate sono state salvate con successo.',
        ERROR_MSG_SAME_FIELD: ' è già selezionato. Seleziona un altro campo.',
    },
    REQUIRED_FORM: {
        FIELD_FILL_MESG:'Questi campi devono essere compilati prima',
        CAN_BE_SUBMITTED:' può essere presentato.'
    },
    GREENLIGHT: {
        COMPANY_NAME_BLANK: 'Inserisci il nome di un\'azienda.',
        COMPANY_NAME_REQUIRED: 'Il nome dell\'azienda non può contenere più di 30 caratteri.',
        ADDRESS1_REQUIRED: 'L\'indirizzo 1 è obbligatorio.',
        ADDRESS1_ADDRESS2_LIMIT: 'Indirizzo1 + Indirizzo2 combinati non possono contenere più di 30 caratteri.',
        PHONE_NUMBER_REQUIRED: 'Si prega di inserire un numero di telefono valido.',
        PHONE_NUMBER_LIMIT: 'I numeri di telefono non possono contenere più di 10 cifre.',
        NET_ANNUAL_LIMIT: 'Netto annuale non può essere vuoto e non può essere zero.',
        NET_ANNUAL_INVALID: 'Inserisci un importo valido in Netto annuale.'
    },
    AUDIT_QUESTIONS: {
        QUESTION_REQUIRED: 'La domanda è obbligatoria',
        ENTER_VALID_SCORE: 'Inserisci un punteggio valido.',
        ENTER_VALID_CHOICES: 'Inserisci scelte valide',
        SUCCESS: 'Successo',
        ERROR: 'Errore',
        NO_QUESTIONS: "Nessuna domanda trovata",
        THANK_YOU: "Grazie!",
        SOMETHING_WRONG: "Qualcosa è andato storto durante l'invio del feedback, riprova!",
        FEATURE_NOT_AVAILABLE: 'Questa funzionalità non è disponibile.',
        RECEIVED_FEEDBACK_1: "Ben fatto! Hai salvato con successo le risposte per ",
        RECEIVED_FEEDBACK_2:"categoria.",
        RECEIVED_FEEDBACK_3:"Ricorda, puoi sempre aggiornare le tue risposte per questa categoria prima dell'invio finale.",
        MAIL_SENT_MESSAGE:'Posta inviata.',
        SUBMIT_AUDIT_1:'Ci sono domande obbligatorie senza risposta nelle seguenti categorie: ',
        SUBMIT_AUDIT_2:'Si prega di rispondere alle domande obbligatorie e quindi inviare la verifica.',
        SUBMIT_AUDIT_3:'Hai inviato con successo il tuo audit!',
        SUBMIT_AUDIT_4:'Puoi visualizzare le risposte di audit inviate e i tuoi punteggi nel CRM in HUB > Audit.',
        SUBMIT_AUDIT_5:'Riceverai una notifica via email se il Franchisor fornisce feedback sul tuo audit.',
        SUBMIT_AUDIT_6:'Puoi anche vedere il feedback fornito dal Franchisor e intraprendere azioni correttive da HUB > Audit.',
        SAVE_ALERT_1:'Attenzione! Stai per lasciare questo ',
        SAVE_ALERT_2:'categoria senza salvare le modifiche.',
        SAVE_ALERT_3:'Fare clic su OK per continuare dopo il salvataggio oppure chiudere questo avviso per rimanere qui e continuare con questa categoria.',
        REQUEST_CORRECTIVE_SUCCESS:'Grazie! Il tuo feedback è stato inviato via email con successo.',
        SUBMIT_CORRECTIVE_ACTION:'Thanks! Your corrective action information has been emailed successfully.',
        EMPTY_REQUEST_CORRECTIVE_MESSAGE:'Non hai aggiunto il tuo feedback per nessuna delle domande.'+'\n'+'Aggiungi prima il feedback e poi fai clic su "Richiedi azioni correttive"',
        EMPTY_SUBMIT_CORRECTIVE_MESSAGE:'Non hai aggiunto le tue azioni correttive rispetto a nessuno dei feedback.' + '\n' + "Aggiungere prima l'azione correttiva e poi fare clic su 'Invia azioni correttive'.",
        EMPTY_AUDIT_BY_NAME_AND_EMAIL:'Il campo Nome e Indirizzo email è vuoto. Inserisci i valori in quei campi.',
        EMPTY_AUDIT_BY_NAME:'Il campo Nome è vuoto. Inserisci i valori in quei campi.',
        EMPTY_AUDIT_BY_EMAIL:'Il campo del tuo indirizzo email è vuoto. Inserisci i valori in quei campi.',
        AUDIT_TYPE_SELECT_VALIDATION:'リンクを生成するには、監査タイプを 1 つ選択してください。',
        INVALID_AUDIT_EMAIL:'Inserisci un indirizzo email valido.',
        INVALID_AUDIT_NAME:'Inserisci un nome valido.',
    },
    AUDIT_ANSWERS:{
        NO_RECORD_FOUND:'Nessuna risposta con domande correttive trovata',
    },
    AUDIT_QUESTION_TYPES:{
        MAX_EXCEED:'Massimo superato',
    },
    COMPLIANCE: {
        SINGLE_RECORD_EDIT: 'Seleziona 1 record da modificare.',
    },
    REVIEW_WEBSITE: {
        WEBSITE_NAME: 'Inserisci il nome del sito web',
        WEBSITE_URL: 'Inserisci l\'URL del sito web',
        SAVE:'Salvato con successo',
        VALID_URL:'Per favore, inserisci un URL valido'
        
    },
    WEBFORM :{
        GROUP_BLANK :'Seleziona un gruppo',
        NAME_BLANK :'Il nome non può essere vuoto',
        URL_VALIDATION:'Si prega di fornire un URL del sito Web valido!',
        ASSIGNTO_BLANK :'Seleziona almeno un rappresentante di vendita dal campo Assegna a.',
        WORKFLOW_BLANK:'Seleziona un flusso di lavoro.',
        SOURCE_BLANK:'Seleziona una fonte ',
        LINK_COPIED_SUCCESS :'codice copiato.',
        SAVE_SUCCESS:'I moduli Web sono stati salvati con successo!',
        
    },
    ROYALTY_PLANS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_ROYALTY_PLAN_RECORD: 'Hai una scheda del piano royalty aperta. Chiudi la scheda prima di aggiungere un nuovo piano royalty.',
        FIELDS_CANNOT_BLANK: ' il campo non può essere vuoto.',
        ROYALTY_PLAN_SAVE_SUCCESS: 'Ril piano fedeltà è stato salvato con successo.',
        ROYALTY_PLAN_DUPLICATE_PLAN_NAME: 'Hai già un piano royalty con lo stesso nome. Inserisci un nome di piano diverso.',
        FIXED_PERCENTAGE_VALIDATION:'il campo deve avere un valore compreso tra 1 e 100%.'
    },
    ROYALTY_RUNS: {
        REPORT_SALES_SAVED_SUCESS: 'I record delle vendite segnalate sono stati salvati correttamente.',
        ROYALTIES_PROCESSED: 'I record delle vendite segnalati sono stati calcolati correttamente.',
        ROYALTIES_NOT_PROCESSED: 'Impossibile elaborare le royalty! Uno o più record delle vendite riportate non sono pronti per l\'elaborazione. Verifica le vendite riportate per tutti gli affiliati e chiudile. Quindi elaborare la Royalty.',
        ROYALTIES_SUBMITTED: 'Hai inviato con successo Royalty per questo periodo.',
        ROYALTIES_NOT_SUBMITTED: 'Nessun record da inviare!',
        INVOICE_ALL_CREATED: 'I record delle fatture sono stati creati per tutte le unità.',
        INVOICE_NOT_CREATED: 'Non sono disponibili record Royalty per questo record Royalty Run. È possibile eseguire l\'azione Fattura tutti solo quando sono disponibili record Royalty.',
        EMAIL_ALL_SUCCESS:'L\'e-mail è stata inviata a tutte le unità in cui è disponibile la fattura.',
        POST_ALL_SUCCESS: 'Le informazioni sulle royalty per questo periodo sono state pubblicate con successo in tutte le unità.',
        NO_UNITS_ENROLLED: 'Impossibile creare vendite segnalate! Non c\'è nessuna unità che si sia iscritta a questo piano. Per prima cosa iscrivi le unità a questo piano e poi elabora le vendite riportate.',
    },
    REPORTED_SALES: {
        REPORTED_SALES_UPDATED: 'Report vendite aggiornato correttamente.',
        REPORTED_SALES_CLOSED: 'Il record delle vendite segnalate è stato chiuso correttamente.',
        //REPORTED_SALES_SOURCE_NOT_ADDED: 'Source field is mandatory in order to update the Reported Sales record. Please edit the record and provide correct information in the Source field and then perform Update operation.',
        REPORTED_SALES_SOURCE_NOT_ADDED: 'Impossibile aggiornare le vendite segnalate! La fonte deve essere specificata in Accesso FE > Impostazioni > Franchising > Vendite segnalate. Quindi eseguire l\'aggiornamento.',
        REPORTED_SALES_CREATED :'I record di vendita segnalati sono stati aggiunti correttamente per questo periodo per tutte le unità iscritte.'
    },
    ROYALTIES: {
        INVOICE_ALREADY_EXIST: 'La fattura esiste già per questo record di royalty.',
        INVOICE_CREATED: 'Il record della fattura è stato creato per l\'unità selezionata.',
        EMAIL_SUCCESS:'L\'e-mail viene inviata all\'unità.',
        POST_SUCCESS: 'Fattura registrata nell \'unità come allegato sotto le vendite segnalate.',
        NO_INVOICE_EXIST: 'Non è disponibile alcun record fattura per questo record Royalty. Crea una fattura e riprova.'
    },
    ROYALTY_REPORT_V2: {
        NO_LAST_RUN_RECORD_FOUND: `Non sono disponibili record di royalty precedenti, seleziona un 'opzione diversa per il periodo.`,
        ALL_OPTIONS_REQUIRED: 'Seleziona tutti i filtri disponibili e riprova',
        NO_ROYALTY_FIELD_SETUP_DONE: 'Imposta prima i campi per il rapporto Royalty da Impostazioni > Franchising > Impostazione rapporto Royalty e poi esegui il rapporto Royalty.',
        NO_FE_ROYALTY_FIELD_SETUP_DONE: 'Il tuo rapporto sulle royalty non è ancora stato impostato. Contatta l amministratore aziendale per configurarlo.',
        NO_RECORDS: 'Nessun record trovato!',
    },
    SALES_AND_REFUND: {
        ALL_OPTIONS_REQUIRED: 'Seleziona tutti i filtri disponibili e riprova',
    },
    INTAKE_FORM : {
        RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM:'You have an open Intake form tab. Please close that tab before opening or adding a new Intake form.',
        INTAKE_FORM_SUCCESSFULLY_SAVE: 'Saved Successfully.',
        THANK_YOU: "Thank You!",
    },
    EXCEPTION: {
        EXCEPTION_SAVE_SUCESS: 'Saved Successfully.',
    },
    PHONE_NUMBERS: {
        USE_FOR_NOTIFICATION_SAVE: 'Dal numero aggiornato per le notifiche.',
        USE_NOTIFICATION: 'Questo numero affittato verrà utilizzato per la notifica. Fare clic su OK per confermare.',
        REMOVE_NOTIFICATION: 'Este número alugado será removido para notificação. Por favor clique em OK para confirmar.',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        SAVE_SUCCESS: 'Le tue impostazioni sono state salvate con successo.',
        SAVE_FAIL: 'La tua impostazione non è stata salvata.',
        NO_PROVIDER: 'Nessun fornitore è stato ancora selezionato per la funzione Telefono e SMS.\n Seleziona prima un fornitore per poter utilizzare le funzioni Telefono e SMS.',
        NO_TWILIO_NUMBERS_FOUND: 'Non è disponibile alcun numero di telefono nel tuo account Twilio connesso.\n Aggiungi prima il numero nel tuo account Twilio, quindi esegui l\'operazione di importazione.',
        NONE_SELECT_CONFIRM: 'Ciò disabiliterà l\'opzione Telefono e SMS in CRM da tutte le posizioni.\n Se disponi di flussi di lavoro o campagne di automazione in cui il testo è già configurato, modificali di conseguenza poiché non funzionerà più.'
    }
}
export default message
