import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { Button,Chip,FormControl,FormControlLabel,InputLabel,Icon,IconButton,MenuItem,Radio,RadioGroup,Step,Stepper,StepLabel,Menu,Select as SelectField,TextField} from "@mui/material";
import ShowCircularProgress from "../components/circularProgress";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import {constants,EVENT_TYPE_KEYS,} from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import { Navigate } from "react-router-dom";
import {getAppDialog,getAppCustomDrawer,} from "../../../services/actions/appContainerActions";
import { Link } from "react-router-dom";
import {dateTimeFormat,timeZone,isEmailExistsInList,getBeeEditorFontList,} from "../../../services/helper/utils";
import moment from "moment";
import {hasAccessPermission,addCampaignTypeIntoReduxStore,getCoupons,getDefaultBeeEditorData,getObjectLabelByObject,} from "../../../services/helper/common";
import * as sfDialogs from "../components/sfDialogs";
import {addTab,TYPE_LIST_VIEW,getActiveTab,} from "../../../services/helper/sfTabManager";
import {isValidParam,getIntParam,getStringParam,getArrayParam,getObjectParam,} from "../../../services/helper/parameterVerifier";
import { setMarketingResults } from "../../../services/actions/marketingReportAction";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { refreshListView } from "../../../services/actions/listViewAcions";
import { sortArrayObjectByProperty } from "../../../services/helper/utils";
import { refreshCalendarForBroadcast } from "../../../services/actions/calendarActions";
import IframeComm from "react-iframe-comm";
import Autocomplete from "@mui/material/Autocomplete";
import { getCouponsAction } from "../../../services/actions/couponActions";
import { getAllCampaignTypes } from "../../../services/actions/campaignTypeActions";
import Bee from "@mailupinc/bee-plugin";
import { styled } from '@mui/material/styles';

const StyledStep = styled('div')(({ theme, status }) => ({
	zIndex: 1,
	color: '#fff',
	backgroundColor:'#717171',
	width: 20,
	height: 20,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(status.active && {
	  color:'white',
	  backgroundColor: localStorage.getItem('background'),
	}),
  }));

function ColoredStep(props) {
	const { active, completed, className } = props;
	return (
	  <StyledStep status={{ completed, active }} className={className}>
		{props.icon}
	  </StyledStep>
	);
  }

const BroadcastCampaignForMosico = ({ id, type }) => {
	const dispatch = useDispatch();

	const app = useSelector((state) => state.app);
	const data = useSelector((state) => state.data);
	const margefields = useSelector((state) => state.sfForm.data.contacts.fields);
	let campaignTypes = useSelector((state) => state.campaignTypes);
	const coupons = useSelector((state) => state.coupons);
	const [finished, setFinished] = useState(false);
	const [stepIndex, setStepIndex] = useState(0);
	const [campaign, setCampaign] = useState({
		bd_name: "",
		sender_name: "",
		subject: "",
		email_message: "",
		id: id | 0,
		sender_email: "",
		campaign_targets: [],
		view_as_web_page: false,
		t_status: null,
		personalize_fields: [],
		metadata: "",
		content: "",
		object: constants.BROADCAST_CAMPAIGNS_OBJECT,
		endPoint: constants.endPoint,
		token: localStorage.getItem("token"),
		template_type: "",
		template_name: "",
		campaign_type: "",
		user_name: app.me.name,
		user_email: app.me.email,
		language: app.me.language,
		coupons: [],
		scheduler_slug: app.appointment_scheduler_slug,
		beeEditorAction : '',
	});
	const [personalize, setPersonalize] = useState(-1);
	const [mounted, setMounted] = useState(false);
	const [couponLoader, setCouponLoader] = useState(true);
	const [personilizeOpen, setPersonilizeOpen] = useState(false);
	const [error, setError] = useState({
		bd_name: "",
		filter_options: "",
		subject: "",
		sender_name: "",
		sender_email: "",
	});
	const [buttonVisible, setButtonVisible] = useState("none");
	const [redirect, setRedirect] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState("");
	const [verifid_email_ids, setVerifid_email_ids] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [filter_options, setFilter_options] = useState([]);
	const [selected_filter_options, setSelected_filter_options] = useState([]);
	const [filter_type, setFilter_type] = useState("group");
	const [loaded_filter_options, setLoaded_filter_options] = useState({
		group: { loaded: false, options: [] },
		contact: { loaded: false, options: [] },
		account: { loaded: false, options: [] },
	});
	const [options_loading, setOptions_loading] = useState("group");
	const [multi, setMulti] = useState(true);
	const [email_credit_balance, setEmail_credit_balance] = useState(null);
	const [scheduled, setScheduled] = useState(false);
	const [scheduled_on, setScheduled_on] = useState(null);
	const [stickyStyle, setStickyStyle] = useState({});
	const [result, setResult] = useState(false);
	const [resultContent, setResultContent] = useState(null);
	const [resultExecuteStart, setResultExecuteStart] = useState(false);
	const [editorInstance, setEditorInstance] = useState(null);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [placeholder, setPlaceholder] = useState(null);
	const [cursorposition, setCursorposition] = useState(0);
	const [isFullScreenEditor, setIsFullScreenEditor] = useState(false);
	const [savedSenderEmail, setSavedSenderEmail] = useState(null);
	const [bdName, setBdName] = useState("");
	const [isCopy, setIsCopy] = useState(false);
	const [campTypelistArr, setCampTypelistArr] = useState(null);
	const [campTypeMenuItems, setCampTypeMenuItems] = useState(null);
	const [disabledFrmEmail, setDisabledFrmEmail] = useState(false);
	const [isDisableRefresh, setIsDisableRefresh] = useState(false);
	const [isOpenNoteTypeList, setIsOpenNoteTypeList] = useState(false);
	const [anchorELNoteTypeList, setAnchorELNoteTypeList] = useState(null);
	const [merge_fields, setMerge_fields] = useState(null);
	const [token, setToken] = useState(null);
    const [bee, setBee] = useState({})
    const [changeLog, setchangeLog] = useState({selected_filter_options:[],disabled:false});
    const beeTest = new Bee(changeLog.token);
	


	const loadBroadcastCampaignData = (id, templateId) => {
		let tempStepIndex = 0;
		let fields =  margefields;
		let tempCampaign = campaign;
		let log = changeLog;
		fields = fields.filter(f => (f.name !== 'header' && f.name !== ''));
		let _mergeFields = {};
		sortArrayObjectByProperty(fields, 'label');
		for (let i = 0; i < fields.length; i++) {
			let fieldsName = "j_contacts." + fields[i].name;
			_mergeFields[fieldsName] = fields[i].label;
		}
			campaign.personalize_fields = _mergeFields;
		var response = null;
		if (id !== undefined && id !== null && id !== 0) {
			response = Promise.resolve(HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.EDIT + '/' + id, null));
			response.then((response) => {
				const campaign_details = response.campaign_details;
				setBdName(campaign_details.bd_name);
				tempCampaign['bd_name'] = campaign_details.bd_name;
				tempCampaign['sender_name'] = campaign_details.from_name;
				tempCampaign['id'] = id;
				tempCampaign['sender_email'] = campaign_details.sender_email;
	
				if (campaign_details.from_name !== null && campaign_details.from_name.trim() === constants.RECORD_OWNER) {
					setDisabledFrmEmail(true);
					setIsDisableRefresh(true);
					tempCampaign['sender_email'] = '';
				}
				tempCampaign['view_as_web_page'] = campaign_details.view_as_web_page;
				tempCampaign['t_status'] = campaign_details.t_status;
				tempCampaign['subject'] = response.template_details.subject;
				tempCampaign['email_message'] = response.template_details.email_message;
				tempCampaign['campaign_type'] = campaign_details.campaign_type === 0 ? '' : campaign_details.campaign_type;
				tempCampaign['metadata'] = response.template_details.metadata;
				tempCampaign['content'] = response.template_details.content;
				tempCampaign['editor_type'] = response.template_details.editorType;
				setMerge_fields(response.available_mail_merge_fields);
				setButtonVisible("inline-block");
				setFilter_type(campaign_details.target_type);
				if (campaign_details.target_type === "666") {
					setFilter_type("contact");
				}
				setVerifid_email_ids(generateFromEmailOptions(response.verifid_email_ids));
	
				if (tempCampaign['sender_email'] === null || tempCampaign['sender_email'] === '') {
					tempCampaign['sender_email'] = '';
				}
				else if ((!isEmailExistsInList( verifid_email_ids, campaign.sender_email)) && (campaign.sender_email === null)) {
					tempCampaign['sender_email'] = app.me.email;
				}
				setSavedSenderEmail(tempCampaign['sender_email']);
				setEmail_credit_balance(response.email_credit_balance);
				let tempError = error;
				tempError["bd_name"] = "";
				tempError["filter_options"] = "";
				tempError["subject"] = "";
				tempError["sender_name"] = "";
				tempError["sender_email"] = "";
				setError({...tempError});
				if (campaign_details.t_status === 'Queued' || campaign_details.t_status === 'Executed'
					|| campaign_details.t_status === 'Halted' || campaign_details.t_status === 'Running') {
						setDisabled(true);
						log.disabled = true;
						setchangeLog({ ...log });
						setScheduled(true);
						setScheduled_on(campaign_details.run_date);
				}
	
				if (campaign_details.t_status === 'Running') {
					setDisabled(true);
					log.disabled = true;
						setchangeLog({ ...log });
				}
	
				if (campaign_details.campaign_targets) {
					if (getArrayParam(campaign_details.campaign_targets).length > 0) {
						let temp_selected_filter_options = changeLog.selected_filter_options;
	
						temp_selected_filter_options = campaign_details.campaign_targets.map(function (target, index) {
							if (campaign_details.target_type === 'group') {
								return {
									value: target.id,
									label: target.name,
									title: target.name,
								};
	
							} else if (campaign_details.target_type === '666') {
								return {
									value: target.id,
									label: target.name,
									type: campaign_details.target_type,
								};
	
							} else {
								return {
									value: target.id,
									label: target.name
								};
							}
						});
						setSelected_filter_options([...temp_selected_filter_options]);
						
                        log.selected_filter_options = temp_selected_filter_options;
                        setchangeLog({ ...log });
						tempCampaign['campaign_targets'] = campaign_details.campaign_targets.map(function (target, index) {
							return {
								id: target.id,
								name: target.name,
								type: campaign_details.target_type
							};
						});
						
					
						let setOptions = campaign_details.campaign_targets.map(function (
							target,
							index
						) {
							return {
								value: target.id,
								label: target.name,
							};
						});

						if (campaign_details.target_type === 'contact' || campaign_details.target_type === 'account' || campaign_details.target_type === '666') {
							setSelected_filter_options(setOptions[0]);
							
                        log.selected_filter_options = setOptions[0];
                        setchangeLog({ ...log });
						}

					}
				}
				else {
					setSelected_filter_options([]);
					
                        log.selected_filter_options = [];
                        setchangeLog({ ...log });
					tempCampaign['campaign_targets'] = [];
				}
				setCursorposition(response.template_details.subject.length);
				setMounted(true);
				if (campaign_details.t_status === "Executed" || type === "copy") {
					tempStepIndex = 0;
					setStepIndex(tempStepIndex);
				} else {
					tempStepIndex = 1;
					setStepIndex(tempStepIndex);
				}
	
				setCampaign(tempCampaign);
			   
					if (tempStepIndex === 1 && campaign.editor_type === constants.EDITOR_BEE) {
						registerBeeEditor();
					}
			
				if (type === 'edit') {
					if (tempStepIndex === 1 && isValidParam(bee)) {
						let templateObj = getStringParam(response.template_details.content) !== "" ? JSON.parse(response.template_details.content) : getDefaultBeeEditorData();
						if(changeLog.token) {
							bee.load(templateObj)
						}
					}
				}
	
			   loadFilterOptions(campaign_details.target_type);
	
				if (type !== "copy" && (campaign_details.t_status === 'Executed' || campaign_details.t_status === 'Running')) {
					loadResults();
				}
	
				if (type === "copy") {
				   handleCopy();
				}
	
			});
	
		} else {
			let params = {
				"template_id": isValidParam(templateId) ? templateId : data.obj.template_id
			};
	
			response = Promise.resolve(HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.NEW, params));
			response.then((response) => {
				setMerge_fields(response.available_mail_merge_fields);
				setEmail_credit_balance(response.email_credit_balance);
			   tempCampaign['subject'] = response.template_details.subject;
				if (data.obj.template_type === constants.LIBRARY_TEMPLATE) {
				   tempCampaign['template_name'] = data.obj.template_id;
				}
				if (filter_type === "group") {
					setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP);
				} else if (filter_type === "contact") {
					setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER +
						getObjectLabelByObject(constants.CONTACTS_OBJECT));
		
				} else if (filter_type === "account") {
					setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER +
						getObjectLabelByObject(constants.ACCOUNTS_OBJECT));
		
				}
				let tempError = error;
				tempError["bd_name"] = "";
				tempError["filter_options"] = "";
				tempError["subject"] = "";
				tempError["sender_name"] = "";
				tempError["sender_email"] = "";
				setMounted(true);
				setError({...tempError});
				setVerifid_email_ids(generateFromEmailOptions(response.verifid_email_ids));
				setSelectedTemplate(data.obj.template_id);
				setSelectedCategory(data.obj.category_id);
			   tempCampaign['template_type'] = data.obj.template_type;
			   tempCampaign['email_message'] = response.template_details.email_message;
			   tempCampaign['content'] = response.template_details.content;
			   tempCampaign['metadata'] = response.template_details.metadata;
			   tempCampaign['editor_type'] = response.template_details.editorType;
			   setCursorposition(0);
	
				if (type === 'edit' && isValidParam(templateId)) {
					if (stepIndex === 1 && isValidParam(bee)) {
						let templateObj = getStringParam(response.template_details.content) !== "" ? JSON.parse(response.template_details.content) : getDefaultBeeEditorData();
						if(changeLog.token) {
							bee.load(templateObj)
						}
					}
				} else {
				   tempCampaign['id'] = 0;
				   tempCampaign['sender_name'] = app.me.name;
				   tempCampaign['sender_email'] = app.me.email;
				}
				setCampaign(tempCampaign);
				if(filter_options.length === 0 && filter_type == 'group') {
					loadFilterOptions('group');
				}
				
			});
		}
		
	};

	const generateFromEmailOptions = (verifid_email_ids) => {
		let verifid_email_options = [];
		try {
			if (
				verifid_email_ids !== undefined &&
				Array.isArray(verifid_email_ids) &&
				verifid_email_ids.length > 0
			) {
				verifid_email_ids.forEach(function (value) {
					if (!isEmailExistsInList(verifid_email_options, value)) {
						verifid_email_options.push({
							value: value,
							label: value,
						});
					}
				});
			}
			if (!isEmailExistsInList(verifid_email_options, app.me.email)) {
				verifid_email_options.unshift({
					value: app.me.email,
					label: app.me.email,
				});
			}
			verifid_email_options.push({
				value: "-1",
				label:
					"---" +
					getLocalizedStrings().label.BROADCAST_CAMPAIGNS
						.VERIFY_EMAIL_ADDRESS_OPTION +
					"---",
			});
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> generateFromEmailOptions()':" + error
			);
		}

		return verifid_email_options;
	};
	const focusSubject = (event) => {
		try {
			setCursorposition(event.target.selectionStart);
		} catch (error) {
			console.log("Error in focusSubject() in salseTemplate.js : " + error);
		}
	};

	const loadResults = () => {
		setResultExecuteStart(true);
		setResult(false);
		setResultContent(null);

		let params = {
			campaign_id: campaign.id,
			report_type: "Broadcast Report",
		};
		var promise = Promise.resolve(
			HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.RESULTS, params)
		);

		promise.then((response) => {
			if (
				Array.isArray(response.marketing_results) &&
				response.marketing_results.length > 0
			) {
				setResult(true);
				setResultContent(response);
				setResultExecuteStart(false);
			} else {
				setResult(true);
				setResultExecuteStart(false);
				setResultContent('');
			}
		});
	};

	const checkAndResetSelectedFilterOptions = (_filter_type) => {
		if (_filter_type === "group") {
			let tempDisabled = changeLog.disabled;
			let tempScheduled = scheduled;
		
			let selected_filter_options = [];
			let campaign_targets = [];
			if (getArrayParam(selected_filter_options).length > 0) {
				if (getStringParam(campaign.t_status) !== "Executed") {
					selected_filter_options.forEach(function (selectedObj) {
						filter_options.forEach(function (listObj) {
							if (selectedObj.value === listObj.value) {
								selected_filter_options.push(selectedObj);
								campaign_targets.push({
									id: selectedObj.value,
									name: selectedObj.label,
									type: "group",
								});
							}
						});
					});

					selected_filter_options =
						selected_filter_options.length > 0
							? selected_filter_options
							: selected_filter_options;
					campaign.campaign_targets = campaign_targets;

					if (
						changeLog.disabled &&
						getStringParam(campaign.t_status) !== "Queued" &&
						(type === "copy" || type === "edit")
					) {
						tempDisabled = false;
					}
					if (type === "copy") {
						let tempScheduled_on = scheduled_on;
						if (scheduled && getStringParam(tempScheduled_on) !== "") {
							tempScheduled = false;
							tempScheduled_on = null;
							setScheduled_on(tempScheduled_on);
						}
					}
				
				}
			}
			setDisabled(tempDisabled);
			let log = changeLog;
			log.disabled = tempDisabled;
			setchangeLog({ ...log });
			setScheduled(tempScheduled);
			
		}
	};

	const loadFilterOptions = (_filter_type) => {
		if (_filter_type === "666") {
			_filter_type = "contact";
		}
		setOptions_loading(true);
		if (loaded_filter_options[_filter_type].loaded) {
			let multi = _filter_type === "group" ? true : false;

			setFilter_options(loaded_filter_options[_filter_type].options);
			setFilter_type(_filter_type);
			setOptions_loading(false);
			setMulti(multi);
			checkAndResetSelectedFilterOptions(_filter_type);
		}

		let end_points, params;
		if (_filter_type === "group") {
			end_points = endPoints.GROUP.LIST;
			params = {
				fields: ["name", "id"],
				search_field_name: "t_status",
				search_text: "Active",
				search_type: constants.SEARCH_TYPE_EXACT,
			};
			setMulti(true);
		} else if (_filter_type === "contact") {
			end_points = endPoints.CONTACTS.CAMPAIGN_FILTER;
			params = null;
			setMulti(false);
		} else if (_filter_type === "account") {
			end_points = endPoints.ACCOUNTS.CAMPAIGN_FILTER;
			params = null;
			setMulti(false);
		}

		var response = Promise.resolve(HTTPClient.get(end_points, params));

		response.then((response) => {
			let options = [];
			let records;
			if (_filter_type === "contact" || _filter_type === "account") {
				records = response.queries;
			} else {
				records = response.records;
			}
			records.forEach(function (data) {
				let filterObject = {
					value: data.id.toString(),
					label: data.name,
				};
				if (data.hasOwnProperty("type")) {
					filterObject.type = data.type;
				}
				if (_filter_type === "group") {
					filterObject.title = data.name;
				}
				options.push(filterObject);
			});

			options.sort(function (option1, option2) {
				let opt1 = option1.label.toLowerCase();
				let opt2 = option2.label.toLowerCase();
				if (opt1 < opt2) return -1;
				if (opt1 > opt2) return 1;
				return 0;
			});

			if (_filter_type === "contact" || _filter_type === "account") {
				if (changeLog.selected_filter_options.length === 0 || (Array.isArray(changeLog.selected_filter_options) &&
						changeLog.selected_filter_options.length === 0)
				) {
					setSelected_filter_options("");
					let log = changeLog;
                        log.selected_filter_options = "";
                        setchangeLog({ ...log });
				}
			}
			
			setFilter_options(options) ;
			let loadedFilterOPtions = loaded_filter_options;
			loadedFilterOPtions[_filter_type].loaded = true;
			loadedFilterOPtions[_filter_type].options =options;
			setLoaded_filter_options(loadedFilterOPtions);
			setFilter_type(_filter_type);
			setOptions_loading(false);

			checkAndResetSelectedFilterOptions(_filter_type);
			if (
				Array.isArray(changeLog.selected_filter_options) &&
				changeLog.selected_filter_options.length > 0
			) {
				reGenerateFilterOptions(changeLog.selected_filter_options);
			}
		});
	};

	const validateForm = () => {
		let isValid = true;
		let tempError = error;

		let addl_msg = "";
		if (campaign.bd_name.trim() !== "") {
			addl_msg =
				" " +
				getLocalizedStrings().message.COMMON.FOR +
				" " +
				campaign.bd_name +
				" ";
		}
		if (campaign.bd_name.trim() == "") {
			isValid = false;
			tempError["bd_name"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS.NAME_BLANK;
		}

		if (campaign.sender_name.trim() === "") {
			isValid = false;
			tempError["sender_name"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FROM_NAME_BLANK +
				addl_msg;
		}

		if (campaign.subject.trim() === "") {
			isValid = false;
			tempError["subject"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS.SUBJECT_BLANK +
				addl_msg;
		}

		if (
			!disabledFrmEmail && campaign.sender_name !="[Record Owner]" &&
			(campaign.sender_email === "-1" ||
				campaign.sender_email === "" ||
				campaign.sender_email === undefined)
		) {
			isValid = false;
			tempError["sender_email"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FROM_EMAIL_BLANK +
				addl_msg;
		}

		if (changeLog.selected_filter_options === "" ||(Array.isArray(changeLog.selected_filter_options) && changeLog.selected_filter_options.length === 0)) {
			isValid = false;

			let filter_type_msg = "";
			if (filter_type === "group") {
				filter_type_msg =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_GROUP;
			} else if (filter_type === "contact") {
				filter_type_msg =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_CONTACT;
			} else if (filter_type === "account") {
				filter_type_msg =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_ACCOUNT;
			}

			tempError["filter_options"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS
					.GROUP_FILTER_BLANK_COMMON_MSG +
				" " +
				filter_type_msg +
				addl_msg;
		} else if (
			typeof changeLog.selected_filter_options === "object" &&
			changeLog.selected_filter_options.value === -1
		) {
			let filter_type_msg = "";
			if (filter_type === "contact") {
				filter_type_msg =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_CONTACT;
			} else if (filter_type === "account") {
				filter_type_msg =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_ACCOUNT;
			}

			tempError["filter_options"] =
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS
					.GROUP_FILTER_BLANK_COMMON_MSG +
				" " +
				filter_type_msg +
				addl_msg;
			
		}
		setError({...tempError});
		return isValid;
	};

	const reGenerateFilterOptions = (options) => {
		let new_options = loaded_filter_options["group"].options;
		new_options = new_options.filter(
			(el) => !options.filter((els) => els.value === el.value).length
		);
		if (options.length === 3) {
			new_options = new_options.map(function (option, index) {
				return { ...option, isDisabled: true, title: option.label };
			});
		} else {
			new_options = new_options.map(function (option, index) {
				return {
					label: option.label,
					value: option.value,
					title: option.label,
				};
			});
		}

		new_options.sort(function (option1, option2) {
			let opt1 = option1.label.toLowerCase();
			let opt2 = option2.label.toLowerCase();
			if (opt1 < opt2) return -1;
			if (opt1 > opt2) return 1;
			return 0;
		});
		setFilter_options(new_options);
	};

	const handleSelectChange = (event, options) => {
		let campaign_targets = [];

		let temp_filter_type = filter_type;
		if (options.hasOwnProperty("type")) {
			temp_filter_type = options.type;
			temp_filter_type = temp_filter_type.toString();
		}
		if (Array.isArray(options)) {
			options.forEach(function (data) {
				campaign_targets.push({
					id: data.value,
					name: data.label,
					type: temp_filter_type,
				});
			});
			reGenerateFilterOptions(options);
		} else {
			campaign_targets.push({
				id: options.value,
				name: options.label,
				type: temp_filter_type,
			});
		}

		let tempCampaign = campaign;
		tempCampaign.campaign_targets = campaign_targets;
		setCampaign(tempCampaign);
		setSelected_filter_options(options);
		let log = changeLog;
		log.selected_filter_options = options;
		setchangeLog({ ...log });
		
		if(options.length > 0){
			setPlaceholder(null);
		}else{
			if (filter_type === "group") {
				setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP);
			}else{
				setPlaceholder(null);
			}
		}
		
		let tempError = error;
		tempError.filter_options = "";
		setError({...tempError});
	};


	useEffect(() => {
		let broadcastId = 0;
		if (id > 0) {
			broadcastId = id;
		}
			loadBroadcastCampaignData(broadcastId, data.obj.template_id);
				// loadCoupons();
			
		
	}, [id,type,data.obj.template_id]);

	useEffect(() => {
		let broadcastId = 0;
		if (id > 0) {
			broadcastId = id;
		}
		if (campaignTypes.hasOwnProperty('data') && campaignTypes.data === null ) {
			dispatch(getAllCampaignTypes());
		}else{
			getCampaignTypeList();
		}
	}, [couponLoader,campaignTypes.data]);


	useEffect(() => {
		loadCoupons();
	}, []);

	const loadCoupons = () => {
		try {
			let _coupons = {};
			let tempStepIndx = 0;
			let tempCampaign = campaign;
			if (!coupons?.couponMounted) {
				let promise = getCoupons();
				promise.then((response) => {
					getCampaignTypeList();
					if (isValidParam(response.records)) {
						dispatch(getCouponsAction(response.records));
						let records = getArrayParam(response.records);
						records.forEach((record) => {
							
							let discount =
								record.discount_type.toLowerCase() === "percentage"
									? record.discount + "%"
									: "$" + record.discount;
									_coupons[" " + record.name] = `${record.name} (${discount})`;
						});
						if(id > 0){
                            if (tempCampaign.t_status === 'Executed' || type === "copy") {
                                tempStepIndx = 0;
                            } else {
                                tempStepIndx = 1;
                            }
                        }
						setCampaign({...tempCampaign,coupons:_coupons});
						setCampaign(tempCampaign);
						setCouponLoader(false);
						setMounted(true);
                        setStepIndex(tempStepIndx);
						

					}
				});
			} else {
				if (isValidParam(coupons.coupons)) {
					getCampaignTypeList();
					let records = getArrayParam(coupons.coupons);
					records.forEach((record) => {
						let discount =
							record.discount_type.toLowerCase() === "percentage"
								? record.discount + "%"
								: "$" + record.discount;
								_coupons[" " + record.name] = `${record.name} (${discount})`;
					});
				}
				if(id > 0 || tempCampaign.id > 0 ){
					if (tempCampaign.t_status === 'Executed' || type === "copy") {
						tempStepIndx = 0;
					} else {
						tempStepIndx = 1;
					}
				}
				setStepIndex(tempStepIndx);
				setCampaign({...tempCampaign,coupons:_coupons});
				setCouponLoader(false);
				setMounted(true);
				
			}
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaignForMosaico.js -> loadCoupons()':" + error
			);
		}
	};

	const autoSetCampaignData = () => {
		let hasPermission = false;
		try {
			let tempCampaign = campaign;
			if (tempCampaign.id > 0) {
				hasPermission = hasAccessPermission(
					constants.BROADCAST_CAMPAIGNS_OBJECT,
					constants.SECURITY_LEVEL_TYPE_ACCESS,
					constants.ACCESS_TYPE_EDIT
				);
			} else {
				hasPermission = true;
			}
			if (hasPermission) {
				let campaignName = tempCampaign.bd_name.trim();
				if (campaignName.length === 0) {
					var promise = Promise.resolve(
						HTTPClient.get(
							endPoints.BROADCAST_CAMPAIGNS.GET_DEFAULT_CAMPAIGN_NAME,
							{}
						)
					);
					promise.then((response) => {
						if (response !== null && response.hasOwnProperty("bdName")) {
							tempCampaign.bd_name = getStringParam(response.bdName);
							setCampaign(tempCampaign);
							autoSaveCampaign(tempCampaign);
						}
					});
				} else {
					autoSaveCampaign(tempCampaign);
				}
			}
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> autoSetCampaignData():'" + error
			);
		}
	};
	const autoSaveCampaign = (updatecampaign) => {
        try {
			let tempCampaign = campaign;
            let _type = getStringParam(filter_type);
			tempCampaign.bd_name = updatecampaign.bd_name.trim();
            let selectedOptions = changeLog.selected_filter_options;
            let campaignTargets = getArrayParam(campaign.campaign_targets);
            if (_type === 'group') {
                if (Array.isArray(selectedOptions) && getArrayParam(selectedOptions).length === 0) {
                    campaignTargets = [{
                        id: -1,
                        name: '',
                        type: 'group'
                    }];
                }
            }
            else if (_type === 'contact') {
                if (getStringParam(selectedOptions).length === 0) {
                    campaignTargets = [{
                        id: -1,
                        name: '',
                        type: 'contact'
                    }];
                }
            }
            else if (_type === 'account') {
                if (getStringParam(selectedOptions).length === 0) {
                    campaignTargets = [{
                        id: -1,
                        name: '',
                        type: 'account'
                    }];
                }
            }
			tempCampaign.campaign_targets = campaignTargets;
            if (getStringParam(updatecampaign.sender_email) === '-1') {
                tempCampaign.sender_email = '';
            }
			setCampaign(tempCampaign);
            let campType = 0;
            if (isValidParam(updatecampaign.campaign_type) && updatecampaign.campaign_type !== '') {
                campType = updatecampaign.campaign_type;
            }
            let params = {
                "id": updatecampaign.id,
                "bd_name": updatecampaign.bd_name,
                "campaign_targets": campaign.campaign_targets,
                "sender_name": updatecampaign.sender_name,
                "sender_email": updatecampaign.sender_email,
                "subject": updatecampaign.subject,
                "email_message": updatecampaign.email_message,
                "view_as_web_page": updatecampaign.view_as_web_page,
                "campaign_type": campType,
                "content": updatecampaign.content,
                "editor_type": updatecampaign.editor_type
            }
            var response = Promise.resolve(HTTPClient.post(endPoints.BROADCAST_CAMPAIGNS.SAVE, params));
            let campaign_id = campaign.id;
            response.then((response) => {
                if (response.status === 0) {
					setBdName(campaign.bd_name);
                    if (params.id === 0) {
						tempCampaign.id = response.data.id[0];

						setButtonVisible("none");
						tempCampaign['t_status'] = 'New';
						setCampaign(tempCampaign);
                    }
                    if (campaign_id > 0) {
                        if (type === 'new' || type === 'copy') {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.BROADCAST_CAMPAIGNS.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop))
                        }
                        else if (type === 'edit') {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.BROADCAST_CAMPAIGNS.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                    }
                    else {
                        if (type === "new") {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.BROADCAST_CAMPAIGNS.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        else if (type === "copy" && !isCopy) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.BROADCAST_CAMPAIGNS.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        else if (type === 'edit' && !isCopy) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.BROADCAST_CAMPAIGNS.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        if (isCopy) {
							setIsCopy(false);
                        }
                    }
					setMounted(true);
					setButtonVisible("inline-block");
                    refreshListView(constants.BROADCAST_CAMPAIGNS_OBJECT);
                }
                else if (response.status === -1 && response.error.message !== "") {
                    dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
					setMounted(true);
					setButtonVisible("none");
                }

            });
        }
        catch (error) {
            console.error("Error in 'BroadcastCampaign.js -> autoSaveCampaign():'" + error);
        }
    }
	const handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		const cursorPosition = target.selectionStart; 
	
		let tempCampaign = { ...campaign };
		let tempError = error;
		let tempDisabledFrmEmail = disabledFrmEmail;
		let tempIsDisableRefresh = isDisableRefresh;
	
		tempCampaign[name] = value;
	    if (value.trim() !== "") {
			tempError[name] = "";
		}
	
		if (name === "sender_name") {
			if (
				value.trim().replace(/ /g, "").toLowerCase() ===
				constants.RECORD_OWNER.replace(/ /g, "").toLowerCase()
			) {
				tempDisabledFrmEmail = true;
				tempCampaign["sender_name"] = constants.RECORD_OWNER;
				tempCampaign["sender_email"] = "";
				tempIsDisableRefresh = true;
			} else {
				tempDisabledFrmEmail = false;
				tempIsDisableRefresh = false;
	
				if (campaign.sender_email === "") {
					tempCampaign["sender_email"] = app.me.email;
				}
			}
		}
	    setCursorposition(cursorPosition);
		setDisabledFrmEmail(tempDisabledFrmEmail);
		setIsDisableRefresh(tempIsDisableRefresh);
		setCampaign(tempCampaign);
	};
	

	const handleCopy = () => {
		let hasPermission = false;
		let tempCampaign = campaign;
		hasPermission = hasAccessPermission(constants.BROADCAST_CAMPAIGNS_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
		if (hasPermission) {
			let params = {
				"id": campaign.id,
				"bd_name": campaign.bd_name,
			}
			var response = Promise.resolve(HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.COPY_OF_NAME_GET, params));
			response.then((response) => {
				if (isValidParam(response)) {
	
					tempCampaign.id = 0;
					response = response.replace('copy', getLocalizedStrings().label.COMMON.COPY).replace('of', getLocalizedStrings().label.COMMON.OF);
					tempCampaign.bd_name = response;
					setBdName(response);
					tempCampaign.t_status = null;
					setButtonVisible("none");
					setDisabled(false);
					let log = changeLog;
			log.disabled = false;
			setchangeLog({ ...log });
					setScheduled(false);
					setScheduled_on(null);
					setResult(false);
					setResultExecuteStart(false);
					setIsCopy(true);
					setStepIndex(0);
	
					//added by Priyanka on 20.03.18 to auto select the previous saved email
					let verifiedEmail = savedSenderEmail;
					if (isValidParam(verifiedEmail) && verifiedEmail.length > 0) {
						let emailObj = { label: verifiedEmail, value: verifiedEmail }
						handleSenderEmailChange({}, emailObj);
					}
	
					if (changeLog.selected_filter_options.length > 0 && (type === 'copy' || type === 'edit')) {
						if (filter_options.length > 0 && filter_type === 'group') {
							loadFilterOptions(filter_type);
							checkAndResetSelectedFilterOptions(filter_type);
						}
					}
				
					setCampaign(tempCampaign);
					if (editorInstance !== null) {
						editorInstance.edit.on();
					}
				}
			});
	
		} else {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
		}
	}
	
	const handleSelectPersonilizeChange = (event,value) => {
		let tempCampaign = campaign;
         setPersonalize(value) ;
		 setPersonilizeOpen(false);
        let front = (campaign['subject']).substring(0, cursorposition);
        let back = (campaign['subject']).substring(cursorposition, campaign['subject'].length);
		setCampaign({...tempCampaign,subject: front + ' \${' + value + '}' + back})

        setCursorposition(tempCampaign['subject'].length) ;
		setIsOpenNoteTypeList(false);
	};

	const changeRadioButton = (value) => {
		if (value === "group") {
			setSelected_filter_options([]);
			let log = changeLog;
		log.selected_filter_options = [];
		setchangeLog({ ...log });
		} else {
			setSelected_filter_options("");
			let log = changeLog;
		log.selected_filter_options = "";
		setchangeLog({ ...log });
		}
		setFilter_options([]);
		let tempError = error;
		tempError.filter_options = "";
		setError(error);
		loadFilterOptions(value);
		if (value === "group") {
			setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP);
		} else if (value === "contact") {
			setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER +
				getObjectLabelByObject(constants.CONTACTS_OBJECT));

		} else if (value === "account") {
			setPlaceholder(getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER +
				getObjectLabelByObject(constants.ACCOUNTS_OBJECT));

		}
	};
	const handleSenderEmailChange = (event,value) => {
		let tempCampaign = campaign;
		let tempError = error;
		value = value.value;
		if (value === "-1") {
			openVerifyEmailPopup();
			tempCampaign["sender_email"] = value;
		} else {
			tempCampaign["sender_email"] = value;
		}
		tempError["sender_email"] = "";

		setCampaign(tempCampaign);
		setError({...tempError});
	};

	const renderPersonalizeField = () => {
		let personalizeObject = campaign["personalize_fields"];
		return Object.keys(personalizeObject).map((itemKey, index) => {
			return (
				<MenuItem
					key={itemKey + index}
					value={itemKey}
					style={styles.popoverMenuItem}
					fullWidth={true}
					labelstyle={{ color: "#479ccf" }}
					underlinestyle={{ display: "none" }}
					autowidth={"true"}
					onClick={(event) =>
						handleSelectPersonilizeChange(event, itemKey)
					}
				>
					{personalizeObject[itemKey]}
				</MenuItem>
			);
		});
	};

	const setTemplateData = (obj) => {
		let params = {};
		let template_type = obj.template_type;
		if (template_type === constants.LIBRARY_TEMPLATE) {
			params = {
				template_id: null,
			};
		} else if (template_type === constants.USER_TEMPLATE) {
			params = {
				template_id: obj.template_id,
			};
		}

		setSelectedTemplate(obj.template_id);
		setSelectedCategory(obj.category_id);

		var promise = Promise.resolve(
			HTTPClient.get(endPoints.MARKETING_TEMPLATE.TEMPLATE_GET, params)
		);

		promise.then((response) => {
			let tempCampaign = campaign;

			if (obj.template_type === constants.USER_TEMPLATE) {
				tempCampaign["subject"] = response.subject;
				tempCampaign["email_message"] = response.mail_body;
				tempCampaign["metadata"] = response.metadata;
				tempCampaign["content"] = response.content;
				tempCampaign["template_type"] = obj.template_type;
			} else if (obj.template_type === constants.LIBRARY_TEMPLATE) {
				tempCampaign["template_name"] = obj.template_id;
				tempCampaign["template_type"] = obj.template_type;
			}
			setCampaign(tempCampaign);
			dispatch(getAppDialog(false,constants.EDITOR_TEMPLATE_DIALOG,null,null,null,null));
		});
	};

	const confirmSchedule = (obj) => {
		let params = { ...campaign };
		delete params.user_email;
		delete params.user_name;
		params.scheduled_on = obj.schedule_date;
		params.t_status = "scheduled";
		let campType = 0;
		let tempCampaign = campaign;
		if (isValidParam(params.campaign_type) && params.campaign_type !== "") {
			campType = params.campaign_type;
		}
		params.campaign_type = campType;

		var promise = Promise.resolve(
			HTTPClient.post(endPoints.BROADCAST_CAMPAIGNS.SCHEDULE, params)
		);
		promise.then((response) => {
			if (response.status === 0) {
				dispatch(
					getAppDialog(
						false,
						constants.CONFIRM_SCHEDULE_DIALOG,
						null,
						null,
						null,
						null
					)
				);
				
				setDisabled(true);
				let log = changeLog;
			log.disabled = true;
			setchangeLog({ ...log });
				setScheduled(true);
				setScheduled_on(obj.schedule_date);
				setCampaign({...tempCampaign,t_status:response.data.t_status,campaign_type:campaign["campaign_type"] === 0 ? "" : campaign["campaign_type"]});

				if (editorInstance !== null) {
					editorInstance.edit.off();
				}
				dispatch(refreshCalendarForBroadcast(true));
			} else if (response.status === -1 && response.error.message !== "") {
				dispatch(
					showCustomSnackBar(
						response.error.message,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					)
				);
			}
		});
	};

	const executeNow = () => {
		let params = { ...campaign };
		delete params.user_email;
		delete params.user_name;
		params.scheduled_on = "";
		params.t_status = "scheduled";
		let campType = 0;
		let tempCampaign = campaign;
		let tempScheduled = scheduled;
		let tempScheduled_on = scheduled_on;

		if (isValidParam(params.campaign_type) && params.campaign_type !== "") {
			campType = params.campaign_type;
		}
		params.campaign_type = campType;
		var promise = Promise.resolve(
			HTTPClient.post(endPoints.BROADCAST_CAMPAIGNS.EXECUTE, params)
		);
		promise.then((response) => {
			if (response.status === 0) {
				
				setScheduled(true);
				setScheduled_on(moment().tz(timeZone[app.me.timezone]));
				setDisabled(true);
				let log = changeLog;
			log.disabled = true;
			setchangeLog({ ...log });
				setCampaign({...tempCampaign,t_status:response.data.t_status,campaign_type:campaign["campaign_type"] === 0 ? "" : campaign["campaign_type"]});

				if (editorInstance !== null) {
					editorInstance.edit.off();
				}
				dispatch(refreshCalendarForBroadcast(true));
				dispatch(
					getAppDialog(false, constants.EXECUTE_DIALOG, null, null, null, null)
				);
			} else if (response.status === -1 && response.error.message !== "") {
				dispatch(
					showCustomSnackBar(
						response.error.message,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					)
				);
			}
		});
	};

	const schedule = (obj) => {
		let momentObj = obj.date;
		let db_date = momentObj.format("YYYY-MM-DD HH:mm:ss");
		let user_date = momentObj.format(dateTimeFormat[app.me.date_format]);
		let data = { user_date: user_date, db_date: db_date };
		dispatch(
			getAppDialog(false, constants.SCHEDULE_DIALOG, null, null, null, null)
		);
		dispatch(
			getAppDialog(
				true,
				constants.CONFIRM_SCHEDULE_DIALOG,
				getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
				confirmSchedule,
				data
			)
		);
	};

	const handleChangeTemplate = () => {
		let tempdata = {
			selectedTemplate: selectedTemplate,
			selectedCategory: selectedCategory,
			type: campaign.editor_type,
			callFrom: "template change",
			object:constants.BROADCAST_CAMPAIGNS_OBJECT
		};
		if (selectedTemplate !== null && data.obj.template_type === constants.LIBRARY_TEMPLATE) {
			tempdata = { ...tempdata, template_type: constants.LIBRARY_TEMPLATE };
		} else {
			tempdata = { ...tempdata, template_type: constants.USER_TEMPLATE };
		}
		dispatch(
			getAppDialog(
				true,
				constants.EDITOR_TEMPLATE_DIALOG,
				getLocalizedStrings().label.CAMPAIGNS.TEMPLATE_DIALOG_TITLE,
				setTemplateData,
				tempdata,
				constants.BROADCAST_CAMPAIGNS_OBJECT
			)
		);
	};
	const handleSchedule = () => {
		if (validateForm()) {
			dispatch(
				getAppDialog(
					true,
					constants.SCHEDULE_DIALOG,
					getLocalizedStrings().label.BROADCAST_CAMPAIGNS.SCHEDULE_BROADCAST,
					schedule,
					null,
					null
				)
			);
		}
	};

	const handleExecute = () => {
		
		let data = { object:constants.BROADCAST_CAMPAIGNS_OBJECT};
		if (validateForm()) {
			dispatch(
				getAppDialog(
					true,
					constants.EXECUTE_DIALOG,
					getLocalizedStrings().label.BROADCAST_CAMPAIGNS.EXECUTE_BROADCAST,
					executeNow,
					data,
					null
				)
			);
		}
	};

	const handleCancelSchedule = () => {
		let msg =
			"<div>" +
			getLocalizedStrings().message.BROADCAST_CAMPAIGNS.EXECUTION_CANCEL_MSG +
			"</div>" +
			'<div class="schedule-cancel-inn-div2"><b>' +
			getLocalizedStrings().message.BROADCAST_CAMPAIGNS.CONFIRM +
			"</b></div>" +
			"<div>" +
			getLocalizedStrings().message.BROADCAST_CAMPAIGNS
				.EXECUTION_CANNOT_CANCEL_2 +
			"</div>";
		sfDialogs.confirm(
			getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
			msg,
			() => handleSfDialogCancelSchedule(this),
			null
		);
	};

	const handleSfDialogCancelSchedule = () => {
		let params = {
			id: campaign.id,
		};
		var promise2 = Promise.resolve(
			HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.CANCEL_QUEUED, params)
		);
		promise2.then((response) => {
			if (response.is_cancel === true) {
				let tempDisabled = changeLog.disabled;
				let tempCampaign = campaign;
				tempDisabled["disabled"] = false;
				tempCampaign["t_status"] = "New";

				setDisabled(tempDisabled);
				let log = changeLog;
			log.disabled = tempDisabled;
			setchangeLog({ ...log });
				setCampaign(tempCampaign);
				setScheduled(false);
			} else {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS
						.EXECUTION_CANNOT_CANCEL_1,
					null
				);
			}
		});
	};

	const testMail = () => {
		if (validateForm()) {
			let params = {};
			params.email = "";
			params.frommail = campaign.sender_email;
			params.fromname = campaign.sender_name;
			params.subject = campaign.subject;
			params.templateBody = campaign.email_message;
			if (campaign["view_as_web_page"] === true) {
				params.isVawp = "1";
			} else {
				params.isVawp = "0";
			}
			params.testMailType = 'marketing';
			dispatch(
				getAppDialog(true, constants.TEST_MAIL_DIALOG, null, null, params, null)
			);
		}
	};

	const openVerifyEmailPopup = () => {
		try {
			let data = {
				fromName: app.me.name,
				verifiedEmailOptions: verifid_email_ids,
			};
			dispatch(
				getAppDialog(
					true,
					constants.VERIFY_EMAIL_DIALOG,
					getLocalizedStrings().label.BROADCAST_CAMPAIGNS.VERIFY_EMAIL,
					null,
					data,
					constants.BROADCAST_CAMPAIGNS_OBJECT
				)
			);
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> openVerifyEmailPopup():'" + error
			);
		}
	};

	const loadVerifiedEmailIds = () => {
		let verifid_email_ids = [];
		let isReloaded = false;
		try {
			let url = endPoints.BROADCAST_CAMPAIGNS.GET_VERIFIED_EMAIL_IDS;
			let params = {};
			params.getUserMailIds = false;
			let promise = Promise.resolve(HTTPClient.get(url, params));
			promise.then((response) => {
				if (isValidParam(response)) {
					isReloaded = true;
					verifid_email_ids = generateFromEmailOptions(
						response.verified_email_ids
					);
					if (isReloaded) {
						let emailObj = {
							label: verifid_email_ids[0].value,
							value: verifid_email_ids[0].value,
						};
						handleSenderEmailChange({},emailObj);
					}

					setVerifid_email_ids(verifid_email_ids);
					setCampaign(campaign);
				}
			});
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> loadVerifiedEmailIds():'" + error
			);
		}
	};

	const changeCampaignType = (event) => {
		try {
			let value = event.target.value;
			if (isValidParam(value)) {
				if (value !== "Manage") {
					let tempCampaign = campaign;
					setCampaign({...tempCampaign,campaign_type:value});
				} else {
					let data = {};
					data.object = constants.AUTOMATION_DESIGNER_OBJECT;
					dispatch(
						getAppDialog(
							true,
							constants.ADD_CAMPAIGN_TYPE_DIALOG,
							getLocalizedStrings().label.CAMPAIGNS.ADD_CAMPAIGN_TYPE,
							addCampaignType,
							null,
							null
						)
					);
				}
			}
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaign.js -> changeCampaignType()': " + error
			);
		}
	};

	const addCampaignType = (obj) => {
		try {
			let camObj = {
				id: obj.id,
				name: obj.name,
			};

			let camTyps = getArrayParam(campTypelistArr);
			camTyps.push(camObj);
			addCampaignTypeIntoReduxStore(camObj);
			sortArrayObjectByProperty(camTyps, "name");
			let campTypeMenuItems = getCampaignTypeMenuItems(camTyps);

			setCampTypelistArr(camTyps);
			setCampTypeMenuItems(campTypeMenuItems);
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaignForMosico.js -> addCampaignType()': " +
					error
			);
		}
	};

	const getCampaignTypeList = () => {
		try {
			let campaign_types = getObjectParam(campaignTypes.data);
			let campTypelistArr = [];
			let objCamp = null;
			if (Object.keys(campaign_types).length > 0) {
				Object.keys(campaign_types).forEach((itemKey) => {
					objCamp = {};
					objCamp.id = parseInt(itemKey);
					objCamp.name = campaign_types[itemKey];

					campTypelistArr.push(objCamp);

					objCamp = null;
				});
			}

			sortArrayObjectByProperty(campTypelistArr, "name");
			let campTypeMenuItems = getCampaignTypeMenuItems(campTypelistArr);
			let tempCampTypelistArr = campTypelistArr;
			let tempCampTypeMenuItems = campTypeMenuItems;

			tempCampTypelistArr = campTypelistArr;
			tempCampTypeMenuItems = campTypeMenuItems;
			setCampTypelistArr(tempCampTypelistArr);
			setCampTypeMenuItems(tempCampTypeMenuItems);
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaignForMosico.js -> getCampaignTypeList()': " +
					error
			);
		}
	};

	const getCampaignTypeMenuItems = (campTypelistArr) => {
		try {
			let campaignTypes = getArrayParam(campTypelistArr);
			let campTypeItems = null;
			if (campaignTypes !== null && campaignTypes.length > 0) {
				campTypeItems = campaignTypes.map((camTy, index) => {
					return (
						<MenuItem
							key={camTy.id}
							value={camTy.id}
							style={styles.popoverMenuItem}
						>
							{
								<div
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
										width: "142px",
									}}
									title={camTy.name}
								>
									{camTy.name}
								</div>
							}
						</MenuItem>
					);
				});
			}
			return campTypeItems;
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaignForMosico.js -> getCampaignTypeMenuItems()': " +
					error
			);
		}
	};

	const handleNext = () => {
		if (!validateForm()) {
			return false;
		}
		if (stepIndex === 0) {
			let params = {
				id: campaign.id,
				name: campaign.bd_name,
			};
			var response = Promise.resolve(
				HTTPClient.get(
					endPoints.BROADCAST_CAMPAIGNS.CHECK_DUPLICATE_CAMPAIGN_NAME,
					params
				)
			);
			response.then((response) => {
				if (response.status === -1 && response.error.message !== "") {
					dispatch(showCustomSnackBar(response.error.message,styles.snackbarBodyStyleError,{ ...styles.snackBarStyleTop, left: "340px" }));
				} else {
					setStepIndex( stepIndex + 1);
					setFinished(stepIndex >= 2);
					let log = changeLog;
					log.token = null;
					beeTest.token ="";
					setchangeLog({ ...log });
					registerBeeEditor();
				}
			});
		}
	};

	const handlePrev = () => {
		if (stepIndex > 0) {
			sfDialogs.confirm(
				getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
				getLocalizedStrings().message.BROADCAST_CAMPAIGNS.STEPPER_BACK_MESSAGE,
				sfDialogConfirnHandlePrev.bind(this, stepIndex),
				null
			);
		}
	};

	const sfDialogConfirnHandlePrev = (stepIndex) => {
		setStepIndex(stepIndex - 1);
	};

	const handleClose = () => {
		if (campaign.id > 0 && campaign.t_status !== "Executed") {
			sfDialogs.confirm(
				getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
				getLocalizedStrings().message.COMMON.CENCEL_MOSAICO_MESSAGE,
				handleSfDialogClose.bind(),
				null
			);
		} else {
			dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
		}
	};

	const handleSfDialogClose = () => {
		dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
	};

	const validateFilter = (callFrom = "default") => {
		let tempError = error;
		let isValid = true;
		let addl_msg = "";
		try {
			if (
				callFrom !== "testMail" &&
				(changeLog.selected_filter_options === "" ||
					(Array.isArray(changeLog.selected_filter_options) &&
						changeLog.selected_filter_options.length === 0))
			) {
				isValid = false;

				let filter_type_msg = "";
				if (filter_type === "group") {
					filter_type_msg =
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS.FILTER_TYPE_GROUP;
				} else if (filter_type === "contact") {
					filter_type_msg =
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS
							.FILTER_TYPE_CONTACT;
				} else if (filter_type === "account") {
					filter_type_msg =
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS
							.FILTER_TYPE_ACCOUNT;
				}

				tempError["filter_options"] =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS
						.GROUP_FILTER_BLANK_COMMON_MSG +
					" " +
					filter_type_msg +
					addl_msg;
			} else if (
				typeof changeLog.selected_filter_options === "object" &&
				changeLog.selected_filter_options.value === -1 &&
				callFrom !== "testMail"
			) {
				let filter_type_msg = "";
				if (filter_type === "contact") {
					filter_type_msg =
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS
							.FILTER_TYPE_CONTACT;
				} else if (filter_type === "account") {
					filter_type_msg =
						getLocalizedStrings().message.BROADCAST_CAMPAIGNS
							.FILTER_TYPE_ACCOUNT;
				}

				tempError["filter_options"] =
					getLocalizedStrings().message.BROADCAST_CAMPAIGNS
						.GROUP_FILTER_BLANK_COMMON_MSG +
					" " +
					filter_type_msg +
					addl_msg;
				setError({...tempError});
			}
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaign.js -> validateFilter()':" + error
			);
		}
		return isValid;
	};

	const handleQualifiedContacts = () => {
		let label =
			getLocalizedStrings().label.BROADCAST_CAMPAIGNS.QUALIFIED_CONTACTS;
		try {
			if (validateFilter()) {
				let url = "/" + constants.CONTACTS_OBJECT + "/queries";
				openQualifiedContactsTab(label, url);
				refreshListView(constants.CONTACTS_OBJECT);
			}
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaignForMosaico.js -> handleQualifiedContacts()':" +
					error
			);
		}
	};

	const openQualifiedContactsTab = (label, url) => {
		try {
			let activeTab = getObjectParam(getActiveTab());
			let activeTabIndex = getIntParam(activeTab.index);
			let filter = {
				id: 542,
				name: "All records",
				type: constants.SEARCH_QUERY_TYPE_ALL,
			};
			var tab = {
				label: getStringParam(label),
				object: constants.CONTACTS_OBJECT,
				type: TYPE_LIST_VIEW,
				imgName: null,
				url: url,
				isActive: true,
				parentTabIndex: activeTabIndex,
			};
			let info = {
				isReport: true,
				reportLabel: label,
				filter: filter,
				input_param: {
					call_from: constants.CAMPAIGN_QUALIFIED_CONTACTS,
					filter_options: changeLog.selected_filter_options,
					filter_type: getStringParam(filter_type),
				},
			};
			tab.info = info;
			addTab(tab, true);
			dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaignForMosaico.js -> openQualifiedContactsTab()':" +
					error
			);
		}
	};

	const getSpecialLinks = () => {
		let specialLinks = [];
		try {
			let slug = app.appointment_scheduler_slug;
			if (isValidParam(slug) && slug !== "") {
				let link = {
					type: "Appointment",
					label: "Book an appointment",
					link: constants.APPOINTMENT_SCHEDULER_URL + slug,
				};
				specialLinks.push(link);
			}
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaignForMosaico.js -> getSchedulerLink()':" +
					error
			);
		}
		return specialLinks;
	};

	const getMergeTags = () => {
		let mergeTags = [];
		let mergeFields = getArrayParam(merge_fields);
		try {
			mergeTags = [
				// { name: 'Company Logo', value:"${company.logo}"},
                { name: 'Company Name', value:"${company.business_name}"},
                { name: 'Company Website', value:"${company.website}"},
                { name: 'Company Address', value:"${company.business_address}"},
                { name: 'Company Phone', value:"${company.business_phone}"},
                { name: 'Company Email', value:"${company.business_email}"},
                // { name: 'Company Country', value:"${company.country}"},
				{
					name: "Opt-in Link",
					value:
						"[Start Opt-in Link] Enter your display text [End Opt-in Link]",
				},
				{
					name: "Opt-out Link",
					value:
						"[Start Opt-out Link] Enter your display text [End Opt-out Link]",
				},
				{ name: "Signature", value: "${Email_Signature}" },
			];
			let tempTags = mergeFields.map((mergeField) => {
				let key = Object.keys(mergeField)[0];
				let tagValue = key.replace("9", "j_contacts");
				return { name: mergeField[key], value: "$" + `{${tagValue}}` };
			});
			mergeTags.push(...tempTags);
		} catch (error) {
			console.error("Error in 'MosaicoTemplate.js -> getMergeTags()':" + error);
		}
		return mergeTags;
	};

	const registerBeeEditor = async () => {
		
        try {
            if (campaign.editor_type === constants.EDITOR_BEE) {
                let tenantId = getStringParam(app.me.projectId);
                let mergeTags = getMergeTags();
                let specialLinks = getSpecialLinks();
                var beeConfig = {
                    uid: tenantId,
                    container: "bee-plugin-container", //Identifies the id of div element that contains BEE Plugin
                    language: 'en-US',
                    mergeTags: mergeTags,
                    specialLinks: specialLinks,
                    editorFonts: {
                        showDefaultFonts: false,
                        customFonts: getBeeEditorFontList()
                    },
                    titleDefaultConfig: {
                        bold: true
                    },
                    titleDefaultStyles: {
                        h1: {
                            color: '#555555',
                            'font-size': '34px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                        h2: {
                            color: '#555555',
                            'font-size': '23px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                        h3: {
                            color: '#555555',
                            'font-size': '18px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                    },
                    contentDefaults: {
                        text: {
                            html: "I'm a new Text block ready for your content.",
                            styles: {
                                color: "#555555",
                                linkColor: "#0068A5",
                                fontSize: '14px',
                                lineHeight: "120%",
                                fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            },
                        },
                    },
                    onSaveAsTemplate: (jsonFile) => {
                        var response = Promise.resolve(HTTPClient.post(endPoints.MARKETING_TEMPLATE.GET_BEE_MESSAGE_HTML, { page: JSON.parse(jsonFile) }));
                        response.then((response) => {
                            if (isValidParam(response) && isValidParam(response.data) && response.data !== '') {
                                let html = response.data;
								let tempCampaign = campaign;
                                tempCampaign.email_message = html;
                                tempCampaign.content = jsonFile;
								let buttonDiv = document.getElementsByClassName('button_group')
								if(buttonDiv[0]?.className == "button_group button-test")
                                           {
                                   // setCampaign(tempCampaign) ;
								   testMail();
                                } else {
									setCampaign(tempCampaign) ;
                                    autoSetCampaignData();
                                }
                            }
                        });
                    },
                }
                let templateObj = getStringParam(campaign.content) !== "" ? JSON.parse(campaign.content) : getDefaultBeeEditorData();
                await beeTest.getToken(
                    constants.BEE_EDITOR_CLIENT_ID,
                    constants.BEE_EDITOR_CLIENT_SECRET_ID
                    )
                    .then((response)=>{
						let log = changeLog;
                        log.token = response.access_token;
                        setchangeLog({ ...log });
                        setToken(response.access_token)
                    })
                    .then(() => {                 
                        beeTest.start(beeConfig, templateObj)
                          .then(instance =>{
                              setBee(instance)
                          })
                      }).catch((error) => console.error('error during iniziatialization --> ', error))
            }
        } catch (error) {
            console.error("Error in 'BroadcastCampaignForMosaico.js -> registerBeeEditor()':" + error);
        }
    }

	const handlePreview = (event,savetype) => {
		if (getObjectParam(campaign).editor_type === constants.EDITOR_BEE) {
			if (isValidParam(bee)) {
				let tempCampaign = campaign;
				tempCampaign.beeEditorAction = savetype;
				setCampaign(tempCampaign);
				bee.preview();
			}
		} else {
			window.open("/#previewbroadcasttemplate/" + campaign.id);
		}
	};

	const handleAction = (event,savetype) => {
		try {
		             	var button = document.getElementsByClassName('button_group');
								if (isValidParam(button) && button.length > 0 && savetype == 'save') {
									button[0]?.classList.add('button-save');
									button[0]?.classList.remove('button-test');

								}
								if (isValidParam(button) && button.length > 0 && savetype == 'test') {
									button[0]?.classList.add('button-test');
									button[0]?.classList.remove('button-save');

								}
			if (getObjectParam(campaign).editor_type === constants.EDITOR_BEE) {
				if (isValidParam(bee)) {
					let tempCampaign = campaign;
					tempCampaign.beeEditorAction = savetype;
					setCampaign(tempCampaign);
					bee.saveAsTemplate();
				}
			}
		} catch (error) {
			console.error(
				"Error in 'BroadcastCampaignForMosaico.js -> handleSave()':" + error
			);
		}
	};

	const openNoteTypeList = (event) => {
		event.preventDefault();
		setIsOpenNoteTypeList(true);
		setAnchorELNoteTypeList(event.currentTarget);
	};

	const getStepContent = (stepIndex) => {
		switch (stepIndex) {
			case 0:
				let campaignName = getStringParam(campaign.bd_name);
				let personalizeFieldList = renderPersonalizeField();

				let mainContentTopMargin = "-12px";
				if (result) {
					mainContentTopMargin = "30px";
				} else if (resultExecuteStart) {
					mainContentTopMargin = "80px";
				}

				return (
					<div style={{ paddingLeft: "11px", paddingRight: "26px" }}>
						{!isFullScreenEditor && (
							<div>
								<>
									<div>
										<div
											className="col-sm-12"
											style={{fontSize: "14px",textAlign: "left",color: "#717171",paddingLeft: "28px",paddingBottom: "5px",}}
										>
											<span>
												{new Intl.NumberFormat().format(email_credit_balance)}{" "}
												{
													getLocalizedStrings().message.BROADCAST_CAMPAIGNS
														.CREDITS_REMAINING
												}{" "}
											</span>
										</div>

										<div className="row" style={{}}>
											<div
												className="col-sm-12"
												style={{ ...stickyStyle, paddingBottom: "20px",marginLeft:'15px' }}
											>
												<div style={{ float: "right" }}>
													{campaign.t_status !== "Executed" && campaign.t_status !== "Queued" && campaign.t_status !== "Running" && (
															<Button
																style={styles.secondaryButton}
																onClick={() => handleChangeTemplate()}
																disabled={changeLog.disabled}
																labelstyle={{ fontWeight: "normal" }}
															>{getLocalizedStrings().label.CAMPAIGNS.CHANGE_TEMPLATE}
															</Button>
														)}
												</div>
												<div>
													{scheduled && campaign.t_status !== "Executed" && campaign.t_status !== "Halted" && campaign.t_status !== "Running" && (
															<span
																style={{
																	fontSize: "12px",
																	textAlign: "left",
																	color: "#228B22",
																	fontWeight: "bold",
																	paddingBttom: "5px",
																}}
															>
																{
																	getLocalizedStrings().label.CAMPAIGNS
																		.SCHEDULED_FOR
																}
																&nbsp;
																{moment(scheduled_on).format(
																	dateTimeFormat[app.me.date_format]
																)}
															</span>
														)}
													{campaign.t_status === "Executed" && (
														<span
															style={{
																fontSize: "12px",
																textAlign: "left",
																color: "#228B22",
																fontWeight: "bold",
																paddingBttom: "5px",
															}}
														>
															{
																getLocalizedStrings().label.CAMPAIGNS
																	.EXECUTED_ON
															}
															&nbsp;
															{moment(scheduled_on).format(
																dateTimeFormat[app.me.date_format]
															)}
														</span>
													)}

													{campaign.t_status === "Running" && (
														<span
															style={{
																fontSize: "12px",
																textAlign: "left",
																color: "#228B22",
																fontWeight: "bold",
																paddingBttom: "5px",
															}}
														>
															{getLocalizedStrings().label.CAMPAIGNS.EXECUTING}
														</span>
													)}
													{campaign.t_status === "Halted" && (
														<span
															style={{
																fontSize: "12px",
																textAlign: "left",
																color: "#228B22",
																fontWeight: "bold",
																paddingBttom: "5px",
															}}
														>
															{getLocalizedStrings().label.CAMPAIGNS.ABORTED}
														</span>
													)}
												</div>
											</div>
										</div>

										{campaign.id > 0 && (result || resultExecuteStart) && (
											<div style={{ ...styles.row }} className="bcresult">
												<div style={{ ...styles.sf_12, padding: "0px" }}>
													<Results
														campaignId={campaign.id}
														campaignName={campaignName}
														setMarketingResults={() => setMarketingResults()}
														subject={campaign.subject}
														resultContent={resultContent}
														mounted={result}
													/>
												</div>
											</div>
										)}
									</div>
								</>

								<div style={{ marginTop: 12 }}>
									<div
										className="row"
										style={{ marginTop: mainContentTopMargin,width:'101%' }}
									>
										<div className="col-sm-12">
											<FormControl
												style={{
													width: "100%",
													marginTop: "-8px",
													marginBottom: "16px",
												}}
												className="test"
												noValidate
												autoComplete="off"
											>
												<TextField
													variant="outlined"
													label={
														getLocalizedStrings().label.CAMPAIGNS.NAME_REQUIRED
													}
													size = 'small'
													fullWidth={true}
													name="bd_name"
													value={campaign.bd_name}
													onChange={(e) => handleChange(e)}
													error={error.bd_name !== ""}
													helperText={error.bd_name}
													disabled={changeLog.disabled}
													autoFocus={campaign.id === 0 ? true : false}
													inputprops={{ maxLength: 128 }}
													autoComplete="off"
													margin="dense"
													className={"sf-fields-bg"}
												/>
											</FormControl>
										</div>
									</div>
									<div style={{ display:'flex',marginLeft:15}}>
									<div style={{ ...styles.sf_3, width: "37%" }}>
											<FormControl variant="outlined" style={{ width: "100%" }}>
												<InputLabel
													id="sf-broadcastcampaignformosico-simple-select-outlined-label"
													className="sf-broadcastcampaignformosico"
													style={{ marginTop: "-5px" }}
												>
													{
														getLocalizedStrings().label.CAMPAIGNS
															.CAMPAIGN_TYPE_OPTIONAL
													}
												</InputLabel>
												<SelectField
													id="campaignType"
													value={campaign.campaign_type}
													onChange={(e) => changeCampaignType(e)}
													style={{ height: "44px" }}
													label={
														getLocalizedStrings().label.CAMPAIGNS
															.CAMPAIGN_TYPE_OPTIONAL
													}
													disabled={changeLog.disabled}
													className={"sf-fields-bg"}
												>
													<MenuItem
														value=""
														title=""
														style={styles.popoverMenuItem}
													></MenuItem>
													{campTypeMenuItems}
													<MenuItem
														value="Manage"
														title={getLocalizedStrings().label.CAMPAIGNS.MANAGE}
														style={styles.popoverMenuItem}
													>
														{"---" +
															getLocalizedStrings().label.CAMPAIGNS.MANAGE +
															"---"}
													</MenuItem>
												</SelectField>
											</FormControl>
										</div>

										<div style={{ width: "38%", marginLeft: "3px" }}>
											<div>
												<div
													style={{
														...styles.customInputHolder,
														height: "auto",
														marginLeft: "12px"
													}}
												>
													<label htmlFor="tophoneno" style={styles.inputLabel}>
														{getLocalizedStrings().label.AB_CAMPAIGNS.TARGET_BY}
													</label>
													<div>
														<div>
															<RadioGroup row className="sf-data-fieldset" name="layout" onChange={(event) => changeRadioButton(event.target.value)}  value={filter_type} style={{ marginTop: "-5px" }}>
																<FormControlLabel value="group" control={<Radio disabled={changeLog.disabled} color="default" />} label={getLocalizedStrings().label.CAMPAIGNS.GROUP} title={getLocalizedStrings().label.CAMPAIGNS.GROUP} />
																<FormControlLabel value="contact" control={<Radio disabled={changeLog.disabled} color="default" />} label={getObjectLabelByObject(constants.CONTACTS_OBJECT)} title={getObjectLabelByObject(constants.CONTACTS_OBJECT)} />
																<FormControlLabel value="account" control={<Radio disabled={changeLog.disabled} color="default" />} label={getObjectLabelByObject(constants.ACCOUNTS_OBJECT)} title={getObjectLabelByObject(constants.ACCOUNTS_OBJECT)} />
															</RadioGroup>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div style={{ marginLeft: "27px", width: "37%" ,marginRight:19,}}>
											<Autocomplete
												multiple={filter_type === "group"}
												style={{ height: "44px" }}
												id={"autocomplete_group"}
												name={"autocomplete_group"}
												key={"autocomplete_group"}
												className={"sf-atc-autoComplete"}
												value={changeLog?.selected_filter_options !== '' ? changeLog?.selected_filter_options : []}
												getOptionDisabled={(option) =>
													option.isDisabled === true
												}
												onChange={(event, options) =>
													handleSelectChange(event, options)
												}
												renderTags={(value, getTagProps) =>
													value.map((option, index) => (
														<Chip 
															style={{...styles.autocompleteChip, width: '26.5%'}}
															label={option?.label}
															title={option?.label}
															{...getTagProps({ index })}
														/>
													))
												}
												options={filter_options}
												disableClearable={true}
												renderInput={(params) => (
													<TextField
														{...params}
														placeholder={placeholder}
														variant="outlined"
														margin="dense"
														size = 'small'
														className={"sf-atc-autoComplete-text-field"}
													/>
												)}
											/>
											<span
												style={{
													fontSize: "12px",
													color: "#FF0000",
													height: "45px",
												}}
											>
												{error?.filter_options}
											</span>
										</div>
										<div >
											<i
												className="fa fa-eye"
												name={"qualifiedcontacts"}
												onClick={() => handleQualifiedContacts()}
												title={
													getLocalizedStrings().label.BROADCAST_CAMPAIGNS
														.QUALIFIED_CONTACTS
												}
												style={{
													marginTop: "3px",
													fontSize: "30px",
													color: "#a8a0a0",
													cursor: "pointer",
												}}
											></i>
										</div>
									</div>
									<div style={{ marginTop: "7px",marginLeft:'15px' }}>
										<div style={{ width: "68%" }}>
											<div style={{ display: "flex" }}>
												<div style={{ width: "48.7%" }}>
													<FormControl
														style={{ width: "100%" }}
														className="test"
														noValidate
														autoComplete="off"
													>
														<TextField
															variant="outlined"
															label={
																getLocalizedStrings().label.CAMPAIGNS
																	.FROM_NAME_REQUIRED
															}
															size = 'small'
															fullWidth={true}
															name="sender_name"
															value={campaign.sender_name}
															onChange={(e) => handleChange(e)}
															error={error.sender_name !== ""}
															helperText={error.sender_name}
															disabled={changeLog.disabled}
															autoComplete="off"
															margin="dense"
															className={"sf-fields-bg"}
														/>
													</FormControl>
												</div>
												<div style={{ ...styles.sf_6 }}>
													<div
														style={{
															float: "left",
															width: "97%",
															marginLeft: "17px",
														}}
													>
														<div
															style={{
																color: "#999999",
																position: "absolute",
																top: "2px",
																zIndex: "1",
																left: "25px",
																fontSize: "11px",
															}}
														></div>
														{verifid_email_ids.length > 0 && (<FormControl
															style={{ width: "100%" }}
															className="test"
															noValidate
															autoComplete="off"
														>
															
																<Autocomplete
																	name="sender_email"
																	value={verifid_email_ids.find(
																		(v) => v.value === campaign.sender_email
																	)}
																	options={verifid_email_ids}
																	onChange={(event, value) =>
																		handleSenderEmailChange(event, value)
																	}
																	getOptionLabel={(option) => option.label}
																	disableClearable={true}
																	disabled={
																		changeLog.disabled || disabledFrmEmail ? true : false
																	}
																	className="broadFromEmail"
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			variant="outlined"
																			label={
																				getLocalizedStrings().label.BROADCAST_CAMPAIGNS
																					.FROM_EMAIL_REQUIRED
																			}
																			margin="dense"
																			size='small'
																			className={"sf-fields-bg"}
																		/>
																	)}
																	style={{ marginBottom: "0px" }}
																/>
														</FormControl>
															)}
														<span
															style={{
																fontSize: "12px",
																color: "#f44336",
																marginBottom: "5px",
																display: "block",
															}}
														>
															{error.sender_email}
														</span>
													</div>
												</div>
												<div style={{ float: "right", marginLeft: "8px",marginTop:'8px' }}>
													<IconButton
														onClick={() => loadVerifiedEmailIds()}
														style={{ color: "#949494", fontSize: "16px" }}
														disabled={isDisableRefresh}

													>
														<Icon
															style={{
																fontSize: "25px",
																paddingLeft: "0px",
																color: "#666161",
															}}
															title={getLocalizedStrings().label.REPORT.REFRESH}
														>
															autorenew
														</Icon>
													</IconButton>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div style={{ ...styles.sf_8, marginTop: "10px" ,marginLeft:'15px',width:'65.8%'}}>
											<FormControl
												style={{ width: "150%" }}
												className="test"
												noValidate
												autoComplete="off"
											>
												<TextField
													variant="outlined"
													label={
														getLocalizedStrings().label.MARKETING_TEMPLATE
															.SUBJECT_REQUIRED
													}
													fullWidth={true}
													name="subject"
													size = 'small'
													value={campaign.subject}
													onChange={(e) => handleChange(e)}
													error={error.subject !== ""}
													helperText={error.subject}
													inputprops={{ maxlength: "255" }}
													autoComplete="new-password"
													margin="dense"
													className={"sf-fields-bg"}
												/>
												<div
													title={
														getLocalizedStrings().label.CAMPAIGNS.PERSONALIZE
													}
													style={{
														width: "10%",
														marginTop: error.subject !== "" ? '-5%' :'-3%',
														zIndex: 1,
														marginLeft: "93%",
													}}
												>
													<div
														style={{ marginTop: "4%" }}
														onClick={(e) => openNoteTypeList(e)}
													>
														<Icon
															style={{
																fontSize: "17px",
																color: "black",
																cursor: "pointer",
																marginTop: "1px",
																marginLeft: "45px",
															}}
														>
															settings
														</Icon>
														<Icon
															style={{
																fontSize: "17px",
																color: "black",
																cursor: "pointer",
																marginTop: "1px",
															}}
														>
															keyboard_arrow_down
														</Icon>
													</div>
													<Menu
														name="noteTypeList"
														key={"sf-auto-complete-noteTypeList"}
														id={"noteTypeList"}
														style={{ height: "50%" }}
														anchorEl={anchorELNoteTypeList}
														anchorOrigin={{
															horizontal: "left",
															vertical: "bottom",
														}}
														targetOrigin={{
															horizontal: "left",
															vertical: "top",
														}}
														open={isOpenNoteTypeList}
														onClose={() => setIsOpenNoteTypeList(false)}
													>
														{personalizeFieldList}
													</Menu>
												</div>
											</FormControl>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				);
			case 1:
				let iframeSrc = constants.nodeServerURL + "/editor.html";
				let status = campaign.t_status;
				return (
					<div>
						<div className="row" style={{}}>
							<div
								className="col-sm-12"
								style={{
									...stickyStyle,
									paddingBottom: "20px",
									marginTop: "29px",
									marginLeft:"10px",
								}}
							>
								<div style={{ float: "right" }}>
									{campaign.editor_type === constants.EDITOR_BEE && (
										<span className = 'button_group'
										>
											{status !== "Executed" &&
												status !== "Running" &&
												status !== "Queued" &&
												status !== "Halted" && (
													<Button
														style={styles.primaryButton}
														onClick={(e) => handleAction(e, "save")}
													>
														{" "}
														{getLocalizedStrings().label.COMMON.SAVE}
													</Button>
												)}
											{campaign.id > 0 && (
												<Button
													style={styles.secondaryButton}
													onClick={(e) => handleAction(e, "test")}
												>
													{getLocalizedStrings().label.CAMPAIGNS.TEST}
												</Button>
											)}
										</span>
									)}
									{status !== null &&
										status !== "Queued" &&
										status !== "Executed" &&
										status !== "Running" &&
										status !== "Halted" && (
											<Button
												onClick={() => handleSchedule()}
												style={styles.secondaryButton}
											>
												{getLocalizedStrings().label.CAMPAIGNS.SCHEDULE}
											</Button>
										)}
									{status !== null &&
										status !== "Queued" &&
										status !== "Executed" &&
										status !== "Running" &&
										status !== "Halted" && (
											<Button
												onClick={() => handleExecute()}
												style={styles.secondaryButton}
											>
												{" "}
												{getLocalizedStrings().label.CAMPAIGNS.EXECUTE_NOW}
											</Button>
										)}
									<Button
										onClick={() => handleCopy()}
										style={{
											...styles.secondaryButton,
											display: buttonVisible,
											paddingTop: "4px",
											lineHeight:'20px'
										}}
									>
										{getLocalizedStrings().label.COMMON.COPY}
									</Button>
									{status === "Queued" && (
										<Button
											style={styles.secondaryButton}
											onClick={() => handleCancelSchedule()}
										>
											{" "}
											{getLocalizedStrings().label.COMMON.CANCEL}
										</Button>
									)}

									{status !== "Executed" && status !== "Queued" && status !== "Running" && status !== "Halted" && (
											<Button
												style={styles.secondaryButton}
												onClick={() => handleChangeTemplate()}
											>
												{" "}
												{getLocalizedStrings().label.CAMPAIGNS.CHANGE_TEMPLATE}
											</Button>
										)}
									{campaign.id > 0 && (
										<Button
											style={styles.secondaryButton}
											onClick={(e) => handlePreview(e, "preview")}
										>
											{" "}
											{getLocalizedStrings().label.COMMON.PREVIEW}
										</Button>
									)}
								</div>
							</div>
						</div>
						

						{campaign.editor_type === constants.EDITOR_BEE &&
							getEditor()
							
						} 

						{campaign.editor_type === constants.EDITOR_NEW && (
							<MosaicoFrame
								postMessageData={campaign}
								iframeSrc={iframeSrc}
								resetTemplateData={() => resetTemplateData()}
							/>
						)}
					</div>
				);

			default:
				return "Soffront Campaign Module";
		}
	};
	
	const resetTemplateData = (templateInfo) => {
		let tempCampaign = campaign;
		if (campaign.id === 0) {
			tempCampaign.id = templateInfo.id;
			tempCampaign["t_status"] = "New";
		}
		tempCampaign["email_message"] = templateInfo.email_message;
		tempCampaign["metadata"] = templateInfo.metadata;
		tempCampaign["content"] = templateInfo.content;

		setCampaign(campaign);
		setButtonVisible("inline-block");
	};
	const getEditor = () => {
        let editor = null;
		let contentHeight = 250;
        if (campaign.editor_type !== null) {
            if (campaign.editor_type === constants.EDITOR_BEE) {
                {changeLog.token ? editor = <div id='bee-plugin-container' style={{ padding: '0px 10px', height: (window.innerHeight - 220) + 'px' }}></div> : 
                editor =  <div style={styles.loaderContainer}>
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            </div>}
            }
        }
        return editor
    }
	let themeColor = app.me.background;
	let calendarHeight = window.innerHeight - 200;
	let top = (calendarHeight - 10) / 2;
	if (redirect) {
		return <Navigate push to={redirectUrl} />;
	}

	if (!mounted || couponLoader) {
		return (
			<div style={{ width: "100%", height: calendarHeight }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "45%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 50,
							width: 50,
							padding: 7,
						}}
					>
						<ShowCircularProgress
							size={30}
							style={{ marginTop: "3", marginLeft: "3" }}
						/>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div>
			<div style={{ width: "30%", margin: "auto",marginTop:'24px' }}>
				<>
					<Stepper
						nonLinear
						activeStep={stepIndex}
						style={{ backgroundColor: "#f5f5f5" }}
					>
						<Step>
							<StepLabel
							StepIconComponent={ColoredStep}
								onClick={() => handlePrev()}
								style={{
									cursor: "pointer",
									fontSize:'11px',
									lineHeight:'2px',
								}}
							>
								{getLocalizedStrings().label.BROADCAST_CAMPAIGNS.BROADCAST_INFO}
							</StepLabel>
						</Step>
						<Step>
							<StepLabel
							 StepIconComponent={ColoredStep}
								onClick={() => handleNext()}
								style={{ cursor: "pointer", fontSize:'11px',lineHeight:'2px', }}
							>
								{getLocalizedStrings().label.BROADCAST_CAMPAIGNS.DESIGN}
							</StepLabel>
						</Step>
					</Stepper>
				</>
			</div>
			<div>
				<div
					style={{ padding: 20, paddingTop: 0, paddingBottom: 0, marginTop: 0 }}
				>
					{ getStepContent(stepIndex)}
				</div>
				{stepIndex === 0 && (
					<div
						style={{
							padding: "20 30 20 20",
							float: "right",
							marginRight: 10,
							marginTop: "12px",
						}}
					>
						<Button
							id="next"
							primary={true}
							onClick={() => handleNext()}
							style={styles.primaryButton}
						>
							{" "}
							{getLocalizedStrings().label.FILTER.NEXT_BUTTON_TEXT}
						</Button>
						<Button
							id="cancel"
							primary={false}
							onClick={() => {
								handleClose();
							}}
							style={styles.secondaryButton}
						>
							{getLocalizedStrings().label.COMMON.CANCEL}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

const Results = ({ resultContent, campaignName,campaignId,setMarketingResults,subject,mounted }) => {
	const dispatch = useDispatch();
	
	

	const getPercentage = (value) => {
		let percentage_value = "";
		let sent_value = resultContent.marketing_results[0].sent;
		if (sent_value !== 0) {
			percentage_value = (value / sent_value) * 100;
			if (Math.round(percentage_value) !== percentage_value) {
				percentage_value = percentage_value.toFixed(2);
			}
			return percentage_value + "%";
		}
	};

	const drilldownMarketingReport = (statsId, eventType, reportType) => {
		try {
			let params = {
				name: "All records",
				tableId: 9,
			};
			var promise = Promise.resolve(
				HTTPClient.get(endPoints.REPORTS.QUERY_DETAIL, params)
			);
			promise.then((response) => {
				if (isValidParam(response)) {
					let label = getStringParam(campaignName);
					if (eventType === "click") {
						let resultObject = resultContent.marketing_results[0];
						let reportName = resultObject["subject"];
						let eventTypeText = getLocalizedStrings().label.CAMPAIGNS.CLICK_URL;
						label = eventTypeText + " " + reportName;
					} else {
						let eventTypeText =
							eventType.charAt(0).toUpperCase() + eventType.slice(1);
						if (reportType === constants.BROADCAST_REPORT_TYPE) {
							let eventTypeTextUpper = eventTypeText.toUpperCase();
							eventTypeText =
								getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(
									eventTypeTextUpper
								)
									? getLocalizedStrings().label.CAMPAIGNS[eventTypeTextUpper]
									: eventTypeText;
							label =
								eventTypeText +
								" " +
								getLocalizedStrings().label.BROADCAST_CAMPAIGNS
									.EMAIL_CAMPAIGN_REPORT;
						} else if (reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
							label =
								eventTypeText +
								" " +
								getLocalizedStrings().label.AUTOMATION_DESIGNER
									.TOUCH_CAMPAIGN_REPORT;
						} else if (reportType === constants.AB_REPORT_TYPE) {
							label =
								eventTypeText +
								" " +
								getLocalizedStrings().label.AB_CAMPAIGNS.AB_CAMPAIGN_REPORT;
						}
					}
					let url = "/" + constants.CONTACTS_OBJECT + "/" + constants.BROADCAST_REPORT_TYPE.toLocaleLowerCase();
						openTab(label,constants.CONTACTS_OBJECT,response.query_id,"fa fa-bullhorn",url,statsId,eventType);
					
				}
			});
		} catch (error) {
			console.log(
				"Error in 'BroadcastCampaign.js -> drilldownMarketingReport()':" + error
			);
		}
	};

	const openTab = (label,component,queryId,imgName,url,statsId,eventType) => {
		let filter = {
			id: queryId,
			name: "",
			type: constants.TYPE_AUTORESPONDER_REPORT,
		};
		let tabType = TYPE_LIST_VIEW;
		let arrMarketingResults = resultContent.marketing_results;
		arrMarketingResults = arrMarketingResults.filter(function (el) {
			return el.id === statsId;
		});
		arrMarketingResults = arrMarketingResults[0];
		let tab = {
			label: label,
			object: component,
			type: tabType,
			imgName: imgName,
			info: {
				filter: filter,
				input_param: {
					stat_id: statsId,
					event_type: eventType,
					reportCallFrom:'broadReport'
				},
				isReport: true,
				reportType: constants.BROADCAST_REPORT_TYPE,
				event_type_key: EVENT_TYPE_KEYS[eventType],
				campaignId: campaignId,
				reportFieldValue: getStringParam(eventType),
				reportLabel: getStringParam(campaignName),
				recordCount: arrMarketingResults[EVENT_TYPE_KEYS[eventType]],
			},
			url: url,
			isActive: true,
		};
		dispatch(setMarketingResults(resultContent.marketing_results));
		addTab(tab, true);
		dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
	};

	

	let calendarHeight = window.innerHeight - 250;
	let top = (calendarHeight - 10) / 2;
	if (!mounted) {
		return (
			<div style={{ width: "100%", height: calendarHeight }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "45%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 50,
							width: 50,
							padding: 7,
						}}
					>
						<ShowCircularProgress
							size={30}
							style={{ marginTop: "3", marginLeft: "3" }}
						/>
					</div>
				</div>
			</div>
		);
	} else if (typeof resultContent === "object") {
		let resultObject = resultContent.marketing_results[0];
		let id = resultObject.id;
		let sent = 0;
		let opened = 0;
		let unopened = 0;
		let clicked = 0;
		let bounced = 0;
		let unsubscribed = 0;
		let spam = 0;
		let dropped = 0;

		if (resultObject.hasOwnProperty("sent")) {
			sent = resultObject.sent;
		}
		if (resultObject.hasOwnProperty("opened")) {
			opened = resultObject.opened;
		}
		if (resultObject.hasOwnProperty("unopened")) {
			unopened = resultObject.unopened;
		}
		if (resultObject.hasOwnProperty("clicked")) {
			clicked = resultObject.clicked;
		}
		if (resultObject.hasOwnProperty("bounced")) {
			bounced = resultObject.bounced;
		}
		if (resultObject.hasOwnProperty("unsubscribed")) {
			unsubscribed = resultObject.unsubscribed;
		}
		if (resultObject.hasOwnProperty("spam")) {
			spam = resultObject.spam;
		}
		if (resultObject.hasOwnProperty("dropped")) {
			dropped = resultObject.dropped;
		}

		return (
			<div
				style={{width: "100%",paddingBottom: "20px",backgroundColor: "#919191",paddingTop: "20px",marginBottom: "23px",paddingLeft: "30px",minHeight: "120px",		}}
			>
				<div className="report_box_area">
					<div className="report_box" style={{
								height:'62px'
							}} >
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.SENT}
						>
							{getLocalizedStrings().label.CAMPAIGNS.SENT}
						</div>
						{sent !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>drilldownMarketingReport(id,"Sent",constants.BROADCAST_REPORT_TYPE)}
								>
									{sent}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{sent}</span>
						)}

						<br />
						{sent !== 0 && <span>&nbsp;</span>}
					</div>
					<div className="report_gap"></div>
					<div className="report_box">
						<div
							style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.OPENED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.OPENED}
						</div>
						{opened !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>drilldownMarketingReport(id,"Open",constants.BROADCAST_REPORT_TYPE)}
								>
									{opened}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{opened}</span>
						)}
						<br />
						{() => getPercentage(opened)}
					</div>
					<div className="report_gap"></div>
					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.UNOPENED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.UNOPENED}
						</div>
						{unopened !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>drilldownMarketingReport(id,"Unopened",constants.BROADCAST_REPORT_TYPE)
									}
								>
									{unopened}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{unopened}</span>
						)}
						<br />
						{() => getPercentage(unopened)}
					</div>
					<div className="report_gap"></div>

					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.CLICKED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.CLICKED}
						</div>
						{clicked !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											id,
											"click",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{clicked}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{clicked}</span>
						)}
						<br />
						{() => getPercentage(clicked)}
					</div>
					<div className="report_gap"></div>

					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.BOUNCED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.BOUNCED}
						</div>
						{bounced !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											id,
											"Bounce",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{bounced}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{bounced}</span>
						)}
						<br />
						{() => getPercentage(bounced)}
					</div>
					<div className="report_gap"></div>

					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.UNSUBSCRIBED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.UNSUBSCRIBED}
						</div>
						{unsubscribed !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											id,
											"Unsubscribed",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{unsubscribed}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>
								{unsubscribed}
							</span>
						)}
						<br />
						{() => getPercentage(unsubscribed)}
					</div>
					<div className="report_gap"></div>

					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.SPAM}
						>
							{getLocalizedStrings().label.CAMPAIGNS.SPAM}
						</div>
						{spam !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											id,
											"spamreport",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{spam}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{spam}</span>
						)}
						<br />
						{() => getPercentage(spam)}
					</div>
					<div className="report_gap"></div>
					<div className="report_box">
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							title={getLocalizedStrings().label.CAMPAIGNS.DROPPED}
						>
							{getLocalizedStrings().label.CAMPAIGNS.DROPPED}
						</div>
						{dropped !== 0 ? (
							<Link to={"#"}>
								<span
									style={{ fontSize: 18, color: "#F04B24" }}
									onClick={() =>
										drilldownMarketingReport(
											id,
											"dropped",
											constants.BROADCAST_REPORT_TYPE
										)
									}
								>
									{dropped}
								</span>
							</Link>
						) : (
							<span style={{ fontSize: 18, color: "#F04B24" }}>{dropped}</span>
						)}
						<br />
						{() => getPercentage(dropped)}
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

const MosaicoFrame = ({ postMessageData, iframeSrc, resetTemplateData }) => {
	const attributes = {
		src: iframeSrc + "?background=" + localStorage.getItem("background"),
		width: "100%",
		height: window.innerHeight - 220 + "px",
		frameBorder: 0,
		id: "editorFrame",
	};

	const onReceiveMessage = (e) => {
		let receiveData = e.data;
		if (receiveData.callbackAction === "saved_success") {
			refreshListView(constants.BROADCAST_CAMPAIGNS_OBJECT);
			resetTemplateData(receiveData.templateInfo);
		} else if (receiveData.callbackAction === "upload_file") {
			const formData = new FormData();
			formData.append("file", receiveData.file);
			let url = endPoints.DOCUMENT_LIBRARY.UPLOAD;
			return HTTPClient.postFormData(url, formData);
		}
	};

	const onReady = () => {};

	return (
		<IframeComm
			attributes={attributes}
			postMessageData={postMessageData}
			handleReady={() => onReady()}
			handleReceiveMessage={(e) => onReceiveMessage(e)}
			targetOrigin={constants.nodeServerURL}
		/>
	);
};

export default BroadcastCampaignForMosico;
