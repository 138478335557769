import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from "react";
import { setAppDialogActions, getAppDialog ,setAppDialogCallBackActions,reSetAppDialogCallBackActions} from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { Link } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import { constants } from '../../../services/constants/constants';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Card, CardMedia, CardActions, CardActionArea, Button, Box, Divider, Icon, TextField, FormControl, Chip } from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import AssetsHome from '../components/assetsHome';
import { updateInBodyAction } from '../../../services/actions/documentLibraryActions';
import * as sfDialogs from '../components/sfDialogs';



const crdStyle = {
    cardWrapper: {
        display: 'inline-block',
        position: 'relative',
        cursor: ' pointer',
        width: '100%',
        // height: '100%'
    }
    ,
    layer: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        display: 'none',
        zIndex: '1100'
    }
}


const iconStyles = {
    marginLeft: 5,
};

const inlinestyle = {
    button: {
        marginRight: 20,
    },
    exampleImageInput: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        opacity: 0,
    }
};
function getDocumentImage(ext) {
    let imgName = '';
    if (ext === "pdf") {
        imgName = "pdf";
    } else if (ext === "jpg" || ext === "jpeg" || ext === "gif" || ext === "png") {
        imgName = "img";
    } else if (ext === "doc" || ext === "rtf" || ext === "docx") {
        imgName = "doc";
    } else if (ext === "xls" || ext === "csv" || ext === "xlsx") {
        imgName = "xls";
    } else if (ext === "ppt" || ext === "pptx") {
        imgName = "ppt";
    } else if (ext === "zip") {
        imgName = "zip";
    } else if (ext === "rar") {
        imgName = "rar";
    } else if (ext === "txt") {
        imgName = "txt";
    } else if (ext === "java" || ext === "jar") {
        imgName = "java";
    } else if (ext === "html" || ext === "htm") {
        imgName = "html";
    } else if (ext === "js") {
        imgName = "js";
    } else if (ext === "css") {
        imgName = "css";
    } else if (ext === "swf") {
        imgName = "flash";
    } else if (ext === "sql") {
        imgName = "db";
    } else if (ext === "mp4" || ext === "mpg" || ext === "mpeg" || ext === "mov") {
        imgName = "film";
    } else if (ext === "m4p" || ext === "mp3" || ext === "ogg" || ext === "mov") {
        imgName = "music";
    } else if (ext === "jsp" || ext === "php" || ext === "c" || ext === "cpp") {
        imgName = "code";
    } else {
        imgName = "file";
    }
    return '/asset/images/externalPage/largeIcons/' + imgName + '.png';
}

const DocumentLibraryDialog = ({ data, callFromData }) => {

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const documentLibrary = useSelector((state) => state.documentLibrary);

    const dispatch = useDispatch();

    const [selectedLibrary, setSelectedLibrary] = useState(appContainer.dialogProps.data.type === 'link' ? 'documents' : 'images');
    const [documentList, setDocumentList] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [addDocument, setAddDocument] = useState(false);
    const [file, setFile] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [category, setcategory] = useState('');
    const [categories, setcategories] = useState([]);
    const [error, setError] = useState({ upload: '' });
    const [files, setFiles] = useState([]);
    const handleClearCallBack = (from) => {
        dispatch(updateInBodyAction(null, null, null))
        dispatch(reSetAppDialogCallBackActions());
    };
    useEffect(() => {
        dispatch(setAppDialogCallBackActions({onClose: () => {handleClearCallBack()}}));
    }, [])
    const handleClose = () => {
        if (addDocument && selectedLibrary === 'images') {
            handleDocumentChange('images');
        } else if (addDocument && selectedLibrary === 'documents') {
            handleDocumentChange('documents');
        } else {
            dispatch(getAppDialog(false, null, null, null, null, null));
        }
    }

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
        getDocumentList(selectedLibrary);
    }, [addDocument, selectedLibrary, files]);

    const setSelectedCategoryData = (category) => {
        setSelectedCategory(category);

    }

    const fileOpen = (e) => {
        let _files = files;
        let totalFileCount = e.target.files.length + _files.length;
        if (e.target.files.length > 5 || totalFileCount > 5) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_MAX_ATTACHMENTS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            e.target.value = null;
            return false;
        } else if (totalFileCount <= 5) {
            let filesArray = [];
            let filesName = null;
            for (let i = 0; i < e.target.files.length; i++) {
                var _file = e.target.files[i];
                let fileName = _file.name;
                fileName = fileName.replace(/'/g, "");
                let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
                if (selectedLibrary === constants.IMAGES_LIBRARY) {
                    if (fileext.toLowerCase() === 'jpg' || fileext.toLowerCase() === 'jpeg' || fileext.toLowerCase() === 'gif' || fileext.toLowerCase() === 'png') {
                        let isDuplicate = false;
                        let uploadFileName = _file.name;
                        for (let j = 0; j < documentList.length; j++) {
                            let docName = documentList[j].name;
                            if (docName === uploadFileName) {
                                isDuplicate = true;
                            }
                        }

                        for (let a = 0; a < _files.length; a++) {
                            let docName = _files[a].fileName;
                            if (docName === uploadFileName) {
                                isDuplicate = true;
                            }
                        }

                        if (isDuplicate) {
                            if (filesName === null) {
                                filesName = uploadFileName;
                            } else {
                                filesName += ", " + uploadFileName;
                            }
                        } else {
                            let fileDetails = {};
                            fileDetails.fileName = _file.name;
                            fileDetails.file = e.target.files[i];
                            filesArray.push(fileDetails);
                        }
                    } else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_UPLOAD_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        return false;
                    }
                } else {
                    if (fileext.toLowerCase() === 'exe' || fileext.toLowerCase() === 'bat' || fileext.toLowerCase() === 'sh') {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_DOCUMENT_UPLOAD_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        return false;
                    } else {
                        let isDuplicate = false;
                        let uploadFileName = _file.name;
                        for (let j = 0; j < documentList.length; j++) {
                            let docName = documentList[j].name;
                            if (docName === uploadFileName) {
                                isDuplicate = true;
                            }
                        }
                        for (let a = 0; a < _files.length; a++) {
                            let docName = _files[a].fileName;
                            if (docName === uploadFileName) {
                                isDuplicate = true;
                            }
                        }
                        if (isDuplicate) {
                            if (filesName === null) {
                                filesName = uploadFileName;
                            } else {
                                filesName += ", " + uploadFileName;
                            }
                        } else {
                            let fileDetails = {};
                            fileDetails.fileName = _file.name;
                            fileDetails.file = e.target.files[i];
                            filesArray.push(fileDetails);
                        }
                    }
                }
            }
            _files = [..._files, ...filesArray];
            setInProgress(false);
            if (filesName !== null) {
                let errorMsg = '';
                if (appContainer.dialogProps.data.selectedLibrary === constants.IMAGES_LIBRARY) {
                    errorMsg = filesName + " " + getLocalizedStrings().message.DOCUMENT_LIBRARY.DUPLICATE_IMAGE_NAME;
                } else {
                    errorMsg = filesName + " " + getLocalizedStrings().message.DOCUMENT_LIBRARY.DUPLICATE_DOCUMENT_NAME;
                }
                dispatch(showCustomSnackBar(errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            e.target.value = null;
            setFiles(_files);
        }
    }

    const uploadImage = () => {
        let _files = files;
        let tempDocumentList = documentList;
        if (_files.length === 0 || _files === null) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_UPLOAD_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else {
            for (let i = 0; i < _files.length; i++) {
                let isDuplicate = false;
                let uploadFileName = _files[i].fileName;
                for (let j = 0; j < tempDocumentList.length; j++) {
                    let docName = tempDocumentList[j].name;
                    if (docName === uploadFileName) {
                        isDuplicate = true;
                    }
                }
                if (isDuplicate) {
                    _files = [..._files];
                    setFiles([..._files]);
                    setDocumentList(tempDocumentList);
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.DUPLICATE_IMAGE_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else if (_files[i].file.size >= 1024) {
                    let kb = (_files[i].file.size / 1024).toFixed(2);
                    if (kb > 500) {
                        _files = [..._files];
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.OVER_MAX_SIZE_IMAGE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        return false;
                    }
                }
            }
        }
        setInProgress(true);
        setAddDocument(true);

        let promise = Promise.resolve(fileUpload(_files));
        promise.then((response) => {
            if (response.data.status === 0) {
                _files = [];
                setMounted(false);
                setAddDocument(false);
                setInProgress(false);
                getDocumentList(selectedLibrary);
                dispatch(setAppDialogActions(getActions()));
                setFiles([..._files]);
                let uploadMsg = getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD_IMAGE;
                dispatch(showCustomSnackBar(uploadMsg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            } else if (response.data.status === -1 && response.data.error.message !== "") {
                dispatch(showCustomSnackBar(response.data.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                setInProgress(false);
                setFiles([..._files]);
            }

        });
    }

    const uploadDocument = () => {
        let _file = file;
        let _error = error;
        if (_file === null || _error.upload !== '') {
            _error.upload = getLocalizedStrings().message.DOCUMENT_LIBRARY.DOCUMENT_FILE_EMPTY_ERROR;
            return false;
        }

        setInProgress(true);
        setAddDocument(true);
        let promise = Promise.resolve(documentUpload(_file));

        promise.then((response) => {
            if (response.data.status === 0) {

                setMounted(false);
                setAddDocument(false);
                setInProgress(false);
                getDocumentList(selectedLibrary);
                setFile(null);
                dispatch(setAppDialogActions(getActions()));
            }
            else if (response.data.status === -1 && response.data.error.message !== "") {
                _error.upload = response.data.error.message;
                setInProgress(false);
                setError(_error);
                setFile(_file);

            }

        });
    }

    const handleUpload = () => {
        if (selectedLibrary === 'images') {
            uploadImage();
        } else if (selectedLibrary === 'documents') {
            uploadDocument();
        }

    }

    const documentUpload = (_file) => {
        const formData = new FormData();
        formData.append('file', _file);
        formData.append('input_param', JSON.stringify({ documentId: 0 }));
        let url = endPoints.DOCUMENT_LIBRARY.UPLOAD_DOCUMENT;
        return HTTPClient.postFormData(url, formData);
    }

    const fileUpload = (_files) => {
        const formData = new FormData();
        for (let i = 0; i < _files.length; i++) {
            formData.append('file', _files[i].file);
        }
        formData.append('input_param', JSON.stringify({
            documentId: 0,
            parentId: appContainer.dialogProps.data.parentId,
            childId: appContainer.dialogProps.data.childId,
        }));
        let url = endPoints.DOCUMENT_LIBRARY.UPLOAD;
        return HTTPClient.postFormData(url, formData);
    }

    const handleAdd = () => {
        setAddDocument(true);
        dispatch(setAppDialogActions(getActions()));
    }

    const handleCategoryChange = (category) => {
        loadStockLibrary(category);
    }

    const loadStockLibrary = (category) => {
        setMounted(false);
        let params = { type: 222, 'category': category }
        var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.LIST, params));

        promise.then((response) => {
            if (Array.isArray(response)) {
                let tempDocumentList = documentList;
                tempDocumentList = response;
                setDocumentList(tempDocumentList);
                setMounted(true);

            } else {

            }
        });
    }


    const getActions = () => {
        let actions = null;
        if (addDocument) {
            actions = [
                <Button
                    primary={true}
                    onClick={() => handleUpload()}
                    style={styles.primaryButton}
                >{getLocalizedStrings().label.ATTACHMENT.UPLOAD}</Button>,
                <Button
                    primary={true}
                    onClick={() => handleClose()}
                    style={styles.secondaryButton}
                >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,
            ]

        } else {
            actions = [

                <Button
                    primary={true}
                    onClick={() => handleClose()}
                    style={styles.secondaryButton}
                >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

            ];
        }
        return actions;
    }

    const getDocumentList = (selectedLibrary) => {
        let params = null;
        setMounted(false);

        if (selectedLibrary === 'images') {
            params = { type: 111 }
            let promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.LIST, params));
            promise.then((response) => {
                if (Array.isArray(response)) {
                    setMounted(true);
                    setDocumentList(response);

                } else {

                }
            });
        } else if (selectedLibrary === 'stock_images') {
            let promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.CATEGORY_LIST, null));
            let categories = [];
            promise.then((response) => {
                if (Array.isArray(response)) {
                    response.forEach(function (record) {
                        categories.push({
                            label: record.category,
                            value: record.category
                        });
                    });
                }

                let tempCategory = category;
                let tempSelectedCategory = selectedCategory;
                if (categories.length > 0) {
                    tempCategory = categories[0];
                    tempSelectedCategory = categories[0].value;

                }
                setcategory(tempCategory);
                setSelectedCategory(tempSelectedCategory)
                loadStockLibrary(tempSelectedCategory);
            });

        } else if (selectedLibrary === 'documents') {

            params = {
                fields: ["document_name", "t_type", "creator"],
                "search_field_name": "document_type", "search_text": "through_library"
            }

            var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.DOCUMENT_LIST, params));
            promise.then((response) => {
                if (Array.isArray(response.records)) {
                    setMounted(true);
                    setDocumentList(response.records);


                } else {

                }
            });
        }
    }

    const setLoading = (mountedObject) => {
        setMounted([...mountedObject]);
    }

    const generateDocumentList = () => {
        let docClass = 'col-sm-2 ' + selectedLibrary;
        return documentList.map((documentObj, index) => {
            return (
                <div className={docClass} key={index} style={{ marginBottom: '30px', borderRadius: 10, display: 'flex', justifyContent: 'space-evenly' }}>
                    <Document
                        style={{ borderRadius: 10 }}
                        insertDocument={(src, documentName) => insertDocument(src, documentName)}
                        deleteDocument={(id, key) => deleteDocument(id, key)}
                        documentObj={documentObj}
                        selectedLibrary={selectedLibrary}
                        setLoading={(mountedObject) => setLoading(mountedObject)}
                        showCustomSnackBar={dispatch(showCustomSnackBar)}
                        app={app}
                    />
                </div>
            )
        });
    }

    const handleDocumentChange = (value) => {
        let tempError = error;
        tempError.upload = '';
        setSelectedLibrary(value);
        setDocumentList([]);
        setMounted(false);
        setAddDocument(false);
        setError(tempError);
        getDocumentList(value);
        dispatch(setAppDialogActions(getActions()));
    }

    const generatMenuLists = () => {
        let library_types = [
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.IMAGES,
                value: 'images'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.STOCK_IMAGES,
                value: 'stock_images'
            },
        ];
        if (isValidParam(appContainer.dialogProps.data) && appContainer.dialogProps.data.type === 'link') {
            library_types.unshift({
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.DOCUMENTS,
                value: 'documents'
            });
        }
        if (callFromData != null && callFromData != undefined && callFromData === constants.SEND_SMS) {
            library_types = library_types.filter(f => f.value === 'documents');
        }
        if (appContainer.dialogProps.callFrom == "agreement") {
            library_types = library_types.filter(f => f.value === 'documents');
        }
        let tempLink = []
        library_types.map((obj, index) => {
            let color = obj.value === selectedLibrary ? 'red' : '#717171';
            tempLink.push(<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} key={obj.value + index}>
                <Link
                    to={'#'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleDocumentChange(obj.value)}>
                    <span style={{ fontSize: '14px', lineHeight: '32px', color: color, padding: '10px' }}>{obj.label}</span>
                </Link>
            </div>);
        });
        return tempLink;
    }

    const insertDocument = (src, documentName) => {
        let obj = {
            src: src,
            editor: appContainer.dialogProps.data.editor,
            type: appContainer.dialogProps.data.type,
            name: documentName
        }
        appContainer.dialogProps.eventName(obj);
    }

    const deleteDocument = (id, key) => {
        let params = {
            id: id,
            key: key,
        }
        var promise = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.DELETE, params));
        promise.then((response) => {
            if (response.status === 0 && response.data.is_deleted) {
                setMounted(false);
                getDocumentList(selectedLibrary);
            }
            else if (response.status === -1 && response.error.message !== "") {
                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }

        });
    }

    const search = (search_text, search_by) => {
        var matcher = new RegExp(search_text, "gi");
        for (var i = 0; i < document.getElementsByClassName("documents").length; i++) {
            if (search_by === 'All') {
                if (
                    matcher.test(document.getElementsByClassName("doc-name")[i].innerHTML) ||
                    matcher.test(document.getElementsByClassName("doc-owner")[i].innerHTML) ||
                    matcher.test(document.getElementsByClassName("doc-ext")[i].innerHTML)
                ) {
                    document.getElementsByClassName("documents")[i].style.display = "inline-block";
                } else {
                    document.getElementsByClassName("documents")[i].style.display = "none";
                }
            } else if (search_by === 'Name') {
                if (matcher.test(document.getElementsByClassName("doc-name")[i].innerHTML)) {
                    document.getElementsByClassName("documents")[i].style.display = "inline-block";
                } else {
                    document.getElementsByClassName("documents")[i].style.display = "none";
                }
            } else if (search_by === 'Type') {
                if (matcher.test(document.getElementsByClassName("doc-ext")[i].innerHTML)) {
                    document.getElementsByClassName("documents")[i].style.display = "inline-block";
                } else {
                    document.getElementsByClassName("documents")[i].style.display = "none";
                }
            } else if (search_by === 'Owner') {
                if (matcher.test(document.getElementsByClassName("doc-owner")[i].innerHTML)) {
                    document.getElementsByClassName("documents")[i].style.display = "inline-block";
                } else {
                    document.getElementsByClassName("documents")[i].style.display = "none";
                }
            }
        }
    }

    const handleFileRemove = (indexValue) => {
        let newFiles = [];
        let _files = files;
        for (let i = 0; i < _files.length; i++) {
            if (i !== indexValue) {
                newFiles.push(_files[i]);
            }
        }
        if (newFiles.length === 0) {
            _files = [];
        } else {
            _files = newFiles;
        }
        setFiles(_files);
    }

    const getFilesForRender = () => {
        let ul = null;
        let li = files.map((f, index) => {
            return <li key={f.fileName + index}><div key={'div' + index} style={{ width: '100%' }}><span key={'span' + index} style={{
                maxWidth: '85%', float: 'left', textAlign: 'left', overflow: 'hidden',
                textOverflow: 'ellipsis', whiteSpace: 'nowrap'
            }} title={f.fileName}> {f.fileName} </span> <span onClick={(e) => { e.preventDefault(); handleFileRemove(index); }}><Icon key={f.fileName + index} style={iconStyles}>close</Icon> </span></div></li>
        });
        if (li !== null && li.length > 0) {
            ul = <ul style={{ paddingLeft: '22px' }}>{li}</ul>;
        }
        return ul;
    }

    let documentListHtml = generateDocumentList();
    let menuList = generatMenuLists();
    let height = window.innerHeight - 250;
    let callFrom = 'editor';
    if (callFrom !== null && callFrom !== undefined) {
        callFrom = callFrom;
    }
    let fileObj = null;
    if (isValidParam(data) && data.hasOwnProperty('files')) {
        fileObj = data.files;
    }
    return (
        <   >
            <div style={{ backgroundColor: '#fff' }}>
                <div style={{ width: '100%', padding: '5px', borderBottom: '0.75px solid #aeaeae80', borderTop: '0.75px solid #aeaeae80' }}>
                    <span style={{ display: 'flex' }}> {menuList} </span>
                </div>

                <div className="dialog-clearfix" style={{ height: height, overflowY: 'auto', paddingTop: '10px' }}>
                    {(selectedLibrary === 'documents') &&
                        <AssetsHome callFrom={callFrom} files={fileObj} styleConfig={{ height: height, padding: false }} />
                    }
                    {selectedLibrary !== 'documents' &&
                        <div className="col-sm-12" style={{ border: '1px solid #d9d9d9', overflow: 'hidden', paddingRight: 0, marginRight: 0 }}>
                            <div className="row" style={{ padding: '8px 10px', borderBottom: '1px solid #d9d9d9', marginLeft: -41 }}>
                                <HeaderSection
                                    selectedLibrary={selectedLibrary}
                                    handleAdd={() => handleAdd()}
                                    handleCategoryChange={(category) => handleCategoryChange(category)}
                                    setSelectedCategory={(category) => setSelectedCategoryData(category)}
                                    category={selectedCategory}
                                    categories={categories}
                                    addDocument={addDocument}
                                    search={(search_text, search_by) => search(search_text, search_by)}
                                />
                            </div>
                            <div style={{ padding: '0px', paddingRight: 0, marginRight: '0px', paddingTop: 0, paddingLeft: 0, marginLeft: 0 }}>
                                <div className="dialog-clearfix" style={{ marginTop: '0px', height: 'auto', maxHeight: '280px', overflowY: 'auto', minHeight: '280px', paddingLeft: 0, marginLeft: -15 }}>
                                    <RightSection
                                        inProgress={inProgress}
                                        error={error}
                                        fileOpen={(e) => fileOpen(e)}
                                        addDocument={addDocument}
                                        mounted={mounted}
                                        documentListHtml={documentListHtml}
                                        selectedLibrary={selectedLibrary}
                                        callFrom={callFrom}
                                        getFilesForRender={() => getFilesForRender()}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </  >
    );
}

const RightSection = ({ fileOpen, documentListHtml, getFilesForRender, mounted, addDocument, selectedLibrary, error, inProgress }) => {

    useEffect(() => {

    }, [addDocument])

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2
    if (!mounted) {

        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        )
    } else if (addDocument && selectedLibrary === 'images') {
        return (
            <div className="dialog-clearfix" style={{ padding: '0px 20px', marginLeft: '0px', marginTop: '20px' }}>
                <div>
                    <label>{getLocalizedStrings().message.DOCUMENT_LIBRARY.UPLOAD_IMAGE}</label>
                    <div>
                        <div style={{ ...styles.sf_12, border: '1px solid #ccc' }}>
                            <div id="attachmentUpload">
                                <div style={{ ...styles.row, padding: '0px' }}>
                                    <div style={{ ...styles.sf_3, padding: 15, backgroundColor: '#fff', width: '210px' }}>
                                        <h3>{getLocalizedStrings().label.ATTACHMENT.MY_COMPUTER}</h3>
                                        <Button
                                            variant="contained"
                                            backgroundColor={'gray'}
                                            labelstyle={{ color: 'white' }}
                                            style={{ ...styles.button, width: '180px' }}
                                            endIcon={<Icon style={iconStyles} color={'primary'}>computer</Icon>}
                                        >
                                            {getLocalizedStrings().label.ATTACHMENT.BROWSE}
                                            <input type="file" style={inlinestyle.exampleImageInput} onChange={(e) => fileOpen(e)} multiple />
                                        </Button>

                                    </div>
                                    <div style={{width: '100%', overflow: 'auto', verticalAlign: 'middle', backgroundColor: '#efefef' }}>
                                        {getFilesForRender()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>&nbsp;</div>
                    {error.upload !== '' &&
                        <div style={{ color: '#FF0000', fontSize: '14px' }}>{error.upload}</div>
                    }

                    {inProgress &&
                        <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{getLocalizedStrings().label.ATTACHMENT.UPLOADING}...</div>
                    }
                </div>
            </div>
        )
    } else if (addDocument && selectedLibrary === 'documents') {
        return (
            <div className="dialog-clearfix" style={{ paddingLeft: '20px' }}>
                <label>{getLocalizedStrings().label.DOCUMENT_LIBRARY.UPLOAD_DOCUMENT}</label>
                <input type="file" name="img_name" id="img_to_upload" onChange={(e) => fileOpen(e)} />

                <div>&nbsp;</div>
                {error.upload !== '' &&
                    <div style={{ color: '#FF0000', fontSize: '14px' }}>{error.upload}</div>
                }

                {inProgress &&
                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{getLocalizedStrings().label.ATTACHMENT.UPLOADING}...</div>
                }
            </div>
        )
    }

    else {
        return (
            <div class="container">
                <div class="row">
                    {documentListHtml}
                </div>
            </div>
        )
    }
}

const Document = ({ documentObj, callFrom, value, setLoading, app, selectedLibrary, dispatch, insertDocument, style, deleteDocument }) => {

    const inputRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [shadow, setShadow] = useState(1);
    const [display, setDisplay] = useState('none');
    const [edit, setEdit] = useState(false);
    const [edited_name, setEdited_name] = useState('');
    const [ext, setExt] = useState('');
    const cardRef = useRef(null);
    const appContainer = useSelector((state) => state.appContainer);

    const onMouseOver = () => {
        if (cardRef.current != undefined && cardRef.current != null) {
            cardRef.current.style.display = "block";
        }
        setShadow(3);
        setDisplay('block');
    }

    const onMouseOut = () => {
        if (cardRef.current != undefined && cardRef.current != null) {
            cardRef.current.style.display = "none";
        }
        setShadow(1);
        setDisplay('none');
    }
    const insertDocumentData = (src, documentName) => {
        insertDocument(src, documentName);
    }

    const getExternalLinkAndInsert = (id, documentName) => {
        let isExternal = appContainer.dialogProps.data.is_library 
                            && !appContainer.dialogProps.data.is_attachment;
        let params = { documentId: id , is_external : isExternal }
        var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.GET_DOCUMENT_EXTERNAL_LINK, params));
        promise.then((response) => {
            if (response.src !== '') {
                let src = response.src;
                insertDocumentData(src, documentName);
            }
        });
    }

    const changeDocumentToEmailBucketAndgetLink = (id, documentName, selectedLibrary) => {
        let params = { documentId: id, documentType: selectedLibrary };
        setLoading({ mounted: false });
        var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.ATTACHMENT_CHANGE_BUCKET, params));
        promise.then((response) => {
            setLoading({ mounted: true });
            if (response.src !== '') {
                let src = response.src;
                insertDocumentData(src, documentName);
            } else if (response.msg !== undefined && response.msg !== "") {
                dispatch(showCustomSnackBar(response.msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        });
    }

    const deleteDocumentData = (id, key) => {
        let msg = getLocalizedStrings().message.DOCUMENT_LIBRARY.CONFIRM_DELETE_MSG;
        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => deleteDocument(id, key), null);
    }

    const handleEdit = (fileObj) => {
        setEdit(true);
        let t = null;
        let ext = null;
        let name = fileObj.name;
        if (name.lastIndexOf('.') > -1) {
            t = name.substring(0, name.lastIndexOf('.'));
            ext = name.substring(name.lastIndexOf('.') + 1);
        }

        setEdited_name(t);
        setExt(ext);

    }

    const handleOnChangeName = (e) => {
        setEdited_name(e.target.value);
    }

    const handleBlur = (fileObj, value) => {
        let _fileName = fileObj.name;
        let _ext = null;
        if (_fileName.lastIndexOf('.') > -1) {
            _ext = _fileName.substring(_fileName.lastIndexOf('.') + 1);
        }

        if (value !== '') {
            _fileName = value + '.' + _ext;
        }
        let params = {
            id: fileObj.pk,
            image_name: _fileName,
        }
        var promise = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.EDIT, params));
        promise.then((response) => {
            if (response.status === 0 && response.data.is_edit) {
                setEdited_name('');
                documentObj.name = _fileName;
                setEdit(false);

            }
            else if (response.status === -1 && response.error.message !== "") {
                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }

        });
    }

    const handleImageErr = (id) => {
        let div = `<div class='noimage-div'>${getLocalizedStrings().label.DOCUMENT_LIBRARY.NO_IMAGE_FOUND}</div>`;
        document.getElementById(id).innerHTML = div;
    }

    let tempDisplay = 'block';
    let themeColor = app.me.background;
    let hasPermission = false;
    if (selectedLibrary === constants.IMAGES_LIBRARY) {
        if (app.me.id === documentObj.ownerId || app.me.is_tenant_admin) {
            hasPermission = true;
        }
    }

    if (selectedLibrary === 'stock_images') {
        tempDisplay = 'none';
    }

    if (selectedLibrary === 'documents') {
        let file_name = null;
        let ext = null;
        let fileName = documentObj.document_name;
        if (fileName.lastIndexOf('.') > -1) {
            file_name = fileName.substring(0, fileName.lastIndexOf('.'));
            ext = fileName.substring(fileName.lastIndexOf('.') + 1);
            ext = ext.toLowerCase()
        }
        let src = '';
        if (ext !== null) {
            src = getDocumentImage(ext);

        }

        return (
            <div>
                <div style={{ marginTop: '0px', borderRadius: '7px', cursor: 'pointer', textAlign: 'center', margin: 'auto' }}
                    onClick={
                        () => {
                            if (callFrom !== undefined && callFrom === constants.SALES_EMAIL) {
                                changeDocumentToEmailBucketAndgetLink(documentObj.id, documentObj.document_name, selectedLibrary);
                            } else {
                                getExternalLinkAndInsert(documentObj.id, documentObj.document_name);
                            }
                        }
                    }
                >

                    <Card onMouseOver={() => onMouseOver()}
                        onMouseOut={() => onMouseOut()} className=''
                        zDepth={shadow} style={{ width: 50, height: 50, margin: 'auto' }}>
                        <CardActionArea className='dlcard1'>
                            {!edit &&
                                <div style={{ ...crdStyle.cardWrapper }} id={'id' + documentObj.id} ref={cardRef}>
                                    <CardMedia
                                        component="img"
                                        image={src}
                                        style={{ alt: "No Image Found", width: "50", height: "50" }}
                                        onError={() => handleImageErr('id' + documentObj.id)}
                                    >
                                    </CardMedia>
                                </div>
                            }
                        </CardActionArea>
                    </Card>
                </div>
                <div style={{ color: '#000000', fontSize: '12px', paddingTop: '5px' }}>
                    <span title={documentObj.document_name} className="name" style={{ color: '#000000', fontSize: '12px', overflow: 'hidden', tempDisplay: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', margin: 'auto', width: '100%', textAlign: 'center' }}>{documentObj.document_name}</span>
                </div>
                <span style={{ tempDisplay: 'none' }} className="doc-name">{file_name}</span>
                <span style={{ tempDisplay: 'none' }} className="doc-ext">{ext}</span>
                <span style={{ tempDisplay: 'none' }} className="doc-owner">{documentObj.creator}</span>
            </div>
        );
    }
    return (

        <div style={{ marginTop: '0px', borderRadius: '7px' }}>
            <Box className="sf-box-sfbody-card" boxShadow={shadow} m={1} style={{ width: '120px'}}>
                <Card
                    onMouseOver={() => onMouseOver()}
                    onMouseOut={() => onMouseOut()}>
                    {(!edit && hasPermission) && <CardActionArea className='dlcard1'>

                        <div style={{ ...crdStyle.cardWrapper }} id={'id' + documentObj.id}>
                            <CardMedia
                                component="img"
                                image={documentObj.src}
                                onError={() => handleImageErr('id' + documentObj.id)}
                                title="Icon"
                                className='dlimg1'
                                alt={getLocalizedStrings().label.ATTACHMENT.NO_IMAGE_FOUND}
                            >
                            </CardMedia>
                            <CardActions>
                                <div style={{ ...crdStyle.layer }} ref={cardRef}>
                                    <div className="sf-card-btn-overlay" style={{ textAlign: 'center', width: '100%', height: '100%' }}>
                                        <div style={{ marginTop: '8px' }} >
                                            <div style={{ color: '#FFFFFF', fontSize: '12px', margin: '0 10px', marginBottom: '8px' }}>
                                                <span title={documentObj.name} style={{ color: '#FFFFFF', fontSize: '11px', marginRight: '10px', overflow: 'hidden', tempDisplay: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '60px', fontWeight: '100' }}>{documentObj.name}</span>
                                                <span title="Edit"><i className="fa fa-pencil" aria-hidden="true" style={{ paddingRight: '5px', cursor: 'pointer', top: '0px', position: 'relative' }} onClick={() => handleEdit(documentObj)}></i></span>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 0 }}>

                                            <Button
                                            className='btn-insert'
                                                onClick={() => {
                                                    if (callFrom !== undefined && (callFrom === constants.SALES_EMAIL || callFrom === constants.NEWS_OBJECT)) {
                                                        changeDocumentToEmailBucketAndgetLink(documentObj.pk, documentObj.name, selectedLibrary);
                                                    } else {
                                                        insertDocumentData(documentObj.src, documentObj.name);
                                                    }

                                                }
                                                }
                                                style={{ margin: '5px auto', backgroundColor: themeColor, color: '#ffffff', border: 'solid 1px #d3d6d6', zIndex: '0', height: '28px', lineHeight: '28px' }}
                                            >{getLocalizedStrings().label.COMMON.INSERT}</Button>
                                        </div>
                                        <div style={{ marginTop: 10, display: tempDisplay }}>
                                            <Button
                                                onClick={() => deleteDocumentData(documentObj.pk, documentObj.key)}
                                                style={{ marginBottom: '5px', backgroundColor: '#ffffff', color: '#717171', border: 'solid 1px #e0e0e0', zIndex: '0', height: '28px', lineHeight: '28px' }}
                                            >{getLocalizedStrings().label.COMMON.DELETE}</Button>
                                        </div>
                                    </div>
                                </div>
                            </CardActions>
                        </div>
                    </CardActionArea>}
                    {(!edit && !hasPermission) && <CardActionArea className='dlcard1 '>

                        <div style={{ ...crdStyle.cardWrapper }} id={'id' + documentObj.id}>
                            <CardMedia
                                component="img"
                                image={documentObj.src}
                                onError={() => handleImageErr('id' + documentObj.id)}
                                title="Icon"
                                className='dlimg1'
                                alt={getLocalizedStrings().label.ATTACHMENT.NO_IMAGE_FOUND}
                            >
                            </CardMedia>
                            <CardActions>
                                <div style={{ ...crdStyle.layer }} ref={cardRef}>
                                    <div className="sf-card-btn-overlay" style={{ textAlign: 'center', width: '100%', height: '100%' }}>
                                        <div >
                                            <div style={{ color: '#FFFFFF', fontSize: '12px', margin: '0 10px' }}>
                                                <span title={documentObj.name} style={{ color: '#FFFFFF', fontSize: '12px', marginRight: '10px', overflow: 'hidden', tempDisplay: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '60px' }}>{documentObj.name}</span>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: '30px' }}>
                                            <Button
                                            className='btn-insert'
                                                onClick={

                                                    () => {

                                                        if (callFrom !== undefined && callFrom === constants.SALES_EMAIL) {
                                                            changeDocumentToEmailBucketAndgetLink(documentObj.pk, documentObj.name, selectedLibrary);
                                                        } else {
                                                            insertDocumentData(documentObj.src, documentObj.name);
                                                        }

                                                    }
                                                }
                                                style={{ margin: '5px auto', backgroundColor: themeColor, color: '#ffffff', border: 'solid 1px #d3d6d6', zIndex: '0', height: '28px', lineHeight: '28px' }}
                                            >{getLocalizedStrings().label.COMMON.INSERT}</Button>
                                        </div>
                                    </div>
                                </div>
                            </CardActions>
                        </div>

                    </CardActionArea>
                    }
                    {edit && <CardActionArea className='dlcard1'>

                        <div style={{ ...crdStyle.cardWrapper }}>
                            <CardMedia
                                component="img"
                                image={documentObj.src}
                                onError={() => handleImageErr('id' + documentObj.id)}
                                title="Icon"
                                className='dlimg1'
                            >
                            </CardMedia>

                            <CardActions>
                                <div style={{ ...crdStyle.layer }} ref={cardRef} >
                                    <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
                                        <div style={{ marginTop: 50 }}>
                                            <input type="text"
                                                onChange={(e) => handleOnChangeName(e)}
                                                onMouseOut={(e) => handleBlur(documentObj, e.target.value)}
                                                value={edited_name}
                                                size="10"
                                                style={{ width: '90%', margin: '0 auto' }}
                                                ref={input => input && input.focus()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </CardActions>
                        </div>

                    </CardActionArea>}
                </Card>
            </Box>
        </div>
    );
}


const HeaderSection = ({ addDocument, handleAdd, searchText, setSelectedCategory, search, handleCategoryChange, selectedLibrary }) => {

    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [searchBy, setSearchBy] = useState('Name');
    const [searchOptions, setSearchOptions] = useState([]);

    useEffect(() => {

    }, [addDocument])

    const handleChangeCategoryList = (value) => {
        setCategory(value);
        handleCategoryChange(value?.value);
    }

    const loadCategoryList = () => {
        var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.CATEGORY_LIST, null));
        let categories = [];
        let tempCategory = category;
        promise.then((response) => {
            if (Array.isArray(response)) {
                response.forEach(function (record) {
                    categories.push({
                        label: record.category,
                        value: record.category
                    });
                });
            }

            if (categories.length > 0) {
                tempCategory = categories[0];
                setSelectedCategory(tempCategory);
            }
            setCategory(tempCategory);
            setCategories(categories);
        });
    }

    const loadSearchByOptionList = () => {
        let searchOptions = [
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.NAME,
                value: 'Name'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.TYPE,
                value: 'Type'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.OWNER,
                value: 'Owner'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.ALL,
                value: 'All'
            }
        ]

        setSearchOptions(searchOptions);
    }


    useEffect(() => {
        loadCategoryList();
        loadSearchByOptionList();
    }, []);

    const handleKeyUp = (event) => {
        search(event.target.value, searchBy);
    }

    const changeSearchBy = (event, value) => {
        setSearchBy(value.value);
    }

    if (selectedLibrary === 'images' || selectedLibrary === 'documents') {
        return (
            <div className="row">
                <div className="col-sm-2">
                    <div style={{ cursor: 'pointer', marginTop: '5px', marginLeft: '35px' }}>
                        <Button
                            primary={true}
                            onClick={() => handleAdd()}
                            style={styles.secondaryButton}
                        >{getLocalizedStrings().label.COMMON.ADD}</Button>
                    </div>
                </div>
                {selectedLibrary === 'documents' && !addDocument &&

                    <div className="col-sm-10" style={{ paddingLeft: 0, marginLeft: '-20px', zIndex: 99999999999 }}>

                        <div className="row">
                            <div className="col-sm-2">
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    {searchOptions.length > 0 && <Autocomplete
                                        name="template"
                                        defaultValue={searchOptions.find(v => v.value === searchBy)}
                                        options={searchOptions}
                                        onChange={(event, value) => changeSearchBy(event, value)}
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(option) => {
                                            return (
                                                <div title={option.label}>
                                                    {option.label}
                                                </div>
                                            );
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    label={option.label}
                                                    title={option.label}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        disableClearable={false}
                                        className="sendMail"
                                        style={{ height: 42 }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                                    />
                                    }
                                </FormControl>
                            </div>
                            <div className="col-sm-6">
                                <div style={{ border: '1px solid #ccc', height: '30px', marginTop: '5px' }}>
                                    <input type='text' id="searchText" value={searchText} onKeyUp={(e) => handleKeyUp(e)} placeholder={getLocalizedStrings().label.DOCUMENT_LIBRARY.SEARCH} style={{ outline: '0 none', color: '#888', border: 'none', paddingTop: '3px', paddingLeft: '10px', width: '85%' }} />
                                    <i className="fa fa-search" style={{ paddingTop: '5px', paddingLeft: '7px', color: '#ccc', float: 'right', paddingRight: '6px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    } else if (selectedLibrary === 'stock_images') {
        return (
            <div className="row">
                <div className="col-sm-2" style={{ padding: '8px 30px' }}>
                    <span style={{ fontSize: '14px', paddingLeft: '20px' }}>{getLocalizedStrings().label.COMMON.CATEGORIES}:</span>
                </div>
                <div className="col-sm-4" style={{ marginLeft: '-30px', fontSize: '14px' }}>
                    <FormControl style={{ width: '100%', marginTop: '-9px' }} className="test" noValidate autoComplete="off">
                        {categories.length > 0 && <Autocomplete
                            value={category}
                            name="category"
                            options={categories}
                            onChange={(e, value) => handleChangeCategoryList(value)}
                            getOptionLabel={(option) => option.label}
                            style={{ height: 42 }}
                            disableClearable={false}
                            className="search-by"
                            renderInput={(params) => <TextField {...params} margin='dense' size='small' className={"sf-fields-bg"} />}
                        />
                        }
                    </FormControl>


                </div>
            </div>
        );
    }
    else {
        return null;
    }
}

export default DocumentLibraryDialog;