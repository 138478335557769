import React ,{ useEffect, useState }from 'react';
import {Button,Chip,FormControl,TextField} from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import { completeAction } from '../../../services/actions/batchActions';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getTasksRefresh, getUnitTasksRefresh,getTasksPortal } from '../../../services/actions/taskActions';
import * as sfDialogs from '../components/sfDialogs';
import { refreshDetailViewData, refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { getStringParam, isValidParam, getArrayParam, getObjectParam, getBooleanParam,isInteger } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { refreshListView, refreshColumnView } from '../../../services/actions/listViewAcions';
import { getActiveTabInfo, getActiveTab } from '../../../services/helper/sfTabManager';
import { hasAccessPermission } from '../../../services/helper/common';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import Autocomplete from '@mui/material/Autocomplete';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Menu,IconButton } from '@mui/material';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { FormControlLabel } from '@mui/material';
import { detailViewIsMounted} from "../../../services/actions/detailViewActions";
import Checkbox from '@mui/material/Checkbox'; 
import Box from '@mui/material/Box';
import { event } from 'jquery';
import { updateTabInfo } from '../../../services/helper/sfTabManager';
import { useParams } from 'react-router-dom'


const Complete = ({ object,data,selectedRecords,callFrom,closeDrawer }) => {

    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
    const { suffix } = useParams();
    const [isMounted, setIsMounted] = useState(true);
    const [noteTypeArr, setNoteTypeArr] = useState([]);
    const [ntype, setNtype] = useState('');
    const [isAddToThisList, setIsAddToThisList] = useState(false);
    const [attachFiles, setAttachFiles] = useState([]);

    const [isOpenAttachmentPopover, setIsOpenAttachmentPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [attachLoader, setAttachLoader] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [taskIds, setTaskIds] = useState([]);
    const [existingFiles, setExistingFiles] = useState([]);
    const [attachmentDetails, setAttachmentDetails] = useState([]);

    
    useEffect(() => {
    getTaskInfo();
        getNoteTypes();
    }, []);

   const getTaskInfo =()=>{
        var accountvals = [];
        var recordIds = [];
       
        if (callFrom == constants.LIST_VIEW) {
            recordIds = selectedRecords;
            for (var i = 0; i < recordIds.length; i++) {
                accountvals.push(recordIds[i].id);
            }
        } else if (callFrom === constants.DETAIL_VIEW || callFrom === constants.COLUMN_VIEW) {
            recordIds[0] = data.id;
            for (var i = 0; i < recordIds.length; i++) {
                accountvals.push(recordIds[i]);
            }

        } else if (callFrom == constants.OTHER) {
            recordIds[0] = data.id;
            for (var i = 0; i < recordIds.length; i++) {
                accountvals.push(recordIds[i]);
            }
        }
        if(app.me.is_franchisee){
            setTaskIds(accountvals);
            setIsCheckboxChecked(true);
            getTaskDetails(accountvals);
           
        }else{
            setTaskIds(accountvals);
            setIsCheckboxChecked(false);
            getTaskDetails(accountvals);
        }
    }
    const getTaskDetails = (accountvals) => {
        if(accountvals.length == 1){
            let params = {};
            try {
                params.object = object;
                let promise = SFFormActions.getRecordPromise(object, accountvals[0], params);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response) && isValidParam(response.records)) {
                            if (response.records.hasOwnProperty('t_attachment') && isValidParam(response.records.t_attachment)  ) {
                                let _attachmentDetails = JSON.parse(response.records.t_attachment);
                                if(response.records.hasOwnProperty('t_workflow_task') && response.records.t_workflow_task == 1){
                                    if (isValidParam(_attachmentDetails.attached_files) && getArrayParam(_attachmentDetails.attached_files).length > 0) {
                                        let attachedFiles = [];
                                        _attachmentDetails.attached_files.map((file)=>{
                                            if( isInteger(file.uid)) {
                                            attachedFiles.push(file);
                                           }
                              
                                         })
                                         _attachmentDetails.attached_files = attachedFiles;
                                         setAttachmentDetails(_attachmentDetails);
                                    }

                                }else{
                                    setAttachmentDetails(_attachmentDetails);
                                }
                                
                            }
                           
                        }
                    });
                }
            } catch (error) {
                console.error("error in CustomForm.js --> 'getTaskDetails'" + error);
            }
        }
        
    }
   const getNoteTypes= () => {
        let appTypes = [];
        let hasPermission = false;
        let param = {};
        param.object = constants.NOTES;
        param.name = 'note_type';
        param.list_type = 'external';

        let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.NOTE_TYPE_LIST, param));

        promise.then((response) => {
            if (isValidParam(response)) {
                let type = getArrayParam(response.record);
                appTypes = type.map((f, i) => {
                    let typeObj = {};
                    typeObj.value = f.value;
                    typeObj.label = f.value;
                    return typeObj;
                });
                sortArrayObjectByProperty(appTypes, 'label');
                hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_ADD_TO_LIST);
                if (hasPermission) {
                    appTypes.push({ value: 'Add to This List', label: '----Add to this List----' });
                }
                if (object === constants.APPOINTMENTS) {
                    let noteValueAppo = ParameterVerifier.getArrayParam(appTypes).filter(f => f.value === 'Appointment');
                    setNoteTypeArr( appTypes);
                    setNtype(noteValueAppo[0].value);

                } else if (object === constants.TASKS || object === 'task') {
                    setNoteTypeArr( appTypes);
                    setNtype(data.taskType);
                }
                else {
                    setNoteTypeArr( appTypes);
                    setNtype(appTypes[0].value);
                }
            }
        });
    }
   const doComplete = (name) => {
        var accountvals = [];
        var recordIds = [];
        var msg = '';
        var relateObject = null;
        var currentStatus = null;
        let isAutomaticConversion = false;
        if (callFrom === constants.LIST_VIEW) {
            relateObject = object;
            recordIds = selectedRecords;
            for (var i = 0; i < recordIds.length; i++) {
                accountvals.push(recordIds[i].id);
            }
        } else if (callFrom === constants.DETAIL_VIEW || callFrom === constants.COLUMN_VIEW) {
            recordIds[0] = data.id;
            relateObject = object;
            for (var i = 0; i < recordIds.length; i++) {
                accountvals.push(recordIds[i]);
            }

        } else if (callFrom === constants.OTHER) {
            recordIds[0] = data.id;
            relateObject = data.object;
            for (var i = 0; i < recordIds.length; i++) {
                accountvals.push(recordIds[i]);
            }
            currentStatus = data.currentStatus;
        }

        let isDetailView = data.isDetailView;

        var notetext = document.getElementById('notetext').value;
        var notetype = ntype;
        let files = attachFiles;
        let _attachmentDetails = attachmentDetails;
        if (isValidParam(_attachmentDetails)) {
            if (isValidParam(_attachmentDetails.attached_files) && getArrayParam(_attachmentDetails.attached_files).length > 0) {
                files = [...files, ..._attachmentDetails.attached_files];
            }
        }
        let info = new Object();
        info.attached_urls = '';
        info.attached_files = getObjectParam(files);

        let strAtachmentInfo = '';
        let arrAttachment = getArrayParam(attachFiles);
        if(arrAttachment.length > 0){
            arrAttachment.forEach(f => {
                if (strAtachmentInfo == '') {
                    strAtachmentInfo = '##' + f.name + '##' + f.urlS3;
                } else {
                    strAtachmentInfo += ',@##' + f.name + '##' + f.urlS3;
                }
            });
        }
        
        let params = {
            "id": accountvals,
            "note_text": notetext,
            "note_types": notetype,
            "audit_trail_tableIds": getStringParam(app.me.audit_trail_table_ids),
            "current_status": getStringParam(currentStatus),
            "attachment":info,
            "isnotifytoFR": isCheckboxChecked,
            "mailattachInfo":strAtachmentInfo,
        }

        var response = completeAction(relateObject, params);
        setIsMounted(false);
        response.then((response) => {
            if (response.status === 0) {
                closeDrawer();
                if (accountvals.length > 1) {
                    msg = relateObject.charAt(0).toUpperCase() + relateObject.slice(1, relateObject.length) + " " + getLocalizedStrings().message.BATCH_ACTION.BATCH_COMPLETE_SUCCESSFULL;
                } else {
                    msg = relateObject.charAt(0).toUpperCase() + relateObject.slice(1, relateObject.length - 1) + " " + getLocalizedStrings().message.BATCH_ACTION.COMPLETE_SUCCESSFULL;
                }
                if (callFrom === constants.LIST_VIEW && !isDetailView) {
                    refreshListView(object, { isDetailView: false },null,suffix)
                } else if (callFrom === constants.COLUMN_VIEW) {
                    let dataParams = {
                        record_id: data.record_id,
                        object: data.parentObject,
                        listIndex: data.listIndex,
                        cardIndex: data.cardIndex,
                        updateCards: data.updateCards
                    }
                    refreshColumnView(dataParams);
                } else if (callFrom === constants.OTHER) {
                    let info = getActiveTabInfo();
                    if (isValidParam(info) && isValidParam(info.getTaskPageData)) {
                        info.getTaskPageData(object);
                    } else {
                        if(data.hasOwnProperty("propsTaskApp") && data.propsTaskApp != null){
                            dispatch(getTasksPortal(data.propsTaskApp));
                        }
                        dispatch(getTasksRefresh(true));
                    }
                } else {
                    if (response.data.hasOwnProperty('is_automatic_convert')) {
                        isAutomaticConversion = response.data.is_automatic_convert;
                    }
					dispatch(detailViewIsMounted(false));
                    dispatch(getTasksRefresh(true));
                    dispatch(refreshDetailViewListViewData(true));
                    dispatch(refreshDetailViewData(true));
                   
                    if (callFrom === constants.DETAIL_VIEW) {
                        let activeTab = getActiveTab();
                        activeTab = getObjectParam(activeTab);
                        let info = getObjectParam(activeTab.info);
                        if (isValidParam(info.selectedPageNo) && info.selectedPageNo > 1) {
                            info.selectedPageNo = 1;
                        }
                        let dataObj = getObjectParam(data);
                        if(getBooleanParam(dataObj.isFranchiseRecord)){
                            dispatch(getUnitTasksRefresh(true));
                        }
                    }
                }
                if(callFrom=== 'columnview' && object=== 'task'){
              msg = 'Task has been completed successfully.';
                }
                dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

                if (name === 'completeandnew') {
                    createNewTaskApp();
                }
            } else if (response.status !== 0 && response.error.message !== "") {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, response.error.message, null);
                setIsMounted(true);
            } else {
                msg = relateObject.charAt(0).toUpperCase() + relateObject.slice(1, relateObject.length - 1) + " " + getLocalizedStrings().message.BATCH_ACTION.COMPLETE_UNSUCCESSFULL;
                dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            if(callFrom == constants.LIST_VIEW && isDetailView && object == 'tasks'){
                let _info = getActiveTabInfo();
                if (_info.selectedPageNo > 1) {
                    _info.selectedPageNo = 1;
                    updateTabInfo(_info);
                }}
        });
       

    }

   const createNewTaskApp = () => {
        let propsData = data;
        let objectName = object;
        try {
            let hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
            if (hasPermission) {
                let data = {};
                data.id = 0;
                data.object = objectName;
                if (propsData.hasOwnProperty('parentDetails') && isValidParam(propsData.parentDetails)) {
                    data['parent_object'] = propsData.parentDetails.parent_object;
                    data['parent_record_id'] = propsData.parentDetails.parent_record_id;
                    data['isDetailView'] = propsData.parentDetails.isDetailView;
                } else if (propsData.parent_object === constants.JOBS_OBJECT && !propsData.hasOwnProperty('parentDetails')) {

                    data['parent_object'] = propsData.parent_object;
                    data['parent_record_id'] = propsData.parent_record_id;
                    data['isDetailView'] = propsData.isDetailView;

                } else {
                    data['parent_object'] = propsData.parentObject;
                    data['parent_record_id'] = propsData.record_id;
                    data['isDetailView'] = true;
                    data['columnViewTaskApp'] = true;
                    data['listIndex'] = propsData.listIndex;
                    data['cardIndex'] = propsData.cardIndex;
                    data['updateCards'] = propsData.updateCards;
                }

                let objName = "";
                if (objectName === constants.APPOINTMENTS) {
                    objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
                } else if (objectName === constants.TASKS) {
                    objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
                } else {
                    objName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
                }
                let add = ((objectName === constants.APPOINTMENTS) || (objectName === constants.TASKS)) ? getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT : getLocalizedStrings().label.COMMON.ADD;
                let labelName = add + ' ' + objName;

                dispatch(getAppDrawer(true, labelName, constants.NEW, data, callFrom));
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
            }
        } catch (error) {
            console.error('Error on Complete.js -> createNewTaskApp() : ' + error);
        }

    }

   const changeNoteType = (event, option) => {
        try {
            let value = option.value;
            if (value !== null) {
                if (value === 'Add to This List') {
                    setIsAddToThisList(true);
                    setNtype('');
                } else {
                    setNtype(value);
                }
            }
        } catch (error) {
            console.error("Error in 'complete.js -> changeNoteType()': " + error);
        }
    }
    const addToThisList = (event) => {
        let value = event.target.value;
        let fieldName = 'note_type';
        let tempNoteTypeArr = noteTypeArr;
        if (value !== '') {
            let noteValue = ParameterVerifier.getArrayParam(tempNoteTypeArr).filter(f => f.value === value);
            if (noteValue.length === 0) {
                let promise = SFFormActions.saveListFieldName(fieldName, value);
                promise.then((response) => {
                    if (isValidParam(response.data)) {
                        tempNoteTypeArr = tempNoteTypeArr.filter((f) => { return f.value !== 'Add to This List' });
                        tempNoteTypeArr.push({ value: value, label: value });
                        sortArrayObjectByProperty(tempNoteTypeArr, 'label');
                        tempNoteTypeArr.push({ value: 'Add to This List', label: '----Add to this List----' });


                        setNoteTypeArr(tempNoteTypeArr);
                        setIsAddToThisList(false);
                        setNtype(value);
                        addNoteTypeInForms(value);
                    }
                });
            } else {
                dispatch(showCustomSnackBar('Value already exists in List', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } else {
            setIsAddToThisList(false);
        }
    }
   const addNoteTypeInForms = (value) => {
        let notesFormData = Object.assign({}, sfForm.data['note']);
        if (isValidParam(notesFormData) && Object.keys(notesFormData).length !== 0) {
            let noteTypeField = notesFormData.fields.filter(f => { return f.name === 'note_type' })
            if (noteTypeField !== null && noteTypeField.length > 0) {
                let noteTypeItems = noteTypeField[0].list_values;
                if (noteTypeItems !== null && noteTypeItems.length > 0) {
                    let noteTypeObject = {};
                    noteTypeObject.id = value;
                    noteTypeObject.value = value;
                    noteTypeItems.push(noteTypeObject);
                }
            }
        }
    }
    const changeAddToNoteType = (event) => {
        let value = event.target.value;
        setNtype(value);
    }
  
    const openAttachmentPopover = (event) => {
        event.preventDefault();
        setIsOpenAttachmentPopover(true);
        setAnchorEl(event.currentTarget);
    }

   const closeAttachmentPopover = () => {
    setIsOpenAttachmentPopover(false);

    };

   const onClickUploadAttachment = (value, event) => {

        if (value == "Computer") {
            let _existingFiles=[];
            if(attachmentDetails != null){
                _existingFiles=[...existingFiles, attachmentDetails.attached_files]
            }else{
                _existingFiles=[...existingFiles]
            }
    setIsOpenAttachmentPopover(false);
             
            let data = {};
            data.fullWidth = true;
            data.maxWidth = 'md';
            data.callFrom = 'TaskForm';
            data.existingFiles= _existingFiles;
            data.object = constants.TASKS;
            //data.duplicateFiles= duplicateFiles;

            dispatch(getAppDialog(true, constants.SEND_MAIL_ATTACHMENT_UPLOAD_DIALOG, getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE, setAttachFilesFn, data, null));
     
        } 
      
    }
   const setAttachFilesFn = (files,duplicateFileName,duplicateFileExsts) => {
        if(object==constants.TASKS && duplicateFileExsts==true){
            var uniqueResultArray = attachFiles.filter(function(objOne) {
                return !files.some(function(objTwo) {
                    return objOne.name == objTwo.name;
                });
            });
            files = getArrayParam(files);
            if (files.length > 0) {
        setAttachFiles([...uniqueResultArray, ...files])
        
            }
        }
        else{
            files = getArrayParam(files);
            if (files.length > 0) {
                setAttachFiles([...attachFiles, ...files]);
            }
        }
               
            }
   const handleCheckboxChange = (isChecked) => {

    setIsCheckboxChecked(isChecked);
    }
   const getAttachmetFiles = () => {
        let _files = getArrayParam(attachFiles);
        let ul = null;
        let li = _files.map((f, index) => {
            return <li key={f.name + index}><span style={{
                float: 'left', textAlign: 'left', overflow: 'hidden',
                textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap', width: '150px'
            }} title={f.fileName}> {f.name} </span>
                &nbsp;  <i className="fa fa-times" aria-hidden="true" style={{ paddingRight: '5px', cursor: 'pointer' }}
                 onClick={() => {
                        attachFiles.splice(index, 1);
                        setAttachFiles([...attachFiles])
                }} ></i>
            </li>


        });
        if (li != null && li.length > 0) {
            ul = <ul>{li}</ul>;
          //  setExistingFiles(_files);

        }
        return ul;
    }

        let contentHeight = window.innerHeight - 200;
        let top = (contentHeight - 10) / 2;
        if (isMounted) {
            return (
                <div style={{ marginTop: '-10px', paddingLeft: '20px', paddingRight: '20px' }}>
                    <div style={{ color: '#717171', marginTop: '20px' }}>
                        <div><span></span></div>
                        {!isAddToThisList ?
                            <div style={{ width: '100%' }} >
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    {noteTypeArr.length > 0 && <Autocomplete
                                        name='Note Types'
                                        id="notetype"
                                        defaultValue={noteTypeArr.find(v => v.value === ntype)}
                                        options={noteTypeArr}
                                        onChange={changeNoteType.bind(this)}
                                        getOptionLabel={(option) => option.label}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                              <Chip
                                                label={option.label}
                                                title={option.label}
                                                {...getTagProps({ index })}
                                              />
                                            ))
                                          }
                                        disableClearable={false}
                                        className='selectStatus'
                                        renderInput={(params) => <TextField {...params} label={"Note Types"} variant="outlined" margin="dense" className={"sf-fields-bg"} />}
                                    />
                                    }
                                </FormControl>
                            </div> :

                            <div>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        name='Note Types'
                                        value={ntype}
                                        onChange={(event)=>changeAddToNoteType(event)}
                                        onBlur={(event)=> addToThisList(event)}
                                        autoFocus={true}
                                        autoComplete="new-password"
                                        margin='dense'
                                        className={"sf-fields-bg"}
                                    />
                                </FormControl>
                            </div>
                        }
                        <div >
                            <FormControl style={{ width: '100%', paddingTop: '15px' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    type='textarea'
                                    multiline={true}
                                    rows={3}
                                    fullWidth={true}
                                    id="notetext"
                                    textareastyle={{ marginTop: '10px', height: '90%' }}
                                    inputStyle={{ overflowY: 'auto' }}
                                    label={getLocalizedStrings().label.APPOINTMENTS_TASKS.ADD_NOTE}
                                    autoComplete="new-password"
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                    </div>
                    {app.me.is_franchisee &&
                            <div>
                            <box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                <div style={{ ...styles.sf_12 }}> <FormControlLabel control={<Checkbox color="default" checked={isCheckboxChecked} value={isCheckboxChecked} name="isCheckboxChecked" onChange={(e) =>handleCheckboxChange(e.target.checked)} defaultChecked={true} />} label="Notify Franchise" style={{ marginTop: '15px' }} />
                                </div>
                               
                            </box>
                            </div>

                    }
                    {taskIds.length == 1 &&
                    <div>
                        <div style={{ width: 'auto', fontSize: '14px' }}>
                            <a herf="" onClick={(event)=>openAttachmentPopover(event)} style={{ cursor: 'pointer' }}><i className="fa fa-paperclip" aria-hidden="true"></i><span> {getLocalizedStrings().label.MAIL.ATTACH}</span></a>
                            <i className="fa fa-angle-down" aria-hidden="true" onClick={(event)=>openAttachmentPopover(event)} style={{ paddingLeft: '7px', cursor: 'pointer' }}></i>
                            <Menu
                                open={isOpenAttachmentPopover}
                                anchorEl={anchorEl}
                                onClose={ ()=>closeAttachmentPopover()}
                                style={{ width: '100%' }}
                            >
                                <MenuItem onClick={()=>onClickUploadAttachment('Computer')} value='Computer' style={styles.popoverMenuItem}>{getLocalizedStrings().label.MAIL.COMPUTER}</MenuItem>
                                {/* <MenuItem onClick={this.onClickUploadAttachment.bind(this, 'Library')} value='Library' style={styles.popoverMenuItem}>{getLocalizedStrings().label.MAIL.LIBRARY}</MenuItem> */}
                            </Menu>


                        </div>

                        <div style={{ marginTop: '10px', height: '28px' }}>
                            {(attachFiles.length > 0 && !attachLoader) &&
                                <div style={{ marginLeft: '-10px' }}>{
                                getAttachmetFiles()
                                }</div>}

                        </div>
                     
</div> }

                    <div style={{ float: 'right', marginTop: '30px' }}>
                        <Button
                            style={styles.primaryButton}
                            onClick={()=> doComplete('complete')}
                        >
                            {getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE}</Button>
                        {(object == constants.APPOINTMENTS || object == constants.TASKS) &&
                            <Button
                                key='completeandnew'
                                onClick={()=> doComplete('completeandnew')}

                                style={{ ...styles.secondaryButton, marginRight: '10px' }}
                            > {getLocalizedStrings().label.COMMON.COMPLETE + ' & New'}</Button>}
                        <Button

                            style={styles.secondaryButton}
                            onClick={closeDrawer}
                        >{getLocalizedStrings().label.COMMON.CANCEL}</Button></div>


                </div>
            );
        } else {
            return (<div style={{ width: '100%', height: contentHeight }} >
                {<div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }} >
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                }
            </div>);
        }

    }

export default Complete;