import React, { useState, useEffect, useRef, useCallback } from 'react';
import { isValidParam, getStringParam, getObjectParam, getIntParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { setHeader } from '../../../services/actions/headerActions';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import { sortArrayObjectByProperty, dateFormatFlatPicker, dateFormat } from '../../../services/helper/utils';
import { constants, RELATION_REPORT_MAP, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Select, MenuItem, InputLabel, Button, FormControl } from '@mui/material';
import SFDataTable from '../components/sfDataTable';
import ShowCircularProgress from '../components/circularProgress';
import ReportSetup from '../components/reportSetup';
import * as sfDialogs from '../components/sfDialogs';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { addTab, TYPE_LIST_VIEW, getActiveTab, getActiveTabInfo, updateTabInfo } from '../../../services/helper/sfTabManager';
import { hasAccessPermission } from '../../../services/helper/common';
import { updateReferralReportData } from '../../../services/actions/referralReportActions';
import moment from 'moment';
import PopOver from '../components/PopOver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'flatpickr/dist/flatpickr.min.css';
import Flatpickr from 'react-flatpickr';
import Popover from '@mui/material/Popover';
import SuperSelectField from '../components/sfSuperSelect';
import { useDispatch, useSelector } from "react-redux";


const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};

const CustomDateInputStyle = () => {
    return <style> {
        ".form-control[readonly] {background-color: #fff !important;}"
    }</style>
}

function formatDate(date) {
    if (isValidParam(date)) {
        let d = date instanceof Date ? date : new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    return null;
}

function toDate(dateStr) {
    var parts = dateStr.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]);
}

const ReferralReport = (props) => {

    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const report = useSelector((state) => state.report);
    const sfForm = useSelector((state) => state.sfForm);
    const referralReport = useSelector((state) => state.referralReport.referralReportData);

    /**relation filter*/
    const [relationId, setRelationId] = useState(null);
    const [relationName, setRelationName] = useState(null);
    const [relationList, setRelationList] = useState([]);
    const [relation_popup, setRelation_popup] = useState(false);

    /* date field filter */
    const [dateFieldName, setDateFieldName] = useState(null);
    const [dateFieldsList, setDateFieldsList] = useState([]);
    const [dateField, setDateField] = useState({});
    const [date_field_popup, setDate_field_popup] = useState(false);

    /* date filter*/
    const [dueBy, setDueBy] = useState("tm");
    const [from_date, setFrom_date] = useState(null);
    const [to_date, setTo_date] = useState(null);
    const [due_by_popup, setDue_by_popup] = useState(false);

    /* row filter*/
    const [rowFieldName, setRowFieldName] = useState(null);
    const [rowFieldList, setRowFieldList] = useState([]);
    const [rowField, setRowField] = useState({});
    const [row_field_popup, setRow_field_popup] = useState(false);

    /* row filter values*/
    const [rowFieldValue, setRowFieldValue] = useState({ value: 'All', label: 'All' });
    const [rowValueList, setRowValueList] = useState([]);


    /* status filter*/
    const [colFieldName, setColFieldName] = useState(null);
    const [colField, setColField] = useState({});
    const [col_field_popup, setCol_field_popup] = useState(false);

    /* status filter values*/
    const [column, setColumn] = useState({ value: 'All', label: 'All' });
    const [colValueList, setColValueList] = useState([]);

    /* report */
    const [fields, setFields] = useState([]);
    const [records, setRecords] = useState([]);
    const [rowSort, setRowSort] = useState({});

    /** save component */
    const [saveComponent, setSaveComponent] = useState(null);
    const [isOpenSavePopover, setIsOpenSavePopover] = useState(false);

    const [title, setTitle] = useState(null);
    const [StateObject, setStateObject] = useState('accounts');
    const [isMounted, setIsMounted] = useState(false);

    const [fieldName, setFieldName] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [name, setName] = useState(null);
    const [colFieldsList, setColFieldsList] = useState([]);
    const [isReload, setIsReload] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    const [, updateNState] = useState();
    const handleForceupdateMethod = useCallback(() => updateNState({}), []);

    useEffect(() => {
        let cols = [];
        let rows = [];
       if (props.type === constants.REPORT_TYPE_RELATION) {
          dispatch(setHeader(constants.RELATION_REPORT, getLocalizedStrings().label.RELATION_REPORT.RELATION_REPORT_LABEL, null, false, true));
        }
      
        let tab = getActiveTab();
        let info = getActiveTabInfo();
       if (isValidParam(info) && Object.keys(info).length > 0 && info.hasOwnProperty("due_by") && info.hasOwnProperty("date_field")) {
          if (info.hasOwnProperty("report_name")) {
            setReportTitle(info.report_name);
           }
          setStateFromTabInfo();
          if (isValidParam(referralReport)) {
            cols = referralReport.fields;
            rows = getArrayParam(referralReport.records);
            setFields(cols);
            setRecords([...rows]);
          }
        } else {
          if (props.type === constants.REPORT_TYPE_CUSTOM_RELATION) {
            let tempId = 0;
           if (getIntParam(tempId) > 0) {
              tempId = props.id;
            } else {
              let info = getActiveTabInfo();
              if (isValidParam(info) && info.hasOwnProperty("selectedReport")) {
                tempId = getIntParam(info["selectedReport"].parameter_id);
              }
            }
            getCustomReport(tempId);
        }
        }
        }, [props.type]);

      useEffect(() => {
        if (colField) {
          loadFiltersAndReport();
        }
        },[colField,rowFieldName]);
      
      useEffect(() => {
        if (isMounted) {
        reloadReport();
        setIsMounted(true);
      }
      }, [isMounted]);

      const getCustomReport = (id) => {
        try {
            let customRep = null;
            let url = endPoints.RELATION_REPORT.REPORT_CUSTOMIZATION_GET;
            let params = {};
            if (getIntParam(id) > 0) {
                params.id = id;
                params.report_type = constants.REPORT_TYPE_CUSTOM_RELATION;
                params.type = 13;
                let promise = Promise.resolve(HTTPClient.get(url, params));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        customRep = getObjectParam(response);
                        setStateFromCustomReport(customRep);
                     }
                });
            }
        } catch (error) {
            console.error("Error in referralReport.js -> getCustomReport()'" + error);
        }
    }

    const loadFiltersAndReport = () => {
        try {
            getFiltersByPropsAndDefaultState();
            getFiltersByCall();
        } catch (error) {
            console.error("Error in referralReport.js -> loadFiltersAndReport()':" + error);
        }
    }

    const setStateFromTabInfo = () => {
        let info = getActiveTabInfo();
        let _title = title;
        let _dateFieldName = dateFieldName;
        let _dueBy = dueBy;
        let _rowFieldName = rowFieldName;
        let _rowFieldValue = rowFieldValue;
        let _rowValueList = rowValueList;
        let _rowFieldsList = rowFieldList;
        let _colFieldName = colFieldName;
        let _column = column;
        let _colValueList = colValueList;
        let _object = StateObject;
        let _relationName = relationName;
        let _relationId = relationId;
        let _relationList = relationList;
        
        if (isValidParam(info) && Object.keys(info).length > 0 && info.hasOwnProperty("due_by") && info.hasOwnProperty("date_field")) {
            _title = getStringParam(info.report_name);
            _dateFieldName = getStringParam(info.date_field);
            setDueBy(info.due_by);
            if (getStringParam(info.due_by) == "cust") {
                if (info.hasOwnProperty("from_date") && getStringParam(info.from_date).length > 0) {
                    let fromDate = new Date(info.from_date);
                    setFrom_date(fromDate);
                }
                if (info.hasOwnProperty("to_date") && getStringParam(info.to_date).length > 0) {
                    let toDate = new Date(info.to_date);
                    setTo_date(toDate);
                }
            } else {
                setDateRangeValue(info.due_by);
            }
            _rowFieldName = getStringParam(info.row_field);
            _rowFieldValue = getArrayParam(info.row_field_value);
            _rowValueList = getArrayParam(info.row_value_list);
            _rowFieldsList = getArrayParam(info.row_field_list);
            _colFieldName = getStringParam(info.col_field);
            _column = getArrayParam(info.column);
            _colValueList = getArrayParam(info.col_value_list);
            _object = getStringParam(info.object);
            _relationName = getStringParam(info.relation_name);
            _relationId = getIntParam(info.relation_id);
            _relationList = getArrayParam(info.relation_list);
            setRowFieldName(_rowFieldName);
            setRowFieldValue(_rowFieldValue );
            setRowValueList(_rowValueList);
            setRowFieldList([..._rowFieldsList]);
            setColFieldName(_colFieldName);
            setColumn(info.column);
            setDateFieldName(_dateFieldName);
            setColValueList([..._colValueList]);
            setStateObject(_object);
            setRelationName(_relationName);
            setRelationId(_relationId);
            setRelationList(_relationList);
            setTitle(_title);
            getFiltersByPropsAndDefaultState();
        }
    }

    const setStateFromCustomReport = (customRep) => {
        let repJson = null;
        let _from_date = from_date;
        let _to_date = to_date;
        try {
            if (isValidParam(customRep)) {
                setReportTitle(customRep.report_title);
                setDueBy(getStringParam(customRep.due_by));
                if (customRep.due_by == "cust") {
                    if (isValidParam(customRep.from_date) && getStringParam(customRep.from_date).length > 0) {
                        let momentDate = moment(customRep.from_date);
                        let tempObj = momentDate.format(dateFormat[app.me.date_format]);
                        _from_date = new Date(tempObj);
                        setFrom_date(_from_date);
                    }
                    if (isValidParam(customRep.to_date) && getStringParam(customRep.to_date).length > 0) {
                        let momentDate = moment(customRep.to_date);
                        let tempObj = momentDate.format(dateFormat[app.me.date_format]);
                        _to_date = new Date(tempObj);
                        setTo_date(_to_date);
                    }
                } else {
                    setDateRangeValue(getStringParam(customRep.due_by));
                }
                repJson = JSON.parse(customRep.params);
                if (isValidParam(repJson)) {
                    setRelationId(repJson.relation_id)
                    setRelationName(repJson.relation_name);
                    setRowFieldValue(repJson.selected_row_values);
                    setColumn(repJson.selected_col_values);
                    loadFiltersAndReport();
                }
                setTitle(customRep.report_title);
                setColFieldName(repJson.col_field_name);
                setRowFieldName(repJson.row_field_name);
                setDateFieldName(getStringParam(customRep.date_field_name));
                 }
                 
        } catch (error) {
            console.log("Error in 'referralReport -> setStateFromCustomReport()'" + error);
        }
    }

    const getFiltersByCall = () => {
        try {
            let relation_list = getArrayParam(relationList);
            let _relationName = getStringParam(relationName);
            let _relationId = getStringParam(relationId);
            let url = endPoints.RELATION_REPORT.RELATIONS_GET;
            let params = {};
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (getArrayParam(response).length > 0) {
                    relation_list = getArrayParam(response);
                    sortArrayObjectByProperty(relation_list, 'relation_name');
                    if (relation_list.length > 0) {

                        let relarr = [];
                        if (props.type == constants.REPORT_TYPE_CUSTOM_RELATION || props.type == "relation-matrix") {
                            //for user's saved report
                            relarr = relation_list.filter(f => {
                                if (f.relation_name == _relationName) {
                                    return f;
                                }
                            });

                            //set selected value if exists else the 1st item
                            if (relarr.length > 0) {
                                _relationId = getStringParam(relarr[0].id);
                                _relationName = getStringParam(relarr[0].relation_name);
                            } else {
                                _relationId = getStringParam(relation_list[0].id);
                                _relationName = getStringParam(relation_list[0].relation_name);
                            }
                        } else {
                            // main relation report 
                            relarr = relation_list.filter(f => {
                                if (f.relation_name == 'Referent') {
                                    return f;
                                }
                            });
                            //set Referent if exists else the 1st item
                            if (relarr.length > 0) {
                                _relationId = getStringParam(relarr[0].id);
                                _relationName = getStringParam(relarr[0].relation_name);
                                setRelationName(_relationName);
                            } else {
                                _relationId = getStringParam(relation_list[0].id);
                                _relationName = getStringParam(relation_list[0].relation_name);
                            }
                        }
                        setRelationList(relation_list);
                        setRelationId(_relationId);
                    }
                }
                getColumnFilterValueList(colField);
            });
        } catch (error) {
            console.error("Error in referralReport.js -> getFiltersByCall():" + error);
        }
    }

    const getRowFilterValueList = (rowField) => {
        let valueList = [];
        if (isValidParam(rowField)) {
            if (getArrayParam(rowField.list_values).length > 0) {
                rowField.list_values.map((m, i) => {
                    if (m.id != "") {
                        valueList.push({ value: m.id, label: m.value });
                    }
                });
                valueList.unshift({ value: 'All', label: 'All' });
            }
        }
         return valueList;
    }


    const getColumnFilterValueList = (field) => {
        let _colValueList = [];
        if (isValidParam(field)) {
            if (field.name == 't_status') {
                let url = endPoints.RELATION_REPORT.STATUS_LIST_GET;
                let params = {};
                params.object = getStringParam(StateObject);
                let promise = Promise.resolve(HTTPClient.get(url, params));
                promise.then((response) => {
                    if (getArrayParam(response).length > 0) {
                        _colValueList = getArrayParam(response);
                        _colValueList.unshift({ value: 'All', label: 'All' });
                        setColValueList(_colValueList);
                        if (props.type !== constants.REPORT_TYPE_CUSTOM_RELATION) {
                            setColumn(_colValueList);
                            setColValueList(_colValueList);
                        }
                        if (!isReload) {
                            validateRequest('columnFilter');
                        }
                        if (field !== 't_status') {
                            handleRequestClose(field + "_popup");
                        }
                        handleForceupdateMethod();
                    }
                })
            } else {
                if (getArrayParam(field.list_values).length > 0) {
                    field.list_values.map((m, i) => {
                        if (m.id !== "") {
                            _colValueList.push({ value: m.id, label: m.value });
                        }
                    });
                }
                _colValueList.unshift({ value: 'All', label: 'All' });
                setColValueList(_colValueList);
                if (props.type !== constants.REPORT_TYPE_CUSTOM_RELATION) {
                    setColumn(_colValueList);
                    setColValueList(_colValueList);
                   
                }
               if (!isReload) {
                  validateRequest('handleChange');
                }
                if (field !== 't_status') {
                    handleRequestClose(field + "_popup");
                }
                handleForceupdateMethod();
            }

        }

    }


    const getMultiselectValues = (selectedValues, fieldName) => {
        let value = "";
        try {
            if (getArrayParam(selectedValues).length > 0) {
                selectedValues = selectedValues.filter(f => { return f.value != 'All'; })
                selectedValues.map((item, i) => {
                    if (i == 0) {
                        value = getStringParam(item.value);
                    } else {
                        value += "," + getStringParam(item.value);
                    }
                })
            }
        } catch (error) {
            console.error("Error in referralReport.js -> getMultiselectValues():" + error);
        }
        return value;
    }

    const getReportConfig = () => {
        let config = {};
        try {
            config.tableId = OBJECT_TABLEID_MAP[getStringParam(StateObject)];
            config.relationId = getIntParam(relationId);
            config.relationName = getStringParam(relationName);
            config.date = { id: dateField.id, name: dateField.name, dueBy: dueBy, from_date: formatDate(from_date), to_date: formatDate(to_date) };
            config.row = { id: rowField.id, name: rowField.name, values: getArrayParam(rowFieldValue) };
            config.column = { id: colField.id, name: colField.name, values: getArrayParam(column) };
        } catch (error) {
            console.log("Error in 'referralReport.js -> getReportConfig'" + error);
        }
        return config;
    }

    const validateRequest = (callfrom, event) => {
        let errorMessage = null;
        let isValid = true;
        try {
            if (getStringParam(dueBy).length == 0) {
                isValid = false;
                errorMessage = getLocalizedStrings().message.RELATION_REPORT.DUE_BY_BLANK;
            } else if (getStringParam(dueBy).length > 0 && dueBy == "cust") {
                let fromDate = new Date(formatDate(from_date));
                let toDate = new Date(formatDate(to_date));
                if (fromDate > toDate) {
                    isValid = false;
                    errorMessage = getLocalizedStrings().message.RELATION_REPORT.INVALID_END_DATE;
                }
            } else if (!rowFieldValue || rowFieldValue.length === 0) {
                isValid = false;
                errorMessage = getLocalizedStrings().message.RELATION_REPORT.NO_ROW_FIELD_VALUE;
            } else if (!column || column.length === 0) {
                isValid = false;
                errorMessage = getLocalizedStrings().message.RELATION_REPORT.NO_COLUMN_VALUE;
            }
            else if (getStringParam(colFieldName) && getStringParam(rowFieldName) && getStringParam(colFieldName) === getStringParam(rowFieldName)) {
                isValid = false;
                errorMessage = getLocalizedStrings().message.RELATION_REPORT.SAME_ROW_COLUMN_FIELD;
            }else if (callfrom=='run' && relationName == null) {
                isValid = false;
                errorMessage = 'Please select relation';
            }

            if (isValid) {
                if (callfrom == "save_report") {
                    openSaveCustomizationPopOver(event);
                    return false;
                }
                else {
                    reloadReport();
                }
            } else {
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }

        } catch (error) {
            console.error("Error in 'referralReport.js -> validateRequest()' " + error);
        }
    }

    const reloadReport = () => {
        let params = {};
        let url = endPoints.RELATION_REPORT.RELATION_REPORT_GET;
        let _records = [];
        let _fields = [];
        let tempRelationId = 0;
        try {
            params.object = getStringParam(StateObject);
            params.due_by = getStringParam(dueBy);
            params.from_date = formatDate(from_date);
            params.to_date = formatDate(to_date);
            params.date_field = getStringParam(dateFieldName);
            params.row_field = getStringParam(rowFieldName);
            params.row_values = getMultiselectValues(rowFieldValue, 'rowField');
            if (getArrayParam(rowFieldValue).length == getArrayParam(rowValueList).length) {
                params.row_value_type = 'All';
            }
            params.col_field = getStringParam(colFieldName);
            params.col_values = getMultiselectValues(column, 'column');
            if (getArrayParam(column).length == getArrayParam(colValueList).length) {
                params.col_value_type = 'All';
            }
            params.relation_id = relationId;
            //save to tab
            let activeTab = getActiveTab();
            let info = getObjectParam(activeTab.info);
            info.object = params.object;
            if (props.type == constants.REPORT_TYPE_CUSTOM_RELATION) {
                info.report_name = getStringParam(info.selectedReport.item_name);
            } else {
                info.report_name = getLocalizedStrings().label.RELATION_REPORT.RELATION_REPORT_LABEL;
            }

            info.due_by = params.due_by;
            info.date_field = params.date_field;
            if (params.due_by == 'cust') {
                info.from_date = getStringParam(params.from_date);
                info.to_date = getStringParam(params.to_date);
            }
            info.row_field = params.row_field;
            info.row_field_value = getArrayParam(rowFieldValue);
            info.row_value_list = getArrayParam(rowValueList);
            info.row_field_list = getArrayParam(rowFieldList);
            if (getArrayParam(rowFieldValue).length == getArrayParam(rowValueList).length) {
                info.row_value_type = 'All';
            }

            info.col_field = getStringParam(colFieldName);
            info.column = getArrayParam(column);
            info.col_value_list = getArrayParam(colValueList);
            if (getArrayParam(column).length == getArrayParam(colValueList).length) {
                info.col_value_type = 'All';
            }
            info.relation_id = getIntParam(relationId);
            info.relation_name = getStringParam(relationName);
            info.relation_list = getArrayParam(relationList);
            updateTabInfo(info);

            setIsMounted(false);
            setIsReload(false);
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    tempRelationId = getIntParam(response.relationId);
                    _records = getArrayParam(response.records);
                    _fields = getFieldList(response.fields);
                    let resultSet = {};
                    resultSet.fields = _fields;
                    resultSet.records = _records;
                    dispatch(updateReferralReportData(resultSet));
                }
                setIsMounted(true);
                setFields([..._fields]);
                setRecords([..._records]);
                setIsReload(true);
            });
        } catch (error) {
            console.error("Error in 'referralReport.js -> reloadReport()':" + error);
        }

    }

    const getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }

    const generateDateSelectionField = () => {
        let optionsMultilingual = getMultilingualOptionsMap();
        try {
            return Object.keys(optionvalue).map((itemKey) => {
                return <MenuItem key={itemKey} value={itemKey} style={styles.popoverMenuItem}>{optionsMultilingual[optionvalue[itemKey]]}</MenuItem>;
            });
        } catch (error) {
            console.error("Error in 'referralReport.js -> generateDateSelectionField()':" + error);
        }
    }


    const handlePopover = (fieldName, event) => {
        try {
            event.preventDefault();
            setFieldName(fieldName);
            setAnchorEl(event.currentTarget);
        }
        catch (error) {
            console.error("Error in 'referralReport.js -> handlePopover()':" + error);
        }
    }

    const handleRequestClose = (fieldName) => {
        try {
            setFieldName(false);
        }
        catch (error) {
            console.error("Error in 'referralReport.js -> handleRequestClose()':" + error);
        }
    };

    const handleDropDownChange = (name, event, value) => {
        try {
            value = value.props.value;
            if (name === "due_by") {
                setDueBy(value);
                setDateRangeValue(value);
                handleRequestClose(name + "_popup");
                handleForceupdateMethod();
            } else if (name === 'relation') {
                if (getStringParam(value).length > 0) {
                    let relation = relationList.filter(f => {
                        if (f.relation_name === value) {
                            return f;
                        }
                    })
                    if (relation.length > 0) {
                        setRelationId(relation[0].id);
                        setRelationName(relation[0].relation_name);
                    }
                }
                handleRequestClose(name + "_popup");
                handleForceupdateMethod();
            }
            else if (name === 'date_field') {
                if (getStringParam(value).length > 0) {
                    let tempDateField = dateFieldsList.filter(f => {
                        if (f.label === value) {
                            return f;
                        }
                    })
                    if (tempDateField.length > 0) {
                        setDateField(tempDateField[0]);
                        setDateFieldName(tempDateField[0].name);
                    }
                }
                handleRequestClose(name + "_popup");
                handleForceupdateMethod();
            } else if (name === 'row_field') {
                if (getStringParam(value).length > 0) {
                    let rowFieldVal = rowFieldList.filter(f => {
                        if (f.label === value) {
                            return f;
                        }
                    })
                    if (rowFieldVal.length > 0) {
                        setRowField(rowFieldVal[0]);
                        setRowFieldName(rowFieldVal[0].name);
                        let list = getArrayParam(getRowFilterValueList(rowFieldVal[0]));
                        setRowValueList(list);
                        setRowFieldValue(list);
                    }
                }
                handleRequestClose(name + "_popup");
                handleForceupdateMethod();
            } else if (name === 'col_field') {
                if (getStringParam(value).length > 0) {
                    let tempColField = colFieldsList.filter(f => {
                        if (f.label === value) {
                            return f;
                        }
                    })
                    if (tempColField.length > 0) {
                        setColField(tempColField[0]);
                        setColFieldName(tempColField[0].name);
                        handleForceupdateMethod();
                        getColumnFilterValueList(tempColField[0]);
                        // selects all values by default on change
                    }
                }

            }
        }
        catch (error) {
            console.error("Error in 'referralReport.js -> handleDropDownChange()':" + error);
        }
    }


    const handleDateChange = (_name, date) => {
        setFrom_date(date);
    }

    const downloadReportPdf = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(14);

        let documentName = getLocalizedStrings().label.RELATION_REPORT.RELATION_REPORT_LABEL + '[' + new Date().getTime() + '].pdf';
        const title = getLocalizedStrings().label.RELATION_REPORT.RELATION_REPORT_LABEL;
        let optionsMultilingual = getMultilingualOptionsMap();
        let subHeading = getLocalizedStrings().label.RELATION_REPORT.PDF_LABEL_DURATION + " : " + optionsMultilingual[optionvalue[getStringParam(dueBy)]] + "\n";
        const headers = [];
        getArrayParam(fields).map(f => {
            headers.push(f.label);
        });


        const data = [];
        records.map(r => {
            let arr = [];
            let col = '';
            for (let i = 0; i < headers.length; i++) {
                if (i == 0) {
                    arr.push(r[getStringParam(rowFieldName)]);
                } else if (getStringParam(headers[i]) == "Total") {
                    arr.push(r["row_total"]);
                }
                else {
                    arr.push(r[getStringParam(headers[i])]);
                }
            }
            data.push(arr);
        });

        let content = {
            startY: 100,
            head: [headers],
            body: data,

        };

        doc.text(title, marginLeft, 40);
        doc.text(subHeading, marginLeft, 60);
        doc.autoTable(content);
        doc.save(documentName);

    }

    const excelGenerateBeforeHeaders = () => {
        let cells = '';
        try {
            let optionsMultilingual = getMultilingualOptionsMap();
            let _dueBy = '';
            let reportName = getStringParam(getLocalizedStrings().label.RELATION_REPORT.RELATION_REPORT_LABEL);
            let columns = fields.filter(f => {
                if (!f.is_hidden) { return f; }
            });
            let columnsLength = getArrayParam(columns).length;

            cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
            if (reportName.length > 0) {
                _dueBy = getStringParam(optionsMultilingual[optionvalue[dueBy]]);
                cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + _dueBy + ' </td></tr>';
            }
        }
        catch (error) {
            console.error("Error in referralReport.js -> 'excelGenerateBeforeHeaders()' " + error);
        }
        return cells;
    }

    const excelGenerateHeaders = () => {
        let cells = '';
        try {
            let reportName = getStringParam(getLocalizedStrings().label.RELATION_REPORT.RELATION_REPORT_LABEL);
            let columns = fields.filter(f => {
                if (!f.is_hidden) { return f; }
            });
            columns.map(f => {
                cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
            });
            cells = '<tr>' + cells + '</tr>';
        }
        catch (error) {
            console.error("Error in referralReport.js -> 'excelGenerateBeforeHeaders()' " + error);
        }
        return cells;
    }

    const excelGenerateRows = () => {
        let rows = records;
        let columns = getArrayParam(fields);
        let rowData = '';
        try {
            if (rows.length != 0) {
                let cells = '';
                rows.map((rowObject, rowIndex) => {
                    let innerCells = '';
                    columns.map((columnObject, colIndex) => {
                        if (!columnObject.is_hidden) {
                            innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                        }
                    })
                    if (rowIndex % 2 == 0) {
                        cells = cells + ('<tr>' + innerCells + ' </tr>');
                    } else {
                        cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                    }

                });
                rowData = cells;
            } else {
                rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
            }
        } catch (error) {
            console.error("Error in 'referralReport.js -> 'excelGenerateRows()' " + error);
        }
        return rowData;
    }

    const downloadExcel = () => {
        let beforeHeaderComponent = excelGenerateBeforeHeaders();
        let headerComponents = excelGenerateHeaders();
        let rowComponents = excelGenerateRows();
        let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '</tbody></table>';
        let htmlData = htmlTable;
        if (isValidParam(htmlData) && htmlData != '') {
            htmlData = htmlData.replace("%22", "\"");
            var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = 'ReferralMatrixReportResultSet_' + new Date().getTime() + '.xls';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }


    const getButtonSet = () => {
        return (
            <div style={{ paddingLeft: '15px', paddingTop: '25px', float: 'left' }}>
                <div style={{ cursor: 'pointer', width: "2%", float: 'left', marginLeft: '5px', height: '16px', display: 'inline' }}>
                    <Button startIcon={<i className="fa fa-file-pdf-o" style={{ fontSize: '20px', color: 'red' }} />} onClick={() => { downloadReportPdf(props.config) }} style={{
                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                        color: '#717171',
                        border: '0px',
                        minWidth: '30px'
                    }} title="Export to PDF"
                    ></Button>
                </div>
                <div style={{ cursor: 'pointer', width: "2%", float: 'left', marginLeft: '25px', height: '16px', display: 'inline' }}>
                    <Button
                        startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
                        onClick={() => { downloadExcel(); }}
                        style={{
                            ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                            color: '#717171',
                            border: '0px',
                            minWidth: '30px'
                        }}
                        title="Export to Excel"
                    ></Button>
                </div>
            </div>
        );
    }

    const openSaveCustomizationPopOver = (event) => {
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        let hasPermission = hasAccessPermission(null, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_REPORTS);
        try {
            if (!isInXpress && hasPermission) {
                event.preventDefault();
                setIsOpenSavePopover(true);
                setAnchorEl(event.currentTarget);
                setSaveComponent(<ReportSetup object={constants.ACCOUNTS_OBJECT} closeEvent={closeListViewSetupPopOver} reportConfig={getReportConfig} setReportTitle={(reportLabel,callFrom)=>setReportTitle(reportLabel,callFrom)} />)
            } else {
                let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
            }
        } catch (error) {
            console.log("Error in 'referralReport.js -> openSaveCustomizationPopOver()' " + error);
        }
    }

    const closeListViewSetupPopOver = () => {
        setIsOpenSavePopover(false);
        setSaveComponent(null);
    }

    const closePopOver = () => {
        setIsOpenSavePopover(false);
    }
    const getFirstRowFilters = () => {
        let component = null;
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        let datePopOverList = generateDateSelectionField();
        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            minuteIncrement: 1,
            static: true,
            time_24hr: HHFormat.test(app.me.date_format) ? true : false
        };
        try {
            component = (<div className="row" style={{ padding: '0px' }}>
                <CustomDateInputStyle />
                <div >
                    <div style={{ display: 'flex', marginLeft: "17px", marginBottom: "12px" }}>
                        <div style={{ paddingLeft: '7px', marginRight: '7px' }} >
                            <div>
                                <FormControl variant="outlined" >
                                    <InputLabel id="sf-referralreport-simple-select-outlined-label" className="sf-referralreport">{getLocalizedStrings().label.RELATION_REPORT.RELATION_FIELD_LABEL}</InputLabel>
                                    <Select
                                        labelId="sf-referralreport-simple-select-outlined-label"
                                        id="sf-referralreport-simple-select-outlined"
                                        value={relationName ? relationName : ""}
                                        children={true}
                                        onChange={(event, value) => handleDropDownChange('relation', event, value)}
                                        className={"sf-fields-bg sf-referralreport"}
                                        label={getLocalizedStrings().label.RELATION_REPORT.RELATION_FIELD_LABEL}
                                        style={{ minWidth: 200, maxWidth: 200, height: '45px' }}
                                    >
                                        {generateSelectionOptions('relation')}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ paddingLeft: '0px' }}>
                            <div style={{ paddingRight: 6 }}>

                                <FormControl variant="outlined" >
                                    <InputLabel id="sf-referral-report-simple-select-outlined-label" className="sf-referral-report">{getLocalizedStrings().label.RELATION_REPORT.DATE_FIELD_LABEL}</InputLabel>
                                    <Select
                                        labelId="sf-referral-report-simple-select-outlined-label"
                                        id="sf-referral-report-simple-select-outlined"
                                        value={dateField.label}
                                        children={true}
                                        onChange={(event, value) => handleDropDownChange('date_field', event, value)}
                                        className={"sf-fields-bg sf-referralreport"}
                                        label={getLocalizedStrings().label.RELATION_REPORT.DATE_FIELD_LABEL}
                                        style={{ minWidth: 200, maxWidth: 200, height: '45px' }}
                                    >
                                        {generateSelectionOptions('date_field')}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div >
                            <div style={{ paddingRight: 6 }}>
                                <FormControl variant="outlined" >
                                    <InputLabel id="sf-referralreport-fromreferral-simple-select-outlined-label" className="sf-referralreport-fromreferral">{getLocalizedStrings().label.REPORT.DATE_RANGE}</InputLabel>
                                    <Select
                                        labelId="sf-referralreport-fromreferral-simple-select-outlined-label"
                                        id="sf-referralreport-fromreferral-simple-select-outlined"
                                        value={dueBy}
                                        onChange={(event, value) => handleDropDownChange('due_by', event, value)}
                                        className={"sf-fields-bg sf-referralreport"}
                                        label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                                        style={{ minWidth: 200, maxWidth: 200, height: '45px' }}
                                    >
                                        {generateDateSelectionField('due_by')}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ width: '15%', paddingRight: 6 }}>
                            <PopOver
                                id={'sf-date-time-picker1'}
                                className={'date-time anval'}
                                key={'fromDate'}
                                name={'from_date'}
                                btnType={'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1 }}
                                buttonLabel={getLocalizedStrings().label.REPORT.START_DATE}
                                onChange={(e) => setFrom_date(e)}
                                value={moment(getStringParam(from_date)).format(dateFormat[app.me.date_format])}
                                innerLabelStyle={{ padding: "13px 5px 2px 5px", fontSize: '15px' }}
                                height={45}
                                options={datePickerOptions}
                            />

                        </div>
                        <div style={{ width: '15%', paddingRight: 6 }}>
                            <PopOver
                                id={'sf-date-time-picker2'}
                                className={'date-time anval'}
                                key={'toDate'}
                                name={'to_date'}
                                btnType={'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1 }}
                                buttonLabel={getLocalizedStrings().label.REPORT.END_DATE}
                                onChange={(e) => setTo_date(e)}
                                value={moment(getStringParam(to_date)).format(dateFormat[app.me.date_format])}
                                innerLabelStyle={{ padding: "13px 5px 2px 5px", fontSize: '15px' }}
                                height={45}
                                options={{ ...datePickerOptions, minDate: from_date }}
                            />
                        </div>
                        <div style={{ paddingLeft: '0px', display: 'block', width: '23%', paddingLeft: '8px', marginLeft: '0px' }}>
                            <Button
                                id="save_report"
                                key='save_report'
                                onClick={(event) => validateRequest('save_report', event)}
                                title={getLocalizedStrings().label.RELATION_REPORT.SAVE_CUSTOMIZATION_LABEL}

                                style={{ ...styles.primaryButton, paddingLeft: '10px', marginTop: '10px', verticalAlign: 'middle', borderRadius: '30px 30px 30px 30px', }}
                            >{getLocalizedStrings().label.RELATION_REPORT.SAVE_CUSTOMIZATION_LABEL}</Button>
                            <Popover
                                open={isOpenSavePopover}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                onClose={closePopOver}
                                className='overflow-y-initial'
                            >
                                {saveComponent}
                            </Popover>
                        </div>
                    </div>
                </div>
            </div>
            );
        } catch (error) {
            console.error("Error in 'referralReport.js -> getFirstRowFilters':" + error)
        }
        return component;
    }

    const selectColumn = (data, name, values) => {
        let finalSelected = data;
        let allType = getArrayParam(colValueList);
        let finalAllSelect = values.label;
        let allSelected = values.selected;
        let hasAll = data.filter(f => { return f.value == 'All' });

        if ((allType.length - data.length) == 1) {
            if (finalAllSelect != null && finalAllSelect == 'All' && allSelected == false) {
                finalSelected = allType;
            } else if (finalAllSelect != null && finalAllSelect == 'All' && allSelected) {
                finalSelected = [];
            } else if (finalAllSelect != null && finalAllSelect != 'All' && hasAll.length == 0) {
                finalSelected = allType;
            } else {
                finalSelected = data.filter(f => { return f.value != 'All' });
            }
        } else if (finalAllSelect != null && finalAllSelect == 'All' && allSelected == false) {
            finalSelected = allType;
        }
        setColumn(finalSelected);
    }

    const selectRow = (data, name, values) => {
        let finalSelected = data;
        let allType = getArrayParam(rowValueList);
        let finalAllSelect = values.label;
        let allSelected = values.selected;
        let hasAll = data.filter(f => { return f.value == "All" });

        if ((allType.length - data.length) == 1) {
            if (finalAllSelect != null && finalAllSelect == 'All' && allSelected == false) {
                finalSelected = allType;
            } else if (finalAllSelect != null && finalAllSelect == 'All' && allSelected) {
                finalSelected = [];
            } else if (finalAllSelect != null && finalAllSelect != 'All' && hasAll.length == 0) {
                finalSelected = allType;
            } else {
                finalSelected = data.filter(f => { return f.value != "All" });
            }
        } else if (finalAllSelect != null && finalAllSelect == 'All' && allSelected == false) {
            finalSelected = allType;
        }
        setRowFieldValue(finalSelected);

    }

    const getSecondRowFilters = () => {
        let showRowFieldOptions = getArrayParam(rowValueList);
        let showColumnOptions = colValueList.length > 0 ? getArrayParam(colValueList) : [];
         let component = null;
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        try {
            component = (
                <div className="row" style={{ marginLeft: '-13px', padding: '0px' }}>
                    <div className="col-md-12" style={{ display: 'flex', alignItems: 'center' }}>
                        {/** Row filter */}
                        <div style={{ flex: '0 0 12%', padding: '7px' }}>
                            <FormControl variant="outlined" style={{ width: '100%', marginLeft: '10px' }}>
                                <InputLabel id="sf-referralreport-result--simple-select-outlined-label" className="sf-referralreport-result">
                                    {getLocalizedStrings().label.RELATION_REPORT.ROW_FIELD_LABEL}
                                </InputLabel>
                                <Select
                                    labelId="sf-referralreport-result-simple-select-outlined-label"
                                    id="sf-referralreport-result-simple-select-outlined"
                                    value={rowField.label}
                                    size="small"
                                    onChange={(event, value) => handleDropDownChange('row_field', event, value)}
                                    className={"sf-fields-bg sf-referralreport"}
                                    label={getLocalizedStrings().label.RELATION_REPORT.ROW_FIELD_LABEL}
                                >
                                    {generateSelectionOptions('row_field')}
                                </Select>
                            </FormControl>
                        </div>

                        {/** Row filter values */}
                        <div style={{ padding: 0 }}>
                            <div className='no-line userCalendarField' style={{ paddingTop: '4px', marginLeft: '10px' }}>
                                <SuperSelectField
                                    name='rowField'
                                    id='rowField'
                                    key='rowField'
                                    multiple
                                    colorBox={false}
                                    options={showRowFieldOptions}
                                    checkPosition='left'
                                    onChange={(value, name) => onChangeRowField(value, name)}
                                    value={rowFieldValue}
                                    floatingLabelStyle={{ display: 'none' }}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    buttonStyle={{ backgroundColor: '#fff', border: '1px solid #e0e0e0', padding: '1px 8px', borderRadius: '3px', color: '#616161', lineHeight: 2.5 }}
                                    downArrowStyle={{ marginTop: '7px' }}
                                    width={194}
                                    height={43}
                                    innerDivStyle={{ fontSize: '14px' }}
                                    underlinestyle={{ border: '1px solid red' }}
                                    onSelect={(data, name, values) => selectRow(data, name, values)}
                                    showAutocompleteThreshold={0}
                                />
                            </div>
                        </div>

                        {/** Column filter */}
                        <div style={{ flex: '0 0 12%', padding: '7px', paddingTop: '9px' }}>
                            <FormControl variant="outlined" style={{ width: '100%', marginLeft: '23px' }}>
                                <InputLabel id="sfreferralreport-simple-select-outlined-label" className="sfreferralreport">
                                    {getLocalizedStrings().label.RELATION_REPORT.COL_FIELD_LABEL}
                                </InputLabel>
                                <Select
                                    labelId="sfreferralreport-simple-select-outlined-label"
                                    id="sfreferralreport-simple-select-outlined"
                                    value={colField.label}
                                    size="small"
                                    onChange={(event, value) => handleDropDownChange('col_field', event, value)}
                                    className={"sf-fields-bg sf-referralreport"}
                                    label={getLocalizedStrings().label.RELATION_REPORT.COL_FIELD_LABEL}
                                >
                                    {generateSelectionOptions('col_field')}
                                </Select>
                            </FormControl>
                        </div>

                        {/** Column filter values */}
                        <div style={{ flex: '0 0 15%', padding: 0 }}>
                            <div className='no-line userCalendarField' style={{ paddingTop: "4px", marginLeft: '26px' }}>
                                <SuperSelectField
                                    name='colFieldList'
                                    id='colFieldList'
                                    key='colFieldList'
                                    multiple
                                    colorBox={false}
                                    options={showColumnOptions}
                                    checkPosition='left'
                                    onChange={onChangeColFieldList}
                                    value={column}
                                    floatingLabelStyle={{ display: 'none' }}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    buttonStyle={{ backgroundColor: '#fff', border: '1px solid #e0e0e0', padding: '1px 8px', borderRadius: '3px', color: '#616161', lineHeight: 2.5 }}
                                    downArrowStyle={{ marginTop: '7px' }}
                                    width={194}
                                    height={43}
                                    innerDivStyle={{ fontSize: '14px' }}
                                    underlinestyle={{ border: '1px solid red' }}
                                    onSelect={(data,name,value)=>selectColumn(data,name,value)}
                                    showAutocompleteThreshold={0}
                                />
                            </div>
                        </div>

                        {/** Run */}
                        <div style={{ flex: '0 0 10%', padding: 0, marginLeft: '23px' }}>
                            <Button
                                key='run'
                                title={getLocalizedStrings().label.RELATION_REPORT.RUN_BUTTON_LABEL}
                                onClick={(event) => validateRequest('run', event)}
                                style={{ ...styles.primaryButton, marginTop: '10px', verticalAlign: 'middle', borderRadius: '30px 30px 30px 30px' }}
                            >
                                {getLocalizedStrings().label.RELATION_REPORT.RUN_BUTTON_LABEL}
                            </Button>
                        </div>
                    </div>
                </div>
            );


        } catch (error) {
            console.error("Error in 'referralReport -> getSecondRowFilters()'" + error)
        }
        return component;
    }

    const getReportFilter = () => {
        let buttonSet = getButtonSet();
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        let firstRow = getFirstRowFilters();
        let secondRow = getSecondRowFilters();
        return (
            <div>
                {firstRow}
                {secondRow}
            </div>
        )
    }

    const getReportHeaderName = () => {
        let component = null;
        let buttonSet = getButtonSet();
        component = (
            <div style={{ paddingLeft: "8px", marginTop: "-22px" }}>
                <span className="text" style={{ margin: '0px', fontSize: '22px', float: 'left' }}><h3 style={{ fontWeight: 'bold' }}>{title}</h3></span>
                {buttonSet}
            </div>)
          return component;
    }

    const getFieldList = (columnsHeaders) => {
        let object = getStringParam(StateObject);
        let columnHeaderList = [];
        let headers = getArrayParam(columnsHeaders);
        headers.unshift(getStringParam(rowFieldName));
        let fields = getArrayParam(sfForm.data[object].fields).filter(f => {
            if (f.name == getStringParam(rowFieldName)) {
                return f;
            }
        });
        let header = null;
        headers.map((col, i) => {
            let label = col == "row_total" ? "Total" : getStringParam(col);
            header = null;
            if (i == 0) {
                if (fields.length > 0) {
                    label = getStringParam(fields[0].label);
                }
                header = { field_type: "text", is_date: false, is_hidden: false, is_detail_link: false, is_sql_col: false, label: label, name: col }
            }
            else {
                header = { field_type: "integer", is_date: false, is_hidden: false, is_detail_link: true, is_sql_col: false, label: label, name: col, style: { textAlign: 'center' } }
            }
            columnHeaderList.push(header);
        });
        return columnHeaderList;
    }

    const getNoRecordContent = () => {
        return (<table className="layout_maintable" style={{ width: '100%', marginLeft: '10px' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr></table>);
    }

    const customEvent = (eventType, _column, row, event) => {
        try {
            let columnValue = getIntParam(row[_column.name]);
            let columnLabel = '';
            columnValue = getIntParam(columnValue);
            if (isValidParam(columnValue) && columnValue != "" && columnValue > 0) {

                let rowValueType = "";
                let colValueType = "";
                let colValue = getMultiselectValues(getArrayParam(_column), 'column');
                let _relationId = getIntParam(relationId);
                let rowValue = getMultiselectValues(getArrayParam(rowFieldValue), 'rowField');
                let _rowFieldName = getStringParam(rowFieldName);
                let _colFieldName = getStringParam(colFieldName);


                //all salesrep , all col labels
                if (row[_rowFieldName] == "Total" || _column.label == "Total") {

                    if (row[_rowFieldName] == "Total" && _column.label == "Total") {
                        rowValue = getMultiselectValues(getArrayParam(rowFieldValue), 'rowField');
                        colValue = getMultiselectValues(getArrayParam(column), 'column');
                        if (getArrayParam(column).length == getArrayParam(colValueList).length) {
                            colValueType = 'All'
                        }
                        if (getArrayParam(rowFieldValue).length == getArrayParam(rowValueList).length) {
                            rowValueType = 'All'
                        }
                    }
                    else if (row[_rowFieldName] == "Total") {
                        rowValue = getMultiselectValues(getArrayParam(rowFieldValue), 'rowField');
                        colValue = getStringParam(_column.label);
                    }
                    else if (_column.label == "Total") {
                        colValue = getMultiselectValues(getArrayParam(column), 'column');
                        rowValue = getStringParam(row[_rowFieldName]);;
                    }
                }
                //send specific sales rep and column values
                else if (row[_rowFieldName] != "Total" && _column.label != "Total") {
                    colValue = getStringParam(_column.label);
                    rowValue = getStringParam(row[_rowFieldName]);
                }

                let label = getStringParam(getLocalizedStrings().label.RELATION_REPORT.RELATION_REPORT_LABEL);
                let object = getStringParam(StateObject);
                let reportLabel = getStringParam(_column.label);
                let url = '/' + constants.ACCOUNTS_OBJECT + '/queries';
                let activeTab = getObjectParam(getActiveTab());
                let activeTabIndex = getIntParam(activeTab.index);
                let tab = { label: label, object: object, type: TYPE_LIST_VIEW, url: url };
                let tabInfo = {};
                tabInfo.isReport = true;
                tabInfo.reportLabel = reportLabel;
                tabInfo.filter = { id: 542, name: 'All records', type: constants.SEARCH_QUERY_TYPE_ALL };
                tabInfo.input_param = {
                    object: getStringParam(StateObject),
                    date_field: getStringParam(dateFieldName),
                    due_by: getStringParam(dueBy),
                    row_field: _rowFieldName,
                    row_value: rowValue,
                    row_value_type: rowValueType,
                    col_value: colValue,
                    col_field: _colFieldName,
                    col_value_type: colValueType,
                    relation_id: _relationId,
                    is_report: true,
                    report_name: constants.RELATION_REPORT,
                    report_type: RELATION_REPORT_MAP.TYPE_RELATION_REPORT,
                    report_click_from: 'DRILLDOWN'
                };
                if (tabInfo.input_param.due_by == 'cust') {
                    tabInfo.input_param.from_date = formatDate(from_date);
                    tabInfo.input_param.to_date = formatDate(to_date);
                }
                tab.info = tabInfo;
                addTab(tab, true);
            }
        } catch (error) {
            console.error("Error in 'referralReport.js -> customEvent()':" + error);
        }
    }

    const onChangeColFieldList = (value, name) => {
        setColumn(value);
    }

    const onChangeRowField = (value, name) => {
        setRowFieldValue(value);
    }

    const generateSelectionOptions = (fieldName) => {
        try {
            let options = [];
            if (fieldName === 'relation') {
                options = getArrayParam(relationList);
            } else if (fieldName === 'date_field') {
                options = getArrayParam(dateFieldsList);
            } else if (fieldName === 'row_field') {
                options = getArrayParam(rowFieldList);
            } else if (fieldName === 'col_field') {
                options = getArrayParam(colFieldsList);
            }
            if (fieldName === 'relation') {
                return options.map((item, i) => (
                    <MenuItem key={item.id} value={item.relation_name} style={styles.popoverMenuItem}>
                        {item.relation_name}
                    </MenuItem>
                ));
            } else {
                return options.map((item, i) => (
                    <MenuItem key={item.name} value={item.label} style={styles.popoverMenuItem}>
                        {item.label}
                    </MenuItem>
                ));
            }
        }
        catch (error) {
            console.error("Error in 'referralReport.js -> generateSelectionOptions()':" + error);
        }
    }

    const getFiltersByPropsAndDefaultState = () => {
        let fields = null;
        try {
            let info = getActiveTabInfo();
            let _object = getStringParam(StateObject);
            let tempdateFieldName = getStringParam(dateFieldName).length > 0 ? dateFieldName : "created_on";
            let temprowFieldName = getStringParam(rowFieldName).length > 0 ? rowFieldName : "sales_rep";
            let tempcolFieldName = getStringParam(colFieldName).length > 0 ? colFieldName : "t_status";
            let temptitle = getStringParam(title).length > 0 ? title : getLocalizedStrings().label.RELATION_REPORT.RELATION_REPORT_LABEL;
            let tempDateField = null;
            let tempRowField = null;
            let tempColField = null;
            let _dateFieldsList = getArrayParam(dateFieldsList);
            let _rowFieldsList = getArrayParam(rowFieldList);
            let _colFieldsList = getArrayParam(colFieldsList);
            setTitle(temptitle);
            
            if (isValidParam(info) && (info.hasOwnProperty("due_by") || props.type === constants.REPORT_TYPE_CUSTOM_RELATION)) { }
            else {
                setDateRangeValue(dueBy);
            }

            if (_object === constants.ACCOUNTS_OBJECT) {
                fields = getArrayParam(sfForm.data[constants.ACCOUNTS_OBJECT].fields);
                if (fields !== null && fields.length > 0) {
                    _dateFieldsList = fields.filter(f => {
                        if ((f.field_type === constants.FIELD_TYPE_DATE_TIME || f.field_type === constants.FIELD_TYPE_DATE) && !f.field_is_read_only) {
                            return f;
                        }
                    });

                    _rowFieldsList = fields.filter(f => {
                        if (f.field_type === constants.FIELD_TYPE_LIST && (f.list_type === constants.LIST_FIELD_TYPE_USER || f.list_type === constants.LIST_FIELD_TYPE_REGULAR || f.list_type === constants.LIST_FIELD_TYPE_EXTERNAL) && !f.field_is_read_only
                            && f.name !== 'workflow_name' && f.name !== 'country' && f.name !== 'state') {
                            return f;
                        }
                    });

                    _colFieldsList = fields.filter(f => {
                        if (f.field_type === constants.FIELD_TYPE_LIST && (f.list_type === constants.LIST_FIELD_TYPE_USER || f.list_type === constants.LIST_FIELD_TYPE_REGULAR || f.list_type === constants.LIST_FIELD_TYPE_EXTERNAL) && !f.field_is_read_only
                            && f.name !== 'workflow_name' && f.name !== 'country' && f.name !== 'state') {
                            return f;
                        }
                    });
                }

                let dateFieldArr = fields.filter(f => {
                    if (f.name === tempdateFieldName) {
                        return f;
                    }
                });
                if (dateFieldArr.length > 0) {
                    tempDateField = dateFieldArr[0];
                }

                let rowFieldArr = fields.filter(f => {
                    if (f.name === temprowFieldName) {
                        return f;
                    }
                });
                if (rowFieldArr.length > 0) {
                    tempRowField = rowFieldArr[0];
                }

                let colFieldArr = fields.filter(f => {
                    if (f.name === tempcolFieldName) {
                        return f;
                    }
                });
                if (colFieldArr.length > 0) {
                    tempColField = colFieldArr[0];
                }
                let tempRowValueList = getRowFilterValueList(tempRowField);
                setRowValueList(tempRowValueList);
                //if no option is selected , then set all options as user's input
                if (props.type !== constants.REPORT_TYPE_CUSTOM_RELATION) {
                    setRowFieldValue(tempRowValueList);
                }
                setDateField(tempDateField);
                if (getStringParam(tempdateFieldName).length === 0) {
                    setDateFieldName(tempdateFieldName);
                }
                setRowField(tempRowField);
                if (getStringParam(temprowFieldName).length === 0) {
                    setRowFieldName(temprowFieldName);
                }
                setRowFieldList([..._rowFieldsList]);
                setColField(tempColField);
                if (getStringParam(tempcolFieldName).length === 0) {
                    setColFieldName(tempcolFieldName);
                }
                setColFieldsList([..._colFieldsList]);
                setDateFieldsList([..._dateFieldsList]);
                setRowFieldName(temprowFieldName);
                setColFieldName(tempcolFieldName);
                //setDateFieldName(tempdateFieldName);  // creating problems when tab changes or rerendering the components. It changes the date field
             }

        } catch (error) {
            console.error("Error in 'referralReport -> getFiltersByPropsAndDefaultState() '" + error)
        }
    }


    const setDateRangeValue = (value) => {
        try {
            let _from_date = from_date;
            let _to_date = to_date;
            let tempDueBy = getStringParam(value);
            if (tempDueBy === "cust" || tempDueBy === "td") {
                _from_date = new Date();
                _to_date = new Date();
            }
            else if (tempDueBy === "yd") {
                let date = new Date();
                _from_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
                _to_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
            }
            else if (tempDueBy === 'tw') {
                _from_date = moment().startOf('week').toDate();
                _to_date = moment().endOf('week').toDate();
            }
            else if (tempDueBy === 'tm') {
                _from_date = moment().startOf('month').toDate();
                _to_date = moment().endOf('month').toDate();
            }
            else if (tempDueBy === 'tq') {
                _from_date = moment().startOf('quarter').toDate();
                _to_date = moment().endOf('quarter').toDate();
            }
            else if (tempDueBy === 'ty') {
                _from_date = moment().startOf('year').toDate();
                _to_date = moment().endOf('year').toDate();
            }
            else if (tempDueBy === 'lw') {
                _from_date = moment().subtract(1, 'week').startOf('week').toDate();
                _to_date = moment().subtract(1, 'week').endOf('week').toDate();
            }
            else if (tempDueBy === 'lm') {
                _from_date = moment().subtract(1, 'months').startOf('month').toDate();
                _to_date = moment().subtract(1, 'months').endOf('month').toDate();
            }
            else if (tempDueBy === 'lq') {
                _from_date = moment().subtract(1, 'quarter').startOf('quarter').toDate();
                _to_date = moment().subtract(1, 'quarter').endOf('quarter').toDate();
            }
            else if (tempDueBy === 'ly') {
                _from_date = moment().subtract(1, 'year').startOf('year').toDate();
                _to_date = moment().subtract(1, 'year').endOf('year').toDate();
            }
            setFrom_date(_from_date);
            setTo_date(_to_date);
        } catch (error) {
            console.error("Error in 'referralReport -> setDateRangeValue() '" + error)
        }
    }


    const setReportTitle = (reportLabel, callFrom) => {
        try {
            let label = getLocalizedStrings().label.RELATION_REPORT.RELATION_REPORT_LABEL;
            if (getStringParam(reportLabel).length > 0) {
                label = reportLabel;
            }
           dispatch(setHeader(constants.RELATION_REPORT, label, null, false, true));
            if (callFrom === 'save_report') {
                setTitle(label);
            }
        }
        catch (error) {
            console.error("Error in 'referralReport -> setReportTitle() '" + error);
        }
    }

    let headerComponent = null;
    let reportHeaderName = null;
    let _records = null;
    let style = { margin: 10, width: '98%', paddingTop: 0, paddingLeft: 11 };
    style = { ...style, ...getObjectParam(style) };
    let noRecordsTitle = getNoRecordContent();
    if (isMounted) {
        headerComponent = getReportFilter();
        reportHeaderName = getReportHeaderName();
        _records = getArrayParam(records);
    }
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return (
        <div id="referralReportDiv" style={style}>
            {headerComponent}

            <div>{reportHeaderName}</div>
            {isMounted && showLoader?
                <div id="divcontents">
                    {_records.length > 0 ?
                        <div><SFDataTable columns={fields} rowSort={rowSort} rows={_records} customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)} /></div>
                        :
                        noRecordsTitle
                    }
                </div>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>);
}

export default ReferralReport;