import React, { useState, useEffect } from "react";
import * as SFFormActions from "../../../services/actions/sfFormActions";
import * as ParameterVerifier from "../../../services/helper/parameterVerifier";
import {
	isValidParam,
	getStringParam,
	getObjectParam,
	getIntParam,
	getBooleanParam,
	makeValidAppUrl,
} from "../../../services/helper/parameterVerifier";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { refreshListView } from "../../../services/actions/listViewAcions";
import { getAppDrawer, } from "../../../services/actions/appContainerActions";
import {
	getTasksRefresh, getTasksPortal
} from "../../../services/actions/taskActions";
import {
	constants,
	TABLEID_OBJECT_MAP,
} from "../../../services/constants/constants";
import ShowCircularProgress from "../components/circularProgress";
import { hasAccessPermission } from "../../../services/helper/common";
import { styles } from "../../../services/constants/styles";
import {
	FormControl,
	TextField,
	Button,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
	fabClasses,
} from "@mui/material";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import PopOver from "./PopOver";
import {
	dateTimeFormat,
	dateTimeFormatFlatPicker,
	dateFormat,
} from "../../../services/helper/utils";
import Autocomplete from "@mui/material/Autocomplete";
import { OBJECT_TABLEID_MAP, } from "../../../services/constants/constants";
import { getDetailViewTaskAppointment, refreshDetailViewData, refreshDetailViewListViewData } from "../../../services/actions/detailViewActions";

import {
	addTab,
	getActiveTab,
	TYPE_COLUMN_VIEW,
	TYPE_LIST_VIEW,
	TYPE_DETAIL_VIEW,
	getActiveTabInfo,
	updateActiveTab
} from "../../../services/helper/sfTabManager";
import { Navigate } from "react-router-dom";
import moment from "moment";
import * as sfDialogs from "../components/sfDialogs";
import { refreshColumnView } from "../../../services/actions/listsActions";
import { phoneFormat } from "../../../services/helper/utils";
import { useDispatch, useSelector } from "react-redux";
import * as HTTPClient from "../../../services/helper/httpClient";
import { isObjectChildExists } from "../../../services/helper/common";
import {  getArrayParam} from '../../../services/helper/parameterVerifier';

import { endPoints } from "../../../services/constants/endPoints";
import {
	detailViewIsMounted,
} from "../../../services/actions/detailViewActions";
const MiniEdit = ({ object, parentDetails, recordId, data }) => {
	const isConvert = false;
	const isNotifySalesRep = false;
	const dispatch = useDispatch();
	const sfForm = useSelector((state) => state.sfForm);
	const app = useSelector((state) => state.app);
	const [editableFieldsList, setEditableFieldsList] = useState([]);
	const [recordData, setRecordData] = useState(null);
	const [isLoadded, setIsLoadded] = useState(false);
	const [redirectUrl, setredirectUrl] = useState("");
	const [redirect, setredirect] = useState(false);
	const [searchableFieldProps, setsearchableFieldProps] = useState(null);
	const [taskNameField, setTestNameField] = useState('');
	const appContainer = useSelector((state) => state.appContainer);
	const {my_clientid} = useSelector((state) => state.childListView.data);
	const userlist = useSelector((state) => state.userlist);
	const [dummyData,setDummyData] = useState(false);

	const detailViewTaskAppintmentReducer = useSelector(
		(state) => state.detailViewTaskAppintmentReducer
	);
	const detailView = useSelector((state) => state.detailView);

	useEffect(() => {
		getFieldsList();
		setDummyData(true)
	}, [userlist,dummyData]);

	const getFieldsList = () => {
		let _fieldsList = [];
		if (object === constants.TASKS) {
			_fieldsList = [
				"t_name",
				"t_status",
				"t_dueby",
				"t_owner",
				"t_description",
			];
		}
		getFormData(_fieldsList);
	};

	const getFormData = (_fieldsList) => {
		if (!data.isUnitsAccount) {
			if (sfForm.data[object] == undefined) {
				var promise = SFFormActions.getSFFormFieldsPromise(object);
				promise.then((response) => {
					if (isValidParam(response)) {
						let data = JSON.parse(JSON.stringify(getObjectParam(response)))
						sfForm.data[object] = data;
						getAllFields(data, _fieldsList);
					}
				});
			}
			else {
				getAllFields(sfForm.data[object], _fieldsList);
			}
		}
		else if(data.isUnitsAccount && app.me.is_franchisor == true){
			if (sfForm.data[object] == undefined) {
				var promise = SFFormActions.getSFFormFieldsPromise(object);
				promise.then((response) => {
					if (isValidParam(response)) {
						let data = JSON.parse(JSON.stringify(getObjectParam(response)))
						sfForm.data[object] = data;
						getAllFields(data, _fieldsList);
					}
				});
			}
			else {
				getAllFields(sfForm.data[object], _fieldsList);
			}
		}
		else {
			let tab = getActiveTab();
			if (data.isUnitsAccount) {
				tab.info = { ...tab.info, isUnitsAccount: data.isUnitsAccount, UnitsAccountProjectId: data.UnitsAccountProjectId };
				updateActiveTab(tab);
			}
			var promise = SFFormActions.getSFFormFieldsPromise(object);
			promise.then((response) => {
				if (isValidParam(response)) {
					let data = JSON.parse(JSON.stringify(getObjectParam(response)));
					getAllFields(data, _fieldsList);
					let info = tab.info;
					if (object === constants.TASKS && isValidParam(info) && info.hasOwnProperty("isUnitsAccount") && info.hasOwnProperty("UnitsAccountProjectId") && info.isUnitsAccount) {
						delete info.isUnitsAccount;
						delete info.UnitsAccountProjectId;
					}
				}
			});
		}

	}
	const getAllFields = (data, _fieldsList) => {
		getRecord(data?.fields, _fieldsList);
	};

	const getRecord = (_allFields, _fieldsList) => {
		let parentObject = getStringParam(parentDetails.parent_object).trim();
		let parentRecordId = getIntParam(parentDetails.parent_record_id);
		let params = {};
		params.object = object;
		params.parent_object = parentObject;
		params.parent_record_id = parentRecordId;
		params.isUnitsAccount = data.isUnitsAccount;
		params.UnitsAccountProjectId = data.UnitsAccountProjectId ? data.UnitsAccountProjectId : my_clientid;
		
		let promise = SFFormActions.getRecordPromise(object, recordId, params);
		promise.then((response) => {
			setRecordData(response.records);
			prepareEditableFieldsList(response.records, _allFields, _fieldsList);
		});
	};

	
	const prepareEditableFieldsList = (_recordData, _allFields, _fieldsList) => {
		let _editableFields = [...editableFieldsList];
		_fieldsList.forEach((field) => {
			_allFields?.forEach((fieldObj, index) => {
				if (fieldObj.name === field) {
					let tempObj = fieldObj;
					tempObj['value'] = _recordData[field]
					_editableFields.push(tempObj);
				}
			});
		});
		setEditableFieldsList([..._editableFields]);
		setIsLoadded(true);
	};



	const processFormData = (_editableFieldsList) => {
		let component = (
			<div
				key={"sfas-cointainer-from"}
				style={{ marginLeft: "22px", marginRight: "22px" }}
			>
				<div style={{ width: "100%", display: "inline-table" }}>
					<div
						style={{
							float: "right",
							marginTop: "10px",
							paddingBottom: "15px",
							display: "flex",
							marginRight: "-3px",
						}}
					>
						{getActionButtons()}
					</div>
				</div>
				<div
					className="sfas-cointainer-from-class"
					id="sfas-cointainer-from-id"
				>
					{getFromRows(_editableFieldsList)}
				</div>
				<div
					style={{ float: "right", marginTop: "10px", paddingBottom: "30px" }}
				>
					{getActionButtons()}
				</div>
			</div>
		);
		return component;
	};

	const getFromRows = (_editableFieldsList) => {
		let fieldRows = [];
		let keyCount = 0;
		if (isValidParam(_editableFieldsList)) {
			_editableFieldsList.forEach((item, index) => {
				let component = getTypeWiseFormField(item, item.name, index);
				fieldRows.push(
					<div
						id={"row_" + keyCount}
						key={"1_" + item.name}
						style={{ ...styles.row, padding: 0 }}
					>
						<div key={"component_" + keyCount} style={{ ...styles.sf_12 }}>
							{component}
						</div>
					</div>
				);
			});
		}
		return fieldRows;
	};
	const setMultiSelectValue = (event, fieldName, positionIndex) => {
		try {
			let value = event.target.value;
			let stringValue = "";
			if (value.length > 0) {
				for (let j = 0; j < value.length; j++) {
					if (j < value.length - 1) {
						stringValue += value[j] + ",";
					} else {
						stringValue += value[j];
					}
				}
			}
			let tempsearchableFieldProps = searchableFieldProps;
			tempsearchableFieldProps[positionIndex].value = stringValue;
			tempsearchableFieldProps[positionIndex].selectedListValues = value;
			tempsearchableFieldProps[positionIndex].helperText = "";
			setsearchableFieldProps(tempsearchableFieldProps);
		} catch (error) {
			console.error("Error in 'miniEdit.js -> setMultiSelectValue()':" + error);
		}
	};

	const setFieldValueOnBlur = (fieldName, event, value) => {
		let val = event.target.value;
		setTestNameField(val);
	}

	const getTypeWiseFormField = (field, key, index) => {
		let component = null;
		let autoCompleteValue = "";
		if (object === constants.OPPORTUNITIES_OBJECT) {
			autoCompleteValue = "off";
		} else {
			autoCompleteValue = "new-password";
		}
		let memoFieldRowSize = 3;
		if (object === constants.NOTE_OBJECT) {
			memoFieldRowSize = 15;
		}
		if (field.is_hidden_field !== true) {
			component = null;
console.log("field.name",field.name)
			if (
				field.field_type === constants.FIELD_TYPE_TEXT ||
				(field.field_type === constants.FIELD_TYPE_INTEGER &&
					field.name !== "workflow_id") ||
				field.field_type === constants.FIELD_TYPE_FLOAT ||
				field.field_type === constants.FIELD_TYPE_URL ||
				field.field_type === constants.FIELD_TYPE_MEMO
			) {
				component = (
					<FormControl
						style={{ width: "100%" }}
						className="test"
						noValidate
						autoComplete="off"
					>
						<TextField
							variant="outlined"
							key={field.id}
							inputstyle={styles.textField.input}
							disabled={field.is_readonly}
							multiline={
								field.field_type === constants.FIELD_TYPE_MEMO ? true : false
							}
							rows={memoFieldRowSize}
							InputLabelProps={{
								style: {
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									overflow: "hidden",
									width: "96%",
								},
							}}
							fullWidth={true}
							name={field.name}
							value={field.value}
							label={field.label}
							onChange={(e) =>
								setFieldValue(e, index, field.name, field.field_type)
							}
							className={field.is_lookup_field ? "form-field-search-icon" : ""}
							inputProps={{ maxLength: field.name =='t_description' ? 512 : field.field_length }}
							// ref={'field-ref-' + field.name}
							autoComplete={autoCompleteValue}
							margin="dense"
							size="small"
						/>
					</FormControl>
				);
			} else if (field.field_type === constants.FIELD_TYPE_LIST) {
				if (
					field.list_type === constants.LIST_FIELD_TYPE_REGULAR ||
					field.list_type === constants.LIST_FIELD_TYPE_USER ||
					field.list_type === constants.LIST_FIELD_TYPE_EXTERNAL
				) {
					let listValueMenuItems = isValidParam(field.list_values)
						? field.list_values
						: [];
					let autocompleteValue =
						field !== null && field !== undefined && data.isUnitsAccount && field.name !== 't_status' && field?.default_value != "" ? field?.default_value :field == null && field == undefined ? null : field?.value ;
				  if(field.name === "t_owner" && data.isUnitsAccount && field?.value !== ""){
					autocompleteValue = field.value;
					let temp={}
					temp.id =  field.value
					temp.value =  field.value
					listValueMenuItems.push(temp)
				  }
				  if(field.name === "t_owner" && field?.value !== ""){
					autocompleteValue = field.value;
					let temp={}
					if( data?.isUnitownerTasksVal){
						listValueMenuItems = []
						temp.id =  field.value
						temp.value =  field.value
						listValueMenuItems.push(temp)
					}else{
						let userListVal = getArrayParam(userlist?.data);
									if(userListVal.length > 0)	{
										listValueMenuItems =[];
										userListVal.map((m)=>{
											listValueMenuItems.push({id:m.value , value:m.value})
										})
									}
					}
				  }
				  
						if (field.name === 't_name') {
							listValueMenuItems =ParameterVerifier.getArrayParam(listValueMenuItems).filter((f)=>f.value !='')
						component = (
							<FormControl style={{ width: "100%" }}>

								<Autocomplete
									value={taskNameField !== '' ? taskNameField : autocompleteValue}
									onChange={(event, value) =>
										setFieldValue(
											event,
											index,
											field.name,
											field.field_type,
											value
										)
									}
									filterOptions={(options, params) => {

										const { inputValue } = params;
										let filtered = options;
										if (inputValue !== '') {
											filtered = options.filter(function (el) {
												return el.value.includes(inputValue);
											});
										}

										// Suggest the creation of a new value
										const isExisting = options.some((option) => inputValue === option.value);
										// if (inputValue !== '' && !isExisting) {
										// 	filtered.push({
										// 		inputValue,
										// 		value: `Add "${inputValue}"`,
										// 	});
										// }

										return filtered;
									}}
									onBlur={(event, newValue) =>
										setFieldValueOnBlur("t_name", event, newValue)
									}
									selectOnFocus
									clearOnBlur
									handleHomeEndKeys
									id="free-solo-with-text-demo"
									options={listValueMenuItems}
									getOptionLabel={(option) => {
										// Value selected with enter, right from the input
										if (typeof option === 'string') {
											return option;
										}
										// Add "xxx" option created dynamically
										if (option.inputValue) {
											return option.inputValue;
										}
										// Regular option
										return option.value;
									}}
									renderOption={(props, option) => <li {...props}>{option.value}</li>}
									style={{ width: "100%" }}

									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label={field.label}
											inputprops={{
												maxlength: field.field_length,
												...params.InputProps,
											}}
											disabled={field.is_readonly}
											fullWidth={true}
											margin="dense"
											size="small"
											className={"sf-fields-bg"}
											InputLabelProps={{
												style: {
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
													width: "90%",
												},
											}}
										/>
									)}
								/>
							</FormControl>
						);

					} else {
						component = (
							<FormControl style={{ width: "100%" }}>
								<Autocomplete
									style={{ width: "100%" }}
									disableClearable
									options={listValueMenuItems}
									getOptionLabel={(option) => (option.value ? option.value : "")}
									value={ 
										listValueMenuItems.find(
										(v) => v.value === autocompleteValue
									
										)}
									disabled={field.is_readonly}
									id={"adv-sf-auto-complete-id-" + field.name}
									name={"adv-sf-auto-complete-name-" + field.name}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label={field.label}
											inputprops={{
												maxlength: field.field_length,
												...params.InputProps,
											}}
											disabled={field.is_readonly}
											fullWidth={true}
											margin="dense"
											size="small"
											className={"sf-fields-bg"}
											InputLabelProps={{
												style: {
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
													width: "90%",
												},
											}}
										/>
									)}
									onChange={(event, value) =>
										setFieldValue(
											event,
											index,
											field.name,
											field.field_type,
											value
										)
									}
								/>
							</FormControl>
						);
					}

				} else if (field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
					let listValueMenuItems = isValidParam(field.list_values)
						? field.list_values
						: [];
					component = (
						<FormControl style={{ width: "100%" }} variant="outlined">
							<Select
								key={field.id}
								id={"id" + field.id}
								multiple
								value={field.selectedListValues}
								label={field.label}
								onChange={(e) => setMultiSelectValue(e, field.name, index)}
								disabled={field.is_readonly}
								className={"sf-fields-bg"}
								style={{ height: "38px", width: "100%", margin: "8 0 8 0" }}
								renderValue={(selected) => selected.join(", ")}
							>
								{listValueMenuItems.forEach((listValue, listValueIndex) => {
									return (
										<MenuItem
											id={listValue.id}
											key={listValue.id}
											value={listValue.value}
											title={listValue.value}
										>
											<Checkbox
												checked={
													field.selectedListValues &&
														field.selectedListValues.indexOf(listValue.value) > -1
														? true
														: false
												}
											/>
											<ListItemText primary={listValue.value} />
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					);
				}
			} else if (
				field.field_type === constants.FIELD_TYPE_DATE_TIME ||
				field.field_type === constants.FIELD_TYPE_DATE
			) {
				let HHFormat = new RegExp("HH");
				let datePickerOptions = {
					enableTime: true,
					noCalendar: false,
					dateFormat: dateTimeFormatFlatPicker[app.me.date_format],
					minuteIncrement: 1,
					static: true,
					time_24hr: HHFormat.test(app.me.date_format) ? true : false,
				};
				let openedPickr = false;
				if (object === constants.TASKS) {
					openedPickr = true;
				}
				component = (
					<div>
						<PopOver
							id={field.name}
							key={field.name}
							name={field.name}
							btnType={
								field.field_type === constants.FIELD_TYPE_DATE_TIME
									? "dateTime"
									: "date"
							}
							openedPickr={openedPickr}
							buttonStyle={{ fontSize: "16px" }}
							containerStyle={{ lineHeight: 1, marginTop: "5px" }}
							buttonLabel={getLocalizedStrings().label.CALENDAR.DATE}
							onChange={(date, fieldName) =>
								handleFlatpkrDateChange(
									date,
									field.name,
									field.field_type,
									index
								)
							}
							value={field.value}
							innerLabelStyle={{ padding: "8 5 2 5", fontSize: "15px" }}
							height={40}
							options={datePickerOptions}
						/>
					</div>
				);
			}

			return (
				<div
					key={field.name}
					style={{
						padding: "0 5 0 5",
						paddingTop:
							field.name === "t_dueby" || field.name === "t_owner" ? "4px" : "",
					}}
				>
					{component}
				</div>
			);
		}
		return component;
	};
	const setTaskNameDescription = (value, tempFieldProps) => {
		let params = {
			"t_name": value,
		};

		var response = Promise.resolve(
			HTTPClient.post(endPoints.TASKS_INFO.GET_DESCRIPTION, params)
		);
		response.then((response) => {
			let Index = tempFieldProps.findIndex(v => v.name === 't_description');
			tempFieldProps[Index].value = response.data.t_description;
			setEditableFieldsList([...tempFieldProps])
		});
	}
	const setFieldValue = (event, positionIndex, fieldName, fieldType, value) => {
		if (
			fieldType === constants.FIELD_TYPE_TEXT ||
			fieldType === constants.FIELD_TYPE_FLOAT ||
			fieldType === constants.FIELD_TYPE_MEMO ||
			fieldType === constants.FIELD_TYPE_URL ||
			fieldType === constants.FIELD_TYPE_INTEGER
		) {
			value = event.target.value;
		}
		if (
			fieldType === constants.FIELD_TYPE_TEXT ||
			fieldType === constants.FIELD_TYPE_INTEGER ||
			fieldType === constants.FIELD_TYPE_FLOAT ||
			fieldType === constants.FIELD_TYPE_MEMO
		) {
			editableFieldsList[positionIndex].value = value;
		} else if (fieldType === constants.FIELD_TYPE_LIST) {

			if (fieldName === "t_name") {
				let tempvalue = null;
				if (value !== null) {
					tempvalue = value.inputValue !== undefined ? value.inputValue : value.value;
					editableFieldsList[positionIndex].value = tempvalue;
					if (value.inputValue === undefined) {
						setTaskNameDescription(tempvalue, editableFieldsList)
					}
				} else {
					tempvalue = value;
					editableFieldsList[positionIndex].value = tempvalue;
				}
			} else {
				editableFieldsList[positionIndex].value = isValidParam(value)
					? value.value
					: "";
			}
		} else {
			editableFieldsList[positionIndex].value = value;
		}

		let isPhoneField = getBooleanParam(
			editableFieldsList[positionIndex].isPhoneField
		);
		if (isPhoneField) {
			editableFieldsList[positionIndex].value = phoneFormat(
				editableFieldsList[positionIndex].value
			);
		}

		editableFieldsList[positionIndex].helperText = "";
		setEditableFieldsList([...editableFieldsList])
	};
	const handleFlatpkrDateChange = (date, fieldName, fieldType, index) => {
		try {
			if (isValidParam(date)) {
				let selectedDate = new Date(date);
				let dateValue = new Date(
					selectedDate.getFullYear(),
					selectedDate.getMonth(),
					selectedDate.getDate(),
					selectedDate.getHours(),
					selectedDate.getMinutes()
				);
				const momentDate = moment(dateValue);
				if (moment !== null && moment !== "") {
					if (fieldType === constants.FIELD_TYPE_DATE) {
						date = momentDate.format(dateFormat[app.me.date_format]);
					} else if (fieldType === constants.FIELD_TYPE_DATE_TIME) {
						date = momentDate.format(dateTimeFormat[app.me.date_format]);
					}
					if (object === constants.TASKS) {
						editableFieldsList[index].value = date;
					}
				} else {
					date = "";
				}
			} else {
				date = "";
			}

			editableFieldsList[index].helperText = "";
		} catch (error) {
			console.error(
				"Error in 'miniEdit.js -> handleFlatpkrDateChange()':" + error
			);
		}
		// this.setState({ fieldProps: this.state.fieldProps });
	};
	const getActionButtons = () => {
		let buttons = [];
		buttons.push(
			<Button
				key="search"
				onClick={() => saveData()}
				style={{
					...styles.primaryButton,
					marginRight: "6px",
					verticalAlign: "top",
				}}
				disabled={(data.isUnitownerTasksVal && data?.task_type !== "Corrective Action") || (!app.me.is_franchisee && data?.task_type === "Corrective Action")}

			>
				{getLocalizedStrings().label.COMMON.SAVE}
			</Button>
		);

		buttons.push(
			<Button
				key="edit"
				onClick={() => triggerAction(constants.EDIT)}
				style={{
					...styles.secondaryButton,
					marginRight: "6px",
					verticalAlign: "top",
				}}
			>
				{getLocalizedStrings().label.COMMON.EXPAND}
			</Button>
		);

		buttons.push(
			<Button
				key="clear"
				onClick={() =>
					openTaskDetailView(object, recordId, recordData["t_name"])
				}
				style={{
					...styles.secondaryButton,
					marginRight: "6px",
					verticalAlign: "top",
				}}
			>
				{" "}
				DETAILS
			</Button>
		);
		buttons.push(
			<Button
				onClick={() => closeDrawer()}
				style={{
					...styles.secondaryButton,
					marginRight: "6px",
					verticalAlign: "top",
				}}
			>
				{getLocalizedStrings().label.COMMON.CANCEL}
			</Button>
		);
		return buttons;
	};

	const triggerAction = (value) => {
		let isOpenDrawer = false;
		let _data = null;
		let permissionMessage = null;
		let tabInfo = null;
		let isInXpress = false;
		let isUnitsAccount = false;
		try {
			permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
			tabInfo = getActiveTabInfo();
			isInXpress = getBooleanParam(tabInfo.isInXpress);
			if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
				isUnitsAccount = tabInfo.isUnitsAccount;
			}
			if (!isInXpress) {
				if (
					ParameterVerifier.isValidParam(object) &&
					ParameterVerifier.isValidParam(value)
				) {
					if (value === constants.EDIT) {
						let hasPermission = false;
						isOpenDrawer = false;
						hasPermission = hasAccessPermission(
							object,
							constants.SECURITY_LEVEL_TYPE_ACCESS,
							constants.ACCESS_TYPE_EDIT
						);
						if (hasPermission) {
							_data = {
								object: object,
								id: recordId,
								type: 'edit',
								mode: constants.SF_FORM_OPEN_MODE_EXPANDED,
								isDetailView: false,
								isUnitownerTasks: data.isUnitownerTasksVal,
								isUnitsAccount: data.isUnitsAccount,
								UnitsAccountProjectId: data.UnitsAccountProjectId ? data.UnitsAccountProjectId : my_clientid,
								parent_object: appContainer.drawerProps.data.parent_object,
								parent_record_id: appContainer.drawerProps.data.parent_record_id,
								parentRecordDetails: recordData
							};
							isOpenDrawer = true;
							if (isOpenDrawer) {
								dispatch(
									getAppDrawer(true, "Edit Tasks", value, _data, constants.OTHER)
								);
							}
						} else {
							sfDialogs.alert(
								getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
								getLocalizedStrings().message.COMMON.ACCESS_DENIED,
								null
							);
						}
					}
				}
			} else {
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					permissionMessage,
					null
				);
			}
		} catch (error) {
			console.error(
				"Error in 'detailViewHeader.js -> handleAction()':" + error
			);
		}
	};

	const saveData = () => {
		let validityObj = getFormValidity();
		if (validityObj.isValidParam) {
			let parentObject = getStringParam(parentDetails.parent_object).trim();
			let parentRecordId = getIntParam(parentDetails.parent_record_id);
			let link_info = recordData.link_info;
			let params = {};
			params.id = recordId;
			params.parent_object = parentObject;
			params.parent_record_id = parentRecordId;
			params.is_convert = isConvert;
			params.is_notify_sales_rep = isNotifySalesRep;
			params.is_unit_owner = recordData["t_unit_owner"] && recordData["t_unit_owner"] == 1 ? true : false;
			
			params.link_info =
				isValidParam(link_info) && link_info !== null ? link_info : "";
			let lookUpArr = [];
			if (
				isValidParam(link_info) &&
				link_info !== null &&
				link_info !== "" &&
				link_info.indexOf(",") > -1
			) {
				lookUpArr = link_info.split(",");
			} else if (
				link_info === undefined ||
				link_info === null ||
				link_info === ""
			) {
				lookUpArr = [];
			} else {
				lookUpArr.push(link_info);
			}
			let lookup_info = [];
			lookUpArr.forEach((e) => {
				let tempObj = {};
				if (e !== null && e.indexOf("_") > -1) {
					tempObj.lookup_object_name = TABLEID_OBJECT_MAP[e.split("_")[0]];
					tempObj.record_id = e.split("_")[1];
					lookup_info.push(tempObj);
				}
			});
			params.lookup_info = lookup_info;
			params.fields = [];
			//params.type = type;
			let tempEditableFields = editableFieldsList;
			tempEditableFields.forEach((fld, i) => {
				let fldObj = {};
				fldObj.id = fld.id;
				fldObj.name = fld.name;
				let value = fld.value;
				if (fld.name == 't_name' && taskNameField !== '') {
					value = taskNameField;
				}
				if (
					fld.field_type === constants.FIELD_TYPE_DATE ||
					fld.field_type === constants.FIELD_TYPE_DATE_TIME
				) {
					let date_format =
						fld.field_type === constants.FIELD_TYPE_DATE_TIME
							? constants.INPUT_DATE_TIME_FORMAT_24
							: constants.INPUT_DATE_FORMAT;
					// value=moment(getStringParam(fld.value)).format(dateFormat[date_format]);
					value = moment(fld.value.toString()).format(date_format);
				}
				fldObj.value = value;
				params.fields.push(fldObj);
			});

			let isCancelled = false;
			// Find the object with name "t_status"
			const statusObject = tempEditableFields.find(item => item.name === "t_status");
			// Check if its value is "Cancelled"
			if(app.me.is_franchisee && (data.tType === "Corrective Action" || data.task_type ===  "Corrective Action")){
				isCancelled = statusObject && statusObject.value === "Cancelled";
			}
			if (!isCancelled) {
				let promise = SFFormActions.saveRecordPromise(object, params);

				promise.then((response) => {
					if (isValidParam(response) && response.hasOwnProperty("data")) {
						if (response.status === 0) {
							let activeTab = getActiveTab();
							let parentTableIds = "";

							dispatch(getAppDrawer(false, null, null, null, null));
							dispatch(
								showCustomSnackBar(
									getLocalizedStrings().message.COMMON.SAVE,
									styles.snackbarBodyStyleSuccess,
									styles.snackBarStyleTop
								)
							);
							//dispatch(detailViewIsMounted(false));
							console.log('data-->', data)
							dispatch(getTasksRefresh(true));
							if (object === constants.TASKS) {
								dispatch(refreshDetailViewListViewData(true));
								dispatch(refreshDetailViewData(true));
							}
							if (detailView.mounted && data.propsTaskApp !== undefined && data.propsTaskApp !== null) {
								let isTaskInChild = isObjectChildExists(data.propsTaskApp.object, constants.TASKS);
								if (isTaskInChild) {
									parentTableIds = "349";
								}
								if (data.propsTaskApp.isAppointmentInChild) {
									parentTableIds += parentTableIds === "" ? "142" : ",142";
								}
								let param = {};
								param.parent_table_id = parentTableIds;
								param.child_table_id = OBJECT_TABLEID_MAP[data.propsTaskApp.object];
								param.child_record_id = data.propsTaskApp.recordId;
								param.task_app_value = detailViewTaskAppintmentReducer.taskAppValue;
								dispatch(getDetailViewTaskAppointment(data.propsTaskApp.object, param));
							}

							if (data.hasOwnProperty("propsTaskApp") && data.propsTaskApp != null) {
								dispatch(getTasksPortal(data.propsTaskApp));
							}
							if (activeTab.info.callFrom === "task_listview") {
								refreshColumnView(activeTab.object);
							}
							if (activeTab.type === TYPE_LIST_VIEW) {
								refreshListView(object);
							}
							if (activeTab.type === TYPE_COLUMN_VIEW) {
								refreshColumnView(activeTab.object);
							}
						} else {
							dispatch(
								showCustomSnackBar(
									getLocalizedStrings().message.BATCH_ACTION_VALIDATION
										.GROUP_ALREADY_EXIST,
									styles.snackbarBodyStyleError,
									styles.snackBarStyleTop
								)
							);
						}
					}
				});
			}else{
				let message = getLocalizedStrings().message.TASKS.DELETE_CANCELLED_STATAUS_RESTRICT_MSG;
				dispatch(showCustomSnackBar(message,styles.snackbarBodyStyleError,styles.snackBarStyleTop));
			}
		} else {
			dispatch(
				showCustomSnackBar(
					validityObj.fieldName + " " + validityObj.helperText,
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				)
			);
		}
	};

	const getFormValidity = () => {
		let validityObj = {};
		validityObj.isValidParam = false;
		validityObj.helperText = "";
		validityObj.fieldName = "";

		let fields = editableFieldsList;
		for (let i = 0; i < fields.length; i++) {
			let fieldObject = fields[i];
			if (fields[i].name == "t_name" && fields[i].value == null && taskNameField !== '') {
				fields[i].value = taskNameField;
			}
			if (fieldObject.is_required_field === true) {
				if (
					isValidParam(fieldObject.value) &&
					fieldObject.value !== null &&
					fieldObject.value !== ""
				) {
					validityObj.isValidParam = true;
					validityObj.helperText = "";
					validityObj.fieldName = "";
				} else {
					validityObj.isValidParam = false;
					validityObj.helperText = "can not be blank";
					validityObj.fieldName = fieldObject.label;
					break;
				}
			} else {
				validityObj.isValidParam = true;
				validityObj.helperText = "";
				validityObj.fieldName = "";
			}
		}
		return validityObj;
	};
	const openTaskDetailView = (object, id) => {
		let linkTo = 'detailview/' + id;
		let linkToUrl = '/' + object + "/" + linkTo;
		linkToUrl = makeValidAppUrl(linkToUrl);
		setredirect(true);
		setredirectUrl(linkToUrl);


	};
	const closeDrawer = () => {
		dispatch(getAppDrawer(false, null, null, null, null));
		let tab = getActiveTab();

		let info = tab.info;
		if (object === constants.TASKS && isValidParam(info) && info.hasOwnProperty("isUnitsAccount") && info.hasOwnProperty("UnitsAccountProjectId") && info.isUnitsAccount) {
			delete info.isUnitsAccount;
			delete info.UnitsAccountProjectId;
		}
		dispatch(refreshDetailViewListViewData(true));
		dispatch(refreshDetailViewData(true));
	}
	const addTabFn = (object, id) => {
		let linkTo = "detailview/" + id;
		let label = isValidParam(data.labelName) ? data.labelName : "Empty";
		let tab = {
			label: label,
			object: object,
			type: TYPE_DETAIL_VIEW,
			url: '/' + object + "/" + linkTo,
		};
		if (data.isUnitsAccount) {
			tab.info = { isUnitsAccount: data.isUnitsAccount, UnitsAccountProjectId: data.UnitsAccountProjectId ? data.UnitsAccountProjectId : my_clientid ,callFrom:'mini_edit' };
		} else {
			tab.info = {};
		}
		addTab(tab, true);
	};
	let contentHeight = 280;
	let top = (contentHeight - 40) / 2;
	if (redirect) {
		addTabFn(object, recordId);
		return <Navigate push to={redirectUrl} />;
	}
	if (isLoadded && editableFieldsList.length > 0) {
		console.log("editableFieldsList",editableFieldsList)
		return <div>{processFormData(editableFieldsList)}</div>;
	} else {
		return (
			<div style={{ width: "100%", height: contentHeight }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "45%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 50,
							width: 50,
							padding: 7,
						}}
					>
						<ShowCircularProgress
							size={30}
							style={{ marginTop: "3", marginLeft: "3" }}
						/>
					</div>
				</div>
			</div>
		);
	}
};
export default MiniEdit;
