 const label = {
    MODULE: {
        Sales: 'Vendas',
        Marketing: 'Marketing',
        Service: 'Serviço',
        Operations: 'Operações',
        HUB: 'Hub'
    },
    MODULE_ITEMS: {
        Reports: 'Relatórios',
        Dashboard: 'painel de controle',
        'Marketing Templates': 'Modelos de e-mail de marketing',
        'Broadcast Campaigns': 'Campanhas de Transmissão',
        'Automation Designer': 'Designer de automação',
        'Email Marketing': 'Marketing de email',
        'Lead Capture': 'Captura de chumbo',
        'Landing Pages': 'Páginas de destino',
        'Web Forms': 'Formulários da Web',
        'Automated Campaigns': 'Campanhas automatizadas',
        'Social Marketing': 'Marketing Social',
        'Website Tracking': 'Acompanhamento de sites',
        'Website Analytics Setup': 'Configuração da análise do site',
        'Ab Campaigns': 'Campanhas Ab',
        'Opportunities': 'Oportunidades',
        'Marketing Reports': 'Relatórios',
        'Touch Campaigns': 'Campanhas de toque',
        'A/B Campaigns': 'Campanhas A / B',
        'Email Blast': 'Explosão de email',
        Activities: 'actividades',
        Conversion: 'Conversão',
        Campaigns: 'Campanhas',
        Custom: 'personalizadas',
        Sales: 'Vendas',
        Marketing: 'Marketing',
        Offers: 'Ofertas',
        Coupons: 'Cupons',
        'Coupons Claim Report': 'Relatório',
        ASSETS_DISTRIBUTION: 'Distribuição de ativos',
        USEFUL_LINKS: 'Links úteis',
        Royalties: 'Royalties - Legado',
        COMPLIANCE_REPORT: "Conformidade",
    },
    COMMON: {
        PUSH_NO_CONFIRM: 'Empurre sem confirmação',
        UNIT_CRM_UPDATE_STAGES:'Estágios de atualização',
        UNIT_CRM_UPDATE_TASKS:'Atualizar tarefas',
        FRENCHISEE_COMPANY_NAME:'Selecione o nome de uma empresa franqueada',
        SELECT_AN_ACCOUNT:'Selecione uma unidade',
        ADD_TO_PLAN:'Adicionar ao plano',
        LOGIN_TO_BASE:'Faça login na base',
        PLUS_BRANCH: '+Filial',
        ADD: 'Adicionar',
        ADD_NEW_APPOINTMENT: 'Adicione um Novo',
        SHOW: 'Mostrar',
        HIDE: 'ocultar',
        REFRESH: 'Atualizar',
        EDIT: 'Editar',
        SAVE: 'Salvar',
        TEST: 'teste',
        CANCEL: 'Cancelar',
        ENTER_TEXT: 'Inserir texto',
        APPLY: 'Aplique',
        AND: 'e',
        EMPTY: 'Vazio',
        EXPAND: 'Expandir',
        DELETE: 'Excluir',
        REQUIRED: ' (necessário)',
        AUTO_SEARCH_TEXT: 'Mesmo texto valores diferentes',
        NOTE: 'Nota',
        SEARCH: 'Busca',
        OK: 'Okey',
        SEND_MAIL: 'Enviar e-mail',
        ASSIGN: 'Atribuir',
        SELECT_A: 'Selecione um  ',
        SELECT_MORE: 'Selecione um ou mais ',
        CLOSE: 'Perto or Fechado',
        CREATE: 'Criar',
        WITH: 'com',
        CONVERT: 'Converter',
        SEARCH_PLACEHOLDER: 'Digite e pressione enter para Pesquisar',
        COPY: 'Cópia',
        MORE: 'Mais',
        CLICK_TO_SEARCH: 'Clique aqui para Pesquisar',
        PREVIEW: 'Visualização',
        BACK: 'Atrás',
        CONFIRM_REQUEST: 'Confirmar solicitação',
        SELECT: 'Selecione',
        ADVANCED: 'Avançado',
        ADVANCED_SEARCH: 'Pesquisa avançada',
        RESULTS_FOR: 'Resultados para',
        SETTINGS: 'Configurações',
        SIGN_OUT: 'Sair',
        BACK_TO_YOUR_ACCOUNT: 'Voltar à sua conta',
        BACK_TO_LIST: 'Volte para a lista.',
        BACK_TO_COLUMN_VIEW: 'Volte para a vista da coluna.',
        MERGE: 'Mesclar',
        DISPLAY_DIFFERENT_FIELDS: '* apenas exibindo campos que são diferentes.',
        WORKFLOW: 'Fluxo de trabalho',
        STATUS: 'Status',
        RECORD: 'Registro',
        MERGED_RECORD: 'Registo mesclado',
        EXPORT: 'Exportação',
        CTR_DETAILS_FOR: 'Detalhes da CTR para',
        URL: 'URL',
        COUNT: 'Contagem',
        CLICK_FOR_KANBAN_VIEW: 'Clique aqui para a visão Kanban',
        CLICK_FOR_LIST_VIEW: 'Clique aqui para ver a vista da lista',
        INSERT: 'Inserir',
        CATEGORIES: 'Categorias',
        REMOVE: 'Remover',
        REPLY: 'Resposta',
        REPLY_ALL: 'Responder a todos',
        FORWARD: 'frente',
        WORKFLOW_NAME: 'Nome do fluxo de trabalho',
        CONVERTING: 'Convertendo',
        TO: 'para',
        CURRENT_WORKFLOW: 'Fluxo de trabalho atual',
        CHANGE_WORKFLOW: 'Alterar fluxo de trabalho',
        UPDATE: 'Atualização',
        STATUS_TO_OPTIONAL: 'status para (opcional)',
        MANAGE: 'Gerenciar',
        ADD_PERSONAL_NOTE_OPTIONAL: 'Adicionar uma nota pessoal à sua solicitação (opcional)',
        CONVERTED: 'Convertido',

        //not found in doc
        SELECT_USER: 'Selecione um usuário',
        COMING_SOON: 'Em breve',
        IMPORT: 'Importação',
        NO: 'Não',
        FOUND: 'Encontrei',
        NOTHING_FOUND: 'Nada encontrado para',
        DOT: '.',
        UNDER_PROCESSING: 'Em processamento, brevemente ',
        FIND: 'Encontrar',
        LOADING: 'Carregamento',
        NAME: 'Nome',
        CLEAR_ALL: 'Apagar tudo',
        CLEAR: 'Claro',
        SHARE: 'Compartilhar',
        ADD_TO_GROUP: 'Adicionar ao grupo',
        MAP: 'Mapa',
        AUDIT: 'Alterar o log',
        OPEN_AUDIT_FORM: 'Auditoria',
        TRAIL: 'Trilha',
        ADD_NOTES: 'Adicionar notas',
        TO_EXCEL: 'Para o Excel',
        PUSH: 'Empurrar',
        RESCHEDULE: 'Reagendar',
        PRINT: 'Imprimir',
        COMPLETE: 'Completa',
        COMPLETE_New: 'Completo e novo',
        SEND_EMAIL: 'Enviar e-mail',
        SEND_SMS: 'Enviar SMS',
        CONVERT_TO_ACCOUNTS: 'Converter em contas',
        CONNECT: 'Conectar',
        DISCONNECT: 'Desconectar',
        DOWNLOAD: 'Download',
        BATCH_EDIT: 'Edição em lote',
        BATCH_CONVERT: 'Conversão em lote',
        INACTIVE: 'Inativo',
        ACTIVE: 'Ativo',
        RESTORE: 'Restaurar',
        PROFILE: 'Perfil',
        TYPE_TO_SEARCH: 'Digite aqui para Pesquisar',
        CONVERT_AND_ADD_OPP: 'Converter e adicionar oportunidade',
        SEND_VERIFICATION_EMAIL: 'Enviar e-mail de verificação',
        THESE: 'estes',
        ALL_APPOINTMENTS: 'Todas as nomeações',
        ALL_TASKS: 'tarefas',
        CONTINUE: 'Continuar',
        RUN: 'Corre',
        EDIT_NOTES: 'Editar notas',
        INTEGRATION_TITLE: 'Integração',
        UNSUBSCRIBE: 'Cancelar subscrição',
        MY_INTEGRATIONS: 'Integrações',
        NOTIFY: 'Notificar ',
        HELP: 'Socorro',
        PUBLISH: 'Publicar',
        UNPUBLISH: 'Cancelar publicação',
        CALL_SCRIPT: 'Chamar scripts',
        DOCUSIGN: 'Enviar DocuSign',
        DOCUSIGN_INTEGRATION: 'Enviar DocuSign',
        ALL: 'Todos',
        USER: 'Do utilizador',
        SYSTEM: 'Sistema',
        MY: 'Minhas',
        TODAYS: "Hoje",
        YES: 'sim',
        DONE: 'Feito',
        OPEN: 'Abrir',
        FUTURE: 'Futuro',
        OVERDUE: 'Atrasado',
        TOMORROWS: "Amanha",
        TOMORROW: "Amanhã",
        TODAY: 'Hoje',
        CONNECTED_AS: 'Conectado como',
        SALESREP: 'Representante de vendas',
        EVERYONE: 'Todos',
        NOTE_TYPE: 'Tipo de nota',
        TEXT: 'Texto',
        FOR: 'para',
        MODULE_SETTING: 'Definições',
        TEMPLATE: {
            LIBRARY_TEMPLATE: 'Modelo de biblioteca',
            USER_TEMPLATE: 'Modelo do usuário',
        },
        OF: 'do',
        'System Fields': 'Campos do sistema',
        'Custom Fields': 'Os campos personalizados',
        'Additional Information': 'informação adicional',
        'Contact Details': 'Detalhes do contato',
        'Phone and Email Information': 'Telefone e informações de email',
        'Sales rep': 'Representante de vendas',
        IMPORT_CSV_SOURCE_FIELD_HEADER: 'Eu mapearia minhas fontes do arquivo CSV',
        ADD_NEW_SOURCE: 'Adicione uma nova fonte',
        ADD_TO_THIS_LIST: 'Adicionar a esta lista',
        TYPE_SOMETHING: 'Digite algo',
        'Opportunity Details': 'Detalhes da oportunidade',
        'Company Details': 'Detalhes da empresa',
        'Contact Information': 'Informações de contato',
        'Case Information': 'Informação do caso',
        'Solution Details': 'Detalhes da solução',
        'Project Details': 'Detalhes do projeto',
        'Issue Details': 'Detalhes do problema',
        'Assigned to': 'Atribuído a',
        'Sales person': 'Pessoa das vendas',
        EMAIL_TO: 'Email para',
        RECENT_SEARCHES: 'pesquisas recentes',
        RECENT_RECORDS: 'Registros Recentes',
        'Activities': 'actividades',
        'Conversions': 'Conversões',
        ASSETS: 'Ativos',
        ENABLE_MAILING: 'Ativar envio de e-mail',
        DISABLE_MAILING: 'Desativar envio de email',
        CLICK_TO_REMOVE: 'Clique aqui para remover',
        PIN: 'PIN',
        UNPIN: 'Unpin',
        RENAME: 'Renomear',
        DEACTIVATE: 'Desativar',
        ACTIVATE: 'Ativar',
        COUPONS: 'Cupons',
        ADD_FOLDER: 'Adicionar pasta',
        ADD_FILE: 'Adicionar arquivo',
        SELECT_CONTACTS: 'Selecione contatos',
        PREFERENCES: 'Preferências',
        NOTIFICATION_REMINDERS: 'Notificações e lembretes',
        LOCK_STAGES: 'Bloquear Etapas',
        LOCK_TASKS: 'Bloquear Tarefas',
        SEND_BATCH_SMS: 'Enviar mensagem de texto em lote',
        SEND_TEXT_MESSAGE: 'Envie mensagem de texto',
        VERIFY_EMAIL: 'Verificar E-mail',
        SELECT_UNIT_NAME: 'Selecione o nome da unidade',
        NEWS: 'Notícia',
        CLOSE_CASE: 'Fechar caso',
        CUSTOMIZE: 'Personalizar',
        VIEW_MODE: 'MODO DE VISUALIZAÇÃO',
        LIST: 'Lista',
        COLUMN: 'Coluna',
        SETUP_SCHEDULING_PAGES: 'Páginas de agendamento de configuração',
        SAVE_RUN: 'SALVAR E EXECUTAR',
        BATCH_CONVERT_TO_ACCOUNTS: 'Lote Convertido em Contas',
        NEXT: 'Next',
        ADD_QUICKBOOKS: 'Add to Quickbooks',
        ITEM_FROM_QB: 'FROM Quickbooks',
        PUSH_TO_QB: 'Add to Quickbooks',
        ADD_NOTE: 'Adicionar Nota',
        ADD_NOTE_CONVERT: 'Adicionar Nota & Converter',
        OBJECT_NAME: 'Nome do objeto',
        GREENLIGHT_EXPORT_DOWNLOAD: 'Processo e download',
        PREVIOUS: 'Anterior',
        VIEW: 'Ver',
        MENU: 'Cardápio',
        NO_DUPLICATE: 'Sem duplicado',
        ONGOING: 'Em andamento',
        ONGOING_ONBOARDING: 'Integração contínua',
        SYNC_NOW: 'Sync Now',
        'Generate Invoice': 'Gerar Fatura',
        'Add Payment': 'Adicionar Pagamento',
        'Print' : 'Imprimir',
        MOBILE_MENU: 'Mobile Menu',
        PORTAL_MENU: 'Portal Menu',
        TO_QUICKBOOKS:'Para Quickbooks',
        CONVERT_INVOICE: 'Converter para fatura',
        RECEIVE : 'Receber',
        ACCEPT : 'Aceitar',
        CONFIRM: 'confirme',
        FULFILL: 'Preencha',
        SHIP: 'Navio',
        ADD_JOBS : 'Adicionar empregos',
        ROYALTY_SETUP: 'Configuração de royalties',
        SAVE_AND_SCHEDULE:'Salvar e programar',
        UNLINK: 'Desvincular',
        TO_XMS: 'Para XMS',
        SEQUENTIAL:"Sequencial",
        STAR: 'Estrela',
        PIN :'Alfinete',
        CONVERT: 'Converter',
        SHARE_WITH : 'Compartilhar com',
        LMS_SETUP:'Configuração do LMS',
        LMS_LABEL:'Conta LMS (obrigatório)',
        PLUS_BASE: '+Base',
        UNITBASE:'Base da Unidade'
    },
    NOTE:{
        ALL_NOTES:'Todas as notas',
        ALL_TYPES:'Todos os tipos',        
    },
    HOME: {
        MY_FILTERS: 'Meus filtros',
        GROUPS: 'Grupos',
        ADD_NEW: 'Adicionar novo',
        RECENT: 'Recente',
        HOME: 'Casa',
        CLICK_TO_REFRESH: 'Atualizar',
        RECENTLY_VIEWED: 'Visto recentemente',
        FILTERS: 'Filtros',
        ADD_GROUP: 'Adicionar grupo',
        NOTIFICATIONS: 'Notificações',
        WHATS_NEW: "O que há de novo",
        COLLAPSE: 'Colapso',
        EXPAND: 'Expandir',
        ADD_FILTER: 'Adicionar filtro',
        CLICK_TO_FIND_GROUP: 'Clique aqui para encontrar um grupo',
        CLICK_TO_FILTER_GROUP: 'Filtre seus grupos',
        CLICK_TO_FIND_FILTER: 'Clique aqui para encontrar um filtro',
        FORECASTS: 'Previsões',
        //not found in doc
        ADD_FIELD: 'Clique aqui para adicionar um campo ao filtro',
        FITER_SETUP: 'Configuração de filtro',
        ENTER_BY_HAND: 'Enter à mão',
        IMPORT: 'Importar',
        VIEW: 'Visão',
        DELETE: 'Excluir',
        CLICK_TO_FIND_REPORT: 'Clique aqui para encontrar um relatório',
    },
    CAMPAIGNS: {
        NAME_REQUIRED: 'Nome da campanha (obrigatório)',
        GROUP: 'Grupo',
        CONTACT_FILTER: 'Filtro de contato',
        ACCOUNT_FILTER: 'Filtro de conta',
        FROM_NAME_REQUIRED: 'Do nome (obrigatório)',
        VIEW_AS_WEB_PAGE: 'Exibir como uma página da Web',
        SUBJECT_REQUIRED: 'Assunto (obrigatório)',
        DAYS: 'Dia (s)',
        EXECUTE_NOW: 'Executar agora',
        TEST: 'Teste',
        SCHEDULED_FOR: 'Programado para',
        EXECUTED_ON: 'Executado em',
        EXECUTING: 'Execução',
        ABORTED: 'Abortada',
        SCHEDULE: 'Cronograma',
        CLICK_URL: 'Clique em URL',
        SELECT_GROUP: 'Selecionar até três grupos',
        SELECT_CONTACT_FILTER: 'Selecione um filtro de contato',
        SELECT_ACCOUNT_FILTER: 'Selecionar um filtro da conta',
        CHANGE_TEMPLATE: 'Alterar modelo',
        WITHOUT_AB_TEST: 'Sem um teste a/ b',
        WITH_AB_TEST: 'Com teste de a/b',
        WITH_AUTOMATION: 'Campanhas automatizadas',
        WITHOUT_AUTOMATION: 'Campanhas de toque (legado)',
        SEND_ON_WEEK_DAY: 'Não envie nos finais de semana (sáb, dom)',
        RESEND_AFTER: 'Reenviar depois',
        RESEND_ALL: 'Reenviar tudo',
        FROM_EMAIL_REQUIRED: 'De e-mail (obrigatório)',
        START_DATE_REQUIRED: 'Data de início (obrigatório)',
        CAMPAIGN_TYPE_OPTIONAL: 'Tipo de campanha (opcional)',
        CAMPAIGN_TYPE: 'Tipo de campanha',
        SENT: 'Enviado',
        OPENED: 'Aberto',
        UNOPENED: 'Fechado',
        CLICKED: 'Clicado',
        BOUNCED: 'Ressaltado',
        UNSUBSCRIBED: 'Não subscrito',
        SPAM: 'Spam',
        DROPPED: 'Cair',
        TYPE_HERE: 'Digite aqui.',
        ADD_SIGNATURE: 'Adicionar assinatura',
        INSERT_IMAGE: 'Inserir imagem',
        PERSONALIZE: 'Personalizar',
        TEMPLATE_DIALOG_TITLE: 'Selecione um modelo',

        // Campaign Status
        'All': 'Todos',
        'Active': 'Ativo',
        'Running': 'Corrida',
        'Stopped': 'Parado',

        'New': 'Novo',
        'Queued': 'Na fila',
        'Executed': 'Executado',

        OPEN: 'Abierto',
        BOUNCE: 'Bunce',
        SPAMREPORT: 'Spamreport',
        'Halted': 'Parado',
        'Auto Response': 'Resposta Automática',
        'Marketing Template': 'Modelo de marketing',
        'Newsletter': 'Boletim de Notícias',
        'Greetings': 'saudações',
        'Inactive': 'Inativo',
        'Executed - No Winner': 'Executado - sem vencedor',
        'Sent to Winner': 'Enviado para o vencedor',
        'Sending to Winner': 'Envio para o vencedor',
        ADD_CAMPAIGN_TYPE: 'Adicione um novo tipo de campanha',
        CAMPAIGN_NAME: 'Nome da campanha',
        SUBJECT: 'Assunto',
        MANAGE: 'Gerir',
        FROM_EMAIL: 'De e-mail',
        SELECT_A_FILTER:'Selecione um filtro de ',
        BETWEEN: 'Entre',
        WEEKDAYS_ONLY: 'Apenas dias da semana'
    },
    BROADCAST_CAMPAIGNS: {
        PERSONALIZE_SUBJECT: 'Personalize o assunto',
        FROM_EMAIL_REQUIRED: 'De e-mail (obrigatório)',
        EMAIL_CAMPAIGN_REPORT: 'Relatório de campanha de e-mail',
        SCHEDULE_BROADCAST: 'Programar transmissão',
        RESCHEDULE_BROADCAST: 'Reagendar transmissão',
        EXECUTE_BROADCAST: 'Executar transmissão',
        ADD_GROUP: 'Adicionar grupo',
        CATEGORY: 'Categoria',
        TEMPLATE: 'Modelo',
        PERFORM_AB_TESTING: 'Executar testes A/B',
        //not found in doc 
        VERIFY_EMAIL: 'Verificar o seu endereço de e-mail',
        VERIFY_EMAIL_ADDRESS: 'Introduza o seu endereço de e-mail aqui',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verificar outro endereço de email',
        BROADCAST_CAMPAIGN_EDIT_TAB_LABEL: 'Editar campanha de difusão',
        BATCH_EDIT: 'Edição em lote',
        BROADCAST_CAMPAIGN_COPY_TAB_LABEL: 'Copiar Campanha de Transmissão',
        BROADCAST_CAMPAIGN_CREATE_TAB_LABEL: 'Criar campanha de difusão',
        All_BROADCASTS: 'Todas as transmissões',
        BROADCASTS: 'Transmissões',
        ADD_SIGNATURE: 'Adicionar assinatura',
        BROADCAST_INFO: 'Informações de transmissão',
        DESIGN: 'desenhar',
        DRAG_AND_DROP: 'Arrastar e largar',
        LEGACY: 'Legado',
        EMAIL_BUILDER: 'Construtor de email',
        QUALIFIED_CONTACTS: 'Ver a lista de destinatários',
    },
    AB_CAMPAIGNS: {
        TARGET_BY: 'Alvo por',
        AB_TEST_DISTRIBUTION: 'Distribuição de teste A/B',
        MIN_MARGIN_PERCENT: 'Margem vencedora mínima%',
        A_PERCENT: 'A%',
        B_PERCENT: 'B%',
        AB_CAMPAIGN_REPORT: 'Relatório de campanha de A/B',
        //not found in doc
        AB_SEND_REST: 'Envie o resto usando o vencedor',
        AB_SELECT_WINNER_BY: 'Selecione o vencedor por',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verificar outro endereço de email',
        EMAIL_VIEWS: 'Visualizações de email',
        EMAIL_CLICKS: 'Cliques de e-mail',
        AB_CAMPAIGN_EDIT_TAB_LABEL: 'Editar campanha A/B',
        BATCH_EDIT: 'Edição em lote',
        AB_CAMPAIGN_COPY_TAB_LABEL: 'Copiar campanha A/B',
        AB_CAMPAIGN_CREATE_TAB_LABEL: 'Criar campanha A/B',
        ALL_AB_CAMPAIGNS: 'Todas as Campanhas AB',
        AB_TESTS: 'Testes A/B',
        MESSAGE_A: 'Mensagem A',
        MESSAGE_B: 'Mensagem B',
        AFTER: 'depois',
        SCHEDULE_AB_CAMPAIGN: 'Agendar uma campanha A/B',
        WINNER_BASEDON_A: 'Vencedor (baseado na mensagem A)',
        WINNER_BASEDON_B: 'Vencedor (baseado na mensagem B)'

    },
    CALENDAR: {
        CALENDAR_TASKS: 'Calendário/tarefas',
        CALENDAR_ONLY: 'Calendário',
        CALENDARS: 'Calendário para',
        SHOW_OPTION_1: 'Cancelado',
        SHOW_OPTION_2: 'Concluído',
        SHOW_OPTION_3: 'Programado',

        //not found in doc 
        TODAY: 'Hoje',
        BACK: 'Atrás',
        NEXT: 'Próxima',
        DAY: 'Dia',
        WEEK: 'Semana',
        MONTH: 'Mês',
        AGENDA: 'Agenda',
        DATE: 'Data',
        TIME: 'Tempo',
        EVENT: 'Evento',
        ALL_DAY: 'o dia todo',
        WORK_WEEK: 'semana de trabalho',
        YESTERDAY: 'Ontem',
        TOMMOROW: 'amanhã',
        SHOW_MORE: '',
        TYPE: 'Tipo',
        MY_CALENDAR_SETTING: 'Minhas configurações de calendário',
        DISPLAY:'Exibição',
        ACCOUNT_NAME:'Nome da conta',
        JOB_NAME : 'Nome do Trabalho',
        COLOR_CODE: 'Código de cores',
        LOGO_COLOR :'Fundo do logotipo',

    },
    GROUP: {
        GROUP_NAME: 'Nome do grupo',
        DELETE_RECORD: 'Excluir registro',
        USED_IN: 'Usado em',
        REMOVE_FROM: 'Remover de',
        SELECT_RECORDS: 'Registros selecionados',
        ALL_RECORDS: 'Todos os registros',
        SELECT_OPTIONS: 'Selecionar opções',
        GROUP_FILTER: 'Selecione o filtro de grupo',
        ALL: 'Todos',
        ACTIVE: 'Ativo',
        INACTIVE: 'Inativo',
        DETAILS: 'Detalhes',
        'Active': 'Ativo',
        'Inactive': 'Inativo',
        'New': 'Novo',
        'scheduled': 'agendado',
        'Queued': 'Enfileiradas',
        'Executed': 'Executado',
        'Running': 'Corrida',
        'Halted': 'Parado'
    },
    APPOINTMENTS_TASKS: {
        MY_TASKS: 'Minhas tarefas',
        PERSONALIZE_OPTION_1: 'Atraso',
        PERSONALIZE_OPTION_2: 'Hoje',
        PERSONALIZE_OPTION_3: 'Futuro',
        PERSONALIZE_OPTION_4: 'Amanhã',
        PERSONALIZE_SORT_1: 'Encontro',
        PERSONALIZE_SORT_2: 'Prioridade',
        TASKS_FOR: 'Tarefas para',
        SORT_BY: 'Ordenar por',
        COMPLETE_THIS_APPOINTMENT: 'Conclua esse compromisso',
        COMPLETE_THIS_TASK: 'Complete esta tarefa',
        NEXT_DAY: 'Dia seguinte',
        NEXT_WEEK: 'Próxima semana',
        COMPLETE: 'Completa',
        DELETE: 'Excluir',
        TASKS: 'Tarefas',
        APPOINTMENTS: 'Compromissos',
        ADD_NOTE: 'Adicionar uma nota (opcional)',
        NEXT_MONTH: 'Mês que vem',
        DAYS: 'Dias',
        SPECIFIC_DATE: 'Data específica',
        RESCHEDULE: 'Reagendar',
        RESCHEDULE_FOR: 'Reagendar para',
        REPEAT: 'Repetir',
        DAY_OF_THE_MONTH: 'dia do mês',
        DAY_OF_THE_WEEK: 'dia da semana',
        REPEAT_TYPE: 'Repita o tipo',
        REPEAT_ON: 'Repita em',
        REPEAT_BY: 'Repita por',
        START_DATE: 'Data de início',
        END_DATE: 'Data de término',
        REPEAT_EVERY: 'Repita cada',
        REMOVE_REMINDER: 'Remover lembrete',
        ONLY_THIS: 'Apenas esta',
        FOLLOWING: 'Seguir',
        ALL: 'Todos',
        THIS:'Esta',
        THIS_AND_FOLLOWING:'Este e o seguinte',
        ONLY_THIS_RECORD: 'Apenas este registro',
        FOLLOWING_RECORDS: 'Seguintes registros',
        ALL_RECORDS: 'Todos os registros',
        ADD_A_NOTE: 'Adicionar uma nota',
        COMPLETE_TASK_OPTIONAL: 'Concluir tarefas pendentes',
        COMPLETE_TASK_OPTIONAL_OBJ: 'Conclua tarefas pendentes / e-mails / mensagens de texto',
        CREATE_NEXT_TASKS: 'Criar próximas tarefas',
        CREATE_NEXT_TASKS_OBJ: 'Crie as próximas tarefas / e-mails / mensagens de texto',
        PENDING_TASKS: 'Tarefas pendentes',
        SEARCH_BY_NAME: 'Pesquisar por nome',
        INVITE_GUEST: 'Convidar convidados',
        ATTENDEES: 'Participantes',
        SALES_REP: 'Representante de vendas',
        ADD_REMINDER: 'Adicione um lembrete',
        // not found in doc
        'Email': 'O email',
        'Pop-up': 'Aparecer',
        'minutes': 'minutos',
        'hours': 'horas',
        'days': 'dias',
        'weeks': 'semanas',
        DAILY: 'Diariamente',
        WEEKLY: 'Semanal',
        MONTHLY: 'Por mês',
        YEARLY: 'Anual',
        DAYS_S: 'dias',
        WEEKS: 'semanas',
        MONTHS: 'meses',
        YEARS: 'anos',
        APPOINTMENT: 'Compromisso',
        TASK: 'Tarefa',
        SUBJECT: 'Sujeito',
        ASSIGN_TO: 'Atribuir a',
        COMPANY: 'Empresa',
        CONTACT_NAME: 'Nome de contato',
        OUTLOOK_SYNC: 'Sincronização do Outlook',
        GOOGLE_SYNC: 'Google Sincronizar',
        'Scheduled': 'Agendado',
        'Completed': 'Concluído',
        'Cancelled': 'Cancelado',
        'Pending': 'Pendente',
        TASK_NAME: 'Nome da tarefa',
        DUE_BY: 'Devido por',
        DESCRIPTION: 'DESCRIÇÃO',
        OWNER: 'Proprietário',
        FOLLOWUP: 'Acompanhar',
        'All': 'Todos',
        'Empty': 'Esvaziar',
        'No': 'Não',
        'Yes': 'sim',
        'Everyone': 'Todos',
        'Just me': 'Apenas eu',
        ALL_TASK_COMPLETED: 'Essas tarefas já foram concluídas.',
        'duration': 'duração',
        'days_after': 'dias depois',
        DEP_TASK_NAME: 'Nome da tarefa',
        TASK_COMPLETED: 'Tarefa completa(%)',
        APPOINTMENTS_AND_TASKS: 'Compromissos e tarefas',
        APPOINTMENTS_ONLY: 'Apenas compromissos',
        TASKS_ONLY: 'Apenas tarefas',
        EDIT: 'Editar',
        SENDNOW: 'Envie agora',
        OPPORTUNITY_NAME: 'Nome da oportunidade',
        SELECT_USER: 'Selecione o usuário',
        SELECT_STATUS: 'Selecione Status',
        VISITS: 'Visitas',
        UNIT_OWNER_TASKS: 'Tarefas da Unidade',
        COMPLETE_UNIT_TASK_OPTIONAL: 'Tarefas pendentes do proprietário da unidade completa',
        UNIT_TASKS: 'Tarefa para a Unidade',
        DOCUMENTS: 'Documentos',
        LINKS: 'Links',
        TASK_SETTINGS: 'Configurações de tarefa',
        APPOINTMENT_SETTINGS: 'Configurações de compromissos',
    },
    ATTACHMENT: {
        UPLOAD: 'Upload',
        MY_COMPUTER: 'Meu computador',
        BROWSE: 'Procurar',
        DOWNLOAD: 'Download de arquivos',
        ATTACH_FILE: 'Anexar um arquivo',
        UPLOADING: 'Fazendo upload',
        NO_IMAGE_FOUND: 'Nenhuma imagem encontrada',
        DOWNLOAD_RECORDING: 'Baixe a gravação',
        ATTACHMENT: 'Anexo',
        ADD_AN_ATTACHMENT: 'Adicionar um anexo'

    },
    LIST_VIEW: {
        CLICK_TO_COLLAPSE: 'Clique aqui para entrar em colapso',
        CLICK_TO_EXPAND: 'Clique aqui para expandir',
        SHOW_TOTAL: 'Mostrar total',
        SHOW_PICTURE: 'Mostrar imagem',
        DESCENDING: 'Decrescente',
        SORT_BY: 'Classificar por',
        SELECT_COLUMN_FOR_VIEW: 'Selecione os campos para esta visualização',
        SELECT_COLUMN_FOR_FILTER_VIEW: 'Selecionar colunas para os resultados do filtro',
        ADD_AND_REORDER: 'Adicionar e reordenar',
        FIELDS: 'campos',
        CLICK_TO_ADD_MORE_FIELDS: '(clique nesta caixa para adicionar mais campos. Arraste e reordene os campos para alterar sua posição.)',
        SELECT_FIELDS: 'Selecionar campos',
        MODIFY_FIELD: 'Modificar campos',
        EXPORT_SELECTED_RECORDS: 'Exportar registros selecionados',
        EXPORT_ALL_RECORDS: 'Exportar todos os registros',

        //not found
        LINK_EXISTING: 'Link existente',
        SET_UP: 'Configuração',
        GROUP_BY: 'Grupo por',
        FIND_BY_FIELD: 'Encontrar por',
        FILTER_TYPE: {
            DEFAULT_FILTERS: 'Filtros padrão',
            SHARED_FILTERS: 'Filtros compartilhados'
        },
        COLUMN_VIEW_SETUP_INFO: 'Esta vista de coluna mostrará apenas os primeiros cinco campos. A exibição de lista mostra todos os campos.',
    },
    DETAIL_VIEW: {
        SHOW_LABELS: 'Mostrar Etiquetas',
        SELECT_OPTIONS: 'Selecionar opções',
        CLICK_TO_ADD_MORE_FIELDS: 'Clique nesta caixa para adicionar mais campos. Arraste e reordene os campos para alterar sua ordem. Você pode selecionar máximo de 50 campos.',
        SETUP_FIELDS: 'Campos de configuração',
        ADD_OR_REMOVE_FROM_LIST: 'Adicionar/remover campos desta lista',
        CLICK_TO_KNOW_MORE: 'Clique para saber mais',
        CLICK_TO_ADD_CHILD_OBJECTS: 'Clique nesta caixa para adicionar mais objetos filho. Arraste e reordene os objetos para alterar sua posição.',
        SETUP_CHILD_TABS: 'Configurar guias filho',

        //not found
        SHOW_LABELS_AGAINST_FIELD: 'Selecione isso para mostrar rótulos contra os campos.',
        CUSTOMIZE_CHILD: 'Personalize a criança',
        REFUND_CHILD_LABEL: 'Reembolso',
        GROUPS: 'Grupos',
        MANAGE: 'Gerir',
        ADD_TO_GROUP: 'Adicionar ao grupo',
        DATA_TIP_1: 'Isso criaria as tarefas para o ',
        DATA_TIP_2: 'Isso criaria as tarefas / e-mails / mensagens de texto para o ',
        DATA_TIP_3: ' etapa',

        HIGHLIGHT_LABEL_OPPORTUNITY: 'Oportunidade $',
        HIGHLIGHT_LABEL_FORECAST: 'Previsão $',
        HIGHLIGHT_LABEL_CLOSING_ON: 'Fechamento em',
        HIGHLIGHT_LABEL_OPPORTUNITY_AGE: 'Idade da oportunidade',
        HIGHLIGHT_LABEL_DAYS: 'Dias',

        CHILD_SETUP_SELECT_CHILD_LABEL: 'Clique aqui para adicionar um objeto filho à configuração.',
        NO_SETUP_OPTION: 'Nenhum'

    },
    RELATIONSHIP: {
        DEFINE_RELATIONSHIP: 'Definir relacionamento',
        RELATE: 'Relacionar',
        COMPANY: 'Empresa',
        FULL_NAME: 'Nome completo',
        EMAIL: 'Email'
    },
    AUTOMATION_DESIGNER: {
        TOUCH_CAMPAIGN_REPORT: 'Relatório de campanha Touch',
        INTERVAL: 'Depois',
        NOTE_TYPE: 'Tipo de nota',
        TEXT_REQUIRED: 'Texto (obrigatório)',
        TASK_NAME_REQUIRED: 'Nome da tarefa (obrigatório)',
        OWNER_REQUIRED: 'Proprietário (obrigatório)',
        DUE_BY_REQUIRED: 'Devido (obrigatório)',
        DAY_AFTER_REQUIRED: 'Dias depois (obrigatório)',
        EMAIL: 'Email',
        POP_UP: 'Pop-up',
        MINUTES: 'minutos',
        HOURS: 'horas',
        DAYS: 'dias',
        WEEKS: 'semanas',
        REMINDER: 'Lembrete',
        GROUP_REQUIRED: 'Selecionar grupo (obrigatório)',
        ADD_TO_GROUP: 'Adicionar ao grupo',
        MOVE_TO_GROUP: 'Mover para o grupo',
        REMOVE_FROM_GROUP: 'Remover do grupo',
        STATUS_REQUIRED: 'Status (obrigatório)',
        WORKFLOW_REQUIRED: 'Fluxo de trabalho (obrigatório)',
        CHOOSE_ANOTHER_FIELD: 'Escolha outro campo',
        CHOOSE: 'Escolha',
        FIELD_FOR: 'um campo para',
        CAMPAIGN_NAME: 'Nome da campanha (obrigatório)',
        START: 'Começar',
        STOP: 'Parar',
        REPORT: 'Relatório',
        RESUME: 'Currículo',
        SELECT_PAGE: 'Selecione uma página',
        ENTER_WEB_PAGE_LINK_OPTIONAL: 'Insira o link da página da Web (opcional).',
        ENTER_WEB_PAGE_LINK: 'Insira o link da página da Web.',
        CLICK_TO_VIEW: 'Clique aqui para ver',
        CLICK_TO_EDIT: 'Clique aqui para editar',
        DRAG_TO_MOVE: 'Arraste para mover',
        DRAG_TO_CONNECT: 'Arraste para conectar',
        CLICK_TO_REMOVE: 'Clique aqui para remover',
        CLICK_TO_SEE_REPORT: 'Clique aqui para ver o relatório',
        CLICK_TO_ADD_MSG: 'Clique para Adicionar nova mensagem',
        NO: 'Não',
        YES: 'Sim',
        CLICK_TO_SELECT_USERS: 'Clique aqui para selecionar os usuários',
        SALES_REP: 'Representante de vendas',
        USERS: 'Usuários',
        CAMPAIGN_SENDER: 'Remetente da campanha',
        SCORE: 'Ponto',
        START_WITH: 'Começar com', //'Add',//'Começar com'
        DECISIONS: 'Decisões',
        ACTIONS: 'Ações',
        LINK_CLICKED: 'Link clicado?',
        SCORE_REACHED: 'Pontuação atingida?',
        TOUCH_CAMPAIGN: 'Campanha Touch',
        WEB_FORM: 'Formulário da Web',
        LANDING_PAGE: 'Página de destino',
        WEBSITE_VISITED: 'Site visitado',
        CHANGE_GROUP: 'Alterar grupo',
        ADD_NOTE: 'Adicionar nota',
        NOTIFY: 'Notificar',
        ADD_TASK: 'Adicionar tarefa',
        FIELD_UPDATED: 'Atualizar campos',
        CHOOSE_TEMPLATE: 'Escolha um modelo',
        USE_THIS_TEMPLATE: 'Usar este modelo',
        WAIT: 'Esperar',
        NAME: "Nome",
        TARGET_GROUPS: 'Grupo (s) alvo (s)',
        TARGET_FILTER: 'Filtro de destino',
        RESEND_AFTER: 'Reenviar depois',
        START_DATE: 'Data de início',
        SUBJECT: 'Assunto',
        TEXT: 'Texto',
        TASK_NAME: 'Nome da tarefa',
        ASSIGN_TO: 'Atribuir a',
        FIELD_LABEL: 'Rótulo do campo',
        FIELD_VALUE: 'Valor do campo',
        LINK: 'Link',
        SEND_ON_ANY_TIME: 'Enviar em a qualquer momento',
        SEND_ON_BUSINESS_DAY: 'Enviar em apenas em dias úteis',
        CONVERT_TO: 'Converter para',
        QUALIFIER: 'Qualificador',
        OBJECT: 'Objeto',
        NOTIFY_SENDER: 'Notificar remetente',
        NOTIFY_SALES_REP: 'Notificar representante de vendas',
        NOTIFY_SENDER_SALESREP: 'Notificar remetente, notificar representante de vendas',
        NOTIFY_USERS: 'Notificar os usuários',

        //not found in doc
        TEST: 'Teste',
        VIEW_AS_WEBPAGE_INFO: 'Esta opção irá adicionar um link no topo do e-mail para abrir o e-mail como uma página Web',
        LINK_CLICK_INFORMATION: 'Se você deixar este espaço em branco, todos os links no e-mail serão rastreados.',
        GROUP_NAME: 'Novo nome do grupo',
        SELECT_USER: 'Atribuir a',
        REMIND_BEFORE: 'Lembrar antes',
        INTERVAL_REQUIRED: 'Intervalo (obrigatório)',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verificar outro endereço de email',
        'Touch Campaign': 'Campanha de toque',
        'Link Clicked': 'Link clicado',
        'Link Clicked?': 'Link clicado?',
        'Email Opened': 'Email aberto',
        'Email Opened?': 'Email aberto',
        'Unsubscribed': 'Não subscrito',
        'Score Reached': 'Pontuação alcançada',
        //'Send One-off Email': 'Enviar email único',
        'Send Email': 'Enviar email',
        'Change Group': 'Grupo de Mudanças',
        'Add Note': 'Adicionar nota',
        'Notify': 'Notificar',
        'Add Task': 'Adicionar tarefa',
        'Field Updated': 'Campo Atualizado',
        'Assign': 'Atribuir',
        'Converted': 'Convertido',
        'Start': 'Começar',
        'Add Touch Message': 'Adicionar mensagem de toque',
        'Web Form': 'Formulário da Web',
        'Website Visit': 'Visita ao site',
        'Landing Page': 'Landing Page',
        'Wait': 'Esperar',
        WAIT_FOR_DAYS: 'dias)',
        'Simple email nurturing': 'Criação de e-mail simples',
        'Capture leads using a web form and nurture': 'Capture leads usando um formulário da Web e crie',
        'Track web page visit and nurture': 'Acompanhar a visita e a promoção da página da Web',
        'Capture leads from a landing page and nurture': 'Capture leads de uma página de destino e cuide',
        'Start with touch campaign': 'Comece com a campanha de toque',
        'Start with web form': 'Comece com o formulário da web',
        'Start with tracking website visit': 'Comece com a visita ao site de rastreamento',
        'Start with landing page': 'Comece com a página de destino',
        COPY_OF: 'Cópia de',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'Nova Campanha Automatizada',
        TARGET_BY: 'Segmentar por',
        'Hours': 'Horas',
        'Hour(s)': 'Hora (s)',
        'day(s)': 'dia (s)',
        'hour(s)': 'hora (s)',
        ALL_AUTOMATED_CAMPAIGNS: 'Todas as campanhas automatizadas',
        MESSAGE: 'mensagem',
        SELECT_A_CAMPAIGN: 'Por favor, selecione uma campanha',
        ADD_SIGNATURE: 'Adicionar assinatura',
        BIRTHDAY_CAMPAIGN: 'Campanha de aniversário',
        'Add Birthday Message': 'Adicionar mensagem de aniversário',
        'Start with Birthday Campaign': 'Comece com a campanha de aniversário',
        'SMS Replied': 'SMS Respondido',
        'Send SMS': 'Enviar SMS',

        'Touch Campaign Automation': 'Automatização de campanhas de toque',
        'Birthday Campaign Automation': 'Automação de Campanhas de Aniversário',
        'Web Form Automation': 'Automação de formulário da Web',
        'Landing Page Automation': 'Automação da página de aterrissagem',

        SEND_SMS: 'Enviar SMS',
        SMS_REPLY: 'SMS Respondido?',
        BIRTHDAY_CAMPAIGN_REPORT: 'Relatório de campanha de aniversário',
        START_IMMEDIATELY: 'Imediatamente',
        START_SCHEDULE: 'Cronograma',
        SCHEDULE_ON_TEXT: 'Agendado para começar',
        SCHEDULE_DIALOG_TITLE: 'Selecione a data e a hora da programação',
        FROM_NUMBER: 'Do número',
        REPLY: 'Resposta',
        NOT_CONVERTED: 'Não convertido'
    },
    TOUCH_CAMPAIGNS: {
        ALL_TOUCH_CAMPAIGNS: 'Todas as campanhas de toque',
    },
    MARKETING_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalize o assunto',
        NAME_REQUIRED: 'Nome (obrigatório)',
        STATUS: 'Status',
        SUBJECT_REQUIRED: 'Assunto (obrigatório)',
        ACTIVE: 'Ativo',
        INACTIVE: 'Inativo',
        TEST: 'Teste',
        SELECT_TEMPLATE: 'Comece a digitar para selecionar um modelo',
        TYPE_HERE: 'Digite aqui.',
        ADD_SIGNATURE: 'Adicionar assinatura',
        INSERT_IMAGE: 'Inserir imagem',
        PERSONALIZE: 'Personalizar',
        SELECT_FROM_LIBRARY: 'Selecione a partir da biblioteca',
        USE_THIS_DESIGN: 'Use este projeto',
        DEAR: 'Prezado (male) or Prezada (female)',

        // not found in doc
        EXPAND_EDITOR: 'Fullscreen',
        SHARE: 'Uso para e-mails de marketing e vendas',
        MARKETING_TEMPLATE_CREATE_TAB_LABEL: 'Criar modelo de e-mail de marketing',
        COPY_OF: 'Cópia_de',
        BATCH_EDIT: 'Edição em lote',
        MARKETING_TEMPLATE_EDIT_TAB_LABEL: 'Editar modelo de e-mail de marketing',
        MARKETING_TEMPLATE_COPY_TAB_LABEL: 'Modelo de e-mail de marketing de cópia',
        CATEGORY: 'Categoria',
        LAYOUT: 'Layout',
        'Auto Response': 'Resposta Automática',
        'Marketing Template': 'Modelo de marketing',
        'Newsletter': 'Boletim de Notícias',
        'Greetings': 'saudações',
        CHOOSE_EMAIL_BUILDER: 'Escolha um construtor de email',
        DRAG_AND_DROP_BUILDER: 'Use o novo Construtor de arrastar e soltar',
        LEGACY_BUILDER: 'Use o Construtor herdado',
        TEMPLATE_INFO: 'Informações sobre o modelo',
        DESIGN: 'desenhar',
    },
    SALES_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalize o assunto',
        NAME_REQUIRED: 'Nome (obrigatório)',
        STATUS: 'Status',
        SUBJECT_REQUIRED: 'Assunto (obrigatório)',
        ACTIVE: 'Ativo',
        INACTIVE: 'Inativo',
        TEST: 'Teste',
        TYPE_HERE: 'Digite aqui.',
        ADD_SIGNATURE: 'Adicionar assinatura',
        INSERT_IMAGE: 'Inserir imagem',
        PERSONALIZE: 'Personalizar',
        DEAR: 'Prezado (male) or Prezada (female)',
        SALES_TEMPLATE_CREATE_TAB_LABEL: 'Criar modelo de e-mail de vendas',
        SALES_TEMPLATE_EDIT_TAB_LABEL: 'Editar modelo de e-mail de vendas',
        SALES_TEMPLATE_COPY_TAB_LABEL: 'Modelo de e-mail de vendas de cópia',
        COPY_OF: 'Cópia_de',
        SALES_TEMPLATES: 'Modelos de e-mail de vendas',
    },
    AGREEMENT_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalize o assunto',
        NAME_REQUIRED: 'Nome (obrigatório)',
        STATUS: 'Status',
        SUBJECT_REQUIRED: 'Assunto (obrigatório)',
        ACTIVE: 'Ativo',
        INACTIVE: 'Inativo',
        TEST: 'Teste',
        TYPE_HERE: 'Digite aqui.',
        ADD_SIGNATURE: 'Adicionar assinatura',
        INSERT_IMAGE: 'Inserir imagem',
        PERSONALIZE: 'Personalizar',
        DEAR: 'Prezado (male) or Prezada (female)',
        AGREEMENT_TEMPLATE_CREATE_TAB_LABEL: 'Criar modelo de acordo',
        AGREEMENT_TEMPLATE_EDIT_TAB_LABEL: 'Modelo de acordo de edição',
        AGREEMENT_TEMPLATE_COPY_TAB_LABEL: 'Modelo de acordo de cópia',
        COPY_OF: 'Cópia_de',
        AGREEMENT_TEMPLATES: 'Modelos de acordo',
        ADD_SIGNATURE_1: 'Assinatura 1',
        ADD_SIGNATURE_2: 'Assinatura 2',
        ADD_SIGNATURE_3: 'Assinatura 3',
        ADD_SIGNATURE_4: 'Assinatura 4',
        ADD_SIGNATURE_5: 'Assinatura 5',
        COVER_LETTER: 'Carta de apresentação',
    },
    SMS_TEMPLATE: {
        SMS_TEMPLATE_EDIT_TAB_LABEL: 'Editar modelo de SMS',
        SMS_TEMPLATE_COPY_TAB_LABEL: 'Copiar modelos de soma',
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME: 'Digite seu nome de domínio (excluir http ou www)',
        GENERATE_TRACKING_CODE: 'Gerar código de acompanhamento',
        TRACKING_CODE: 'Seu código de rastreamento',
        COPY_TO_CLIPBOARD: 'Copiar para área de transferência',
        DONE: 'Feito'

    },
    WEB_FORM: {
        SELECT_FORM: 'Selecionar um formulário',
        WEB_FORM_CREATE_TAB_LABEL: 'Crie formulários da Web',
    },
    MAIL: {
        EMAIL_FOR_TESTING: 'Insira e-mail para teste',
        SEND_A_TEST_EMAIL: 'Enviar um mail de teste',
        TYPE_HERE: 'Digite aqui.',
        INSERT_IMAGE: 'Inserir imagem',
        ADD_SIGNATURE: 'Adicionar assinatura',
        PERSONALIZE: 'Personalizar',
        FROM: 'De',
        SEND_MAIL: 'Enviar e-mail',
        PERSONALIZE_SUBJECT: 'Personalize o assunto',
        SUBJECT: 'Assunto',

        //not found in doc
        COMPUTER: 'Do computador',
        LIBRARY: 'Da biblioteca',
        RESCHEDULE: 'Reagendar',
        TO: 'Para',
        ATTACH: 'Anexar um arquivo',
        CC: 'CC',
        WHEN: 'Quando',
        SENDING_TEST_MAIL: 'Enviando e-mail de teste ...',
        SCHEDULE: 'Cronograma',
        SCHEDULE_DIALOG_TITLE: 'Selecionar data e hora da programação',
        SEND_LATER: 'ENVIAR MAIS TARDE',
        SEND: 'Enviar',
        RECIPIENTS: 'Destinatários',
        SENDING: 'Enviando...'
    },
    SMS: {
        TO: 'Para',
        WHEN: 'Quando',
        FROM: 'De',
        FROM_NUMBER: 'Do numero',
        SEND_A_TEST_SMS: 'Envie um sms de teste',
        TO_NUMBER: 'Enumerar',
        STATUS: 'Status',
        ENTER_NUMBER :'Digite o número para teste'
    },
    AUDIT_LEADSCORE: {
        LEAD_SCORE_HISTORY: 'Histórico de pontuação de lead.',
        SCORE: 'Ponto',
        DATE: 'Data',
        ACTION: 'Ação',
        OPENED: 'Aberto',
        UNSUBSCRIBED: 'Não subscrito',

        //not found in doc 
        USER: 'Usuário',
        DETAILS: 'Detalhes',
        ACTION_NAME: 'Nome da ação',
        SCORE_DETAILS: 'Detalhes da Pontuação',
        BY: 'por',
        FROM: 'De',
        LINK: 'Link',
        CLICKED: 'Clicado',
        EMAIL: 'Email',
        CONVERTED_FROM: 'Convertido de',
        TO: 'Para',
        IN_OPPORTUNITIES: 'em oportunidades',
        ADDED_TO: 'Adicionada a',
        REMOVED_FROM: 'Removido',
        UPDATED_TO: 'atualizado para',
        WEBFORM: 'Webform',
        SUBMITTED: 'submetidos',
        VISITED: 'Visitado',
        EXPIRED: 'Expirou',
        'Imported': 'Importado',
        'Created': 'Criado',
        'Converted to Account': 'Convertido em conta',
        'Created from Web form': 'Criado a partir do formulário da Web',
        'Created from Landing page': 'Criado a partir da página de destino',
        'Created from Zapier': 'Criado a partir de Zapier',
        'Created from Outlook': 'Criado a partir do Outlook',
        'Created from Outlook (Client)': 'Criado a partir do Outlook (Cliente)',
        'Created from G Suite': 'Criado a partir do G Suite',
        'Created from Office 365': 'Criado a partir do Office 365',
        'Created from Facebook/twitter etc': 'Criado a partir do Facebook / twitter etc',
        'Pushed': 'Empurrado',
        'Created from API': 'Criado a partir da API',
        'Inline/Quick updated': 'Inline / Atualização rápida',
        'Updated': 'Atualizada',
        'Batch Updated': 'Lote atualizado',
        'Workflow Applied': 'Fluxo de trabalho aplicado',
        'Converted': 'Convertido',
        'Assigned': 'Atribuído',
        'Deleted': 'Excluído',
        'Restored': 'Restaurado',
        'Merged': 'Mesclado',
        'Main Contact updated': 'Contato principal atualizado',
        'Shared': 'Compartilhado',

        'Link Clicked': 'Link clicado',
        'Email Opened': 'Email aberto',
        'Unsubscribed': 'Não subscrito',
        'Web Form Submitted': 'Formulário da Web enviado',
        'Removed from Group': 'Removido do grupo',
        'Field Updated': 'Campo Atualizado',
        'Added to Group': 'Adicionado ao grupo',
        'Website Visited': 'Site visitado',
        'Has Business Email': 'Tem email comercial',
        'Clicked Link': 'Link clicado',
        'Opened Email': 'E-mail aberto',
        ACCOUNT_SCORE: 'Sua pontuação perfeita para contas é',
        CONTACT_SCORE: 'Sua pontuação perfeita para contatos é'
    },
    DASHBOARD: {
        TODAY: 'Hoje',
        YESTERDAY: 'Ontem',
        THIS_WEEK: 'Esta semana',
        THIS_MONTH: 'Este mês',
        THIS_QUARTER: 'Este trimestre',
        THIS_YEAR: 'Este ano',
        LAST_WEEK: 'Última Semana',
        LAST_MONTH: 'Último mês',
        LAST_QUARTER: 'Último trimestre',
        LAST_YEAR: 'Ano passado',
        CUSTOM: 'Personalizado',

        //Kanban View
        NEXT_MONTH: 'Próximo mês',
        NEXT_QUARTER: 'Próximo trimestre',
        NEXT_THREE_MONTHS: 'Próximos três meses',
        NEXT_TWO_MONTHS: 'Próximos dois meses',

        SALES_DASHBOARD: 'Painel de vendas',
        MARKETING_DASHBOARD: 'Painel de marketing',
        // SALES_MARKETING_DASHBOARD: 'Painel de vendas e marketing',

        //not found in doc 
        REPOSITION_GADGET: 'Clique e arraste para reposicionar este gadget',
        'Top Sources By Lead Volume': 'Principais fontes por volume de leads',
        'Top Sources By Lead Conversion': 'Principais fontes por conversão de leads',
        'Marketing Email Summary': 'Resumo de email de marketing',
        'Top Marketing Emails by CTR': 'Principais emails de marketing por CTR',
        'Top Web Pages': 'Principais páginas da web',
        'Lead Pipeline': 'Pipeline de chumbo',
        'Opportunity Pipeline': 'Pipeline de oportunidades',
        'Sales Leaderboard': 'Quadro de vendas',

        'Status': 'Status',
        'Count': 'Contagem',
        'Subject': 'Assunto',
        // 'Click-Through Rate(CTR)': '',
        'Website Name': 'Nome do site',
        'Forecast': 'Previsão',
        'Sales person': 'Pessoa das vendas',
        'Closed': 'Fechado',

        'Activities': 'Atividades',
        'Accounts': 'Matriz de Contas',
        'Opportunities': 'Matriz de oportunidades',
        'Users': 'Usuários',
        'Appointment': 'Nomeação',
        'Dialed': 'Discado',
        'Email': 'Email',
        'LVM': 'LVM',
        'Note': 'Nota',
        'Spoke': 'Falou',
        'Task': 'Tarefa',
        'Transferred': 'Transferido',

        'Qualified': 'Qualificado',
        'Presentation': 'Apresentação',
        'Proposal': 'Proposta',
        'Negotiation': 'Negociação',
        'Close': 'Perto',

        'Lead': 'Levar',
        'Prospect': 'Perspectiva',
        'Customer': 'Cliente',
    },
    LOGIN: {
        LOG_IN: 'Entrar',
        EMAIL_ADDRESS: 'Endereço de e-mail',
        PASSWORD: 'Senha',
        REMEMBER_ME: 'Lembre-se de mim'
    },
    SOCIAL_CAMPAIGN: {
        SAVE_AS_DRAFT: 'Salvar como rascunho',
        POST_NOW: 'Postar agora',
        CONNECT: 'Conectar'

    },
    DOCUMENT_LIBRARY: {
        COMPUTER: 'Computador',
        LIBRARY: 'Biblioteca',
        //not found in doc
        IMAGES: 'Imagens',
        STOCK_IMAGES: 'Imagens Stock',
        DOCUMENTS: 'Documentos',
        SEARCH: 'Pesquisa...',
        UPLOAD_DOCUMENT: 'Carregar Documento',
        NAME: 'Nome',
        TITLE: 'Título',
        TITLE_REQUIRED: 'Título (obrigatório)',
        LINK_REQUIRED: 'Link (obrigatório)',
        TYPE: 'Tipo',
        OWNER: 'Proprietário',
        ALL: 'Todos',
        NO_IMAGE_FOUND: 'Nenhuma imagem encontrada',
        UPLOAD_DIALOG_TITLE: 'Selecione um documento para fazer upload',
        SHARED_WITH: 'compartilhado com',
        UNITS: 'unidades',
        UNIT: 'unidade',
        UNIT_PUSH: 'unidade:',
        RENAME_FOLDER: 'Renomear pasta',
        CREATE_FOLDER: 'Criar pasta',
        NEW_FOLDER: 'Nova pasta',
        ASSETS_NOT_FOUND: 'Nenhuma pasta e arquivo encontrados.',
        'Folder': 'Pasta',
        'File': 'Arquivo',
        IMAGE: 'Imagem',
        DOCUMENT_AND_FILE: 'Documentos e Ficheiros',
    },
    HEADER: {
        SALES_TEMPLATE: 'Modelo de e-mail de vendas',
        CALENDAR_TASKS: 'Calendário/Tarefas',
        ACCOUNT: 'Conta',
        SALES_REPORT: 'Relatório de vendas',
        ACCOUNTS: 'Contas',
        ACCOUNTS_WITHOUT_GROUP: 'Contas sem grupos',
        MY_ACCOUNT: 'Minhas contas',
        TODAYS_ACCCOUNTS: 'Contas de hoje',
        CONTACTS: 'Contatos',
        CONTACTS_WITHOUT_ACCOUNT: 'Contatos sem conta',
        CONTACTS_WITHOUT_ACCOUNTS: 'Contatos sem contas',
        CONTACTS_WITHOUT_GROUPS: 'Contatos sem grupos',
        MY_CONTACTS: 'Meus contatos',
        TODAYS_CONTACTS: 'Contatos de hoje',

        ALL_APPOINMENTS: 'Todas as nomeações',
        MY_APPOINMENTS: 'Minhas nomeações',
        MY_COMPLETED_APPOINMENTS: 'Minhas nomeações concluídas',
        MY_MISSED_APPOINMENTS: 'Minhas nomeações perdidas',
        TODAYS_APPOINMENTS: 'Nomeações de hoje',
        ALL_TASKS: 'Todas as tarefas',
        MY_COMPLETED_TASKS: 'Minhas Tarefas Concluídas',
        MY_FUTURE_TASKS: 'Minhas Tarefas Futuras',
        MY_PENDING_TASKS: 'Minhas tarefas pendentes',
        MY_TODAYS_TASKS: 'Minhas Tarefas de Hoje',
        ONBOARDING: 'Onboarding',
        SALES_PIPELINE:'Relatório de pipeline de vendas',
        COUPONS_CLAIM_REPORTS: 'Relatórios de reclamações de cupons',
        'Cases': 'Casos',
        'Projects': 'Projetos',
        'Issues': 'Problemas',
        'Solutions': 'Soluções',
        'Contacts': 'Contatos',
        'Accounts': 'Contas',
        'Opportunities': 'Oportunidades',
        'Appointments': 'Compromissos',
        'Tasks': 'Tarefas',
        'Onboarding': 'Onboarding',
        'Units': 'Unidades',
        'Assets': 'Ativos',
        'Activities': 'actividades',
        'Conversions': 'Conversões',
        'Campaigns': 'Campanhas',
        'Reports': 'Relatórios',
        'Sales_templates ': 'Modelos de vendas',
        'Dashboard': 'painel de controle',
        'Sales Leads': 'Leads de vendas',
        'Recycle Bin': 'Contas',
        REPORT: 'Relatório',
        TASKS_PAGE: 'Tarefas',
        'Products': 'Produtos',
    },
    ADVANCE_SEARCH: {
        //not found in doc
        ADVANCE_SEARCH: 'Busca Avançada',
        ADD_FIELD:'Pesquisar outro campo'
    },
    IMPORT: {
        GROUP: 'Grupo ( obrigatório )',
        //not found in doc
        WORKFLOW: 'Fluxo de trabalho (obrigatório)',
        STATUS: 'Status (obrigatório)',
        SOURCE: 'Fonte ( obrigatório )',
        IMPORT: 'Importação',
        LINK_USING: 'Link usando',
        LINK_WITH: 'Link com',
        APPLY_WORKFLOW: 'Aplicar fluxo de trabalho',
        AUTO_MAP: 'Auto mapa',
        USE_SAVED_MAPPING: 'Usar mapeamento salvo',
        SAVE_MAPPING: 'Salvar mapeamento',
        DELETE_MAPPING: 'Excluir mapeamento',
        DONOT_CHECK_DUPLICATE: 'Não Verificar duplicatas',
        CHECK_DUPLICATE: 'Verificar duplicatas',
        CHECK_DUPLICATE_BY: 'Verificar duplicatas por',
        DONOT_IMPORT: 'Não importar',
        MARK_DUPLICATE: 'Importar e marcar como duplicado',
        //end
        UPDATE: 'Atualização',

        //not found in doc
        STEP_1: 'Selecione a fonte',
        STEP_2: 'Mapear campos e importar',
        FINISH: 'Terminar',
        NEXT: 'Próximo',
        MAP_CSV_COLUMNS: 'Mapear colunas CSV para o CRM',
        MAP_XLS_COLUMNS: 'Mapear colunas XLS para o CRM',
        MAP_XLSX_COLUMNS: 'Mapear colunas XLSX para o CRM',
        MAP_TO_CRM_FIELD: 'Mapear para o campo do CRM',
        COLUMN: 'Coluna',
        ADD_NEW_GROUP: 'Adicione um novo grupo',
        WORKFLOW_OPTIONAL: 'Fluxo de trabalho (Opcional)',
        FILE_MUST_CSV: 'O ficheiro deve estar no formato CSV.',
        SKIP: 'Pular',
        MERGE: 'mesclar',
        CHECK_DUPLICATE_USING: 'Verifique duplicatas usando',
    },
    REPORT: {
        START_DATE: 'Data de início',
        END_DATE: 'Data de término',
        //not found in doc
        REPORT_ON: 'Repita em',
        //end

        REFRESH: 'Atualizar',
        PRINT: 'Imprimir',
        SALES_REP: 'Representante de vendas',

        //not found in doc
        DATE_RANGE: 'Intervalo de datas',
        SALES_REPORT: 'Relatórios de vendas',
        SERVICE_REPORT: 'Relatórios de serviço',
        'Activity Report': 'Relatório de atividade',
        'Conversion Report': 'Relatório de Conversão',
        'Call Report': 'Relatório de chamadas',
        'Pipeline Report': 'Relatório de Pipeline',

        'Customer Activation Report': 'Relatório de ativação do cliente',
        'Customer Service Type Report': 'Relatório de Tipo de Serviço ao Cliente',
        'Customer Assessment Report': 'Relatório de Avaliação do Cliente',
        'Customer Leads Assessment Report': 'Relatório de Avaliação de Clientes',
        'Customer Status Report': 'Relatório de status do cliente',
        'Sales Pipeline Report': 'Relatório de pipeline de vendas',
        'Days in Franchise Workflow Report': 'Dias no relatório de fluxo de trabalho de franquia',
        'Franchise Workflow Report': 'Relatório de fluxo de trabalho de franquia',
        'Franchise Sales Pipeline Report': 'Relatório de pipeline de vendas de franquia',
        'Franchise Employees Report': 'Relatório de Funcionários de Franquia',
        'Franchise Last Contact Report': 'Relatório do Último Contato de Franquia',

        'InXpress User Baseline Report': 'Relatório da linha de base do usuário do InXpress',
        'Franchise User Report': 'Relatório de usuário de franquia',
        'Sales Summary Report': 'Relatório de Resumo de vendas',
        ACTIVITY_MATRIX: 'Matriz de Atividade',
        'ACTIVITY_TYPE': 'Tipo de atividade',
        'Service Reports': 'Relatórios de serviço',
        'Project Reports': 'Relatórios de projetos',
        'CRM Activity by Account': 'Atividade de CRM por conta',
        'Activity by Franchise': 'Atividade por Franquia',
        'Franchise Activity Report': 'Relatório de atividades de franquia',
        'Issue Reports': 'Relatórios de Emissão',
        'Customer Service and Sales Rep Listing': 'Listagem do atendimento ao cliente e do representante de vendas',
         GO: 'Ir',
        'CRM Account Details': 'Detalhes da conta do CRM',
        'Activity by Coach': 'Atividade por Coach',
        'Franchise Conversion Report': 'Relatório de conversão de franquia',
        //end

        'Count': 'Contagem',
        'Grand Total': 'Total geral',
        ACTIVITIES_BY_TYPE: 'Atividades por tipo',
        ACTIVITIES_BY_SALES_REP: 'Atividades por representante de vendas',
        ALL_CONVERSIONS_BY_STATUS: 'Todas as conversões por status',
        CUSTOMERS_BY_SOURCE: 'Clientes por fonte',
        DEALS_BY_SALES_PER: 'Pipeline por representante de vendas',
        DEALS_BY_STAGES: 'Ofertas por fases',
        TOTAL_CALLS_BY_SALES_REP: 'Total de chamadas por representante de vendas',
        TOTAL_CALL_DURATION_BY_SALES_REP: 'Duração total da chamada por representante de vendas',
        EXCEL: 'Excel',
        MATRIX_BY_STATUS: 'Matriz Por Status',
        MATRIX_BY_SOURCE: 'Matriz Por Fonte',
        'Status': 'Status',
        'Source': 'Fonte',
        '5 Ways Report': 'Relatório de 5 maneiras',
        'Forecast Report': 'Relatório de previsão',
        'Tasks Report': 'Relatório de tarefas',
        'Email Report': 'Relatório de email',
        'SMS Report': 'Relatório SMS',
        'Orthotic Report': 'Relate o dinheiro',
        'Greenlight Export': 'Exportação Greenlight',
        REPORT_ON_OBJECTS: {
            ACCOUNTS: 'Cuentas',
            OPPORTUNITIES: 'Oportunidades'
        },
        CASES_BY_STATUS: 'Casos por status',
        CASES_BY_PRIORITY: 'Casos por prioridade',
        PROJECTS_BY_STATUS: 'Projetos por status',
        PROJECTS_BY_TYPE: 'Projetos por tipo',
        ISSUES_BY_STATUS: 'Problemas por status',
        ISSUES_BY_PRIORITY: 'Problemas por prioridade',
        SUPPORT_MATRIX: 'Matriz de Suporte',
        CUSTOMER_BY_CASES: 'Clientes por Casos',
        PROJECT_MATRIX: 'Matriz do Projeto',
        ISSUE_MATRIX: 'Matriz de problemas',
        SUB_TOTAL: 'Subtotal',
        SERVICE_MATRIX_HEADER: {
            'Assigned to': 'Atribuído a',
            'Total count': 'Contagem total',
            'Company': 'Empresa',
            'Assigned To': 'Atribuído a',
        },
        COUPONS_NAME: 'Selecione Cupons',
        PRODUCT_SELECT: 'Selecionar produto',
        AVAILABLE_REPORTS: 'Relatórios Disponíveis',
        WORKFLOW_STAGES: 'Estágios',
        CHART_TYPE: 'Tipo de Gráfico',
        SHOW: 'mostrar',
        VALUE: 'Valor',
        COUNT: 'Contar',
        DEALS_BY_SALES_REP: 'Pipeline por representante de vendas',
        PIPELINE:'Oleoduto',
    },
    FILTER: {
        //not found in doc 
        NAME_REQUIRED: 'Nome do filtro (obrigatório)',
        REMOVE_CONDITION: 'Remover condição',
        CRITERIA_FOR_ASSOCIATED_OBJECTS:'Critérios para objetos associados',
        NEXT_BUTTON_TEXT: 'Próximo',
        PREVIOUS_BUTTON_TEXT: 'Anterior',
        AND: 'E',
        OR: 'Ou',
        ADD_ANOTHER: 'Adicionar outro',
        ADD_CONDITION: 'Adicionar condição',
        COPY_FILTER: 'Cópia de ',
        FROM_DATE: 'Da data (From Date)',
        TO_DATE: 'Até a presente data (To Date)',

        CONTAINS: 'contém',
        DOES_NOT_CONTAINS: 'não contém',
        IS_EQUAL_TO: 'é igual a',
        IS_NOT_EQUAL_TO: 'não é igual a',
        IS_EMPTY: 'está vazia',

        IS_GREATER_THAN: 'é maior do que',
        IS_GREATER_THAN_OR_EQUAL_TO: 'é maior ou igual a',
        IS_LESS_THAN: 'é menos do que',
        IS_LESS_THAN_OR_EQUAL_TO: 'é menor ou igual a',
        'Contacts': 'Contatos',
        'Contacts without Groups': 'Contatos sem grupos',
        'My Contacts': 'Meus contatos',
        'Contacts without Account': 'Contatos sem conta',
        'Todays Contacts': 'Contatos de hoje',
        'Contacts without Accounts': 'Contatos sem contas',
        'All records': 'Todos os registros',
        'Added this Month': 'Adicionado este mês',
        'Added this Week': 'Adicionado esta semana',
        'My Opportunities': 'Minhas Oportunidades',
        'Opportunities': 'Oportunidades',
        'Accounts': 'Contas',
        'Accounts without Contact': 'Contas sem contato',
        'Accounts without Groups': 'Contas sem grupos',
        'My Accounts': 'Minhas contas',
        'Todays Accounts': 'Contas de hoje',
        'Cases': 'Casos',
        'Projects': 'Projetos',
        'Issues': 'Problemas',
        'Accounts without Contacts': 'Contas sem contatos',
    },
    SOURCE: {
        //not found in doc 
        SOURCE_NAME: 'Nome de origem'
    },
    INXPRESS_REPORT: {

        //not found in doc 
        COUNTRY_SELECT: 'Selecione o país',
        FRANCHISE_SELECT: 'Selecione franquia',
        WORKFLOW_SELECT: 'Selecionar fluxo de trabalho',
        START_DATE: 'Data de início',
        NO_RECORDS_FOUND: 'Nenhum registro foi encontrado.',
        'Account': 'Conta',
        'Sales Rep': 'Representante de vendas',
        'Workflow': 'Fluxo de trabalho',
        'Status': 'Status',
        REGION_SELECT: 'Selecione a região',
    },
    KANBAN_REPORT: {
        WORKFLOW: 'Fluxo de trabalho',
        USER: 'Usuário',
        DURATION: 'Duração',
        SORT_BY: 'Classificar por',
        REPORT_BY: 'Relatório por',
        'Forecast amount': 'Valor da previsão',
        'Close date': 'Fechar data',
        'Sales rep': 'Representante de vendas',
        'Status': 'Status',
        'Convert': 'Converter',
        'Edit': 'Editar',
        'Delete': 'Excluir',
        'opportunities': 'Oportunidades',
        'Probability': 'Probabilidade',
    },
    APP: {
        CURRENT: 'Eu usaria a nova versão',
        LEGACY: 'Eu usaria a versão legada',
        HEADING: 'Por favor, escolha uma versão Soffront',
        SUB_TITLE: 'Sua organização tem acesso à nossa versão legada. Você gostaria de usá-lo em vez da nova versão?'
    },
    OTHER_VIEW: {
        //not found in doc
        SUBSCRIBE_LABEL: 'Se inscrever'
    },
    MY_INTEGRATION: {
        CONNECTING: 'Conectando',
        DISCONNECTING: 'Desconectando',
        SYNC_EMAIL: 'Sincronizar e-mails',
        SYNC_CONTACT: 'Sincronizar contatos',
        SYNC_CALENDAR: 'Sincronizar Calendário',
        APPEND_COMPANY: 'Anexar nome da empresa',
        APPEND_CONTACT: 'Anexar nome do contato',
        CALENDAR: 'Calendário',
        SERVICE: 'Serviço',
        INTEGRATION_HEADER: 'Integração de e-mail e calendário',
        PHONE_INTEGRATION: 'Integração Telefônica',
        MY_INTEGRATIONS: 'Integrações',
        CONTACT_FILTER: 'Filtro de contato',
        RINGCENTRAL_HEADER: 'RingCentral',
        SMS_INTEGRATION: 'Integração SMS',
        QUICKBOOKS: 'QuickBooks',
        XERO:'Xero',
        INTEGRATE:'integrar',
        SELECT_INTEGRATION_ITEMS:'selecione os iten a serem integrados',
        INVOICES:'Faturas',
        PAYMENTS:'Pagamentos',
        MANNUALLY:'Manualmente (CRM> Xero)',
        AUTOMATICALLY:'Automaticamente (Xero> CRM)',
        ACCOUNTING_INTEGRATION_LABEL: 'Integrar com',
        ACCOUNTING_INTEGRATION: 'Integração Contábil',
        QUICKBOOKS_INTEGRATION: 'Integração QuickBooks',
        EVENTBRITE_INTEGRATION: 'Integração Eventbrite',
        SELECT_A: 'Selecione um',
        CRM_CONTACT_FILTER_SELECT: 'Selecione um filtro de contato do CRM',
        CONTACT_GROUP_NOT_FIND: 'Não é possível localizar grupo de contatos. Por favor, clique em',
        HERE: 'Aqui',
        TO_TRY_AGAIN: 'para tentar novamente.',
        CONTACT_FILTER_NOT_FIND: 'Não é possível localizar qualquer filtro de contato.',
        SEND_SALES_EMAILS_USING: 'Envie e-mails de vendas usando',
    },
    PROFILE_DETAILS: {
        //not found in doc
        PROFILE_DETAILS_LABEL: 'Perfil',
        PERSONAL_INFORMATION: 'Informação pessoal',
        USERNAME_LABEL: 'Isso será usado como o nome do usuário.',
        CHANGE_PASSWORD: 'Mudar senha',
        MANAGE_NOTIFICATIONS: 'Gerenciar Notificações',
        EMAIL_SIGNATURE: 'Assinatura de e-mail',
        TASKS: 'Tarefas',
        APPOINTMENTS: 'Compromissos',
        TASK_EMAIL: 'E-mail de tarefas',
        TASK_POPUP: 'Tarefa Pop up',
        APPOINTMENT_EMAIL: 'E-mail de compromissos',
        APPOINTMENT_POPUP: 'Compromissos aparecer',
        ON: 'Em',
        OFF: 'Fora',
        CHANGE_PHOTO: 'Mudar foto',
        NEW_PASSWORD: 'Nova senha',
        CONFIRM_PASSWORD: 'Confirme a nova senha',
        NAME_REQUIRED: 'Nome (obrigatório)',
        EMAIL_REQUIRED: 'Email (obrigatório)',
        PHONE: 'telefone',
        TIME_ZONE_REQUIRED: 'Fuso horário (obrigatório)',
        DATE_FORMAT: 'Formato de data',
        LANGUAGE: 'Língua',
        USER_COLOR: 'Cor do usuário',
    },
    DETAIL_VIEW_CHILD_NOTES: {
        ALL: 'Todos',
        USER: 'Do utilizador',
        SYSTEM: 'Sistema',
        MY: 'Minhas',
        ATTACHMENT_INFO: 'Informações sobre o anexo',
    },

    DOCUSIGN: {
        CONNECT_BUTTON: 'Conectar',
        SAVE_BUTTON: 'Salve ',
        CANCEL_BUTTON: 'Cancelar',
        USERNAME: 'Nome de usuário (requeridos)',
        PASSWORD: 'Senha (requeridos)',
        INTREGATOR_KEY: 'Chave Integradora (requeridos)',
        SELECT_TEMPLATE: 'Selecione o modelo',
        SEND: 'Enviar',
        DISCONNECT_BUTTON: 'Desconectar',
        DOCUSIGN_SETUP_HEADER: 'Integração DocuSign',
        DOCUSIGN_CONNECT_TITLE: 'Conecte-se à sua conta DocuSign',
        ADD_NOTE: 'Adicione um comentário (opcional).',
    },
    TWILIO: {
        CALL: 'Ligar',
        VERIFY: 'Verificar',
        DISCONNECT: 'Desconectar',
        SHOW_SCRIPTS: 'Mostrar Scripts',
        HIDE_SCRIPTS: 'Ocultar scripts',
        CALL_SETUP: 'Configuração de chamada',
        CALL_SCRIPT: 'Script de chamada',
        CALL_SCRIPT_COPY_TAB_LABEL: 'Copiar script de chamada',
        COPY_OF: 'Cópia de',
        ADD_NUMBERS: 'Adicione seus números de telefone de saída',
        OUTBOUND_PHONE_NUMBERS: 'Números de telefone de saída',
        ADD_ANOTHER_NUMBER: 'Adicione outro número',
        CALL_RECORDING: 'Gravação de chamadas',
        PHONE_CREDITS: 'Seus créditos telefônicos',
        PURCHASE_CREDITS_YOU_HAVE: 'Você tem',
        PURCHASE_CREDITS_MINUTES_LEFT: 'minutos restantes.',
        PURCHASE_CREDITS_MORE: 'Compre mais créditos.',
        CALLING: 'Chamando',
        RECORD_ALL_CALL: 'Gravar todas as chamadas',
        VERIFIED: 'Verificado',
        CLICK_TO_CALL: 'Eu estou pronto para tomar a chamada',
        ADDITIONAL_INFO: 'Exibir informações adicionais',
        ADDITIONAL_INFO_TITLE: 'Informação adicional',
        SELECT_FIELD:'Selecione os campos (máximo de 3 campos)'
    },
    CALL_SCRIPT: {
        ADD_SCRIPT_NAME: 'Nome do script (obrigatório)',
        ADD_DETAILS: 'Detalhes do script (obrigatório)',
        WORKFLOW_NAME: 'Nome do fluxo de trabalho',
        CALL_SCRIPTS_EDIT_TAB_LABEL: 'Editar scripts de chamadas',
    },
    TWILIO_SMS: {
        ZIP_CODE: 'Código postal',
        AREA_CODE: 'Código de área',
        ADD_TO_ACCOUNT: 'Adicionar',
        SMS_CREDITS: 'Seus créditos SMS',
        SMS_CREDITS_LEFT: 'Créditos SMS à esquerda.',
        SEND: 'Enviar',
        NO_NUMBER_PURCHASED: 'Você ainda não tem números de telefone. Use a opção de localização para comprar números de telefone. Após a compra, você poderá enviar mensagens usando os números.',
        FIND_PHONE_NUMBER: 'Encontre números de telefone por CEP ou código de área do telefone',
        FIND_PHONE_NUMBER_BY_AREA_CODE: 'Encontre números de telefone por código de área',
        SEND_SMS: 'Enviar mensagem de texto',
        YOUR_MESSAGE: 'Sua mensagem',
        OR: 'Ou',
        PHONE_NUMBER: 'Número de telefone',
        PHONE: 'Telefone',
        AVAILABLE_TO: 'Disponível para',
        USER_NAME: 'Nome de usuário',
        USED_BY: 'Usado por',
        UNIT_NAME: 'Nome da Unidade',
    },
    QUICKBOOKS: {
        CONNECT_BUTTON: 'Conectar',
        LABEL: 'Conecte-se à sua conta do QuickBooks Online'
    },
    PREVIEW_EMAIL_TEMPLATE: {
        ALL_LAYOUT: 'Todos os layouts',
        LEFT_SIDEBAR_LAYOUT: 'Layout da barra lateral esquerda',
        RIGHT_SIDEBAR_LAYOUT: 'Layout da barra lateral direita',
        HORIZONTAL_LAYOUT: 'Layout Horizontal',
        MULTIPLE_COLUMN_LAYOUT: 'Layout de coluna múltipla'
    },
    EVENTBRITE: {
        CONNECT_BUTTON: 'Conectar',
        SELECT_ORGANISATIONS: 'Sincronize eventos desta organização',
        EVENTBRITE_CONNECT_TITLE: 'Conecte-se à sua conta Eventbrite',
        SELECT: 'Selecione',
        EVENTBRITE_TOGGLE_TITLE: 'Durante a sincronização, adicione novos contatos se não forem encontrados no CRM',
    },
    SIDE_NAV: {
        APPOINTMENTS: 'Calendário / Tarefas',
        DASHBOARD: 'Painel',
        REPORTS: 'Relatórios',
        SALES_TEMPLATES: 'Modelos de e-mail de vendas',
        SOCIAL_MARKETING: 'Marketing Social',
        WEBSITE_TRACKING: 'Web Analytics',
        SOLUTIONS: 'Soluções',
        CONTACTS: 'Contatos',
        ACCOUNTS: 'Contas',
        OPPORTUNITIES: 'Oportunidades',
        CASES: 'Casos',
        PROJECTS: 'Projetos',
        ISSUES: 'Problemas',
        ONBOARDING: 'Onboarding',
        UNITS: 'Unidades',
        ASSESTS: 'Ativos',
        OPERATIONS_ACTIVITIES: 'actividades',
        OPERATIONS_CONVERSIONS: 'Conversões',
        OPERATIONS_CAMPAIGNS: 'Campanhas',
        OPERATIONS_REPORTS: 'Relatórios',
        LEAD_CAPTURE: 'Captura de chumbo',
        EMAIL_MARKETING: 'Marketing de email',
        'CALENDAR/TASKS': 'Calendário / Tarefas',
        'SALES REPORTS': 'Relatórios',
        'SALES TEMPLATES': 'Modelos de vendas',
        'SOCIAL MARKETING': 'Marketing Social',
        'ONLINE REPUTATION': 'Reputação Online',
        DASHBOARDS: 'Dashboards',
        'SALES LEADS': 'Leads de vendas',
        'USEFUL LINKS': 'Links Úteis',
        NEWS: 'Notícia',
        LIBRARY: 'Biblioteca',
        COMMUNITY: 'Comunidade',
        TRAINING: 'Treinamento',
        HUB: 'HUB',
        SCROLL_TO_RIGHT: 'Role para a direita',
        SCROLL_TO_LEFT: 'Role para a esquerda',
        Royalties: 'a direitos autorais',
        INBOX: 'Caixa de entrada',
    },
    NO_RECORD: {
        'Solutions': 'Soluções',
        'Appointments': 'Compromissos',
        'Tasks': 'Tarefas',
        'Groups': 'Grupos',
        'Notes': 'Notas',
        'Attachments': 'Anexos',
        'Marketing-templates': 'Modelos de marketing',
        'Sales-templates': 'Modelos de vendas',
        'Web-forms': 'Formulários da web',
        'Products': 'Produtos',
        'Broadcast-campaigns': 'Campanhas de transmissão',
        'Ab-campaigns': 'Ab-campanhas',
        'Automation-designer': 'Designer de automação',
        'Projects': 'Projetos',
        'Invoices': 'Faturas',
        'Touch-campaigns': 'Campanhas de toque',
        'Events': 'Eventos',
        'Sms-templates': 'Modelos de SMS',

        'Emails': 'Emails',
        'Relationships': 'Relacionamentos',
        'Templates': 'Modelos',

        'Cases': 'Casos',
        'Contacts': 'Contatos',
        'Accounts': 'Contas',
        'Opportunities': 'Oportunidades',
        'Issues': 'Problemas',
        'Salesleads': 'Leads de vendas',
        'Source': 'Fonte',
        'Users': 'Comercial',
        'Securitylevel': 'Níveis de segurança',
        'Workflows': 'Fluxos de trabalho',
        'Relations': 'Relações',
        'Notetypes': 'Tipos de nota',
        'Campaigntypes': 'Tipos de campanha',
        'Emailintegration': 'Aliases de e-mail',
        'Library': 'Biblioteca',
        'Recyclebin': 'Contas',
        'Sms': 'SMS',
        'Assignmentrules': 'Regras de Atribuição',
        'Units': 'Unidades',
        'Orderitems': 'Itens de ordem',
        'Claims': 'Reivindicações',
        'Payments': 'Pagamentos',

    },
    REPORT_SCHEDULE_DIALOG: {
        REPORT_SCHEDULE_SETUP: 'Agenda de relatórios',
        DAY_OF_THE_MONTH: 'dia do mês',
        DAY_OF_THE_WEEK: 'dia da semana',
        REPEAT_TYPE: 'Tipo de repetição (obrigatório)',
        REPEAT_ON: 'Repita o',
        REPEAT_BY: 'Repita por',
        START_DATE: 'Data de início (obrigatório)',
        END_DATE: 'Data de término',
        REPEAT_EVERY: 'Repita cada (obrigatório)',
        SEND_TO: 'Enviar para (obrigatório)',
        SCHEDULE: 'Cronograma',
    },
    LIST_VIEW_HEADER: {
        AUTOMATION_DESIGNER: {
            'Name': 'Nome',
            'Status': 'Status',
            'Created By': 'Criado por',
            'Created On': 'Criado em',
            'Updated By': 'Atualizado por',
            'Updated On': 'Atualizado em',
        },
        APPOINTMENT: {
            'Subject': 'Sujeito',
            'Company': 'Empresa',
            'Status': 'Status',
            'Contact': 'Contato',
            'Contact Name': 'Nome de contato',
            'Start date': 'Data de início',
            'End date': 'Data final',
            'Assign to': 'Atribuir a',
            'Visible to': 'Visível para',
            'Outlook Sync': 'Sincronização do Outlook',
            'Google Sync': 'Google Sync',
            'Text': 'Texto',
            'Creator': 'O Criador',
            'Created on': 'Criado em',
            'Updated by': 'Atualizado por',
            'Updated on': 'Atualizado em',
        },
        TASKS: {
            'Task Name': 'Nome da tarefa',
            'Company': 'Empresa',
            'Status': 'Status',
            'Previous Task': 'Tarefa Anterior',
            'Complete Date': 'Data completa',
            'Owner': 'Proprietário',
            'Due By': 'Devido por',
            'Contact Name': 'Nome de contato',
            'Description': 'Descrição',
            'Updated on': 'Atualizado em',
            'Contact': 'Contato',
            'Start Time': 'Hora de início',
            'End Time': 'Fim do tempo',
            'Item': 'Item',
            'Billable': 'Faturável',
        },
        BROADCAST: {
            'Broadcast Name': 'Nome de Broadcast',
            'Status': 'Status',
            'Run Date': 'Data de execução',
            'Owner': 'Proprietário',
            'Created On': 'Criado em',
            'Updated On': 'Atualizado em',
            'Target': 'Alvo',
            'From email': 'De e-mail',
            'From name': 'Do nome',
            'Updated By': 'Atualizado por',
            'Created By': 'Criado por'
        },
        AB_CAMPAIGN: {
            'Campaign Name': 'Nome da campanha',
            'Created By': 'Criado por',
            'Created On': 'Criado em',
            'Run Date': 'Data de execução',
            'Status': 'Status',
            'Target Filter': 'Filtro Alvo',
            'Updated By': 'Atualizado por',
            'Updated On': 'Atualizado em'
        },

        SALES_TEMPLATE: {
            'Name': 'Nome',
            'Category': 'Categoria',
            'Subject': 'Assunto',
            'Status': 'Status',
            'Owner': 'Proprietário',
            'Created On': 'Criado em',
            'Updated By': 'Atualizado por',
            'Updated On': 'Atualizado em',
        },
        MARKETING_TEMPLATE: {
            'Name': 'Nome',
            'Category': 'Categoria',
            'Subject': 'Assunto',
            'Status': 'Status',
            'Owner': 'Proprietário',
            'Created On': 'Criado em',
            'Updated By': 'Atualizado por',
            'Updated On': 'Atualizado em',
        },
        WEB_FORM: {
            'Name': 'Nome',
            'Table Name': 'Nome da tabela',
            'Group': 'Grupo',
            'Source': 'Fonte',
            'Status': 'Status',
            'Assign To': 'Atribuir a',
        },
        TOUCH_CAMPAIGNS: {
            'Campaign name': 'Nome da campanha',
            'Status': 'Status',
            'Owner': 'Proprietário',
            'Target': 'Alvo',
            'From email': 'De e-mail',
            'Created On': 'Criado em',
            'Created By': 'Criado por',
            'From name': 'Do nome',
            'Updated On': 'Atualizado em',
            'Updated By': 'Atualizado por',
            'Resend All': 'Reenviar todos',
            'Resend After': 'Reenviar após',
        },
        ACCOUNTS: {
            'Status': 'Status',
        },
        CONTACTS: {
            'Status': 'Status',
        },
        OPPORTUNITIES: {
            'Status': 'Status',
        },
        PROJECTS: {
            'Status': 'Status',
        },
        ISSUES: {
            'Status': 'Status',
        },
        CASES: {
            'Status': 'Status',
        },
        SOLUTIONS: {
            'Status': 'Status',
        },
        SALES_LEADS: {
            'Status': 'Status',
        },
    },
    DEFAULT_NOTE_TYPES: {
        'Appointment': 'Compromisso',
        'Dialed': 'Discado',
        'Email': 'O email',
        'LVM': 'LVM',
        'Note': 'Nota',
        'Spoke': 'Falou',
        'Task': 'Tarefa',
        'Transferred': 'Transferido',
    },
    VIEW_TOGGLE: {
        LIST: 'Lista',
        COLUMN: 'Colunas',
        LIST_TITLE: 'Mude para a vista de lista',
        COLUMN_TITLE: 'Mudar para a vista da coluna',
        TIMELINE: 'Cronograma',
        TIMELINE_TITLE: 'Alternar para a exibição da linha do tempo',
        DETAILS: 'Detalhes',
        DETAILS_TITLE: 'Alternar para a exibição de detalhes',
    },
    EMAIL_MARKRTING_REPORT: {
        REPORT_HEADER: 'E-mail marketing Report',
        MY_CAMPAIGNS: 'Minhas campanhas',
        MY_LOCATION_CAMPAIGNS: 'Campanhas da minha unidade',
        RECENT_CAMPAIGNS: 'Campanhas recentes',
        BROADCAST_REPORT: 'Relatório de campanha de difusão',
        TOUCH_REPORT: 'Relatório de campanha de toque'
    },
    DOCUMENT_PUSH: {
        DIALOG_TITLE: 'Empurre para',
        DOCUMENT_NOT_FOUND: 'Nenhum documento encontrado.'
    },
    OPERATIONS_REPORT: {
        REQUEST_NEW_REPORT: 'Solicite um novo relatório'
    },
    OPERATIONS_CAMPAIGNS: {
        REPORT_HEADER: 'Campanhas de E-mail',
        BROADCAST_REPORT: 'Campanhas de difusão',
        TOUCH_REPORT: 'Campanhas de toque'
    },
    TASKS_REPORT: {
        TASKS_REPORT_LABEL: 'Relatório de tarefas'
    },
    EMAIL_REPORT: {
        EMAIL_REPORT_LABEL: 'Relatório de email',
        ERROR_REASON: 'Razão'
    },
    SMS_REPORT: {
        SMS_REPORT_LABEL: 'Relatório SMS'
    },
    BATCH_SMS: {
        MESSAGE: 'Sua mensagem',
        SEND: 'Mandar',
        CHARS: ' chars',
        SCHEDULE: 'Cronograma',
        SCHEDULE_SMS: 'Programar mensagem de texto',
        SEND_LATER: 'Enviar mais tarde'
    },
    SETTING_MENU: {
        MARKETING: 'Marketing',
        SECURITY: 'Usuários e Segurança',
        DATA: 'Dados',
        CUSTOMIZATION: 'Costumização',
        INTEGRATION: 'Integração',
        COMPANY: 'Empresa',
        WORKFLOW_RULES:'Fluxos de trabalho e regras',
        LISTS: 'Listas',
        FRANCHISE: 'Franquia',
        COMPANY_SCHEDULER: 'Agendador da empresa'
    },
    MARKETING_SETTING: {
        'SOURCE': 'Fontes',
        'LIBRARY': 'Biblioteca',
        'LEAD_SCORE': 'Pontuações de chumbo',
    },
    SECURITY_SETTING: {
        'USERS': 'Comercial',
        'SECURITY_LEVELS': 'Níveis de segurança'
    },
    DATA_SETTING: {
        'EXPORT': 'Exportar',
        'RECYCLE_BIN': 'Lixeira de reciclagem',
        'FIND_DUPLICATES': 'Encontrar duplicados'
    },
    CUSTOMIZATION_SETTING: {
        'CHANGE_LOGO': 'Alterar logotipo',
        'DATA_BASE': 'Base de dados',
        'WORKFLOWS': 'Fluxos de trabalho',
        'RELATIONS': 'Relações',
        'AUTO_FILL_VALUES': 'Valores de preenchimento automático',
        'NOTE_TYPES': 'TONTAS E TIPOS DE NOTA',
        'CAMPAIGN_TYPES': 'Tipos de campanha',
        'CORPORATE_CURRENCY': 'Moeda Corporativa',
        'HUB_SETTING': 'EIXO',
        'COMPANY_PROFILE': 'perfil de companhia',
        'WHITE_LABEL': 'Etiqueta branca',
        'MENU': 'Cardápio',
        'DESKTOP_MENU': 'Menu da Área de Trabalho',
        'MOBILE_MENU': 'Menu Móvel',
        'PHONE_NUMBERS': 'Números de telefone',
    },
    INTEGRATION_SETTING: {
        'MICROSOFT_OUTLOOK': 'Microsoft Outlook (Desktop)',
        'GENERATE_API': 'Gerar API',
        'EMAIL_INTEGRATION': 'Integração de email'
    },
    SETTINGS: {
        ADDED: 'adicionado',
        EDIT: 'atualizado',
        SUCCESSFULL: 'Com sucesso.',
        'SOURCE': 'Fonte',
        'NOTETYPES': 'TONTAS E TIPOS DE NOTA',
        'CAMPAIGNTYPES': 'Tipo de campanha',
        'CORPORATECURRENCY': 'Moeda Corporativa',
        'RELATIONS': 'Relação',
        'LIBRARY': 'Biblioteca',
        'USERS': 'Comercial',
        'SECURITYLEVEL': 'Níveis de segurança',
        'WORKFLOWS': 'Fluxos de trabalho',
        'EMAILINTEGRATION': 'Aliases de e-mail',
        'RECYCLEBIN': 'Lixeira de reciclagem',
        'ASSIGNMENTRULES': 'Regras de atribuição',
        'APPOINTMENTTYPES': 'Tipo de nomeação',
        'ROLLUP': 'Regras de Roll Up',
        'SUBSCRIPTION': 'Inscrição',
        'UPGRADE': 'Assinatura de atualização',
        'BUYPRODUCT': 'Comprar Produtos e Treinamento',
        'BUYPROSERVICE': 'Vendo Serviços Profissional',
        'BILLING': 'Faturamento e Detalhes da fatura',
        'CANCELSUB': 'Cancelar assinatura',
        'SUBDETAIL': 'Assinatura Detalhes',
        'PURCHESEMAILCREDIT': 'Comprar créditos E-mail',
        'PURCHESPHONECREDIT': 'Comprar créditos de telefone',
        'PURCHESSMSCREDIT': 'Comprar créditos de SMS',
        'PURCHESCARDCREDIT': 'Comprar créditos cartão de digitalização',
        'PURCHESCONTACTCREDIT': 'Compre Mais Contactos',
        'PURCHESTRANINGCREDIT': 'Formação de compra',
        'PURCHESMERKETINGSERVICE': 'Vendo Serviços de Marketing',
        'PURCHESPROFESSONALSERVICE': 'Vendo Serviços Profissional',
        'SETUP': 'Minhas configurações',
        SHOW_LOGO: 'Exibir logo',
        SHOW_SIGNOUT_LINK: 'Mostrar link de desconexão',
        THEME_COLOR:'Cor do Tema',
        AUDIT_QUESTIONS: 'Lista de verificação de auditoria',
        LMS_USERS: 'Usuários LMS',
    },
    CORPORATE_CURRENCY: {
        SELECT_CURRENCY_NAME: 'Selecione uma moeda corporativa'
    },
    UNSUBSCRIBE: {
        EMAILS: 'Emails',
        TEXTS: 'Textos',
        BOTHS: 'Ambas'
    },
    HUB_SETUP: {
        SETUP_FIELD_NAME: 'Nome do hub',
        USEFUL_LINK: 'Links Úteis',
        CASES: 'Estojos',
        SETUP_NAME: 'Configuração do HUB',
        NEWS: 'Notícia',
        LIBRARY: 'Biblioteca',
        COMMUNITY: 'Comunidade',
        TRAINING: 'Treinamento',
        HYPER_LINK: 'hiperlink',
        SET: 'Conjunto',
    },
    UNITS: {
        TASK_DETAILS: 'Detalhes das tarefas',
        'Unit': 'unidade',
        'Contact Name': 'Nome do contato',
        'Done': 'Feito',
        'Open': 'Aberto',
        'Tasks Name': 'Nome das tarefas',
        'Assigned To': 'Atribuído a',
        'Due By': 'Due por',
        'Status': 'Status',
        UNIT_DETAILS_LABEL: 'Detalhes da Unidade',
        FIRST_NAME: 'Primeiro nome (necessário)',
        LAST_NAME: 'Sobrenome (obrigatório)',
        PASSWORD: 'Senha (requeridos)',
        RETYPE_PASSWORD: 'Digite a senha novamente (obrigatório)',
        EMAIL: 'Email (obrigatório)',
        PHONE: 'Telefone (obrigatório)',
        COMPANY: 'Empresa (obrigatório)',
        TIME_ZONE_SELECT: 'Fuso horário',
        COUNTRY_SELECT: 'País',
        STATE_SELECT: 'Estado',
        NO_UNIT_TASKS: 'Não existe nenhuma tarefa disponível para o Proprietário da Unidade.',
        NO_ACCOUNT_TASKS: 'Não existe nenhuma tarefa disponível para a Conta.'
    },
    ASSIGNMENT_RULES: {
        SETUP_FIRST_RULE: 'Configurar sua primeira regra',
        ASSIGNMENT_RULE: 'Regra de atribuição',
        ASSIGNMENT_RULE_CREATE_TAB_LABEL: 'Criar regra de atribuição',
        ASSIGNMENT_RULE_EDIT_TAB_LABEL: 'Editar regra de atribuição',
        NAME_REQUIRED: 'Nome (obrigatório)',
        ASSIGNMENT_RULES: 'Regras de atribuição',
        STATUS: 'Status',
        SETUP_RULE: 'Regra de atribuição de configuração',
        USE_RULE: 'Usar regra de atribuição',
        TABLE_NAME: 'Nome da mesa',
        SELECT_UNIT_WORKFLOW: 'selecione o fluxo de trabalho da unidade'
    },
    COUPONS: {
        PREFIX: 'Prefixo',
        'Coupon Name': 'Nome do Cupão',
        'Discount Value': 'Valor de desconto',
        EXPIRATION_DATE: 'Data de validade',
        PODUCT_SERVICE: 'Produto / Serviço (opcional)',

    },
    COUPONS_CLAIM_REPORTS: {
        COUPONS_CLAIM_REPORTS_LABEL: 'Relatório de reclamações de cupons',
        COUPONS_REPORT: 'Relatório'
    },
    PIPELINE_REPORT: {
        PIPELINE_REPORT_LABEL: 'Relatório de pipeline',
        SALES_SUMMARY_REPORT: 'Relatório de Resumo de vendas'
    },
    NOTIFICATION_REMINDERS: {
        NOTIFICATION_REMINDERS: 'Alertas e Notificações',
        NOTIFY_ME: 'Notificar-me quando...',
        REMIND_ME: 'Lembre-me antes...',
        NOTIFY_EMAIL_OPEN: 'Meu email é aberto',
        NOTIFY_EMAIL_CLICK: 'Meu e-mail é clicado',
        NOTIFY_TEXT_REPLY: 'Meu texto é respondido',
        REMIND_UPCOMING_APPOINTMENT: 'Próximos compromissos',
        REMIND_UPCOMING_TASK: 'Próximas tarefas',
        ADD_NEW_REMINDER: 'Adicionar um novo lembrete',
        REMIND: 'Lembrar',
        DAY_BEFORE: 'día(s) antes de',
        MY_WEB_FORM_SUBMITTTED: 'Meu formulário da Web é submetido',
        NO_DATE_FIELD: 'Nenhum campo de data disponível',
    },
    EMAIL_INTEGRATION: {
        EMAIL: 'Aliases de e-mail',
    },
    NEWS: {
        HEADER: 'Notícias',
        STATUS: {
            DRAFT: 'Esboço, projeto',
            PUBLISHED: 'Publicadas'
        },
        EDIT_TAB_LABEL: 'Editar Notícias',
        ADD_TAB_LABEL: 'Adicionar Notícias',
        CATEGORY_REQUIRED: 'Categoria (obrigatório)',
        STATUS_REQUIRED: 'Status (obrigatório)',
        TITLE_REQUIRED: 'Título (obrigatório)',
        SUMMARY_REQUIRED: 'Resumo (obrigatório)',
        EXTERNAL_LINK_REQUIRED: 'Link externo (obrigatório)',
        UPLOAD_IMAGE: 'Carregar uma imagem',
        EXTERNAL_LINK: 'link externo',
        ENTER_DETAILS: 'Digite os detalhes'
    },
    USEFUL_LINKS: {
        USEFUL_LINKS: 'Links úteis',
        CATEGORY: 'Categoria',
        LINK: 'Link',
        TITLE: 'Título',
        NO_RECORD_FOUND: 'Nenhum links úteis encontrados.',
    },
    ORTHOTIC_REPORT: {
        ORTHOTIC_REPORT_LABEL: 'Relatório Ortopédico'
    },
    REPORTS: {
        NO_REPORTS_FOUND: 'Nenhum relatório encontrado.',
    },
    AUTO_FILL_VALUES: {
        SELECT_PARENT: 'Selecione um pai',
        SELECT_CHILD: 'Selecione uma criança',
        PARENT_FIELDS: 'Campos de objetos parentais',
        CHILD_FIELDS: 'Campos de Objetos infantis',
    },
    LANDING_PAGE: {
        LANDING_PAGE: 'Página de destino',
        LANDING_PAGES: 'Páginas de destino',
        NAME_REQUIRED: 'Nome (obrigatório)',
        URL_REQUIRED: 'URL (obrigatório)',
        LANDING_PAGE_FORMS: 'Construtor de formulários',
        EDIT_LANDING_PAGE: 'Editar página de destino',
        COPY_LANDING_PAGE: 'Copiar página de destino'
    },
    FIND_DUPLICATES: {
        FIND_DUPLICATES: 'Encontre duplicados',
        SELECT_OBJECT: 'Selecione objeto',
        SELECT_FIELD: 'Selecionar campo',
    },
    SCHEDULER: {
        APPOINTMENT_SCHEDULER: 'Agendador de consultas',
        APPOINTMENT_INFO: 'Informações de Marcação',
        AVAILABILITY:'Disponibilidade',
        APPOINTMENT_TYPES:'Tipos de compromisso',
        MY_AVAILABILITY: 'Minha Disponibilidade',
        SCHEDULER_LINK: 'Link do agendador',
        INTAKE_FORM: 'Formulário de advisão',
        APPOINTMENT_NAME: 'Nome de nomeação (obrigatório)',
        LOCATION: 'Localização',
        TASKS:'Tarefas',
        UNIT_CRM:'Unidade CRM',
        NOTIFICATION_SETUP:'Configuração de notificação',
        DURATION: 'Duração',
        MIN_ADVANCE_NOTICE: 'Aviso Prévio Mínimo ',
        DESCRIPTION: 'Descrição',
        COPY_TO_CLIPBOARD: 'Copiar para a minha prancheta',
        SCHEDULER_LINK_TITLE: 'A minha ligação de agendar de consultas',
        SCHEDULING_PAGE: 'Agendador de compromissos',
        ADD_BUTTON: 'Adicione um novo horário',
        MY_SCHEDULAR_LINK: 'My Scheduler Link',
        MY_APPOINTMENT_TYPE: 'Meu tipo de compromisso',
        APPOINTMENT_TYPE: 'Tipo de compromisso',
        DEFINE_YOUR_INTAKE_FORM: 'Defina o seu formulário de admissão',
        REQUIRED: 'Requeridos',
        ADD_ANOTHER_FIELD: 'Adicionar outro campo',
        SUBMIT_BUTTON_LABEL: 'Etiqueta do botão de envio',
        CHOOSE_A_STATUS_FOR_LEAD: 'Escolha um status para os leads adicionados a partir do agendador',
        DEFINE_YOUR_THANK_YOU_MESSAGE: 'Defina sua mensagem de agradecimento',
        THANK_YOU_MESSAGE: 'Obrigado mensagem',
    },
    RELATION_REPORT: {
        RELATION_REPORT_LABEL: 'Matriz de relação',
        REPORT_SAVE_LABEL: 'Salvar personalização',
        REPORT_NAME_LABEL: 'Nome do relatório personalizado',
        REPORT_NAME_TEXTFIELD_LABEL: 'Nome do relatório',
        ROW_FIELD_LABEL: 'Campo de linha',
        COL_FIELD_LABEL: 'Campo da coluna',
        DATE_FIELD_LABEL: 'Campo Data',
        PDF_LABEL_DURATION: 'Duração',
        RELATION_FIELD_LABEL: 'Relação',
        SAVE_CUSTOMIZATION_LABEL: 'Salvar personalização',
        RUN_BUTTON_LABEL: 'Corre'
    },
    COMPANY_PROFILE: {
        UPLOAD_LOGO: 'Faça upload do seu logotipo',
        WEBSITE: 'Site',
        BUSINESS_NAME: 'Nome comercial',
        BUSINESS_ADDRESS: 'Endereço de negócios',
        BUSINESS_PHONE: 'Telefone de negócios',
        BUSINESS_EMAIL: 'Email de Negócios',
        CORPORATE_CURRENCY: 'Moeda Corporativa'
    },
    EXPORT_SETTINGS: {
        EXPORT_HEADING: 'Exportar',
        FILE_NAME_REQUIRED: 'Nome do arquivo (obrigatório)',
        PARENT_OBJECT_REQUIRED: 'Nome do Objeto Pai (obrigatório)',
        FILE_TYPE_REQUIRED: 'Tipo de arquivo (obrigatório)',
        QUERY_NAME_REQUIRED: 'Nome da consulta (obrigatório)',
        SELECTED_FIELDS_FOR_PARENT: 'Campos selecionados para pai',
        AVAILABLE_FIELDS_FOR_PARENT: 'Campos disponíveis para pai',
        CHILD_OBJECT_NAME: 'Nome do objeto filho',
        AVAILABLE_FIELDS_FOR_CHILD: 'Campos disponíveis para criança',
        SELECTED_FIELDS_FOR_CHILD: 'Campos selecionados para filho',
        EXPORT_BUTTON: 'Exportar',
        DOWNLOAD_BUTTON: 'download de arquivos',
        ADD: 'Adicionar',
        ADD_ALL: 'Adicionar todos',
        REMOVE: 'Remover',
        REMOVE_ALL: 'Deletar tudo'
    },
    QUEUE: {
        QUEUE: 'Fila',
        PREVIOUS: 'Anterior na fila',
        NEXT: 'A seguir na fila',
        REMOVE: 'Retire da fila',
        CLOSE: 'Saia da fila',
    },
    SECURITY_LEVEL: {
        ACCESS: 'Acesso',
        DESCRIPTION: 'Descrição',
        VIEW: 'Visão',
        PERMISSIONS: 'Permissões',
        OPERATIONS: 'Operações',
        ADMINISTRATION: 'Administração',
    },
    ROLL_UP: {
        CHILD_OBJECT_NAME: 'objeto criança',
        PARENT_OBJECT_NAME: 'objeto pai',
        CHILD_FIELD_NAME: 'Criança Nome do campo',
        PARENT_FIELD_NAME: 'Pai Nome do campo',
    },
    ROYALTY_REPORT: {
        ROYALTY_REPORT_LABEL: 'Relatório da Realeza',
        ROYALTY_PERCENT: 'Royalties %',
        SHOW: 'programa',
        ALL_INVOICES: 'Todas as Faturas',
        PAID_INVOICE_ONLY: 'Faturas Pagas Apenas',
        SETUP_TITLE: 'Instalação de royalties',
        PAID_INVOICES: 'Faturas Pagas',
        USE: 'Uso',
        FIXED: '«% fixo',
        VARIABLE: '% variável',
        TOTAL_INVOICES: 'Total de faturas',
        TOTAL_PAYMENTS: 'Pagamentos totais',
        CALCULATE: 'Calcular',
        WEEKLY: 'Semanalmente',
        MONTHLY: 'Por mês',
        WEEK_TYPE: 'Tipo de semana',
        MON_TO_FRI: '2a. A 6a',
        MON_TO_SUN: 'Seg - Sun.',
        ADD_INVOICES: 'Adicionar fatura',
        TOTAL_Reported_Sales: 'Total de vendas relatadas',
        YEAR: 'Ano',
        MARKETING_FEE: 'Taxa de marketing',
        TECH_FEE: 'Taxa de tecnologia',
        PERCENT: 'Percentagem',
        FIXED_VALUE: 'Fixo',
        WITH: 'Com taxas',
        WITH_OUT: 'Sem taxas',
        Reported_Sales_Franchisee: 'Vendas informadas - Franqueado',
        Reported_Sales_Franchisor: 'Vendas informadas - Franqueador',
        Reported_Sales_ThirdParty: 'Vendas informadas - Terceiros',
    },
    POWER_CALLING: {
      NOTE_AND_CALL_SCRIPTS: 'Scripts de notas e chamadas',
      SEND: 'ENVIAR',
      LOG_CALL:'Registrar chamada',
      CALLER_ID: 'Identificador de chamadas',
      END_CALL: 'Terminar Chamada',
      MOBILE: 'Móvel',
      PHONE: 'Telefone',
      HOME_PHONE:'Telefone residencial',
      NEXT: 'Next',
      COMPLETE: 'completo',
      OF: 'de',
      POWER_DIALER: 'Power Dialer'
    },
    DUPLICATE_RULE: {
        DUPLICATE_RULE: 'Regras duplicadas',
        DUPLICATE_RULE_HEAD_LABEL: 'Verifique Duplicatas Usando',
        SELECT_OBJECT_LABEL: 'Selecione objeto',
        ACTIVE: 'Ativo',
        DEFAULT: 'Padrão',
        ONE_FIELD: 'um campo',
        TWO_FIELD: 'dois campos',
        THREE_FIELD: 'três campos',
        FIELD_ONE: 'campo 1',
        FIELD_TWO: 'campo 2',
        FIELD_THREE: 'campo 3',
        SELECT_FIELD_LABEL: 'Seleccionar campo',
        CHECK_DUPLICATES: 'Verifique duplicatas',
        SELECT_RULES: 'Selecione uma regra',
    },
    JOBS:{
        RESCHEDULE: 'Reprogramar',
        OMW:'ai',
        INVOICE:'Fatura',
        PAY:'Pagar'
    },
    TASK_REPORT:{
        TITLE:'Relatório de tarefas',
        UNITS:'Unidades',
        NO_TASK_FOUND:'Nenhuma tarefa encontrada',
    },
    AUDIT_REPORT: {
        AUDIT_SCORE_DETAILS : 'Detalhes do relatório de tarefas',
    },
    AUDIT_QUESTIONS: {
        MINIMUM_OPTIONS: 'Deve pelo menos 2 escolhas deve ser nesta questão',
        SINGLE_RECORD: 'Apenas 1 registro é permitido se mover de cada vez.',
        AUDIT_QUESTIONS: "Lista de verificação de auditoria",
        GIVE_FEEDBACK:'Dê retorno',
        NEW_QUESTION: 'Nova pergunta',
        TEXT: 'Pequeno texto',
        COMMENT: 'Texto longo',
        LIST: 'Escolha única (suspenso)',
        CHECKBOX: 'Múltipla escolha (caixas de seleção).',
        QUESTION: 'Pergunta',
        ADD_NEW: 'Adicionar novo',
        ADD_NEW_CATEGORY:'Add a New Category',
        SINGLE_RECORD_EDIT: 'Por favor, selecione 1 registro para editar.',
        MESSAGE_DELETE: 'Você tem certeza deseja excluir',
        SELECTED_RECORDS: 'Registro (s) selecionado (s)?',
        YOUR_ANSWER: "Sua Resposta",
        SUBMITTING: 'Enviando ...',
        SAVE_CATEGORY:'Salvar categoria',
        SUBMIT_AUDIT:'Enviar auditoria',
        SUBMIT: 'Enviar',
        SCORE: 'Pontuação',
        REQUIRED: 'Obrigatório',
        CHOICE: 'Escolha',
        FIELD_REQUIRED: 'Este campo é obrigatório *',
        NEW_CHOICE: 'Nova escolha',
        FREE_FORM: 'Forma livre',
        SCORE_HELP_TOOLTIP: "Atribuir pontos ou pontuações às respostas. As pontuações serão somadas para calcular a pontuação total para a auditoria.",
        SURVEY_LINK: "Link de auditoria / pesquisa",
        NEXT_CATEGORY:'Próxima categoria',
        GENERATE:'Gerar',
        FIELD_AUDIT:'Auditoria de campo (auditada pelo franquiador)',
        SELF_AUDIT:'Autoauditoria (auditada pelo franquiado)',
        GENERATE_AUDIT_LINK:'Gerar link de auditoria',
        REQUIRES_PHOTO: 'Requer foto',
        ENABLE_NOTE: 'Ativar nota',
        ADD_PHOTO: 'Photo',
        UPDATE_PHOTO: 'Atualização',
        NOTE: 'Adicione uma nota',
        PHOTO_REQUIRED: 'A foto é obrigatória *',
    },
    AUDIT_ANSWERS: {
        AUDIT_ANSWERS_LABEL: 'Resposta de auditoria',
        QUESTION:'Pergunta',
        SCORE: 'Pontuação',
        ANSWER: 'Responder',
        SUBMIT_CORRECTIVE_ACTIONS:'Enviar ações corretivas',
        REQUEST_CORRECTIVE_ACTIONS:'Solicitar ações corretivas',
        INSERT_PHOTO:'Inserir foto',
    },
    AUDIT_WEAKNESS:{
        CATEGORY:'Categoria',
        MIN_FAILURE_COUNT:'Contagem mínima de falhas',
    },
    COMPLIANCE_REPORT:{
        COMPLIANCE_REPORT_LABEL: 'Relatório de Conformidade',
    },
    SALES_AND_REFUND: {
        PERIOD:'Período',
        START_DATE:'Data de início',
        END_DATE :'Data final',
        BASED_ON :'Baseado em',
        SALES_AND_REFUND_TITLE:'Vendas e Reembolso',
        SALES_AND_REFUND_DETAIL:'Detalhes de vendas e reembolso',
    },
    SALES_AND_REFUND_DETAILS_REPORT:{
        SALES_AND_REFUND_DETAILS :'Detalhes do relatório de vendas e reembolso',
        START_DATE:'Data de início',
        END_DATE:'Data final',
        BASED_ON:'Baseado em',
        NO_RECORD_EXPORT: 'Nenhum Registro Encontrado'
    },
    TOTAL_REPORTED_SALES_DRILLDOWN:{
        TOTAL_REPORTED_SALES_DRILLDOWN:'Detalhamento de vendas relatadas de royalties',
    },
    OTHER_FORM_DATA: {
        CARETAKING:'Caretaking',
        EXPLANATION:'Explanation',
        EXPLANATION_HISTORY_TIMELINE:'Explanation of history/timeline (dates/places/etc)',
        PEOPLE_PLEASING:'People Pleasing',
        RELATIONSHIPS:'Relationships',
        PERFECTIONISM:'Perfectionism',
        CONTROL:'Control',
        WORKAHOLISM:'Workaholism',
        SHOPPING_SPENDING:'Shopping/Spending',
        RELIGION_OR_ABUSE:'Religion/Abuse',
        RISK_TAKING:'Risk Taking',
        EXCESSIVE_SCREEN_TIME:'Excessive Screen time',
        DEPRESSION:'Depression',
        ANXIETY:'Anxiety',
        ANGER_OR_RAGE:'Anger/Rage',
        SELF_EXTEEM:'Self Esteem',
        PAINFUL_OR_TROMATIC :'Describe any painful/traumatic/memorable experiences or events that have been impactful in your life (trauma)',
        MOTHER:'Mother',
        FATHER:'Father',
        SIBLINGS:'Siblings',
        CHILDREN:'Children',
        SIG_OTHER:'Sig. Other',
        OTHER:'Other',
        PRESENTING_PROBLEM:'Describe the main presenting problem or current crisis/goal for coming to TBR',
        ALCOHAL:'Alcohol',
        HOW_MUCH:'How Much & What type?',
        HOW_OFTEN:'How often, and for how long?',
        DATE_LAST_USED:'Date Last Used',
        COCAINE_OR_CRACK :'Cocaine/Crack',
        HEROINE:'Heroin',
        MARIJUANA:'Marijuana',
        METHADONE_OR_SUBOXONE :'Methadone/Suboxone',
        AMPHETAMINES :'Amphetamines & Stimulants (Meth, Adderall, Speed)',
        NARCOTICS_OR_OPIATES:'Narcotics/Opiates',
        BENZODIAZEPINES:'Benzodiazepines',
        TOBACCO:'Tobacco',
        OTHER_ETC:'Other (Hallucinogenic, OTC, Synthesis, etc)',
        WITHDRAWEL_SYMPTOMS :'Withdrawal Symptoms (shaking, sweating, tremors, seizures etc)',
        MEDICAL_DETOX:'Medical Detox',
        PRIMARY_TRATMENT_OR_IOP:'Primary Treatment/IOP',
        SUCIDE_THOUGHT:'Had suicidal Thoughts?',
        ENGAGED_IN_SELF_HARD:'Engaged in Self Hard',
        ATTEMPED_SUCIDE:'Attempted Suicide While under the influence?',
        ENGAGED_IN_CUTTING:'Engaged in "cutting" behaviors',
        ATTEMPED_SUCIDE_WHITE_SOBER:'Attempted suicide while Sober?',
        ENGEGED_IN_SCORINCATION :'Engaged in "scorincation"' ,
        HOSPITALIZED_FOR_A_SUICIDE_ATTEMPT:'Been hospitalized for a suicide attempt?',
        ENGEGED_IN_BODY_MUTATION:'Engaged in any other body mutation bx',
        HISTORY_EXPLANATION:'If history, explanation (use this space to provide further details, including dates, current ideation, thoughts or plans, etc.)',
        PHYCHIATRIC_DIANOSIS:'Psychiatric diagnosis',
        DATES_DIAGNOSIS_TREATMENT:'Date, diagnosis, treatment',
        MENTAL_BREAKDOWNS_OR_HOSPITALIZATIONS:'Mental Breakdowns/Hospitalizations' ,
        ANOREXIA:'Anorexia',
        TREATMENT_HISTORY:'Treatment History',
        PERIODS_OF_ABSTINENCE:'Periods of Abstinence',
        LAST_EPISODE:'Last Episode',
        LOWEST_WEIGHT:'Lowest Weight',
        HIGHEST_WEIGHT:'Highest Weight',
        CURRENT_WEIGHT_OR_HEIGHT: 'Current Weight/Height',
        HOW_LONG_AT_CURRENT_WEIGHT:'How Long at Current Weight',
        BULIMIA:'Bulimia',
        OVEREATING:'Overeating',
        EXCESSING_EXCERCISING:'Excessive Exercising',
        DETAILS_SOBRIETY_LENGTH_PATTERNS:'If history, explanation (use this space to provide further details, sobriety length, patterns etc.)',
        BETTING:'Betting',
        ONSET:'Onset',
        TREATMENT:'Treatment',
        AMOUNT_OF_DEBT_ACCUMULAATED:'Amount of Debt accumulated',
        TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING:'Time per day/week spent gambling',
        GAMING_OR_CASINOS :'Gaming/Casinos',
        ONLINE_GAMES_OR_APPS:'Online Games/Apps' ,
        PORNOGRAPHY:'Pornography',
        FIRED:'Have you ever been fired or had job threatened because of behavior?',
        EXCESSIVE_MASTURBATION:'Excessive Masturbation',
        VOYEURISM_OR_EXHIBITIONIST:'Voyeurism/Exhibitionist',
        ANONYMOUS_SEX:'Anonymous Sex (casual sex, one-night stands, hookup apps/websites)',
        PROSTITUTION:'Prostitution',
        AFFAIRS:'Affairs (Physical/Emotional)',
        SERIAL_RELATIONSHIP:'Serial Relationships',
        INCLUDE_DATE_TEASON_OR_CHARGE:'Include Date, Reason/Charge (What was the charge/reason for arrest), Result (was there a conviction, jail time served, probation/parole, sex offender registration etc), Current Pending/Legal Obligations',
        CODEPENDENCY_AND_PROCESS_ADDITIONS_BEHAVIOURS:'Codependency and process addictions behaviors ("C" for Current, "D" Denies or "H" for History)',
        RELATIONSHIP_AND_FAMILY_HISTORY:'Relationship/family history (List current relationship status and family history of addiction/psych issues)',
        SUBSTANCE_HISTORY:'Substance history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUBSTANCE_TREATMENT_HISTORY:'Substance treatment history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUICIDIAL_IDEATION:'Suicidal ideation / self harm behaviors ("C" for Current, "D" Denies or "H" for History)',
        MENTAL_HEALTH_AND_PSHYATRATIC_DIAGNOSIS_HISTORY :'Mental health/ psychiatric diagnosis history- ("C" for Current, "D" Denies or "H" for History)',
        EATING_DISORDER_BEHAVIER:'Eating disorder behaviors - ("C" for Current, "D" Denies or "H" for History)',
        GAMBLIMG_BEHAVIER:'Gambling behaviors - ("C" for Current, "D" Denies or "H" for History)',
        SEXUAL_RELATIONSHIP_LOVE_BEHAVIOUR:'Sexual/relationship/love behaviors- ("C" for Current, "D" Denies or "H" for History)',
        ARRESTED_HISTORY:'Arrest history (including prior/current and pending)',
    },
    PHONE_NUMBERS: {
        USE_NOTIFICATION_TOOLTIP: 'Usar para notificação',
        REMOVE_NOTIFICATION_TOOLTIP: 'Remover para notificação',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        MANAGE_UNIT_PHONE: 'Gerenciar números de telefone, créditos de texto e minutos de chamada da unidade',
    },
}
export default label
