import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import AssetsBody from '../components/assetsBody';
import AssetsMenu from '../components/assetsMenu';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { updateExpandedNode, updateSelectedNode, updateSelectedTree, updateDocumentMaxChildId, updateDocumentLibrary, updateisAssetsUpdated, updateDocumentObject, updateDocumentCallFrom } from '../../../services/actions/documentLibraryActions';
import { isValidParam, getStringParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { alfaNumSort } from '../../../services/helper/utils';
import { doRefreshAssets } from '../../../services/actions/headerActions';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import map from 'lodash/map';
import { constants } from '../../../services/constants/constants';
import PopOver from '../components/PopOver';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { groupByObjectArrayByProperty } from '../../../services/helper/utils';
import { getAssetsTreeItem } from '../../../services/helper/common';

const AssetsHome = ({ callFrom, object, files, styleConfig }) => {
    const [parentId, setParentId] = useState('0');
    const [mainDataArr, setMainDataArr] = useState([]);
    const [expandedNods, setExpandedNode] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [bodyTreeData, setBodyTreeData] = useState([]);
    const [isMount, setIsMount] = useState(false);
    const [selectedKey, setSelectedKey] = useState('');
    const [isAddDocument, setIsAddDocument] = useState(false);
    const [isAddFolder, setIsAddFolder] = useState(false);
    const [isRenameFolder, setIsRenameFolder] = useState(false);
    const [selectedLibrary, setSelectedLibrary] = useState('documents');
    const documentLibrary = useSelector((state) => state.documentLibrary);
    const refreshAssets = useSelector((state) => state.header.refreshAssets);

    const app = useSelector((state) => state.app);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateDocumentCallFrom(callFrom));
        dispatch(updateDocumentObject(object));
        getDocumentData(selectedLibrary);
    }, []);

    useEffect(() => {
        if (documentLibrary.isAssetsUpdated) {
            setMainDataArr(documentLibrary.mainData)
            dispatch(updateisAssetsUpdated(false));
            prepareDataSet(documentLibrary.mainData, 'update', documentLibrary.selectedNode);
        }
    }, [documentLibrary, bodyTreeData]);
    useEffect(() => {
        if (refreshAssets) {
            dispatch(doRefreshAssets(false));
            getDocumentData(selectedLibrary);
        }
    }, [refreshAssets]);

    const callBackData = (callFrom, data) => {
        let _mainDataArr = [...documentLibrary.mainData];
        if (callFrom == 'nodeSelecte' || callFrom == 'nodeExpand') {

            let _isShared = false;
            let _item = _mainDataArr.find((i) => i.key == data);
            _item?.children.map((item) => {
                if (!_isShared) {
                    _isShared = item.isShared;
                }
            })

            let index = _mainDataArr.findIndex((assetObj) => assetObj.parentId == _item.childId);
            if (index == -1 || _isShared) {
                let params = {
                    parentId: _item.childId.toString(),
                    type: 'all'
                }
                var assetesPromise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.ASSETS_GET, params));
                assetesPromise.then((assetsResponse) => {
                    _mainDataArr = [..._mainDataArr, ...assetsResponse];
                    _mainDataArr = _mainDataArr?.filter((obj, index) => {
                        return index === _mainDataArr?.findIndex((sObj) => obj.key === sObj.key);
                    });
                    dispatch(updateDocumentLibrary(_mainDataArr));
                    setMainDataArr(_mainDataArr);
                    prepareDataSet(_mainDataArr, callFrom, data);
                    setSelectedKey(data);

                });
            } else {
                prepareDataSet(_mainDataArr, callFrom, data);
                setSelectedKey(data);
            }
        }
    }

    const getDocumentData = (libraryName) => {
        try {
            setIsMount(false);
            if (libraryName == constants.IMAGES_LIBRARY) {
                let params = { type: 111 }
                var imgLibPromise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.LIBRARY_IMAGES_GET, params));
                imgLibPromise.then((imgLibResponse) => {
                    dispatch(updateDocumentLibrary(imgLibResponse));
                    setMainDataArr(imgLibResponse);
                    prepareDataSet(imgLibResponse, 'getDocumentData');
                });
            } else {
                let params = {
                    parentId: parentId,
                    type: 'all'
                }
                var assetesPromise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.ASSETS_GET, params));
                assetesPromise.then((assetsResponse) => {
                    dispatch(updateDocumentLibrary(assetsResponse));
                    setMainDataArr(assetsResponse);
                    prepareDataSet(assetsResponse, 'getDocumentData');
                });
            }
        } catch (error) {
            console.error("Error on assetsHome.js >> getDocumentData: " + error);
        }
    }

    const prepareDataSet = (dataArray, fncCallFrom, selectedNodeKey) => {
        let nodeObj = {};
        let nodeData = [];
        let maxChildId = 0;
        let assetBodyArry = [...bodyTreeData];
        let selectedFolder = '';
        let _expandedNods = [];
        let boolGroupedData = groupByObjectArrayByProperty(dataArray, 'isShared');
        let rootDataArr = boolGroupedData['false'];
        if (isValidParam(rootDataArr) && getArrayParam(rootDataArr).length > 0) {
            rootDataArr = rootDataArr;
        } else {
            rootDataArr = [];
        }
        let sharedWithMeArray = boolGroupedData['true'];
        if (isValidParam(sharedWithMeArray) && getArrayParam(sharedWithMeArray).length > 0) {
            sharedWithMeArray.map((item, index) => {
                let isContainFolder = dataArray.filter(e => e.childId == item.parentId).length > 0 ? true : false;
                if (!isContainFolder && item.parentId > 0 && app.me.is_franchisee) {
                    item.parentId = -999;
                }
            });
            dataArray = [...rootDataArr, ...sharedWithMeArray];
        }

        if (isValidParam(dataArray) && getArrayParam(dataArray).length > 0) {
            let groupedData = groupByObjectArrayByProperty(dataArray, 'parentId');
            let selectedMenuKeys = [];
            let children = [];
            let rootFolder = groupedData['0'];
            rootFolder.map((subFolder) => {
                selectedNodeKey = selectedNodeKey || subFolder.key;
                children = prepareChilAssetsData(groupedData[subFolder.childId], groupedData);
                selectedMenuKeys.push(getStringParam(selectedNodeKey));
                subFolder.uniqueKeys = subFolder.key
                subFolder.children = isValidParam(children) ? children : [];
                let index = expandedNods.findIndex((j) => j == subFolder.key);
                if (index > -1) {
                    subFolder.expanded = true;
                }
                selectedFolder = selectedMenuKeys;
                maxChildId = subFolder.maxChildId
                nodeData.push(subFolder);
            });

            nodeObj.nodeData = nodeData;
            nodeObj.selectedKeys = selectedMenuKeys;
            let selectedFolderObj = dataArray.find(f => {
                return f.key === selectedNodeKey;
            });
            if (fncCallFrom == 'getDocumentData') {
                _expandedNods = [...expandedNods];
                _expandedNods.push(...selectedMenuKeys);
                dispatch(updateExpandedNode(_expandedNods));
                setExpandedNode([..._expandedNods]);
            }
            if (fncCallFrom != 'nodeExpand') {
               if (!selectedFolderObj.isLeaf){
                assetBodyArry = prepareChilAssetsData(groupedData[selectedFolderObj?.childId], groupedData);
                dispatch(updateSelectedTree(assetBodyArry));
               }
                setBodyTreeData(assetBodyArry);
                if (fncCallFrom == 'update') {
                    setExpandedNode(documentLibrary.expandedNode);
                }
            }
        }
        dispatch(updateDocumentMaxChildId(maxChildId));
        dispatch(updateSelectedNode(selectedNodeKey));
        setSelectedKey(selectedNodeKey)
        setTreeData(nodeObj);
        setIsMount(true);
    }

    const treeSort = (treeArray) => {
        let tempArr = [];
        if (isValidParam(treeArray) && getArrayParam(treeArray).length > 0) {
            let folderArr = treeArray.filter((e) => { return e.t_type == 'folder' });
            folderArr = alfaNumSort(folderArr, 'name');
            let fileArr = treeArray.filter((e) => { return e.t_type != 'folder' });
            fileArr = alfaNumSort(fileArr, 'name');
            tempArr = folderArr.concat(fileArr)
        }
        return tempArr;
    }

    const prepareChilAssetsData = (chilDatadArr, groupedData) => {
        if (getArrayParam(chilDatadArr).length > 0) {
            chilDatadArr.forEach((item, index) => {
                let itemObj = item;
                if (item.t_type == "folder") {
                    itemObj.uniqueKeys = item.key;
                    let index = expandedNods.findIndex((j) => j == item.key);
                    if (index > -1) {
                        itemObj.expanded = true;
                    }
                    itemObj.children = isValidParam(groupedData[item.childId]) && item.childId !== item.parentId ? prepareChilAssetsData(groupedData[item.childId], groupedData) : [];
                }
                return itemObj;
            });
            chilDatadArr = treeSort(chilDatadArr);
            return chilDatadArr;
        }

        else {

            return [];
        }
    }

    const addDocument = (isAdd) => {
        setIsAddDocument(isAdd)
    }

    const addFolder = (isAdd) => {
        setIsAddFolder(isAdd)
    }

    const renameFolder = (isRename) => {
        setIsRenameFolder(isRename)
    }

    const setLoading = (mountedObject) => {
        setIsMount(mountedObject)
    }

    const changeLibrary = (value) => {
        if (selectedLibrary != value) {
            setSelectedLibrary(value);
            getDocumentData(value)
        }
    }

    const getMenuItems = () => {
        let itemsArr = [];
        let items = [];
        let checkIcon = null;
        try {
            itemsArr.push({ value: 'documents', text: getLocalizedStrings().label.DOCUMENT_LIBRARY.DOCUMENT_AND_FILE, rightIconClassName: 'fas fa-folder' });
            itemsArr.push({ value: 'images', text: getLocalizedStrings().label.DOCUMENT_LIBRARY.IMAGES, rightIconClassName: 'fas fa-file-image' });

            map(itemsArr, function (item, i) {
                checkIcon = selectedLibrary == item.value ? 'fa fa-check' : null;

                let data = new Object();
                data.label = item.text;
                data.value = item.value;
                data.leftIcon = <i className={item.rightIconClassName} />;
                data.rightIcon = <i className={checkIcon} />;
                items.push(data);
            });
        } catch (error) {
            console.error("Error in 'assetsHome.js -> getMenuItems()':" + error);
        }
        return items;
    }

    let iconClassName = selectedLibrary == constants.IMAGES_LIBRARY ? 'fas fa-file-image' : 'fas fa-folder';
    let icontitle = selectedLibrary == constants.IMAGES_LIBRARY ? getLocalizedStrings().label.DOCUMENT_LIBRARY.IMAGES : getLocalizedStrings().label.DOCUMENT_LIBRARY.DOCUMENT_AND_FILE;
    let titleIconStyle = { float: 'left', marginTop: '3px', fontSize: '14px', marginLeft: '-6px', marginRight: '10px' };

    if (isMount) {
        return (
            <>
                <div>
                    <div style={{ ...styles.row, padding: '0px 27px 8px 27px' }}>
                        {callFrom == 'drawer' &&
                            <div className='libraryhead' style={{ float: 'left', height: 31 }}>
                                <i className={iconClassName} style={titleIconStyle} />
                                <span style={{ marginTop: '-1px', display: 'inline-block', marginLeft: '5px', fontSize: '24px' }} title={icontitle}>{icontitle}</span>
                            </div>}
                        {callFrom != 'editor' && <div style={{ ...styles.sf_12 }}>
                            <div style={{ float: 'right' }}>
                                <span style={{ marginRight: '10px' }}>{getLocalizedStrings().label.COMMON.VIEW_MODE1}</span>
                                <div style={{ float: 'right', marginRight: '5px' }}>
                                    <PopOver
                                        id={"PopOver"}
                                        key={"PopOver"}
                                        name={'PopOver'}
                                        btnType={'icon-div'}
                                        buttonEndIcon={"keyboard_arrow_down"}
                                        buttonStyle={{ fontSize: '16px' }}
                                        containerStyle={{ lineHeight: 1, marginTop: 0 }}
                                        buttonLineOrigin={[30, 145]}
                                        buttonLabel={<i className={iconClassName} style={{ fontSize: 16, color: '#717171', cursor: 'pointer', paddingLeft: 2, paddingTop: 2 }} />}
                                        isShowIcon={true}
                                        options={getMenuItems()}
                                        value={selectedLibrary}
                                        onclickAction={(value) => changeLibrary(value)}
                                        innerIcon={'left'}
                                        viewSelectedMark={true}
                                        innerLabelStyle={{ padding: "1 1 1 1", fontSize: '15px' }}
                                        height={27}
                                        downArrowStyle={{ padding: '3px 6px', margin: '-3px 0px 0px 4px' }}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>

                    <div style={{ ...styles.row, padding: styleConfig?.padding ? '0px 30px' : '0px' }}>
                        <div style={{ ...styles.bg_white, width: '30%' }}>
                            <AssetsMenu
                                callFrom={callFrom}
                                menuData={treeData}
                                callBack={(callFrom, data) => callBackData(callFrom, data)}
                                setExpandedNode={setExpandedNode}
                                expandedNods={expandedNods}
                                defaultSelected={selectedKey}
                                files={files}
                                mainDataArr={mainDataArr}
                                selectedLibrary={selectedLibrary}
                                isAddDocument={addDocument}
                                isAddFolder={(isAdd) => addFolder(isAdd)}
                                isRenameFolder={(isRename) => renameFolder(isRename)}
                                setLoading={setLoading}
                            />
                        </div>
                        <div style={{ ...styles.bg_white, width: '70%' }}>
                            <AssetsBody
                                callFrom={callFrom}
                                treeData={bodyTreeData}
                                isAddDocument={isAddDocument}
                                callBack={(callFrom, data) => callBackData(callFrom, data)}
                                isAddFolder={isAddFolder}
                                isRenameFolder={isRenameFolder}
                                files={files}
                                selectedLibrary={selectedLibrary}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

}

export default AssetsHome;