import { getTopUpDialog } from '../../../services/actions/topUpDialogActions';
import store from "../../../services/store";
import { constants } from "../../../services/constants/constants";


function convertToText(htmlArr) {
    let vfieldLabel = '';
    let length = htmlArr.length;
    if (length > 0) {
        for (let j = 0; j < length; j++) {
            vfieldLabel += htmlArr[j];
        }
    } else {
        vfieldLabel = htmlArr[0]
    }
    return vfieldLabel
}

function alert(title, message, accept, style) {
    let html = null;
    if (typeof message === 'undefined') {
        html = title;
        title = 'Alert';
    } else {
        if (typeof message === 'object') {
            if (html != null) {
                html = message;
            } else {
                html = message
            }
        } else {
            html = message
        }
    }
    let data = {};
    let actions = {};
    data.message = html;
    data.object = constants.SF_ALERT_DIALOG;
    actions.accept = accept;
    actions.deny = null;
    store.dispatch(getTopUpDialog(true, constants.SF_ALERT_DIALOG, title, null, data,actions, 'alert', style));
}

function singleAlert(title, message, action, style) {
    let html = null;
    if (typeof message === 'undefined') {
        html = title;
        title = 'Alert';
    } else {
        if (typeof message === 'object') {
            if (html != null) {
                html = message;
            } else {
                html = message
            }
        } else {
            html = message
        }
    }
    let data = {};
    let actions = {};
    data.message = html;
    data.object = constants.SF_ALERT_DIALOG;
    actions.accept = action;
    actions.deny = action;
    store.dispatch(getTopUpDialog(true, constants.SF_ALERT_DIALOG, title, null, data,actions, 'single-alert', style));
}

function confirm(title, message, accept, deny) {
    if (typeof message === 'undefined') {
        title = 'Confirm';
    } else {
        if (typeof message === 'object') {
            message = message;
        }
    }

    let data = {};
    let actions = {};
    data.message = message;
    data.object = constants.SF_ALERT_DIALOG;
    actions.accept = accept;
    actions.deny = deny;
    store.dispatch(getTopUpDialog(true, constants.SF_ALERT_DIALOG, title, null, data,actions, 'confirm'));

}


export {
    alert,
    confirm,
    singleAlert,
    convertToText
}
