import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ShowCircularProgress from '../components/circularProgress';
import Switch from '@mui/material/Switch';
import { isValidEmail } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { hideSnackBar, showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { groupByObjectArrayByProperty } from '../../../services/helper/utils';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, TextField, Icon, IconButton, Alert, Slide, Snackbar, Typography } from '@mui/material';
import * as sfDialogs from '../components/sfDialogs';
import { getObjectParam, getStringParam, isValidParam } from '../../../services/helper/parameterVerifier';
import SFCustomDialog from "./../containers/sfCustomDialog";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { setAuditId } from '../../../services/actions/auditActions';
import { updateAuditAnswers, updateAuditPhotos, updateAuditAnswerIds } from '../../../services/actions/auditActions';
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';

const formStyles = {
    page: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '.5em'
    },
    textStyle: {
        clear: 'both',
        margin: '5px 0px 10px 15px'
    },
    boxStyle: {
        background: '#ffffff',
        borderRadius: '10px',
        border: '.03em solid lightgrey',
        margin: '0 auto',
        padding: '1em',
        clear: 'both',
        marginBottom: '.5em',
    },
    required: {
        fontWeight: 'bolder',
        color: 'red'
    },
    errorMessage: {
        color: 'red'
    },
    photoErrorMessage: {
        color: 'red',
        display: 'flex',
        fontSize: '14px',
        marginTop: '5px'
    },
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 2,
        cursor: 'pointer'
    },
    backdropLoader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    }
}

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: green[600],
      '&:hover': {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: green[600],
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const MobileAuditQuiz = ({ location }) => {
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);
    const [value, setValue] = useState(0);
    const [urlParams] = useState(new URLSearchParams(window.location.hash.split("audit-questions")[1]));
    const [questions, setQuestions] = useState({});
    const [error, setError] = useState('');
    const [photoError, setPhotoError] = useState('');
    const [logo, setLogo] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSubmitProcessing,setIsSubmitProcessing] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.screen.width > 700 ? '50%' : '90%');
    const fileInput = useRef(null);
    const filePhoto = useRef(null);
    const scrollref = useRef(null);
    const scrollToTop = useRef(null);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
    const [category, setCategory] = useState('');
    const [CategoryList, setCategoryList] = useState([]);
    const [isChangesAvailable,setIsChangesAvailable] = useState(false);
	const [snackBarOpenStatus, setSnackBarOpenStatus] = useState(false);
	const [snackBarOpenDuration, setSnackBarOpenDuration] = useState(2000);
	const snackBar = useSelector((state) => state.snackBar);
    const [audit_name,setAudit_name] = useState('');
    const [audit_email,setAudit_email] = useState('');
    const [isFeildAudit,setisFieldAudit] = useState(false);
    const [auditTypeError,setAuditTypeError] = useState({
        isAuditNameEmpty :false,
        isAuditEmailEmpty:false,
    });

    const { AuditId, answers, photos, AuditAnswerIds } = useSelector((state) => state.auditDetails);
    
    const contentHeight = window.innerHeight - 240;
    const top = (contentHeight - 10) / 2;
    const color = urlParams.get('c') ? '#' + urlParams.get('c') : 'lightgrey';
    const _logo = (!logo || logo === "") ? "/asset/images/soffront_logo.png" : logo;
    const style = { backgroundColor: color }


    useEffect(() => {
        window.addEventListener("resize", () => handleResize());

        const response = Promise.resolve(HTTPClient.getWithoutToken(endPoints.AUDIT_QUESTIONS.GET_AUDIT_QUESTIONS, {
            encodedParams: urlParams.get('p')
        }));
        response.then((response) => {
            if(response.hasOwnProperty('is_field_audit')){
                let isAudit = response.is_field_audit === 1 ? true : false;
                setisFieldAudit(isAudit);
            }
            if (response.hasOwnProperty('questions')) {
                let tempData = response.questions.map((element, index) => {
                    return { ...element, qNo: index + 1 }
                })
                let groupedData = groupByObjectArrayByProperty(tempData, 'categoryName');
                setQuestions(groupedData);
                setMounted(true);
                dispatch(updateAuditAnswers(groupedData));
                setLogo(response.logo);
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.AUDIT_QUESTIONS.NO_QUESTIONS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        });

        return () => {
            window.addEventListener("resize", null);
        }
    }, [dispatch, urlParams]);

    useEffect(() => {
        let _categories = { ...questions };
        let Categorykeys = Object.keys(_categories);
        let CategoryList = [];
        Categorykeys.map((ele) => {
            let tempData = {};
            tempData.label = ele;
            tempData.value = ele;
            CategoryList.push(tempData);
        })
        setCategoryList(CategoryList);
        if (!category) {
            setCategory(CategoryList[0]?.value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions, value])

    useEffect(() => {
		let delay = 2000;
		if (snackBar?.snackbar?.duration !== null) {
			delay = parseInt(snackBar.snackbar.duration);
		}
		setSnackBarOpenDuration(delay);
		setSnackBarOpenStatus(snackBar.snackbar.open);
		if (snackBar.snackbar.open) {
			setTimeout(() => {
				setSnackBarOpenStatus(false);
				dispatch(hideSnackBar());
			}, delay);
		}
	}, [dispatch, snackBar])

    const handleResize = () => {
        setScreenWidth(window.screen.width > 700 ? '50%' : '90%');
    }

    const updateAnswers = (event, key, questionId, selectionId = null, isList = false) => {
        let tempAnswers = { ...answers };
        if (selectionId !== null && !isList) {
            tempAnswers[key][questionId].items[selectionId].status = event.target.checked && ('selected' || 'Active');
        }
        if (selectionId !== null && isList) {
            tempAnswers[key][questionId].items.forEach((v, i) => {
                tempAnswers[key][questionId].items[i].status = 'Active';
            })
            if (selectionId > -1) {
                tempAnswers[key][questionId].items[selectionId].status = 'selected'
            }
        }
        if (selectionId === null && !isList) {
            tempAnswers[key][questionId].items[0].items = event.target.value;
        }
        setIsChangesAvailable(true);
        setError('');
        dispatch(updateAuditAnswers(tempAnswers));
    }

    const handleAnswersPassedChange = (event, key, index,questionId) =>{
        let tempAnswers = { ...answers };
        tempAnswers[key][index].corrective_action_required = event.target.checked;
        setIsChangesAvailable(true);
        dispatch(updateAuditAnswers(tempAnswers));
    }

    const validateForm = () => {
        let isVerificationPassed = true
        let _answers = { ...answers };
        let requiredCategoriesList = {};
        let selected_category = [];
        let error = -1;
        let photoError = -1;
        let id = null;
        let fieldId = null;
        let btnId = null;
        let loopBreak = false;
        let fieldIds = [];
        let btnIds = [];
        let ids =[];
        let _auditTypeError = auditTypeError;
        if((!audit_email || !audit_name) && !isFeildAudit){
            let message = "";
            if(!audit_email && !audit_name){
                message = getLocalizedStrings().message.AUDIT_QUESTIONS.EMPTY_AUDIT_BY_NAME_AND_EMAIL;
                _auditTypeError.isAuditEmailEmpty = true;
                _auditTypeError.isAuditNameEmpty = true;
            }else if(!audit_name){
                message = getLocalizedStrings().message.AUDIT_QUESTIONS.EMPTY_AUDIT_BY_NAME;
                _auditTypeError.isAuditNameEmpty = true;
            }else if(!audit_email){
                message = getLocalizedStrings().message.AUDIT_QUESTIONS.EMPTY_AUDIT_BY_EMAIL;
                _auditTypeError.isAuditEmailEmpty = true;
            }
            sfDialogs.alert(message, getLocalizedStrings().message.AUDIT_QUESTIONS.RECEIVED_FEEDBACK, () => {
                setAuditTypeError({..._auditTypeError});
                scrollref.current?.scrollIntoView({ behavior: 'smooth' });
            }, style);
        }else if(!isValidEmail(audit_email) && !isFeildAudit ){
            let message ='';
            message = getLocalizedStrings().message.AUDIT_QUESTIONS.INVALID_AUDIT_EMAIL;
            _auditTypeError.isAuditEmailEmpty = true;
            sfDialogs.alert(message, getLocalizedStrings().message.AUDIT_QUESTIONS.RECEIVED_FEEDBACK, () => {
                setAuditTypeError({..._auditTypeError});
                scrollref.current?.scrollIntoView({ behavior: 'smooth' });
            },style);
        }else if(!isValidAuditName(audit_name) && !isFeildAudit){
            let message ='';
            message = getLocalizedStrings().message.AUDIT_QUESTIONS.INVALID_AUDIT_NAME;
            _auditTypeError.isAuditNameEmpty = true;
            sfDialogs.alert(message, getLocalizedStrings().message.AUDIT_QUESTIONS.RECEIVED_FEEDBACK, () => {
                setAuditTypeError({..._auditTypeError});
                scrollref.current?.scrollIntoView({ behavior: 'smooth' });
            }, style);
        }
        else{
            for (const key in _answers) {
                for (let i = 0; i < _answers[key].length; i++) {
                    if (_answers[key][i].required && !loopBreak) {
                        if (_answers[key][i].type === 'Text' || _answers[key][i].type === 'Comment') {
                            if (!_answers[key][i].items[0].items) {
                                selected_category.push(_answers[key][i]?.categoryName);
                                isVerificationPassed = false;
                                requiredCategoriesList[key] = 'required';
                                error = i;
                                id = 'element-' + key.replaceAll(' ', '_') + '-' + _answers[key][i].questions_id;
                                fieldId = 'field-element-' + key.replaceAll(' ', '_') + '-' + _answers[key][i].questions_id;
                                ids.push(id);
                                fieldIds.push(fieldId);
                                // loopBreak = true;
                            }
                        }
                        if (_answers[key][i].type === 'List' || _answers[key][i].type === 'Checkbox') {
                            if (!_answers[key][i].items.some((k) => {
                                return k.status === 'selected'
                            })) {
                                isVerificationPassed = false
                                requiredCategoriesList[key] = 'required';
                                error = i;
                                id = 'element-' + key.replaceAll(' ', '_') + '-' + _answers[key][i].questions_id;
                                fieldId = 'field-element-' + key.replaceAll(' ', '_') + '-' + _answers[key][i].questions_id;
                                // loopBreak = true;
                                ids.push(id);
                                fieldIds.push(fieldId);
                                selected_category.push(_answers[key][i]?.categoryName);
                            }
                        }
                    }

                    if (_answers[key][i].requiresPhoto && !loopBreak) {
                        if (!_answers[key][i].photo || _answers[key][i].photo?.length === 0) {
                            isVerificationPassed = false
                            requiredCategoriesList[key] = 'required';
                            photoError = i;
                            btnId = 'btn-element-' + key.replaceAll(' ', '_') + '-' + _answers[key][i].questions_id;
                            // loopBreak = true;
                            btnIds.push(btnId);
                            selected_category.push(_answers[key][i]?.categoryName);
                        }
                    }
                }

            let indexOfcategory = 0;
            Object.keys(questions)?.map((ele, idx) => {
                if (ele === (selected_category[0] ? selected_category[0]:'')) {
                    indexOfcategory = idx;
                }
            })
            if (indexOfcategory !== undefined && !isVerificationPassed) {
                let tempRequiredCategoriesList = Object.keys(requiredCategoriesList);
                let message_1 = getLocalizedStrings().message.AUDIT_QUESTIONS.SUBMIT_AUDIT_1;
                let message_2 = getLocalizedStrings().message.AUDIT_QUESTIONS.SUBMIT_AUDIT_2;
                let message_3 = '';
                tempRequiredCategoriesList?.map((ele,idx)=>{
                    message_3 = message_3 + "\n" + `<span style='font-weight:bold'>${ele}</span>`;
                    if( idx < tempRequiredCategoriesList?.length-1){
                        message_3 = message_3 + ", ";
                    }else{
                        message_3 = message_3 + ".";
                    }
                })
                let message  = message_1 +"\n" + message_3 + "<br />"+ "<br />"+ message_2 ;
                // eslint-disable-next-line no-loop-func
                sfDialogs.alert(message, getLocalizedStrings().message.AUDIT_QUESTIONS.RECEIVED_FEEDBACK, () => {
                    setValue(indexOfcategory);
                    setSelectedCategory(indexOfcategory);
                    setSelectedCategoryIndex(indexOfcategory);
                    getErrorIDPosition(error,photoError,id,btnId,fieldId,ids,fieldIds,btnIds);
                    setCategory(selected_category[0] ? selected_category[0]:'');
                }, style);
            }
            }
            return isVerificationPassed
        }
    }
    const isValidAuditName = (audit_name) =>{
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if(!format.test(audit_name)){
            return true;
        }else{
            return false;
        }
    }
    const getErrorIDPosition = (error,photoError,id,btnId,fieldId,ids,fieldIds,btnIds) =>{
        let inputElement = null;
        let inputFieldElement = null;
        if (error > -1) {
            inputElement = document.getElementById(ids[0]?ids[0] :id );
            if (isValidParam(inputElement) && inputElement !== null) {
                inputElement.scrollIntoView({
                    behavior: 'smooth'
                });
            }
            inputFieldElement = document.getElementById(fieldIds[0]? fieldIds[0] : fieldId);
            if (isValidParam(inputFieldElement) && inputFieldElement !== null) {
                inputFieldElement.focus();
            }
        }
        if (photoError > -1) {
            inputFieldElement = document.getElementById(btnIds[0]? btnIds[0] : btnId);
            if (isValidParam(inputFieldElement) && inputFieldElement !== null) {
                inputFieldElement.focus();
            }
        }
        setError(fieldIds[0] ? fieldIds[0] : fieldId);
        setPhotoError(btnIds[0]? btnIds[0] : btnId);
    }
    
    const prepareAndGetQuestions = (value, categoryName) => {
        let groupedQuestionsData = { ...questions };
        let keys = Object.keys(groupedQuestionsData);
        let selectedCategryKey = keys[value];
        let tempgroupedQuestionsData = groupedQuestionsData[selectedCategryKey];
        let _groupedQuestionsData = {}
        _groupedQuestionsData[selectedCategryKey] = tempgroupedQuestionsData;
        let formComponent = [];
        for (const key in _groupedQuestionsData) {
            formComponent.push(
                <div className='audit-container' key={key}>
                    <div style={{ ...formStyles.textStyle, width: '100%' }}>
                        <span style={{
                            fontSize: '20px',
                            fontWeight: 900,
                            color: '#5d6363'
                        }}>
                            {key && key !== 'null' ? key : 'Empty'}
                        </span>
                    </div>
                    <div>{
                        _groupedQuestionsData[key]?.map((data, i) => {
                            return getQuestions(key, data, i);
                        })
                    }
                    </div>
                </div>);
        }
        return formComponent;
    }
    const getQuestions = (key, question, index) => {
        if (question.status === 'Active') {
            let fieldType = question.type.toLowerCase();
            let hasError = error === 'field-element-' + key.replaceAll(' ', '_') + '-' + question.questions_id
            if (fieldType === 'text') {
                return (
                    <div className="container" style={{ ...formStyles.boxStyle, width: '100%' }}
                        key={'element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}
                        id={'element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}>
                        <h4 style={hasError ? formStyles.errorMessage : null}>{'Q' + question.qNo + ': ' + question.questions} {question.required === 1 &&
                            <span style={formStyles.required}>*</span>}</h4>
                        {/* <p>Limited space for typed response. Great space for name of responder or short answer</p> */}
                        <div style={{ width: question.enableNote ? '100%' : '100%' }}>
                            <div style={{ width: question.enableNote ? '100%' : '100%', float: question.enableNote ? 'left' : 'none' }}>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        label={getLocalizedStrings().label.AUDIT_QUESTIONS.YOUR_ANSWER}
                                        id={'field-element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}
                                        name={'text' + index}
                                        key={'text' + index}
                                        autoFocus={hasError}
                                        value={answers[key][index]?.items[0]?.items}
                                        onChange={e => updateAnswers(e, key, index)}
                                        autoComplete="off"
                                        error={hasError}
                                        style={{ zIndex: 0 }}
                                        margin="dense"
                                        size='small'
                                        className={"sf-fields-bg"} />
                                    {hasError && <span
                                        style={formStyles.errorMessage}>{getLocalizedStrings().label.AUDIT_QUESTIONS.FIELD_REQUIRED}</span>}
                                </FormControl>
                                
                                {isFeildAudit && <FormGroup style={{marginTop:'0px',marginLeft:'0px'}} component="fieldset" variant="standard">
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                            checked={answers[key][index].corrective_action_required} 
                                            onChange={e => handleAnswersPassedChange(e, key, index,question.questions_id)}
                                            name="gilad"/>
                                        } 
                                        label="Requires Corrective Action" 
                                    />
                                </FormGroup>}
                            </div>
                            {
                                getPhotoInput(key, question, index)
                            }
                            {
                                getNoteInputField(key, question, index)
                            }
                            <div className="spacer" style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                )
            }

            if (fieldType === 'comment') {
                return (
                    <div className="container" style={{ ...formStyles.boxStyle, width: '100%' }}
                        key={'element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}
                        id={'element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}>
                        <h4 style={hasError ? formStyles.errorMessage : null}>{'Q' + question.qNo + ': ' + question.questions} {question.required === 1 &&
                            <span style={formStyles.required}>*</span>}</h4>
                        {/* <p>Extended space for longer answer</p> */}
                        <div style={{ width: question.enableNote ? '100%' : '100%' }}>
                            <div style={{ width: question.enableNote ? '100%' : '100%', float: question.enableNote ? 'left' : 'none'}}>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        label={getLocalizedStrings().label.AUDIT_QUESTIONS.YOUR_ANSWER}
                                        id={'field-element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}
                                        name={'text' + index}
                                        key={'comment' + index}
                                        autoFocus={hasError}
                                        error={hasError}
                                        value={answers[key][index]?.items[0]?.items}
                                        multiline
                                        rows={3}
                                        onChange={e => updateAnswers(e, key, index)}
                                        autoComplete="off"
                                        style={{ zIndex: 0 }}
                                        margin="dense"
                                        size='small'
                                        className={"sf-fields-bg"} />
                                    {hasError && <span
                                        style={formStyles.errorMessage}>{getLocalizedStrings().label.AUDIT_QUESTIONS.FIELD_REQUIRED}</span>}
                                </FormControl>
                                
                                {isFeildAudit && <FormGroup style={{marginTop:'0px',marginLeft:'0px'}} component="fieldset" variant="standard">
                                    <FormControlLabel control={
                                        <Checkbox 
                                            checked={answers[key][index].corrective_action_required} 
                                            onChange={e => handleAnswersPassedChange(e, key, index,question.questions_id)}
                                            name="gilad" 
                                            />} 
                                            label="Requires Corrective Action" 
                                            />
                                </FormGroup>}
                            </div>
                            {
                                getPhotoInput(key, question, index)
                            }
                            {
                                getNoteInputField(key, question, index)
                            }
                            <div className="spacer" style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                )
            }

            if (fieldType === 'list') {
                let value = 'default';
                const selectedItem = question.items.find(i => i.status === 'selected');
                if (selectedItem) {
                    value = selectedItem.items;
                }
                return (
                    <div className="container" style={{ ...formStyles.boxStyle, width: '100%' }}
                        key={'element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}
                        id={'element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}>
                        <h4 style={hasError ? formStyles.errorMessage : null}>{'Q' + question.qNo + ': ' + question.questions} {question.required === 1 &&
                            <span style={formStyles.required}>*</span>}</h4>
                        {/* <p>Select one of the choices</p> */}
                        <div style={{ width: question.enableNote ? '100%' : '100%' }}>
                            <div style={{ width: question.enableNote ? '100%' : '100%', float: question.enableNote ? 'left' : 'none' }}>
                                <FormControl variant="outlined" style={{ width: '100%', marginTop: '8px', marginBottom: '4px' }}>
                                    <Select
                                        key={'list' + index}
                                        labelId='sf-label-field'
                                        id={'field-element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}
                                        autoFocus={hasError}
                                        style={{ height: '42px' }}
                                        className={"sf-fields-bg"}
                                        value={value}
                                        placeholder={"Select"}
                                    >
                                        <MenuItem
                                            key={'list' + 0}
                                            value="default"
                                            className='menuItemUpdateFields'
                                            onClick={e => updateAnswers(e, key, index, -1, true)}>
                                            Select
                                        </MenuItem>
                                        {
                                            question.items.map((items, idx) => {
                                                return (
                                                    <MenuItem
                                                        value={items.items || ''}
                                                        key={'list' + idx + 1}
                                                        style={{ whiteSpace: 'normal' }}
                                                        className='menuItemUpdateFields'
                                                        onClick={e => updateAnswers(e, key, index, idx, true)}
                                                        title={items.items}>
                                                        {items.items}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                    {hasError && <span
                                        style={formStyles.errorMessage}>{getLocalizedStrings().label.AUDIT_QUESTIONS.FIELD_REQUIRED}</span>}
                                </FormControl>
                                {isFeildAudit && <FormGroup style={{marginTop:'0px',marginLeft:'0px'}} component="fieldset" variant="standard">
                                    <FormControlLabel control={
                                        <Checkbox 
                                            checked={answers[key][index].corrective_action_required} 
                                            onChange={e => handleAnswersPassedChange(e, key, index,question.questions_id)}
                                            name="gilad" 
                                            />} 
                                            label="Requires Corrective Action" 
                                            />
                                </FormGroup>}
                            </div>
                            {
                                getPhotoInput(key, question, index)
                            }
                            {
                                getNoteInputField(key, question, index)
                            }
                            <div className="spacer" style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                )
            }

            if (fieldType === 'checkbox') {
                return (
                    <div className="container" style={{ ...formStyles.boxStyle, width: '100%' }}
                        key={'element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}
                        id={'element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}>
                        <h4 style={hasError ? formStyles.errorMessage : null}>{'Q' + question.qNo + ': ' + question.questions} {question.required === 1 &&
                            <span style={formStyles.required}>*</span>}</h4>
                        {/* <p>Choose multiple options</p> */}
                        <div style={{ width: question.enableNote ? '100%' : '100%' }}>
                            <div style={{ width: question.enableNote ? '100%' : '100%', float: question.enableNote ? 'left' : 'none',display:'inline-flex',justifyContent:'space-between' }}>
                                <div style={{width:'60%'}}>
                                    <FormGroup key={'checkbox' + index}
                                        id={'field-element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}
                                        autoFocus={hasError}>
                                        {
                                            question.items.map((items, idx) => {
                                                return (
                                                    <FormControlLabel
                                                        key={'checkbox' + idx + 1}
                                                        control={<Checkbox checked={answers[key][index]?.items[idx].status === "selected" ? true : false} color="default" onClick={e => updateAnswers(e, key, index, idx)} />}
                                                        label={items.items}
                                                    />
                                                )
                                            })
                                        }
                                        {hasError && <span
                                            style={formStyles.errorMessage}>{getLocalizedStrings().label.AUDIT_QUESTIONS.FIELD_REQUIRED}</span>}
                                    </FormGroup>
                                </div>
                                {isFeildAudit && <div>
                                    <FormGroup style={{marginTop:'0px',marginLeft:'0px'}} component="fieldset" variant="standard">
                                        <FormControlLabel control={
                                        <Checkbox 
                                            checked={answers[key][index].corrective_action_required} 
                                            onChange={e => handleAnswersPassedChange(e, key, index,question.questions_id)}
                                            name="gilad" 
                                            />} 
                                            label="Requires Corrective Action" 
                                            />
                                    </FormGroup>
                                </div>}
                            </div>
                            {
                                getPhotoInput(key, question, index)
                            }
                            {
                                getNoteInputField(key, question, index)
                            }
                            <div className="spacer" style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                )
            }
        }
    }

    function getNoteInputField(key, question, index) {
        if (question.enableNote) {
            return (
                <div style={{ width: '100%', float: 'right' }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key={'textarea' + index}
                            type='textarea'
                            multiline={true}
                            rows={3}
                            value={answers[key][index].note}
                            label={getLocalizedStrings().label.AUDIT_QUESTIONS.NOTE}
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '96%',
                                }
                            }}
                            onChange={e => handleNoteChange(e, key, index)}
                            autoComplete="new-password"
                            margin='dense'
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>
            )
        } else {
            return null;
        }
    }

    function getPhotoInput(key, question, index) {
        let hasPhotoError = photoError === 'btn-element-' + key.replaceAll(' ', '_') + '-' + question.questions_id
        let contentWidth = window.innerWidth;
        let MrightFD = question.enableNote ? '5%' : '8%'
        let MrightFM = question.enableNote ? '12%' : '16%'
        return (
            <div style={{ display: 'inline' }}>
                <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%', marginTop: answers[key][index]?.photo?.length > 0 ? '6px' : '-3px', height: 'flt-content' }}>
                    <Box sx={{ flexGrow: 1, marginTop: contentWidth >= 1024 ? '16px' : '5px' }}>
                        <Grid container spacing={0} >
                            {(answers[key][index].photo)?.map((ele, idx) => {
                                return <Grid item xs={1} style={{ marginRight: contentWidth >= 1024 ? MrightFD : MrightFM, marginTop: contentWidth >= 1024 ? '' : '5px' }}>
                                    <div style={{ marginBottom: '0px' }}>
                                        <img style={{ width: '50px', height: '50px', marginRight: '5px', cursor: 'pointer' }} src={ele} onClick={() => handleUpdatePhoto(key, question, index, idx)} />
                                        <IconButton
                                            title={getLocalizedStrings().label.COMMON.REMOVE}>
                                            <Icon style={{
                                                position: 'absolute',
                                                left: '50px',
                                                right: 0,
                                                top: '-58px',
                                                bottom: 0,
                                                color: 'red',
                                                fontSize: '18px'
                                            }}
                                                onClick={() => handleDeleteAttachedPhoto(key, question, index, idx)}
                                            > close</Icon>
                                        </IconButton>
                                    </div>
                                </Grid>
                            })}
                        </Grid>
                    </Box>
                </div>
                <div style={{ marginBottom: (answers[key][index]?.photo?.length) > 0 ? '7px' : '', marginRight: '5px' }}>
                    <Button
                        id={'btn-element-' + key.replaceAll(' ', '_') + '-' + question.questions_id}
                        style={{ ...styles.secondaryButton, marginTop: '0rem', cursor: 'pointer', color: hasPhotoError ? 'red' : 'inherit' }}
                        onClick={() => handleAddPhoto(key, question, index)}
                    >
                        {getLocalizedStrings().label.AUDIT_QUESTIONS.ADD_PHOTO}
                    </Button>

                    {question.requiresPhoto === 1 && <span style={formStyles.required}>*</span>}
                    {hasPhotoError && <span style={formStyles.photoErrorMessage}>{getLocalizedStrings().label.AUDIT_QUESTIONS.PHOTO_REQUIRED}</span>}
                </div>
            </div>
        )
    }

    const handleNoteChange = (e, key, index) => {
        let note = e.target.value;
        let tempAnswers = { ...answers };
        if (note) {
            tempAnswers[key][index].note = note;
        } else {
            tempAnswers[key][index].note = "";
        }
        setIsChangesAvailable(true);
        dispatch(updateAuditAnswers(tempAnswers));
    }

    const handleAddPhoto = (key, question, index) => {
        if (fileInput.current) {
            function onFileChange(e) {
                const [file] = e.target.files;
                if (file) {
                    if (getStringParam(file.type).startsWith('image/')) {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const tempAnswers = { ...answers };
                            let totlePhoto = [];
                            if (tempAnswers[key][index]?.photo?.length > 0) {
                                totlePhoto = tempAnswers[key][index]?.photo;
                                totlePhoto = [...totlePhoto, reader?.result];
                            } else {
                                totlePhoto.push(reader?.result);
                            }
                            tempAnswers[key][index].photo = totlePhoto;
                            setIsChangesAvailable(true);
                            dispatch(updateAuditAnswers(tempAnswers));
                        }
                        reader.onerror = function (evt) {
                            console.error("error reading file");
                        }
                        reader.readAsDataURL(file);
                        const tempPhotos = { ...photos };
                        tempPhotos[question.questions_id] = [...tempPhotos[question.questions_id] ?? [], file];
                        dispatch(updateAuditPhotos(tempPhotos));
                        setPhotoError('');
                    } else {
                        sfDialogs.alert(getLocalizedStrings().message.AUDIT_QUESTIONS.ERROR, getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_UPLOAD_ERROR, () => { }, style);
                    }
                }
                fileInput.current.removeEventListener('change', onFileChange);
                fileInput.current.value = null;
            }
            fileInput.current.addEventListener('change', onFileChange);
            fileInput.current.click();
        }
    }

    const handleUpdatePhoto = (key, question, index, idx) => {
        if (filePhoto.current) {
            function onHandleFileChange(e) {
                const [file] = e.target.files;
                if (file) {
                    if (getStringParam(file.type).startsWith('image/')) {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const tempAnswers = { ...answers };
                            tempAnswers[key][index].photo[idx] = reader?.result;
                            dispatch(updateAuditAnswers(tempAnswers));
                            setIsChangesAvailable(true);
                        }
                        reader.onerror = function (evt) {
                            console.error("error reading file");
                        }
                        reader.readAsDataURL(file);
                        const tempPhotos = { ...photos };
                        tempPhotos[question.questions_id][idx] = file;
                        dispatch(updateAuditPhotos(tempPhotos));
                    } else {
                        sfDialogs.alert(getLocalizedStrings().message.AUDIT_QUESTIONS.ERROR, getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_UPLOAD_ERROR, () => { }, style);
                    }
                }
                filePhoto.current.removeEventListener('change', onHandleFileChange);
                filePhoto.current.value = null;
            }
            filePhoto.current.addEventListener('change', onHandleFileChange);
            filePhoto.current.click();
        }
    }

    const handleDeleteAttachedPhoto = (key, question, index, idx) => {
        const tempAnswers = { ...answers };
        let TotalPhoto = tempAnswers[key][index].photo;
        TotalPhoto.splice(idx, 1);
        tempAnswers[key][index].photo = TotalPhoto;
        dispatch(updateAuditAnswers(tempAnswers));
        const tempPhotos = { ...photos };
        let id = question.questions_id;
        let _photos = tempPhotos[id];
        _photos.splice(idx, 1);
        tempPhotos[id] = _photos;
        dispatch(updateAuditPhotos(tempPhotos));
        setIsChangesAvailable(true);
    }

    const getAnswersDataForSave = () => {
        let data = [];
        let _answers = { ...answers };
        let groupedQuestionsData = _answers;
        let keys = Object.keys(groupedQuestionsData);
        let selectedCategryKey = keys[value];
        let tempgroupedQuestionsData = groupedQuestionsData[selectedCategryKey];
        let _groupedQuestionsData = {}
        _groupedQuestionsData[selectedCategryKey] = tempgroupedQuestionsData;
        _answers = _groupedQuestionsData;
        let _AuditAnswerIds = AuditAnswerIds;
        const answerIdListObject = _AuditAnswerIds.reduce((acc, obj) => {
            for (const [key, value] of Object.entries(obj)) {
                if (key !== 'AuditId') {
                    acc[key] = value;
                }
            }
            return acc;
        }, {});

        for (let key in _answers) {
            const tempAnswers = _answers[key];
            for (let answer of tempAnswers) {
                data.push({
                    questions_id: answer.questions_id,
                    answer_id: answerIdListObject[answer.questions_id] ?? 0,
                    questions: answer.questions,
                    type: answer.type,
                    required: answer.required,
                    requiresPhoto: answer.requiresPhoto,
                    note: answer.note,
                    items: answer.items,
                    corrective_action_required:answer.corrective_action_required ? answer.corrective_action_required : false,
                });
            }
        }
        return data;
    }

    const getAnswersData = () => {
        let data = [];
        let _answers = { ...answers };
        let _AuditAnswerIds = AuditAnswerIds;
        const answerIdListObject = _AuditAnswerIds.reduce((acc, obj) => {
            for (const [key, value] of Object.entries(obj)) {
                if (key !== 'AuditId') {
                    acc[key] = value;
                }
            }
            return acc;
        }, {});

        for (let key in _answers) {
            const tempAnswers = _answers[key];
            for (let answer of tempAnswers) {
                data.push({
                    questions_id: answer.questions_id,
                    answer_id: answerIdListObject[answer.questions_id] ?? 0,
                    questions: answer.questions,
                    type: answer.type,
                    required: answer.required,
                    requiresPhoto: answer.requiresPhoto,
                    note: answer.note,
                    items: answer.items,
                    corrective_action_required:answer.corrective_action_required ? answer.corrective_action_required : false,
                });
            }
        }
        return data;
    }

    const submitAnswers = () => {
        if (validateForm()) {
            setMounted(true);
            setIsSubmitProcessing(true);
            let data = getAnswersData();
            const response = Promise.resolve(HTTPClient.postWithoutToken(endPoints.AUDIT_QUESTIONS.SUBMIT_QUESTIONS, {
                encodedParams: urlParams.get('p'),
                audit_status: "submitted",
                answers: data,
                AuditId: AuditId > 0 ? AuditId : 0,
                auditBy_name : audit_name,
                auditBy_email : audit_email,
            }));
            response.then((response) => {
                if (response.status === 0) {
                    let _AuditAnswerIds = AuditAnswerIds;
                    _AuditAnswerIds.push(response.data)
                    dispatch(updateAuditAnswerIds(_AuditAnswerIds));
                    dispatch(setAuditId(response.data.AuditId));
                    let qnAnMap = response.data;
                    let promises = [];
                    const formData = new FormData();
                    formData.append('encodedParams', urlParams.get('p'));
                    let answerMap = {};
                    for (const [key, value] of Object.entries(qnAnMap)) {
                        if (photos[key]) {
                            let photoNames = [];
                            for (let i = 0; i < photos[key].length; i++) {
                                photoNames.push(photos[key][i].name);
                                formData.append('photo', photos[key][i], photos[key][i].name);
                            }
                            answerMap[value] = photoNames;
                        }
                    }
                    formData.append('answerMap',JSON.stringify(answerMap));
                    const p = HTTPClient.postFormDataWithoutToken(endPoints.AUDIT_QUESTIONS.UPLOAD_ANSWER_PHOTO, formData);
                    promises.push(p);

                    let message ;
                    if(isFeildAudit){
                        message = getLocalizedStrings().message.AUDIT_QUESTIONS.SUBMIT_AUDIT_3;
                    }else{
                        message = getLocalizedStrings().message.AUDIT_QUESTIONS.SUBMIT_AUDIT_3 + "<br />" + getLocalizedStrings().message.AUDIT_QUESTIONS.SUBMIT_AUDIT_4 + "<br />" + getLocalizedStrings().message.AUDIT_QUESTIONS.SUBMIT_AUDIT_5 + "<br />" + getLocalizedStrings().message.AUDIT_QUESTIONS.SUBMIT_AUDIT_6;
                    }
                    Promise.allSettled(promises).then(result => {
                        if (window?.ReactNativeWebView) {
                            dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            sfDialogs.alert(getLocalizedStrings().message.AUDIT_QUESTIONS.THANK_YOU,message, () => {
                                window?.ReactNativeWebView?.postMessage('success')
                            }, style);
                            setIsSubmitProcessing(false);
                            setIsChangesAvailable(false);
                        }
                    });
                } else {
                    if (window?.ReactNativeWebView) {
                        window?.ReactNativeWebView?.postMessage('error')
                        setIsSubmitProcessing(false);
                        setIsChangesAvailable(false);
                    }
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.AUDIT_QUESTIONS.SOMETHING_WRONG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            });
        }
    }

    const saveAnswers = () => {
        setMounted(true);
        setIsProcessing(true);
        let data = getAnswersDataForSave();
        const response = Promise.resolve(HTTPClient.postWithoutToken(endPoints.AUDIT_QUESTIONS.SUBMIT_QUESTIONS, {
            encodedParams: urlParams.get('p'),
            audit_status: "saved",
            answers: data,
            AuditId: AuditId > 0 ? AuditId : 0,
        }));
        response.then((response) => {
            if (response.status === 0) {
                dispatch(setAuditId(response.data.AuditId));
                let _AuditAnswerIds = AuditAnswerIds;
                _AuditAnswerIds.push(response.data)
                dispatch(updateAuditAnswerIds(_AuditAnswerIds));
                let qnAnMap = response.data;
                let promises = [];
                const formData = new FormData();
                formData.append('encodedParams', urlParams.get('p'));
                let answerMap = {};
                for (const [key, value] of Object.entries(qnAnMap)) {
                    if (photos[key]) {
                        let photoNames = [];
                        for (let i = 0; i < photos[key].length; i++) {
                            photoNames.push(photos[key][i].name);
                            formData.append('photo', photos[key][i], photos[key][i].name);
                        }
                        answerMap[value] = photoNames;
                    }
                }
                formData.append('answerMap',JSON.stringify(answerMap));

                const p = HTTPClient.postFormDataWithoutToken(endPoints.AUDIT_QUESTIONS.UPLOAD_ANSWER_PHOTO, formData);
                promises.push(p);
                Promise.allSettled(promises).then(result => {
                    
                    if (window?.ReactNativeWebView) {
                        let element = `<span style='font-weight:bold'>${category}</span>`;
                        let message =getLocalizedStrings().message.AUDIT_QUESTIONS.RECEIVED_FEEDBACK_1 + element + " " + getLocalizedStrings().message.AUDIT_QUESTIONS.RECEIVED_FEEDBACK_2 + "<br />"+getLocalizedStrings().message.AUDIT_QUESTIONS.RECEIVED_FEEDBACK_3;
                        sfDialogs.alert(getLocalizedStrings().message.AUDIT_QUESTIONS.THANK_YOU, message, () => {
                            // window?.ReactNativeWebView?.postMessage('success')
                        }, style);
                        //  dispatch(showCustomSnackBar(getLocalizedStrings().message.AUDIT_QUESTIONS.THANK_YOU, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        setIsProcessing(false);
                        setIsChangesAvailable(false);
                        setError('');
                        setPhotoError('');
                    } else {
                        let element = `<span style='font-weight:bold'>${category}</span>`;
                        let message =getLocalizedStrings().message.AUDIT_QUESTIONS.RECEIVED_FEEDBACK_1 + element + " " + getLocalizedStrings().message.AUDIT_QUESTIONS.RECEIVED_FEEDBACK_2 + "<br />"+getLocalizedStrings().message.AUDIT_QUESTIONS.RECEIVED_FEEDBACK_3;
                        sfDialogs.alert(getLocalizedStrings().message.AUDIT_QUESTIONS.THANK_YOU, message, () => {
                            //    window.close()
                            //    window.location.reload()
                        },style);
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.AUDIT_QUESTIONS.THANK_YOU, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        setIsProcessing(false);
                        setIsChangesAvailable(false);
                        setError('');
                        setPhotoError('');
                    }
                });
            } else {
                if (window?.ReactNativeWebView) {
                    window?.ReactNativeWebView?.postMessage('error')
                    setIsProcessing(false);
                    setIsChangesAvailable(false);
                    setError('');
                    setPhotoError('');
                }
                dispatch(showCustomSnackBar(getLocalizedStrings().message.AUDIT_QUESTIONS.SOMETHING_WRONG, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        });
    }

     const handleChangeAuditBy = (event,type) =>{
        let value = event.target.value;
        let _auditTypeError = auditTypeError;
        if(type === 'name'){
            setAudit_name(value);
            _auditTypeError.isAuditNameEmpty = false;
        }else{
            setAudit_email(value);
            _auditTypeError.isAuditEmailEmpty = false;
        }
        setAuditTypeError({..._auditTypeError});
    }

     const NextCategory = () =>{
        if(!isChangesAvailable){
            setCategory(CategoryList[selectedCategoryIndex+1].value);
            setSelectedCategoryIndex(selectedCategoryIndex+1)
            scrollToTop.current?.scrollIntoView({ behavior: 'smooth' });
        }else{
            let message='';
            let message_1 = getLocalizedStrings().message.AUDIT_QUESTIONS.SAVE_ALERT_1;
            let message_2 = getLocalizedStrings().message.AUDIT_QUESTIONS.SAVE_ALERT_2;
            let message_3 = getLocalizedStrings().message.AUDIT_QUESTIONS.SAVE_ALERT_3;
            let element = `<span style='font-weight:bold'>${category}</span>`;
            message = message_1 + " "+element + " "+message_2 +"\n" +message_3 ;
            sfDialogs.alert(getLocalizedStrings().message.AUDIT_QUESTIONS.ALERT, message, () => {
                saveAnswers();
                if(!isProcessing){
                     setCategory(CategoryList[selectedCategoryIndex+1].value);
                    setSelectedCategoryIndex(selectedCategoryIndex+1)
                    scrollToTop.current?.scrollIntoView({ behavior: 'smooth' });
                }
            }, style);
        }
    }

    const getAuditQuestions = (value, categoryName) => {
        let contentWidth = window.innerWidth;
        let groupedQuestionsData = { ...questions };
        let keys = Object.keys(groupedQuestionsData);
        let totalcategory = keys?.length;
        const _selectedCategoryIndex = selectedCategoryIndex + 1;
        let element = <div>
            <div style={{ ...formStyles.overlay, display: (isProcessing || isSubmitProcessing) ? 'block' : 'none' }}>
                <div style={formStyles.backdropLoader}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
            <div  ref={scrollToTop} style={{ background: '#f0f0f0', width: '100%', height: 'auto', marginBottom: '2em' }}>
                
                {/* self Audit */}
                {/* <div className="container" style={{
                    ...formStyles.boxStyle,
                    width: '100%',
                    borderTop: '10px solid ' + color,
                    marginTop: '.3em',
                    textAlign:isFeildAudit ? 'center':'',
                }}>
                    <div style={{ cursor: 'pointer', padding: '5px 34px 0px 0px', height: 'fit-content' ,display: isFeildAudit? 'flex':'', alignItems:isFeildAudit? 'center':'', textAlign:isFeildAudit? 'center':'', justifyContent: isFeildAudit?'center':''}}>
                        
                        {!isFeildAudit && <div>
                            <div style={{width:'100%',height:'20px'}} ref={scrollref}>
                                <span style={{fontWeight:'bold'}}>Audited By</span>
                            </div>
                            
                            <div style={{width:'100%',marginTop:'10px'}}>
                                <div style={{width:'100%'}}>
                                    <FormControl style={{ width: '17rem' }} className="test" noValidate autoComplete="off">
                                        <TextField
                                        id='due_by' 
                                        variant="outlined"
                                        placeholder='Your Name'
                                        style={{width: '100%'}}
                                        // InputProps={{ style: { height: '30px' } }}
                                        error={auditTypeError.isAuditNameEmpty}
                                        autoFocus={auditTypeError.isAuditNameEmpty}
                                        value={audit_name} 
                                        onChange={(event)=>handleChangeAuditBy(event,'name')}
                                        size='small'
                                        />
                                    </FormControl>
                                    {auditTypeError.isAuditNameEmpty && <span
                                            style={formStyles.errorMessage}>{getLocalizedStrings().label.AUDIT_QUESTIONS.FIELD_REQUIRED}</span>}
                                </div>
                                
                                <div style={{marginTop:'10px', width:'100%'}}>
                                    <FormControl style={{ width: '17rem'}} className="test" noValidate autoComplete="off">
                                        <TextField
                                        id='due_by' 
                                        variant="outlined"
                                        placeholder='Your Email Address'
                                        style={{width: '100%'}}
                                        type='email'
                                        error={auditTypeError.isAuditEmailEmpty}
                                        autoFocus={auditTypeError.isAuditEmailEmpty}
                                        // InputProps={{ style: { height: '30px' } }}
                                        value={audit_email} 
                                        onChange={(event)=>handleChangeAuditBy(event,'email')}
                                        size='small'
                                        />
                                    </FormControl>
                                    {auditTypeError.isAuditEmailEmpty && <span
                                            style={{...formStyles.errorMessage,marginLeft:'13px'}}>{getLocalizedStrings().label.AUDIT_QUESTIONS.FIELD_REQUIRED}</span>}
                                </div>
                            </div>
                        </div>}
                        
                    </div>
                    <div style={{width:'100%',justifyContent:'center',alignItems:'center'}}>
                        <p style={{ paddingTop: '.8em' }}>Questions with a <span style={formStyles.required}>*</span> are
                            required</p>
                    </div>
                </div> */}

                <input type='file' style={{ display: 'none' }} ref={fileInput} accept="image/*"></input>
                <input type='file' style={{ display: 'none' }} ref={filePhoto} accept="image/*"></input>
                {prepareAndGetQuestions(value, categoryName)}
                <br />
                <div style={{ marginTop: '10px', textAlign: 'center',display:'flex',alignItems:'center',justifyContent:'center' }}>
                    <Button
                        variant="contained"
                        onClick={() => saveAnswers()}
                        disabled={isProcessing}
                        style={{ ...styles.listViewPrimaryButton,width:'fit-content', height:'42px', lineHeight: '18px', backgroundColor: color }}>
                        {isProcessing ? getLocalizedStrings().label.AUDIT_QUESTIONS.SAVING : getLocalizedStrings().label.AUDIT_QUESTIONS.SAVE_CATEGORY}
                    </Button>
                {_selectedCategoryIndex < totalcategory && <div style={{display:'flex',justifyContent:'center'}}>
                    <Button
                        variant="contained"
                        onClick={() => NextCategory()}
                        disabled={isProcessing}
                        style={{ ...styles.listViewPrimaryButton,width:'fit-content', height:'42px', lineHeight: '18px', backgroundColor: color }}>
                        {isProcessing ? getLocalizedStrings().label.AUDIT_QUESTIONS.NEXT_CATEGORY : getLocalizedStrings().label.AUDIT_QUESTIONS.NEXT_CATEGORY}
                    </Button>
                </div>}
                    <Button
                        variant="contained"
                        onClick={() => submitAnswers()}
                        disabled={isSubmitProcessing}
                        style={{ ...styles.listViewPrimaryButton, width: 'fit-content', height:'42px', lineHeight: '18px', backgroundColor: color }}>
                        {isSubmitProcessing ? getLocalizedStrings().label.AUDIT_QUESTIONS.SUBMITTING : getLocalizedStrings().label.AUDIT_QUESTIONS.SUBMIT_AUDIT}
                    </Button>
                </div>
                <br />
            </div>
        </div>
        return element;
    }

    const addActions = (event) => {
        let value = event.target.value;
        let _categories = { ...questions };
        let keys = Object.keys(_categories);
        let index = 0;
        keys.map((ele, idx) => {
            if (ele === value) {
                index = idx;
            }
        })
        if(!isChangesAvailable){
            setSelectedCategoryIndex(index);
            setCategory(value);
        }else{
            let message_1 = getLocalizedStrings().message.AUDIT_QUESTIONS.SAVE_ALERT_1;
            let message_2 = getLocalizedStrings().message.AUDIT_QUESTIONS.SAVE_ALERT_2;
            let message_3 = getLocalizedStrings().message.AUDIT_QUESTIONS.SAVE_ALERT_3;
            let element = `<span style='font-weight:bold'>${category}</span>`;
            let message = message_1 + " "+ element + " "+message_2 +"\n" +message_3 ;
            // alert(message)
            sfDialogs.alert('Alert', message, () => {
                saveAnswers()
                if(!isProcessing){
                    setSelectedCategoryIndex(index);
                    setCategory(value);
                }
            }, style);
        }
    }

    if (mounted) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                 {/* header */}
                <div style={{ minHeight: '50px', backgroundColor: '#FE5722', display: 'flex', flex: 1, width: '100%', position: 'fixed', top: 0, zIndex: 10  }}>
                    <div className="alignleft">
                        <IconButton onClick={() => window?.ReactNativeWebView?.postMessage('goback')}>
                            <ArrowBackIcon sx={{ fontSize: 25 }} style={{ fontWeight: 'normal', color: '#fff', margin: 5 }} />
                        </IconButton>
                    </div>

                    <div className="aligncenter" style={{ alignSelf: 'center' }}>
                        <span style={{ color: '#fff', margin: 10, fontSize: '20px', alignSelf: 'center' }}>Audit Form</span>
                    </div>
                </div>

                <div style={{ paddingTop: '60px' }}>
                    {/* Audit Questions Header */}
                    <div className="container" style={{
                        ...formStyles.boxStyle,
                        width: '100%',
                        borderTop: '10px solid ' + color,
                        marginTop: '.3em',
                        textAlign: 'center'
                    }}>
                        <div style={{ cursor: 'pointer', padding: '5px 34px 0px 20px', height: '50px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                            <img src={decodeURI(_logo)} alt='' border="0"
                                style={{ paddingLeft: '0px', maxWidth: '130px', maxHeight: '40px' }} />
                            
                        </div>
                        <p style={{ paddingTop: '.8em' }}>Questions with a <span style={formStyles.required}>*</span> are
                            required</p>
                    </div>
                    
                    {/* category dropdown */}
                    <div style={{marginTop: '10px'}}>
                        <Box
                            //component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label={getLocalizedStrings().label.COMMON.CATEGORIES}
                                    value={category ?  category : CategoryList[0]?.value || ''}
                                    onChange={addActions}
                                    size='small'
                                    style={{ width: '95%' }}
                                >
                                    {CategoryList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Box>
                    </div>
                    
                    {/* Audit Questions */}
                    <div>
                        {Object.keys(questions)?.map((categoryName, idx) => {
                            if (idx === selectedCategoryIndex) {
                                return getAuditQuestions(selectedCategoryIndex, categoryName);
                            }
                        })}
                        {/* {Categorykeys?.map((categoryName, idx) => {
                            return <TabPanel value={value} index={idx} style={{ width: '100%' }}>
                                {getAuditQuestions(value, categoryName)}
                            </TabPanel>
                        })} */}
                    </div>
                </div>


                 <SFCustomDialog />

                 {snackBarOpenStatus && <Snackbar
					open={snackBarOpenStatus}
					onClose={()=>{dispatch(hideSnackBar())}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={snackBarOpenDuration}
					TransitionComponent={Slide}
					children={
						<Alert
							icon={false}
							style={{
								overflow: "hidden",
								Width: "800px",
								...getObjectParam(snackBar.snackbar.bodyStyle),
								...getObjectParam(snackBar.snackbar.style),
							}}
						>
							{snackBar.snackbar.message}
						</Alert>
					}
				/>}
            </div>
        )
    } else {
        return (<div style={{ width: '100%', height: contentHeight }}>
            <div style={{ minHeight: '50px', backgroundColor: '#FE5722', display: 'flex', flex: 1, width: '100%', position: 'fixed', top: 0, zIndex: 10  }}>
                <div className="alignleft">
                    <IconButton onClick={() => window?.ReactNativeWebView?.postMessage('goback')}>
                        <ArrowBackIcon sx={{ fontSize: 25 }} style={{ fontWeight: 'normal', color: '#fff', margin: 5 }} />
                    </IconButton>
                </div>

                <div className="aligncenter" style={{ alignSelf: 'center' }}>
                    <span style={{ color: '#fff', margin: 10, fontSize: '20px', alignSelf: 'center' }}>Audit Form</span>
                </div>
            </div>

            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        </div>);
    }
}

export default MobileAuditQuiz;
