import { actions } from '..//constants/actions'

const headerInitialState = {
    selectedTabObject: "",
    selectedHeader: "",
    kanban: {
        currentView: "list",
        show: false
    },
    showAddIcon: false,
    showHeader: true,
    refreshAssets: false,
    refreshOnboardingUnits: false,
    //onBoarding new state
    workflowData: [],
    workflow: '',
    onboardingReportData:[]
}

const headerReducer = (state = headerInitialState, action) => {
    switch (action.type) {
        case actions.SET_HEADER:
            return {
                ...state,
                selectedTabObject: action.payload.selectedTabObject,
                selectedHeader: action.payload.selectedHeader,
                kanban: action.payload.kanban,
                showAddIcon: action.payload.showAddIcon,
                showHeader: action.payload.showHeader
            }
        case actions.REFRESH_ASSETS: 
            return {
                ...state,
                refreshAssets: action.payload.refresh
            }
        case actions.REFRESH_ONBOARDING_UNITS:
            return {
                ...state,
                refreshOnboardingUnits: action.payload.isRefresh,
            }
        case actions.SET_WORKFLOW_DATA:
            const sortedWorkflowData = action.payload?.sort((a, b) => a.NAME.localeCompare(b.NAME))
            return {
                ...state,
                workflowData: sortedWorkflowData,
            }
        case actions.SET_ONBOARDING_WORKFLOW:
            return {
                ...state,
                workflow: action.payload
            }
        case actions.SET_ONBOARDING_REPORT_DATA:

            return {
                ...state,
                onboardingReportData: action.payload
            }
        default:
            return state;
    }
}

export default headerReducer;
