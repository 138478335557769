 const label = {
    MODULE: {
        Sales: 'Ventes',
        Marketing: 'Commercialisation',
        Service: 'Un service',
        Operations: 'Opérations',
        HUB: 'CENTRE',
    },
    MODULE_ITEMS: {
        Reports: 'Rapports',
        Dashboard: 'Tableau de bord',
        'Marketing Templates': "Modèles d'e-mails marketing",
        'Broadcast Campaigns': 'Campagnes de diffusion',
        'Automation Designer': "Concepteur d'automatisation",
        'Email Marketing': 'Publicité par e-mail',
        'Lead Capture': 'Capture de plomb',
        'Landing Pages': 'Pages de destination',
        'Web Forms': 'Formulaires Web',
        'Automated Campaigns': 'Campagnes automatisées',
        'Social Marketing': 'Marketing social',
        'Website Tracking': 'Suivi de site Web',
        'Website Analytics Setup': "Configuration de l'analyse du site Web",
        'Ab Campaigns': 'Campagnes Ab',
        'Opportunities': 'Opportunités',
        'Marketing Reports': 'Rapports',
        'Touch Campaigns': 'Campagnes tactiles',
        'A/B Campaigns': 'Campagnes A / B',
        'Email Blast': "Souffle d'e-mails",
        Activities: 'Activités',
        Conversion: 'Conversion',
        Campaigns: 'Campagnes',
        Custom: 'Personnalisé',
        Sales: 'Ventes',
        Marketing: 'Commercialisation',
        Offers: 'Des offres',
        Coupons: 'Coupons',
        'Coupons Claim Report': 'Signaler', // Report
        ASSETS_DISTRIBUTION: 'Répartition des actifs',
        USEFUL_LINKS: 'Liens utiles',
        Royalties: 'Redevances - Héritage',
        COMPLIANCE_REPORT: "Conformité",
    },
    COMMON: {
        PUSH_NO_CONFIRM: 'Pousser sans confirmation',
        UNIT_CRM_UPDATE_STAGES:'Étapes de mise à jour',
        UNIT_CRM_UPDATE_TASKS:'Tâches de mise à jour',
        FRENCHISEE_COMPANY_NAME:'Sélectionnez le nom d une entreprise franchisée',
        SELECT_AN_ACCOUNT:'Sélectionnez une unité',
        ADD_TO_PLAN:'Ajouter au forfait',
        LOGIN_TO_BASE:'Connectez-vous à la base',
        PLUS_BRANCH: '+Branche',
        ADD: 'Ajouter',
        ADD_NEW_APPOINTMENT: 'Ajouter un nouveau',
        SHOW: 'montre',
        HIDE: 'Cacher',
        REFRESH: 'Rafraîchir',
        EDIT: 'Modifier',
        SAVE: 'Enregistrer',
        CANCEL: 'Annuler',
        TEST: 'Test',
        ENTER_TEXT: 'Saisir du texte',
        APPLY: 'Appliquer',
        AND: 'et',
        EMPTY: 'Vide',
        EXPAND: 'Développer',
        DELETE: 'Supprimer',
        REQUIRED: ' (obligatoire)',
        AUTO_SEARCH_TEXT: 'Même texte, valeurs différentes',
        NOTE: 'Remarque',
        SEARCH: 'Rechercher',
        OK: 'Ok',
        SEND_MAIL: 'Envoyer un e-mail',
        ASSIGN: 'Assign',
        SELECT_A: 'Sélectionnez un ',
        SELECT_MORE: 'Sélectionnez un ou plusieurs ',
        CLOSE: 'Fermer',
        CREATE: 'Créer',
        WITH: 'avec',
        CONVERT: 'Convertir',
        SEARCH_PLACEHOLDER: 'Tapez et appuyez sur Entrée pour rechercher',
        COPY: 'Copier',
        MORE: 'Plus',
        CLICK_TO_SEARCH: 'Cliquez ici pour rechercher',
        PREVIEW: 'Aperçu',
        BACK: 'Retour',
        CONFIRM_REQUEST: 'Confirmer la demande',
        SELECT: 'Sélectionner',
        ADVANCED: 'Avancé',
        ADVANCED_SEARCH: 'Recherche avancée',
        RESULTS_FOR: 'Résultats pour',
        SETTINGS: 'Paramètres',
        SIGN_OUT: 'Déconnexion',
        BACK_TO_YOUR_ACCOUNT: 'Revenir à votre compte',
        BACK_TO_LIST: 'Revenir à la liste.',
        BACK_TO_COLUMN_VIEW: 'Revenir à la vue des colonnes.',
        MERGE: 'Fusionner',
        DISPLAY_DIFFERENT_FIELDS: '*Afficher uniquement les champs différents.',
        WORKFLOW: 'Flux de travail',
        STATUS: 'Statut',
        RECORD: 'Enregistrer',
        MERGED_RECORD: 'Enregistrement fusionné',
        EXPORT: 'Exportation',
        CTR_DETAILS_FOR: 'Détails du CTR pour',
        URL: 'URL',
        COUNT: 'Compter',
        CLICK_FOR_KANBAN_VIEW: 'Colonne',
        CLICK_FOR_LIST_VIEW: 'Lister',
        INSERT: 'Insérer',
        CATEGORIES: 'Catégories',
        REMOVE: 'Supprimer',
        REPLY: 'Réponse',
        REPLY_ALL: 'Répondre à tous',
        FORWARD: 'Avant',
        WORKFLOW_NAME: 'Nom du workflow',
        CONVERTING: 'Conversion',
        TO: 'à',
        CURRENT_WORKFLOW: 'Flux de travail actuel',
        CHANGE_WORKFLOW: 'Modifier le flux de travail',
        UPDATE: 'Mettre à jour',
        STATUS_TO_OPTIONAL: 'statut à (facultatif)',
        MANAGE: 'Faire en sorte',
        ADD_PERSONAL_NOTE_OPTIONAL: 'Ajouter une note personnelle à votre demande (facultatif)',
        CONVERTED: 'Converti',
        SELECT_USER: 'Sélectionnez un utilisateur',
        COMING_SOON: 'Bientôt disponible',
        IMPORT: 'Importer',
        NO: 'Non',
        FOUND: 'trouvé',
        NOTHING_FOUND: 'Rien trouvé pour',
        DOT: '.',
        UNDER_PROCESSING: 'En cours de traitement, à venir ',
        FIND: 'Trouve',
        LOADING: 'Chargement',
        NAME: 'Nom',
        CLEAR_ALL: 'Tout effacer',
        CLEAR: 'Dégager',
        SHARE: 'Partager',
        ADD_TO_GROUP: 'Ajouter au groupe',
        MAP: 'Carte',
        AUDIT: 'Changer de journal',
        OPEN_AUDIT_FORM: "Audit",
        TRAIL: 'Piste',
        ADD_NOTES: 'Ajouter des notes',
        TO_EXCEL: 'Exceller',
        PUSH: 'Pousser',
        RESCHEDULE: 'Replanifier',
        PRINT: 'Imprimer',
        COMPLETE: 'Compléter',
        COMPLETE_New: 'Complet et nouveau',
        SEND_EMAIL: 'Envoyer un e-mail',
        SEND_EMAIL_ATTACHMENT: 'Envoyer une pièce jointe par e-mail',
        SEND_SMS: 'Envoyer un SMS',
        CONVERT_TO_ACCOUNTS: 'Convertir en comptes',
        CONNECT: 'Relier',
        DISCONNECT: 'Déconnecter',
        DOWNLOAD: 'Télécharger',
        BATCH_EDIT: 'Modification par lots',
        BATCH_CONVERT: 'Conversion par lots',
        INACTIVE: 'Inactive',
        ACTIVE: 'Active',
        RESTORE: 'Restaurer',
        PROFILE: 'Profil',
        TYPE_TO_SEARCH: 'Tapez ici pour rechercher',
        CONVERT_AND_ADD_OPP: 'Convertir et ajouter une opportunité',
        SEND_VERIFICATION_EMAIL: 'Envoyer email de vérification',
        THESE: 'ceux-ci',
        ALL_APPOINTMENTS: 'Tous les rendez-vous',
        ALL_TASKS: 'tâches',
        CONTINUE: 'Continuer',
        ALL_OPPORTUNITIES: 'Toutes les opportunités',
        EDIT_NOTES: 'Modifier les notes',
        RUN: 'Cours',
        INTEGRATION_TITLE: "L'intégration",
        UNSUBSCRIBE: 'Se désabonner',
        MY_INTEGRATIONS: 'Intégrations',
        NOTIFY: 'Notifier ',
        HELP: 'Aider',
        PUBLISH: 'Publier',
        UNPUBLISH: 'Annuler la publication',
        CALL_SCRIPT: "Scripts d'appel",
        DOCUSIGN: 'Envoyer DocuSign',
        DOCUSIGN_INTEGRATION: 'Envoyer DocuSign',
        ALL: 'Tout',
        USER: 'Utilisateur',
        SYSTEM: 'Système',
        MY: 'Mon',
        TODAYS: "Aujourd'hui",
        YES: 'Oui',
        FUTURE: 'Futur',
        OVERDUE: 'En retard',
        TOMORROWS: "Demain",
        TOMORROW: "Demain",
        TODAY: "Aujourd'hui",
        CONNECTED_AS: 'Connecté en tant que',
        SALESREP: 'Représentant commercial',
        EVERYONE: 'Toutes les personnes',
        NOTE_TYPE: 'Type de note',
        TEXT: 'Texte',
        FOR: 'pour',
        MODULE_SETTING: 'Paramètres',
        TEMPLATE: {
            LIBRARY_TEMPLATE: 'Modèle de bibliothèque',
            USER_TEMPLATE: 'Modèle utilisateur',
        },
        OF: 'de',
        'System Fields': 'Champs système',
        'Custom Fields': 'Les champs personnalisés',
        'Additional Information': 'Informations Complémentaires',
        'Contact Details': 'Détails du contact',
        'Phone and Email Information': "Informations sur le téléphone et l'e-mail",
        'Sales rep': 'Représentant commercial',
        DONE: 'Fait',
        OPEN: 'Ouvert',
        IMPORT_CSV_SOURCE_FIELD_HEADER: 'Je mapperais mes sources à partir du fichier CSV',
        ADD_NEW_SOURCE: 'Ajouter une nouvelle source',
        ADD_TO_THIS_LIST: 'Ajouter à cette liste',
        TYPE_SOMETHING: 'Tapez quelque chose',
        'Opportunity Details': "Détails de l'opportunité",
        'Company Details': 'Détails de la société',
        'Contact Information': 'Coordonnées',
        'Case Information': 'Information sur le cas',
        'Solution Details': 'Détails de la solution',
        'Project Details': 'détails du projet',
        'Issue Details': 'Détails du problème',
        'Assigned to': 'Assigné à',
        'Sales person': 'Vendeur',
        EMAIL_TO: 'Envoyer à',
        RECENT_SEARCHES: 'recherches récentes',
        RECENT_RECORDS: 'Enregistrements récents',
        ENABLE_MAILING: "Activer l'envoi d'e-mails",
        ASSETS: 'Bibliothèque',
        DISABLE_MAILING: "Désactiver l'envoi d'e-mails",
        CLICK_TO_REMOVE: 'Cliquez ici pour supprimer',
        PIN: 'Épingler',
        UNPIN: 'Détacher',
        RENAME: 'Renommer',
        DEACTIVATE: 'Désactiver',
        ACTIVATE: 'Activer',
        COUPONS: 'Coupons',
        ADD_FOLDER: 'Ajouter le dossier ',
        ADD_FILE: 'Ajouter le fichier',
        SELECT_CONTACTS: 'Sélectionnez les contacts',
        NOTIFICATION_REMINDERS: 'Notifications et rappels',
        PREFERENCES: 'Préférences',
        LOCK_STAGES: 'Verrouiller les étapes',
        LOCK_TASKS: 'Verrouiller les tâches',
        SEND_BATCH_SMS: 'Envoyer un message texte par lots',
        SEND_TEXT_MESSAGE: 'Envoyer un texto',
        VERIFY_EMAIL: 'Vérifier les courriels',
        SELECT_UNIT_NAME: "Sélectionnez le nom de l'unité",
        NEWS: 'Nouvelles',
        CLOSE_CASE: "Fermer l'affaire",
        CUSTOMIZE: 'Personnaliser',
        VIEW_MODE: "MODE D'AFFICHAGE",
        VIEW_MODE1: "Mode d'affichage",
        LIST: 'Lister',
        COLUMN: 'Colonne',
        SETUP_SCHEDULING_PAGES: 'Configurer les pages de planification',
        SCHEDULER_LINK: 'Lien du planificateur',
        SAVE_RUN: 'ENREGISTRER ET EXÉCUTER',
        BATCH_CONVERT_TO_ACCOUNTS: 'Conversion par lots en comptes',
        NEXT: 'Suivant',
        ADD_QUICKBOOKS: 'Ajouter aux Quickbooks',
        ITEM_FROM_QB: 'DE Quickbooks',
        PUSH_TO_QB: 'Ajouter aux Quickbooks',
        ADD_NOTE: 'Ajouter une note',
        ADD_NOTE_CONVERT: 'Ajouter une note et convertir',
        OBJECT_NAME: "Nom de l'objet",
        GREENLIGHT_EXPORT_DOWNLOAD: 'Processus et téléchargement',
        PREVIOUS: 'Précédent',
        VIEW: 'Vue',
        MENU: 'Menu',
        NO_DUPLICATE: 'Pas de doublon',
        ONGOING: 'En cours',
        ONGOING_ONBOARDING: 'Intégration continue',
        SYNC_NOW: 'Synchroniser maintenant',
        'Generate Invoice' :'Générer une facture',
        'Add Payment' :'Ajouter un paiement',
        'Print' : 'Imprimer',
         MOBILE_MENU: 'Menu mobile',
         PORTAL_MENU: 'Menu du portail',
         TO_QUICKBOOKS:'Vers Quickbooks',
         CONVERT_INVOICE: 'Convertir en facture',
         RECEIVE : 'Recevoir',
         ACCEPT : "J'accepte",
         CONFIRM: 'Confirmer',
         FULFILL: 'Remplir',
         SHIP: 'Bateau',
         ADD_JOBS : 'Ajouter des emplois',
         ROYALTY_SETUP: 'Configuration des redevances',
         SAVE_AND_SCHEDULE:'Enregistrer et planifier',
         UNLINK: 'Dissocier',
         TO_XMS: 'Vers XMS',
         SEQUENTIAL:"Séquentiel",
         STAR: 'Étoile',
         PIN :'Épingle',
         CONVERT: 'Convertir',
         SHARE_WITH : 'Partager avec',
         FRANCHISOR: 'Franchiseur',
        FRANCHISE: 'La franchise',
        ALL_PHONE_NO: 'Tous les numéros de téléphone',
        BRING_YOUR_OWN:'Numéro BYO (apportez le vôtre)',
        LEASE_NUMBER:'Numéro de bail',
        ADD_NUMBERS:'AJOUTER DES NUMÉROS',
        ADD_CREDITS:'AJOUTER DES CRÉDITS',
        CALL_MINUTES:'minutes d\'appel et',
        TEXT_CREDIT:'crédit de texte restant',
        LEASE_A_NO:'Louer un numéro',
        TOLL_FREE:'Gratuit',
        LOCAL:'Local',
        COUNTRY:'Pays',
        SURVEY_SETUP : 'Configuration de l\'enquête',
        THANKS_SURVEY:'Enquête « J\'aime – Formulaire de remerciement »',
        REVIEW_WEBSITE_REQUIRED:'Vérifier l\'URL du site Web (obligatoire)',
        THUMBS_UP_TITLE:'Titre du pouce levé',
        THUMBS_UP_DESC:'Description du pouce vers le haut',
        YES_BUTTON:'Bouton Oui',
        NO_BUTTON:'Aucun bouton',
        DAYS: 'Jours)',
        LOCATION_ALERT:'L\'emplacement existe déjà !',
        TASK_NAME:'Nom de la tâche',
        DESC:'Description',
        UNIT_CRM: 'Configuration CRM de l\'unité',
        UNIT_CRM_LABEL: 'Les administrateurs de l\'unité CRM peuvent :',
        UNIT_CRM_CUSTOMIZE_FORMS: 'Personnaliser les formulaires',
        UNIT_CRM_CUSTOMIZE_WORKFLOW: 'Personnaliser les flux de travail',
        ACTION: 'Actions:',
        INVOICE_ALL : 'Facturer tout',
        EMAIL_ALL:'Envoyer tout par email',
        POST_ALL: 'Publier tout',
        REPORT_FIELDS: 'Champs du rapport :',
        COMPLIANCE_NOTIFICATION:'Avis de conformité',
        COMPLIANCE_LABEL:'Entrez l\'adresse e-mail pour avertir FR lorsqu\'un franchisé n\'est pas conforme',
        ONBOARDING_NOTIFICATION:'Notification d\'intégration',
        EMAIL:'E-mail',
        HELPDESK_NOTIFICATION:'Notification du service d\'assistance',
        LMS_SETUP:'Configuration LMS',
        LMS_LABEL:'Compte LMS (obligatoire)',
        PLUS_BASE: '+Base',
        UNITBASE:'Base de l\'unité'
    },
    NOTE:{
        ALL_NOTES:'Toutes les remarques',
        ALL_TYPES:'Tous les types',        
    },
    HOME: {
        MY_FILTERS: 'Mes filtres',
        GROUPS: 'Groupes',
        ADD_NEW: 'Ajouter nouveau',
        RECENT: 'Récent',
        HOME: 'Domicile',
        CLICK_TO_REFRESH: 'Rafraîchir',
        RECENTLY_VIEWED: 'vu récemment',
        FILTERS: 'Filtres',
        ADD_GROUP: 'Ajouter un groupe',
        NOTIFICATIONS: 'Notifications',
        WHATS_NEW: "Quoi de neuf",
        COLLAPSE: "S'effondrer",
        EXPAND: 'Développer',
        ADD_FILTER: 'Ajouter un filtre',
        CLICK_TO_FIND_GROUP: 'Cliquez ici pour trouver un groupe',
        CLICK_TO_FILTER_GROUP: 'Filtrez vos groupes',
        CLICK_TO_FIND_FILTER: 'Cliquez ici pour trouver un filtre',
        FORECASTS: 'Prévisions',
        SALES_REPORT: 'Rapports de ventes',

        //not found in doc
        ADD_FIELD: 'Cliquez ici pour ajouter un champ au filtre',
        FITER_SETUP: 'Configuration du filtre',
        ENTER_BY_HAND: 'Entrez à la main',
        IMPORT: 'Importer',
        VIEW: 'Vue',
        DELETE: 'Effacer',
        CLICK_TO_FIND_REPORT: 'Cliquez ici pour trouver un rapport',

    },
    CAMPAIGNS: {
        NAME_REQUIRED: 'Nom de la campagne (obligatoire)',
        GROUP: 'Grouper',
        CONTACT_FILTER: 'Filtre de contact',
        ACCOUNT_FILTER: 'Filtre de compte',
        FROM_NAME_REQUIRED: "Nom de l'expéditeur (obligatoire)",
        VIEW_AS_WEB_PAGE: 'Afficher sous forme de page Web',
        SUBJECT_REQUIRED: 'Sujet (obligatoire)',
        DAYS: 'Journées)',
        EXECUTE_NOW: 'Exécuter maintenant',
        TEST: 'Test',
        SCHEDULED_FOR: 'Prévu pour',
        EXECUTED_ON: 'Exécuté sur',
        EXECUTING: 'Exécution',
        ABORTED: 'Aborted',
        SCHEDULE: 'Horaire',
        CLICK_URL: "Cliquez sur l'URL",
        SELECT_GROUP: "Sélectionnez jusqu'à trois groupes" ,
        SELECT_CONTACT_FILTER: 'Sélectionnez un filtre à partir du contact',
        SELECT_ACCOUNT_FILTER: 'Sélectionnez un filtre du compte',
        CHANGE_TEMPLATE: 'Changer de modèle',
        WITHOUT_AB_TEST: 'Sans test a / b',
        WITH_AB_TEST: 'Avec test A / B',
        WITH_AUTOMATION: 'Campagnes automatisées',
        WITHOUT_AUTOMATION: 'Campagnes tactiles (héritées)',
        SEND_ON_WEEK_DAY: 'Ne pas envoyer le week-end (samedi, dimanche)',
        RESEND_AFTER: 'Renvoyer après',
        RESEND_ALL: 'Tout renvoyer',
        FROM_EMAIL_REQUIRED: "De l'e-mail (obligatoire)",
        CAMPAIGN_TYPE_OPTIONAL: 'Type de campagne (facultatif)',
        CAMPAIGN_TYPE: 'Type de campagne',
        START_DATE_REQUIRED: 'Date de début (obligatoire)',
        SENT: 'Envoyé',
        OPENED: 'Ouvert',
        UNOPENED: 'Non ouvert',
        CLICKED: 'Cliqué',
        BOUNCED: 'Rebondi',
        UNSUBSCRIBED: 'Désabonné',
        SPAM: 'Pourriel',
        DROPPED: 'Chuté',
        TYPE_HERE: 'Écrivez ici.',
        ADD_SIGNATURE: 'Ajouter une signature',
        INSERT_IMAGE: 'Insérer une image',
        PERSONALIZE: 'Personnaliser',
        TEMPLATE_DIALOG_TITLE: 'Sélectionnez un modèle',

        // Campaign Status
        'All': 'Tout',
        'Active': 'Active',
        'Running': 'En cours',
        'Stopped': 'Arrêté',

        'New': 'Nouveau',
        'Queued': "En file d'attente",
        'Executed': 'Réalisé',
        OPEN: 'Ouvert',
        BOUNCE: 'Rebondir',
        SPAMREPORT: 'Rapport de spam',
        'Halted': 'Arrêté',
        'Auto Response': 'Réponse automatique',
        'Marketing Template': 'Modèle de marketing',
        'Newsletter': 'Bulletin',
        'Greetings': 'Les salutations',
        'Inactive': 'Inactive',
        'Executed - No Winner': 'Exécuté - Aucun gagnant',
        'Sent to Winner': 'Envoyé au gagnant',
        ADD_CAMPAIGN_TYPE: 'Ajouter un nouveau type de campagne',
        CAMPAIGN_NAME: 'Nom de la campagne',
        SUBJECT: 'Matière',
        MANAGE: 'Faire en sorte',
        FROM_EMAIL: "De l'email",
        SELECT_A_FILTER:'Sélectionnez un filtre dans ',
        BETWEEN: 'Compris entre',
        WEEKDAYS_ONLY: 'En la semaine seulement'
    },
    BROADCAST_CAMPAIGNS: {
        PERSONALIZE_SUBJECT: 'Personnaliser le sujet',
        FROM_EMAIL_REQUIRED: "De l'e-mail (obligatoire)",
        EMAIL_CAMPAIGN_REPORT: 'Rapport de campagne par e-mail',
        SCHEDULE_BROADCAST: 'Programmer la diffusion',
        RESCHEDULE_BROADCAST: 'Replanifier la diffusion',
        EXECUTE_BROADCAST: 'Exécuter la diffusion',
        ADD_GROUP: 'Ajouter un groupe',
        CATEGORY: 'Catégorie',
        TEMPLATE: 'Modèle',
        PERFORM_AB_TESTING: 'Effectuer des tests A / B',
        VERIFY_EMAIL: 'Vérifiez votre adresse e-mail',
        VERIFY_EMAIL_ADDRESS: 'Entrez votre adresse de messagerie ici',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Vérifier une autre adresse e-mail',
        BROADCAST_CAMPAIGN_EDIT_TAB_LABEL: 'Modifier la campagne de diffusion',
        BATCH_EDIT: 'Modification par lots',
        BROADCAST_CAMPAIGN_COPY_TAB_LABEL: 'Copier la campagne de diffusion',
        BROADCAST_CAMPAIGN_CREATE_TAB_LABEL: 'Créer une campagne de diffusion',
        All_BROADCASTS: 'Toutes les diffusions',
        BROADCASTS: 'Diffusions',
        ADD_SIGNATURE: 'Add Signature',
        BROADCAST_INFO: 'B Roa d ou st Info',
        DESIGN: 'Conception',
        DRAG_AND_DROP: 'Glisser déposer',
        LEGACY: 'Héritage',
        EMAIL_BUILDER: "Créateur d'e-mails",
        QUALIFIED_CONTACTS: 'Afficher la liste des destinataires',
    },
    AB_CAMPAIGNS: {
        TARGET_BY: 'Cibler par',
        AB_TEST_DISTRIBUTION: 'Distribution des tests A / B',
        MIN_MARGIN_PERCENT: 'Marge gagnante minimale%',
        A_PERCENT: 'A%',
        B_PERCENT: 'B%',
        AB_CAMPAIGN_REPORT: 'Rapport de campagne A / B',
        //not found in doc
        AB_SEND_REST: 'Envoyez le reste en utilisant le gagnant',
        AB_SELECT_WINNER_BY: 'Sélectionnez le gagnant par',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Vérifier une autre adresse e-mail',
        EMAIL_VIEWS: 'Vues des e-mails',
        EMAIL_CLICKS: 'Clics sur les e-mails',
        AB_CAMPAIGN_EDIT_TAB_LABEL: 'Modifier la campagne A / B',
        BATCH_EDIT: 'Modification par lots',
        AB_CAMPAIGN_COPY_TAB_LABEL: 'Copier la campagne A / B',
        AB_CAMPAIGN_CREATE_TAB_LABEL: 'Créer une campagne A / B',
        ALL_AB_CAMPAIGNS: 'Toutes les campagnes AB',
        AB_TESTS: 'Tests A / B',
        MESSAGE_A: 'Message A',
        MESSAGE_B: 'Message B',
        AFTER: 'après',
        SCHEDULE_AB_CAMPAIGN: 'Planifier la campagne A / B',
        WINNER_BASEDON_A: 'Gagnant (basé sur le message A)',
        WINNER_BASEDON_B: 'Gagnant (basé sur le message B)'
    },
    CALENDAR: {
        CALENDAR_TASKS: 'Calendrier / Tâches',
        CALENDAR_ONLY: 'Calendrier',
        CALENDARS: 'Calendrier pour',
        SHOW_OPTION_1: 'Annulé',
        SHOW_OPTION_2: 'Complété',
        SHOW_OPTION_3: 'Programmé',
        TODAY: "Aujourd'hui",
        BACK: 'Dos',
        NEXT: 'Suivant',
        DAY: 'Jour',
        WEEK: 'Semaine',
        MONTH: 'Mois',
        AGENDA: 'Ordre du jour',
        DATE: 'Date',
        TIME: 'Temps',
        EVENT: 'Événement',
        ALL_DAY: 'toute la journée',
        WORK_WEEK: 'semaine de travail',
        YESTERDAY: 'hier',
        TOMMOROW: 'demain',
        SHOW_MORE: '',
        TYPE: 'Taper',
        WORKING_HOUERS:"heures d'ouverture",
        DEFAULT_lOCATION:'Emplacement par défaut',
        DEFAULT_DESCRIPTION:'Description par défaut',
        MY_CALENDAR_SETTING: 'Paramètres de mon calendrier',
        DISPLAY:'Affichage',
        ACCOUNT_NAME:'Nom du compte',
        JOB_NAME : 'Nom du travail',
        COLOR_CODE: 'Codigo de color',

    },
    GROUP: {
        GROUP_NAME: 'Nom de groupe',
        DELETE_RECORD: "Supprimer l'enregistrement",
        USED_IN: 'Utilisé dans',
        REMOVE_FROM: 'Retirer',
        SELECT_RECORDS: 'Enregistrements sélectionnés',
        ALL_RECORDS: 'Tous les enregistrements',
        SELECT_OPTIONS: 'Sélectionnez les options',
        GROUP_FILTER: 'Sélectionnez un filtre de groupe',
        ALL: 'Tout',
        ACTIVE: 'Actif',
        INACTIVE: 'Inactif',
        DETAILS: 'Des détails',
        'Active': 'Actif',
        'Inactive': 'Inactif',
        'New': 'Nouveau',
        'scheduled': 'programmé',
        'Queued': "En file d'attente",
        'Executed': 'Réalisé',
        'Running': 'En cours',
        'Halted': 'Arrêté'
    },
    APPOINTMENTS_TASKS: {
        MY_TASKS: 'Tâches',
        PERSONALIZE_OPTION_1: 'En retard',
        PERSONALIZE_OPTION_2: "Aujourd'hui",
        PERSONALIZE_OPTION_3: 'Futur',
        PERSONALIZE_OPTION_4: 'Demain',
        PERSONALIZE_SORT_1: 'Date',
        PERSONALIZE_SORT_2: 'Priorité',
        TASKS_FOR: 'Tâches pour',
        SORT_BY: 'Trier par',
        COMPLETE_THIS_APPOINTMENT: 'Terminez ce rendez-vous',
        COMPLETE_THIS_TASK: 'Terminez cette tâche',
        NEXT_DAY: 'Le prochain jour',
        NEXT_WEEK: 'La semaine prochaine',
        COMPLETE: 'Compléter',
        DELETE: 'Effacer',
        TASKS: 'Tâches',
        APPOINTMENTS: 'Rendez-vous',
        ADD_NOTE: 'Ajouter une note (facultatif):',
        NEXT_MONTH: 'Le mois prochain',
        DAYS: 'Journées',
        SPECIFIC_DATE: 'Date spécifique',
        RESCHEDULE: 'Replanifier',
        RESCHEDULE_FOR: 'Replanifier pour:',
        REPEAT: 'Répéter',
        DAY_OF_THE_MONTH: 'jour du mois',
        DAY_OF_THE_WEEK: 'jour de la semaine',
        REPEAT_TYPE: 'Type de répétition',
        REPEAT_ON: 'Répéter le',
        REPEAT_BY: 'Répéter par',
        START_DATE: 'Date de début',
        END_DATE: 'Date de fin',
        REPEAT_EVERY: 'Répétez chaque',
        REMOVE_REMINDER: 'Supprimer le rappel',
        ONLY_THIS: 'Seulement ça',
        FOLLOWING: 'Suivant',
        ALL: 'Tout',
        THIS:'Cette',
        THIS_AND_FOLLOWING:'Ceci et les suivants',
        ONLY_THIS_RECORD: 'Seulement cet enregistrement',
        FOLLOWING_RECORDS: 'Suivi des enregistrements',
        ALL_RECORDS: 'Tous les enregistrements',
        ADD_A_NOTE: 'Ajouter une note',
        COMPLETE_TASK_OPTIONAL: 'Terminer les tâches en attente',
        COMPLETE_TASK_OPTIONAL_OBJ: 'Terminer les tâches / e-mails / SMS en attente',
        CREATE_NEXT_TASKS: 'Créer les prochaines tâches',
        CREATE_NEXT_TASKS_OBJ: 'Créer les prochaines tâches / e-mails / messages texte',
        PENDING_TASKS: 'Tâches en attente',
        SEARCH_BY_NAME: 'Recherche par nom',
        INVITE_GUEST: 'Inviter les invités',
        ATTENDEES: 'Participants',
        SALES_REP: 'Représentant commercial',

        //not found in doc
        ADD_REMINDER: 'Ajouter un rappel',
        'Email': 'E-mail',
        'Pop-up': 'Surgir',
        'minutes': 'minutes',
        'hours': 'les heures',
        'days': 'journées',
        'weeks': 'semaines',
        DAILY: 'du quotidien',
        WEEKLY: 'Hebdomadaire',
        MONTHLY: 'Mensuel',
        YEARLY: 'Annuel',
        DAYS_S: 'journées',
        WEEKS: 'semaines',
        MONTHS: 'mois',
        YEARS: 'ans',
        APPOINTMENT: 'Rendez-vous',
        TASK: 'Tâche',
        SUBJECT: 'Matière',
        ASSIGN_TO: 'Affecter à',
        COMPANY: 'Compagnie',
        CONTACT_NAME: 'Nom du contact',
        OUTLOOK_SYNC: 'Synchronisation Outlook',
        GOOGLE_SYNC: 'Google Sync',
        'Scheduled': 'Programmé',
        'Completed': 'Complété',
        'Cancelled': 'Annulé',
        'Pending': 'En attente',
        TASK_NAME: 'Nom de la tâche',
        DUE_BY: 'Dû par',
        DESCRIPTION: 'La description',
        OWNER: 'Propriétaire',
        FOLLOWUP: 'Suivre',
        'All': 'Tout',
        'Empty': 'Vider',
        'No': 'Non',
        'Yes': 'Oui',
        'Everyone': 'Toutes les personnes',
        'Just me': 'Juste moi',
        ALL_TASK_COMPLETED: 'Ces tâches sont déjà terminées.',
        'duration': 'Durée',
        'days_after': 'Des jours après',
        DEP_TASK_NAME: 'Nom de la tâche',
        TASK_COMPLETED: 'Tâche terminée(%)',
        APPOINTMENTS_AND_TASKS: 'Rendez-vous et tâches',
        APPOINTMENTS_ONLY: 'Rendez-vous seulement',
        TASKS_ONLY: 'Tâches uniquement',
        EDIT: 'Éditer',
        TASKS_CANCEL: 'Ces tâches seront annulées',
        SENDNOW: 'Envoyer maintenant',
        OPPORTUNITY_NAME: "Nom de l'opportunité",
        SELECT_USER: 'Sélectionnez un utilisateur',
        SELECT_STATUS: 'Sélectionnez le statut',
        VISITS:'Visites',
        UNIT_OWNER_TASKS: 'Tâches de l\'unité',
        COMPLETE_UNIT_TASK_OPTIONAL: 'Terminer les tâches en attente du propriétaire de l’unité',
        UNIT_TASKS: 'Tâche de l’Unité',
        DOCUMENTS: 'Documents',
        LINKS: 'Liens',
        TASK_SETTINGS: 'Paramètres de tâche',
        APPOINTMENT_SETTINGS: 'Paramètres des rendez-vous',
    },
    ATTACHMENT: {
        UPLOAD: 'Télécharger',
        MY_COMPUTER: 'Mon ordinateur',
        BROWSE: 'Parcourir',
        DOWNLOAD: 'Téléchargement de fichier',
        ATTACH_FILE: 'Joindre un fichier',
        UPLOADING: 'Téléchargement',
        NO_IMAGE_FOUND: 'Aucune image trouvée',
        DOWNLOAD_RECORDING: "Téléchargez l'enregistrement",
        ATTACHMENT: 'Attachement',
        ADD_AN_ATTACHMENT: 'Ajouter une pièce jointe'

    },
    LIST_VIEW: {
        CLICK_TO_COLLAPSE: 'Cliquez ici pour réduire',
        CLICK_TO_EXPAND: 'Cliquez ici pour agrandir',
        SHOW_TOTAL: 'Afficher le total',
        SHOW_PICTURE: "Afficher l'image",
        DESCENDING: 'Descendant',
        SORT_BY: 'Trier par',
        SELECT_COLUMN_FOR_VIEW: 'Sélectionnez les champs pour cette vue',
        SELECT_COLUMN_FOR_FILTER_VIEW: 'Sélectionnez des colonnes pour les résultats du filtre',
        ADD_AND_REORDER: 'Ajouter et réorganiser',
        FIELDS: 'des champs',
        CLICK_TO_ADD_MORE_FIELDS: "(Cliquez sur cette case pour ajouter d'autres champs. Faites glisser et réorganisez les champs pour changer leur position.)",
        SELECT_FIELDS: 'Sélectionnez les champs',
        MODIFY_FIELD: 'Modifier le champ',
        EXPORT_SELECTED_RECORDS: 'Exporter les enregistrements sélectionnés',
        EXPORT_ALL_RECORDS: 'Exporter tous les enregistrements',
        LINK_EXISTING: 'Lien existant',
        SET_UP: 'Installer',
        GROUP_BY: 'Par groupe',
        FIND_BY_FIELD: 'Trouver par',
        FILTER_TYPE: {
            DEFAULT_FILTERS: 'Filtres par défaut',
            SHARED_FILTERS: 'Filtres partagés'
        },
        COLUMN_VIEW_SETUP_INFO: "Cette vue en colonne n'affichera que les cinq premiers champs. La vue de liste montre tous les champs.",
    },
    DETAIL_VIEW: {
        SHOW_LABELS: 'Afficher les étiquettes',
        SELECT_OPTIONS: 'Sélectionnez les options',
        CLICK_TO_ADD_MORE_FIELDS: 'Cliquez sur cette case pour ajouter plus de champs. Faites glisser et réorganisez les champs pour modifier leur ordre. Vous pouvez sélectionner au maximum 50 champs.',
        SETUP_FIELDS: 'Champs de configuration',
        ADD_OR_REMOVE_FROM_LIST: 'Ajouter / supprimer des champs de cette liste',
        CLICK_TO_KNOW_MORE: 'Cliquez pour en savoir plus',
        CLICK_TO_ADD_CHILD_OBJECTS: "Cliquez sur cette case pour ajouter d'autres objets enfants. Faites glisser et réorganisez les objets pour changer leur position.",
        SETUP_CHILD_TABS: 'Configurer les onglets enfants',
        SHOW_LABELS_AGAINST_FIELD: 'Sélectionnez ceci pour afficher les étiquettes par rapport aux champs.',
        CUSTOMIZE_CHILD: "Personnaliser l'enfant",
        REFUND_CHILD_LABEL: 'Remboursement',
        //not found in doc 
        GROUPS: 'Groupes',
        MANAGE: 'Faire en sorte',
        ADD_TO_GROUP: 'Ajouter au groupe',
        DATA_TIP_1: 'Cela créerait les tâches pour le ',
        DATA_TIP_2: 'Cela créerait les tâches / e-mails / messages texte pour le ',
        DATA_TIP_3: ' étape',

        //opportunity info 
        HIGHLIGHT_LABEL_OPPORTUNITY: 'Opportunité $',
        HIGHLIGHT_LABEL_FORECAST: 'Prévisions $',
        HIGHLIGHT_LABEL_CLOSING_ON: 'Fermeture le',
        HIGHLIGHT_LABEL_OPPORTUNITY_AGE: "Âge de l'opportunité",
        HIGHLIGHT_LABEL_DAYS: 'Journées',

        CHILD_SETUP_SELECT_CHILD_LABEL: 'Cliquez ici pour ajouter un objet enfant à la configuration.',
        NO_SETUP_OPTION: 'Rien'
    },
    RELATIONSHIP: {
        DEFINE_RELATIONSHIP: 'Définir la relation',
        RELATE: 'Relater',
        COMPANY: 'Compagnie',
        FULL_NAME: 'Nom et prénom',
        EMAIL: 'E-mail'
    },
    AUTOMATION_DESIGNER: {
        TOUCH_CAMPAIGN_REPORT: 'Rapport de campagne tactile',
        INTERVAL: 'Après',
        NOTE_TYPE: 'Type de note',
        TEXT_REQUIRED: 'Texte de la note (obligatoire)',
        TASK_NAME_REQUIRED: 'Nom de la tâche (obligatoire)',
        OWNER_REQUIRED: 'Propriétaire (obligatoire)',
        DUE_BY_REQUIRED: 'Dû par (obligatoire)',
        DAY_AFTER_REQUIRED: 'Jours après (obligatoire)',
        EMAIL: 'E-mail',
        POP_UP: 'Surgir',
        MINUTES: 'minutes',
        HOURS: 'les heures',
        DAYS: 'journées',
        WEEKS: 'semaines',
        REMINDER: 'Rappel',
        GROUP_REQUIRED: 'Sélectionnez un groupe (obligatoire)',
        ADD_TO_GROUP: 'Ajouter au groupe',
        MOVE_TO_GROUP: 'Déplacer vers le groupe',
        REMOVE_FROM_GROUP: 'Supprimer du groupe',
        STATUS_REQUIRED: 'Statut (obligatoire)',
        WORKFLOW_REQUIRED: 'Workflow (obligatoire)',
        CHOOSE_ANOTHER_FIELD: 'Choisissez un autre champ',
        CHOOSE: 'Choisir',
        FIELD_FOR: 'un champ pour',
        CAMPAIGN_NAME: 'Nom de la campagne (obligatoire)',
        START: 'Démarrer',
        STOP: 'Arrêter',
        REPORT: 'Signaler',
        RESUME: 'Reprendre',
        SELECT_PAGE: 'Sélectionnez une page',
        ENTER_WEB_PAGE_LINK_OPTIONAL: "Saisissez l'URL du lien (facultatif).",
        ENTER_WEB_PAGE_LINK: 'Entrez le lien de la page Web.',
        CLICK_TO_VIEW: 'Cliquez ici pour afficher',
        CLICK_TO_EDIT: 'Cliquez ici pour modifier',
        DRAG_TO_MOVE: 'Glisser pour déplacer',
        DRAG_TO_CONNECT: 'Faites glisser pour vous connecter',
        CLICK_TO_REMOVE: 'Cliquez ici pour supprimer',
        CLICK_TO_SEE_REPORT: 'Cliquez ici pour voir le rapport',
        CLICK_TO_ADD_MSG: 'Cliquez pour ajouter un nouveau message',
        NO: 'Non',
        YES: 'Oui',
        CLICK_TO_SELECT_USERS: 'Cliquez ici pour sélectionner les utilisateurs',
        SALES_REP: 'Représentant commercial',
        USERS: 'Utilisateurs',
        CAMPAIGN_SENDER: 'Expéditeur de la campagne',
        SCORE: 'But',
        START_WITH: 'Ajouter',//'Start with'
        DECISIONS: 'Les décisions',
        ACTIONS: 'Actions',
        LINK_CLICKED: 'Lien cliqué?',
        SCORE_REACHED: 'Score atteint?',
        TOUCH_CAMPAIGN: 'Campagne tactile',
        WEB_FORM: 'Formulaire Web',
        LANDING_PAGE: 'Page de destination',
        WEBSITE_VISITED: 'Site Web visité',
        CHANGE_GROUP: 'Changer de groupe',
        ADD_NOTE: 'Ajouter une note',
        NOTIFY: 'Notifier',
        ADD_TASK: 'Ajouter une tâche',
        FIELD_UPDATED: 'Mettre à jour les champs',
        CHOOSE_TEMPLATE: 'Choisissez un modèle',
        USE_THIS_TEMPLATE: 'Utilisez ce modèle',
        WAIT: 'Attendez',
        NAME: "Nom",
        TARGET_GROUPS: 'Groupes cibles)',
        TARGET_FILTER: 'Filtre cible',
        RESEND_AFTER: 'Renvoyer après',
        START_DATE: 'Date de début',
        SUBJECT: 'Matière',
        TEXT: 'Texte',
        TASK_NAME: 'Nom de la tâche',
        ASSIGN_TO: 'Affecter à',
        FIELD_LABEL: 'Étiquette de champ',
        FIELD_VALUE: 'Valeur du champ',
        LINK: 'Relier',
        SEND_ON_ANY_TIME: 'Envoyer: à tout moment',
        SEND_ON_BUSINESS_DAY: 'Envoyer: uniquement les jours ouvrables',
        CONVERT_TO: 'Convertir en',
        QUALIFIER: 'Qualificatif',
        OBJECT: 'Objet',
        NOTIFY_SENDER: "Notifier l'expéditeur",
        NOTIFY_SALES_REP: 'Avertir le représentant des ventes',
        NOTIFY_SENDER_SALESREP: "Notifier l'expéditeur, notifier le représentant commercial",
        NOTIFY_USERS: 'Notifier les utilisateurs',
        TEST: 'Test',
        VIEW_AS_WEBPAGE_INFO: "Cette option ajoutera un lien en haut de l'e-mail pour ouvrir l'e-mail en tant que page Web",
        LINK_CLICK_INFORMATION: "Si vous laissez ce champ vide, tous les liens de l'e-mail seront suivis.",
        GROUP_NAME: 'Nouveau nom de groupe',
        SELECT_USER: 'Affecter à',
        REMIND_BEFORE: 'Rappeler avant',
        INTERVAL_REQUIRED: 'Intervalle (obligatoire)',
        //not found in doc
        VERIFY_EMAIL_ADDRESS_OPTION: 'Vérifier une autre adresse e-mail',
        'Touch Campaign': 'Campagne tactile',
        'Link Clicked': 'Lien cliqué',
        'Link Clicked?': 'Lien cliqué?',
        'Email Opened': 'Courriel ouvert',
        'Email Opened?': 'Courriel ouvert',
        'Unsubscribed': 'Désabonné',
        'Score Reached': 'Score atteint',
        //'Send One-off Email': 'Send One-off Email',
        'Send Email': 'Envoyer un e-mail',
        'Change Group': 'Changer de groupe',
        'Add Note': 'Ajouter une note',
        'Notify': 'Notifier',
        'Add Task': 'Ajouter une tâche',
        'Update Fields': 'Mettre à jour les champs',
        'Assign': 'Attribuer',
        'Convert': 'Convertir',
        'Start': 'Démarrer',
        'Add Touch Message': 'Ajouter un message tactile',
        'Web Form': 'Formulaire Web',
        'Website Visit': 'Visite du site Web',
        'Landing Page': 'Page de destination',
        'Wait': 'Attendez',
        WAIT_FOR_DAYS: 'journées)',
        'Simple email nurturing': 'Gestion simple des e-mails',
        'Capture leads using a web form and nurture': "Capturez des prospects à l'aide d'un formulaire Web",
        'Track web page visit and nurture': 'Suivre la visite de la page Web et nourrir',
        'Capture leads from a landing page and nurture': "Capturez des prospects à partir d'une page de destination et nourrissez-les",
        'Start with tracking website visit': 'Commencez par suivre la visite du site Web',

        COPY_OF: 'Copie de ',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'Nouvelle campagne automatisée',
        TARGET_BY: 'Cibler par',
        'Hours': 'Les heures',
        'Hour(s)': 'Les heures)',
        'day(s)': 'journées)',
        'hour(s)': 'les heures)',
        ALL_AUTOMATED_CAMPAIGNS: 'Toutes les campagnes automatisées',
        SELECT_A_CAMPAIGN: 'Veuillez sélectionner une campagne',
        ADD_SIGNATURE: 'Ajouter une signature',
        BIRTHDAY_CAMPAIGN: "Campagne d'anniversaire",
        'Add Birthday Message': "Ajouter un message d'anniversaire",
        'Start with Birthday Campaign': "Commencez avec la campagne d'anniversaire",
        'SMS Replied': 'SMS répondu',
        'Send SMS': 'Envoyer un SMS',
        PET_BIRTHDAY_CAMPAIGN: "Campagne d'anniversaire pour animaux de compagnie",

        'Touch Campaign Automation': 'Automatisation de campagne tactile',
        'Birthday Campaign Automation': "Automatisation de la campagne d'anniversaire",
        'Web Form Automation': 'Automatisation des formulaires Web',
        'Landing Page Automation': 'Automatisation de la page de destination',
        'Pet Birthday Greetings': "Salutations d'anniversaire pour animaux de compagnie",


        SEND_SMS: 'Envoyer un SMS',
        SMS_REPLY: 'SMS répondu?',
        BIRTHDAY_CAMPAIGN_REPORT: "Rapport de campagne d'anniversaire",
        START_IMMEDIATELY: 'Immédiatement',
        START_SCHEDULE: 'Horaire',
        SCHEDULE_ON_TEXT: 'Programmé pour démarrer le',
        SCHEDULE_DIALOG_TITLE: "Sélectionnez la date et l'heure du programme",
        FROM_NUMBER: 'Du numéro',
        MESSAGE: 'Un message',
        REPLY: 'Réponse',
        NOT_CONVERTED: 'Non converti',
    },
    TOUCH_CAMPAIGNS: {
        ALL_TOUCH_CAMPAIGNS: 'Toutes les campagnes tactiles',
    },
    MARKETING_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personnaliser le sujet',
        NAME_REQUIRED: 'Nom (obligatoire)',
        STATUS: 'Statut',
        SUBJECT_REQUIRED: 'Sujet (obligatoire)',
        ACTIVE: 'Actif',
        INACTIVE: 'Inactif',
        TEST: 'Test',
        SELECT_TEMPLATE: 'Commencez à taper pour sélectionner un modèle',
        TYPE_HERE: 'Écrivez ici.',
        ADD_SIGNATURE: 'Ajouter une signature',
        INSERT_IMAGE: 'Insérer une image',
        PERSONALIZE: 'Personnaliser',
        SELECT_FROM_LIBRARY: 'Sélectionnez dans la bibliothèque',
        USE_THIS_DESIGN: 'Utilisez cette conception',
        DEAR: 'Cher',
        EXPAND_EDITOR: 'Plein écran',
        SHARE: 'Utiliser à la fois pour les e-mails de marketing et de vente',
        MARKETING_TEMPLATE_CREATE_TAB_LABEL: "Créer un modèle d'e-mail marketing",
        COPY_OF: 'Copie de',
        BATCH_EDIT: 'Modification par lots',
        MARKETING_TEMPLATE_EDIT_TAB_LABEL: "Modifier le modèle d'e-mail marketing",
        MARKETING_TEMPLATE_COPY_TAB_LABEL: "Copier le modèle d'e-mail marketing",
        CATEGORY: 'Catégorie',
        LAYOUT: 'Mise en page',
        'Auto Response': 'Réponse automatique',
        'Marketing Template': 'Modèle de marketing',
        'Newsletter': 'Bulletin',
        'Greetings': 'Les salutations',
        CHOOSE_EMAIL_BUILDER: "Choisissez un générateur d'e-mails",
        DRAG_AND_DROP_BUILDER: 'Utilisez le nouveau générateur de glisser-déposer',
        LEGACY_BUILDER: "Utilisez l'ancien générateur",
        TEMPLATE_INFO: "Modèle d'informations",
        DESIGN: 'Conception',
    },
    SALES_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personnaliser le sujet',
        NAME_REQUIRED: 'Nom (obligatoire)',
        STATUS: 'Statut',
        SUBJECT_REQUIRED: 'Sujet (obligatoire)',
        ACTIVE: 'Actif',
        INACTIVE: 'Inactif',
        TEST: 'Test',
        TYPE_HERE: 'Écrivez ici.',
        ADD_SIGNATURE: 'Ajouter une signature',
        INSERT_IMAGE: 'Insérer une image',
        PERSONALIZE: 'Personnaliser',
        DEAR: 'Cher',
        SALES_TEMPLATE_CREATE_TAB_LABEL: "Créer un modèle d'e-mail de vente",
        SALES_TEMPLATE_EDIT_TAB_LABEL: "Modifier le modèle d'e-mail de vente",
        SALES_TEMPLATE_COPY_TAB_LABEL: "Copier le modèle d'e-mail de vente",
        COPY_OF: 'Copie de',
        SALES_TEMPLATES: "Modèles d'e-mails de vente",
    },
    AGREEMENT_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personnaliser le sujet',
        NAME_REQUIRED: 'Nom (obligatoire)',
        STATUS: 'Statut',
        // SUBJECT_REQUIRED: 'Subject (required)',
        ACTIVE: 'Actif',
        INACTIVE: 'Inactif',
        TEST: 'Test',
        TYPE_HERE: 'Écrivez ici.',
        ADD_SIGNATURE: 'Ajouter une signature',
        INSERT_IMAGE: 'Insérer une image',
        PERSONALIZE: 'Personnaliser',
        DEAR: 'Dear',
        AGREEMENT_TEMPLATE_CREATE_TAB_LABEL: "Créer un modèle d'accord",
        AGREEMENT_TEMPLATE_EDIT_TAB_LABEL: "Modifier le modèle d'accord",
        AGREEMENT_TEMPLATE_COPY_TAB_LABEL: "Copier le modèle d'accord",
        COPY_OF: 'Copie de',
        AGREEMENT_TEMPLATES: "Modèles d'accord",
        ADD_SIGNATURE_1: 'Signature 1',
        ADD_SIGNATURE_2: 'Signature 2',
        ADD_SIGNATURE_3: 'Signature 3',
        ADD_SIGNATURE_4: 'Signature 4',
        ADD_SIGNATURE_5: 'Signature 5',
        COVER_LETTER: 'Lettre de motivation',
    },
    SMS_TEMPLATE: {
        SMS_TEMPLATE_EDIT_TAB_LABEL: 'Modifier le modèle de SMS',
        SMS_TEMPLATE_COPY_TAB_LABEL: 'Copier les modèles de somme',
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME: 'Saisissez votre nom de domaine (excluez http ou www)',
        GENERATE_TRACKING_CODE: 'Générer un code de suivi',
        TRACKING_CODE: 'Votre code de suivi',
        COPY_TO_CLIPBOARD: 'Copier dans le presse-papier',
        DONE: 'Fait'

    },
    WEB_FORM: {
        SELECT_FORM: 'Sélectionnez un formulaire',
        WEB_FORM_CREATE_TAB_LABEL: 'Créer des formulaires Web',
    },
    MAIL: {
        EMAIL_FOR_TESTING: "Entrez l'e-mail pour le test",
        SEND_A_TEST_EMAIL: 'Envoyer un e-mail de test',
        TYPE_HERE: 'Écrivez ici.',
        INSERT_IMAGE: 'Insérer une image',
        ADD_SIGNATURE: 'Ajouter une signature',
        PERSONALIZE: 'Personnaliser',
        FROM: 'De',
        SEND_MAIL: 'Envoyer un e-mail',
        PERSONALIZE_SUBJECT: 'Personnaliser le sujet',
        SUBJECT: 'Matière',
        COMPUTER: "De l'ordinateur",
        LIBRARY: 'De la bibliothèque',
        RESCHEDULE: 'Replanifier',
        TO: 'À',
        ATTACH: 'Joindre un fichier',
        CC: 'CC',
        WHEN: 'Lorsque',
        SENDING_TEST_MAIL: "Envoi d'un e-mail de test...",
        SCHEDULE: 'Horaire',
        SCHEDULE_DIALOG_TITLE: "Sélectionnez la date et l'heure du programme",
        SEND_LATER: 'ENVOYER PLUS TARD',
        SEND: 'Envoyer',
        RECIPIENTS: 'Bénéficiaires',
        SENDING: 'Envoi en cours...'
    },
    SMS: {
        TO: 'À',
        WHEN: 'Lorsque',
        FROM: 'De',
        FROM_NUMBER: 'Du numéro',
        SEND_A_TEST_SMS: 'envoyer un SMS',
        TO_NUMBER: 'Au numéro',
        STATUS: 'Statut',
        ENTER_NUMBER :'Entrez le numéro pour le test'
    },
    AUDIT_LEADSCORE: {
        LEAD_SCORE_HISTORY: 'Historique des scores des leads',
        SCORE: 'But',
        DATE: 'Date',
        ACTION: 'action',
        USER: 'Utilisateur',
        DETAILS: 'Des détails',
        ACTION_NAME: "Nom de l'action",
        SCORE_DETAILS: 'Détails du score',
        BY: 'par',
        FROM: 'de',
        LINK: 'Relier',
        CLICKED: 'cliqué',
        EMAIL: 'E-mail',
        OPENED: 'ouvert',
        UNSUBSCRIBED: 'Désabonné',
        CONVERTED_FROM: 'Converti de',
        TO: 'à',
        IN_OPPORTUNITIES: 'dans les opportunités',
        ADDED_TO: 'Ajouté à',
        REMOVED_FROM: 'Supprimé de',
        UPDATED_TO: 'mis à jour à',
        WEBFORM: 'Webform',
        SUBMITTED: 'soumis',
        VISITED: 'A visité',
        EXPIRED: 'Expiré',
        'Imported': 'Importé',
        'Created': 'Créé',
        'Converted to Account': 'Converti en compte',
        'Created from Web form': "Créé à partir d'un formulaire Web",
        'Created from Landing page': 'Créé à partir de la page de destination',
        'Created from Zapier': 'Créé à partir de Zapier',
        'Created from Outlook': "Créé à partir d'Outlook",
        'Created from Outlook (Client)': "Créé à partir d'Outlook (client)",
        'Created from G Suite': 'Créé à partir de G Suite',
        'Created from Office 365': "Créé à partir d'Office 365",
        'Created from Facebook/twitter etc': 'Créé à partir de Facebook / twitter, etc.',
        'Pushed': 'Poussé',
        'Created from API': "Créé à partir de l'API",
        'Inline/Quick updated': 'Mise à jour en ligne / rapide',
        'Updated': 'Mise à jour',
        'Batch Updated': 'Lot mis à jour',
        'Workflow Applied': 'Workflow appliqué',
        'Converted': 'Converti',
        'Assigned': 'Attribué',
        'Deleted': 'Supprimé',
        'Restored': 'Restauré',
        'Merged': 'Fusionné',
        'Main Contact updated': 'Contact principal mis à jour',
        'Shared': 'partagé',

        'Link Clicked': 'Lien cliqué',
        'Email Opened': 'Courriel ouvert',
        'Unsubscribed': 'Désabonné',
        'Web Form Submitted': 'Formulaire Web soumis',
        'Removed from Group': 'Retiré du groupe',
        'Field Updated': 'Champ mis à jour',
        'Added to Group': 'Ajouté au groupe',
        'Website Visited': 'Site Web visité',
        'Has Business Email': 'Possède une adresse e-mail professionnelle',
        'Clicked Link': 'Lien cliqué',
        'Opened Email': 'Courriel ouvert',
        ACCOUNT_SCORE: 'Votre score parfait pour les comptes est  ',
        CONTACT_SCORE: 'Votre score parfait pour les contacts est  '
    },
    DASHBOARD: {
        TODAY: "Aujourd'hui",
        YESTERDAY: 'Hier',
        THIS_WEEK: 'Cette semaine',
        THIS_MONTH: 'Ce mois-ci',
        THIS_QUARTER: 'Ce trimestre',
        THIS_YEAR: 'Cette année',
        LAST_WEEK: 'La semaine dernière',
        LAST_MONTH: 'Le mois dernier',
        LAST_QUARTER: 'Dernier quart',
        LAST_YEAR: "L'année dernière",
        CUSTOM: 'Personnalisé',

        //Kanban View
        NEXT_MONTH: 'Le mois prochain',
        NEXT_QUARTER: 'Prochain trimestre',
        NEXT_THREE_MONTHS: 'Trois prochains mois',
        NEXT_TWO_MONTHS: 'Deux prochains mois',

        SALES_DASHBOARD: 'Tableau de bord des ventes',
        MARKETING_DASHBOARD: 'Tableau de bord marketing',
        // SALES_MARKETING_DASHBOARD: 'Sales and Marketing Dashboard',
        REPOSITION_GADGET: 'Cliquez et faites glisser pour repositionner ce gadget',
        'Top Sources By Lead Volume': 'Principales sources par volume de leads',
        'Top Sources By Lead Conversion': 'Principales sources par conversion de prospect',
        'Marketing Email Summary': 'Résumé des e-mails marketing',
        'Top Marketing Emails by CTR': 'Meilleurs e-mails marketing par CTR',
        'Top Web Pages': 'Meilleures pages Web',
        'Lead Pipeline': 'Pipeline de plomb',
        'Opportunity Pipeline': "Pipeline d'opportunités",
        'Sales Leaderboard': 'Classement des ventes',

        'Status': 'Statut',
        'Count': 'Compter',
        'Subject': 'Matière',
        'Click-Through Rate(CTR)': 'Taux de clics (CTR)',
        'Website Name': 'Nom du site Web',
        'Forecast': 'Prévoir',
        'Sales person': 'Vendeur',
        'Closed': 'Fermé',

        'Activities': 'Activités',
        'Accounts': 'Matrice des comptes',
        'Opportunities': 'Matrice des opportunités',
        'Users': 'Utilisateurs',
        'Appointment': 'Rendez-vous',
        'Dialed': 'Composé',
        'Email': 'E-mail',
        'LVM': 'LVM',
        'Note': 'Noter',
        'Spoke': 'Parlait',
        'Task': 'Tâche',
        'Transferred': 'Transféré',

        'Qualified': 'Qualifié',
        'Presentation': 'Présentation',
        'Proposal': 'Proposition',
        'Negotiation': 'Négociation',
        'Close': 'Fermer',

        'Lead': 'Conduire',
        'Prospect': 'Perspective',
        'Customer': 'Client',
    },
    LOGIN: {
        LOG_IN: 'Connexion',
        EMAIL_ADDRESS: 'Adresse e-mail',
        PASSWORD: 'Mot de passe',
        REMEMBER_ME: 'Souviens-toi de moi'
    },
    SOCIAL_CAMPAIGN: {
        SAVE_AS_DRAFT: 'Enregistrer comme brouillon',
        POST_NOW: 'Publier maintenant',
        CONNECT: 'Relier'

    },
    DOCUMENT_LIBRARY: {
        COMPUTER: "L'ordinateur",
        LIBRARY: 'Bibliothèque',
        IMAGES: 'Images',
        STOCK_IMAGES: 'Stock Images',
        DOCUMENTS: 'Des documents',
        SEARCH: 'Rechercher...',
        UPLOAD_DOCUMENT: 'Télécharger le document',
        NAME: 'Nom',
        TITLE: 'Titre',
        TITLE_REQUIRED: 'Titre (obligatoire)',
        LINK_REQUIRED: 'Lien (obligatoire)',
        TYPE: 'Taper',
        OWNER: 'Propriétaire',
        ALL: 'Tout',
        NO_IMAGE_FOUND: 'Aucune image trouvée',
        UPLOAD_DIALOG_TITLE: 'Sélectionnez un document à télécharger',
        SHARED_WITH: 'partagé avec',
        UNITS: 'unités',
        UNIT: 'unité',
        UNIT_PUSH: 'Unité:',
        RENAME_FOLDER: 'Renommer le dossier ',
        CREATE_FOLDER: 'Créer le dossier',
        NEW_FOLDER: 'Nouveau dossier',
        ASSETS_NOT_FOUND: 'Aucun dossier ni fichier trouvé.',
        'Folder': 'Dossier',
        'File': 'Déposer',
        IMAGE: 'Image',
        DOCUMENT_AND_FILE: 'Documents et fichiers',
    },
    HEADER: {
        SALES_TEMPLATE: "Modèle d'e-mail de vente",
        CALENDAR_TASKS: 'Calendrier / Tâches',
        ACCOUNT: 'Compte',
        SALES_REPORT: 'Rapport des ventes',
        ACCOUNTS: 'Comptes',
        ACCOUNTS_WITHOUT_GROUP: 'Comptes sans groupes',
        MY_ACCOUNT: 'Mes comptes',
        TODAYS_ACCCOUNTS: "Comptes d'aujourd'hui",
        CONTACTS: 'Contacts',
        CONTACTS_WITHOUT_ACCOUNT: 'Contacts sans compte',
        CONTACTS_WITHOUT_ACCOUNTS: 'Contacts sans comptes',
        CONTACTS_WITHOUT_GROUPS: 'Contacts sans groupes',
        MY_CONTACTS: 'Mes contacts',
        ACCOUNTS_WITHOUT_CONTACT: 'Comptes sans contact',
        TODAYS_CONTACTS: "Contacts d'aujourd'hui",

        ALL_APPOINMENTS: 'Tous les rendez-vous',
        MY_APPOINMENTS: 'Mes rendez-vous',
        MY_COMPLETED_APPOINMENTS: 'Mes rendez-vous terminés',
        MY_MISSED_APPOINMENTS: 'My Missed Appointments',
        TODAYS_APPOINMENTS: 'Rendez-vous du jour',

        ALL_TASKS: 'Toutes les tâches',
        MY_COMPLETED_TASKS: 'Mes tâches terminées',
        MY_FUTURE_TASKS: 'Mes futures tâches',
        MY_PENDING_TASKS: 'Mes tâches en attente',
        MY_TODAYS_TASKS: 'Mes tâches du jour',
        ONBOARDING: 'Intégration',
        SALES_PIPELINE: 'Rapport sur le pipeline des ventes',
        COUPONS_CLAIM_REPORTS: 'Rapport sur les réclamations de bons de réduction',
        'Cases': 'Cas',
        'Projects': 'Projets',
        'Issues': 'Questions',
        'Solutions': 'Solutions',
        'Contacts': 'Contacts',
        'Accounts': 'Comptes',
        'Opportunities': 'Opportunités',
        'Appointments': 'Rendez-vous',
        'Tasks': 'Tâches',
        'Onboarding': 'Intégration',
        'Units': 'Unités',
        'Assets': 'Bibliothèque',
        'Activities': 'Activités',
        'Conversions': 'Les conversions',
        'Campaigns': 'Campagnes',
        'Reports': 'Rapports',
        'Sales_templates ': 'Modèles de vente',
        'Dashboard': 'Tableau de bord',
        'Sales Leads': 'Leads de vente',
        'Recycle Bin': 'Comptes',
        'News': 'Nouvelles',
        REPORTS: 'Rapports',
        REPORT: 'Signaler',
        TASKS_PAGE: 'Tâches',
        'Products': 'Des produits',

    },
    ADVANCE_SEARCH: {
        ADVANCE_SEARCH: 'Recherche Avancée',
        ADD_FIELD:'Rechercher un autre champ'
    },
    IMPORT: {
        GROUP: 'Grouper ( Obligatoire )',
        WORKFLOW: 'Workflow ( obligatoire )',
        STATUS: 'Statut ( obligatoire )',
        SOURCE: 'Source ( obligatoire )',
        IMPORT: 'Importer',
        LINK_USING: 'Lien en utilisant',
        LINK_WITH: 'Lier avec',
        APPLY_WORKFLOW: 'Appliquer le workflow',
        AUTO_MAP: 'Auto Map',
        USE_SAVED_MAPPING: 'Utiliser le mappage enregistré',
        SAVE_MAPPING: 'Enregistrer le mappage',
        DELETE_MAPPING: 'Supprimer le mappage',
        DONOT_CHECK_DUPLICATE: 'Ne pas vérifier les doublons',
        CHECK_DUPLICATE: 'Vérifier les doublons',
        CHECK_DUPLICATE_BY: 'Vérifier les doublons par',
        DONOT_IMPORT: "N'importez pas",
        MARK_DUPLICATE: 'Importer et marquer comme duplicata',
        UPDATE: 'Mettre à jour',
        //not found in doc
        STEP_1: 'Sélectionnez la source',
        STEP_2: 'Mapper les champs et importer',
        FINISH: 'Finir',
        NEXT: 'Suivant',
        MAP_CSV_COLUMNS: 'Mapper les colonnes CSV au CRM',
        MAP_XLS_COLUMNS: 'Mapper les colonnes XLS au CRM',
        MAP_XLSX_COLUMNS: 'Mapper les colonnes XLSX au CRM',
        MAP_TO_CRM_FIELD: 'Mapper au champ CRM',
        COLUMN: 'Colonne',
        ADD_NEW_GROUP: 'Ajouter un nouveau groupe',
        WORKFLOW_OPTIONAL: 'Workflow ( facultatif )',
        FILE_MUST_CSV: 'Le fichier doit être au format CSV.',
        SKIP: 'Sauter',
        MERGE: 'Fusionner',
        CHECK_DUPLICATE_USING: "Vérifier les doublons à l'aide",
    },
    REPORT: {
        START_DATE: 'Date de début',
        END_DATE: 'Date de fin',
        REPORT_ON: 'Rapport sur',
        REFRESH: 'Rafraîchir',
        VIEW: 'Vue',
        PRINT: 'Imprimer',
        SALES_REP: 'Représentant commercial',
        DATE_RANGE: 'Plage de dates',
        SALES_REPORT: 'Rapports de ventes',
        SERVICE_REPORT: 'Rapports de service',
        'Activity Report': "Rapport d'activité",
        'Conversion Report': 'Rapport de conversion',
        'Call Report': "Rapport d'appel",
        'Pipeline Report': 'Rapport sur le pipeline',
        'Customer Activation Report': "Rapport d'activation client",
        'Customer Service Type Report': 'Rapport sur le type de service client',
        'Customer Assessment Report': "Rapport d'évaluation des clients",
        'Customer Leads Assessment Report': "Rapport d'évaluation des clients potentiels",
        'Customer Status Report': "Rapport sur l'état du client",
        'Sales Pipeline Report': 'Rapport sur le pipeline des ventes',
        'Days in Franchise Workflow Report': 'Rapport sur le flux de travail de la franchise',
        'Franchise Workflow Report': 'Rapport sur le flux de travail de la franchise',
        'Franchise Sales Pipeline Report': 'Rapport sur le pipeline des ventes de franchise',
        'Franchise Employees Report': 'Rapport sur les employés de franchise',
        'Franchise Last Contact Report': 'Rapport de dernier contact de franchise',
        'InXpress User Baseline Report': "Rapport de base de l'utilisateur InXpress",
        'Franchise User Report': "Rapport d'utilisateur de franchise",
        'Sales Summary Report': 'Rapport de synthèse des ventes',
        ACTIVITY_MATRIX: "Matrice d'activité",
        'ACTIVITY_TYPE': "Type d'activité",
        'Service Reports': 'Rapports de service',
        'Project Reports': 'Rapports de projet',
        'CRM Activity by Account': 'Activité CRM par compte',
        'Activity by Franchise': 'Activité par franchise',
        'Franchise Activity Report': "Rapport d'activité de la franchise",
        'Issue Reports': 'Rapports sur les problèmes',
        'Customer Service and Sales Rep Listing': 'Liste des représentants du service client et des ventes',
        GO: 'Va',
        'CRM Account Details': 'Détails du compte CRM',
        'Activity by Coach': 'Activité du coach',
        'Franchise Conversion Report': 'Rapport de conversion de franchise',
        'Task Report': 'Rapport de tâche',
        'Email Report': 'Rapport par e-mail',
        'SMS Report': 'Rapport SMS',
        'Orthotic Report': 'Rapport orthopédique',
        'Greenlight Export': 'Exportation Greenlight',
        REPORT_ON_OBJECTS: {
            ACCOUNTS: 'Comptes',
            OPPORTUNITIES: 'Opportunités'
        },
        'Count': 'Compter',
        'Grand Total': 'Total',
        ACTIVITIES_BY_TYPE: 'Activités par type',
        ACTIVITIES_BY_SALES_REP: 'Activités par représentant commercial',
        ALL_CONVERSIONS_BY_STATUS: 'Toutes les conversions par statut',
        CUSTOMERS_BY_SOURCE: 'Clients par source',
        DEALS_BY_SALES_PER: 'Pipeline par représentant des ventes',
        DEALS_BY_STAGES: 'Offres par étapes',
        TOTAL_CALLS_BY_SALES_REP: "Nombre total d'appels par représentant commercial",
        TOTAL_CALL_DURATION_BY_SALES_REP: 'Durée totale des appels par représentant commercial',
        EXCEL: 'Exceller',
        MATRIX_BY_STATUS: 'Matrice par statut',
        MATRIX_BY_SOURCE: 'Matrice par source',
        'Status': 'Statut',
        'Source': 'La source',
        '5 Ways Report': 'Rapport 5 façons',
        'Forecast Report': 'Rapport de prévision',
        CASES_BY_STATUS: 'Cas par statut',
        CASES_BY_PRIORITY: 'Cas par priorité',
        PROJECTS_BY_STATUS: 'Projets par statut',
        PROJECTS_BY_TYPE: 'Projets par type',
        ISSUES_BY_STATUS: 'Problèmes par statut',
        ISSUES_BY_PRIORITY: 'Problèmes par priorité',
        SUPPORT_MATRIX: 'Matrice de soutien',
        CUSTOMER_BY_CASES: 'Clients par cas',
        PROJECT_MATRIX: 'Matrice de projet',
        ISSUE_MATRIX: 'Matrice des problèmes',
        SUB_TOTAL: 'Sous-total',
        SERVICE_MATRIX_HEADER: {
            'Assigned to': 'Assigné à',
            'Total count': 'Le compte total',
            'Company': 'Compagnie',
            'Assigned To': 'Assigné à',
        },
        WORKFLOW_SELECT: 'Sélectionnez Workflow',
        COUPONS_NAME: 'Sélectionnez les coupons',
        PRODUCT_SELECT: 'Sélectionner un produit',
        AVAILABLE_REPORTS: 'Rapports disponibles',
        WORKFLOW_STAGES: 'Étapes',
        CHART_TYPE: 'Type de graphique',
        SHOW: 'Spectacle',
        VALUE: 'Évaluer',
        COUNT: 'Compter',
        DEALS_BY_SALES_REP: 'Pipeline par représentant des ventes',
        PIPELINE:'Pipeline',
    },
    FILTER: {
        NAME_REQUIRED: 'Nom du filtre ( obligatoire )',
        REMOVE_CONDITION: 'Supprimer la condition',
        CRITERIA_FOR_ASSOCIATED_OBJECTS:'Critères pour les objets associés',

        //not found in doc
        NEXT_BUTTON_TEXT: 'Suivant',
        PREVIOUS_BUTTON_TEXT: 'Précédent',
        AND: 'Et',
        OR: 'Ou alors',
        ADD_ANOTHER: 'Ajouter un autre',
        ADD_CONDITION: 'Ajouter une condition',
        COPY_FILTER: 'Copie de ',
        FROM_DATE: 'Partir de la date',
        TO_DATE: 'À ce jour',

        CONTAINS: 'contient',
        DOES_NOT_CONTAINS: 'ne contient pas',
        IS_EQUAL_TO: 'est égal à',
        IS_NOT_EQUAL_TO: "n'est pas égal à",
        IS_EMPTY: 'est vide',

        IS_GREATER_THAN: 'est supérieur à',
        IS_GREATER_THAN_OR_EQUAL_TO: 'est supérieur ou égal à',
        IS_LESS_THAN: 'est inférieur à',
        IS_LESS_THAN_OR_EQUAL_TO: 'est inférieur ou égal à',
        'Contacts': 'Contacts',
        'Contacts without Groups': 'Contacts sans groupes',
        'My Contacts': 'Mes contacts',
        'Contacts without Account': 'Contacts sans compte',
        'Todays Contacts': "Contacts d'aujourd'hui",
        'Contacts without Accounts': 'Contacts sans comptes',
        'All records': 'Tous les enregistrements',
        'Added this Month': 'Ajouté ce mois-ci',
        'Added this Week': 'Ajouté cette semaine',
        'My Opportunities': 'Mes opportunités',
        'Opportunities': 'Opportunités',
        'Accounts': 'Comptes',
        'Accounts without Contact': 'Comptes sans contact',
        'Accounts without Groups': 'Comptes sans groupes',
        'My Accounts': 'Mes comptes',
        'Todays Accounts': "Comptes d'aujourd'hui",
        'Cases': 'Cas',
        'Projects': 'Projets',
        'Issues': 'Questions',
        'Accounts without Contacts': 'Comptes sans contacts',
    },
    SOURCE: {
        SOURCE_NAME: 'Nom de la source'
    },
    INXPRESS_REPORT: {
        COUNTRY_SELECT: 'Choisissez le pays',
        FRANCHISE_SELECT: 'Sélectionnez Franchise',
        WORKFLOW_SELECT: 'Sélectionnez Workflow',
        START_DATE: 'Date de début',
        NO_RECORDS_FOUND: 'Aucun enregistrement trouvé.',
        'Account': 'Compte',
        // 'Company Name': 'Company Name',
        'Sales Rep': 'Représentant commercial',
        'Workflow': 'Flux de travail',
        'Status': 'Statut',
        REGION_SELECT: 'Choisissez une région',
    },
    KANBAN_REPORT: {
        WORKFLOW: 'Flux de travail',
        USER: 'Utilisateur',
        DURATION: 'Durée',
        SORT_BY: 'Trier par',
        REPORT_BY: 'Signaler par',
        'Forecast amount': 'Montant prévisionnel',
        'Close date': 'Date de clôture',
        'Sales rep': 'Représentant commercial',
        'Status': 'Statut',
        'Convert': 'Convertir',
        'Edit': 'Éditer',
        'Delete': 'Effacer',
        'opportunities': 'Opportunités',
        'Probability': 'Probabilité',
    },
    APP: {
        CURRENT: "J'utiliserais la nouvelle version",
        LEGACY: "J'utiliserais la version héritée",
        HEADING: 'Veuillez choisir une version Soffront',
        SUB_TITLE: "Votre organisation a accès à notre ancienne version. Souhaitez-vous l'utiliser à la place de la nouvelle version"
    },
    OTHER_VIEW: {
        SUBSCRIBE_LABEL: "S'abonner"
    },
    MY_INTEGRATION: {
        CONNECTING: 'De liaison',
        DISCONNECTING: 'Déconnexion',
        SYNC_EMAIL: 'Synchroniser les e-mails',
        SYNC_CONTACT: 'Synchroniser les contacts',
        SYNC_CALENDAR: 'Synchroniser le calendrier',
        APPEND_COMPANY: "Ajouter le nom de l'entreprise",
        APPEND_CONTACT: 'Ajouter le nom du contact',
        CALENDAR: 'Calendrier',
        SERVICE: 'Un service',
        INTEGRATION_HEADER: 'Intégration des e-mails et du calendrier',
        PHONE_INTEGRATION: 'Intégration du téléphone',
        MY_INTEGRATIONS: 'Intégrations',
        CONTACT_FILTER: 'Filtre de contact',
        RINGCENTRAL_HEADER: 'RingCentral',
        SMS_INTEGRATION: 'Intégration SMS',
        QUICKBOOKS: 'QuickBooks',
        XERO:'Xero',
        INTEGRATE:'intégrer',
        SELECT_INTEGRATION_ITEMS:'sélectionner les élément à intégrer',
        INVOICES:'Factures',
        PAYMENTS:'Paiements',
        MANNUALLY:'Manuellement (CRM > Xero)',
        AUTOMATICALLY:'Automatiquement (Xero > CRM)',
        ACCOUNTING_INTEGRATION_LABEL: 'Intégrer avec',
        ACCOUNTING_INTEGRATION: 'Intégration comptable',
        QUICKBOOKS_INTEGRATION: 'Intégration QuickBooks',
        EVENTBRITE_INTEGRATION: 'Intégration Eventbrite',
        SELECT_A: 'Sélectionner un',
        CRM_CONTACT_FILTER_SELECT: 'Sélectionnez un filtre de contact CRM',
        CONTACT_GROUP_NOT_FIND: 'Impossible de trouver le groupe de contacts. Cliquez sur',
        HERE: 'ici',
        TO_TRY_AGAIN: 'Essayer à nouveau.',
        CONTACT_FILTER_NOT_FIND: 'Impossible de trouver un filtre de contact.',
        SEND_SALES_EMAILS_USING: 'Envoyez des e-mails commerciaux à l\'aide de',
    },
    PROFILE_DETAILS: {
        PROFILE_DETAILS_LABEL: 'Profil',
        PERSONAL_INFORMATION: 'Renseignements personnels',
        USERNAME_LABEL: "Ce sera utilisé comme nom d'utilisateur.",
        CHANGE_PASSWORD: 'Changer le mot de passe',
        MANAGE_NOTIFICATIONS: 'Gérer les notifications',
        EMAIL_SIGNATURE: 'Signature e-mail',
        TASKS: 'Tâches',
        APPOINTMENTS: 'Rendez-vous',
        TASK_EMAIL: 'E-mail de la tâche',
        TASK_POPUP: 'Fenêtre contextuelle de la tâche',
        APPOINTMENT_EMAIL: 'E-mail de rendez-vous',
        APPOINTMENT_POPUP: 'Rendez-vous Pop up',
        ON: 'Au',
        OFF: 'désactivé',
        CHANGE_PHOTO: 'Changer la photo',
        NEW_PASSWORD: 'Nouveau mot de passe',
        CONFIRM_PASSWORD: 'Confirmer le nouveau mot de passe',
        NAME_REQUIRED: 'Nom (obligatoire)',
        EMAIL_REQUIRED: 'Email (requis)',
        PHONE: 'Téléphoner',
        TIME_ZONE_REQUIRED: 'Fuseau horaire (obligatoire)',
        DATE_FORMAT: 'Format de date',
        LANGUAGE: 'Langue',
        USER_COLOR: 'Couleur utilisateur',
    },
    DETAIL_VIEW_CHILD_NOTES: {
        ALL: 'Tout',
        USER: 'Utilisateur',
        SYSTEM: 'Système',
        MY: 'Mon',
        ATTACHMENT_INFO: 'Informations sur la pièce jointe',
    },
    DOCUSIGN: {
        CONNECT_BUTTON: 'Relier',
        SAVE_BUTTON: 'Sauvegarder',
        CANCEL_BUTTON: 'Annuler',
        USERNAME: "Nom d'utilisateur (requis)",
        PASSWORD: 'Mot de passe requis)',
        INTREGATOR_KEY: "Clé d'intégration (obligatoire)",
        SELECT_TEMPLATE: 'Sélectionnez un modèle',
        SEND: 'Envoyer',
        DISCONNECT_BUTTON: 'Déconnecter',
        DOCUSIGN_SETUP_HEADER: 'Intégration DocuSign',
        DOCUSIGN_CONNECT_TITLE: 'Connectez-vous à votre compte DocuSign',
        ADD_NOTE: 'Ajouter un commentaire (facultatif).',
    },
    TWILIO: {
        CALL: 'Appel',
        VERIFY: 'Vérifier',
        DISCONNECT: 'Déconnecter',
        SHOW_SCRIPTS: 'Afficher les scripts',
        HIDE_SCRIPTS: 'Masquer les scripts',
        CALL_SETUP: 'Configurer les appels',
        CALL_SCRIPT: "Script d'appel",
        CALL_SCRIPT_COPY_TAB_LABEL: 'Copier le script d\'appel',
        COPY_OF: 'Copie de',
        ADD_NUMBERS: 'Ajoutez vos numéros de téléphone sortants',
        OUTBOUND_PHONE_NUMBERS: 'Numéros de téléphone sortants',
        ADD_ANOTHER_NUMBER: 'Ajouter un autre numéro',
        CALL_RECORDING: "Enregistrement d'appel",
        PHONE_CREDITS: 'Vos crédits téléphoniques',
        PURCHASE_CREDITS_YOU_HAVE: 'Vous avez',
        PURCHASE_CREDITS_MINUTES_LEFT: 'Minutes restant.',
        PURCHASE_CREDITS_MORE: 'Achetez plus de crédits.',
        CALLING: 'Appel',
        RECORD_ALL_CALL: 'Enregistrer tous les appels',
        VERIFIED: 'Vérifié',
        CLICK_TO_CALL: "Je suis prêt à prendre l'appel",
        SCRIPTS: 'Scripts',
        VOICE_MAILS: 'VMs',
        END_CALL:"Fin d'appel",
        ADDITIONAL_INFO: 'Afficher des informations supplémentaires',
        ADDITIONAL_INFO_TITLE: 'Informations Complémentaires',
        SELECT_FIELD:'Sélectionnez les champs (Maximum 3 champs)'
    },
    CALL_SCRIPT: {
        ADD_SCRIPT_NAME: 'Nom du script (obligatoire)',
        ADD_DETAILS: 'Détails du script (obligatoire)',
        WORKFLOW_NAME: 'Nom du workflow',
        CALL_SCRIPTS_EDIT_TAB_LABEL: "Modifier les scripts d'appel",
    },
    TWILIO_SMS: {
        ZIP_CODE: 'Code postal',
        AREA_CODE: 'Indicatif régional',
        ADD_TO_ACCOUNT: 'Ajouter',
        SMS_CREDITS: 'Vos crédits SMS',
        SMS_CREDITS_LEFT: 'Crédits SMS restants.',
        SEND: 'Envoyer',
        NO_NUMBER_PURCHASED: "Vous n'avez pas encore de numéro de téléphone. Utilisez l'option de recherche pour acheter des numéros de téléphone. Après l'achat, vous pourrez envoyer des messages en utilisant les numéros.",
        FIND_PHONE_NUMBER: 'Rechercher des numéros de téléphone par code postal ou indicatif téléphonique',
        FIND_PHONE_NUMBER_BY_AREA_CODE: 'Rechercher des numéros de téléphone par indicatif régional',
        SEND_SMS: 'Envoyer un texto',
        YOUR_MESSAGE: 'Votre message',
        OR: 'OU ALORS',
        PHONE_NUMBER: 'Numéro de téléphone',
        PHONE: 'Téléphoner',
        AVAILABLE_TO: 'Disponible pour',
        USER_NAME: "Nom d'utilisateur",
        USED_BY: 'Utilisé par',
        UNIT_NAME: 'Nom de l\'unité',
    },
    QUICKBOOKS: {
        CONNECT_BUTTON: 'Relier',
        LABEL: 'Connectez-vous à votre compte QuickBooks en ligne'
    },
    PREVIEW_EMAIL_TEMPLATE: {
        ALL_LAYOUT: 'Toutes les mises en page',
        LEFT_SIDEBAR_LAYOUT: 'Disposition de la barre latérale gauche',
        RIGHT_SIDEBAR_LAYOUT: 'Disposition de la barre latérale droite',
        HORIZONTAL_LAYOUT: 'Disposition horizontale',
        MULTIPLE_COLUMN_LAYOUT: 'Disposition à plusieurs colonnes'
    },
    EVENTBRITE: {
        CONNECT_BUTTON: 'Relier',
        SELECT_ORGANISATIONS: 'Synchroniser les événements de cette organisation',
        EVENTBRITE_CONNECT_TITLE: 'Connectez-vous à votre compte Eventbrite',
        SELECT: 'Sélectionner',
        EVENTBRITE_TOGGLE_TITLE: "Lors de la synchronisation, ajoutez de nouveaux contacts s'ils ne sont pas trouvés dans CRM",
        EVENTBRITE_MAP_FIELDS: 'Champs de la carte',
        CRM_FIELDS_TITLE: 'Champs de contact CRM',
        EVENTBRITE_FIELDS_TITLE: 'Champs Eventbrite',
    },
    SIDE_NAV: {
        APPOINTMENTS: 'Calendrier/Tâches',
        DASHBOARD: 'Tableau de bord',
        REPORTS: 'Rapports',
        SALES_TEMPLATES: "Modèles d'e-mails de vente",
        SOCIAL_MARKETING: 'Marketing social',
        WEBSITE_TRACKING: 'Analyse de site Web',
        SOLUTIONS: 'Solutions',
        CONTACTS: 'Contacts',
        ACCOUNTS: 'Comptes',
        OPPORTUNITIES: 'Opportunités',
        PRODUCTS: 'Des produits',
        CASES: 'Cas',
        PROJECTS: 'Projets',
        ISSUES: 'Questions',
        ONBOARDING: 'Intégration',
        UNITS: 'Unités',
        ASSESTS: 'Bibliothèque',
        OPERATIONS_ACTIVITIES: 'Activités',
        OPERATIONS_CONVERSIONS: 'Les conversions',
        OPERATIONS_CAMPAIGNS: 'Campagnes',
        OPERATIONS_REPORTS: 'Rapports',
        LEAD_CAPTURE: 'Capture de plomb',
        EMAIL_MARKETING: 'Publicité par e-mail',
        'CALENDAR/TASKS': 'Calendrier/Tâches',
        'SALES REPORTS': 'Rapports',
        'SALES TEMPLATES': 'Modèles de vente',
        'SOCIAL MARKETING': 'Marketing social',
        'ONLINE REPUTATION': 'Réputation en ligne',
        DASHBOARDS: 'Tableaux de bord',
        'SALES LEADS': 'Leads de vente',
        'USEFUL LINKS': 'Liens utiles',
        NEWS: 'Nouvelles',
        LIBRARY: 'Bibliothèque',
        COMMUNITY: 'Communauté',
        TRAINING: 'Formation',
        SCROLL_TO_RIGHT: 'Faites défiler vers la droite',
        SCROLL_TO_LEFT: 'Faites défiler vers la gauche',
        Royalties: 'Redevance',
        INBOX: 'Boîte de réception',
    },
    NO_RECORD: {
        'Solutions': 'Solutions',
        'Appointments': 'Rendez-vous',
        'Tasks': 'Tâches',
        'Groups': 'Groupes',
        'Notes': 'Remarques',
        'Attachments': 'Pièces jointes',
        'Marketing-templates': 'Modèles de marketing',
        'Sales-templates': 'Modèles de vente',
        'Web-forms': 'Formulaires Web',
        'Products': 'Des produits',
        'Broadcast-campaigns': 'Campagnes de diffusion',
        'Ab-campaigns': 'Campagnes Ab',
        'Automation-designer': "Concepteur d'automatisation",
        'Projects': 'Projets',
        'Invoices': 'Les factures',
        'Iouch-campaigns': 'Campagnes tactiles',
        'Events': 'Événements',
        'Sms-templates': 'Modèles de SMS',

        'Emails': 'E-mails',
        'Relationships': 'Des relations',
        'Templates': 'Modèles',

        'Cases': 'Cas',
        'Contacts': 'Contacts',
        'Accounts': 'Comptes',
        'Opportunities': 'Opportunités',
        'Issues': 'Questions',
        'Activities': 'Activités',
        'Conversions': 'Les conversions',
        'Salesleads': 'Leads de vente',
        'Source': 'La source',
        'Users': 'Utilisateurs',
        'Securitylevel': 'Niveaux de sécurité',
        'Workflows': 'Flux de travail',
        'Relations': 'Rapports',
        'Notetypes': 'Types de notes',
        'Appointmenttypes': 'Types de rendez-vous',
        'Campaigntypes': 'Types de campagne',
        'Emailintegration': 'Alias ​​de messagerie',
        'Library': 'Bibliothèque',
        'Recyclebin': 'Comptes',
        'Sms': 'Sms',
        'Assignmentrules': "Règles d'attribution",
        'Units': 'Unités',
        'Orderitems': 'Items commandés',
        'Landing-pages-froala': 'Pages de destination',
        'Claims': 'Réclamations',
        'Payments': 'Paiements',
    },
    REPORT_SCHEDULE_DIALOG: {
        REPORT_SCHEDULE_SETUP: 'Calendrier de rapport',
        DAY_OF_THE_MONTH: 'jour du mois',
        DAY_OF_THE_WEEK: 'jour de la semaine',
        REPEAT_TYPE: 'Type de répétition (obligatoire)',
        REPEAT_ON: 'Répéter le',
        REPEAT_BY: 'Répéter par',
        START_DATE: 'Date de début (obligatoire)',
        END_DATE: 'Date de fin',
        REPEAT_EVERY: 'Répéter tous les (obligatoire)',
        SEND_TO: 'Envoyer à (obligatoire)',
        SCHEDULE: 'Horaire',
    },
    LIST_VIEW_HEADER: {
        AUTOMATION_DESIGNER: {
            'Name': 'Nom',
            'Status': 'Statut',
            'Created By': 'Créé par',
            'Created On': 'Créé sur',
            'Updated By': 'Mis à jour par',
            'Updated On': 'Mis à jour le',
        },
        APPOINTMENT: {
            'Subject': 'Matière',
            'Company': 'Compagnie',
            'Status': 'Statut',
            'Contact': 'Contacter',
            'Contact Name': 'Nom du contact',
            'Start date': 'Date de début',
            'End date': 'Date de fin',
            'Assign to': 'Affecter à',
            'Visible to': 'Visible par',
            'Outlook Sync': 'Synchronisation Outlook',
            'Google Sync': 'Google Sync',
            'Text': 'Texte',
            'Creator': 'Créateur',
            'Created on': 'Créé sur',
            'Updated by': 'Mis à jour par',
            'Updated on': 'Mis à jour le',
        },
        TASKS: {
            'Task Name': 'Nom de la tâche',
            'Company': 'Compagnie',
            'Status': 'Statut',
            'Previous Task': 'Tâche précédente',
            'Complete Date': 'Date complète',
            'Owner': 'Propriétaire',
            'Due By': 'Dû par',
            'Contact Name': 'Nom du contact',
            'Description': 'La description',
            'Updated on': 'Mis à jour le',
            'Contact': 'Contacter',
            'Start Time': 'Heure de début',
            'End Time': 'Heure de fin',
            'Item': 'Article',
            'Billable': 'Facturable',
        },
        BROADCAST: {
            'Broadcast Name': 'Nom de la diffusion',
            'Status': 'Statut',
            'Run Date': "Date d'exécution",
            'Owner': 'Propriétaire',
            'Created On': 'Créé sur',
            'Updated On': 'Mis à jour le',
            'Target': 'Cible',
            'From email': "De l'email",
            'From name': 'De nom',
            'Updated By': 'Mis à jour par',
            'Created By': 'Créé par',
        },
        AB_CAMPAIGN: {
            'Campaign Name': 'Nom de la campagne',
            'Created By': 'Créé par',
            'Created On': 'Créé sur',
            'Run Date': "Date d'exécution",
            'Status': 'Statut',
            'Target Filter': 'Filtre cible',
            'Updated By': 'Mis à jour par',
            'Updated On': 'Mis à jour le'
        },
        SALES_TEMPLATE: {
            'Name': 'Nom',
            'Category': 'Catégorie',
            'Subject': 'Matière',
            'Status': 'Statut',
            'Owner': 'Propriétaire',
            'Created On': 'Créé sur',
            'Updated By': 'Mis à jour par',
            'Updated On': 'Mis à jour le',
        },
        MARKETING_TEMPLATE: {
            'Name': 'Nom',
            'Category': 'Catégorie',
            'Subject': 'Matière',
            'Status': 'Statut',
            'Owner': 'Propriétaire',
            'Created On': 'Créé sur',
            'Updated By': 'Mis à jour par',
            'Updated On': 'Mis à jour le',
        },
        WEB_FORM: {
            'Name': 'Nom',
            'Table Name': 'Nom de la table',
            'Group': 'Grouper',
            'Source': 'La source',
            'Status': 'Statut',
            'Assign To': 'Affecter à',
        },
        TOUCH_CAMPAIGNS: {
            'Campaign name': 'Nom de la campagne',
            'Status': 'Statut',
            'Owner': 'Propriétaire',
            'Target': 'Cible',
            'From email': "De l'email",
            'Created On': 'Créé sur',
            'Created By': 'Créé par',
            'From name': 'Du nom',
            'Updated On': 'Mis à jour le',
            'Updated By': 'Mis à jour par',
            'Resend All': 'Tout renvoyer',
            'Resend After': 'Renvoyer après',
        },
        ACCOUNTS: {
            'Status': 'Statut',
        },
        CONTACTS: {
            'Status': 'Statut',
        },
        OPPORTUNITIES: {
            'Status': 'Statut',
        },
        PROJECTS: {
            'Status': 'Statut',
        },
        ISSUES: {
            'Status': 'Statut',
        },
        CASES: {
            'Status': 'Statut',
        },
        SOLUTIONS: {
            'Status': 'Statut',
        },
        SALES_LEADS: {
            'Status': 'Statut',
        },
    },
    DEFAULT_NOTE_TYPES: {
        'Appointment': 'Rendez-vous',
        'Dialed': 'Composé',
        'Email': 'E-mail',
        'LVM': 'LVM',
        'Note': 'Noter',
        'Spoke': 'Parlait',
        'Task': 'Tâche',
        'Transferred': 'Transféré',
    },
    VIEW_TOGGLE: {
        LIST: 'Lister',
        COLUMN: 'Colonnes',
        LIST_TITLE: 'Passer à la vue Liste',
        COLUMN_TITLE: 'Passer à la vue en colonnes',
        TIMELINE: 'Chronologie',
        TIMELINE_TITLE: 'Passer à la vue chronologique',
        DETAILS: 'Des détails',
        DETAILS_TITLE: 'Passer à la vue Détails',
    },
    EMAIL_MARKRTING_REPORT: {
        REPORT_HEADER: 'Rapport de marketing par e-mail',
        MY_CAMPAIGNS: 'Mes campagnes',
        MY_LOCATION_CAMPAIGNS: 'Campagnes de mon unité',
        RECENT_CAMPAIGNS: 'Campagnes récentes',
        BROADCAST_REPORT: 'Rapport de campagne de diffusion',
        TOUCH_REPORT: 'Rapport de campagne tactile'
    },
    DOCUMENT_PUSH: {
        DIALOG_TITLE: 'Pousser pour',
        DOCUMENT_NOT_FOUND: 'Aucun document trouvé.'
    },
    OPERATIONS_REPORT: {
        REQUEST_NEW_REPORT: 'Demander un nouveau rapport'
    },
    OPERATIONS_CAMPAIGNS: {
        REPORT_HEADER: 'Campagnes par e-mail',
        BROADCAST_REPORT: 'Campagnes de diffusion',
        TOUCH_REPORT: 'Campagnes tactiles'
    },
    TASKS_REPORT: {
        TASKS_REPORT_LABEL: 'Rapport de tâche'
    },
    EMAIL_REPORT: {
        EMAIL_REPORT_LABEL: 'Rapport par e-mail',
        ERROR_REASON: 'Raison'
    },
    SMS_REPORT: {
        SMS_REPORT_LABEL: 'Rapport SMS'
    },
    ORTHOTIC_REPORT: {
        ORTHOTIC_REPORT_LABEL: 'Rapport orthopédique'
    },
    BATCH_SMS: {
        MESSAGE: 'Votre message',
        SEND: 'Envoyer',
        CHARS: ' caractères',
        SCHEDULE: 'Horaire',
        SCHEDULE_SMS: 'Programmer un message texte',
        SEND_LATER: 'Envoyer plus tard'
    },
    SETTING_MENU: {
        MARKETING: 'Commercialisation',
        SECURITY: 'Utilisateurs et sécurité',
        DATA: 'Donnés',
        CUSTOMIZATION: 'Personnalisation',
        INTEGRATION: "L'intégration",
        COMPANY: 'Société',
        WORKFLOW_RULES:'Flux de travail et règles',
        LISTS: 'Listes',
        FRANCHISE: 'La franchise',
        COMPANY_SCHEDULER: 'Planificateur d\'entreprise'
    },
    MARKETING_SETTING: {
        'SOURCE': 'Sources',
        'LIBRARY': 'Bibliothèque',
        'LEAD_SCORE': 'Scores de plomb',
        'SCORES': 'Les scores'
    },
    SECURITY_SETTING: {
        'USERS': 'Utilisateurs',
        'SECURITY_LEVELS': 'Niveaux de sécurité'
    },
    DATA_SETTING: {
        'EXPORT': 'Exportation',
        'RECYCLE_BIN': 'Corbeille',
        'FIND_DUPLICATES': 'Rechercher des doublons'
    },
    CUSTOMIZATION_SETTING: {
        'CHANGE_LOGO': 'Changer de logo',
        'DATA_BASE': 'Base de données',
        'WORKFLOWS': 'Flux de travail',
        'RELATIONS': 'Rapports',
        'AUTO_FILL_VALUES': 'Valeurs de remplissage automatique',
        'NOTE_TYPES': 'Types de tâches et de notes',
        'CAMPAIGN_TYPES': 'Types de campagne',
        'CORPORATE_CURRENCY': "Monnaie d'entreprise",
        'HUB_SETTING': 'CENTRE',
        'COMPANY_PROFILE': 'Profil de la société',
        'WHITE_LABEL': 'Marque blanche',
        'API_KEY': 'clé API',
        'RE_API_KEY': 'Re générer la clé API',
        'GENERATED_ON': 'Généré le',
        'UPDATED_ON': 'Mis à jour le',
        'MENU': 'Menu',
        'DESKTOP_MENU': 'Menu du bureau',
        'MOBILE_MENU': 'Menu mobile',
        'PHONE_NUMBERS': 'Les numéros de téléphone',
    },
    INTEGRATION_SETTING: {
        'MICROSOFT_OUTLOOK': 'Microsoft Outlook (bureau)',
        'GENERATE_API': 'Générer une API',
        'EMAIL_INTEGRATION': 'Intégration de messagerie'
    },
    SETTINGS: {
        ADDED: 'ajouté',
        EDIT: 'mise à jour',
        SUCCESSFULL: 'Avec succès.',
        'SOURCE': 'La source',
        'NOTETYPES': 'Types de tâches et de notes',
        'CAMPAIGNTYPES': 'Type de campagne',
        'CORPORATECURRENCY': "Monnaie d'entreprise",
        'RELATIONS': 'Relation',
        'LIBRARY': 'Bibliothèque',
        'USERS': 'Utilisateurs',
        'SECURITYLEVEL': 'Niveaux de sécurité',
        'WORKFLOWS': 'Flux de travail',
        'EMAILINTEGRATION': 'Alias ​​de messagerie',
        'RECYCLEBIN': 'Corbeille',
        'ASSIGNMENTRULES': "Règles d'attribution",
        // 'APPOINTMENTTYPES': 'Appointment Type',
        'ROLLUP': 'Règles de cumul',
        'SUBSCRIPTION': 'Abonnement',
        'UPGRADE': 'Abonnement de mise à niveau',
        'BUYPRODUCT': 'Acheter des produits et de la formation',
        'BUYPROSERVICE': 'Acheter des services professionnels',
        'BILLING': 'Détails de facturation et de facturation',
        'CANCELSUB': "Annuler l'abonnement",
        'SUBDETAIL': "Détails de l'abonnement",
        'PURCHESEMAILCREDIT': 'Acheter des crédits par e-mail',
        'PURCHESPHONECREDIT': 'Acheter des crédits téléphoniques',
        'PURCHESSMSCREDIT': 'Acheter des crédits SMS',
        'PURCHESCARDCREDIT': 'Acheter des crédits de numérisation de carte',
        'PURCHESCONTACTCREDIT': 'Acheter plus de contacts',
        'PURCHESTRANINGCREDIT': 'Acheter une formation',
        'PURCHESMERKETINGSERVICE': 'Acheter des services marketing',
        'PURCHESPROFESSONALSERVICE': 'Acheter des services professionnels',
        'SETUP': 'Mes paramètres',
        'APPOINTMENTTYPES': 'Types de rendez-vous',
        SHOW_LOGO: 'Afficher le logo',
        SHOW_SIGNOUT_LINK: 'Afficher le lien de déconnexion',
        THEME_COLOR:'Couleur du thème',
        LOGO_COLOR :'Fond de logo',
        AUDIT_QUESTIONS: "Liste de contrôle d'audit",
        LMS_USERS: 'Utilisateurs LMS',
    },
    CORPORATE_CURRENCY: {
        SELECT_CURRENCY_NAME: "Sélectionnez une devise d'entreprise"
    },
    UNSUBSCRIBE: {
        EMAILS: 'E-mails',
        TEXTS: 'Les textes',
        BOTHS: 'Tous les deux'
    },
    HUB_SETUP: {
        SETUP_FIELD_NAME: 'Nom du hub',
        USEFUL_LINK: 'Liens utiles',
        CASES: 'Cas',
        SETUP_NAME: 'Configuration du HUB',
        NEWS: 'Nouvelles',
        LIBRARY: 'Bibliothèque',
        COMMUNITY: 'Communauté',
        TRAINING: 'Formation',
        HYPER_LINK: 'hyperlien',
        SET: 'Ensemble',
        MANNUAL: 'Manuel'
    },
    UNITS: {
        TASK_DETAILS: 'Détails des tâches',
        'Unit': 'Unité',
        'Contact Name': 'Nom du contact',
        'Done': 'Fait',
        'Open': 'Ouvert',
        'Tasks Name': 'Nom des tâches',
        'Due By': 'Dû par',
        'Status': 'Statut',
        UNIT_DETAILS_LABEL: "Détails de l'unité",
        FIRST_NAME: 'Prénom (obligatoire)',
        LAST_NAME: 'Nom (obligatoire)',
        PASSWORD: 'Mot de passe requis)',
        RETYPE_PASSWORD: 'Retapez le mot de passe (obligatoire)',
        EMAIL: 'Email (requis)',
        PHONE: 'Téléphone (obligatoire)',
        COMPANY: 'Entreprise (obligatoire)',
        TIME_ZONE_SELECT: 'Fuseau horaire',
        COUNTRY_SELECT: 'Pays',
        STATE_SELECT: 'État',
        NO_UNIT_TASKS: 'Aucune tâche n’est disponible pour le propriétaire de l’unité.',
        NO_ACCOUNT_TASKS: 'Aucune tâche n’est disponible pour le compte.'
    },
    ASSIGNMENT_RULES: {
        SETUP_FIRST_RULE: 'Configurez votre première règle',
        ASSIGNMENT_RULE: "Règle d'affectation",
        ASSIGNMENT_RULE_CREATE_TAB_LABEL: "Créer une règle d'attribution",
        ASSIGNMENT_RULE_EDIT_TAB_LABEL: "Modifier la règle d'attribution",
        NAME_REQUIRED: 'Nom (obligatoire)',
        ASSIGNMENT_RULES: "Règles d'attribution",
        STATUS: 'Statut',
        SETUP_RULE: "Règle d'attribution de configuration",
        USE_RULE: "Utiliser la règle d'attribution",
        TABLE_NAME: 'Nom de la table',
        SELECT_UNIT_WORKFLOW: 'Nom de la table'
    },
    COUPONS: {
        PREFIX: 'Préfixe',
        'Coupon Name': 'Nom du coupon',
        'Discount Value': 'Valeur de la remise',
        EXPIRATION_DATE: "Date d'expiration",
        PODUCT_SERVICE: 'Produit/Service (facultatif)'

    },
    COUPONS_CLAIM_REPORTS: {
        COUPONS_CLAIM_REPORTS_LABEL: 'Rapport sur les réclamations de bons de réduction',
        COUPONS_REPORT: 'Signaler'
    },

    NOTIFICATION_REMINDERS: {
        NOTIFICATION_REMINDERS: 'Alertes et notifications',
        NOTIFY_ME: "M'avertir quand...",
        REMIND_ME: 'Rappelle-moi avant...',
        NOTIFY_EMAIL_OPEN: 'Mon email est ouvert',
        NOTIFY_EMAIL_CLICK: 'Mon email est cliqué',
        NOTIFY_TEXT_REPLY: 'Mon texte est répondu',
        REMIND_UPCOMING_APPOINTMENT: 'Prochains rendez-vous',
        REMIND_UPCOMING_TASK: 'Tâches à venir',
        ADD_NEW_REMINDER: 'Ajouter un nouveau rappel',
        REMIND: 'Rappeler',
        DAY_BEFORE: 'jours avant',
        MY_WEB_FORM_SUBMITTTED: 'Mon formulaire Web est soumis',
        NO_DATE_FIELD: 'Aucun champ de date disponible',
    },

    PIPELINE_REPORT: {
        PIPELINE_REPORT_LABEL: 'Rapport sur le pipeline',
        SALES_SUMMARY_REPORT: 'Rapport de synthèse des ventes'
    },
    EMAIL_INTEGRATION: {
        EMAIL: 'Alias ​​de messagerie',
    },
    NEWS: {
        HEADER: 'Nouvelles',
        STATUS: {
            DRAFT: 'Brouillon',
            PUBLISHED: 'Publié'
        },
        EDIT_TAB_LABEL: 'Modifier les actualités',
        ADD_TAB_LABEL: 'Ajouter des nouvelles',
        CATEGORY_REQUIRED: 'Catégorie (obligatoire)',
        STATUS_REQUIRED: 'Statut (obligatoire)',
        TITLE_REQUIRED: 'Titre (obligatoire)',
        SUMMARY_REQUIRED: 'Résumé (obligatoire)',
        EXTERNAL_LINK_REQUIRED: 'Lien externe (obligatoire)',
        UPLOAD_IMAGE: 'Télécharger une image',
        EXTERNAL_LINK: 'lien externe',
        ENTER_DETAILS: 'Entrez les détails'
    },
    USEFUL_LINKS: {
        USEFUL_LINKS: 'Liens utiles',
        CATEGORY: 'Catégorie',
        LINK: 'Relier',
        TITLE: 'Titre',
        NO_RECORD_FOUND: 'Aucun lien utile trouvé.',
    },
    REPORTS: {
        NO_REPORTS_FOUND: 'Aucun rapport trouvé.',
    },
    AUTO_FILL_VALUES: {
        SELECT_PARENT: 'Sélectionnez un parent',
        SELECT_CHILD: 'Sélectionnez un enfant',
        PARENT_FIELDS: "Champs d'objet parent",
        CHILD_FIELDS: "Champs d'objet enfant",
        SELECT_PARENT_FIELD: 'Sélectionnez un champ parent',
        SELECT_CHILD_FIELD: 'Sélectionnez un champ enfant',
    },
    WORKFLOW_AUTOMATION: {
        TOUCH_CAMPAIGN_REPORT: 'Rapport de campagne tactile',
        INTERVAL: 'Après',
        NOTE_TYPE: 'Type de note',
        TEXT_REQUIRED: 'Texte de la note (obligatoire)',
        TASK_NAME_REQUIRED: 'Nom de la tâche (obligatoire)',
        OWNER_REQUIRED: 'Propriétaire (obligatoire)',
        DUE_BY_REQUIRED: 'Dû par (obligatoire)',
        DAY_AFTER_REQUIRED: 'Jours après (obligatoire)',
        EMAIL: 'E-mail',
        POP_UP: 'Surgir',
        MINUTES: 'minutes',
        HOURS: 'les heures',
        DAYS: 'journées',
        WEEKS: 'semaines',
        REMINDER: 'Rappel',
        GROUP_REQUIRED: 'Sélectionnez un groupe (obligatoire)',
        ADD_TO_GROUP: 'Ajouter au groupe',
        MOVE_TO_GROUP: 'Déplacer vers le groupe',
        REMOVE_FROM_GROUP: 'Supprimer du groupe',
        STATUS_REQUIRED: 'Statut (obligatoire)',
        WORKFLOW_REQUIRED: 'Workflow (obligatoire)',
        CHOOSE_ANOTHER_FIELD: 'Choisissez un autre champ',
        CHOOSE: 'Choisir',
        FIELD_FOR: 'un champ pour',
        CAMPAIGN_NAME: 'Nom de la campagne (obligatoire)',
        START: 'Démarrer',
        STOP: 'Arrêter',
        REPORT: 'Signaler',
        RESUME: 'Reprendre',
        SELECT_PAGE: 'Sélectionnez une page',
        ENTER_WEB_PAGE_LINK_OPTIONAL: "Saisissez l'URL du lien (facultatif).",
        ENTER_WEB_PAGE_LINK: 'Entrez le lien de la page Web.',
        CLICK_TO_VIEW: 'Cliquez ici pour afficher',
        CLICK_TO_EDIT: 'Cliquez ici pour modifier',
        DRAG_TO_MOVE: 'Glisser pour déplacer',
        DRAG_TO_CONNECT: 'Faites glisser pour vous connecter',
        CLICK_TO_REMOVE: 'Cliquez ici pour supprimer',
        CLICK_TO_SEE_REPORT: 'Cliquez ici pour voir le rapport',
        CLICK_TO_ADD_MSG: 'Cliquez pour ajouter un nouveau message',
        NO: 'Non',
        YES: 'Oui',
        CLICK_TO_SELECT_USERS: 'Cliquez ici pour sélectionner les utilisateurs',
        SALES_REP: 'Représentant commercial',
        USERS: 'Utilisateurs',
        CAMPAIGN_SENDER: 'Expéditeur de la campagne',
        SCORE: 'But',
        START_WITH: 'Ajouter',//'Start with'
        DECISIONS: 'Les décisions',
        ACTIONS: 'Actions',
        LINK_CLICKED: 'Lien cliqué?',
        SCORE_REACHED: 'Score atteint?',
        TOUCH_CAMPAIGN: 'Campagne tactile',
        WEB_FORM: 'Formulaire Web',
        LANDING_PAGE: 'Page de destination',
        WEBSITE_VISITED: 'Site Web visité',
        CHANGE_GROUP: 'Changer de groupe',
        ADD_NOTE: 'Ajouter une note',
        NOTIFY: 'Notifier',
        ADD_TASK: 'Ajouter une tâche',
        FIELD_UPDATED: 'Mettre à jour les champs',
        CHOOSE_TEMPLATE: 'Choisissez un modèle',
        USE_THIS_TEMPLATE: 'Utilisez ce modèle',
        WAIT: 'Attendez',
        NAME: "Nom",
        TARGET_GROUPS: 'Groupes cibles)',
        TARGET_FILTER: 'Filtre cible',
        RESEND_AFTER: 'Renvoyer après',
        START_DATE: 'Date de début',
        SUBJECT: 'Matière',
        TEXT: 'Texte',
        TASK_NAME: 'Nom de la tâche',
        ASSIGN_TO: 'Affecter à',
        FIELD_LABEL: 'Étiquette de champ',
        FIELD_VALUE: 'Valeur du champ',
        LINK: 'Relier',
        SEND_ON_ANY_TIME: 'Envoyer: à tout moment',
        SEND_ON_BUSINESS_DAY: 'Envoyer: uniquement les jours ouvrables',
        CONVERT_TO: 'Convertir en',
        QUALIFIER: 'Qualificatif',
        OBJECT: 'Objet',
        NOTIFY_SENDER: "Notifier l'expéditeur",
        NOTIFY_SALES_REP: 'Avertir le représentant des ventes',
        NOTIFY_SENDER_SALESREP: "Notifier l'expéditeur, notifier le représentant commercial",
        NOTIFY_USERS: 'Notifier les utilisateurs',
        TEST: 'Test',
        VIEW_AS_WEBPAGE_INFO: "Cette option ajoutera un lien en haut de l'e-mail pour ouvrir l'e-mail en tant que page Web",
        LINK_CLICK_INFORMATION: "Si vous laissez ce champ vide, tous les liens de l'e-mail seront suivis.",
        GROUP_NAME: 'Nouveau nom de groupe',
        SELECT_USER: 'Affecter à',
        REMIND_BEFORE: 'Rappeler avant',
        INTERVAL_REQUIRED: 'Intervalle (obligatoire)',
        //not found in doc
        VERIFY_EMAIL_ADDRESS_OPTION: 'Vérifier une autre adresse e-mail',
        'Touch Campaign': 'Campagne tactile',
        'Required Tasks Done': 'Tâches requises effectuées',
        'Not Converted': 'Non converti',
        'Link Clicked': 'Lien cliqué',
        'Link Clicked?': 'Lien cliqué?',
        'Email Opened': 'Courriel ouvert',
        'Email Opened?': 'Courriel ouvert',
        'Unsubscribed': 'Désabonné',
        'Score Reached': 'Score atteint',
        //'Send One-off Email': 'Send One-off Email',
        'Send Email': 'Envoyer un e-mail',
        'Change Group': 'Changer de groupe',
        'Add Note': 'Ajouter une note',
        'Notify': 'Notifier',
        'Add Task': 'Ajouter une tâche',
        'Update Fields': 'Mettre à jour les champs',
        'Assign': 'Attribuer',
        'Convert': 'Convertir',
        'Start': 'Démarrer',
        'Add Touch Message': 'Ajouter un message tactile',
        'Web Form': 'Formulaire Web',
        'Website Visit': 'Visite du site Web',
        'Landing Page': 'Page de destination',
        'Wait': 'Attendez',
        WAIT_FOR_DAYS: 'journées)',
        'Simple email nurturing': 'Gestion simple des e-mails',
        'Capture leads using a web form and nurture': "Capturez des prospects à l'aide d'un formulaire Web",
        'Track web page visit and nurture': 'Suivre la visite de la page Web et nourrir',
        'Capture leads from a landing page and nurture': "Capturez des prospects à partir d'une page de destination et nourrissez-les",
        'Start with tracking website visit': 'Commencez par suivre la visite du site Web',

        COPY_OF: 'Copie de ',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'Nouvelle campagne automatisée',
        TARGET_BY: 'Cibler par',
        'Hours': 'Les heures',
        'Hour(s)': 'Les heures)',
        'day(s)': 'journées)',
        'hour(s)': 'les heures)',
        ALL_AUTOMATED_CAMPAIGNS: 'Toutes les campagnes automatisées',
        MESSAGE: 'Un message',
        SELECT_A_CAMPAIGN: 'Veuillez sélectionner une campagne',
        ADD_SIGNATURE: 'Ajouter une signature',
        BIRTHDAY_CAMPAIGN: "Campagne d'anniversaire",
        'Add Birthday Message': "Ajouter un message d'anniversaire",
        'Start with Birthday Campaign': "Commencez avec la campagne d'anniversaire",
        'SMS Replied': 'SMS répondu',
        'Send SMS': 'Envoyer un SMS',
        PET_BIRTHDAY_CAMPAIGN: "Campagne d'anniversaire pour animaux de compagnie",

        'Touch Campaign Automation': 'Automatisation de campagne tactile',
        'Birthday Campaign Automation': "Automatisation de la campagne d'anniversaire",
        'Web Form Automation': 'Automatisation des formulaires Web',
        'Landing Page Automation': 'Automatisation de la page de destination',
        'Pet Birthday Greetings': "Salutations d'anniversaire pour animaux de compagnie",


        SEND_SMS: 'Envoyer un SMS',
        SMS_REPLY: 'SMS répondu?',
        BIRTHDAY_CAMPAIGN_REPORT: "Rapport de campagne d'anniversaire",
        START_IMMEDIATELY: 'Immédiatement',
        START_SCHEDULE: 'Horaire',
        SCHEDULE_ON_TEXT: 'Programmé pour démarrer le',
        SCHEDULE_DIALOG_TITLE: "Sélectionnez la date et l'heure du programme",
        FROM_NUMBER: 'Du numéro',
        REPLY: 'Réponse',
        NOT_CONVERTED: 'Si non convoqué avec in',
        DAYS_AFTER: 'Des jours après'
    },
    LANDING_PAGE: {
        LANDING_PAGE: 'Page de destination',
        LANDING_PAGES: 'Pages de destination',
        NAME_REQUIRED: 'Nom (obligatoire)',
        URL_REQUIRED: 'URL (obligatoire)',
        LANDING_PAGE_FORMS: 'Générateur de formulaires',
        EDIT_LANDING_PAGE: 'Modifier la page de destination',
        COPY_LANDING_PAGE: 'Copier la page de destination'
    },
    FIND_DUPLICATES: {
        FIND_DUPLICATES: 'Rechercher des doublons',
        SELECT_OBJECT: 'Sélectionnez un objet',
        SELECT_FIELD: 'Sélectionnez un champ',

    },
    SCHEDULER: {
        APPOINTMENT_SCHEDULER: 'Planificateur de rendez-vous',
        APPOINTMENT_INFO: 'Informations sur le rendez-vous',
        AVAILABILITY:'Disponibilité',
        APPOINTMENT_TYPES:'Types de rendez-vous',
        MY_AVAILABILITY: 'Ma disponibilité',
        SCHEDULER_LINK: 'Lien du planificateur',
        INTAKE_FORM: "Formulaire d'admission",
        APPOINTMENT_NAME: 'Nom du rendez-vous (obligatoire)',
        LOCATION: 'Emplacement',
        TASKS:'Tâches',
        UNIT_CRM:'Unité CRM',
        NOTIFICATION_SETUP:'Configuration des notifications',
        DURATION: 'Durée',
        MIN_ADVANCE_NOTICE: 'Préavis minimum ',
        DESCRIPTION: 'La description',
        COPY_TO_CLIPBOARD: 'Copier dans mon presse-papiers',
        SCHEDULER_LINK_TITLE: 'Lien de mon planificateur de rendez-vous',
        SCHEDULING_PAGE: 'Planificateur de rendez-vous',
        ADD_BUTTON: 'Ajouter un nouvel horaire',
        MY_SCHEDULAR_LINK: 'Mon lien de planificateur',
        MY_APPOINTMENT_TYPE: 'Mon type de rendez-vous',
        APPOINTMENT_TYPE: 'Type de rendez-vous',
        DEFINE_YOUR_INTAKE_FORM: "Définissez votre formulaire d'admission",
        REQUIRED: 'Obligatoire',
        ADD_ANOTHER_FIELD: 'Ajouter un autre champ',
        SUBMIT_BUTTON_LABEL: "Soumettre l'étiquette du bouton",
        CHOOSE_A_STATUS_FOR_LEAD: 'Choisissez un statut pour les prospects ajoutés à partir du planificateur',
        DEFINE_YOUR_THANK_YOU_MESSAGE: 'Définissez votre message de remerciement',
        THANK_YOU_MESSAGE: 'Message de remerciement',
    },
    RELATION_REPORT: {
        RELATION_REPORT_LABEL: 'Matrice de relation',
        REPORT_SAVE_LABEL: 'Enregistrer la personnalisation',
        REPORT_NAME_LABEL: 'Nom du rapport personnalisé',
        REPORT_NAME_TEXTFIELD_LABEL: 'Nom du rapport',
        ROW_FIELD_LABEL: 'Champ de ligne',
        COL_FIELD_LABEL: 'Champ de colonne',
        DATE_FIELD_LABEL: 'Champ de date',
        PDF_LABEL_DURATION: 'Durée',
        RELATION_FIELD_LABEL: 'Relation',
        SAVE_CUSTOMIZATION_LABEL: 'Enregistrer la personnalisation',
        RUN_BUTTON_LABEL: 'Cours'
    },
    COMPANY_PROFILE: {
        UPLOAD_LOGO: 'Téléchargez votre logo',
        WEBSITE: 'Site Internet',
        BUSINESS_NAME: "Nom de l'entreprise",
        BUSINESS_ADDRESS: 'Adresse de travail',
        BUSINESS_PHONE: 'Téléphone de travail',
        BUSINESS_EMAIL: 'Courriel professionnel',
        CORPORATE_CURRENCY: "Monnaie d'entreprise"
    },
    EXPORT_SETTINGS: {
        EXPORT_HEADING: 'Exportation',
        FILE_NAME_REQUIRED: 'Nom du fichier (obligatoire)',
        PARENT_OBJECT_REQUIRED: "Nom de l'objet parent (obligatoire)",
        FILE_TYPE_REQUIRED: 'Type de fichier (obligatoire)',
        QUERY_NAME_REQUIRED: 'Nom de la requête (obligatoire)',
        SELECTED_FIELDS_FOR_PARENT: 'Champs sélectionnés pour le parent',
        AVAILABLE_FIELDS_FOR_PARENT: 'Champs disponibles pour le parent',
        CHILD_OBJECT_NAME: "Nom de l'objet enfant",
        AVAILABLE_FIELDS_FOR_CHILD: 'Champs disponibles pour enfant',
        SELECTED_FIELDS_FOR_CHILD: 'Champs sélectionnés pour enfant',
        EXPORT_BUTTON: 'Exportation',
        DOWNLOAD_BUTTON: 'Télécharger un fichier',
        ADD: 'Ajouter',
        ADD_ALL: 'Tout ajouter',
        REMOVE: 'Supprimer',
        REMOVE_ALL: 'Enlever tout'
    },
    QUEUE: {
        QUEUE: "File d'attente",
        PREVIOUS: "Précédent dans la file d'attente",
        NEXT: "Suivant dans la file d'attente",
        REMOVE: "Retirer de la file d'attente",
        CLOSE: "Quittez la file d'attente",
    },
    SECURITY_LEVEL: {
        ACCESS: 'Accès',
        DESCRIPTION: 'La description',
        VIEW: 'Vue',
        PERMISSIONS: 'Autorisations',
        OPERATIONS: 'Opérations',
        ADMINISTRATION: 'Administration',
    },
    ROLL_UP: {
        CHILD_OBJECT_NAME: 'Objet enfant',
        PARENT_OBJECT_NAME: 'Objet parent',
        CHILD_FIELD_NAME: 'Nom du champ enfant',
        PARENT_FIELD_NAME: 'Nom du champ parent',
    },
    ROYALTY_REPORT: {
        ROYALTY_REPORT_LABEL: 'Rapport sur les redevances',
        ROYALTY_PERCENT: 'Royalties %',
        SHOW: 'Spectacle',
        ALL_INVOICES: 'Toutes les factures',
        PAID_INVOICE_ONLY: 'Factures payées uniquement',
        SETUP_TITLE: 'Configuration des redevances',
        PAID_INVOICES: 'Factures payées',
        USE: 'Utiliser',
        FIXED: '% fixe',
        VARIABLE: 'Variable %',
        TOTAL_INVOICES: 'Factures totales',
        TOTAL_PAYMENTS: 'Total des paiements',
        CALCULATE: 'Calculer',
        WEEKLY: 'Hebdomadaire',
        MONTHLY: 'Mensuel',
        WEEK_TYPE: 'Type de semaine',
        MON_TO_FRI: 'Lundi au vendredi',
        MON_TO_SUN: 'Lundi au soleil',
        ADD_INVOICES: 'Ajouter une facture',
        TOTAL_Reported_Sales: 'Ventes totales déclarées',
        YEAR: 'An',
        MARKETING_FEE: 'Frais de marketing',
        TECH_FEE: 'Frais de technologie',
        PERCENT: 'Pourcentage',
        FIXED_VALUE: 'Fixé',
        WITH: 'Avec des frais',
        WITH_OUT: 'Sans frais',
        Reported_Sales_Franchisee: 'Ventes rapportées - Franchisé',
        Reported_Sales_Franchisor: 'Ventes rapportées - Franchiseur',
        Reported_Sales_ThirdParty: 'Ventes rapportées - Tiers',
    },
    POWER_CALLING: {
      NOTE_AND_CALL_SCRIPTS: "Notes et scripts d'appel",
      SEND: 'ENVOYER',
      LOG_CALL:'Journal des appels',
      CALLER_ID: "Identification de l'appelant",
      END_CALL: "Fin d'appel",
      MOBILE: 'Mobile',
      PHONE: 'Téléphoner',
      HOME_PHONE:'Téléphone fixe',
      NEXT: 'suivant',
      COMPLETE: 'achevé',
      OF: 'de',
      POWER_DIALER: 'Numéroteur électrique'
    },
    LEAD_SCORE_SETUP:{ 
        SCORES: 'Les scores',
        NO_SCORES_AVAILABLE: "Aucune action n'est disponible.",
        OBJECT: 'Objet',
        GROUPS:'Groupes',
        WORKFLOW_NAME: 'Nom du workflow',
        CONVERTED_FROM:'Converti à partir de',
        CONVERTED_TO: 'Converti en',
        FIELDS_NAME: 'Nom des champs',
        POINTS: 'Points',
        EXPIRES_BY: 'Expire le',
        APPLY_TO_EXISTING_SCORE: "*La date d'expiration s'appliquera rétroactivement à vos scores existants.",
        GROUP_ALREADY_REMOVE: 'Groupe déjà supprimé. Veuillez modifier avant de sauvegarder.',
        FIELD_ALREADY_REMOVE: 'Champ déjà supprimé. Veuillez modifier avant de sauvegarder.',
        FIELD_VALUE_ALREADY_REMOVE:'La valeur du champ a déjà été supprimée. Veuillez modifier avant de sauvegarder.',
        AND_ABOVE: 'et ci-dessus',
        LESS_THAN: 'Moins que',
    },
    DUPLICATE_RULE: {
        DUPLICATE_RULE: 'Règles en double',
        DUPLICATE_RULE_HEAD_LABEL: "Vérifier Doublons aide",
        SELECT_OBJECT_LABEL: "Sélectionnez l'objet",
        ACTIVE: 'actif',
        DEFAULT: 'Défaut',
        ONE_FIELD: 'un champ',
        TWO_FIELD: 'deux champs',
        THREE_FIELD: 'trois champs',
        FIELD_ONE: 'champ 1',
        FIELD_TWO: 'champ 2',
        FIELD_THREE: 'champ 3',
        SELECT_FIELD_LABEL: 'Sélectionner le champ',
        CHECK_DUPLICATES: 'Vérifiez doublons',
        SELECT_RULES: 'Sélectionnez une règle',
    },
    JOBS:{
        RESCHEDULE: 'Reprogrammer',
        OMW:'omw',
        INVOICE:'Facturer',
        PAY:'Payer'
    },
    TASK_REPORT:{
        TITLE:'Rapport de tâche',
        UNITS:'Unités',
        NO_TASK_FOUND:'Aucune tâche trouvée',
    },
    AUDIT_TASK_REPORT_DETAILS:{
        TASK_REPORT_DETAILS:'Détails du rapport de tâche',
    },
    AUDIT_QUESTIONS: {
        MINIMUM_OPTIONS: 'Il faut au moins 2 choix doivent être dans cette question',
        SINGLE_RECORD: 'Seulement 1 enregistrement est autorisé à bouger à la fois.',
        AUDIT_QUESTIONS: "Questions d'audit",
        GIVE_FEEDBACK:'Donner votre avis',
        NEW_QUESTION: 'Nouvelle question',
        TEXT: 'Texte court',
        COMMENT: 'Texte long',
        LIST: 'Choix unique (Dropdown)',
        CHECKBOX: 'Choix multiple (cases à cocher)',
        QUESTION: 'Question',
        ADD_NEW: 'Ajouter de nouveau',
        ADD_NEW_CATEGORY:'Add a New Category',
        SINGLE_RECORD_EDIT: 'Veuillez sélectionner 1 enregistrement à modifier.',
        MESSAGE_DELETE: 'Êtes-vous sûr de vouloir supprimer',
        SELECTED_RECORDS: 'Enregistrement sélectionné (s)?',
        YOUR_ANSWER: "Votre Réponse",
        SUBMITTING: 'Soumission...',
        SAVE_CATEGORY:'Enregistrer la catégorie',
        SUBMIT_AUDIT:'Soumettre l audit',
        SUBMIT: 'Soumettre',
        SCORE: 'But',
        REQUIRED: 'Obligatoire',
        CHOICE: 'Choix',
        FIELD_REQUIRED: 'Ce champ est obligatoire *',
        NEW_CHOICE: 'Nouveau choix',
        FREE_FORM: 'Forme libre',
        SCORE_HELP_TOOLTIP: "Attribuer des points ou des scores aux réponses. Les scores s'ajoutent pour calculer le score total de l'audit.",
        SURVEY_LINK: "Lien d'audit / enquête",
        NEXT_CATEGORY:'Catégorie suivante',
        GENERATE:'Générer',
        FIELD_AUDIT:'Audit sur le terrain (audité par le franchiseur)',
        SELF_AUDIT:'Auto-audit (audité par le franchisé)',
        GENERATE_AUDIT_LINK :"Générer un lien d'audit",
        REQUIRES_PHOTO: 'Nécessite une photo',
        ENABLE_NOTE: 'Activer la note',
        ADD_PHOTO: 'Photo',
        UPDATE_PHOTO: 'Mise à jour',
        NOTE: 'Ajouter une note',
        PHOTO_REQUIRED: 'La photo est obligatoire *',
    },
    AUDIT_ANSWERS: {
        AUDIT_ANSWERS_LABEL: 'Audit Réponse',
        QUESTION:'Question',
        SCORE: 'But',
        ANSWER: 'Réponse',
        SUBMIT_CORRECTIVE_ACTIONS:'Soumettre des actions correctives',
        REQUEST_CORRECTIVE_ACTIONS:'Demander des actions correctives',
        INSERT_PHOTO:'Insérer une photo',
    },
    AUDIT_WEAKNESS:{
        CATEGORY:'Catégorie',
        MIN_FAILURE_COUNT:"Nombre minimum d'échecs",
    },
    COMPLIANCE_REPORT:{
        COMPLIANCE_REPORT_LABEL: 'Rapport de conformité',
    },
    SALES_AND_REFUND: {
        PERIOD:'Période',
        START_DATE:'Start Date',
        END_DATE :'Date de début',
        BASED_ON :'Basé sur',
        SALES_AND_REFUND_TITLE:'Ventes et remboursement',
        SALES_AND_REFUND_DETAIL:'Détails des ventes et des remboursements',
    },
    SALES_AND_REFUND_DETAILS_REPORT:{
        SALES_AND_REFUND_DETAILS :'Détails du rapport de ventes et de remboursement',
        START_DATE:'Date de début',
        END_DATE:'Date de fin',
        BASED_ON:'Basé sur',
        NO_RECORD_EXPORT: 'Aucun Enregistrement Trouvé'
    },
    TOTAL_REPORTED_SALES_DRILLDOWN:{
        TOTAL_REPORTED_SALES_DRILLDOWN:'Détail des ventes déclarées par les redevances'
    },
    OTHER_FORM_DATA: {
        CARETAKING:'Caretaking',
        EXPLANATION:'Explanation',
        EXPLANATION_HISTORY_TIMELINE:'Explanation of history/timeline (dates/places/etc)',
        PEOPLE_PLEASING:'People Pleasing',
        RELATIONSHIPS:'Relationships',
        PERFECTIONISM:'Perfectionism',
        CONTROL:'Control',
        WORKAHOLISM:'Workaholism',
        SHOPPING_SPENDING:'Shopping/Spending',
        RELIGION_OR_ABUSE:'Religion/Abuse',
        RISK_TAKING:'Risk Taking',
        EXCESSIVE_SCREEN_TIME:'Excessive Screen time',
        DEPRESSION:'Depression',
        ANXIETY:'Anxiety',
        ANGER_OR_RAGE:'Anger/Rage',
        SELF_EXTEEM:'Self Esteem',
        PAINFUL_OR_TROMATIC :'Describe any painful/traumatic/memorable experiences or events that have been impactful in your life (trauma)',
        MOTHER:'Mother',
        FATHER:'Father',
        SIBLINGS:'Siblings',
        CHILDREN:'Children',
        SIG_OTHER:'Sig. Other',
        OTHER:'Other',
        PRESENTING_PROBLEM:'Describe the main presenting problem or current crisis/goal for coming to TBR',
        ALCOHAL:'Alcohol',
        HOW_MUCH:'How Much & What type?',
        HOW_OFTEN:'How often, and for how long?',
        DATE_LAST_USED:'Date Last Used',
        COCAINE_OR_CRACK :'Cocaine/Crack',
        HEROINE:'Heroin',
        MARIJUANA:'Marijuana',
        METHADONE_OR_SUBOXONE :'Methadone/Suboxone',
        AMPHETAMINES :'Amphetamines & Stimulants (Meth, Adderall, Speed)',
        NARCOTICS_OR_OPIATES:'Narcotics/Opiates',
        BENZODIAZEPINES:'Benzodiazepines',
        TOBACCO:'Tobacco',
        OTHER_ETC:'Other (Hallucinogenic, OTC, Synthesis, etc)',
        WITHDRAWEL_SYMPTOMS :'Withdrawal Symptoms (shaking, sweating, tremors, seizures etc)',
        MEDICAL_DETOX:'Medical Detox',
        PRIMARY_TRATMENT_OR_IOP:'Primary Treatment/IOP',
        SUCIDE_THOUGHT:'Had suicidal Thoughts?',
        ENGAGED_IN_SELF_HARD:'Engaged in Self Hard',
        ATTEMPED_SUCIDE:'Attempted Suicide While under the influence?',
        ENGAGED_IN_CUTTING:'Engaged in "cutting" behaviors',
        ATTEMPED_SUCIDE_WHITE_SOBER:'Attempted suicide while Sober?',
        ENGEGED_IN_SCORINCATION :'Engaged in "scorincation"' ,
        HOSPITALIZED_FOR_A_SUICIDE_ATTEMPT:'Been hospitalized for a suicide attempt?',
        ENGEGED_IN_BODY_MUTATION:'Engaged in any other body mutation bx',
        HISTORY_EXPLANATION:'If history, explanation (use this space to provide further details, including dates, current ideation, thoughts or plans, etc.)',
        PHYCHIATRIC_DIANOSIS:'Psychiatric diagnosis',
        DATES_DIAGNOSIS_TREATMENT:'Date, diagnosis, treatment',
        MENTAL_BREAKDOWNS_OR_HOSPITALIZATIONS:'Mental Breakdowns/Hospitalizations' ,
        ANOREXIA:'Anorexia',
        TREATMENT_HISTORY:'Treatment History',
        PERIODS_OF_ABSTINENCE:'Periods of Abstinence',
        LAST_EPISODE:'Last Episode',
        LOWEST_WEIGHT:'Lowest Weight',
        HIGHEST_WEIGHT:'Highest Weight',
        CURRENT_WEIGHT_OR_HEIGHT: 'Current Weight/Height',
        HOW_LONG_AT_CURRENT_WEIGHT:'How Long at Current Weight',
        BULIMIA:'Bulimia',
        OVEREATING:'Overeating',
        EXCESSING_EXCERCISING:'Excessive Exercising',
        DETAILS_SOBRIETY_LENGTH_PATTERNS:'If history, explanation (use this space to provide further details, sobriety length, patterns etc.)',
        BETTING:'Betting',
        ONSET:'Onset',
        TREATMENT:'Treatment',
        AMOUNT_OF_DEBT_ACCUMULAATED:'Amount of Debt accumulated',
        TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING:'Time per day/week spent gambling',
        GAMING_OR_CASINOS :'Gaming/Casinos',
        ONLINE_GAMES_OR_APPS:'Online Games/Apps' ,
        PORNOGRAPHY:'Pornography',
        FIRED:'Have you ever been fired or had job threatened because of behavior?',
        EXCESSIVE_MASTURBATION:'Excessive Masturbation',
        VOYEURISM_OR_EXHIBITIONIST:'Voyeurism/Exhibitionist',
        ANONYMOUS_SEX:'Anonymous Sex (casual sex, one-night stands, hookup apps/websites)',
        PROSTITUTION:'Prostitution',
        AFFAIRS:'Affairs (Physical/Emotional)',
        SERIAL_RELATIONSHIP:'Serial Relationships',
        INCLUDE_DATE_TEASON_OR_CHARGE:'Include Date, Reason/Charge (What was the charge/reason for arrest), Result (was there a conviction, jail time served, probation/parole, sex offender registration etc), Current Pending/Legal Obligations',
        CODEPENDENCY_AND_PROCESS_ADDITIONS_BEHAVIOURS:'Codependency and process addictions behaviors ("C" for Current, "D" Denies or "H" for History)',
        RELATIONSHIP_AND_FAMILY_HISTORY:'Relationship/family history (List current relationship status and family history of addiction/psych issues)',
        SUBSTANCE_HISTORY:'Substance history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUBSTANCE_TREATMENT_HISTORY:'Substance treatment history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUICIDIAL_IDEATION:'Suicidal ideation / self harm behaviors ("C" for Current, "D" Denies or "H" for History)',
        MENTAL_HEALTH_AND_PSHYATRATIC_DIAGNOSIS_HISTORY :'Mental health/ psychiatric diagnosis history- ("C" for Current, "D" Denies or "H" for History)',
        EATING_DISORDER_BEHAVIER:'Eating disorder behaviors - ("C" for Current, "D" Denies or "H" for History)',
        GAMBLIMG_BEHAVIER:'Gambling behaviors - ("C" for Current, "D" Denies or "H" for History)',
        SEXUAL_RELATIONSHIP_LOVE_BEHAVIOUR:'Sexual/relationship/love behaviors- ("C" for Current, "D" Denies or "H" for History)',
        ARRESTED_HISTORY:'Arrest history (including prior/current and pending)',
    },
    PHONE_NUMBERS: {
        USE_NOTIFICATION_TOOLTIP: 'Utiliser pour les notifications',
        REMOVE_NOTIFICATION_TOOLTIP: 'Supprimer pour notification',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        MANAGE_UNIT_PHONE: 'Gérer les numéros de téléphone, les crédits de SMS et les minutes d\'appel de l\'unité',
    },
}
export default label