import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import {
    isValidParam,
    getBooleanParam,
    getIntParam,
    getStringParam,
    getArrayParam
} from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { useDispatch, useSelector } from "react-redux";


const EmailIntegrationForm = ({object,id,type,isDetailView,data}) => {
    const dispatch = useDispatch();

    const tab = useSelector((state) => state.tab);
    const records = useSelector((state) => state.listView.data.records);

    const [uid, setUId] = useState(0);
    const [emailText, setemailText] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isShrinkedEmail, setIsShrinkedEmail] = useState(false);


    useEffect(() => {
        if (type === 'edit') {
            getFormData();
        }
    }, []);


    const getFormData = () => {
            let url = endPoints.EMAIL_INTEGRATION.EDIT;
            let recordId = getIntParam(id);
            let param = {};
            param.id = recordId;
            let tempEmailText;

            let promise = Promise.resolve(HTTPClient.get(url, param));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    tempEmailText = getStringParam(response.email_id);
                    setemailText(tempEmailText);
                    setUId(recordId);
                    setIsShrinkedEmail(true);
                }
            });
    }

    const handleChange = (event) => {
            // let emailText = getStringParam(value).trim();
            // emailText = emailText.split(" ").join("");
            let emailText = event.target.value;
            emailText =getStringParam(emailText).trim();
            setemailText(emailText.split(" ").join(""));
    }

    const onBlurShrink = () => {
        if(emailText!==''){
            setIsShrinkedEmail(true);

        }
        else{
            setIsShrinkedEmail(false);

        }
    }
    const processSave = () => {

        let email_id = getStringParam(emailText).trim();
        let isValidEmail = isValidEmailId(email_id);
            if (isValidEmail) {
                setIsSaving(true);
                let params = {};
                let tempId = getIntParam(uid);
                params.id = tempId;
                params.email_id = email_id
                let oldEmailId = '';
                if (type == 'edit') {
                    if (isValidParam(data.selectedRecords)) {
                        oldEmailId = getStringParam(data.selectedRecords[0].title)
                    } else {
                        let recordObj = getArrayParam(records).filter((obj) => { return obj.id == tempId })
                        oldEmailId = getStringParam(recordObj[0].t_email);
                    }
                    if (email_id !== '' && oldEmailId === email_id &&  tempId > 0) {
                        save(params);
                    } else {
                        checkDuplicateEmail(params);
                    }
                } else {
                    checkDuplicateEmail(params);
                }
            }
    }


    const checkDuplicateEmail = (params) => {
        let url = endPoints.EMAIL_INTEGRATION.CHECK_DUPLICATE_EMAIL;
        let isDuplicateEmail = false;
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    isDuplicateEmail = getBooleanParam(response.is_duplicate_email);
                    if (isDuplicateEmail) {
                        let errorMessage = getLocalizedStrings().message.EMAIL_INTEGRATION.DUPLICATE_EMAIL;
                        dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        setIsSaving(false);
                        return false;
                    } else {
                        save(params);
                    }
                }
            });
    }

    const isValidEmailId = (emailId) => {
            let errorMessage = getLocalizedStrings().message.EMAIL_INTEGRATION.INVALID_EMAIL;
            if (emailId === '') {
                errorMessage = getLocalizedStrings().message.EMAIL_INTEGRATION.EMAIL_CANT_BLANK;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                return false;
            } else {
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) {
                    return true;
                } else {
                    dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
            }
    }

    const save = (params) => {
            let url = endPoints.EMAIL_INTEGRATION.SAVE_EMAIL;
            let promise = null;
            let successMessage = getLocalizedStrings().message.EMAIL_INTEGRATION.EMAIL_SAVE_SUCCESS;
            promise = Promise.resolve(HTTPClient.post(url, params));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    closeDrawer();
                    dispatch(showCustomSnackBar(successMessage, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    refreshListView(object);
                }
            })
    }

    const getActionButtons = () => {
        let buttons = [];
        buttons.push(
            <Button
                key='save'
                onClick={() => processSave()}
                style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top', marginTop: "8px" }}
                disabled={isSaving}
            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
        );
        buttons.push(
            <Button
                key='cancel'
                onClick={() => closeDrawer()}
                style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px', marginTop: "8px" }}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
        );
        return buttons;
    }

    const closeDrawer = () => {
       dispatch(getAppDrawer(false, null, null, null, null));
    }



    return (
        <div style={{ paddingLeft: '25px', paddingRight: '25px', }}>
            <div className="row " >
                <div  >
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key={id + "_email"}
                            name={"emailText"}
                            label={getLocalizedStrings().label.EMAIL_INTEGRATION.EMAIL}
                            value={emailText}
                            defaultValue={emailText}
                            onChange={(event)=> handleChange(event)}
                            autoFocus={true}
                            inputprops={{ maxLength: '100' }}
                            autoComplete="new-password"
                            margin='dense'
                            size='small'
                            className={"sf-fields-bg"}
                            onFocus={() => setIsShrinkedEmail(true)}
                            onBlur={onBlurShrink}
                            InputLabelProps={{
                                shrink: isShrinkedEmail,
                            }}
                        />
                    </FormControl>
                </div>
            </div>
            {getActionButtons()}
        </div>
    );
};

export default EmailIntegrationForm;

