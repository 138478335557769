import React from 'react';
import { connect } from 'react-redux';
import DraggableSelect from '../../sfPlugins/draggableSelect.tsx';
import SFToolTip from '../components/sfTooltip';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { styles } from '../../../services/constants/styles';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getArrayParam, getStringParam, getBooleanParam, getIntParam, getObjectParam, getFloatParam } from '../../../services/helper/parameterVerifier';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import { generateLeadScoreIcon, dateFormatFlatPicker, dateTimeFormatFlatPicker } from '../../../services/helper/utils';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { Menu, MenuItem, InputLabel, Icon, IconButton, Select, Radio, Popper, RadioGroup, ListItemText, Checkbox } from '@mui/material';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { refreshDetailViewData } from '../../../services/actions/detailViewActions';
import { refreshDetailViewListViewData, getAccountContacts } from '../../../services/actions/detailViewActions';
import { detailViewIsMounted, isDetailViewSet } from '../../../services/actions/detailViewActions';
import moment from 'moment';
import { dateTimeFormat, dateFormat, sortArrayObjectByProperty } from '../../../services/helper/utils';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { LinkToType } from '../../../services/constants/link';
import { getCallDrawer } from '../../../services/actions/callAction';
import { FORM_FIELD_TYPE, isValidateFormFieldByField } from '../../../services/helper/formValidator';
import * as sfDialogs from '../components/sfDialogs';
import { getLookupRecordInfo } from '../../../services/actions/listViewAcions';
import { addTab, TYPE_DETAIL_VIEW, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { hasAccessPermission, getCampaignTypeNames, getObjectLabelByObject } from '../../../services/helper/common';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Flatpickr from 'react-flatpickr';
import SFLoader from '../components/sfLoader';
import DetailViewChildObjectFields from '../components/detailViewChildObjectFields';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import { getObjectSpecificEndpoint } from '../../../services/helper/utils';
import { checkDuplicateProductName } from '../../../services/actions/sfFormActions';
import { updateTabInfo } from '../../../services/helper/sfTabManager';

import $ from "jquery";
import copy from 'copy-to-clipboard';

const maxLimit = 50;
class DetailViewFields extends React.Component {
    constructor(props) {
        super(props);
        this.childSetup = React.createRef();
        this.state = {
            isOpenCustomizedFields: false,
            customizeFields: [],
            initialCustomizeFields: [],
            restofCustomizedFields: [],
            initialRestofCustomizeFields: [],
            isShowFieldLabel: false,
            initialIsShowFieldLabelVal: true,
            fields: [],
            fieldProps: new Object(),
            anchorEl: null,
            redirectUrl: '',
            redirect: false,
            openList: false,
            recentClickFieldId: null,
            avaliableContacts: [],
            primaryContactEmail: '',
            displayEditPrimaryContact: 'none',
            primaryContactFieldProps: new Object(),
            isFocusPrimaryContact: false,
            isChangePrimaryContact: false,
            isContactsMounted: false,
            isPopOverOpen: false,
            anchorEl2: null,
            selectedOption: null,
            recordSelectObject: null,
            pos: {},
            isShowDialog: false,
            open: false
        }
    }

    componentDidMount() {
        this.setFieldState(this.props);
    }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     this.setFieldState(nextProps);
    // }

    componentDidUpdate() {
        let fields = this.state.recentClickFieldId;
        if (fields != null) {
            if (fields.isDisplay == 'block') {
                if ((fields.fieldType == constants.FIELD_TYPE_TEXT
                    || fields.field_type == constants.FIELD_TYPE_INTEGER
                    || fields.field_type == constants.FIELD_TYPE_FLOAT
                    || fields.field_type == constants.FIELD_TYPE_URL
                    || fields.field_type == constants.FIELD_TYPE_MEMO

                )) {

                    let htmlComponet = document.getElementById('field_Id_' + fields.id);
                    if (htmlComponet != undefined && htmlComponet != null) {
                        htmlComponet.focus();

                    }
                } else if (fields.fieldType == constants.FIELD_TYPE_DATE_TIME || fields.fieldType == constants.FIELD_TYPE_DATE) {


                }
            }
        }
        if (this.state.isFocusPrimaryContact == true) {

            let htmlComponet = document.getElementById('field_Id_primary_firstName');
            if (htmlComponet != undefined && htmlComponet != null) {
                this.state.isFocusPrimaryContact = false

                htmlComponet.focus();

            }
        }
        this.state.redirect = false;
    }

    rearrangeSequence = (options) => {
        let _state = this.state;
        if (options != null && options != undefined)
            _state.customizeFields = options;
        this.setState(_state);
    }


    removeFromgenerateFieds = (fields) => {
        let fieldsWithoutHeader = [];
        try {
            fields.map((fieldObject, i) => {
                if (fieldObject.field_type != constants.FIELD_TYPE_HEADER && fieldObject.name != "" && fieldObject.label != "" && !getBooleanParam(fieldObject.is_hidden_field)) {
                    fieldsWithoutHeader.push(fieldObject);
                }
            });
            let wfFld = fields.filter((f) => { return f.name == 'workflow_name' && getBooleanParam(f.is_hidden_field) == true });
            if (wfFld.length > 0) {
                fieldsWithoutHeader.push(wfFld[0]);
            }
        } catch (error) {
            console.error('Error on DetailViewFields -> removeFromgenerateFieds() : ' + error);
        }

        return fieldsWithoutHeader;
    }

    setFieldState = (currentProps) => {
        let fieldsRecords = getObjectParam(JSON.parse(JSON.stringify(currentProps.fieldsRecords)));
        let stateProps = {};
        try {
            fieldsRecords = this.getFieldPropsForHub(fieldsRecords);
            let object = getStringParam(fieldsRecords.object);
            let fields = getArrayParam(fieldsRecords.fields);
            let detailsViewFields = fieldsRecords?.detailViewFieldsData?.length > 0 ? getArrayParam(fieldsRecords.detailViewFieldsData) : currentProps.sfForm.data.hasOwnProperty(object) ? getArrayParam(currentProps.sfForm.data[object].fields) : []

            let records = fieldsRecords.records;
            let isShowFieldLabel = getBooleanParam(fieldsRecords.isShowFieldLabel);
            if (object == constants.PETS_OBJECT) {
                detailsViewFields = detailsViewFields.filter(f => { return f.name != 't_status' })
            }
            if (object != '' && isValidParam(records) && fields.length > 0 && detailsViewFields.length > 0) {
                fields = this.removeFromgenerateFieds(fields);
                detailsViewFields = this.removeFromgenerateFieds(detailsViewFields);
                let fieldSetupInfo = this.getCustomizeFieldSetupInfo(object, fields, detailsViewFields, records);
                fields = this.getFields(fields, detailsViewFields);
                let fieldProps = this.getFieldProps(fields, records);
                let accountPrimaryContactDetails = this.getPrimaryContactDetails(currentProps);
                stateProps = {
                    ...fieldSetupInfo,
                    fields,
                    fieldProps,
                    isShowFieldLabel,
                    initialIsShowFieldLabelVal: isShowFieldLabel,
                    ...accountPrimaryContactDetails,
                };
                this.setState(stateProps);
            } else if (
                getArrayParam(detailsViewFields).length == 0 &&
                currentProps.hasOwnProperty('isChildView') &&
                getBooleanParam(currentProps.isChildView)
            ) {
                if (currentProps.sfForm.data.hasOwnProperty(object)) {
                    detailsViewFields = getArrayParam(currentProps.sfForm.data[object].fields);
                }
                if (detailsViewFields.length > 0) {
                    let accountPrimaryContactDetails = this.getPrimaryContactDetails(currentProps);
                    fields = this.removeFromgenerateFieds(fields);
                    detailsViewFields = this.removeFromgenerateFieds(detailsViewFields);
                    let fieldSetupInfo = this.getCustomizeFieldSetupInfo(object, fields, detailsViewFields, records);
                    fields = this.getFields(fields, detailsViewFields);
                    let fieldProps = this.getFieldProps(fields, records);
                    stateProps = {
                        ...fieldSetupInfo,
                        fields,
                        fieldProps,
                        isShowFieldLabel,
                        initialIsShowFieldLabelVal: isShowFieldLabel,
                        ...accountPrimaryContactDetails,
                    };
                    this.setState(stateProps);
                } else {
                    let data = new Object();
                    data.object = object;
                    data.records = records;
                    data.fields = fields;
                    data.isShowFieldLabel = isShowFieldLabel;
                    this.setChildDetailViewInfo(data, currentProps);
                }
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> setFieldState()': " + error);
        }
    }

    setChildDetailViewInfo = (data, currentProps) => {
        try {
            if (isValidParam(data) && Object.keys(data).length > 0 && this.props != currentProps && !currentProps.sfForm.data.hasOwnProperty(getStringParam(data.object))) {
                let stateProps = {};
                let object = getStringParam(data.object);
                let records = getObjectParam(data.records);
                let fields = getArrayParam(data.fields);
                let isShowFieldLabel = getBooleanParam(data.isShowFieldLabel);
                if (object != '' && Object.keys(records).length > 0 && fields.length > 0) {
                    let detailsViewFields = null;
                    let promise = getSFFormFieldsPromise(object);
                    promise.then((response) => {
                        currentProps.sfForm.data[object] = Object.assign({}, response);
                        detailsViewFields = getArrayParam(response.fields);
                        fields = this.removeFromgenerateFieds(fields);
                        detailsViewFields = this.removeFromgenerateFieds(detailsViewFields);
                        let fieldSetupInfo = this.getCustomizeFieldSetupInfo(object, fields, detailsViewFields, records);
                        fields = this.getFields(fields, detailsViewFields);
                        let fieldProps = this.getFieldProps(fields, records);
                        stateProps = { ...fieldSetupInfo, fields, fieldProps, isShowFieldLabel, initialIsShowFieldLabelVal: isShowFieldLabel }; //...accountPrimaryContactDetails
                        this.setState(stateProps);

                    });
                }
            }
        }
        catch (error) {
            console.error("Error in 'detailViewFields.js -> setChildDetailViewInfo()': " + error);
        }
    }

    getFieldPropsForHub = (fieldsRecords) => {
        try {
            fieldsRecords = getObjectParam(fieldsRecords);
            let object = getStringParam(fieldsRecords.object);
            if (object == constants.CASES_OBJECT && (this.props.app.selectedModuleName == constants.MODULE_HUB || this.props.app.selectedModuleName == constants.MODULE_OPERATIONS)) {

                let fields = getArrayParam(fieldsRecords.fields);
                let detailsViewFields = getArrayParam(fieldsRecords.detailViewFieldsData);

                fieldsRecords.fields = fields.filter(f => { return f.name != 'custom_field1' });
                fieldsRecords.detailsViewFields = detailsViewFields.filter(f => { return f.name != 'custom_field1' });
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getFieldsForHub()': " + error);
        }
        return fieldsRecords;
    }

    getCustomizeFieldSetupInfo = (object, fields, detailsViewFields, record) => {
        let info = null;
        try {
            object = getStringParam(object);
            fields = getArrayParam(fields);
            detailsViewFields = getArrayParam(detailsViewFields);
            if (object != '' && fields.length > 0 && detailsViewFields.length > 0 && isValidParam(record)) {
                info = new Object();
                let customizeFields = fields.map(m => {
                    return { value: m.name, label: m.label, title: m.label };
                });


                if (object == constants.ACCOUNTS_OBJECT) {
                    detailsViewFields = detailsViewFields.filter(f => { return f.name != 'first_name' && f.name != 'last_name' && f.name != 'workflow_id' && !record.hasOwnProperty(f.name) });
                } else if (object == constants.PETS_OBJECT || object == constants.ORDER_ITEMS_OBJECTS) {
                    detailsViewFields = detailsViewFields.filter(f => { return f.name != 't_status' && !record.hasOwnProperty(f.name) })
                } else {
                    detailsViewFields = detailsViewFields.filter(f => { return f.name != 'workflow_id' && !record.hasOwnProperty(f.name) })
                }

                let restofCustomizedFields = detailsViewFields.map(m => { return { value: m.name, label: m.label } });

                info.initialCustomizeFields = customizeFields;
                info.initialRestofCustomizeFields = restofCustomizedFields;
                info.customizeFields = customizeFields;
                info.restofCustomizedFields = restofCustomizedFields;
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getCustomizeFieldSetupInfo()': " + error);
        }
        return info;
    }

    getFields = (fields, detailsViewFields) => {
        let finalFields = [];
        try {
            fields = getArrayParam(fields);
            detailsViewFields = getArrayParam(detailsViewFields);
            if (fields.length > 0 && detailsViewFields.length > 0) {
                for (let i = 0; i < fields.length; i++) {
                    for (let j = 0; j < detailsViewFields.length; j++) {
                        if (detailsViewFields[j].name == fields[i].name) {
                            detailsViewFields[j].is_inline_edit = fields[i].is_inline_edit;
                            detailsViewFields[j].is_hidden = fields[i].is_hidden;
                            detailsViewFields[j].is_click_to_call = fields[i].is_click_to_call;
                            detailsViewFields[j].is_ring_central = fields[i].is_ring_central;
                            detailsViewFields[j].is_twilio = fields[i].is_twilio;
                            detailsViewFields[j].is_twilio_sms = fields[i].is_twilio_sms;
                            detailsViewFields[j].is_currency_field = fields[i].is_currency_field;
                            detailsViewFields[j].currency_field_value = fields[i].currency_field_value;
                            detailsViewFields[j].lookup_table_id = fields[i].lookup_table_id;
                            detailsViewFields[j].lookup_field_id = fields[i].lookup_field_id;

                            if (this.props.object == constants.CASES_OBJECT && this.props.app.selectedModuleName == constants.MODULE_HUB) {
                                detailsViewFields[j].is_inline_edit = false;
                            }
                            finalFields.push(detailsViewFields[j]);
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getFields()': " + error);
        }
        return finalFields;
    }

    getFieldProps = (fields, record) => {
        let fieldProps = {};
        try {
            let finalFields = getArrayParam(JSON.parse(JSON.stringify(fields)));
            if (finalFields.length > 0 && isValidParam(record)) {
                let tempfield = null;
                let defaultDate = null;
                finalFields.map((field, index) => {
                    tempfield = new Object();
                    tempfield = { ...tempfield, ...field }
                    tempfield.id = field.id;
                    tempfield.name = field.name;
                    tempfield.label = field.label;
                    tempfield.fieldType = field.field_type;
                    tempfield.listType = field.list_type;
                    tempfield.selectedListIds = [];
                    if (field.list_type == constants.LIST_FIELD_TYPE_MULTI_SELECT && isValidParam(record)) {
                        record[field.name] = getArrayParam(record[field.name].split(","));
                        if (record[field.name].length > 0) {
                            let dataArry = [];
                            record[field.name].map((fldValue, i) => {
                                let data = new Object();
                                data.id = fldValue;
                                data.value = fldValue;
                                dataArry.push(data);
                            });
                            tempfield.selectedListValues = dataArry;
                        } else {
                            tempfield.selectedListValues = [];
                        }
                    } else {
                        tempfield.selectedListValues = isValidParam(record) ? record[field.name] : [];
                    }
                    tempfield.isOpenPopover = false;
                    tempfield.popoverTarget = null;
                    tempfield.isDisplay = 'none';
                    tempfield.list_values = field.list_values;
                    tempfield.value = isValidParam(record) ? record[field.name] : field.default_value;
                    if (field.field_type == constants.FIELD_TYPE_LIST && field.is_required_field == false && field.list_type != constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                        field.list_values.unshift({ id: "", value: "" });
                    }
                    if (field.field_type == constants.FIELD_TYPE_LIST && field.list_type == constants.LIST_FIELD_TYPE_REGULAR) {
                        field.list_values.sort(function (a, b) { return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0); });
                    }
                    if (field.field_type == constants.FIELD_TYPE_LIST && field.list_type == constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                        let multiSelectList = [];
                        for (let x = 0; x < field.list_values.length; x++) {
                            if (field.list_values[x].value != "") {
                                multiSelectList.push(field.list_values[x]);
                            }

                        }
                        field.list_values = multiSelectList;
                        tempfield.selectedListIds = tempfield.value;
                        tempfield.selectedListValues = isValidParam(record) && record[field.name] != "" ? record[field.name] : [];

                    } if (field.field_type == constants.FIELD_TYPE_DATE_TIME) {

                        let defaultDate = isValidParam(record) ? record[field.name] : field.default_value;

                        if (defaultDate != "") {
                            let momentObj = moment(defaultDate);
                            defaultDate = momentObj.format(dateTimeFormat[this.props.app.me.date_format]);
                        }
                        tempfield.value = defaultDate
                    } else if (field.field_type == constants.FIELD_TYPE_DATE) {

                        defaultDate = isValidParam(record) ? record[field.name] : field.default_value;
                        if (defaultDate != "") {
                            let momentObj = moment(defaultDate);
                            defaultDate = momentObj.format(dateFormat[this.props.app.me.date_format]);
                        }
                        tempfield.value = defaultDate
                    } else {
                        if (field.field_type == constants.FIELD_TYPE_LIST && field.list_type == constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                            tempfield.value = isValidParam(record) && record[field.name] != "" ? record[field.name] : [];
                        } else {
                            tempfield.value = isValidParam(record) ? record[field.name] : '';
                        }
                    }
                    fieldProps[field.name] = tempfield;

                });
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getFieldProps()': " + error);
        }
        return fieldProps;
    }

    getPrimaryContactDetails = (currentProps) => {
        let primaryContactProps = {};
        try {
            let detailView = getObjectParam(currentProps.detailView.data);
            let object = getStringParam(currentProps.object);
            let detailViewObject = getStringParam(detailView.object).toLowerCase();
            let fields = getArrayParam(JSON.parse(JSON.stringify(currentProps.fieldsRecords.detailViewFieldsData)));
            if (object == constants.ACCOUNTS_OBJECT && object == detailViewObject) {
                let contactList = getArrayParam(detailView.avaliableContacts);
                contactList = contactList.filter(f => { return getStringParam(f.email) != '' });
                let full_name = getStringParam(detailView.full_name);
                let selectedContact = contactList.find(f => { return f.full_name == full_name });

                if (!isValidParam(selectedContact) || Object.keys(selectedContact).length == 0) {
                    selectedContact = new Object();
                    selectedContact.first_name = currentProps.detailView.data.first_name;
                    selectedContact.last_name = currentProps.detailView.data.last_name;
                    selectedContact.email = currentProps.detailView.data.email;
                    selectedContact.full_name = currentProps.detailView.data.full_name;
                    selectedContact.phone = currentProps.detailView.data.record.phone;
                    selectedContact.mobile = currentProps.detailView.data.record.mobile
                }


                let primaryContactFieldProps = new Object();
                fields = fields.filter(field => { return field.name == "first_name" || field.name == "last_name" || field.name == "email" || field.name == "phone" || field.name == "mobile" });
                fields.forEach(field => {
                    let tempfield = new Object();
                    tempfield.id = field.id;
                    tempfield.name = field.name;
                    tempfield.value = selectedContact[field.name] == undefined ? '' : selectedContact[field.name];
                    primaryContactFieldProps[field.name] = tempfield;
                });

                primaryContactProps.avaliableContacts = contactList;
                primaryContactProps.primaryContactFieldProps = primaryContactFieldProps;
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getPrimaryContactDetails()': " + error);
        }
        return primaryContactProps;
    }

    inlineUpdatePrimaryContact = (fieldArray, updateContact) => {
        let tempField = null;
        try {
            updateContact = getBooleanParam(updateContact);
            let contactList = getArrayParam(this.props.detailView.avaliableContacts);
            contactList = contactList.filter(f => { return getStringParam(f.email) != '' });
            let selectedContact = {};
            if (fieldArray != null) {
                let field = fieldArray['main_contact_id'];
                let selectedContactId = 0;
                if (field != null && field != '') {
                    selectedContactId = field.value;
                }
                for (let i = 0; i < contactList.length; i++) {
                    if (contactList[i].id == selectedContactId) {
                        selectedContact = contactList[i];
                        break;
                    }
                }
            }
            let object = this.props.fieldsRecords.object;
            let recordId = this.props.fieldsRecords.records.id;
            if (object != '' && recordId > 0) {
                let params = new Object();
                params.id = recordId;
                params.contact_id = selectedContact.id;
                params.fields = new Array();
                let fieldNameArray = Object.keys(fieldArray);
                let refreshChildListView = false;
                for (let i = 0; i < fieldNameArray.length; i++) {
                    let _field = fieldArray[fieldNameArray[i]];
                    if (_field.name == 'first_name' || _field.name == 'last_name' || _field.name == 'email' || _field.name == 'phone' || _field.name == 'hphone' || _field.name == 'mobile' || _field.name == 'job_title' || _field.name == 'email2') {
                        refreshChildListView = true;
                    }
                    if ((_field.name == 'first_name' || _field.name == 'last_name' || _field.name == 'email' || _field.name == 'phone' || _field.name == 'hphone' || _field.name == 'mobile' || _field.name == 'job_title' || _field.name == 'email2')) {
                        tempField = new Object();
                        tempField.id = _field.id;
                        tempField.name = _field.name;
                        tempField.value = _field.value;
                        if (updateContact) {
                            tempField.NoChangeContact = true;
                            params.is_switch_contact = true;
                        }
                        params.fields.push(tempField);
                        params.auditrail_action_type = constants.MAIN_CONTACT_UPDATE_AUDIT_TRAIL_RECORD;
                    }
                    if ((_field.name == 'first_name' || _field.name == 'last_name') && object == constants.ACCOUNTS_OBJECT) {
                        tempField = new Object();
                        tempField.id = _field.id;
                        tempField.name = _field.name;
                        tempField.value = _field.value;
                        if (updateContact) {
                            tempField.NoChangeContact = true;
                            params.is_switch_contact = true;
                        }
                        params.fields.push(tempField);
                        params.auditrail_action_type = constants.MAIN_CONTACT_UPDATE_AUDIT_TRAIL_RECORD;
                    }
                }
                let promise = SFFormActions.saveRecordPromise(object, params);
                promise.showCustomSnackBar = this.props.showCustomSnackBar;
                promise.then((response) => {
                    if (isValidParam(response) && response.hasOwnProperty('data') && response.data.hasOwnProperty('id')) {
                        let id = isValidParam(response.data.id) && isArray(response.data.id) && response.data.id.length > 0 ? response.data.id[0] : 0;
                        if (id > 0) {
                            if (updateContact != undefined && updateContact == true) {
                                this.props.refreshDetailViewData(true);
                            } else {
                                this.props.refreshDetailViewData(true);
                                this.props.detailView.data.full_name = fieldArray.first_name.value + ' ' + fieldArray.last_name.value;
                                if (this.props.listView.data.object.toLowerCase() == constants.CONTACTS_OBJECT) {
                                    this.props.refreshDetailViewListViewData(true);
                                }
                                if (refreshChildListView && this.props.childListView.data.object.toLowerCase() == constants.CONTACTS_OBJECT) {
                                    this.props.refreshDetailViewListViewData(true);
                                }
                            }
                            promise.showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                            this.props.detailViewIsMounted(false);
                            this.props.isDetailViewSet(false);
                            this.props.refreshDetailViewData(true);
                            this.props.refreshDetailViewListViewData(true);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> inlineUpdatePrimaryContact()':" + error);
        }
    }

    inlineUpdate = (field, fieldName) => {
        let tempField = null;

        try {
            let object = this.props.fieldsRecords.object;
            let recordId = this.props.fieldsRecords.records.id;

            let fieldId = field.id;
            let fieldName = field.name;
            let fieldValue = field.value;

            if (field.fieldType == constants.FIELD_TYPE_DATE_TIME && field.value != '') {

                if (typeof fieldValue == "string") {
                    fieldValue = moment(fieldValue);
                }
                let tempDateValue = fieldValue
                if (isValidParam(tempDateValue)) {
                    tempDateValue = tempDateValue.format("YYYY-MM-DD HH:mm:ss");
                    fieldValue = tempDateValue;
                }
            } else if (field.fieldType == constants.FIELD_TYPE_DATE && field.value != '') {

                if (typeof fieldValue == "string") {
                    fieldValue = moment(field.value);
                }
                let tempDateValue = fieldValue;
                if (isValidParam(tempDateValue)) {
                    tempDateValue = tempDateValue.format("YYYY-MM-DD");
                    fieldValue = tempDateValue;
                }
            }


            if (object != '' && recordId > 0 && fieldId > 0 && fieldName != '') {
                let params = new Object();
                params.id = recordId;
                params.fields = new Array();
                params.auditrail_action_type = constants.INLINE_UPDATE_AUDIT_TRAIL_RECORD;
                tempField = new Object();
                tempField.id = fieldId;
                tempField.name = fieldName;
                tempField.value = fieldValue;
                if (field.is_currency_field) {
                    tempField.is_currency_field = true;
                }
                params.fields.push(tempField);

                let promise = SFFormActions.saveRecordPromise(object, params);
                promise.then((response) => {
                    if (isValidParam(response) && response.hasOwnProperty('data') && response.data.hasOwnProperty('id')) {
                        let id = isValidParam(response.data.id) && isArray(response.data.id) && response.data.id.length > 0 ? response.data.id[0] : 0;
                        if (id > 0) {
                            if (field.is_currency_field) {
                                if (response.data.hasOwnProperty('currency_field_value')) {
                                    field.currency_field_value = response.data.currency_field_value;
                                    this.setState({});
                                }
                            }
                            this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                            this.props.detailView.data.record[fieldName] = fieldValue;
                            if ((object == 'accounts') && (fieldName == 'email' || fieldName == 'mobile' || fieldName == 'phone')) {
                                let primaryContactVal = this.state.primaryContactFieldProps;
                                primaryContactVal[fieldName].value = fieldValue;
                                this.setState({
                                    primaryContactFieldProps: primaryContactVal
                                });
                            }

                            if (this.props.object == constants.ACCOUNTS_OBJECT && (field.name == "first_name" || field.name == "last_name" || field.name == "email" || field.name == "phone" || field.name == "company" || field.name == "mobile") || field.fieldType == constants.FIELD_TYPE_DATE_TIME) {
                                this.props.refreshDetailViewData(true);
                                this.props.refreshDetailViewListViewData(true);
                            }
                            if (this.props.object == constants.OPPORTUNITIES_OBJECT || this.props.object == constants.PRODUCTS_OBJECT) {
                                this.props.refreshDetailViewData(true);
                                this.props.refreshDetailViewListViewData(true);
                            }
                            if (this.props.object == constants.CONTACTS_OBJECT && (fieldName == 'first_name' || fieldName == 'last_name' || fieldName == "email" || fieldName == "phone" || fieldName == "mobile")) {
                                this.props.refreshDetailViewData(true);
                                this.props.refreshDetailViewListViewData(true);
                            }
                            if (this.props.object == constants.PETS_OBJECT) {
                                this.props.detailViewIsMounted(false);
                                this.props.isDetailViewSet(false);
                                this.props.refreshDetailViewData(true);
                                this.props.refreshDetailViewListViewData(true);
                            }
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> inlineUpdate()':" + error);
        }
    }

    openTab = (label, object, type, imgName, url) => {
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: imgName, url: url, isActive: true };
        addTab(tab, true);
    }


    openRecord = (field, label, recordId) => {
        let isLookup = getBooleanParam(field.is_lookup_field);
        let linkUrl = '#';
        if (isLookup) {
            label = getStringParam(label);
            let params = new Object();
            params.parent_object = getStringParam(this.props.detailView.data.object);
            params.parent_record_id = recordId;
            params.lookup_table_id = getIntParam(field.lookup_table_id);
            params.lookup_field_id = getIntParam(field.lookup_field_id);
            params.field_value = label;
            let promise = Promise.resolve(getLookupRecordInfo(params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    let lookupInfo = response;
                    let lookupObject = getStringParam(lookupInfo.object).trim();
                    let lookupRecordId = getIntParam(lookupInfo.record_id);
                    if (lookupObject != '' && lookupRecordId > 0) {
                        linkUrl = getCustomLink({ object: lookupObject, field: field }, lookupRecordId);
                        this.setState({ redirect: true, redirectUrl: linkUrl });
                        this.openTab(label, lookupObject, lookupRecordId, null, linkUrl);
                    } else {
                        let msg = getLocalizedStrings().message.COMMON.COULDNOT_LOCATE + label + "." + getLocalizedStrings().message.COMMON.DELETED_OR_ACCESS_DENIED;
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                    }
                });
            }


        }
    }

    openTwilioPopOver = (event, fieldName) => {
        event.preventDefault();
        this.state.fieldProps[fieldName].isTwilioPopOverOpen = true;
        this.state.fieldProps[fieldName].twilioPopoverTarget = event.currentTarget;
        this.setState({ fieldProps: this.state.fieldProps });
    }

    closeTwilioPopOver = (fieldName) => {
        this.state.fieldProps[fieldName].isTwilioPopOverOpen = false;
        this.setState({ fieldProps: this.state.fieldProps });
    };

    getTwilioPopOver = (field) => {
        let popover = null;
        try {
            popover =
                <Menu
                    open={getBooleanParam(this.state.fieldProps[field.name].isTwilioPopOverOpen)}
                    onClose={this.closeTwilioPopOver.bind(this, field.name)} id='modules'
                    menuItemStyle={{ width: 168 }}
                    style={{ ...this.state.pos, width: 241 }}
                    anchorEl={this.state.fieldProps[field.name].twilioPopoverTarget}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                >
                    <MenuItem key='call' id='call' value='call'
                        style={{ ...styles.popoverMenuItem, paddingLeft: 3 }}
                        onClick={this.openTwilioDialog.bind(this, field, 'call')}
                    >
                        <span >
                            <Icon style={{ height: 14, width: 14, margin: 9, fontSize: 14 }}>call</Icon>
                        </span>
                        <span style={{ marginLeft: '4px' }}>{getLocalizedStrings().label.TWILIO.CALL}</span> </MenuItem>
                    <MenuItem key='sms' id='sms' value='sms'
                        style={{ ...styles.popoverMenuItem, paddingLeft: 3 }}
                        onClick={this.openTwilioDialog.bind(this, field, 'sms')}
                    >
                        <span >
                            <Icon style={{ height: 16, width: 16, margin: 9, fontSize: 16 }}>textsms</Icon>
                        </span>
                        <span style={{ marginLeft: '4px' }}>{getLocalizedStrings().label.TWILIO_SMS.SEND_SMS}</span>
                    </MenuItem>
                </Menu>
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getTwilioPopOver()':" + error);
        }
        return popover;
    }

    closePopOver = () => {
        this.setState({
            openPopOverList: false,
        });
    }

    openTwilioDialog = (field, value, event) => {
        let recordId = this.props.fieldsRecords.records.id;
        if (value == 'call') {
            if (field.is_ring_central) {
                openRingCentralWebWidget();
            } else {
                if (field.is_twilio) {
                    let value = getStringParam(this.state.fieldProps[field.name].value);
                    this.getCapabilityTokenForCall(this.props.object, recordId, true, field.name, value);
                }
            }
        } else if (value == 'sms') {
            this.getRecordDetailsForSMS(recordId, field.name);
        }
        this.state.fieldProps[field.name].isTwilioPopOverOpen = false;
        this.setState({});
    }


    getRecordDetailsForSMS = (recordId, fieldName) => {
        let params = null;
        var response = null;
        let endPoint = null;
        let recordIdArr = new Array();
        recordIdArr.push(recordId);
        params = {
            recordIds: recordIdArr,
            type: 'Texts',
            tableId: OBJECT_TABLEID_MAP[getStringParam(this.props.object)]
        }
        endPoint = endPoints.CONTACTS.GET_SMS_UNSUBSCRIBE_LIST;
        response = Promise.resolve(HTTPClient.get(endPoint, params));
        response.then((response) => {
            if (isValidParam(response) && getArrayParam(response).length == 1) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.RECORD_UNSUBSCRIBE_ALERT, null);
            } else {
                let params = { "id": recordId, parent_table_id: OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] };
                let endPoint = getObjectSpecificEndpoint(this.props.object, endPoints.RECORDS.RECORD_LINKED_MAP_DATA);
                if (this.props.object == constants.ACCOUNTS_OBJECT || this.props.object == constants.CONTACTS_OBJECT) {
                    endPoint = getObjectSpecificEndpoint(this.props.object, endPoints.RECORDS.MAP_DATA);
                    params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "country"] };
                }

                var promise = Promise.resolve(HTTPClient.get(endPoint, params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            let records = response.records;
                            this.processInfoAndOpenSMSDialog(records, recordId, fieldName);
                        }
                    });
                }
            }
        });
    }

    processInfoAndOpenSMSDialog = (records, recordId, fieldName) => {
        let firstName = "";
        let lastName = "";
        let fullName = "";
        let company = "";
        let title = "";
        let country = "";
        if (isValidParam(records)) {
            firstName = getStringParam(records.first_name);
            lastName = getStringParam(records.last_name);
            company = getStringParam(records.company);
            country = getStringParam(records.country);
        }
        country = this.props.app.me.tenant_country;
        fullName = (firstName + ' ' + lastName).trim();
        fullName = fullName == '' ? company : fullName;
        fullName = fullName == '' ? getStringParam(records.mobile) : fullName;
        title = fullName != '' ? 'To ' + fullName : title;
        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_SMS;
        let countryCode = "";
        let toNumber = getStringParam(this.state.fieldProps[fieldName].value).trim();
        if (country == "USA" || country == "Canada") {
            countryCode = "+1";
        } else if (country == "India") {
            countryCode = "+91";
        } else if (country == "United Kingdom") {
            countryCode = "+44";
        } else if (country == "Germany") {
            countryCode = "+49";
        } else if (country == "France") {
            countryCode = "+33";
        } else if (country == "Spain") {
            countryCode = "+34";
        } else if (country == "Netherlands") {
            countryCode = "+31";
        } else if (country == "Australia") {
            countryCode = "+61";
        }
        if (toNumber.indexOf("+") < 0) {
            toNumber = countryCode + toNumber;
        }
        let data = new Object();
        data.id = recordId;
        data.object = this.props.object;
        data.toNumber = toNumber;
        data.fieldName = fieldName;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = true;
        if (this.props.detailView.data.object == constants.OPPORTUNITIES_OBJECT) {
            let opportunity_info = new Object();
            opportunity_info.recordId = this.props.detailView.record.id;
            opportunity_info.tableId = 2;
            data.opportunity_info = opportunity_info;
        }
        let style = new Object();
        style.width = '40%';
        this.props.getAppCustomDrawer(true, label, type, style, data, null, false);
    }

    getEditIcon = (field) => {
        let edit_icon = <i
            className="fa fa-pencil"
            aria-hidden="true"
            title={getLocalizedStrings().label.COMMON.EDIT + ' ' + field.label}
            onClick={() => { this.handleShowEditTextField(this, field.name); }}
            style={{ float: 'right', paddingTop: '3px', width: '10px', cursor: 'pointer', paddingRight: '3px', fontSize: '14px', marginRight: '5px' }}
        >
        </i>;
        return edit_icon;
    }

    getCopyIcon = (field) => {
        let edit_icon = <i
            className="far fa-copy"
            aria-hidden="true"
            title={getLocalizedStrings().label.COMMON.COPY + ' ' + field.label}
            onClick={() => { this.handleShowCopyTextField(this, field); }}
            style={{ paddingTop: '3px', cursor: 'pointer', fontSize: '16px', float: 'right' }}
        >
        </i>;
        return edit_icon;
    }

    getFieldLabelElement = (field) => {
        let labelElement = null;
        try {
            let isShowFieldLabel = getBooleanParam(this.props.fieldsRecords.isShowFieldLabel);
            let isInlineEdit = getBooleanParam(field.is_inline_edit);
            let value = getStringParam(this.state.fieldProps[field.name].value);
            let editIcon = this.getEditIcon(field);
            let copyIcon = this.getCopyIcon(field);
            let labelOfStyle = isShowFieldLabel ? styles.detailViewFieldsRecordsWithLabel.fieldsLabel : styles.detailViewFieldsRecordsWithLabel.noFieldsLabel;
            let labelSpan = <span className="detailViewFieldDataLabel"> {field.label}  </span>;
            labelElement = <div style={labelOfStyle} title={field.label} className="detailViewFields_hover">
                {!isShowFieldLabel && value == '' && getBooleanParam(field.is_inline_edit) && editIcon}
                {!isShowFieldLabel && value == '' && labelSpan}
                {isShowFieldLabel && isInlineEdit && editIcon}
                {field.is_email_field && <div style={{ width: '10px', float: 'right', paddingRight: '5px' }}>{copyIcon}</div>}
                {isShowFieldLabel && labelSpan}
            </div>;
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getFieldLabelElement()':" + error);
        }
        return labelElement;
    }

    getIconElement = (field) => {
        let props = new Object();
        let className = null;
        let iconElement = null;
        try {
            let value = getStringParam(this.state.fieldProps[field.name].value);
            if (value != '' && (field.name == 'phone' || field.name == 'hphone' || field.name == 'mobile' || field.name.indexOf('custom_field') > -1)) {
                props.style = { float: 'left', paddingTop: '3px', cursor: 'pointer', paddingRight: '3px' };
                props['aria-hidden'] = true;
                if (field.name == 'phone' || field.name == 'hphone' || (field.name.indexOf('custom_field') > -1 && field.is_click_to_call)) {
                    className = 'fa fa-phone';
                } else if (field.name == 'mobile') {
                    className = 'fa fa-mobile';
                }

                if (className != null) {
                    props.className = className;
                    iconElement = <i {...props} ></i>;
                }
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getIconElement()':" + error);
        }
        return iconElement;
    }

    customEvent = (field, event) => {
        try {
            if (field.name == 'sales_rep' || field.name == 'email' ||
                field.name == 'email2' || field.is_email_field || field.field_type == FORM_FIELD_TYPE.URL ||
                field.name == 'phone' || field.name == 'hphone' || field.name.indexOf('custom_field') > -1) {
                this.handleDetailsLinkClicked(event, field.name);
            } else if (field.name == 'mobile') {
                if (field.is_twilio_sms && (field.is_twilio || field.is_ring_central)) {
                    let phoneTextProvider = this.props.app.me.phone_text_provider ?? constants.NONE;
                    if (phoneTextProvider !== constants.NONE) {
                        this.openTwilioPopOver(event, field.name);
                    } else {
                        sfDialogs.alert(
                            getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                            getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.NO_PROVIDER,
                            null,
                        );
                    }
                } else {
                    this.handleDetailsLinkClicked(this, field.name);
                }
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> customEvent()':" + error);
        }
    }

    getValueElement = (field) => {
        let element = null;
        let isUnderLine = false;
        try {
            let value = this.state.fieldProps[field.name].value;
            let isCurrencyField = getBooleanParam(this.state.fieldProps[field.name].is_currency_field);
            if (isCurrencyField) {
                value = this.state.fieldProps[field.name].currency_field_value;
            } else if (field.name == 'campaign_type') {
                value = getCampaignTypeNames(value);
            }
            if (field.name == 'sales_rep' || field.name == 'email' || field.name == 'email2' || field.is_email_field || field.field_type == FORM_FIELD_TYPE.URL
                || field.is_click_to_call || field.is_lookup_field) {
                isUnderLine = true;
                if (field.is_lookup_field && this.props.object == constants.OPPORTUNITIES_OBJECT && field.name == 'product') {
                    isUnderLine = false;
                }
                if (field.is_click_to_call && (field.name == 'phone' || field.name == 'hphone' || field.name.indexOf('custom_field') > -1) && !field.is_twilio) {
                    isUnderLine = false;
                }
                if (field.is_click_to_call && (field.name == 'phone' || field.name == 'hphone' || field.name.indexOf('custom_field') > -1) && field.is_ring_central) {
                    isUnderLine = true;
                }
            }

            let props = new Object();
            if (isUnderLine) {
                if (field.is_lookup_field) {
                    props.onClick = () => this.openRecord(field, value, this.props.detailView.record.id);
                } else {
                    props.onClick = this.customEvent.bind(this, field);
                }
                props.style = { cursor: 'pointer', display: 'inline', color: 'rgb(3, 155, 229)' };
                if (!this.props.detailView.mounted) {
                    element = <u style={{ cursor: 'pointer', display: 'inline', color: 'rgb(3, 155, 229)' }}>{value}</u>
                } else {
                    element = <u {...props}>{value}</u>
                }
            } else {
                element = value;
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getValueElement()':" + error);
        }
        return element;
    }

    getFieldValueContainer = (field) => {
        let valueElement = null;
        let value = null;
        try {
            value = this.state.fieldProps[field.name].value;
            if (field.name == 'campaign_type') {
                value = getCampaignTypeNames(value);
            }
            let isShowFieldLabel = getBooleanParam(this.props.fieldsRecords.isShowFieldLabel);
            let isDisplay = this.state.fieldProps[field.name].isDisplay;
            let component = this.generateTypeWiseTextFields(field);
            let valueElementProps = new Object();
            valueElementProps.style = styles.detailViewFieldsRecordsWithLabel.fieldsRecord;
            valueElementProps.title = field.label + "\n" + value;
            let hoverContainerProps = new Object();
            hoverContainerProps.className = "detailViewFields_hover";
            hoverContainerProps.style = { display: isDisplay == "none" ? "block" : "none" };
            hoverContainerProps.id = field.name + '_div';
            let dataLabelProps = new Object();
            dataLabelProps.className = "detailViewFieldDataLabel1";
            dataLabelProps.style = new Object();
            if (field.field_type == constants.FIELD_TYPE_MEMO) {
                dataLabelProps.style = { ...dataLabelProps.style, whiteSpace: 'pre' };
            }

            let isInlineEdit = getBooleanParam(field.is_inline_edit);
            let isPopover = ((field.name == 'mobile') && field.is_click_to_call && field.is_twilio_sms && (field.is_twilio || field.is_ring_central)) ? true : false;

            valueElement = <div {...valueElementProps}>
                <div {...hoverContainerProps}>
                    {this.getIconElement(field)}
                    {isInlineEdit && !isShowFieldLabel && this.getEditIcon(field)}
                    {!isShowFieldLabel && field.is_email_field && <div style={{ width: '10px', float: 'right', paddingRight: '5px' }}>{this.getCopyIcon(field)}</div>}
                    <span {...dataLabelProps}>
                        {this.getValueElement(field)}
                        {isPopover && this.getTwilioPopOver(field)}
                    </span>
                </div>
                {isInlineEdit && <div id={field.name + '_edit'} style={{ display: isDisplay }}> {component}</div>}
            </div>;
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getFieldValueContainer()':" + error);
        }
        return valueElement;
    }

    generateFieldsData = (object, isChildView) => {
        let fieldsData = null;
        try {
            let showData = true;
            isChildView = getBooleanParam(isChildView);
            if (isChildView) {
                let recordArr = null;
                let child_setup_details = isValidParam(this.props.detailView.child_setup_details) ? this.props.detailView.child_setup_details[object] : {};
                if (object == constants.ACCOUNTS_OBJECT) {
                    recordArr = getArrayParam(this.props.detailView.avaliableAccounts);
                } else if (object == constants.CONTACTS_OBJECT) {
                    recordArr = getArrayParam(this.props.detailView.avaliableContacts);
                }
                if (recordArr != null && recordArr.length > 0) {
                    showData = true;
                } else {
                    showData = false;
                }
                if (child_setup_details.hasOwnProperty('record') && isValidParam(child_setup_details.record)) {
                    let childRecord = child_setup_details.record;
                    if (childRecord.hasOwnProperty('id')) {
                        showData = true;
                    } else {
                        showData = false;
                    }
                } else {
                    showData = false;
                }
            }
            let objectLabel = getObjectLabelByObject(object);
            objectLabel = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(objectLabel) ? getLocalizedStrings().label.NO_RECORD[objectLabel] : objectLabel;
            let label = getLocalizedStrings().label.COMMON.NO + ' ' + objectLabel.toLowerCase() + ' ' + getLocalizedStrings().label.COMMON.FOUND + getLocalizedStrings().label.COMMON.DOT;
            let fields = getArrayParam(this.state.fields);
            if (this.props.object === constants.TASKS) {
                fields = fields.filter(f => f.name !== "t_unit_owner");
            }
            if (isValidParam(fields) && showData) {
                fieldsData = fields.map((field, i) => {
                    return <div className="detailViewFieldData">
                        {this.getFieldLabelElement(field)}
                        {this.getFieldValueContainer(field)}
                    </div>;
                });
            } else {
                fieldsData = <div style={{ paddingTop: '5px', color: '#B5B5B5', fontSize: '13px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} title={label}> {label} </div>
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> generateFieldsData()':" + error);
        }
        return fieldsData;
    }

    openCustomizedFields = (event) => {
        let tabInfo = getActiveTabInfo();
        let isUnitsAccount = false;
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        if (!isInXpress && !isUnitsAccount) {
            event.preventDefault();
            this.setState({
                isOpenCustomizedFields: true,
                anchorEl: event.currentTarget

            });
        } else {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
        }
    }

    closeCustomizedFields = () => {
        this.setState({
            isOpenCustomizedFields: false,
            restofCustomizeFields: this.state.initialRestofCustomizeFields,
            customizeFields: this.state.initialCustomizeFields,
            isShowFieldLabel: this.state.initialIsShowFieldLabelVal
        });
        this.props.refreshDetailViewData(true);
    }

    generateChildSetupDetails = (childSetup) => {
        let setupList = new Array();
        try {
            if (isValidParam(childSetup)) {
                let selectedList = childSetup.state.selected_child_fields;
                Object.keys(selectedList).map((key) => {
                    let fieldObj = new Object();
                    fieldObj.object = key;
                    fieldObj.fields = getArrayParam(selectedList[key]);
                    setupList.push(fieldObj);
                });
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> generateChildSetupDetails()':" + error);
        }
        return setupList;
    }

    saveCustomizedFields = () => {
        let object = this.props.fieldsRecords.object;
        let customizeFields = this.state.customizeFields;
        let fieldName = [];
        let ref = null;
        let childSetupInfo = null;
        customizeFields.forEach((item) => {
            if (item.value != "") {
                fieldName.push(item.value);
            }
        });

        if (fieldName.length == 0) {
            this.props.showCustomSnackBar(getLocalizedStrings().message.DETAIL_VIEW.SELECT_ATLEAST_ONE_FIELD, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            return;
        }
        if (this.props.object == constants.OPPORTUNITIES_OBJECT) {
            ref = this.childSetup.current;
            childSetupInfo = this.generateChildSetupDetails(ref);
            if (isValidParam(childSetupInfo) && getArrayParam(childSetupInfo).length > 0) {
                let isValid = true;
                let errmsg = '';
                for (let i = 0; i < childSetupInfo.length; i++) {
                    let child = getObjectParam(childSetupInfo[i]);
                    if (Object.keys(child).length == 0) {
                        isValid = false;
                        errmsg = getLocalizedStrings().message.DETAIL_VIEW.CONFIGURE_CHILD_SETUP;
                        break;
                    }
                    else if ((child.hasOwnProperty("object") && child.object != "-99") && (!child.hasOwnProperty("fields") || (child.hasOwnProperty("fields") && getArrayParam(child.fields).length == 0))) {
                        isValid = false;
                        errmsg = getLocalizedStrings().message.DETAIL_VIEW.CHILD_SETUP_SELECT_ATLEAST_ONE_FIELD;
                        break;
                    }
                }
                if (!isValid) {
                    this.props.showCustomSnackBar(errmsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                    return;
                } else {
                    // childSetupInfo.forEach((child) => {
                    //     this.rearrangeChildSetupSequence(child);
                    // })
                }
            }
        }

        this.setState({
            isOpenCustomizedFields: false
        });
        let params = new Object();
        params["is_show_field_label"] = getBooleanParam(this.state.isShowFieldLabel);
        params["field_names"] = fieldName;
        if (this.props.object == constants.OPPORTUNITIES_OBJECT) {
            params["child_fields"] = childSetupInfo;
        }
        let promise = SFFormActions.saveCustomizeFieldsSetupPromise(object, params);
        promise.then((response) => {
            if (this.props.object == constants.ACCOUNTS_OBJECT) {
                let _info = getActiveTabInfo();
                if (_info.selectedPageNo > 1) {
                    _info.selectedPageNo = 1;
                    updateTabInfo(_info);
                }
            }

            this.props.detailViewIsMounted(false);
            this.props.isDetailViewSet(false);
            this.props.refreshDetailViewData(true);
            this.props.refreshDetailViewListViewData(true);
            this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);

        });
    }



    handlePopover = (event, fieldName) => {
        if (event != undefined && fieldName != undefined) {
            let _state = this.state;
            _state.fieldProps[fieldName].popoverTarget = event.currentTarget;
            this.setState(_state);
        }
    };

    handleShowEditTextField = (gg, fieldName) => {
        let tabInfo = getActiveTabInfo();
        let isUnitsAccount = false;
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        let detailViewRecord = this.props.detailView.record;
        let objectName = getStringParam(this.props.object);

        let hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
        if ((hasPermission && !isInXpress && !isUnitsAccount) || (hasPermission && isUnitsAccount && tabInfo.callFrom === "mini_edit")) {
            if (this.props.object == constants.TASKS && detailViewRecord != null && detailViewRecord.t_status != null && detailViewRecord.t_status == "Completed") {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "you cannot edit a completed task.", null);
            } else {
                let isDisplay = (this.state.fieldProps[fieldName].isDisplay == 'none' ? 'block' : 'none');
                Object.keys(this.state.fieldProps).map((localFieldName) => {
                    if (localFieldName != fieldName) {
                        this.state.fieldProps[localFieldName].isDisplay = 'none';
                    }
                });
                let fields = this.state.fieldProps[fieldName];
                if (isDisplay == 'none') {
                    if (fields.text_before_edit != fields.value) {
                        let isValidForm = isValidateFormFieldByField(this.props.object, fields);
                        if (!isValidForm) {
                            this.state.fieldProps[fieldName].helperText = fields.helperText;
                            this.setState({ fieldProps: this.state.fieldProps });
                            this.props.showCustomSnackBar(fields.helperText, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                            return;
                        }
                        this.inlineUpdate(fields, fieldName);
                    }
                } else {
                    fields.text_before_edit = fields.value;
                }
                let _state = this.state;
                _state.fieldProps[fieldName].isDisplay = (this.state.fieldProps[fieldName].isDisplay == 'none' ? 'block' : 'none');
                this.setState(_state);
                if (fields.fieldType == 'list' && fields.isDisplay == 'block' && fields.list_type == 'external') {
                    this.getListValues(fields, fieldName);
                } else {
                    this.setState({ fieldProps: this.state.fieldProps, recentClickFieldId: fields });
                }
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
        }
    }

    handleShowCopyTextField = (gg, field) => {
        let email = this.state.fieldProps[field.name].value;
        if (!email) {
            this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.EMPTY_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
        } else {
            copy(email);
            this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.COPY_EMAIL, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
        }
    }

    onConfirm = (fields, fieldName) => {
        let _state = this.state;
        _state.isShowDialog = false;
        _state.fieldProps[fieldName].isDisplay = (_state.fieldProps[fieldName].isDisplay == 'none' ? 'block' : 'none');
        if (_state.fieldProps[fieldName].isDisplay == 'none') {
            _state.fieldProps[fieldName].value = fields.text_before_edit;
        }
        this.setState(_state);
        return false;
    }

    onCancel = (fields, fieldName) => {
        let _state = this.state;
        _state.isShowDialog = false;
        this.setState(_state);
        return false;
    }

    handleShowEditTextFieldBlur = (gg, fieldName) => {
        if (!this.state.isShowDialog) {
            let fields = this.state.fieldProps[fieldName];
            let isValidForm = isValidateFormFieldByField(this.props.object, fields);
            if (!isValidForm) {
                let _state = this.state;
                _state.fieldProps[fieldName].helperText = fields.helperText;
                _state.fieldProps = this.state.fieldProps;
                _state.isShowDialog = true;
                this.setState(_state);
                let msg = '';
                if (fields.is_email_field) {
                    msg = fields.label + " " + getLocalizedStrings().message.DETAIL_VIEW.EMAIL_FORMAT_MSG + " " + getLocalizedStrings().message.DETAIL_VIEW.ERROR_MSG;
                } else if (fields.is_url_field || fields.field_type == "url") {
                    msg = fields.label + " " + getLocalizedStrings().message.DETAIL_VIEW.URL_FORMAT_MSG + " " + getLocalizedStrings().message.DETAIL_VIEW.ERROR_MSG;
                } else if (fields.field_type == "float") {
                    msg = fields.label + " " + getLocalizedStrings().message.DETAIL_VIEW.FLOAT_FORMAT_MSG + " " + getLocalizedStrings().message.DETAIL_VIEW.ERROR_MSG;
                } else if (fields.field_type == "integer") {
                    msg = fields.label + " " + getLocalizedStrings().message.DETAIL_VIEW.INTEGER_FORMAT_MSG + " " + getLocalizedStrings().message.DETAIL_VIEW.ERROR_MSG;
                } else {
                    msg = fields.helperText + " " + getLocalizedStrings().message.DETAIL_VIEW.ERROR_MSG;
                }
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => this.onConfirm(fields, fieldName), () => this.onCancel(fields, fieldName));
                return;
            } else {
                if (this.props.object == constants.PRODUCTS_OBJECT && getBooleanParam(isValidForm) && fieldName == "product_name") {
                    let recordId = getIntParam(this.props.fieldsRecords.records.id);
                    this.checkDuplicateProduct(fields, recordId);
                } else if (this.props.object == constants.PRODUCTS_OBJECT && fieldName == 'price') {
                    this.checkProductTypeAndUpdatePrice(fields, fieldName);
                } else {
                    this.state.fieldProps[fieldName].isDisplay = (this.state.fieldProps[fieldName].isDisplay == 'none' ? 'block' : 'none');
                    if (this.state.fieldProps[fieldName].isDisplay == 'none') {
                        this.state.fieldProps[fieldName].value = this.state.fieldProps[fieldName].field_type === "text" ? this.state.fieldProps[fieldName].value.trim() : this.state.fieldProps[fieldName].value;
                        if (fields.text_before_edit != fields.value) {
                            if (this.state.fieldProps[fields.name].is_currency_field == true) {
                                this.state.fieldProps[fields.name].currency_field_value = this.state.fieldProps[fields.name].value;
                            }
                            this.inlineUpdate(fields, fieldName);
                        }
                    }
                    if (fields.fieldType == 'list' && fields.isDisplay == 'block' && fields.list_type == 'external') {

                        this.getListValues(fields, fieldName);
                    } else {
                        this.setState({ fieldProps: this.state.fieldProps, recentClickFieldId: fields });

                    }
                }
            }
        }
    }

    checkProductTypeAndUpdatePrice = (fields, fieldName) => {
        let fieldValue = isValidParam(this.state.fieldProps[fieldName]) ? getStringParam(this.state.fieldProps[fieldName].value).trim() : '';
        if (isValidParam(fieldValue) && fieldValue != "" && getFloatParam(fieldValue) < 0) {
            let productType = "";
            if (this.props.fieldsRecords.records.hasOwnProperty('product_type')) {
                productType = this.props.fieldsRecords.records.product_type;
                if (productType == 'Inventory') {
                    this.props.showCustomSnackBar(getLocalizedStrings().message.PRODUCTS.NEGATIVE_PRICE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                } else {
                    this.inlineUpdate(fields, fieldName);
                }
            } else {
                let param = { record_id: this.props.fieldsRecords.records.id }
                let promise = SFFormActions.getProductType(param);
                promise.then((response) => {
                    productType = response.productType;
                    if (productType == 'Inventory') {
                        this.props.showCustomSnackBar(getLocalizedStrings().message.PRODUCTS.NEGATIVE_PRICE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                    } else {
                        this.inlineUpdate(fields, fieldName);
                    }
                });
            }
        } else {
            this.inlineUpdate(fields, fieldName);
        }
    }

    handleInlineEditMemoFieldBlurDisplaySave = (event, fieldName) => {
        this.state.fieldProps[fieldName].isDisplay = (this.state.fieldProps[fieldName].isDisplay == 'none' ? 'block' : 'none');
        let field = this.state.fieldProps[fieldName];
        if (field.text_before_edit != field.value) {
            this.inlineUpdate(field, fieldName);
        }
        this.setState({ fieldProps: this.state.fieldProps, recentClickFieldId: field });
    }

    getListValues = (field, fieldName) => {
        let params = new Object();
        params.object = this.props.object;
        params.id = this.props.fieldsRecords.records.id;
        params.name = field.name;
        params.list_type = field.listType;
        let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.LIST_VALUES, params));
        promise.then((response) => {
            if (isValidParam(response)) {
                if (response.length != 0) {
                    this.state.fieldProps[fieldName].list_values = response;
                    if (field.field_type == constants.FIELD_TYPE_LIST && field.is_required_field == false) {
                        this.state.fieldProps[fieldName].list_values.unshift({ id: "", value: "" });
                    }
                } else {
                    if (fieldName == "state") {
                        this.state.fieldProps[fieldName].list_values = [];
                    }
                }
                this.setState({ fieldProps: this.state.fieldProps, recentClickFieldId: field });
            }
        });

    }

    handleRequestClose = (event, fieldName) => {
        let _state = this.state;
        _state.fieldProps[fieldName].popoverTarget = null;
        this.setState(_state);
    };

    setFieldValue = (fieldName, event, value) => {
        let target = event.target;
        value = target.value;
        let field = this.state.fieldProps[fieldName];
        this.state.fieldProps[fieldName].value = value;
        if (getBooleanParam(this.state.fieldProps[fieldName].isAddToThisList)) {

        } else {
            if (field.fieldType == constants.FIELD_TYPE_LIST) {

                this.state.fieldProps[fieldName].isDisplay = 'none';
            }
            this.state.fieldProps[fieldName].helperText = '';
            this.state.fieldProps[fieldName].isOpenPopover = false;
        }

        this.setState({ fieldProps: this.state.fieldProps });
    }

    setSelectFieldValue = (fieldName, value, event) => {

        let field = this.state.fieldProps[fieldName];
        let _state = this.state;
        _state.fieldProps[fieldName].helperText = '';
        _state.fieldProps[fieldName].isOpenPopover = false;

        if (value == '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----' ||
            value == '---- ' + getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE + ' ----' ||
            value == 'addto_' + fieldName) {
            _state.fieldProps[fieldName].isAddToThisList = true;
            _state.fieldProps[fieldName].value = '';
            this.setState(_state);
        } else {
            if (field.fieldType == constants.FIELD_TYPE_LIST) {
                _state.fieldProps[fieldName].isDisplay = 'none';
            }
            _state.fieldProps[fieldName].value = value;
            this.setState(_state);
            this.inlineUpdate(field, fieldName);
        }
    }

    handleDateChange = (fieldName, date) => {
        let isValidDate = true;
        if (isValidParam(date)) {
            let dateVal = new Date(date);
            let selectedDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate());
            const momentDate = moment(selectedDate);
            if (this.state.fieldProps[fieldName].field_type == constants.FIELD_TYPE_DATE_TIME) {
                this.state.fieldProps[fieldName].value = momentDate.format(dateTimeFormat[this.props.app.me.date_format]);
            } else {
                this.state.fieldProps[fieldName].value = momentDate.format(dateFormat[this.props.app.me.date_format]);
            }
        }
        else {
            if (this.state.fieldProps[fieldName].is_required_field) {
                this.state.fieldProps[fieldName].value = this.state.fieldProps[fieldName].text_before_edit;
                isValidDate = false;
            } else {
                this.state.fieldProps[fieldName].value = '';
                isValidDate = true;
            }
        }

        this.state.fieldProps[fieldName].helperText = '';
        if (this.props.object == constants.APPOINTMENTS && fieldName == 'start_date') {
            this.state.fieldProps['end_date'].value = moment.add(15, 'm').format(dateTimeFormat[this.props.app.me.date_format]);
        }
        this.state.fieldProps[fieldName].isDisplay = 'none';
        this.setState({ fieldProps: this.state.fieldProps });
        if (this.state.fieldProps[fieldName].field_type == constants.FIELD_TYPE_DATE && isValidDate) {
            this.inlineUpdate(this.state.fieldProps[fieldName], fieldName);
        } else {
            this.props.showCustomSnackBar(getLocalizedStrings().message.DETAIL_VIEW.BLANK_SPECIFIC_DATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
        }
    }

    handleDateFocus = (fieldName, event) => {
        let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + fieldName);
        if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
            dateTimeDiv[0].classList.add('label-top');
        }
    }

    handleDateBlur = (fieldName, event) => {
        this.state.fieldProps[fieldName].isDisplay = 'none';
        this.setState({ fieldProps: this.state.fieldProps });
    }

    handleDateTimeChange = (fieldName, date, e, event) => {
        let selectedDate = null;
        if (getArrayParam(date).length > 0) {
            let dateVal = new Date(date);
            selectedDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(), dateVal.getHours(), dateVal.getMinutes());
            const momentDate = moment(selectedDate);
            if (this.state.fieldProps[fieldName].field_type == constants.FIELD_TYPE_DATE_TIME) {
                this.state.fieldProps[fieldName].value = momentDate.format(dateTimeFormat[this.props.app.me.date_format]);
            } else {
                this.state.fieldProps[fieldName].value = momentDate.format(dateFormat[this.props.app.me.date_format]);
            }
        } else {
            if (this.state.fieldProps[fieldName].is_required_field) {
                this.state.fieldProps[fieldName].isDisplay = 'none';
                this.setState({ fieldProps: this.state.fieldProps });
                this.props.showCustomSnackBar(getLocalizedStrings().message.DETAIL_VIEW.BLANK_SPECIFIC_DATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                event.setDate(this.state.fieldProps[fieldName].text_before_edit);
                event.close();
            } else {
                this.state.fieldProps[fieldName].value = '';
                this.state.fieldProps[fieldName].isDisplay = 'none';
                this.setState({ fieldProps: this.state.fieldProps });
                event.close();

            }
        }
    }

    handleDateTimeBlur = (fieldName, event) => {
        this.state.fieldProps[fieldName].isDisplay = 'none';
        let value = '';
        if (getArrayParam(event).length > 0) {
            let dateVal = new Date(event);
            let selectedDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(), dateVal.getHours(), dateVal.getMinutes());
            const momentDate = moment(selectedDate);
            if (this.state.fieldProps[fieldName].fieldType === "date") {
                value = momentDate.format(dateFormat[this.props.app.me.date_format]);
            } else {
                value = momentDate.format(dateTimeFormat[this.props.app.me.date_format]);
            }
            if (this.state.fieldProps[fieldName].value != value) {
                let _state = this.state;
                _state.fieldProps[fieldName].value = value
                this.setState(_state);
            } else {
                this.setState({ fieldProps: this.state.fieldProps });
            }
        }
        if (this.state.fieldProps[fieldName].value != null && this.state.fieldProps[fieldName].text_before_edit != this.state.fieldProps[fieldName].value) {
            this.inlineUpdate(this.state.fieldProps[fieldName], fieldName);
        }
    }

    setMultiSelectValue = (fieldName, event) => {
        let field = this.state.fieldProps[fieldName];
        let values = event.target.value;
        let arrayStr = "";
        values.map((value, index) => {
            if (arrayStr === "") {
                arrayStr = value;
            } else {
                arrayStr += "," + value;
            }

        });
        let _state = this.state;
        _state.fieldProps[fieldName].selectedListValues = values;
        _state.fieldProps[fieldName].helperText = '';
        this.setState(_state);
        field.value = arrayStr;
        this.inlineUpdate(field, fieldName);
    }

    getMenuItems = (field, listValues) => {
        let menuItems = new Array();
        let hasPermission = false;
        let userListVal = getArrayParam(this.props.userlist?.data);
        if(field?.name == "t_owner")
        if (userListVal.length > 0 ) {
            listValues = [];
            userListVal.map((m) => {
                listValues.push({ id: m.value, value: m.value })
            })
        }
        try {
            if (isValidParam(field)) {
                let fieldName = getStringParam(field.name);
                listValues = getArrayParam(listValues);
                listValues.map((listValue, listValueIndex) => {
                    menuItems.push(
                        <MenuItem key={listValue.id} value={listValue.value} id={listValue.id}
                            style={styles.popoverMenuItem} onClick={this.setSelectFieldValue.bind(this, fieldName, listValue.value)}>
                            {<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '100px' }} title={listValue.value}>{listValue.value}</div>}
                        </MenuItem>);
                });
                hasPermission = hasAccessPermission("", constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_ADD_TO_LIST);
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getMenuItems()':" + error);
        }
        return menuItems;
    }

    addToThisList = (fieldName, event) => {
        try {
            let value = this.state.fieldProps[fieldName].value;
            value = getStringParam(value).trim();
            if (value != '') {
                if (fieldName == 'source') {
                    let promise = SFFormActions.checkDuplicateSourceName(value);
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            this.saveNewListValue(fieldName, value, getBooleanParam(response));
                        });
                    }
                } else {
                    let field = this.state.fields.filter(f => { return f.name == fieldName });
                    if (field.length > 0) {
                        let listValues = getArrayParam(field[0].list_values);
                        let tempValues = listValues.filter(f => { return f.value.toLowerCase() == value.toLowerCase() });
                        this.saveNewListValue(fieldName, value, tempValues.length > 0 ? true : false);
                    }
                }
            } else {
                let tempFields = this.state.fields.filter(f => { return f.name == fieldName });
                let fieldValue = isValidParam(this.props.detailView.record[fieldName]) ? getStringParam(this.props.detailView.record[fieldName]) : '';
                fieldValue = fieldValue == '' && tempFields.length > 0 ? getStringParam(tempFields[0].default_value) : fieldValue;
                this.state.fieldProps[fieldName].isAddToThisList = false;
                this.state.fieldProps[fieldName].value = fieldValue;
                this.state.fieldProps[fieldName].isDisplay = "none";
                this.setState(this.state);
            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> addToThisList()':" + error);
        }
    }

    saveNewListValue = (fieldName, value, isExist) => {
        try {
            if (getBooleanParam(isExist)) {
                this.props.showCustomSnackBar('Value already exists in List', styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else if (fieldName == 'source') {
                let promise = SFFormActions.saveSourceName(value);
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (getBooleanParam(response.data)) {
                            SFFormActions.addSourceOnRedux(constants.ACCOUNTS_OBJECT, fieldName, value);
                            SFFormActions.addSourceOnRedux(constants.CONTACTS_OBJECT, fieldName, value);
                            this.state.fieldProps[fieldName].isAddToThisList = false;
                            this.state.fieldProps[fieldName].value = value;
                            this.state.fields.forEach(field => {
                                if (field.name == fieldName) {
                                    field.list_values.push({ id: value, value: value });
                                    this.state.fieldProps[fieldName].list_values = field.list_values;
                                }
                            });
                            this.inlineUpdate(this.state.fieldProps[fieldName], fieldName);
                            this.setState(this.state);
                        }
                    });
                }
            } else {
                let field = this.state.fields.filter(f => { return f.name == fieldName });
                if (field.length > 0) {
                    let values = getArrayParam(field[0].list_values);
                    let listValues = new Array();
                    values.forEach(field => {
                        if (field.value != '') {
                            listValues.push(field.value);
                        }
                    });
                    listValues.push(value);
                    let promise = SFFormActions.saveListValues(this.props.object, field[0].id, listValues);
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            this.state.fieldProps[fieldName].isAddToThisList = false;
                            this.state.fieldProps[fieldName].value = value;

                            this.state.fields.forEach(field => {
                                if (field.name == fieldName) {
                                    field.list_values.push({ id: value, value: value });
                                    this.state.fieldProps[fieldName].list_values = field.list_values;
                                }
                            });
                            this.inlineUpdate(this.state.fieldProps[fieldName], fieldName);
                            this.setState(this.state);
                        });
                    }
                }

            }
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> saveNewListValue()':" + error);
        }
    }

    generateTypeWiseTextFields = (field) => {
        let component = null;
        if (field.field_type == constants.FIELD_TYPE_TEXT
            || field.field_type == constants.FIELD_TYPE_INTEGER
            || field.field_type == constants.FIELD_TYPE_FLOAT
            || field.field_type == constants.FIELD_TYPE_URL
        ) {
            component =
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="standard"
                        id={'field_Id_' + field.id}
                        ref={this.props.fieldsRecords.records.id + field.id}
                        key={field.id}
                        disabled={field.is_readonly}
                        fullWidth={true}
                        name={field.name}
                        value={this.state.fieldProps[field.name].value}
                        onChange={this.setFieldValue.bind(this, field.name)}
                        style={{ display: this.state.fieldProps[field.name].isDisplay }}
                        onBlur={() => {
                            this.handleShowEditTextFieldBlur(this, field.name);
                        }}
                        autoFocus={true}
                        hintstyle={styles.hintTextStyle}
                        inputprops={{ maxlength: this.state.fieldProps[field.name].field_length }}
                        autoComplete="new-password"
                        margin='dense'
                        size='small'
                        className={"sf-fields-bg"}
                    />
                </FormControl>;

        } else if (field.field_type == constants.FIELD_TYPE_LIST) {
            if (field.list_type == constants.LIST_FIELD_TYPE_REGULAR
                || field.list_type == constants.LIST_FIELD_TYPE_USER
                || field.list_type == constants.LIST_FIELD_TYPE_EXTERNAL) {

                if (getBooleanParam(this.state.fieldProps[field.name].isAddToThisList)) {
                    component =
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="standard"
                                id={'field_Id_' + field.id}
                                ref={this.props.fieldsRecords.records.id + field.id}
                                key={field.id}
                                disabled={field.is_readonly}
                                fullWidth={true}
                                name={field.name}
                                value={this.state.fieldProps[field.name].value}
                                onChange={this.setFieldValue.bind(this, field.name)}
                                style={{ display: this.state.fieldProps[field.name].isDisplay }}
                                onBlur={this.addToThisList.bind(this, field.name)}
                                autoFocus={true}
                                hintstyle={styles.hintTextStyle}
                                inputprops={{ maxLength: this.state.fieldProps[field.name].field_length }}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}
                            />
                        </FormControl>;
                } else {
                    let listValues = getArrayParam(this.state.fieldProps[field.name].list_values);
                    listValues = listValues.length == 0 ? getArrayParam(Object.assign([], field.list_values)) : listValues;
                    sortArrayObjectByProperty(listValues, 'value');
                    component = <div
                        style={{ ...{}, 'width': '100%', cursor: "pointer", fontSize: '14px', color: '#717171', paddingTop: '15px', display: this.state.fieldProps[field.name].isDisplay }}
                        id={field.name}
                        key={field.id + field.name}
                    >
                        <Select
                            key={field.name}
                            name={field.name}
                            labelId={field.name}
                            className={"sf-fields-bg"}
                            value={this.state.fieldProps[field.name].value == "" ? field.label : this.state.fieldProps[field.name].value}
                            style={{ width: '100%', height: '42px' }}
                            fullWidth={true}
                        >
                            {this.getMenuItems(field, listValues)}
                        </Select>
                    </div>
                }


            } else if (field.list_type == constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                let listValues = getArrayParam(Object.assign([], field.list_values));
                sortArrayObjectByProperty(listValues, 'value');
                component = <FormControl variant="standard" style={{ width: '100%' }}>
                    <InputLabel id="sf-detail-view-fields-from-detailviewfields-simple-select-outlined-label" className="sf-detail-view-fields-from-detailviewfields"></InputLabel>
                    <Select
                        key={field.id}
                        multiple
                        value={this.state.fieldProps[field.name].selectedListValues}
                        onChange={this.setMultiSelectValue.bind(this, field.name)}
                        style={{ ...{}, ...styles.selectField, display: this.state.fieldProps[field.name].isDisplay }}
                        renderValue={(selected) => selected.join(', ')}
                        className={"sf-fields-bg"}
                    >
                        {
                            listValues.map((valueObj, listValueIndex) => {
                                return <MenuItem id={valueObj.id} key={valueObj.id} value={valueObj.value} title={valueObj.value}>
                                    <Checkbox checked={this.state.fieldProps[field.name].selectedListValues && this.state.fieldProps[field.name].selectedListValues.indexOf(valueObj.value) > -1 ? true : false} />
                                    <ListItemText primary={valueObj.value} />
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            }

        } else if (field.field_type == constants.FIELD_TYPE_DATE) {
            let HHFormat = new RegExp("HH");
            let datePickerOptions = {
                enableTime: false,
                noCalendar: field.is_readonly ? true : false,
                dateFormat: dateFormatFlatPicker[this.props.app.me.date_format],
                minuteIncrement: 1,
                time_24hr: HHFormat.test(this.props.app.me.date_format) ? true : false
            };
            component = <div className="dateParent">
                <Flatpickr
                    key={field.name}
                    style={{ whiteSpace: 'nowrap', paddingLeft: '7px', marginBottom: '12px', height: '21px', width: '100%', border: '1px solid #dddddd', position: 'relative' }}
                    onChange={this.handleDateChange.bind(this, field.name)}
                    onKeyUp={this.handleDateTimeChange.bind(this, field.name)}
                    value={Date.parse(this.state.fieldProps[field.name].value)}
                    options={datePickerOptions}
                    className='flatpickr-detail-view'
                    allowinput={true}
                    onClose={this.handleDateTimeBlur.bind(this, field.name)}
                />


            </div>

        } else if (field.field_type == constants.FIELD_TYPE_DATE_TIME) {

            let HHFormat = new RegExp("HH");
            let datePickerOptions = {
                enableTime: true,
                noCalendar: field.is_readonly ? true : false,
                dateFormat: dateTimeFormatFlatPicker[this.props.app.me.date_format],
                minuteIncrement: 1,
                time_24hr: HHFormat.test(this.props.app.me.date_format) ? true : false
            };
            component = <div className="dateParent">
                <Flatpickr
                    key={field.name}
                    style={{ whiteSpace: 'nowrap', paddingLeft: '7px', marginBottom: '12px', height: '21px', width: '100%', border: '1px solid #dddddd', position: 'relative' }}
                    onChange={this.handleDateTimeChange.bind(this, field.name)}
                    value={Date.parse(this.state.fieldProps[field.name].value)}
                    options={datePickerOptions}
                    className='flatpickr-detail-view date-time anval'
                    onClose={this.handleDateTimeBlur.bind(this, field.name)}
                />
            </div>

        } else if (field.field_type == constants.FIELD_TYPE_MEMO) {
            component =
                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="standard"
                        key={field.id}
                        id={'field_Id_' + field.id}
                        type='textarea'
                        multiline={true}
                        rows={5}
                        mode="landscape"
                        disabled={field.is_readonly}
                        onChange={this.setFieldValue.bind(this, field.name)}
                        value={this.state.fieldProps[field.name].value}
                        onBlur={() => {
                            this.handleInlineEditMemoFieldBlurDisplaySave(this, field.name);
                        }}
                        style={{ border: '1px solid black', width: '100%', overflow: 'hidden', }}
                        textareaStyle={{ height: '100% ' }}
                        className="inlineEditTextArea"
                        maxRows={field.field_length}
                        inputProps={{ maxLength: 512 }}
                        autoComplete="new-password"
                    />
                </FormControl>
        }
        return component;
    }

    handleSelectChange = (options) => {
        if (this.props.object == constants.OPPORTUNITIES_OBJECT) {
            let restofCustomizedFields = [];
            let fields = this.props.fieldsRecords.detailViewFieldsData;
            fields = this.removeFromgenerateFieds(fields);
            fields.map((fieldObject, i) => {
                let fieldName = fieldObject.name;
                let fieldLabel = fieldObject.label;
                restofCustomizedFields.push(
                    {
                        value: fieldName,
                        label: fieldLabel
                    }
                );
            });
            let ref = this.childSetup.current;
            let childSetupSize = 0;
            if (isValidParam(ref)) {
                childSetupSize = ref.getChildFieldsCount();
            }
            if ((options.length + childSetupSize) > maxLimit) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.DETAIL_VIEW.MAX_SELECTED_FIELD_LIMIT, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            }
            this.setState({ customizeFields: options, restofCustomizedFields: restofCustomizedFields });
        }
        else if (options.length > maxLimit) {
            this.props.showCustomSnackBar(getLocalizedStrings().message.DETAIL_VIEW.MAX_SELECTED_FIELD_LIMIT, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
        }
        else {
            if (isValidParam(options)) {
                options.map((objOption, i) => {
                    objOption.title = objOption.label;
                });
            }
            let restofCustomizedFields = [];
            let fields = this.props.fieldsRecords.detailViewFieldsData;
            fields = this.removeFromgenerateFieds(fields);
            if (isValidParam(fields)) {
                fields.map((fieldObject, i) => {
                    let fieldName = fieldObject.name;
                    let fieldLabel = fieldObject.label;
                    if (this.props.object == constants.ACCOUNTS_OBJECT) {
                        if (fieldName != 'first_name' &&
                            fieldName != 'last_name' &&
                            fieldName != 'workflow_id') {
                            let feild = filter(options, (item, index) => {
                                return (item.value == fieldName)
                            });
                            if (feild.length == 0) {
                                restofCustomizedFields.push(
                                    {
                                        value: fieldName,
                                        label: fieldLabel
                                    }
                                );
                            }


                        }
                    } else {
                        if (fieldName != 'workflow_id') {
                            let feild = filter(options, function (item, index) {
                                return (item.value == fieldName)
                            });
                            if (feild.length == 0) {
                                restofCustomizedFields.push(
                                    {
                                        value: fieldName,
                                        label: fieldLabel
                                    }
                                );
                            }
                        }
                    }
                });
            }

            this.setState({ customizeFields: options, restofCustomizedFields: restofCustomizedFields });
        }
    }


    toggleCheckbox = (event, checked) => {
        let isShowFieldLabel = getBooleanParam(this.state.isShowFieldLabel);
        this.setState({ isShowFieldLabel: !isShowFieldLabel });
    }

    handleDetailsLinkClicked = (event, fieldName) => {

        let tabInfo = getActiveTabInfo();
        let isUnitsAccount = false;
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        if (!isInXpress && !isUnitsAccount) {
            let field = this.state.fieldProps[fieldName];

            if ((field.name == "email" || field.field_type == "email" || field.is_email_field) && field.value != "") {
                let minimizeList = this.props.customDrawer.minimizeList;
                if (this.props.customDrawer.actionType === "Convert" && this.props.customDrawer.isOpen === true) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Convert drawer is already opened.', null)
                }
                else if (!minimizeList.hasOwnProperty('email') || (minimizeList.hasOwnProperty('email') && !minimizeList.email)) {
                    let isAurinkoReAuthRequired = this.props.app.me.is_aurinko_reauth_required;
                    let isSendMailUsingAurinko = this.props.app.me.is_send_mail_using_aurinko;
                    if (isSendMailUsingAurinko && isAurinkoReAuthRequired) {
                        let msg = getLocalizedStrings().message.MY_INTEGRATION.RE_AUTHENTICATE_AURINKO;
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                    } else {
                        this.sendMail(field.value, constants.TYPE_EMAIL, this.props.object, field.name);
                    }
                }

                else {
                    this.props.showCustomSnackBar("Send mail popup is already opened for different record", styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                }


            }
            else if (field.field_type == FORM_FIELD_TYPE.URL && field.value != "") {
                let websiteAddress = field.value.toLowerCase();
                if ((websiteAddress.indexOf("http:") != -1) || (websiteAddress.indexOf("https:") != -1)) {
                    window.open(field.value.toLowerCase());
                } else {
                    window.open('http://' + field.value.toLowerCase());
                }

            }
            else if (field.name == "sales_rep" && field.value != "") {
                let email = '';
                let userArray = getArrayParam(this.props.userlist.data);
                let tempUsers = userArray.filter(f => { return f.value == field.value });
                if (field.value != 'Unassigned') {
                    if (tempUsers.length > 0 && isValidParam(tempUsers[0]) && getStringParam(tempUsers[0].email) != '') {
                        email = getStringParam(tempUsers[0].email);
                    } else {
                        email = getStringParam(this.props.app.me.email);
                    }
                    if (email != '') {
                        let isAurinkoReAuthRequired = this.props.app.me.is_aurinko_reauth_required;
                        let isSendMailUsingAurinko = this.props.app.me.is_send_mail_using_aurinko;
                        if (isSendMailUsingAurinko && isAurinkoReAuthRequired) {
                            let msg = getLocalizedStrings().message.MY_INTEGRATION.RE_AUTHENTICATE_AURINKO;
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                        } else {
                            this.sendMail(email, constants.TYPE_NOTIFY, this.props.object);
                        }
                    }
                } else {
                    let msg = "This record is not assigned to any sales rep.";
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                }

            } else if (field.is_click_to_call) {
                let recordId = this.props.fieldsRecords.records.id;
                let phoneField = true;
                let fieldName = field.name;
                let selectedPhoneNumber = field.value
                if (field.is_ring_central) {
                    openRingCentralWebWidget();
                } else {
                    let phoneTextProvider = this.props.app.me.phone_text_provider ?? constants.NONE;
                    if (phoneTextProvider !== constants.NONE) {
                        if (field.is_twilio) {
                            this.getCapabilityTokenForCall(this.props.object, recordId, phoneField, fieldName, selectedPhoneNumber);
                        } else if (field.name == "mobile" && field.is_twilio_sms) {
                            this.getRecordDetailsForSMS(recordId, fieldName);
                        }
                    } else {
                        sfDialogs.alert(
                            getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                            getLocalizedStrings().message.PHONE_AND_TEXT_INTEGRATION.NO_PROVIDER,
                            null,
                        );
                    }
                }
            }
        } else {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
        }
    }

    getCapabilityTokenForCall = (object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        var promise = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.GENERATE_CAPABILITY_TOKEN, null));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let capabilityToken = getStringParam(response.token);
                    if (capabilityToken != null && capabilityToken != "") {
                        this.getCapabilityToken(capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
                    }
                }
            });
        }
    }

    getCapabilityToken = (capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        let params = { "id": recordId, parent_table_id: OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT] };
        let endPoint = getObjectSpecificEndpoint(object, endPoints.RECORDS.RECORD_LINKED_MAP_DATA);
        if (object == constants.ACCOUNTS_OBJECT || object == constants.CONTACTS_OBJECT) {
            endPoint = getObjectSpecificEndpoint(object, endPoints.RECORDS.MAP_DATA);
            params = { "id": recordId, "fields": ["company", "first_name", "last_name", "phone", "mobile", "workflow_id", "country"] };
        }
        var promise = Promise.resolve(HTTPClient.get(endPoint, params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let records = response.records;
                    this.processInfoAndOpenCallDialog(records, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber);
                }
            });
        }
    }


    processInfoAndOpenCallDialog = (records, capabilityToken, object, recordId, phoneField, fieldName, selectedPhoneNumber) => {
        let firstName = "";
        let lastName = "";
        let fullName = "";
        let workflowId = "";
        let company = "";
        let title = "";
        let country = "";
        if (records) {
            if (records.hasOwnProperty('first_name')) {
                firstName = records.first_name;
            }

            if (records.hasOwnProperty('last_name')) {
                lastName = records.last_name;
            }

            if (records.hasOwnProperty('workflow_id')) {
                workflowId = records.workflow_id;
            }

            if (records.hasOwnProperty('company')) {
                company = records.company;
            }

            if (records.hasOwnProperty('country')) {
                country = records.country;
            }
        }
        country = country || this.props.app.me.tenant_country;

        if (firstName != null && firstName != "" && firstName != undefined && lastName != null && lastName != "" && lastName != undefined) {
            fullName = firstName + " " + lastName;
        }
        if (firstName != null && firstName != "" && firstName != undefined && (lastName == null || lastName == "" || lastName == undefined)) {
            fullName = firstName;
        }

        if ((firstName == null || firstName == "" || firstName == undefined) && lastName != null && lastName != "" && lastName != undefined) {
            fullName = lastName;
        }

        if (fullName == null || fullName == "" || fullName == undefined) {
            fullName = company;
        }
        if (fullName == null || fullName == "" || fullName == undefined) {
            fullName = selectedPhoneNumber;
        }
        if (fullName != null && fullName != "" && fullName != undefined) {
            title = "Call " + fullName + "";
        }

        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_CALL;
        let data = new Object();
        data.id = recordId;
        data.object = object;
        data.token = capabilityToken;
        data.selectedPhoneNumber = selectedPhoneNumber;
        data.fieldName = fieldName;
        data.workflowId = workflowId;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = true;
        data.call_from = constants.DETAIL_VIEW;
        if (this.props.detailView.data.object == constants.OPPORTUNITIES_OBJECT) {
            let opportunity_info = new Object();
            opportunity_info.recordId = this.props.detailView.record.id;
            opportunity_info.tableId = 2;
            data.opportunity_info = opportunity_info;
        }
        let style = new Object();
        style.width = '40%';
        this.props.getCallDrawer(true, label, type, style, data, null);
    }




    sendMail = (email, dataType, objectName, fieldName) => {
        let labelName = null;
        let isEmail = true;
        let endPoint = "";
        let params = {};
        let recordId = this.props.fieldsRecords.records.id;
        let isFetch = false;
        if (dataType == constants.TYPE_EMAIL) {
            if (objectName == constants.ACCOUNTS_OBJECT) {
                endPoint = endPoints.ACCOUNTS.MAP_DATA;
                params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email", "email2"] };
                isFetch = true;
            } else if (objectName == constants.CONTACTS_OBJECT) {
                endPoint = endPoints.CONTACTS.MAP_DATA;
                params.id = recordId;
                params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email", "email2"] };
                isFetch = true;
            }
            if (isFetch) {
                var promise = Promise.resolve(HTTPClient.get(endPoint, params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            let records = response.records;
                            let firstName = "";
                            let lastName = "";
                            let fullName = "";
                            let company = "";
                            let email = "";
                            let isFullName = false;
                            if (records) {
                                if (records.hasOwnProperty('first_name')) {
                                    firstName = records.first_name;
                                }
                                if (records.hasOwnProperty('last_name')) {
                                    lastName = records.last_name;
                                }
                                if (records.hasOwnProperty('company')) {
                                    company = records.company;
                                }
                                if (records.hasOwnProperty('email') || records.hasOwnProperty('email2')) {
                                    if (fieldName == 'email') {
                                        email = records.email;
                                    } else {
                                        email = records.email2;
                                    }
                                }
                            }

                            if (firstName != null && firstName != "" && firstName != undefined && lastName != null && lastName != "" && lastName != undefined) {
                                fullName = firstName + " " + lastName;
                            }
                            if (firstName != null && firstName != "" && firstName != undefined && (lastName == null || lastName == "" || lastName == undefined)) {
                                fullName = firstName;
                            }

                            if ((firstName == null || firstName == "" || firstName == undefined) && lastName != null && lastName != "" && lastName != undefined) {
                                fullName = lastName;
                            }

                            if (fullName == null || fullName == "" || fullName == undefined) {
                                fullName = company;
                            }

                            if (fullName == null || fullName == "" || fullName == undefined) {
                                fullName = email;
                            }

                            if (fullName == null || fullName == "" || fullName == undefined) {
                                fullName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                isEmail = false;
                            }

                            if (fullName != null && fullName != "" && fullName != undefined) {
                                if (isEmail) {
                                    labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + fullName + "";
                                } else if (!isEmail && !isFullName) {
                                    labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                }
                            }
                            this.openSendMailDialog(email, dataType, objectName, labelName, firstName, fieldName);
                        }
                    });
                }
            } else {
                labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + email + "";
                this.openSendMailDialog(email, dataType, objectName, labelName);
            }
        } else if (dataType == constants.TYPE_NOTIFY) {
            let userArray = getArrayParam(this.props.userlist.data);
            let tempUsers = userArray.filter(f => { return f.email == email });
            if (tempUsers.length > 0 && isValidParam(tempUsers[0]) && getStringParam(tempUsers[0].value) != '') {
                labelName = getStringParam(tempUsers[0].value);
            }
            labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + labelName + "";
            this.openSendMailDialog(email, dataType, objectName, labelName);
        }
    }

    openSendMailDialog = (email, dataType, objectName, label, firstName, fieldName) => {
        let type = LinkToType.TYPE_EMAIL;
        let data = new Object();
        data.id = this.props.fieldsRecords.records.id;
        data.email = email;
        data.object = objectName;
        data.type = dataType;
        data.firstName = firstName;
        data.fieldName = fieldName;
        if (this.props.detailView.data.object == constants.OPPORTUNITIES_OBJECT) {
            let opportunity_info = new Object();
            opportunity_info.recordId = this.props.detailView.record.id;
            opportunity_info.tableId = 2;
            data.opportunity_info = opportunity_info;
        }
        this.props.getAppCustomDrawer(true, label, type, { width: '90%' }, data, null, true);
    }

    opennotepopup = (event) => {
        event.preventDefault();
        this.state.anchorEl = event.currentTarget;
        this.setState({ openList: true, isContactsMounted: false, avaliableContacts: [] });

        let recordId = this.props.fieldsRecords.records.id;
        let promise = Promise.resolve(getAccountContacts(recordId));
        if (isValidParam(promise)) {
            promise.then(response => {
                let contacts = getArrayParam(response);
                contacts = contacts.filter(f => { return getStringParam(f.email) != '' });
                this.props.detailView.data.avaliableContacts = contacts;
                this.state.avaliableContacts = contacts;
                this.setState({ openList: true, isContactsMounted: true });
            });
        }
    }

    closenotepopup = () => {
        this.setState({
            openList: false,
            isContactsMounted: false
        });
    };

    onChangePrimaryContact = (value) => {
        let tabInfo = getActiveTabInfo();
        let isUnitsAccount = false;
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        if (!isInXpress && !isUnitsAccount) {
            let contactList = getArrayParam(this.props.detailView.avaliableContacts);
            let company = this.props.detailView.data.title;
            let selectedContact = {};
            for (let i = 0; i < contactList.length; i++) {
                if (contactList[i].id == value) {
                    selectedContact = contactList[i];
                    break;
                }
            }

            this.setState({ openList: false });
            let msg = getLocalizedStrings().message.DETAIL_VIEW.CHANGE_PRIMARY_CONTACT_ALERT_1 + " " + selectedContact.full_name + " " + getLocalizedStrings().message.DETAIL_VIEW.CHANGE_PRIMARY_CONTACT_ALERT_2 + " " + company + ". " + getLocalizedStrings().message.DETAIL_VIEW.CLICK_TO_CONFIRM;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => this.onChangePrimaryContactOnConfirm(selectedContact), null);
        } else {
            this.setState({ openList: false });
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
        }
    }
    onChangePrimaryContactOnConfirm = (selectedContact) => {
        let contactList = this.state.avaliableContacts;
        let keysInContact = Object.keys(selectedContact);
        let primaryContactFieldProps = this.state.primaryContactFieldProps
        for (let i = 0; i < keysInContact.length; i++) {
            if (primaryContactFieldProps[keysInContact[i]] != undefined) {
                primaryContactFieldProps[keysInContact[i]].value = selectedContact[keysInContact[i]];
            }
        }

        let field = this.props.sfForm.data.accounts.fields.filter(f => f.name == 'main_contact_id');
        if (field != null && field.length > 0) {
            let mainContactIdObj = new Object();
            mainContactIdObj.name = 'main_contact_id';
            mainContactIdObj.value = selectedContact.id;
            mainContactIdObj.id = field[0].id;

            primaryContactFieldProps['main_contact_id'] = mainContactIdObj;
        }
        let jobField = this.props.sfForm.data.accounts.fields.filter(f => f.name == 'job_title');
        if (jobField != null && jobField.length > 0) {
            let jobTitleObj = new Object();
            jobTitleObj.name = 'job_title';
            jobTitleObj.value = selectedContact.job_title;
            jobTitleObj.id = jobField[0].id;

            primaryContactFieldProps['job_title'] = jobTitleObj;
        }
        let email2 = this.props.sfForm.data.accounts.fields.filter(f => f.name == 'email2');
        if (jobField != null && jobField.length > 0) {
            let email2Obj = new Object();
            email2Obj.name = 'email2';
            email2Obj.value = selectedContact.email2;
            email2Obj.id = email2[0].id;

            primaryContactFieldProps['email2'] = email2Obj;
        }
        let accountPrimaryContactDetails = {};
        accountPrimaryContactDetails["avaliableContacts"] = contactList;
        this.setState({
            openList: false, ...accountPrimaryContactDetails,
            primaryContactFieldProps: primaryContactFieldProps,
            isFocusPrimaryContact: false
        });
        primaryContactFieldProps = { ...primaryContactFieldProps }
        this.inlineUpdatePrimaryContact(primaryContactFieldProps, true);
    }
    changePrimaryContactValue = (event, fieldName, value) => {
        let primaryContactFieldProps = this.state.primaryContactFieldProps;
        primaryContactFieldProps[fieldName].value = value;
        let accountPrimaryContactDetails = {};
        accountPrimaryContactDetails["primaryContactFieldProps"] = primaryContactFieldProps;
        this.setState({
            ...accountPrimaryContactDetails,
            isFocusPrimaryContact: false,
            isChangePrimaryContact: true
        });


    }
    getAvailableContactsFromDtlCall = (contactList, selectedContactIndex) => {
        let tempMenuItem = [];
        let tempContactList = [];
        tempContactList = getArrayParam(contactList);
        tempContactList.map((contact, index) => {

            tempMenuItem.push(<MenuItem
                key={contact.full_name + index}
                value={contact.id}
                selected={index === selectedContactIndex}
                fullWidth={true}
                onClick={() => this.onChangePrimaryContact(contact.id)}
                style={{ ...{ color: '#479ccf' }, ...styles.popoverMenuItem, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '20em', color: index == selectedContactIndex ? 'rgb(3, 155, 229)' : 'none' }}
                className='pomenu'
                autowidth={false}
            >{(contact.full_name == " " || contact.full_name == "") ? "Empty" : contact.full_name}</MenuItem>);
        })
        return tempMenuItem;
    }
    generatePrimaryContactDropDown = () => {
        let contactList = getArrayParam(this.state.avaliableContacts);
        let tabInfo = getActiveTabInfo();
        let isUnitsAccount = false;
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        let selectedContact = {};
        let isShowFieldLabel = this.props.fieldsRecords.isShowFieldLabel;
        let primaryContactName = "";
        let selectedContactIndex = -1;
        let full_name = this.props.detailView.data.full_name;
        let primaryContactFieldProps = this.state.primaryContactFieldProps;
        sortArrayObjectByProperty(contactList, 'full_name');
        if (Object.keys(primaryContactFieldProps).length == 0) {
            return "";
        }

        for (let i = 0; i < contactList.length; i++) {
            if (contactList[i].full_name == full_name) {
                selectedContact = contactList[i];
                selectedContactIndex = i;
                break;
            }
        }

        if (full_name == "" || full_name == " ") {
            primaryContactName = "Empty";
        } else {
            primaryContactName = full_name;
        }

        let primaryContactEditIcon = <i className="fa fa-pencil" aria-hidden="true" title={getLocalizedStrings().label.COMMON.EDIT} style={{ float: 'right', paddingTop: '3px', cursor: 'pointer', paddingRight: '3px', color: '#b5b5b5' }} onClick={() => {
            let hasPermission = false;
            let objectName = getStringParam(this.props.object);
            hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);

            if (hasPermission && !isInXpress && !isUnitsAccount) {
                let display = this.state.displayEditPrimaryContact == 'none' ? 'block' : 'none'
                this.setState({ displayEditPrimaryContact: display, isFocusPrimaryContact: !this.state.isFocusPrimaryContact });
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
            }
        }}  >  </i>

        return (
            <div style={{ display: 'inline-block', cursor: 'pointer', marginBottom: '7px' }} className="detailViewFieldData">
                <div title={getLocalizedStrings().label.COMMON.NAME} className="detailViewFields_hover" style={{ marginBottom: '5px' }} >
                    {isShowFieldLabel && <span className='detailViewFieldDataLabel2'> {getLocalizedStrings().label.COMMON.NAME}  </span>}
                    {isShowFieldLabel && primaryContactEditIcon}
                </div>
                <div style={{ ...styles.detailViewFieldsRecordsWithLabel.fieldsRecord }} title={primaryContactName} >
                    <div className="detailViewFields_hover clearfix" style={{ display: "block" }}>
                        <span className='detailViewFieldData2'>
                            {primaryContactName}
                        </span>
                        {isShowFieldLabel == false && primaryContactEditIcon}
                        <Icon style={{ top: "8px", float: 'right', display: 'inline' }} onClick={this.opennotepopup} >keyboard_arrow_down</Icon>
                        <Menu
                            open={this.state.openList}
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                            onClose={this.closenotepopup}
                        >
                            {!this.state.isContactsMounted &&
                                <div style={{ width: '150px', height: '67px', padding: '0 60px' }}>
                                    <SFLoader style={{ fontSize: '20px' }} />
                                </div>
                            }
                            {this.state.isContactsMounted &&
                                this.getAvailableContactsFromDtlCall(contactList, selectedContactIndex)
                            }

                        </Menu>
                    </div>
                    <div id="menucontainer" style={{ width: '100%', float: 'left', display: this.state.displayEditPrimaryContact }}  >
                        <FormControl style={{ width: '50%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="standard"
                                id={'field_Id_primary_firstName'}
                                fullWidth={true}
                                value={primaryContactFieldProps['first_name'].value}
                                underlinestyle={styles.underlineStyle}
                                underlinefocusstyle={styles.underlineStyle}
                                hintstyle={styles.hintTextStyle}
                                style={{ width: '100%' }}
                                onChange={(e) => this.changePrimaryContactValue(e, 'first_name', e.target.value)}
                                onBlur={() => {

                                    if ($('#menucontainer:hover').length == 1) {

                                    }
                                    else {

                                        let isChangePrimaryContact = this.state.isChangePrimaryContact;

                                        this.setState({ displayEditPrimaryContact: 'none', isFocusPrimaryContact: false, isChangePrimaryContact: false });
                                        if (isChangePrimaryContact == true) {
                                            this.inlineUpdatePrimaryContact(this.state.primaryContactFieldProps);
                                        }


                                    }
                                }}
                                tabIndex={1}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}

                            />
                        </FormControl>
                        <FormControl style={{ width: '50%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="standard"
                                id={'field_Id_primary_lastName'}
                                fullWidth={true}
                                value={primaryContactFieldProps['last_name'].value}
                                underlinestyle={styles.underlineStyle}
                                underlinefocusstyle={styles.underlineStyle}
                                hintstyle={styles.hintTextStyle}
                                style={{ width: '100%', marginLeft: '5px' }}
                                onChange={(e) => this.changePrimaryContactValue(e, 'last_name', e.target.value)}
                                onBlur={() => {

                                    if ($('#menucontainer:hover').length == 1) {

                                    } else {

                                        let isChangePrimaryContact = this.state.isChangePrimaryContact;
                                        this.setState({ displayEditPrimaryContact: 'none', isFocusPrimaryContact: false, isChangePrimaryContact: false });
                                        if (isChangePrimaryContact == true) {

                                            this.inlineUpdatePrimaryContact(this.state.primaryContactFieldProps);
                                        }
                                    }
                                }}
                                tabIndex={2}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </div>
                </div>
            </div>

        );
    }

    getLeadScoreHistory = () => {
        let data = new Object();
        data.parent_object = this.props.object;
        data.parent_record_id = this.props.detailView.record.id;
        data.lead_score = this.props.fieldsRecords.leadScore;
        this.props.getAppDrawer(true, getLocalizedStrings().label.AUDIT_LEADSCORE.LEAD_SCORE_HISTORY, constants.LEAD_SCORE_HISTORY, data, constants.DETAIL_VIEW);
    }

    getSetupFieldsCount = () => {
        let count = 0;
        try {
            count = getArrayParam(this.state.customizeFields).length;
        }
        catch (error) {
            console.error("Error in 'detailViewFields.js -> getSetupFieldsCount()':" + error);
        }
        return count;
    }

    checkDuplicateProduct = (fields, recordId) => {
        try {
            let productName = isValidParam(this.state.fieldProps['product_name']) ? getStringParam(this.state.fieldProps['product_name'].value).trim().toLowerCase() : '';
            let paramObject = new Object();
            paramObject.product_name = getStringParam(productName);
            paramObject.record_id = getIntParam(recordId);
            let promise = checkDuplicateProductName(paramObject);
            promise.then((response) => {
                if (isValidParam(response)) {
                    let isDuplicateProduct = getBooleanParam(response.is_duplicate);
                    if (isDuplicateProduct) {
                        this.state.fieldProps['product_name'].helperText = getLocalizedStrings().message.PRODUCTS.DUPLICATE_PRODUCT;
                        this.props.showCustomSnackBar(getLocalizedStrings().message.PRODUCTS.DUPLICATE_PRODUCT, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                        return;
                    } else {
                        this.state.fieldProps['product_name'].helperText = '';
                        this.state.fieldProps['product_name'].isDisplay = (this.state.fieldProps['product_name'].isDisplay == 'none' ? 'block' : 'none');

                        if (this.state.fieldProps['product_name'].isDisplay == 'none') {
                            this.state.fieldProps['product_name'].value = this.state.fieldProps['product_name'].value.trim();
                            if (fields.text_before_edit != fields.value) {
                                this.inlineUpdate(fields, 'product_name');
                            }
                        }
                        this.setState({ fieldProps: this.state.fieldProps, recentClickFieldId: fields });
                    }
                }
            });

        } catch (error) {
            console.error("Error in 'detailViewFields.js -> checkDuplicateProduct()':" + error);
        }
    }

    getObjectHeader = (object, isChildView) => {
        let actionIcon = this.getObjectHeaderIcon(object);
        let objectLabel = getObjectLabelByObject(object);
        objectLabel = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(objectLabel) ? getLocalizedStrings().label.NO_RECORD[objectLabel] : objectLabel;
        let element = <div style={{ marginBottom: '10px' }}>

            {getBooleanParam(isChildView) &&
                <div className="detailViewFields_hover" style={{ backgroundColor: '#e3e6e8', display: 'flex' }}>
                    <div style={{ padding: '8px', width: '100%', fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} title={objectLabel}>{objectLabel}
                    </div>
                    {actionIcon}
                </div>}
        </div>

        return element;
    }

    getObjectHeaderIcon = (object) => {
        let object_header_icon = null;
        try {
            let isShowEdit = false;
            let paddingRight = '3px';
            let availableRecord = [];
            if (object == constants.ACCOUNTS_OBJECT) {
                availableRecord = getArrayParam(this.props.detailView.avaliableAccounts);
                if (availableRecord.length > 0) {
                    isShowEdit = true;
                }
            } else if (object == constants.CONTACTS_OBJECT) {
                availableRecord = getArrayParam(this.props.detailView.avaliableContacts);
                if (availableRecord.length > 0) {
                    isShowEdit = true;
                }
            }

            let objectLabel = getObjectLabelByObject(object);
            objectLabel = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(objectLabel) ? getLocalizedStrings().label.NO_RECORD[objectLabel] : objectLabel;
            let optionLabel = 'Select ' + objectLabel + ' record';
            let child_setup_details = isValidParam(this.props.detailView.child_setup_details) ? this.props.detailView.child_setup_details[object] : {};
            let selectedChildRecord = null;
            if (isValidParam(child_setup_details) && child_setup_details.hasOwnProperty('record')) {
                selectedChildRecord = child_setup_details.record;
            }
            if (this.state.selectedOption != null) {
                selectedChildRecord = this.state.selectedOption;
            }
            if (!isShowEdit) {
                let hasChildRecord = false;
                if (availableRecord.length > 0) {
                    hasChildRecord = true;
                }
                if (selectedChildRecord != null && selectedChildRecord.hasOwnProperty('id')) {
                    isShowEdit = isShowEdit;
                } else {
                    isShowEdit = hasChildRecord ? !isShowEdit : isShowEdit;
                }
            }

            if (isShowEdit) {
                paddingRight = '10px';
            }

            object_header_icon = <span style={{ float: 'right', padding: '12px 6px 0px', color: '#B5B5B5', display: 'flex' }}>
                <i
                    className="fa fa-plus"
                    aria-hidden="true"
                    title={getLocalizedStrings().label.COMMON.ADD + ' ' + objectLabel}
                    onClick={this.addNewChildRecord.bind(this, object)}
                    style={{ cursor: 'pointer', paddingRight: paddingRight }}
                />
                {isShowEdit &&
                    <span><span onClick={this.handleShowChildRecord.bind(this, object)}><i
                        className="fa fa-pencil"
                        aria-hidden="true"
                        title={getLocalizedStrings().label.COMMON.EDIT + ' ' + objectLabel}
                        style={{ cursor: 'pointer', paddingRight: '3px' }}
                    ></i></span>
                        <Popover
                            open={this.state.isPopOverOpen}
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                            onClose={this.closePopOver}
                        >
                            <div style={{ width: 'auto', height: 'auto', padding: 20, paddingTop: 20 }}>
                                <div>{optionLabel}</div>
                                <div style={{ height: 'auto', minHeight: 50, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            onChange={this.changeRadioButton}
                                            defaultValue={selectedChildRecord.id}
                                            style={{ marginTop: '10px', fontSize: '15px' }} >
                                            {
                                                availableRecord.map((option) => {
                                                    let name = '';
                                                    if (object == constants.ACCOUNTS_OBJECT) {
                                                        name = (option.company != null && option.company != "") ? option.company : 'Empty';
                                                    } else if (object == constants.CONTACTS_OBJECT) {
                                                        name = (option.full_name != null && option.full_name != "") ? option.full_name : 'Empty';
                                                    }
                                                    return <FormControlLabel value={option.id} control={<Radio checked={option.id == selectedChildRecord.id} key={option.id} color="default" />} label={<span className="radio-label" title={name} >{name}</span>} />
                                                }
                                                )
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div style={{ textAlign: 'right', padding: 0, margin: 0, marginTop: 10 }}>
                                    <Button

                                        style={{ ...styles.primaryButton, minWidth: '20px' }}
                                        labelStyle={{ fontWeight: 'normal' }}
                                        onClick={this.doAction}
                                    >{getLocalizedStrings().label.COMMON.UPDATE}</Button>
                                </div>
                            </div>
                        </Popover>
                    </span>}
            </span>;
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> getObjectHeaderIcon()':" + error);
        }

        return object_header_icon;
    }

    doAction = (event) => {
        try {
            let object = this.state.recordSelectObject;
            let selectedOption = this.state.selectedOption;
            let childId = selectedOption.id;
            let objectId = getIntParam(OBJECT_TABLEID_MAP[object]);
            let recordInfo = objectId + '_' + childId;
            let linkInfo = this.props.detailView.data.record.link_info;
            let paramLinkInfo = null;
            if (linkInfo != null && linkInfo != undefined) {
                let linkInfoArr = linkInfo.split(',');

                linkInfoArr.map((rec, i) => {
                    let recArr = rec.split('_');
                    let infoObjId = getIntParam(recArr[0]);
                    if (infoObjId != objectId) {
                        paramLinkInfo = rec;
                    }
                });
            }
            if (paramLinkInfo != null) {
                paramLinkInfo = paramLinkInfo + ',' + recordInfo;
            } else {
                paramLinkInfo = recordInfo;
            }

            let field = [{
                name: 'link_info',
                value: paramLinkInfo
            }];
            let params = {
                id: this.props.detailView.data.record.id,
                fields: field,
                auditrail_action_type: constants.INLINE_UPDATE_AUDIT_TRAIL_RECORD,

            }
            let paramObject = this.props.detailView.data.object.toLowerCase();
            let promise = SFFormActions.saveRecordPromise(paramObject, params);
            promise.then((response) => {
                if (isValidParam(response) && response.hasOwnProperty('data') && response.data.hasOwnProperty('id')) {
                    let id = isValidParam(response.data.id) && isArray(response.data.id) && response.data.id.length > 0 ? response.data.id[0] : 0;
                    if (id > 0) {
                        this.closePopOver();
                        this.props.detailViewIsMounted(false);
                        this.props.isDetailViewSet(false);
                        this.props.refreshDetailViewData(true);
                        this.props.refreshDetailViewListViewData(true);
                        this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.UPDATE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                    }
                }
            })
        } catch (error) {
            console.error("Error in 'detailViewFields.js -> doAction()':" + error);
        }

    }

    changeRadioButton = (event, value) => {
        try {
            let object = this.state.recordSelectObject;
            let recordArr = null;
            if (object == constants.ACCOUNTS_OBJECT) {
                recordArr = getArrayParam(this.props.detailView.avaliableAccounts);
            } else if (object == constants.CONTACTS_OBJECT) {
                recordArr = getArrayParam(this.props.detailView.avaliableContacts);
            }
            let option = recordArr.filter(f => f.id == value);
            if (Array.isArray(option) && option.length == 1) {
                this.state.selectedOption = option[0];
                this.setState({ ...this.state });
            }
        } catch (error) {
            console.error("Error in 'DetailViewFields.js -> changeRadioButton()':" + error);
        }
    }

    addNewChildRecord = (objectName, event) => {
        try {
            let hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
            if (hasPermission) {
                let itemLabel = getObjectLabelByObject(objectName);
                let labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + itemLabel;
                let data = new Object();
                data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
                data.object = objectName;
                data.isDetailView = getBooleanParam(this.props.detailView.isDetailView);
                data.parent_object = this.props.detailView.data.object;
                data.parent_record_id = this.props.detailView.data.record.id;
                data.link_info = this.props.detailView.data.record.link_info;
                this.props.getAppDrawer(true, labelName, 'New', data, null);
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
            }
        } catch (error) {
            console.error("Error in 'DetailViewFields.js -> addNewChildRecord()':" + error);
        }
    }

    handleShowChildRecord = (object, event) => {
        event.preventDefault();
        let _state = this.state;
        _state.isPopOverOpen = true;
        _state.anchorEl = event.currentTarget;
        _state.recordSelectObject = object;
        this.setState({ _state });
    }

    closePopOver = () => {
        this.setState({ isPopOverOpen: false, recordSelectObject: '' });
    }

    getOptions = (availableRecord, objectName) => {
        let html = availableRecord.map((option) => {
            let name = '';
            if (objectName == constants.ACCOUNTS_OBJECT) {
                name = (option.company != null && option.company != "") ? option.company : 'Empty';
            } else if (objectName == constants.CONTACTS_OBJECT) {
                name = (option.full_name != null && option.full_name != "") ? option.full_name : 'Empty';
            }
            return <FormControlLabel value={option.id} control={<Radio key={option.id} color="default" />} label={<span className="radio-label" title={name} >{name}</span>} />
        }
        );
        return html;
    }

    render() {
        let fieldsData = this.generateFieldsData(this.props.object, this.props.isChildView);
        let primaryContactValue = null;
        if (this.props.object == constants.ACCOUNTS_OBJECT) {
            primaryContactValue = this.generatePrimaryContactDropDown();
        }
        let leadScore = null;
        let totalLeadScore = null;
        let scoreIconarray = null;
        if (this.props.object == constants.ACCOUNTS_OBJECT || this.props.object == constants.CONTACTS_OBJECT) {
            leadScore = this.props.fieldsRecords.leadScore;
            totalLeadScore = this.props.fieldsRecords.totalLeadScore;
            scoreIconarray = generateLeadScoreIcon(totalLeadScore, leadScore);
        }

        let language = getLocalizedStrings()._language;
        let setupShowLabelWidth = '21%';
        if (language == 'es-ES' || language == 'pt-PT') {
            setupShowLabelWidth = '27%';
        } else if (language = 'de-DE') {
            setupShowLabelWidth = '25%';
        }

        let isHubCase = false;
        if (this.props.object == constants.CASES_OBJECT && this.props.app.selectedModuleName == constants.MODULE_HUB) {
            isHubCase = true;
        }

        let mainDivStyle = new Object();
        if (this.props.hasOwnProperty("isChildView") && this.props.isChildView) {
            mainDivStyle.marginTop = '-10px'
        } else {
            mainDivStyle.paddingTop = '20px';
        }

        let objectLabel = getObjectLabelByObject(this.props.object);
        objectLabel = getLocalizedStrings().label.NO_RECORD.hasOwnProperty(objectLabel) ? getLocalizedStrings().label.NO_RECORD[objectLabel] : objectLabel;
        let fieldStyle = this.props.childFieldsView == true ? { marginTop: 5 } : { marginBottom: '10px', paddingBottom: '6px' };
        let optionsList = [...this.state.restofCustomizedFields, ...this.state.customizeFields]

        return (
            <div style={{ ...mainDivStyle }}>
                <div style={{ ...fieldStyle, borderBottom: ((this.props.object == constants.ACCOUNTS_OBJECT || this.props.object == constants.CONTACTS_OBJECT) && !this.props.isChildView) ? '1px solid #ccc' : 'none' }}>

                    {
                        ((this.props.object == constants.ACCOUNTS_OBJECT || this.props.object == constants.CONTACTS_OBJECT) && !this.props.isChildView) &&
                        <div
                            style={{ display: 'inline', fontSize: '16px', cursor: 'pointer' }}
                            title={getLocalizedStrings().label.AUDIT_LEADSCORE.SCORE + " : " + leadScore}>
                            {scoreIconarray}
                            <span style={{ paddingLeft: '5px', color: '#717171', textDecoration: 'underline' }} onClick={this.getLeadScoreHistory}>
                                {leadScore}
                            </span>
                        </div>
                    }

                    {/* working here */}

                    {(!isHubCase && !this.props.isChildView && (this.props.object != constants.TASKS)) && <span style={{ float: 'right', paddingRight: '3px' }} onClick={this.openCustomizedFields}>
                        <Icon
                            style={{ fontSize: '16px', color: '#949494', cursor: 'pointer', float: 'right', marginTop: '4px' }}
                            title={getLocalizedStrings().label.DETAIL_VIEW.ADD_OR_REMOVE_FROM_LIST}>
                            settings
                        </Icon>
                    </span>}
                    <Popper
                        anchorReference="anchorPosition"
                        open={this.state.isOpenCustomizedFields}
                        anchorEl={this.state.anchorEl}
                        placement={'right-start'}
                        sx={{ ...styles.popperStyle }}
                    >
                        <div style={styles.customizedFieldsContainerDiv}>
                            <div style={styles.customizedFieldsHeaderDiv}>
                                <span style={styles.customizedFieldsHeader}>
                                    {getLocalizedStrings().label.DETAIL_VIEW.SETUP_FIELDS}
                                </span>
                                <IconButton onClick={this.closeCustomizedFields} aria-label="close" className='SF-float-right' style={{ color: '#000', marginRight: '2px', top: 0 }}>
                                    <Icon fontSize="large" style={{ fontSize: '20px' }} title={getLocalizedStrings().label.COMMON.CLOSE} className="material-icons">close</Icon>
                                </IconButton>
                            </div>
                            <div style={styles.customizedFieldsNotify}>
                                {getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_ADD_MORE_FIELDS}
                            </div>
                            <div style={{ paddingBottom: '5px' }}>
                                {getLocalizedStrings().label.COMMON.SELECT + ' ' + objectLabel + ' ' + getLocalizedStrings().label.LIST_VIEW.FIELDS}
                            </div>
                            {/* working here */}
                            <div id="selCustomFld">
                                <DraggableSelect
                                    loadingPlaceholder={getLocalizedStrings().label.COMMON.LOADING + "..."}
                                    isClearable
                                    value={this.state.customizeFields}
                                    options={optionsList}
                                    selectLimit={maxLimit}
                                    //placeholder={getLocalizedStrings().label.DETAIL_VIEW.SELECT_OPTIONS}
                                    limitMessage={getLocalizedStrings().message.DETAIL_VIEW.MAX_SELECTED_FIELD_LIMIT}
                                    onChange={this.handleSelectChange}
                                    onSortEnd={this.rearrangeSequence}
                                    clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
                                />

                            </div>
                            {this.props.object == constants.OPPORTUNITIES_OBJECT &&
                                <DetailViewChildObjectFields childObjectProps={this.props.detailView.childObjects} object={this.props.object} setupFieldsCount={this.getSetupFieldsCount} childDetails={this.props.childDetails} ref={this.childSetup} />
                            }
                            <div style={{ paddingTop: '11px', float: 'left', width: setupShowLabelWidth }}>
                                <FormControlLabel
                                    control={<Checkbox
                                        color="default"
                                        checked={this.state.isShowFieldLabel}
                                        value={this.state.isShowFieldLabel}
                                        onChange={this.toggleCheckbox}
                                    />}
                                    label={<div style={{ color: '#717171' }}>{getLocalizedStrings().label.DETAIL_VIEW.SHOW_LABELS}</div>}
                                    labelposition="right"
                                />
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <SFToolTip />

                            </div>
                            <div style={{ paddingTop: '10px', paddingBottom: '10px', marginRight: '-10px', float: 'right' }}>
                                <Button
                                    onClick={this.saveCustomizedFields}

                                    style={styles.primaryButton}
                                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                            </div>
                        </div>
                    </Popper>
                </div>

                {/* For Primary contact  */}

                {primaryContactValue}


                {/* For Primary contact  */}
                <div>
                    {isValidParam(this.props.isChildView) && this.getObjectHeader(this.props.object, this.props.isChildView)}
                    {fieldsData}
                </div>
                <div style={{ display: 'none' }}>
                    <button type="button" onClick={() => {
                        this.props.refreshDetailViewData(true);

                    }
                    }> {getLocalizedStrings().label.COMMON.REFRESH} </button>
                </div>

                <div style={{ display: 'none' }}>
                    <button type="button" onClick={() => {
                        this.props.refreshDetailViewListViewData(true);

                    }
                    }> {getLocalizedStrings().label.COMMON.REFRESH} </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        listView: state.listView,
        detailView: state.detailView,
        userlist: state.userlist,
        app: state.app,
        sfForm: state.sfForm,
        childListView: state.childListView,
        customDrawer: state.appContainer.customDrawerProps,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        refreshDetailViewData: (isRefresh) => {
            dispatch(refreshDetailViewData(isRefresh));
        },
        refreshDetailViewListViewData: (isRefresh) => {
            dispatch(refreshDetailViewListViewData(isRefresh));
        },
        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        },
        getAppCustomDrawer: (isOpen, dialogName, actionType, style, data, headerProps, minimizable) => {
            dispatch(getAppCustomDrawer(isOpen, dialogName, actionType, style, data, headerProps, minimizable));
        }, getCallDrawer: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getCallDrawer(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
        isDetailViewSet: (isRefresh) => {
            dispatch(isDetailViewSet(isRefresh));
        },
        detailViewIsMounted: (isRefresh) => {
            dispatch(detailViewIsMounted(isRefresh));
        },
    }
};

function getCustomLink(props, recordId) {
    let linkUrl = '#';
    try {
        if (isValidParam(props)) {
            let object = props.object;
            let field = props.field;
            let isLookup = getBooleanParam(field.is_lookup_field);
            if (isValidParam(field)) {
                if (isLookup) {
                    if (object == constants.ACCOUNTS_OBJECT || object == constants.CONTACTS_OBJECT || object == constants.OPPORTUNITIES_OBJECT
                        || object == constants.CASES_OBJECT || object == constants.PROJECTS_OBJECT || object == constants.ISSUES_OBJECT
                        || object == constants.SOLUTIONS_OBJECT || object == constants.CUSTOM_TABLE1_OBJECT || object == constants.CUSTOM_TABLE2_OBJECT
                        || object == constants.CUSTOM_TABLE3_OBJECT || object == constants.CUSTOM_TABLE4_OBJECT
                        || object == constants.PRODUCTS_OBJECT) {
                        linkUrl = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
                    }
                }
            }
        }
    } catch (error) {
        console.error("Error in 'detailViewFields.js -> getCustomLink()':" + error);
    }
    return linkUrl;
}

function openRingCentralWebWidget() {
    var rcs = document.createElement("script");

    rcs.src = "https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js";
    rcs.id = "ring-central_widget_adapter";
    var rcs0 = document.getElementsByTagName("script")[0];
    if (rcs0.id != rcs.id) {
        rcs0.parentNode.insertBefore(rcs, rcs0);
    }
    if (window.RCAdapter) {
        window.RCAdapter.setMinimized(false);
    }
}

DetailViewFields = connect(mapStateToProps, mapDispatchToProps)(DetailViewFields);

export default DetailViewFields;
