import React from 'react';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getStringParam, getArrayParam, getBooleanParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { SFPopover, SFReportDatePicker } from './sfFormFields';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { constants } from '../../../services/constants/constants';
import { ListItemText, Checkbox, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useSelector } from 'react-redux';

const popoverStyle = { marginLeft: 0, cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', paddingLeft: 7, width: '100%', paddingRight: 7, marginLeft: 30 };

const ReportFilter = (props) => {
    const {rows,columns,footer} = useSelector((state)=> state.report.reportFilterData);
    const {fieldProps,reload,printButton,printEvent,printContentId, reportType, count, isCustom, isShowExcel, isShowGo, object, downloadExcel,printPdf} = props;
    
    const getFields = () => {
        let isActivityReport = false;
        if (reportType === "Tabular Report" || reportType === "Pivot Report") {
            isActivityReport = true;
        }
        let isCallReport = false;
        if (reportType == "Call Report") {
            isCallReport = true;
        }
        let isConversion = false;
        if (reportType === "Conversion Report" || reportType === "Conversion Matrix" || reportType === 'Conversion By Sales Rep' || reportType === "Activity Matrix") {
            isConversion = true;
        }
        let fields = [];
        let div = 3;
        if (count <= 6) {
            div = 2;
        }
        // let _fieldProps = getArrayParam(fieldProps);
        let divison = getIntParam(count % div) !== 0 ? getIntParam(~~(count / div)) + 1 : getIntParam(~~(count / div));
        if ((count === 8 || count === 7) && isCustom) {
            divison = 4;
        }
        let row1 = [];
        let row2 = [];
        let row3 = [];
        fieldProps.map((field, index) => {
            if (isValidParam(field)) {
                let temp = getField(field);
                if (isCustom && !isActivityReport) {
                    if (index < 2) {
                        row1.push(temp);
                    } else if (index > 1 && index < 6) {
                        row2.push(temp);
                    } else {
                        row3.push(temp);
                    }
                } else if (!isCustom && !isActivityReport) {
                    if (index < divison) {
                        row1.push(temp);
                    } else if (index > divison - 1 && index < (divison * 2)) {
                        row2.push(temp);
                    } else {
                        row3.push(temp);
                    }
                } else if (isActivityReport) {
                    row1.push(temp);
                }
            }
        });
        let obj = getStringParam(object);
        let actionElement = null;
        let marginLeft = "10px";
        if (row3.length === 0 && count === 7 && !isCustom) {
            marginLeft = '7px';
        } else if (row3.length === 0 && count === 7 && isCustom) {
            marginLeft = '10px';
        } else if (row3.length === 0 && count === 8 && isCustom) {
            marginLeft = '11px';
        } else if (row3.length === 0 && count === 6 && !isCustom) {
            marginLeft = '1px';
        } else if (isActivityReport) {
            marginLeft = '145px';
        }

        if (object === constants.OPERATIONS_DASHBOARD) {
            actionElement = <div style={{ float: 'left', marginLeft: '20px' }}>{getActions()}</div>
        }else if(reportType === "Pivot Report" ){
           
            actionElement = <div style={{ width: '100%', marginTop: '0.1%', paddingLeft:'70%' }}>{getActions()}</div>;
            
        } 
        else if(reportType === "Tabular Report" && fieldProps[0].date.defaultValue=='cust'){
           actionElement = <div style={{ width: '30%', marginTop: '0.1%', marginLeft:'58%'  }}>{getActions()}</div>;
            
        } 
        else {
            actionElement = <div style={{ float: 'right', marginLeft:reportType === "Tabular Report"? '50px': marginLeft, marginTop: '0.1%' }}>{getActions()}</div>;
        }
        if (!isActivityReport) {
            if (row3.length === 0 && ((!isCustom && divison !== row2.length) || (count === 8 && isCustom) || (count === 7 && isCustom) || (count === 6 && isCustom && reportType === "Activity Matrix"))) {
                row2.push(actionElement);
            } else {
                row3.push(actionElement);
            }
        } else if (isActivityReport) {
            row1.push(actionElement);
        }


        let fnlDiv = <div className="col-md-12" style={{ display: 'flex', float: 'left', marginLeft:'-7px' }}>{row1}</div>;
        let fnlDiv2 = <div className="col-md-12" style={{ display: 'flex', float: 'left', marginTop: '12px',marginLeft:'-7px' }}>{row2}</div>;
        let fnlDiv3 = <div className="col-md-12" style={{ display: 'flex', float: 'left', marginTop: '12px',marginLeft:'-7px' }}>{row3}</div>;
        fields.push(fnlDiv);
        fields.push(fnlDiv2);
        fields.push(fnlDiv3);
        return fields;
    }

    const getField = (fieldProps) => {
        let isCallFromReport = true;
        let isActivityReport = false;
        let isCallReport = false;
        if (reportType == "Call Report") {
            isCallReport = true;
        }
        let widthStyle = {width:'auto'};
        if (reportType === "Tabular Report" || reportType === "Pivot Report") {
            if(reportType === "Pivot Report"){
                widthStyle = {width:'100%'};

            }
            if(reportType === "Tabular Report" ){
                widthStyle = {width:'100%'};
              }
            isActivityReport = true;
        }
        let div = 3;
        if (count <= 6) {
            div = 2;
        }
        if (isCustom && reportType === 'Call Report') {
            div = 1;
        }
        let divison = getIntParam(count % div) !== 0 ? getIntParam(~~(count / div)) + 1 : getIntParam(~~(count / div));
        if ((count === 8 || count === 7 || (count === 6 && reportType === "Activity Matrix")) && isCustom) {
            divison = 4;
        }
        let width = 100 / divison + '%';
        var fullWidth = window.innerWidth - (100 + ((divison - 1) * 10));
        let widthInt = fullWidth / divison
        let field = null;
        let type = Object.keys(fieldProps)[0];
        let props = fieldProps[type];
        if (isActivityReport) {
            width = 100 / 10 + '%';
            isCallFromReport = false;
            widthInt = 250;
        }
        let multiSelectStyle = { marginLeft: 10 };
        let isConversion = false;
        if (reportType === "Conversion Report" || reportType === "Conversion Matrix" || reportType === 'Conversion By Sales Rep' || reportType === "Activity Matrix" || reportType === "Activities By Sales Rep") {
            isConversion = true;
            if (isCustom) {
                multiSelectStyle = { marginLeft: "51%" };
            }
            if (isCustom && reportType === "Activities By Sales Rep" && fieldProps.hasOwnProperty("date")) {
                isActivityReport = true;
            }
            if (isCustom && reportType === "Activities By Sales Rep" && fieldProps.hasOwnProperty("multiSelect") && fieldProps.multiSelect.id === 'userNames') {
                multiSelectStyle = { marginLeft: "51.2%" };
            }
            if (isCustom && (reportType === "Activities By Sales Rep" || reportType === "Activity Matrix") && fieldProps.hasOwnProperty("multiSelect") && fieldProps.multiSelect.id === 'activityTypes') {
                multiSelectStyle = { marginLeft: "0.7%" };
            }
        }
        if (isValidParam(type) && isValidParam(props)) {
            if (type === 'date') {
                field = <div>
                    <SFReportDatePicker
                        isConversionReport={isConversion}
                        isCallFromReport={isCallFromReport}
                        label={getLocalizedStrings().label.REPORT.DATE_RANGE}
                        variant="outlined"
                        type={"dropdown"}
                        {...getObjectParam(props)}
                        fieldWidth={widthInt}
                        isActivityReport={isActivityReport}
                        isCallReport={isCallReport}
                        style={{ ...styles.sfFormFieldContainer, cursor: "pointer", fontSize: '14px', color: '#717171', height: '47px', paddingLeft: 4, paddingRight: 4, ...getObjectParam(props.style) }} />
                </div>
            } else if (type === 'dropdown') {
                field = <SFPopover type="dropdown" {...getObjectParam(props)} style={{ ...popoverStyle, minWidth: width, width: width, ...getObjectParam(props.style) }} fieldWidth={widthInt} />
            } else if (type === 'multiSelect') {
                props.value = typeof props.value === 'string' ? props.value.split(',') : props.value;
                field = <FormControl variant="outlined" size="small" style={multiSelectStyle}>
                    <InputLabel id={"sf-" + props.id} className="sf-sfformresults">{props.label}</InputLabel>
                    <Select
                        key={props.id}
                        multiple
                        renderValue={(selected) => selected.join(', ')}
                        {...getObjectParam(props)}
                        style={{ ...styles.sfFormFieldContainer, minWidth: widthInt, maxWidth: widthInt, cursor: "pointer", height: '40px',color: '#242322', paddingLeft: 4, paddingRight: 4, ...getObjectParam(props.style) }}
                    >
                        {
                            props.list.map((listValue, listValueIndex) => {
                                return <MenuItem id={listValue.id} key={listValue.id} value={listValue.id} title={listValue.label}>
                                    <Checkbox checked={props?.value.filter(e => e === listValue.id).length > 0 ? true : false} />
                                    <ListItemText primary={listValue.label} />
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            }
        }
        return field;
    }

    const getActions = () => {
        let actions = [];
        let buttonStyle = { ...styles.listViewPrimaryButton, backgroundColor: 'inherit', color: '#717171', border: '0px', minWidth: '40px', marginTop: 8 };
        if (isShowGo) {
            actions.push(<Button
                onClick={reload}
                style={{
                    ...styles.primaryButton,
                    minWidth: '56px', width: '19%',
                    marginTop: 8
                }}
                title={getLocalizedStrings().label.REPORT.GO}
            > {getLocalizedStrings().label.REPORT.GO}</Button>);
        } else {
            actions.push(<Button
                startIcon={<Icon>autorenew</Icon>}
                onClick={reload}
                style={buttonStyle}
                title={getLocalizedStrings().label.REPORT.REFRESH}></Button>);
        }
        if (!isValidParam(printButton)) {
            actions.push(<iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }}></iframe>);
            actions.push(<Button
                startIcon={<Icon>print</Icon>}
                onClick={isValidParam(printEvent) ? printEvent : printFile}
                style={buttonStyle}
                title={getLocalizedStrings().label.REPORT.PRINT}></Button>);
        } else {
            actions.push(printButton);
        }
        if (isShowExcel) {
            actions.push(<Button
                startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
                onClick={(e)=>downloadExcelFile(e)}
                style={buttonStyle}
                title={getLocalizedStrings().label.REPORT.EXCEL}
            ></Button>)
        }
        return actions;
    }

    const downloadExcelFile = (e) => {
        if (e !== null && e !== undefined) {
            downloadExcel(e);
        }
    }

    const generateHeadersToPrint = (rows,columns) => {
        let cells = '', temp;
        let tempColumns = getArrayParam(columns);
        let _columns = tempColumns.filter(f => {
            if (!f.is_hidden) {
                temp = f;
            }
            return temp;
        });
        _columns.forEach(f => {
            cells = cells + '<th style="border:1px solid black;padding:5px"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const generateRowsToPrint = (records,fields) => {
        let rows = getArrayParam(records);
        let columns = getArrayParam(fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.forEach((rowObject, rowIndex) => {
                let innerCells = '';
                columns.forEach((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        if(rowObject[columnObject.name] !== undefined)
                        {
                            innerCells = innerCells + '<td style="border:1px solid black;padding:5px;padding-left:10px;">' + rowObject[columnObject.name] + ' </td>';
                        }else if(rowObject[columnObject.name] == undefined){
                            innerCells = innerCells + '<td style="border:1px solid black;padding:5px;padding-left:10px;">' + ' ' + ' </td>';
                        }
                    }
                })
                cells = cells + ('<tr>' + innerCells + ' </tr>');
            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '" style="border:1px solid black;padding:5px;"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    const generateFooterToPrint = (rows,footer) =>{
        let cells = '', temp;
        let _rows = rows;
        let totalValue = 0;
         _rows.map((ele)=>{
            totalValue = totalValue + ele.count;
        })
        let tempFooter = getArrayParam(footer);
        let _footer = tempFooter.filter(f => {
            if (!f.is_hidden) {
                temp = f;
            }
            return temp;
        });
        _footer.forEach(f => {
            if(f.label){
                cells = cells + '<th style="border:1px solid black;padding:5px;"> ' + f.label + ' </th>';
            }else{
                cells = cells + '<th style="border:1px solid black;padding:5px;"> ' + totalValue + ' </th>';
            }
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    const printFile = () => {
        if(props.reportType === "Activity Matrix"){
            if (getArrayParam(rows).length > 0 && getArrayParam(columns).length > 0) {
                let headerComponents = generateHeadersToPrint(rows,columns);
                let rowComponents = generateRowsToPrint(rows,columns);
                let footerComponent = generateFooterToPrint(rows,footer);
                let htmlTable = '<table style="border:1px solid black;padding:5px;border-collapse:collapse;"><thead> '+'<tr><th colspan="2" style="text-align:center;">'+ reportType+'</th></tr>' + headerComponents + ' </thead><tbody>' + rowComponents + ' </tbody><thead>' + footerComponent + '  </thead> </table>' ;
                let htmlData = htmlTable;
                if (isValidParam(htmlData) && htmlData !== '') {
                    var ifrmae = document.getElementById("ifmcontentstoprint");
                    ifrmae.style.display = 'block'
                    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                    ifrmae.style.display = 'block'

                    pri.document.open();
                    pri.document.write(htmlData);
                    pri.document.close();
                    pri.focus();
                    pri.print();
                    ifrmae.style.display = 'none';
                }
            }
        }else{
            let _printContentId = getStringParam(printContentId);
            if (_printContentId !== '') {
                let content = document.getElementById(_printContentId);
                let ifrmae = document.getElementById("ifmcontentstoprint");
                ifrmae.style.display = 'block'
                let pri = document.getElementById("ifmcontentstoprint").contentWindow;
                ifrmae.style.display = 'block'

                pri.document.open();
                pri.document.write(content.innerHTML);
                pri.document.close();
                pri.focus();
                pri.print();
                ifrmae.style.display = 'none';
            }
        }
    }

    return <div style={{ padding: 0, marginBottom: '12px' }}>
        {getFields()}
    </div>
}

export default ReportFilter;
