import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "rc-tree/assets/index.css";
import Tree, { TreeNode } from "rc-tree";
import { styles } from "../../../services/constants/styles";
import { constants } from "../../../services/constants/constants";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { isValidParam, getStringParam, getIntParam, getBooleanParam, getArrayParam, } from "../../../services/helper/parameterVerifier";
import { groupByObjectArrayByProperty } from '../../../services/helper/utils';
import { getAppDialog, setAppDialogActions, } from "../../../services/actions/appContainerActions";
import { endPoints } from "../../../services/constants/endPoints";
import * as HTTPClient from "../../../services/helper/httpClient";
import { doRefreshAssetsTree, updateDocumentLibrary, updateSelectedNode, updateExpandedNode, updateisAssetsUpdated, updateInBodyAction } from "../../../services/actions/documentLibraryActions";
import * as sfDialogs from "../components/sfDialogs";
import { getAssetsTree, getAssetsTreeItem, } from "../../../services/helper/common";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';

const AssetsMenu = ({ defaultSelected, callFrom, files, setLoading, selectedLibrary, callBack, setExpandedNode, expandedNods, isAddDocument, isRenameFolder, isAddFolder, menuData }) => {

	const [treeData, setTreeData] = useState([]);
	const [autoExpandParent, setAutoExpandParent] = useState(true);
	const [expandedKeys, setExpandedKeys] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [isSelected, setIsSelected] = useState(false);
	const [lastSelectedKey, setLastSelectedKey] = useState([]);
	const [mounted, setMounted] = useState(true);
	var defaultSelectedKeys = [];
	let maxChildLimit = 500;
	const app = useSelector((state) => state.app);
	const documentLibrary = useSelector((state) => state.documentLibrary);
	const refreshAssets = useSelector((state) => state.header.refreshAssets);
	const appContainer = useSelector((state) => state.appContainer);
	const userlist = useSelector((state) => state.userlist);

	const dispatch = useDispatch();

	useEffect(() => {
		let tempTreeData = menuData.nodeData;
		defaultSelectedKeys = menuData.selectedKeys;
		setTreeData(tempTreeData);
		setSelectedKeys(menuData.selectedKeys);
		setIsSelected(true);
		setExpandedKeys(expandedNods);
		setLastSelectedKey(menuData.selectedKeys);
	}, []);

	useEffect(() => {
		setExpandedKeys(expandedNods);
	}, [expandedNods]);
	useEffect(() => {
		let tempTreeData = menuData.nodeData;
		defaultSelectedKeys = selectedKeys;
		setTreeData(tempTreeData);
		setLastSelectedKey(menuData.selectedKeys);
		setIsSelected(true);
	}, [menuData]);

	const onDragStart = (info) => {
	}

	const onDragEnter = (info) => {
		setExpandedKeys(info.expandedKeys);
	}

	const onDrop = (info) => {
		const dropKey = info.node.key;
		const dragKey = info.dragNode.key;
		let sharedId = '';
		let parentId = 0;
		let _isShared = false;
		let _maindata = [...documentLibrary.mainData];
		let _finalMaindata = [];

		_maindata.forEach((item) => {
			if (item?.key == dragKey) {
				sharedId = item?.shareId;
			}
		});
		_maindata.forEach((item) => {
			if (item?.key == dropKey) {
				if (item?.isShared) {
					_isShared = true;
				}
				parentId = item?.childId;
			}
		})
		if (!_isShared) {


			let params = {
				id: dragKey,
				parentId: parentId,
				shareChildId: sharedId,
			}

			var promise = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.ASSETS_MENU_UPDATE, params));
			promise.then((response) => {
				if (response.status == 0) {
					_finalMaindata = _maindata.map((item) => {
						if (item?.key == dragKey) {
							item.parentId = parentId;
						}
						return item;
					});

					dispatch(updateDocumentLibrary([..._finalMaindata]));
					dispatch(updateisAssetsUpdated(true));
				}
			});

		}
	}

	const onExpand = (_expandedKeys) => {
		let newKey = null;
		_expandedKeys.map((i) => {
			let index = expandedKeys.findIndex((j) => j == i);
			if (index == -1) {
				newKey = i;
			}
		});
		if (newKey != null) {
			callBack('nodeExpand', newKey);
		}
		dispatch(updateExpandedNode(_expandedKeys))
		setExpandedNode(_expandedKeys);
		setAutoExpandParent(false);
	}

	const handleOpenLink = (doc) => {
		window.open(`${doc.url}`, `${doc.name}`)
	}

	const onActionsElementClick = (value, item) => {
		if (value == 'add-folder') {
			handleAdd(item);
		}
		if (value == 'edit') {
			onEdit(item.t_type == 'folder' ? 'folder' : 'file', item);
		}
		if (value == 'share') {
			onShare(item);
		}
		if (value == 'push') {
			onPush(item);
		}
		if (value == 'download') {
			handleDownload(item);
		}
		if (value == 'open') {
			handleOpenLink(item);
		}
		if (value == 'add-file') {
			handleUpload(item);
		}
		if (value == 'delete') {
			if (item.t_type == 'folder') {
				onDel();
			} else {
				delDocument(item.t_type, item.key);
			}
			if (selectedLibrary == constants.IMAGES_LIBRARY) {
				delImage(item.id, item.key);
			}
		}
	}

	const getTitleLableActionsElement = (value, item) => {
		let titleVsIcon = {
			'title': { title: item.title, icon: "N/A" },
			'add-folder': { title: getLocalizedStrings().label.COMMON.ADD_FOLDER, icon: "fa fa-plus" },
			'edit': { title: getLocalizedStrings().label.COMMON[item.t_type !== "external link" ? 'RENAME' : 'EDIT'], icon: "fa fa-pencil-square-o" },
			'share': { title: getLocalizedStrings().label.COMMON.SHARE, icon: "fas fa-share" },
			'push': { title: getLocalizedStrings().label.COMMON.PUSH, icon: "fa fa-location-arrow" },
			'download': { title: getLocalizedStrings().label.COMMON.DOWNLOAD, icon: "fa fa-download" },
			'open': { title: getLocalizedStrings().label.COMMON.OPEN, icon: "fa fa-expand" },
			'add-file': { title: getLocalizedStrings().label.COMMON.ADD_FILE, icon: "fa fa-upload" },
			'delete': { title: getLocalizedStrings().label.COMMON.DELETE, icon: "fa fa-trash" }
		}
		let iconStyle = { color: '#808080', marginRight: '7px', marginLeft: '7px' };
		let separetorStyle = { backgroundColor: '#ffb951', paddingLeft: '1px' };
		return (
			<span>
				<span style={iconStyle} onClick={() => onActionsElementClick(value, item)} title={titleVsIcon[value].title}>{value === 'title' ? item[value] : <i className={titleVsIcon[value].icon} aria-hidden="true"></i>}</span>
				<span style={separetorStyle} />
			</span>
		)
	};
	const getTitleLableActionsElementRow = (titleOptions, item) => {
		console.log(item)
		return (<span style={{ width: '100%' }}>
			{
				titleOptions.map(val => {
					return (<span>{getTitleLableActionsElement(val, item)}
					</span>)
				})}

		</span>)
	};
	const isReadAccessOnly = (selectedItem) => {
		let _mainDataArr = [...documentLibrary.mainData];
		let _selectedPId = 0;
		if (selectedItem) {
			_selectedPId = selectedItem?.parentId || 0;
		}
		let _mainDataObj = _mainDataArr.find((item) => item.childId === _selectedPId)
		if (selectedItem?.accessType === 'Read') {
			return true;
		}
		if (selectedItem && selectedItem?.parentId !== 0) {
			return isReadAccessOnly(_mainDataObj);
		}
		return false;
	}
	const isSharedFolder = (selectedItem) => {

		let _mainDataArr = [...documentLibrary.mainData];
		let _mainDataObj = _mainDataArr.find((item) => item.childId === selectedItem.parentId)
		if (selectedItem?.key == -99) {
			return true;
		}
		if (selectedItem?.parentId !== 0) {
			return isSharedFolder(_mainDataObj);
		}
		return false;
	};
	const getTitleLableActions = (item) => {
		console.log(item)
		let titleOptions = ['title'];
		if (isValidParam(item) && item.key != -99) {
			let _isSharedFolder = isSharedFolder(item);
			let _isReadAccessOnly = isReadAccessOnly(item);
			if (item.t_type == 'folder' && !_isSharedFolder) {
				titleOptions.push('add-folder');
			}
			if (app.me.is_franchisor || app.me.id == item.userId || app.me.is_tenant_admin) {
				titleOptions.push('edit');
			}
			if (app.me.t_name == "Admin" && app.me.is_franchisor && item.t_type !== "external link") {
				titleOptions.push('push', 'share');
			}
			if (item.t_type === "external link") {
				titleOptions.push('open');
			} else {
				titleOptions.push('download');
			}
			if (item.t_type == 'folder') {
				titleOptions.push('add-file');
			}
			if (!app.me.is_franchisee || item.userid === app.me.id) {
				titleOptions.push('delete')
			}
			if ((!app.me.is_franchisor && app.me.t_name !== 'Admin' && callFrom == constants.SEND_SMS && !app.me.is_my_clientid) || callFrom == 'editor') {
				titleOptions = titleOptions.filter((option) => option != 'share' && option != 'push');
			}
			if ((item.isShared && app.me.is_franchisor) || _isReadAccessOnly) {
				titleOptions = titleOptions.filter((option) => option == 'title' || option == 'download' || option == 'open');
			}
			if (selectedLibrary == constants.IMAGES_LIBRARY) {
				titleOptions = ['title'];
				if (item.t_type == 'folder' && callFrom != constants.SEND_SMS && callFrom != 'editor') {
					if (!item.isShared) {
						titleOptions.push('add-file');
					}
				} else {
					if (!item.isShared && (app.me.id == item.userId || app.me.is_tenant_admin)) {
						titleOptions.push('edit');
						if (app.me.is_franchisor && app.me.is_tenant && app.me.is_my_clientid && callFrom != constants.SEND_SMS && callFrom != 'editor') {
							titleOptions.push('share');
						}
						titleOptions.push('delete')
					}
				}
			}
			if (callFrom === 'editor') {
				titleOptions = titleOptions.filter((option) => option != 'delete');
			}
		}
		return getTitleLableActionsElementRow(titleOptions, item);
	}

	const onSelect = (info) => {
		if (isValidParam(info) && info.length > 0) {
			let _mainDataArr = [...documentLibrary.mainData];
			let isSelectedFolder = false;
			let isselectDialouge = false;
			let item = _mainDataArr.find(f => {
				return parseInt(f.key) == parseInt(info[0])
			});
			if (item?.t_type == 'folder' && item.isLeaf == false) {
				isSelectedFolder = true;
			}
			if (callFrom == constants.SEND_SMS) {
				isselectDialouge = true;
			}
			setSelectedKeys(info);
			setIsSelected(isSelectedFolder);
			dispatch(updateSelectedNode(info[0]));
			setLastSelectedKey(info);
			if ((isSelectedFolder && !isselectDialouge) || isselectDialouge) {
				callBack('nodeSelecte', parseInt(info[0]));
			}
		}
	};



	const handleDownload = (item) => {
		var treeArr = treeData;
		let selectedKey = item.key;
		let documentID = item.id;
		let documentName = item.name;
		let isShared = item.isShared;
		let doc_name = item.name;
		let t_type = item.t_type;
		let url = endPoints.ATTACHMENT.DOWNLOAD;
		if (documentID != '' && documentID != null) {
			var formData = new FormData();
			formData.append('input_param', JSON.stringify({ 'id': documentID, isShared: isShared }));
			//if ((t_type != 'pdf' && t_type != "jpg" && t_type != "jpeg" && t_type != "gif" && t_type != "png")) {
			HTTPClient.postFormDataDownloadFile(url, formData, documentName);
			//}
			/*if ((t_type === 'pdf' || t_type == "jpg" || t_type == "jpeg" || t_type == "gif" || t_type == "png")) {
				let params = { 'id': documentID, 'calling_from': "library" };
				var promise = Promise.resolve(HTTPClient.post(endPoints.ATTACHMENT.GET_ATTACHMENT_URL, params));
				promise.then((response) => {
					window.open(response.attachmentUrl);
				});
			}*/
		} else {
			let alertMsg = 'Not able to download the file.\nPossible reasons may be either there is no file in that folder or the selected file is corrupted.';
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
		}
	}

	const handleUpload = (item) => {
		let parentId = item.childId;
		let childTree = item.children;
		let childId = item.maxChildId + 1;
		let shareId = getStringParam(item.shareId);
		if (childTree.length > maxChildLimit) {
			let alertMsg = getLocalizedStrings().message.ATTACHMENT.MAX_LIMIT;
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);

		} else {

			let data = {
				parentId: parentId,
				childId: childId,
				selectedLibrary: selectedLibrary,
				shareId: shareId,
			};
			if (callFrom == 'editor' || callFrom == constants.SALES_EMAIL) {
				dispatch(updateInBodyAction('file', 'add', data))
			} else {
				let dialogTitle = getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE;
				if (selectedLibrary == constants.IMAGES_LIBRARY) {
					dialogTitle = getLocalizedStrings().message.DOCUMENT_LIBRARY.UPLOAD_IMAGE;
				}
				dispatch(getAppDialog(true, constants.DOCUMENT_UPLOAD_DIALOG, dialogTitle, 'documents', data, callFrom));
			}
		}
	}

	const onCheck = (_checkedKeys, info) => {
		setCheckedKeys(_checkedKeys);
	};

	const onEdit = (type, item) => {
		setTimeout(() => {
			let selectKey = item.key;
			var treeArr = treeData;
			let params = {};
			params.key = selectKey;
			params.treeArray = treeArr;
			params.selectedKey = selectKey;
			params.maxChildId = item.maxChildId;
			let title = item.name;
			let fileId = item.id;
			let shareId = item.shareId;
			if (callFrom == 'editor' || callFrom == constants.SALES_EMAIL) {
				dispatch(updateInBodyAction(type, 'rename', params))
			} else {
				let dialogTitle = getLocalizedStrings().label.DOCUMENT_LIBRARY.RENAME_FILE;
				if (item.t_type == "external link") {
					dialogTitle = getLocalizedStrings().label.DOCUMENT_LIBRARY.LIBRARY_RENAME_FILE;
				} else if (type == "folder") {
					params.parentId = item.parentId;
					dialogTitle = getLocalizedStrings().label.DOCUMENT_LIBRARY.RENAME_FOLDER;
				}
				if (selectedLibrary == constants.IMAGES_LIBRARY) {
					dialogTitle = getLocalizedStrings().label.COMMON.RENAME + ' ' + getLocalizedStrings().label.DOCUMENT_LIBRARY.IMAGE;
					let fileName = title.substring(0, title.lastIndexOf('.'));
					let fileExt = title.substring(title.lastIndexOf('.') + 1, title.length);
					params.title = fileName;
					params.ext = fileExt;
					params.id = fileId;
					params.selectedKey = documentLibrary.selectedNode;
				} else {
					params.title = title;
					params.t_type = item.t_type;
					params.url = item.url;
				}
				params.selectedLibrary = selectedLibrary;
				dispatch(getAppDialog(true, constants.ASSETS_FOLDER_ADD_DIALOG, dialogTitle, 'rename', params, callFrom));
			}
		}, 0);
	};


	const delDocument = (type, key) => {
		const data = [...treeData];
		let documentName = '';
		let deleteParentId = 0;
		let params = {};
		params.id = key;
		dataLoop(data, key, (item) => {
			params.documentId = item.id;
			params.is_external_link = item.t_type === 'external link'
			documentName = item.name;
			deleteParentId = item.parentId;
		});
		let parentFolderKey = 0;
		getAssetsTreeItem(data, (item) => {
			if (deleteParentId == item.childId) {
				parentFolderKey = getStringParam(item.key);
			}
		});
		sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.ATTACHMENT.CLICK_TO_DELETE + documentName + '.', () => delDocumentOnConfirm(data, key, parentFolderKey, params, type), null);

	}

	const delDocumentOnConfirm = (data, key, parentFolderKey, params, type) => {
		var promise = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.ATTACHMENT_DELETE, params));
		if (isValidParam(promise)) {
			promise.then((response) => {
				if (response.status == 0) {
					if (response.data.isDeleted) {
						let _maindata = [...documentLibrary.mainData];
						let _finalMmaindata = _maindata.filter((item) => {
							if (item?.key != key) {
								return item;
							}
						});

						var selectKeyArry = [parentFolderKey];
						dispatch(updateDocumentLibrary([..._finalMmaindata]));
						dispatch(updateSelectedNode(parentFolderKey));
						setSelectedKeys(selectKeyArry);
						setLastSelectedKey(selectKeyArry);
						dispatch(updateisAssetsUpdated(true));
						dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT[type === 'external link' ? 'DOCUMENT_LINK_DELETE_SUCCESSFULLY' : 'FILE_DELETE_SUCCESSFULLY'], styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
					}
				} else {
					dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
				}
			})
		}
	}

	const onDel = () => {
		let deteleFolderKey = defaultSelectedKeys != undefined && defaultSelectedKeys.length > 0 ? defaultSelectedKeys : selectedKeys[0];
		const data = [...treeData];
		let params = {};
		params.id = deteleFolderKey;

		let delFolChild = [];
		let folderTitle = '';
		let selectedKey = [];
		let delParentId = '';
		dataLoop(data, deteleFolderKey, (item) => {
			delFolChild = item.children;
			folderTitle = item.name;
			delParentId = item.parentId;
		});

		getAssetsTreeItem(data, (item) => {
			if (item.childId == delParentId) {
				selectedKey = getStringParam(item.key);
				item.title = item.name;
			}
		})

		if (delFolChild.length > 0 || selectedKey == 0) {
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, folderTitle + ' ' + getLocalizedStrings().message.ATTACHMENT.CANNOT_BE_DELETED, null)
		} else {
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.ATTACHMENT.CLICK_TO_DELETE + folderTitle + '.', () => onDelOnConfirm(deteleFolderKey, selectedKey, params), null);
		}
	};

	const onDelOnConfirm = (deteleFolderKey, selectedKey, params) => {
		var promise = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.ASSETS_FOLDER_DELETE, params));
		if (isValidParam(promise)) {
			promise.then((response) => {
				if (response.data.isDelete) {
					let _maindata = [...documentLibrary.mainData];
					let _finalMmaindata = _maindata.filter((item) => {
						if (item?.key != deteleFolderKey) {
							return item;
						}
					});

					var selectKeyArry = [selectedKey];
					dispatch(updateDocumentLibrary([..._finalMmaindata]));
					dispatch(updateSelectedNode(selectedKey));
					setSelectedKeys(selectKeyArry);
					setLastSelectedKey(selectKeyArry);
					dispatch(updateisAssetsUpdated(true));
				}
				dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.DELETE_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
			})
		}
	}

	const delImage = (id, key) => {
		try {
			const data = [...treeData];
			let selectedKey = [];
			let delParentId = '';
			dataLoop(data, key, (item) => {
				delParentId = item.parentId;
			});
			getAssetsTreeItem(data, (item) => {
				if (item.childId == delParentId) {
					selectedKey = getStringParam(item.key);
					item.title = item.name;
				}
			})
			sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.DOCUMENT_LIBRARY.CONFIRM_DELETE_MSG, (e) => delImageOnConfirm(e, id, key, data, selectedKey), null);
		} catch (error) {
			console.error("Error on assetsMenu.js >> delImage: " + error);
		}
	}

	const delImageOnConfirm = (event, id, key, data, selectedKey) => {
		let params = {
			id: id,
			key: key,
		}
		var promise1 = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.DELETE, params));
		promise1.then((response) => {
			if (response.status == 0) {
				if (response.data.is_deleted) {
					let _maindata = [...documentLibrary.mainData];
					let _finalMmaindata = _maindata.filter((item) => {
						if (item?.key != key) {
							return item;
						}
					});
					dispatch(updateDocumentLibrary([..._finalMmaindata]))
				}



				var selectKeyArry = [selectedKey];
				setTreeData(data);
				setSelectedKeys(selectKeyArry);
				setLastSelectedKey(selectKeyArry);
				// selectedObject, callFrom, treeData, maxChildId, selectedKey,
				dispatch(updateisAssetsUpdated(true));
				dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.IMAGE_DELETE_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
			}
			else if (response.status == -1 && response.error.message != "") {
				showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
			}

		});
	}

	const onPush = (item) => {
		let pushId = '';
		let folderName = '';
		let folderType = false;
		if (item.children) {
			folderName = item.name;
			folderType = true;
			let childArray = item.children || [];
			treeLoop(childArray, (itemObj) => {
				if (itemObj.t_type != 'folder') {
					if (pushId != '') {
						pushId = pushId + ',' + itemObj.id;
					} else {
						pushId = itemObj.id;
					}
				}
			})
		} else {
			pushId = item.id;
		}
		if (pushId != '' && pushId != null) {
			let data = {
				documentID: pushId,
				folderName: folderName,
				folderType: folderType,
			}
			dispatch(getAppDialog(true, constants.DOCUMENT_PUSH_DIALOG, getLocalizedStrings().label.DOCUMENT_PUSH.DIALOG_TITLE, treeData, data, callFrom));
		} else {
			let alertMsg = getLocalizedStrings().message.ATTACHMENT.EMPTY_FOLDER_ERROR_PUSH;
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
		}
	}

	const onShare = (item) => {
		try {
			let shareId = '';
			let folderName = '';
			let folderType = false;
			let dataTree = [...treeData];
			let selectedKey = item.key;
			let rootShareId = 0;
			let shareChildId = 0;
			let sharedId = 0;
			let shareChildByRecord = {};

			if (item.children) {
				folderName = item.name;
				folderType = true;
				let childArray = item.children || [];
				rootShareId = selectedKey;
				shareChildId = item.childId;
				sharedId = item.shareId;
				if (folderType) {
					if (shareId != '') {
						shareId = shareId + ',' + selectedKey;
					} else {
						shareId = selectedKey;
					}
				}
				shareChildByRecord[selectedKey] = getStringParam(item.shareId);
				getAssetsTreeItem(childArray, (item) => {
					if (shareId != '') {
						shareId = shareId + ',' + item.key;
					} else {
						shareId = item.key;
					}
					shareChildByRecord[item.key] = getStringParam(item.shareId);
				});
			} else {
				if (selectedLibrary == 'images') {
					shareId = item.id;
					rootShareId = item.id;
				} else {
					shareId = item.key;
					rootShareId = selectedKey;
				}
				shareChildId = item.childId;
				sharedId = item.shareId;
				folderName = item.name;
				shareChildByRecord[selectedKey] = getStringParam(item.shareId);
			}
			if (shareId != '' && shareId != null) {
				let data = {
					documentID: shareId,
					folderName: folderName,
					folderType: folderType,
					isShare: true,
					rootShareId: getIntParam(rootShareId),
					shareChildId: getIntParam(shareChildId),
					sharedId: getIntParam(sharedId),
					selectedLibrary: selectedLibrary,
					shareChildByRecord: shareChildByRecord,
				}
				let label = getLocalizedStrings().label.COMMON.SHARE_WITH;
				dispatch(getAppDialog(true, constants.DOCUMENT_SHARE_DIALOG, label, treeData, data, callFrom));
			} else {
				let alertMsg = getLocalizedStrings().message.ATTACHMENT.EMPTY_FOLDER_ERROR_PUSH;
				sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
			}
		} catch (error) {
			console.error("Error on assetsMenu.js >> onShare(): " + error);
		}
	}

	const handleAdd = (item) => {
		let title = getLocalizedStrings().label.DOCUMENT_LIBRARY.NEW_FOLDER;
		var treeArr = treeData;
		let count = 0;
		var childTree = item.children;
		let folderChildId = item.childId;
		if (childTree.length > maxChildLimit) {
			let alertMsg = getLocalizedStrings().message.ATTACHMENT.MAX_LIMIT;
			sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);

		} else {
			getAssetsTreeItem(childTree, (itemObj) => {
				if ((folderChildId == itemObj.parentId) && itemObj.name.toLowerCase().startsWith(title.toLowerCase())) {
					count = count + 1;
				}
			});
			if (count > 0) {
				title = title + ' ' + count;
			};

			let params = {};
			params.title = title;
			params.key = 'NewFolder';
			params.treeArray = treeArr;
			params.selectedKey = item.key;
			params.parentId = item.childId;
			params.shareId = getStringParam(item.shareId);
			params.childId = item.maxChildId + 1;
			if (callFrom == 'editor' || callFrom == constants.SALES_EMAIL || callFrom == constants.SEND_SMS) {
				dispatch(updateInBodyAction('folder', 'add', params))
			} else {
				dispatch(getAppDialog(true, constants.ASSETS_FOLDER_ADD_DIALOG, getLocalizedStrings().label.COMMON.ADD_FOLDER, null, params, callFrom));
			}


		}
	}

	const prepareChildNodes = (data) => {
		let isShowFile = true;
		if (callFrom == 'editor' || callFrom == constants.SALES_EMAIL || callFrom == constants.SEND_SMS) {
			isShowFile = false;
		}
		if (isValidParam(data)) {
			return data.map((item) => {
				let title = item.title;
				if (selectedKeys[0] == item.key) {
					title = getTitleLableActions(item)
				}
				if (item.accessType !== 'None') {
					if (item.children) {
						return <TreeNode icon={getDocumentImage(item.t_type, item.onlyCorpAccess, item.isShared, item.shareId)} key={item.key} title={title} isLeaf={item.isLeaf} style={{ paddingTop: '3px' }} >{prepareChildNodes(item.children)}</TreeNode>;
					}
					if (isShowFile) {
						return <TreeNode icon={getDocumentImage(item.t_type, item.onlyCorpAccess ,item.isShared, item.shareId)} key={item.key} title={title} style={{ paddingTop: '3px 8px', height: 'auto' }} />;
					}
				}
			});
		}
	};




	const allowEditing = true;
	let w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName('body')[0],
		clientHeight = w.innerHeight - 50;
	let assetsContainerHeight = clientHeight - 305;
	if (isValidParam(callFrom) && callFrom == 'menu') {
		assetsContainerHeight = clientHeight - 187;
	} else if (isValidParam(callFrom) && callFrom == 'drawer') {
		assetsContainerHeight = clientHeight - 150;
	} else if (isValidParam(callFrom) && callFrom == constants.SEND_SMS) {
		assetsContainerHeight = clientHeight - 320;
	}
	let isDraggable = true;
	if (callFrom == constants.SEND_SMS) {
		isDraggable = false;
	}
	if (selectedLibrary == constants.IMAGES_LIBRARY) {
		isDraggable = false;
	}
	return (
		<div style={{ border: 'solid 1px #d9d9d9', paddingLeft: '2px', paddingTop: '2px', height: 'auto', maxHeight: assetsContainerHeight, overflowY: 'auto', minHeight: assetsContainerHeight }}>
			<div>
				<Tree
					expandedKeys={expandedKeys}
					onExpand={onExpand}
					autoExpandParent={autoExpandParent}
					draggable={isDraggable}
					onDragStart={onDragStart}
					onDragEnter={onDragEnter}
					onDrop={onDrop}
					checkable={false}
					selectable={true}
					selectedKeys={selectedKeys}
					onSelect={onSelect.bind()}
					checkedKeys={checkedKeys}
					onCheck={onCheck}
				>
					{prepareChildNodes(treeData)}
				</Tree>
			</div>
		</div>
	);
}

export default AssetsMenu;

const dataLoop = (data, key, callback) => {
	if (isValidParam(data) && getArrayParam(data).length > 0) {
		data.forEach((item, index, arr) => {
			if (item.key == key) {
				callback(item, index, arr);
				return;
			}
			if (item.children) {
				dataLoop(item.children, key, callback);
			}
		});
	}
};

const treeLoop = (childArray, callback) => {
	childArray.forEach((item, index) => {
		if (item.children) {
			treeLoop(item.children, callback);
		} else {
			callback(item);
			return;
		}
	});
}

function getDocumentImage(ext, onlyCorpAccess,isShared, shareId) {
	let imgName = "";
	let faImgName = "";
	shareId = shareId ? shareId : '0';
	const mutiIndex = shareId.toString().indexOf(",");
	let multiId = false;
	if (mutiIndex > -1) {
		let shareIdArr = shareId.split(',');
		shareIdArr = shareIdArr.map(m => {
			return getIntParam(m);
		});
		if (shareIdArr.length > 0) {
			multiId = true;
		}
	}
	if (ext != 'folder' && !onlyCorpAccess && (isShared || getIntParam(shareId) > 0 || multiId) ) {
		imgName = "file_check";
	} else if (ext == 'folder' && (onlyCorpAccess || (!isShared && getIntParam(shareId) == 0 && !multiId)) ) {
		imgName = 'folder';
	} else if (ext == 'external link') {
		imgName = 'file-link';
	} else
		if (ext == "pdf") {
			imgName = "pdf";
			faImgName = "fa fa-file-pdf-o";
		} else if (ext == "jpg" || ext == "jpeg" || ext == "gif" || ext == "png") {
			imgName = "img";
			faImgName = "fa fa-file-image-o";
		} else if (ext == "doc" || ext == "rtf" || ext == "docx") {
			imgName = "doc";
		} else if (ext == "xls" || ext == "csv" || ext == "xlsx") {
			imgName = "xls";
			faImgName = "fa fa-file-excel-o";
		} else if (ext == "ppt" || ext == "pptx") {
			imgName = "ppt";
			faImgName = "fa fa-file-powerpoint-o";
		} else if (ext == "zip") {
			imgName = "zip";
		} else if (ext == "rar") {
			imgName = "rar";
		} else if (ext == "txt") {
			imgName = "txt";
			faImgName = "fa fa-file-text-o";
		} else if (ext == "java" || ext == "jar") {
			imgName = "java";
			faImgName = "fa fa-file-archive-o";
		} else if (ext == "html" || ext == "htm") {
			imgName = "html";
		} else if (ext == "js") {
			imgName = "js";
		} else if (ext == "css") {
			imgName = "css";
		} else if (ext == "swf") {
			imgName = "flash";
		} else if (ext == "sql") {
			imgName = "db";
		} else if (ext == "mp4" || ext == "mpg" || ext == "mpeg" || ext == "mov") {
			imgName = "film";
		} else if (ext == "mp3" || ext == "ogg") {
			imgName = "music";
		} else if (ext == "jsp" || ext == "php" || ext == "c" || ext == "cpp") {
			imgName = "code";
		} else {
			if ((isShared || getIntParam(shareId) > 0 || multiId ) && !onlyCorpAccess) {
				imgName = "foldershare";
			} else {
				imgName = "file";
				faImgName = "fa fa-file-o";
			}
		}
	let src = '../../asset/images/externalPage/smallIcons/' + imgName + '.png';
	let imageIcon = <img src={src} alt="xx" width="18" height="18" className='dlimg-menue' style={{ top: 8 }} />
	if (faImgName != "") {
		imageIcon = <span className={faImgName} />;
	}
	return imageIcon;
}