import React from 'react';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from 'froala-editor';
import $, { event } from 'jquery';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { Select, MenuItem, InputLabel } from '@mui/material';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import ShowCircularProgress from '../components/circularProgress';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { isValidParam, getIntParam, getStringParam, getArrayParam, isUrl } from '../../../services/helper/parameterVerifier';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { saveListValues } from '../../../services/actions/sfFormActions';
import SFAutoComplete from '../components/sfAutoComplete';
import { hasAccessPermission } from '../../../services/helper/common';
import store from "../../../services/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/js/plugins/font_family.min.js");


const News = ({ object, id, type, data, closeDrawer }) => {
    let tempstatusList = [
        {
            value: 'Draft',
            label: getLocalizedStrings().label.NEWS.STATUS.DRAFT,
        },
        {
            value: 'Published',
            label: getLocalizedStrings().label.NEWS.STATUS.PUBLISHED,
        }
    ];
    const dispatch = useDispatch();
    const [news, setNews] = useState({
        id: id || 0,
        category: '',
        status: '',
        title: '',
        summary: '',
        is_external_link: 1,
        external_link: '',
        news_details: getLocalizedStrings().label.SALES_TEMPLATE.TYPE_HERE,
        attachment_urls: ''
    });
    const [categoryList, setCategoryList] = useState(false);
    const [statusList, setStatusList] = useState(tempstatusList);
    const [cursorposition, setCursorposition] = useState(0);
    const [isAddToThisList, setIsAddToThisList] = useState(false);
    const [new_category, setNewCategory] = useState('');
    const [categoryFieldId, setCategoryFieldId] = useState(null);
    const [error, setError] = useState({
        title: '',
        summary: '',
        category: '',
        status: '',
        external_link: '',
        news_details: ''
    });
    const [mounted, setMounted] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [changeData, setChangeData] = useState({ field: '', value: '' });
    let newImageUr;
    let froalaRef;


    useEffect(() => {
        loadCategory();
        loadNews(id);
    }, []);

    useEffect(() => {
        loadNews(id);
    }, [categoryList]);

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);


    const handleDocumentClick = (event) => {
        if (popUpClose(event.target)) {
            handlePopUpClose()
        }
    };

    const popUpClose = (element) => {
        let _popUp = false;
        const popUpElemrnt = document.querySelector('.fr-popup');
        const dialogElement = document.querySelector('.MuiDialog-paper');
        const insertElement = document.querySelector('.btn-insert');
        if (!insertElement && !dialogElement && popUpElemrnt && !popUpElemrnt.contains(element)) {
            _popUp = true;
        } else {
            _popUp = false;
        }
        if (dialogElement && !dialogElement.contains(element)) {
            if (popUpElemrnt && !popUpElemrnt.contains(element)) {
                if (insertElement && !insertElement.contains(element)) {
                    _popUp = false;
                }
            }
        }
        return _popUp;
    }

    const handlePopUpClose = () => {
        let _store = store.getState()
        const divElement = document.querySelector('.fr-popup');
        if (divElement && !_store.appContainer.dialogProps.isOpenDialog && !_store.topUpDialog.isOpenDialog) {
            divElement.classList.remove('fr-block');
            if (froalaRef && froalaRef?.current) {
                froalaRef.current.events.enableBlur();
            }
        }
    };
    const disableEditorBlurEffect = () => {
        const divElement = document.querySelector('.fr-popup');
        if (divElement) {
            divElement.classList.add('fr-block');
        }
    }

    const loadCategory = () => {
        try {
            let promise = Promise.resolve(HTTPClient.get(endPoints.NEWS.FIELDS, null));
            promise.then((response) => {
                let all_fields = getArrayParam(response.fields);
                let category_field = all_fields.filter(field => {
                    return field.name === 'category';
                });
                if (isValidParam(category_field)) {
                    let categoryFieldId = null;
                    let tempCategoryList = [];
                    if (category_field.length > 0) {
                        categoryFieldId = category_field[0].id;
                        tempCategoryList = category_field[0].list_values;
                        tempCategoryList = tempCategoryList.filter(item => item.value.trim() !== '');
                    }
                    setCategoryList(tempCategoryList);
                    setCategoryFieldId(categoryFieldId);
                }
            });
        } catch (error) {
            console.error("Error in 'news.js -> loadCategory()': " + error);
        }
    }

    const loadNews = (id) => {
        try {
            if (id !== undefined && id !== null && id !== 0) {
                setMounted(false);
                let promise = Promise.resolve(HTTPClient.get(endPoints.NEWS.EDIT + '/' + id, null));
                promise.then((response) => {
                    if (isValidParam(response.records)) {
                        let tempNews = news;
                        tempNews.id = id;
                        tempNews.category = response.records.category;
                        tempNews.status = response.records.t_status;
                        tempNews.title = response.records.title;
                        tempNews.summary = response.records.summary;
                        tempNews.is_external_link = response.records.is_external_link;
                        tempNews.external_link = response.records.external_link;
                        tempNews.news_details = response.records.news_details;
                        tempNews.attachment_urls = getStringParam(response.records.attachment_urls);
                        setNews({ ...tempNews });
                        setMounted(true);
                    }
                });
            }
            else {
                setMounted(true);
            }
        } catch (error) {
            console.error("Error in 'news.js -> loadNews()': " + error);
        }
    }

    const handleModelChange = (event) => {
        let tempNews = news;
        let temperror = error;
        tempNews.news_details = event;
        setNews({ ...tempNews });
        if (news?.news_details?.trim() !== '') {
            if (isValidParam(temperror?.news_details)) {
                temperror.news_details = '';
                setError(temperror);
            }
        }
        handlePopUpClose();
    }

    const handleChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        setCursorposition(event.target.selectionStart);
        if (name === 'external_link') {
            value = value.replace(/\s/g, '');
            value = value.replace(/^\((.+)\)$/, "$1");
        }
        let tempNews = news;
        tempNews[name] = value;
        setNews({ ...tempNews });
        setChangeData({ field: name, value: value });
        let tempError;
        if (value.trim() !== '') {
            tempError = { ...error, name: '' };
        }
        setError(tempError);
    };

    const handleAddCategoryChange = (event) => {
        const target = event.target;
        const value = target.value;
        setNewCategory(value);
    };


    const saveNews = () => {
        let _title = news.title;
        let _summary = news.summary;
        try {
            if (validateForm()) {
                let params = {
                    id: news.id,
                    title: news.title,
                    category: news.category,
                    summary: news.summary,
                    status: news.status,
                    is_external_link: news.is_external_link,
                };
                if (news.is_external_link === 1) {
                    params.external_link = news.external_link;
                } else {
                    params.news_details = news.news_details;
                }

                let formData = new FormData();
                if (isValidParam(attachment)) {
                    formData.append('file', attachment);
                }
                formData.append('input_param', JSON.stringify(params));
                if (_title.length > 256) {
                    dispatch(showCustomSnackBar("Title cannot be more than 256 characters", styles.snackbarBodyStyleError, styles.snackBarStyleLongMsg));

                } else if (_summary.length > 256) {
                    dispatch(showCustomSnackBar("Summary cannot be more than 256 characters", styles.snackbarBodyStyleError, styles.snackBarStyleLongMsg));

                } else {
                    let promise = Promise.resolve(HTTPClient.postFormData(endPoints.NEWS.SAVE, formData));
                    promise.then((response) => {
                        if (response.status === 200) {
                            if (response.data.status === 0) {
                                refreshListView(constants.NEWS_OBJECT);
                                closeDrawer();
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            } else if (response.data.status === -1 && response.data.error.message !== "") {
                                if (response.data.error.message === "Duplicate Title") {
                                    dispatch(showCustomSnackBar(getLocalizedStrings().message.NEWS.DUPLICATE_TITLE, styles.snackbarBodyStyleError, styles.snackBarStyleLongMsg));
                                } else {
                                    dispatch(showCustomSnackBar(getLocalizedStrings().message.NEWS.SAVE_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleLongMsg));
                                }
                            }
                        }
                    });
                }


            }
        } catch (error) {
            console.error("Error in 'news.js -> saveNews()': " + error);
        }
    }

    const changeCategory = (event, value) => {
        try {
            if (value === '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----') {
                setIsAddToThisList(true);
                setNewCategory('');
            } else {
                let tempNews = news;
                tempNews.category = value;
                setNews({ ...tempNews });
            }
            setChangeData({ field: 'category', value: value });
        } catch (error) {
            console.error("Error in 'news.js -> changeCategory()': " + error);
        }
    }

    const generateCategoryList = () => {
        if (categoryList != null && categoryList.length > 0) {
            let hasPermission = false;
            hasPermission = hasAccessPermission("", constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_ADD_TO_LIST);
            let listItems = categoryList.map((category, i) => {
                return (
                    <MenuItem
                        key={i}
                        value={category.id}
                        style={{ ...styles.popoverMenuItem }}
                        title={category.value} >{category.value}</MenuItem>
                );
            });

            let primaryText = '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----';
            if (hasPermission) {
                listItems.push(
                    <MenuItem
                        key={'addto_category'}
                        id={'addto_category'}
                        value={primaryText}
                        style={{ ...styles.popoverMenuItem }}
                        title={primaryText} >{primaryText}</MenuItem>
                );
            }
            return listItems;
        }
    }

    const generateStatusList = () => {
        let listItems = statusList.map((status, i) => {
            return (
                <MenuItem
                    key={i}
                    id={status.value}
                    value={status.value}
                    style={{ ...styles.popoverMenuItem }}
                    title={status.label} >{status.label}</MenuItem>
            );
        });

        return listItems;
    }

    const changeStatus = (event, value) => {
        try {
            let tempNews = news;
            tempNews.status = value;
            setNews({ ...tempNews });
            setChangeData({ field: 'status', value: value });
        } catch (error) {
            console.error("Error in 'news.js -> changeStatus()': " + error);
        }
    }

    const addToCategory = () => {
        let value = new_category;
        value = value.trim();
        if (value !== '' && categoryFieldId !== null) {
            let listValues = [];
            categoryList.forEach(field => {
                if (field.value !== '') {
                    listValues.push(field.value);
                }
            });
            let tempValues = listValues.filter(f => { return f.toLowerCase() === value.toLowerCase() });
            if (tempValues.length > 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.NEWS.CATEGORY_NAME_EXISTS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                listValues.push(value);
                let promise = saveListValues(constants.NEWS_OBJECT, categoryFieldId, listValues);
                if (isValidParam(promise)) {
                    promise.then(response => {
                        let tempNews = news;
                        tempNews.category = value;
                        setNews({ ...tempNews });
                        setNewCategory('');
                        setIsAddToThisList(false);
                        categoryList.push({ id: value, value: value });
                    });
                }
            }
        } else {
            setIsAddToThisList(false);
        }
    }

    const getVideoInsertDialog = (editor, videoType) => {
        editor.events.disableBlur();
        let data = {
            editor: editor,
            contentStyle: { width: '80%', maxWidth: 'none' },
            videoType: videoType
        }
        let title = null;
        if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
            title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
        } else if (videoType === constants.VIDEO_TYPE_WISTIA) {
            title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
        }
        dispatch(getAppDialog(true, constants.VIDEO_INSERT_DIALOG, title, (event) => handleVideoInsertResponse(event), data, null));

    }

    const handleVideoInsertResponse = (obj) => {
        obj.editor.events.focus();
        dispatch(getAppDialog(false, constants.VIDEO_INSERT_DIALOG, null, null, null, null));
        obj.editor.html.insert('<a href="' + obj.video_link + '" target="_blank" class="editor_video-selector"><img  height="220" width="380" "src="' + obj.thumbnail.url + '" title="' + obj.title + '" /></a>');
        obj.editor.events.focus();
    }

    const getLibrary = (editor, type) => {
        if (type != "image") {
            editor.events?.disableBlur();
            disableEditorBlurEffect();
        }
        let data = {
            editor: editor,
            contentStyle: { width: '80%', maxWidth: 'none' },
            type: type,
        }
        data.maxWidth = 'md';
        data.callFrom = constants.NEWS_OBJECT;
        dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE, handleLibraryResponse, data, null));
    }

    const handleLibraryResponse = (obj) => {
        obj.editor.events?.enableBlur();
        if (obj.type === 'link' && obj.src !== '') {
            document.getElementsByName('href')[0].value = obj.src;
        } else if (obj.type === 'image' && obj.src !== '') {
            obj.editor.image.insert(obj.src)
        }
        dispatch(getAppDialog(false, constants.LIBRARY_DIALOG, null, null, null, null));
    }

    const chooseImage = (e) => {
        let file = null;
        let fileExt = null;
        let supportedFileExt = ['jpg', 'jpeg', 'gif', 'png'];
        let fileSize = 0;
        try {
            if (isValidParam(e)) {
                file = e.target.files[0];
                let fileName = getStringParam(file.name).replace(/'/g, "");
                fileExt = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
                fileSize = getIntParam(file.size);
                if (file.size === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_BLANK_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    e.target.value = null;
                    return false;
                } else if (supportedFileExt.indexOf(fileExt) < 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_LOGO_EXTENSION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    e.target.value = null;
                    return false;
                } else if (fileSize > 0 && fileSize >= constants.MAX_IMAGE_FILE_SIZE_FOR_PROFILE) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.PROFILE_DETAILS.VALIDATE_PROFILE_IMAGE_SIZE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
                setAttachment(file);
                var reader = new FileReader();
                reader.onload = (e) => {
                    let tempNews = news;
                    tempNews.attachment_urls = e.target.result;
                    setNews({ ...tempNews });
                }
                reader.readAsDataURL(file);
            }
        } catch (error) {
            console.error("Error in 'news.js -> chooseImage()':" + error);
        }
    }

    const validateForm = () => {
        let isValid = true;
        if (news.category === '') {
            isValid = false;
            dispatch(showCustomSnackBar(getLocalizedStrings().message.NEWS.VALIDATE_CATEGORY, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (news.status === '') {
            isValid = false;
            dispatch(showCustomSnackBar(getLocalizedStrings().message.NEWS.VALIDATE_STATUS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (news.title.trim() === '') {
            isValid = false;
            dispatch(showCustomSnackBar(getLocalizedStrings().message.NEWS.VALIDATE_TITLE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (news.summary.trim() === '') {
            isValid = false;
            dispatch(showCustomSnackBar(getLocalizedStrings().message.NEWS.VALIDATE_SUMMARY, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (news.is_external_link === 1) {
            if (news.external_link === '' || news.external_link == null) {
                isValid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.NEWS.VALIDATE_EXTERNAL_LINK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (news.external_link !== '' && !isUrl(news.external_link)) {
                isValid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.VALID_URL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } else if (news.is_external_link === 0) {
            let news_details = getStringParam(news.news_details);
            if (news_details.trim() === '') {
                isValid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.NEWS.VALIDATE_DETAILS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        }
        return isValid;
    }

    const changeRadioButton = (value) => {
        let tempNews = news;
        tempNews.is_external_link = parseInt(value);
        setNews({ ...tempNews });
        setSelectedId(value);
        setChangeData({ field: 'radio', value: value });
    }
    const handleManualController = (initControls) => {
        initControls.initialize();
        froalaRef = initControls.getEditor();
    }

    let height = window.innerHeight - 150;
    let top = (height - 10) / 2;
    if (!mounted) {
        return (
            <div style={{ width: '100%', height: height }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        )
    } else {

        Froalaeditor.DefineIcon('library', { NAME: 'image', template: "font_awesome" });
        Froalaeditor.RegisterCommand('library', {
            title: getLocalizedStrings().label.MARKETING_TEMPLATE.INSERT_IMAGE,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getLibrary(this, 'image')
            }
        });

        Froalaeditor.DefineIcon('insertYouTubeVideo', { NAME: constants.ICON_YOUTUBE, template: "font_awesome" });
        Froalaeditor.RegisterCommand('insertYouTubeVideo', {
            title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE)
            }
        });

        Froalaeditor.DefineIcon('insertWistiaVideo', { SRC: '/asset/images/wistia.png', ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE, template: 'image' });
        Froalaeditor.RegisterCommand('insertWistiaVideo', {
            title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA)
            }
        });

        Froalaeditor.DefineIcon('link_library', { NAME: 'file-archive-o', template: "font_awesome" });
        Froalaeditor.RegisterCommand('link_library', {
            title: getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_FROM_LIBRARY,
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback: function () {
                getLibrary(this, "link");
            },
        });

        let FroalaConfig = {
            key: constants.FROALA_EDITOR_KEY,
            placeholderText: getLocalizedStrings().label.SALES_TEMPLATE.TYPE_HERE,
            heightMin: 300,
            toolbarButtons: constants.NEWS_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsMD: constants.NEWS_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsSM: constants.NEWS_EDITOR_TOOLBAR_CONFIGURATION,
            toolbarButtonsXS: constants.NEWS_EDITOR_TOOLBAR_CONFIGURATION,
            colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
            colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
            toolbarSticky: false,
            enter: FroalaEditor.ENTER_BR,
            iframe: true,
            htmlUntouched: true,
            attribution: false,
            inlineMode: false,
            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
            linkInsertButtons: ['linkEdit', 'linkRemove', 'link_library'],
            imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
            tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
            quickInsertButtons: [],
            imageUpload: false,
            imagePaste: false,
            fileUpload: false,
            autofocus: true,
            fontSize: constants.EDITOR_FONT_SIZE,
            fontFamily: {
                'Adobe Garamond Pro': 'Adobe Garamond Pro',
                'Arial,Helvetica,sans-serif': 'Arial',
                'Futura Std': 'Futura Std',
                'Georgia,serif': 'Georgia',
                'Impact,Charcoal,sans-serif': 'Impact',
                "RobotoSegoe": 'Roboto',
                'Tahoma,Geneva,sans-serif': 'Tahoma',
                "'Times New Roman',Times,serif": 'Times New Roman',
                'Verdana,Geneva,sans-serif': 'Verdana',
            },
            fontFamilySelection: true,
            events: {
                'froalaEditor.initialized': function (e, editor) {
                    $('.fr-command').on('click', function () {
                        if ($(event).data('cmd') === 'insertLink') {
                            $('input[name="target"]').prop('checked', true);
                        }
                    });
                }
            },
            events: {
                'initialized': function () {
                    window.WEBSPELLCHECKER.init({
                        container: this.$iframe ? this.$iframe[0] : this.el
                    });
                }
            },
            zIndex: 25000
        }

        let categoryList = generateCategoryList();
        let statusList = generateStatusList();
        return (
            <div style={styles.workAreaContainer}>
                <div style={{ paddingLeft: '26px', paddingRight: '26px', paddingTop: '10px' }}>
                    <div style={{ width: '100%', display: 'flex', height: '55px' }}>
                        <div style={{ ...styles.sf_6 }}>
                            {isAddToThisList ?
                                <FormControl style={{ width: '100%', paddingTop: '8px', marginTop: '1px' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        fullWidth={true}
                                        underlineFocusStyle={styles.textField.underlineFocus}
                                        inputStyle={styles.textField.input}
                                        value={new_category}
                                        onChange={(event) => handleAddCategoryChange(event)}
                                        onBlur={() => addToCategory()}
                                        autoFocus={true}
                                        name="new_category"
                                        label={getLocalizedStrings().label.NEWS.CATEGORY_REQUIRED}
                                        InputProps={{ style: { height: '40px', maxLength: 64 } }}
                                        autoComplete="new-password"
                                        className={"sf-fields-bg"}
                                    /></FormControl> : <div>
                                    <FormControl variant="outlined" style={{ width: '100%', marginTop: '8px' }}>
                                        <InputLabel id="sf-news-simple-select-outlined-label" className="sf-news" style={{ marginTop: '-6px' }} >{getLocalizedStrings().label.NEWS.CATEGORY_REQUIRED}</InputLabel>
                                        <Select
                                            label={getLocalizedStrings().label.NEWS.CATEGORY_REQUIRED}
                                            labelId='sf-label-category'
                                            value={news.category}
                                            onChange={(e) => changeCategory(e, e.target.value)}
                                            style={{ height: '38px' }}
                                            className={"sf-fields-bg"}
                                        >
                                            {categoryList}
                                        </Select>
                                    </FormControl>
                                </div>
                            }
                        </div>
                        <div style={{ width: '50%', marginLeft: '20px' }}>
                            <SFAutoComplete
                                key={'sf-auto-complete-status'}
                                id={'status'}
                                name={'status'}
                                label={getLocalizedStrings().label.NEWS.STATUS_REQUIRED}
                                menuItems={statusList}
                                disabled={false}
                                onChange={(event, value) => changeStatus(event, value)}
                                value={news.status}
                                inputStyle={styles.textField.input}
                                menuItemStyle={styles.popoverMenuItem}
                            //isFullHeight={false}
                            />
                        </div>
                    </div>
                    <div className="row" style={{}}>
                        <div className="col-sm-14">
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label={getLocalizedStrings().label.NEWS.TITLE_REQUIRED}
                                    fullWidth={true}
                                    name="title"
                                    value={news.title}
                                    onChange={(e) => handleChange(e, e.target.value)}
                                    inputprops={{ maxLength: '256' }}
                                    autoComplete="off"
                                    margin='dense'
                                    size='small'
                                    style={{ height: "42px" }}
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-14">
                            <FormControl style={{ width: '100%', marginTop: '10px' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    label={getLocalizedStrings().label.NEWS.SUMMARY_REQUIRED}
                                    fullWidth={true}
                                    name="summary"
                                    value={news.summary}
                                    onChange={(e) => handleChange(e, e.target.value)}
                                    inputprops={{ maxLength: '256' }}
                                    autoComplete="off"
                                    margin='dense'
                                    size='small'
                                    className={"sf-fields-bg"}
                                /></FormControl>
                        </div>
                    </div>
                    <div>
                        <div>
                            <FormControl component="fieldset" >
                                <RadioGroup row name="layout" onChange={(e) => changeRadioButton(e.target.value)} value={news.is_external_link} style={{ width: 'auto', height: '38px' }} >
                                    <FormControlLabel value={1} control={<Radio color="default" />} label={getLocalizedStrings().label.NEWS.EXTERNAL_LINK} style={{ color: 'rgb(113, 113, 113)' }} title={getLocalizedStrings().label.NEWS.EXTERNAL_LINK} />
                                    <FormControlLabel value={0} control={<Radio color="default" />} label={getLocalizedStrings().label.NEWS.ENTER_DETAILS} style={{ color: 'rgb(113, 113, 113)' }} title={getLocalizedStrings().label.NEWS.ENTER_DETAILS} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    {news.is_external_link === 1 &&
                        <div className="row">
                            <div className="col-sm-14" >
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        label={getLocalizedStrings().label.NEWS.EXTERNAL_LINK_REQUIRED}
                                        fullWidth={true}
                                        name="external_link"
                                        value={news.external_link}
                                        onChange={(e) => handleChange(e, e.target.value)}
                                        autoComplete="off"
                                        margin='dense'
                                        size='small'
                                        className={"sf-fields-bg"}
                                    /></FormControl>
                            </div>
                        </div>
                    }
                    {news?.is_external_link === 0 &&
                        <div className="row" style={{ marginTop: 6 }} >
                            <div className>
                                <FroalaEditor onManualControllerReady={handleManualController} ref={(ref) => froalaRef = ref} tag='textarea' model={news?.news_details} config={FroalaConfig} style={{ marginLeft: '10px' }} onModelChange={(data) => handleModelChange(data)} />
                            </div>
                            {error?.news_details !== '' &&
                                <div className="col-sm-12" style={{ fontSize: 12, color: '#f44336', marginLeft: 8 }}>
                                    {error?.news_details}
                                </div>
                            }
                        </div>
                    }
                    <div style={{ marginTop: '20px', width: '100%', height: '33px' }} >
                        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                            <div style={{ display: 'flex', height: '15px',width:'30rem' }}>
                                <Button
                                    style={{ ...styles.primaryButton, float: 'left' }}
                                >{getLocalizedStrings().label.NEWS.UPLOAD_IMAGE}
                                    <input type="file" style={styles.imageInput} onChange={(e) => chooseImage(e)} multiple />
                                </Button>
                                {isValidParam(attachment) && <div title={attachment.name} style={{width:'33%',cursor:'pointer',height:'fit-content',overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{attachment.name}</div>}
                            </div>
                            <div style={{ flex: 2, height: '15px' }}></div>
                            <div style={{ flex: 3, alignItems: 'right', textAlign: 'right', height: '15px' }}>
                                <Button
                                    onClick={() => saveNews()}

                                    style={{ ...styles.primaryButton, verticalAlign: 'top' }}
                                > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                                <Button
                                    onClick={() => closeDrawer()}

                                    style={{ ...styles.secondaryButton2, verticalAlign: 'top' }}
                                >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                            </div>
                        </div>
                        {news.attachment_urls !== '' &&
                            <div style={{ height: selectedId == 0 ? '183px' : '100px' }}>
                                <img src={news.attachment_urls} height="100" width="200" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default News;
