import React, { useEffect, useState } from 'react';
import { styles } from '../../../services/constants/styles';
import { isValidParam, getObjectParam, getStringParam, getIntParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Button, Icon } from '@mui/material';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { refreshDetailViewListViewData, getDetailViewPinnedNote, refreshQueueDetailViewNote ,refreshDetailViewData} from '../../../services/actions/detailViewActions';
import ShowCircularProgress from '../components/circularProgress';
import { endPoints } from '../../../services/constants/endPoints';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from "react-redux";
import map from 'lodash/map';
import * as sfDialogs from '../components/sfDialogs';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import {
	getTasksRefresh,getTasksPortal
} from "../../../services/actions/taskActions";
import {updateTabInfo } from '../../../services/helper/sfTabManager';
import { addTab, TYPE_DETAIL_VIEW, getActiveTabInfo} from '../../../services/helper/sfTabManager';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import {
    detailViewIsMounted,
    } from "../../../services/actions/detailViewActions";

const iconStyles = {
    marginLeft: 5,
};


const inlinestyle = {
    button: {
        marginRight: 20,
    },
    exampleImageInput: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        opacity: 0,
    }
};


const AttachmentUpload = ({ data }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const detailView = useSelector((state) => state.detailView);
    const attachmentUpload = useSelector((state) => state.attachmentUpload);
    const childListView = useSelector((state) => state.childListView);
    const [downloadInputParams, setDownloadInputParams] = useState({ 'id': '0', 'fileName': 'abc.png' });
    const [inProgress, setInProgress] = useState(false);
    const [files, setFiles] = useState([]);


    useEffect(() => {
        attachmentUpload.mounted = false;
        attachmentUpload.files = [];
    }, []);


    const handleClose = () => {
        setFiles([]);
        if (data.callFrom === 'detailviewAttachments') {
            dispatch(refreshDetailViewListViewData(true));
        }
        dispatch(getAppDialog(false, null, null, null, null, null));
    }


    const imageOnChange = (e) => {
        if (e.target.files.length > 5) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_MAX_ATTACHMENTS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            e.target.value = null;
            return false;
        }

        let filesArray = [];
        for (let i = 0; i < e.target.files.length; i++) {

            let fileName = e.target.files[i].name;
            fileName = fileName.replace(/'/g, "");
            let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);

            if (fileext.toLowerCase() === 'exe') {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.CANNOT_UPLOAD_EXECUTABLE_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                e.target.value = null;
                return false;
            }

            if (e.target.files[i].size === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_BLANK_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                e.target.value = null;
                return false;
            }

            if (e.target.files[i].size / 1024 / 1024 >= 50) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.OVER_MAX_SIZE, styles.snackbarBodyStyleError, styles.snackBarStyleLongMsg));
                e.target.value = null;
                return false;
            }
            let tempDocArr = [];
            if (getArrayParam(files).length > 0) {
                tempDocArr = files.filter(f => f.fileName === fileName);
                if (tempDocArr.length > 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD_FAILURE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
            }

            let file = {};
            file.fileName = e.target.files[i].name;
            file.file = e.target.files[i];
            filesArray.push(file);
        }

        setFiles([...files, ...filesArray]);
        setInProgress(false);

    }

    const onFormSubmit = (e) => {
        if (!isValidParam(files) || files.length === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_ATTACHMENT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else if (data.callFrom !== constants.SEND_EMAIL) {
            for (let i = 0; i < files.length; i++) {
                if (files[i].file.size === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_EMPTY_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }

                if (files[i].file.size / 1024 / 1024 >= 50) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.MAX_SIZE_50_MB, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
                if (files[i].file.desc !== undefined && files[i].file.desc.length  > 128) {
                    dispatch(showCustomSnackBar("Description should not more than 128 characters", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
            }


        }
        setInProgress(true);
        let promise = Promise.resolve(fileUpload(files));
        promise.then((response) => {

            if (response.data.status === 0) {
                if (files.length === 1) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.ATTACHMENTS_UPLOAD, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }

                let eventName = null;
                if (isValidParam(appContainer.dialogProps.eventName)) {
                    eventName = appContainer.dialogProps.eventName;
                }

                handleClose();
                let callFrom = getStringParam(data.callFrom);
                if (detailView.isDetailView === true || callFrom === 'detailviewAttachments') {
                    let parentObject = getStringParam(data.parent_object);
                    let parentRecordId = getIntParam(data.parent_record_id || data.UnitsAccountProjectId);
                    if (parentObject === constants.NOTES && parentRecordId > 0) {
                        dispatch(refreshDetailViewListViewData(true, data.parent_record_id));
                        dispatch(refreshQueueDetailViewNote(true));
                        let _info = getActiveTabInfo();
                        if (_info.selectedPageNo > 1) {
                            _info.selectedPageNo = 1;
                            updateTabInfo(_info);
                        }
                    } else if(detailView.isDetailView === true  && data.object=== "attachments"){
                        dispatch(detailViewIsMounted(false));
                        dispatch(getTasksRefresh(false));
                        dispatch(refreshDetailViewListViewData(true));
                        dispatch(refreshDetailViewData(true));
                    } else {
                        if(callFrom !== 'TaskForm')
                        dispatch(refreshDetailViewListViewData(true));
                    }

                    let isPinned = getBooleanParam(data.isPinned);
                    if (isPinned) {
                        getDetailViewPinnedNote();
                    }
                }


                if (callFrom === constants.SEND_EMAIL && isValidParam(eventName)) {
                    eventName(response.data.data);
                }
                if (callFrom == 'TaskForm' && isValidParam(eventName)) {
                    eventName(response.data.data);
                }
            } else {
                setInProgress(false);
                handleClose();
                if (response.data.error.message.includes("50")) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.MAX_SIZE_50_MB, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD_FAILURE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            }
        });
    }

    const fileUpload = (files) => {
        const formData = new FormData();
        let fileDescription = [];
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i].file);
            fileDescription.push(files[i].file.desc === "" ? "" : files[i].file.desc || files[i].file.name);
        }

        let url = null;
        let tempData = getObjectParam(data);
        let callFrom = getStringParam(tempData.callFrom);
        if (app.selectedModuleName === constants.MODULE_HUB) {
            tempData.call_from = constants.MODULE_HUB;
        }
        let tab = getActiveTab();
        let info = tab.info;
        if (isValidParam(info) && info.hasOwnProperty("isUnitsAccount") && info.hasOwnProperty("UnitsAccountProjectId") && info.isUnitsAccount) {
            data.isUnitsAccount = info.isUnitsAccount;
            data.UnitsAccountProjectId = info.UnitsAccountProjectId;
        }
        if (callFrom === constants.SEND_EMAIL) {
            url = endPoints.SEND_MAIL.ATTACHMENT_UPLOAD;
        } else {
            url = endPoints.ATTACHMENT.UPLOAD_ATTACHMENT;
            formData.append("input_param", JSON.stringify({ "id": 0, "fileDescription": fileDescription, ...tempData }));
        }
        return HTTPClient.postFormData(url, formData);
    }

    const downloadFormDataChange = (fieldName, event) => {
        let downloadInputParamsTemp = downloadInputParams;
        downloadInputParamsTemp[fieldName] = event.target.value;
        setDownloadInputParams(downloadInputParamsTemp);
    }

    const onDownloadFormSubmit = (e) => {
        e.preventDefault() // Stop form submit
        var formData = new FormData();
        formData.append('input_param', JSON.stringify(downloadInputParams));
        let fileName = downloadInputParams.fileName | 'abc.png';
        HTTPClient.postFormDataDownloadFile('/v3/library/download', formData, fileName);

    }

    const onUploadSubmit = () => {
        let existingFile = appContainer.dialogProps.data.existingFiles;
        let _files = [];
        let duplicateFileExsts = false;
        let uploadFiles = [];
        let duplicateFileName = [];
        if (existingFile[0] != undefined && existingFile.length > 0) {
            files.map((file) => {
                existingFile.map((newFile) => {
                    if (file.fileName == newFile.name) {
                        duplicateFileExsts = true;
                        duplicateFileName.push(file.fileName);
                    }
                }
                )
                if (!duplicateFileExsts) {
                    uploadFiles.push(file);
                }
                duplicateFileExsts = false;
            })
        }
        if (duplicateFileName.length > 0) {
            duplicateFileExsts = true;
        } else {
            duplicateFileExsts = false;
        }

        if (duplicateFileExsts) {
            _files = uploadFiles;
        } else {
            _files = files;
        }
        appContainer.dialogProps.data.duplicateFiles = duplicateFileName;
        let props = appContainer.dialogProps;

        if (duplicateFileExsts == true) {
            handleClose();
    (sfDialogs.confirm(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, " This file already exists. Do you want to replace it?", ()=>onConfimOk(files,duplicateFileName,duplicateFileExsts, props), null))
        }
        else {
            onFormSubmit();
        }

    }
   const onConfimOk = (files,duplicateFileName,duplicateFileExsts,props)=>{
        let promise = Promise.resolve(fileUpload(files));
     
        promise.then((response) => {

            if (response.data.status === 0) {
                if (files.length === 1) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.ATTACHMENTS_UPLOAD, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }

                let eventName = null;
                if (isValidParam(appContainer.dialogProps.eventName)) {
                    eventName = appContainer.dialogProps.eventName;
                }

                handleClose();
                let callFrom = getStringParam(data.callFrom);
                if (detailView.isDetailView === true || callFrom === 'detailviewAttachments') {
                    let parentObject = getStringParam(data.parent_object);
                    let parentRecordId = getIntParam(data.parent_record_id);
                    if (parentObject === constants.NOTES && parentRecordId > 0) {
                        dispatch(refreshDetailViewListViewData(true, data.parent_record_id));
                        dispatch(refreshQueueDetailViewNote(true));
                    } 
                      else if(detailView.isDetailView === true  && data.object=== "attachments"){
                        dispatch(detailViewIsMounted(false));
                        dispatch(getTasksRefresh(false));
                        dispatch(refreshDetailViewListViewData(true));
                        dispatch(refreshDetailViewData(true));
                    }
                    else {
                        dispatch(refreshDetailViewListViewData(true));
                    }

                    let isPinned = getBooleanParam(data.isPinned);
                    if (isPinned) {
                        getDetailViewPinnedNote();
                    }
                }


                if (callFrom == constants.SEND_EMAIL && isValidParam(eventName)) {
                    eventName(response.data.data,duplicateFileName,duplicateFileExsts);
                }
                if (callFrom == 'TaskForm' && isValidParam(eventName)) {
                    eventName(response.data.data,duplicateFileName,duplicateFileExsts);
                }
            } else {
                setInProgress(false);
                handleClose();
                if (response.data.error.message.includes("50")) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.MAX_SIZE_50_MB, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD_FAILURE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            }
        });


    }
    const getFilesForRender = () => {
        let ul = null;
        let li = files.map((f, index) => {
            return <li key={f.fileName + index}><span style={{
                float: 'left', textAlign: 'left', width: '100%', overflow: 'hidden',
                textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                marginTop: index != 0 ? '30px' : ''
            }} title={f.fileName}> {f.fileName} </span></li>
        });
        if (li !== null && li.length > 0) {
            ul = <ul style={{ paddingLeft: '22px' }}>{li}</ul>;
        }
        return ul;
    }
    const handleDesc = (value, i) => {
        let _files = [...files];
        let tempFileObj = { ..._files[i] };
        tempFileObj.file.desc = value;
        _files[i] = tempFileObj
        setFiles([..._files]);
    }
    const getFileDescField = () => {
        let ul = null;
        let li = files.map((f, index) => {
            return <li key={f.fileName + index}>
                <FormControl style={{ width: '100%', paddingRight: '10px', marginTop: '-1px' }} className="test" noValidate autoComplete="off">
                    <TextField
                        variant="outlined"
                        label="Description"
                        fullWidth={true}
                        name="description"
                        value={f.file.desc != undefined ? f.file.desc : f.fileName}
                        onChange={(e) => handleDesc(e.target.value, index)}
                        inputprops={{ maxlength: '128' }}
                        autoComplete="new-password"
                        margin='dense'
                        size='small'
                        autoFocus={true}
                        className={"sf-fields-bg"}
                    />
                </FormControl>
            </li>
        });
        if (li !== null && li.length > 0) {
            ul = <ul style={{ paddingLeft: '10px' }}>{li}</ul>;
        }
        return ul;
    }
    let height = window.innerHeight - 580;
    let top = (height - 10) / 2;
    if (inProgress === false) {
        return (
            <div>
                <div style={{ padding: '0px' }}>
                    <div>
                        <form onSubmit={(e) => onFormSubmit(e)} id='attachmentUpload' style={{ margin: 0, width: '100%' }}>
                            <div>
                                <div style={{ ...styles.sf_12, border: '1px solid #ccc' }}>
                                    <div id='attachmentUpload'>
                                        <div style={{ ...styles.row, padding: '0px' }}>
                                            <div style={{ padding: 15, backgroundColor: '#fff', width: '210px' }}>
                                                <h3>{getLocalizedStrings().label.ATTACHMENT.MY_COMPUTER}</h3>
                                                <Button
                                                    variant="contained"
                                                    style={{ ...styles.button, width: '180px', background: '#e0e0e0', color: 'black' }}
                                                    endIcon={<Icon style={iconStyles} color={'primary'}>computer</Icon>}
                                                >
                                                    {getLocalizedStrings().label.ATTACHMENT.BROWSE}
                                                    <input type="file" style={inlinestyle.exampleImageInput} onChange={(e) => imageOnChange(e)} multiple />
                                                </Button>

                                            </div>
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                <div style={{ width: '50%', verticalAlign: 'middle', backgroundColor: '#efefef' }}>
                                                    {getFilesForRender()}
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <div>
                                                        {getFileDescField()}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-12" style={{ display: 'none' }}>
                        <h3>{getLocalizedStrings().label.ATTACHMENT.DOWNLOAD} </h3>
                        <form onSubmit={(e) => onDownloadFormSubmit(e)}>
                            <input type="text" value={downloadInputParams['id']} onChange={(fieldName, event) => downloadFormDataChange(fieldName, event, 'id')} />
                            <input type="text" value={downloadInputParams['fileName']} onChange={(fieldName, event) => downloadFormDataChange(fieldName, event, 'fileName')} />
                            <button type="submit">{getLocalizedStrings().label.ATTACHMENT.UPLOAD}</button>
                        </form>
                    </div>

                    <div className="col-md-12" style={{ textAlign: 'right', padding: '0px', paddingTop: '15px' }}>

                        <Button variant="contained" type="submit" style={{ ...styles.primaryButton, marginRight: '0px', border: '0px', verticalAlign: 'top' }}
                            onClick={(e) => {
                                e.preventDefault();
                                (appContainer.drawerProps.data !== null && appContainer.drawerProps.data.object === "tasks") ? onUploadSubmit() : onFormSubmit();
                            }}
                        >{getLocalizedStrings().label.ATTACHMENT.UPLOAD}</Button>

                        &nbsp;
                        <Button
                            primary={true}
                            onClick={() => handleClose()}
                            style={{ ...styles.secondaryButton, marginRight: '0px' }}
                        >{getLocalizedStrings().label.COMMON.CLOSE}</Button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ minHeight: '150px' }}>
                <div className="col-md-12" style={{ marginTop: '30px', paddingBottom: '80px' }}>
                    <div style={{ width: '100%', height: height }} >
                        {
                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-12" style={{ textAlign: 'right', padding: '0px', paddingTop: '15px', paddingBottom: '30px' }}>

                    <Button
                        primary={true}
                        onClick={() => handleClose()}
                        style={{ ...styles.secondaryButton, height: '36px', marginRight: '0px', }}
                    >{getLocalizedStrings().label.COMMON.CLOSE}</Button>
                </div>
            </div>
        );
    }
}

export default AttachmentUpload;
