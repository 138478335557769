import React, { useRef, useEffect, useState } from 'react';
import $ from 'jquery';
import { useDispatch, useSelector } from "react-redux";
import { isValidParam, getStringParam, getIntParam, getArrayParam, getObjectParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import FroalaEditor from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor'
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getAppDialog, getAppDrawer, getAppCustomDrawer, minimizeAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { constants } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Menu, MenuItem, IconButton, Typography, Icon } from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import { getScheduleEmails, getEmailSignature } from '../../../services/actions/sendMailActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { refreshDetailViewData, refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { getBeeEditorFontList } from "../../../services/helper/utils";
import { getDefaultBeeEditorData } from '../../../services/helper/common';
import { updateSendMailDataInRedux } from '../../../services/actions/sendMailActions';
import Bee from "@mailupinc/bee-plugin";
import store from "../../../services/store";

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/js/plugins/font_family.min.js");

const taskActionsIdValue = [{ id: 'nextday', value: getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_DAY },
{ id: 'nextweek', value: getLocalizedStrings().label.APPOINTMENTS_TASKS.NEXT_WEEK }];

// const bee = new Bee();
const EditMail = ({ data }) => {
    const dispatch = useDispatch();
    let app = useSelector((state) => state.app);
    let appContainer = useSelector((state) => state.appContainer);
    let sendMail = useSelector((state) => state.sendMail);
    let detailView = useSelector((state) => state.detailView);

    const [emailSignature, setEmailSignature] = useState('');
    const [merge_fields, setMerge_fields] = useState('');
    const [taskAnchor, setTaskAnchor] = useState(null);
    const [taskAction, setTaskAction] = useState(null);
    const [token , setToken] = useState(null);
    const [bee, setBee] = useState({})
    const beeEditor = new Bee(token);
    const [fromEmail, setFromEmail] = useState(null);
    const [template, setTemplate] = useState({
        id: 0,
        name: '',
        subject: '',
        email_message: ''
    });
    const personalize_fields = {
        "j_contacts.company": "Company",
        "j_contacts.first_name": "First name",
        "j_contacts.last_name": "Last name"
    };
    const [attachFiles, setAttachFiles] = useState('');
    const [isOpenTaskAction, setIsOpenTaskAction] = useState(false);
    const [taskActionValue, setTaskActionValue] = useState('');
    const [coupons, setCoupons] = useState({});
    const [isMounted, setIsMounted] = useState(false);
    const [editorType, setEditorType] = useState("Bee");
    let froalaRef;
    const emailContentRef = useRef('');

    useEffect(() => {
        loadMergeFields();
        loadTemplate();
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);




    const handleDocumentClick = (event) => {
        if (popUpClose(event.target)) {
            handlePopUpClose()

        }
    };

    const popUpClose = (element) => {
        let _popUp = false;
        const popUpElemrnt = document.querySelector('.fr-popup');
        const dialogElement = document.querySelector('.MuiDialog-paper');
        const insertElement = document.querySelector('.btn-insert');
        if (!insertElement && !dialogElement && popUpElemrnt && !popUpElemrnt.contains(element)) {
            _popUp = true;
        } else {
            _popUp = false;
        }
        if (dialogElement && !dialogElement.contains(element)) {
            if (popUpElemrnt && !popUpElemrnt.contains(element)) {
                if (insertElement && !insertElement.contains(element)) {
                    _popUp = false;
                }
            }
        }
        return _popUp;
    }

    const handlePopUpClose = () => {
        let _store = store.getState()
        const divElement = document.querySelector('.fr-popup');
        if (divElement && !_store.appContainer.dialogProps.isOpenDialog && !_store.topUpDialog.isOpenDialog) {
            divElement.classList.remove('fr-block');
            if (froalaRef && froalaRef?.current) {
                froalaRef.current.events.enableBlur();
            }
        }
    };
    const disableEditorBlurEffect = () => {
        const divElement = document.querySelector('.fr-popup');
        if (divElement) {
            divElement.classList.add('fr-block');
        }
    }


    const getMergeTags = () => {
        let mergeTags = [];
        let mergeFields = getArrayParam(merge_fields);
        try {
            mergeTags = [
                { name: 'Opt-in Link', value: '[Start Opt-in Link] Enter your display text [End Opt-in Link]' },
                { name: 'Opt-out Link', value: '[Start Opt-out Link] Enter your display text [End Opt-out Link]' },
                { name: 'Signature', value: '${Email_Signature}' },
            ];
            let tempTags = mergeFields.map(mergeField => {
                let key = Object.keys(mergeField)[0];
                return { name: mergeField[key], value: '$' + `{${key}}` };
            });
            mergeTags.push(...tempTags);
        } catch (error) {
            console.error("Error in 'MosaicoTemplate.js -> getMergeTags()':" + error);
        }
        return mergeTags;
    }
    const loadMergeFields = () => {
        var response = Promise.resolve(HTTPClient.get(endPoints.SALES_TEMPLATE.NEW, null));
        response.then((response) => {
            setMerge_fields(response.available_mail_merge_fields);
        });
    }
    const getSpecialLinks = () => {
        let specialLinks = [];
        try {
            let slug = app.appointment_scheduler_slug;
            if (isValidParam(slug) && slug !== '') {
                let link = { type: 'Appointment', label: 'Book an appointment', link: constants.APPOINTMENT_SCHEDULER_URL + slug }
                specialLinks.push(link);
            }
        } catch (error) {
            console.error("Error in 'MosaicoTemplate.js -> getSchedulerLink()':" + error);
        }
        return specialLinks;
    }
    const registerBeeEditor = async (content) => {
       
        try {
            if (editorType === constants.EDITOR_BEE) {
                let tenantId = getStringParam(app.me.projectId);
                let mergeTags = getMergeTags();
                let specialLinks = getSpecialLinks();
                var beeConfig = {
                    uid: tenantId,
                    container: 'bee-container',
                    language: 'en-US',
                    mergeTags: mergeTags,
                    specialLinks: specialLinks,
                    editorFonts: {
                        showDefaultFonts: false,
                        customFonts: getBeeEditorFontList()
                    },
                    titleDefaultConfig: {
                        bold: true
                    },
                    titleDefaultStyles: {
                        h1: {
                            color: '#555555',
                            'font-size': '34px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                        h2: {
                            color: '#555555',
                            'font-size': '23px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                        h3: {
                            color: '#555555',
                            'font-size': '18px',
                            'font-family': "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            'link-color': '#E01253',
                            'line-height': '120%',
                            'text-align': 'center',
                            'direction': 'ltr',
                            'letter-spacing': 0,
                        },
                    },
                    contentDefaults: {
                        text: {
                            html: "I'm a new Text block ready for your content.",
                            styles: {
                                color: "#555555",
                                linkColor: "#0068A5",
                                fontSize: '14px',
                                lineHeight: "120%",
                                fontFamily: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
                            },
                        },
                    },
                    onSaveAsTemplate: (jsonFile) => {
                        /*Call content service api to get Responsible HTML for email */
                        var response = Promise.resolve(HTTPClient.post(endPoints.MARKETING_TEMPLATE.GET_BEE_MESSAGE_HTML, { page: JSON.parse(jsonFile) }));
                        emailContentRef.current = jsonFile;
                        response.then((response) => {
                            if (isValidParam(response) && isValidParam(response.data) && response.data !== '') {
                                let html = response.data;
                                setTemplate((prev)=>({ ...prev, email_message: html }));
                                dispatch(updateSendMailDataInRedux({ ...template, email_message: html, emailContent: jsonFile }));
                            }
                        });
                    }
                }
                var endpoint = constants.BEE_EDITOR_AUTH_API;
                var payload = {
                    client_id: constants.BEE_EDITOR_CLIENT_ID,
                    client_secret: constants.BEE_EDITOR_CLIENT_SECRET_ID,
                };
                let templateObj = getStringParam(content) !== "" ? JSON.parse(content) : getDefaultBeeEditorData();

                await beeEditor.getToken(
                    constants.BEE_EDITOR_CLIENT_ID,
                    constants.BEE_EDITOR_CLIENT_SECRET_ID
                )
                    .then((response) => {
                        setToken(response.access_token);
                    })
                    .then(() => {
                        beeEditor.start(beeConfig, templateObj)
                            .then((instance) => {
                                setBee(instance);
                            });
                    })
                    .catch((error) => console.error('Error during initialization --> ', error));
            }
        } catch (error) {
            console.error("Error in 'sendMail.js -> registerBeeEditor()':" + error);
        }
    }
    const loadTemplate = () => {
        try {
            let object = getStringParam(data.object);
            let minimizeList = appContainer.customDrawerProps.minimizeList
            let actionType = appContainer.customDrawerProps.actionType;

            if (minimizeList.hasOwnProperty(actionType) && minimizeList[actionType]) {
                let tempTemplate = template;
                tempTemplate.subject = sendMail.maildata.template.subject;
                tempTemplate.email_message = sendMail.maildata.template.email_message;
                setTemplate({ ...sendMail.maildata.template });
                setAttachFiles(sendMail.maildata.attachFiles);
                setIsMounted(true);
                setEditorType(sendMail.maildata.editorType);
                emailContentRef.current = sendMail.maildata.emailContent;

                if (editorType !== null && editorType !== "" && editorType !== undefined && editorType === constants.EDITOR_BEE) {
                    registerBeeEditor(sendMail.maildata.emailContent);
                }

            } else {
                let params = {
                    id: getIntParam(data.id),
                    emailType: getStringParam(data.emailType),
                    parent_object: getStringParam(data.parent_object),
                    parent_record_id: getIntParam(data.parent_record_id)
                };
                let promise = Promise.resolve(getScheduleEmails(object, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (response.status === 0) {
                            let temp = template;
                            temp.subject = response.data.subject;
                            temp.email_message = response.data.template_body;
                            let _editorType = response.data.editor_type
                            setTemplate(temp);
                            setAttachFiles(response.data.attachment_info);
                            setIsMounted(true);
                            setEditorType(_editorType);
                            emailContentRef.current = response.data.t_content;
                            const _maildata = {
                                template: temp, editorType: _editorType, emailContent: response.data.t_content, attachFiles: response.data.attachment_info
                            }
                            dispatch(updateSendMailDataInRedux({ ..._maildata }));
                             if (_editorType !== null && _editorType !== "" && _editorType !== undefined && _editorType === constants.EDITOR_BEE) {
                                   registerBeeEditor(response.data.t_content);
                                }
                        

                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'editMail.js -> loadTemplate()':" + error);
        }
    }
    const handleModelChange = (value) => {
        let temp = template;
        temp['email_message'] = value;
        setTemplate(temp);


        const _maildata = {
            template: temp
        }
        dispatch(updateSendMailDataInRedux({ ..._maildata }));
        handlePopUpClose();
    };
    const handleTaskActionOpen = (event) => {
        event.preventDefault();
        setIsOpenTaskAction(true);
        setTaskAnchor(event.currentTarget);
    }
    const handleTaskActionClose = () => {
        setIsOpenTaskAction(false);
    }
    const setFieldValue = (event, fieldName) => {
        let value;
        value = event.target.value;
        if (fieldName === 'subject') {
            let temp = template;
            temp[fieldName] = value;
            setTemplate({ ...temp });
            const _maildata = {
                template: temp
            }
            dispatch(updateSendMailDataInRedux({ ..._maildata }));
        }
    }
    const closeDrawer = () => {
        let minimizeList = appContainer.customDrawerProps.minimizeList
        let actionType = appContainer.customDrawerProps.actionType;
        if (minimizeList.hasOwnProperty(actionType)) {
            minimizeList[actionType] = false;
        }
        let prevScrollPosition = detailView.scrollPosition;
        detailView.prevScrollPosition = prevScrollPosition;
        if (getBooleanParam(appContainer.customDrawerProps.isOpen)) {
            dispatch(getAppCustomDrawer(false, null, null, null, null, null, null));
        } else if (getBooleanParam(appContainer.drawerProps.isOpenDrawer)) {
            dispatch(getAppDrawer(false, null, null, null, null));
        }
    }
    const handelSaveMail = () => {
        if (editorType === constants.EDITOR_BEE) {
            if (isValidParam(bee)) {
                bee.saveAsTemplate();
            }
        } 
        setTimeout(saveMail, 2000);
        
    }
    const isValid = (params) => {
        if (isValidParam(params.subject) && params.subject === '') {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MAIL.BLANK_SUBJECT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        return true;
    }
    const saveMail = () => {
        let object = getStringParam(data.object);
        try {
            let id = getIntParam(data.id);

            let params = {};
            params.id = id;
            params.emailType = getStringParam(data.emailType);
            params.parent_object = getStringParam(data.parent_object);
            params.parent_record_id = getIntParam(data.parent_record_id)
            params.subject = template.subject;
            params.email_message = template.email_message;
            params.t_content = emailContentRef.current;
            if (isValid(params)) {
                let promise = Promise.resolve(HTTPClient.post(endPoints.SEND_MAIL.SAVE_SCHEDULE_EMAILS, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (response.status === 0) {
                            if (response.data.isSaved) {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.EMAIL_INTEGRATION.EDIT_EMAIL_SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                dispatch(getTasksRefresh(true));
                                dispatch(refreshDetailViewData(true));
                                dispatch(refreshDetailViewListViewData(true));
                                closeDrawer();
                                let activeTab = getObjectParam(getActiveTab());
                                if (activeTab.object === constants.TASKS && isValidParam(activeTab.info.getTaskPageData)) {
                                    activeTab.info.getTaskPageData(constants.TASKS);
                                }
                            } else {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                closeDrawer();
                            }
                        } else {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            closeDrawer();
                        }
                    })
                }
            }
        } catch (error) {
            console.error("Error in 'editMail.js -> saveMail()':" + error);
        }
    }
    const getSignature = (inst) => {
        var param = {};
        param.signature_email_id = fromEmail;
        let promise = Promise.resolve(getEmailSignature(param));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let signature = getStringParam(response.t_signature).trim();
                    inst.html.insert(signature);
                    setEmailSignature(signature);
                }
            });
        }
    }
    const getAttachmetFiles = () => {
        let divEle = [];
        try {
            let attachemntInfo = getStringParam(attachFiles);
            if (isValidParam(attachemntInfo) && attachemntInfo !== '') {
                let attachInfoArr = attachemntInfo.split('##');

                if (attachInfoArr.length > 0) {
                    divEle.push(<div className="col-sm-12" style={{marginTop:'20px', marginBottom:'10px'}}>
                        <div title={attachInfoArr[1]} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '50%' }}>
                            {attachInfoArr[1]}
                        </div>
                    </div>);

                }
            }

        } catch (error) {
            console.error("Error in 'editMail.js -> getAttachmetFiles()':" + error);
        }
        return divEle;
    }
    const getEditor = (templateBodyMsg, FroalaConfig) => {
        let editor = null
        let _editorType = editorType;
        if (_editorType !== constants.EDITOR_BEE) {
            editor = <FroalaEditor tag='textarea' ref={(ref) => froalaRef = ref} model={templateBodyMsg} config={FroalaConfig} style={{ marginLeft: '10px' }} onModelChange={handleModelChange} />
        } else {
            editor = <div id='bee-container' style={{ padding: '0px 10px', height: (window.innerHeight - 220) + 'px' }}></div>
        }
        return editor
    }
    const minimizeCustomDrawer = (event) => {
        if ((sendMail.maildata.editorType) === constants.EDITOR_BEE) {
            if (isValidParam(bee)) {
                bee.saveAsTemplate();
            }
        }

        setTimeout(handelMinimizeCustomDrawer, 1000);
    }
    const handelMinimizeCustomDrawer = () => {
        let appData = appContainer.customDrawerProps;
        let minimizeList = appData.minimizeList;
        let type = appData.actionType;
        minimizeList[type] = true
        let style = {};
        let labelName = appContainer.customDrawerProps.dialogName;
        let headerProps = {};
        let prevScrollPosition = detailView.scrollPosition;
        detailView.prevScrollPosition = prevScrollPosition;
        dispatch(minimizeAppCustomDrawer(true, labelName, style, { isMinimize: true }, headerProps, minimizeList));
    }
    const getVideoInsertDialog = (editor, videoType) => {
        editor.events.disableBlur();
        let data = {
            editor: editor,
            contentStyle: { width: '80%', maxWidth: 'none' },
            videoType: videoType
        }
        let title = null;
        if (videoType === constants.VIDEO_TYPE_YOUTUBE) {
            title = getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE;
        } else if (videoType === constants.VIDEO_TYPE_WISTIA) {
            title = getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE;
        }
        dispatch(getAppDialog(true, constants.VIDEO_INSERT_DIALOG, title, (event) => handleVideoInsertResponse(event), data, null));

    }
    const handleVideoInsertResponse = (obj) => {
        obj.editor.events.focus();
        dispatch(getAppDialog(false, constants.VIDEO_INSERT_DIALOG, null, null, null, null));
        obj.editor.html.insert('<a href="' + obj.video_link + '" target="_blank" className="editor_video-selector"><img  height="220" width="380" "src="' + obj.thumbnail.url + '" title="' + obj.title + '" /></a>');
        obj.editor.events.focus();
        obj.editor.events.enableBlur();
    }

    const getLibrary = (editor, type) => {
        if (type != "image") {
            editor.events.disableBlur();
            disableEditorBlurEffect();
        }

        let data = {
            editor: editor,
            type: type,
            fullWidth: true,
            is_library: true,
            is_attachment: false,
        }
        data.maxWidth = 'md';
        data.callFrom = constants.SEND_EMAIL;
        dispatch(getAppDialog(true, constants.LIBRARY_DIALOG, getLocalizedStrings().message.COMMON.LIBRARY_DIALOG_TITLE, handleLibraryResponse, data, constants.SALES_EMAIL));

    }
    const handleLibraryResponse = (obj) => {
        obj.editor.events.enableBlur();
        if (obj.type === 'link' && obj.src !== '') {
            document.getElementsByName('href')[0].value = obj.src;
        } else if (obj.type === 'image' && obj.src !== '') {
            obj.editor.image.insert(obj.src)
        }
        dispatch(getAppDialog(false, constants.LIBRARY_DIALOG, null, null, null, null));
    }

    let isMinimize = getBooleanParam(data.isMinimize);
    if (isMinimize) {
        return <div />;
    }
    let pageHeight = window.innerHeight - 247;
    let type = getStringParam(data.type);
    let batchMailFlag = (data.hasOwnProperty("batch_mail") && data.batch_mail === true) ? true : false;
    var record = getStringParam(data.id);
    let taskId = getIntParam(data.taskId);
    record = record.toString();
    var recordArray = record.split(",");
    var recSize = recordArray.length;
    if (recSize > 1) {
        Froalaeditor.DefineIcon("personalize_dropdown", { NAME: "fa-duotone fa-gear", template: "font_awesome" });
        Froalaeditor.RegisterCommand('personalize_dropdown', {
            title: getLocalizedStrings().label.MAIL.PERSONALIZE,
            type: 'dropdown',
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            options: personalize_fields,
            callback: function (cmd, val) {
                this.html.insert(' ${' + val + '} ');
            },
            // Callback on refresh.
            refresh: function ($btn) {
            },
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {
            }
        });
    }
    Froalaeditor.DefineIcon('signature', { NAME: "fa-regular fa-file-signature", template: "font_awesome" });

    Froalaeditor.RegisterCommand('signature', {
        title: getLocalizedStrings().label.MAIL.ADD_SIGNATURE,
        focus: false,
        undo: true,
        icon: 'signature',
        refreshAfterCallback: true,
        callback: function () {
            getSignature(this);
        }
    });

    Froalaeditor.DefineIcon('library', { NAME: 'image', template: "font_awesome" });
    Froalaeditor.RegisterCommand('library', {
        title: getLocalizedStrings().label.MAIL.INSERT_IMAGE,
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getLibrary(this, 'image')
        }
    });

    Froalaeditor.DefineIcon("insertYouTubeVideo", {
        NAME: "fa-brands fa-youtube", template: "font_awesome"
    });
    Froalaeditor.RegisterCommand('insertYouTubeVideo', {
        title: getLocalizedStrings().message.YOUTUBE_VIDEO.INSERT_TITLE,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getVideoInsertDialog(this, constants.VIDEO_TYPE_YOUTUBE)
        }
    });

    Froalaeditor.DefineIcon("insertWistiaVideo", {
        SRC: "/asset/images/wistia.png",
        ALT: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
        template: "image",
    });
    Froalaeditor.RegisterCommand('insertWistiaVideo', {
        title: getLocalizedStrings().message.WISTIA_VIDEO.INSERT_TITLE,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getVideoInsertDialog(this, constants.VIDEO_TYPE_WISTIA)
        }
    });

    Froalaeditor.DefineIcon('link_library', { NAME: 'file-archive-o', template: "font_awesome" });
    Froalaeditor.RegisterCommand('link_library', {
        title: getLocalizedStrings().label.MARKETING_TEMPLATE.SELECT_FROM_LIBRARY,
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            getLibrary(this, 'link')
        }
    });

    Froalaeditor.DefineIcon('coupon', { NAME: 'ticket', template: "font_awesome" });
    Froalaeditor.RegisterCommand('coupon', {
        title: getLocalizedStrings().label.COMMON.COUPONS,
        type: 'dropdown',
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        options: coupons,
        callback: function (cmd, val) {
            val = val.trim();
            this.html.insert(' ${coupon_code.' + val + '} ');
        },
        // Callback on refresh.
        refresh: function ($btn) {
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
        }
    });

    let FroalaConfig = {
        key: constants.FROALA_EDITOR_KEY,
        placeholderText: getLocalizedStrings().label.MAIL.TYPE_HERE,
        height: document.body.clientHeight - 200,
        toolbarButtons: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsMD: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsSM: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        toolbarButtonsXS: constants.SEND_MAIL_EDITOR_TOOLBAR_CONFIGURATION,
        colorsBackground: constants.EDITOR_TOOLBAR_BACKGROUND_COLOR_PALETTE,
        colorsText: constants.EDITOR_TOOLBAR_TEXT_COLOR_PALETTE,
        toolbarSticky: false,
        enter: FroalaEditor.ENTER_BR,
        iframe: true,
        htmlUntouched: true,
        attribution: false,
        inlineMode: false,
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        linkInsertButtons: ['linkEdit', 'linkRemove', 'link_library'],
        imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'imageDisplay', 'imageAlt', 'imageSize', 'linkOpen', 'linkEdit', 'linkRemove'],
        tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
        quickInsertButtons: [],
        imageUpload: false,
        imagePaste: false,
        fileUpload: false,
        fontSize: constants.EDITOR_FONT_SIZE,
        fontFamily: {
            'Adobe Garamond Pro': 'Adobe Garamond Pro',
            'Arial,Helvetica,sans-serif': 'Arial',
            'Futura Std': 'Futura Std',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            "RobotoSegoe": 'Roboto',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
        },
        fontFamilySelection: true,
        events: {
            'froalaEditor.initialized': function (e, editor) {
                $('.fr-command').on('click', function () {
                    if ($(this).data('cmd') === 'insertLink') {
                        $('input[name="target"]').prop('checked', true);
                    }
                });
            }
        },
        events: {
            'initialized': function () {
                window.WEBSPELLCHECKER.init({
                    container: this.$iframe ? this.$iframe[0] : this.el
                });
            }
        },
        zIndex: 2501
    }


    let mailDrawer = window.innerHeight - 100;
    let top = (mailDrawer - 10) / 2;
    return (
        <div>
            <div className='sf-call-drawer-toolbar'>
                {<div className='sf-drawer-title'>

                    <Typography title={appContainer.customDrawerProps.dialogName} variant="h6" style={{ color: '#fff', paddingLeft: "24px", fontWeight: 400, fontSize: '24px', textAlign: 'left', height: '48px', lineHeight: '48px' ,width:'90%',whiteSpace: 'nowrap',textOverflow: 'ellipsis',overflow:'hidden'}}>
                        {appContainer.customDrawerProps.dialogName}
                    </Typography>
                    <span className='sf-call-drawer-button-right'>

                        <IconButton onClick={(e) => minimizeCustomDrawer(e)}
                            title={'Minimize'}>
                            <i className="fas fa-window-minimize" title="Minimize"
                                style={{ fontWeight: 'normal', fontSize: '1.0em', cursor: 'pointer', color: '#fff', marginLeft: 5 }}
                            ></i>
                        </IconButton>
                        <IconButton
                            onClick={() => closeDrawer()}
                            title={getLocalizedStrings().label.COMMON.CLOSE}>
                            <Icon style={{ color: '#fff', marginTop: 2 }}> close</Icon>
                        </IconButton>
                    </span>
                </div>}
            </div>
            {!isMounted &&
                <div style={{ width: '100%', height: pageHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
            {isMounted &&
                <div style={{ height: mailDrawer, overflowY: 'scroll' }}>
                    <div style={{ width: '94%', margin: '10px 0px 15px 30px' }} ClassName='container'>
                        <div style={{ marginTop: '10px', marginBottom: '15px', height: '28px' }}>
                            <div className="col-sm-6" style={{ float: 'right', marginRight: '-5px', width: 'auto' }}>
                                <div style={{ ...styles.emailSendBtn, float: "left" }}>
                                    <div style={{ float: 'left', margin: 5, textAlign: 'center', padding: '0px 15px' }} onClick={() => handelSaveMail()}>{getLocalizedStrings().label.COMMON.SAVE}</div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        onClick={() => closeDrawer()}

                                        style={{ ...styles.secondaryButton, height: 32 }}
                                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className={taskId > 0 ? "col-sm-12" : "col-sm-12"}>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <TextField
                                        variant="outlined"
                                        onChange={(event) => setFieldValue(event, 'subject')}
                                        value={template['subject']}
                                        label={getLocalizedStrings().label.MAIL.SUBJECT}
                                        underlineFocusStyle={styles.textField.underlineFocus}
                                        inputStyle={styles.textField.input}
                                        fullWidth={true}
                                        style={{ ...styles.sfFormFieldContainer }}
                                        className={"sf-fields-bg"}
                                        autoComplete="new-password"
                                    />
                                </FormControl>
                            </div>

                            {taskId > 0 && <div className="col-sm-2" style={{ display: 'none' }}>
                                <div key='taskActionDiv' onClick={() => handleTaskActionOpen()}
                                    style={{ ...styles.sfFormFieldContainer, 'width': '100%', cursor: "pointer", fontSize: '14px', color: '#717171' }}>
                                    <div>{getLocalizedStrings().label.MAIL.RESCHEDULE} </div>
                                    <span className="ellip">{
                                        taskActionsIdValue.map(f => {
                                            if (f.id === taskActionValue) {
                                                return f.value;
                                            }
                                        })
                                    }
                                        <i className="fa fa-angle-down" aria-hidden="true" style={{ paddingLeft: '7px' }}></i>
                                    </span>
                                    <Menu
                                        key='taskAction'
                                        name='taskAction'
                                        open={isOpenTaskAction}
                                        anchorEl={taskAnchor}
                                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                        onClose={handleTaskActionClose}
                                        value={taskActionValue}
                                        onChange={setTaskAction}
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            taskActionsIdValue.map(obj => {
                                                return <MenuItem
                                                    style={styles.popoverMenuItem}
                                                    fullWidth={true}
                                                    labelstyle={{ color: '#479ccf', fontSize: '12px' }}
                                                    underlinestyle={{ display: 'none' }}
                                                    autowidth={"true"}
                                                    className='pomenuitem'
                                                    value={obj.id}
                                                >{obj.value}</MenuItem>
                                            })
                                        }
                                    </Menu>
                                </div>
                            </div>}
                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-sm-12">
                                <div>
                                    {getEditor(template.email_message, FroalaConfig)}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '10px', height: '28px' }}>
                            <div className="col-sm-3" style={{ float: "right", marginRight: "-5px", width: 'auto' }}>
                                <div style={{ ...styles.emailSendBtn, float: "left" }}>
                                    <div style={{ float: 'left', margin: 5, textAlign: 'center', padding: '0px 15px' }} onClick={() => handelSaveMail()}>{getLocalizedStrings().label.COMMON.SAVE}</div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <Button
                                        onClick={closeDrawer}

                                        style={{ ...styles.secondaryButton, height: 32 }}
                                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {getAttachmetFiles()}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default EditMail;


