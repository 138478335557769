import React, { useEffect, useState } from 'react';
import { styles } from '../../../services/constants/styles';
import Calendar from './Calendar';
import DetailViewTaskApps from '../components/detailViewTaskApps';
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
import { Menu, MenuItem, Icon, IconButton, Button } from '@mui/material';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { constants, QUEUE_LIST, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { openListView } from '../../../services/actions/listViewAcions';
import { loadQueueList, getQueueList, openDetailviewQueueTab } from '../../../services/actions/queueActions';
import { isValidParam, getObjectParam, getStringParam, getIntParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { LinkToType } from '../../../services/constants/link';
import { getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { getAurinkoConfigDetails, setNylasConfigDetails } from '../../../services/actions/UserListAction';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getActiveTab, updateActiveTab } from '../../../services/helper/sfTabManager';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { useDispatch, useSelector } from "react-redux";
import TasksPage from "../components/tasksPage";
import { getCalendarRefresh,setSelectedDate } from '../../../services/actions/calendarActions';
import { refreshColumnView } from "../../../services/actions/listsActions";
import * as sfDialogs from '../components/sfDialogs';

let personalizeList = [
    {
        value: 'Overdue',
        label: getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_OPTION_1,
    },
    {
        value: 'Today',
        label: getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_OPTION_2,
    },
    {
        value: 'Tomorrow',
        label: getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_OPTION_4,
    },
    {
        value: 'Future',
        label: getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_OPTION_3,
    }
];
let sortList = [
    {
        value: 'Date',
        label: getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_SORT_1,
    },
    {
        value: 'Priority',
        label: getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_SORT_2,
    }
];

const CalendarTask = ({ object, type }) => {

    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const userList = useSelector((state) => state.userlist);
    const task = useSelector((state) => state.task);
    const calendar = useSelector((state) => state.calendar);
    const queueList = useSelector((state) => state.queueList);

    const [calendarViewControl, setCalendarViewControl] = useState({ right: null, rightMin: false });
    const [selectedOption, setSelectedOption] = useState(getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_SORT_1);
    const [isScroll, setIsScroll] = useState(false);
    const [selectedUser, setSelectedUser] = useState(app.me.id);
    const [selectedSalesRep, setSelectedSalesRep] = useState(app.me.name);
    const [selectedSortOption, setSelectedSortOption] = useState(getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_SORT_1);
    const [isNylasSetup, setIsNylasSetup] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [isAllTakView, setIsAllTakView] = useState(false)
    const [isAppointmentsOnly, setIsAppointmentsOnly] = useState(null)

    const [menuAncher, setMenuAncher] = useState({
        userlist: null,
        personalizeList: null,
        sortList: null,
    });

    useEffect(() => {
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "calendarMin-right" + projectId + '-' + userId;
        let taskcookieName = "task-view-mode" + projectId + '-' + userId;
        let isAllTakViewIncookie = JSON.parse(getCookie(taskcookieName));
        let _isAllTakView = app.me.calendar_setup[0]?.defaultView == "Collapsed" ? false :true; 
        if(isAllTakViewIncookie != null ){
            _isAllTakView = isAllTakViewIncookie;
            setIsAllTakView(_isAllTakView);
        } else if(_isAllTakView != null){
             _isAllTakView = app.me.calendar_setup[0]?.defaultView == "Collapsed" ? false :true; 
             setIsAllTakView(_isAllTakView);
        }
        refreshColumnView('tasks');

    }, [app.me.calendar_setup[0]?.defaultView])

    useEffect(() => {
        if (type === "calendar" || type === "calendaronly") {
            setIsAppointmentsOnly(true)
        } else {
            setIsAppointmentsOnly(false)
        }
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "calendarMin-right" + projectId + '-' + userId;
        let taskcookieName = "task-view-mode" + projectId + '-' + userId;
        let leftCalanderView =  "calendarMin-left" + projectId + '-' + userId;
        let _leftCalanderView = JSON.parse(getCookie(leftCalanderView));

        let view = JSON.parse(getCookie(cookieName));
        let isAllTakViewIncookie = JSON.parse(getCookie(taskcookieName));
        let _isAllTakView = app.me.calendar_setup[0]?.defaultView == "Collapsed" ? false :true; 
        if(isAllTakViewIncookie != null ){
            _isAllTakView = isAllTakViewIncookie;
            setIsAllTakView(_isAllTakView);
        }
        // if(_leftCalanderView !== null && _leftCalanderView == false ){
        //     setIsAllTakView(false);
        // }
        
        else if(_isAllTakView != null){
             _isAllTakView = app.me.calendar_setup[0]?.defaultView == "Collapsed" ? false :true; 
             setIsAllTakView(_isAllTakView);
        }
      
        if (view !== null) {
            setCalendarViewControl({ right: view ? 50 : '23%', rightMin: view })
        } else {
            setCalendarViewControl({ right: '23%', rightMin: false })

        }
        try {
            getNylasConnection();
        } catch (error) {
            console.error("Error in 'CalendarTask.js -> componentDidMount()':" + error);
        }
    }, [type])
    

    const getNylasConnection = () => {
        try {
            let isNylasSetup = false;
            let data = {};
            if (userList.nylasConfigDetails !== null &&
                userList.nylasConfigDetails !== undefined &&
                userList.nylasConfigDetails.length > 0 &&
                userList.nylasConfigDetails.ConfigDetails.length > 0) {
                let dataLoaded;
                if (userList.nylasConfigDetails.isNylasSetup) {
                    dataLoaded = true;
                    isNylasSetup = true;
                } else {
                    dataLoaded = true;
                    isNylasSetup = false;
                }
                setIsNylasSetup(isNylasSetup)
                setDataLoaded(dataLoaded)
            } else {
                let param = { onload: 'onload' };
                let promise = getAurinkoConfigDetails(param);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        let dataLoaded = true;
                        if (isValidParam(response)) {
                            let list = response;
                            if (list !== null && list.length > 0) {
                                data.ConfigDetails = list;
                                data.isNylasSetup = true;
                                isNylasSetup = true;

                            } else {
                                data.ConfigDetails = [{}];
                                data.isNylasSetup = false;
                            }
                        } else {
                            data.ConfigDetails = [{}];
                            data.isNylasSetup = false;
                        }
                        setIsNylasSetup(isNylasSetup)
                        setDataLoaded(dataLoaded)
                    });
                }
                dispatch(setNylasConfigDetails(data))

            }




        } catch (error) {
            console.error("Error in 'calenderTask.js -> getNylasConnection()':" + error);
        }
    }
    const handleMenuToggle = (event, data) => {
        let menuAnc = menuAncher;
        if (menuAnc[data] == null) {
            menuAnc[data] = event.currentTarget;
        } else {
            menuAnc[data] = null;
        }
        setMenuAncher({ ...menuAnc });
    }
    const cookieSetTaskOption = (value) => {
        let userid = app.me.id;
        let cVarName = "task_" + userid
        document.cookie = cVarName + "=" + value;
    }
   

    const cookieSetSalesRepOption = (value) => {
        let userid = app.me.id;
        let cVarName = "salesrep_" + userid;
        document.cookie = cVarName + "=" + value;
    }

    const cookieGetSalesRepOption = () => {
        let userid = app.me.id;
        let userName = app.me.name;
        let cVarName = "salesrep_" + userid + "=";
        let cookieVar = decodeURIComponent(document.cookie).split(';');
        let tempUser = app.me.name;
        if (userList.mounted) {
            let tab = getActiveTab();
            let userlist = userList.data;
            for (var i = 0; i < cookieVar.length; i++) {
                var c = cookieVar[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(cVarName) === 0) {
                    let uId = c.substring(cVarName.length, c.length);
                    uId = parseInt(uId);
                    let tempUserList = userlist.filter(f => { return f.id === uId });
                    if (tempUserList.length > 0) {
                        // setSelectedUser(uId)
                        // setSelectedSalesRep(tempUserList[0].value)
                        tempUser = tempUserList[0].value;
                        return tempUserList[0].value;
                    }
                }
                // if(tab!==null){
                //     tab.info = { ...tab.info, userName: tempUser, status: ["Pending"], callFrom: 'task_listview' };
                //     updateActiveTab(tab);
                // }
            }
            return userName;

        }

    }

    const handleMenuChange = (value) => {
        cookieSetTaskOption(value)
        setSelectedOption(value)
    }
    const changeUserTask = (value, userName) => {
        cookieSetSalesRepOption(value)
        setSelectedUser(value)
        let tab = getActiveTab();
        tab.info = { ...tab.info, userName: userName, status: ["Pending"], callFrom: 'task_listview' };
        updateActiveTab(tab);
    }

    const handleSortMenuChange = (value) => {
        cookieSetSortOption(value);
        setSelectedSortOption(value)
    }

    const cookieSetSortOption = (value) => {
        let userid = app.me.id;
        let cVarName = "sort_" + userid
        document.cookie = cVarName + "=" + value;
    }

    

    const resetScroll = () => {
        setIsScroll(false)
    }

    const getMenuItemList = (data) => {
        let List = [];
        switch (data) {
            case 'userOptions':
                let userlist = userList.data;
                userlist = userlist.filter((e)=>{return e.value != "Campaigns"});
                userlist.map(ul =>
                    List.push(<MenuItem key={ul.id} value={ul.id} onClick={() => changeUserTask(ul.id, ul.value)} style={styles.popoverMenuItem}>{ul.value}</MenuItem>)
                );
                break;
            case 'personalizeList':
                personalizeList.map(pl =>
                    List.push(<MenuItem key={pl.value} value={pl.value} onClick={() => handleMenuChange(pl.value)} style={styles.popoverMenuItem}>{pl.label}</MenuItem>)
                );
                break;
            case 'sortListOptions':
                sortList.map(sl =>
                    List.push(<MenuItem key={sl.value} value={sl.value} onClick={() => handleSortMenuChange(sl.value)} style={styles.popoverMenuItem}>{sl.label}</MenuItem>)
                );
                break;
            default:
                userlist.map(ul =>
                    List.push(<MenuItem key={ul.id} value={ul.id} style={styles.popoverMenuItem}>{ul.value}</MenuItem>)
                );
        }

        return List;
    }

    const openQueue = (event, value) => {
        let portalQueueTasks = [];
        let portalTasks = getArrayParam(task.data);
        if (portalTasks.length > 0) {
            portalQueueTasks = portalTasks.filter(f => { return f.type === constants.TASKS });
        }
        try {
            if (portalQueueTasks.length > 0) {
                let object = getStringParam(constants.TASKS);

                /* API call propeties*/
                let initilaParam = {};
                initilaParam.object = constants.TASKS;
                initilaParam.call_from_queue = true;

                let sort_option = app.me.calendar_setup.length > 0? app.me.calendar_setup[0].sortOrder:'Date';
                let sort_field_name = sort_option === 'Date' ? 't_dueby' : 't_priority';
                initilaParam = {
                    ...initilaParam,
                    module_id: "Today",
                    fetch_size: QUEUE_LIST.FETCH_SIZE,
                    sort_field_name: sort_field_name,
                    sort_type: 'desc',
                    call_from: constants.CALENDAR_TASK,
                    start_index: 0,
                    sales_rep: cookieGetSalesRepOption(),
                    sort_order: sort_option,
                };
                object = object.toLowerCase();
                let promise = Promise.resolve(getQueueList(object, initilaParam));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let tempqueueList = response.records;
                        let totalCount = getIntParam(response.total_count);
                        if (isValidParam(tempqueueList) && tempqueueList.length > 0) {
                            let recordIndex = 0;
                            let recordId = tempqueueList[0].id;
                            let label = tempqueueList[0].title;
                            response['openedRecordInQueueId'] = recordId;
                            response['openedRecordInQueueIndex'] = recordIndex;
                            response['openedRecordInQueuelabel'] = label;
                            if (object === constants.TASKS) {

                                if (queueList.hasOwnProperty('data') && queueList.data.hasOwnProperty('selecteddrpdwnObj') && queueList.data.selecteddrpdwnObj !== undefined && queueList.data.selecteddrpdwnObj !== null && queueList.data.selecteddrpdwnObj !== "") {
                                    response['selecteddrpdwnObj'] = queueList.data.selecteddrpdwnObj;
                                } else {
                                    response['selecteddrpdwnObj'] = object;
                                }

                            }
                            if (queueList.hasOwnProperty('data') && queueList.data.hasOwnProperty('selecteddrpdwnObj') && queueList.data.selecteddrpdwnObj !== undefined && queueList.data.selecteddrpdwnObj !== null && queueList.data.selecteddrpdwnObj !== "") {
                                let linkInfo;
                                
                                let islinkpresent = false;
                                linkInfo = tempqueueList[0].linkInfo;
                                if (linkInfo !== null && linkInfo !== "" && linkInfo !== undefined && queueList.data.selecteddrpdwnObj !== constants.TASKS) {
                                    let linkInfoArr = linkInfo.split(',');
                                    let selectedObjId = getIntParam(OBJECT_TABLEID_MAP[queueList.data.selecteddrpdwnObj]);
                                    linkInfoArr.map((item, i) => {
                                        let ObjRecordArr = item.split('_');
                                        let tableId = getIntParam(ObjRecordArr[0].trim());
                                        if (selectedObjId === tableId) {
                                            recordId = getIntParam(ObjRecordArr[1]);
                                            object = queueList.data.selecteddrpdwnObj;
                                            islinkpresent = true;
                                        }
                                        return 0;
                                    });

                                    openDetailviewQueueTab(object, label, recordId, recordIndex, totalCount);
                                } else {
                                    openDetailviewQueueTab(constants.TASKS, label, recordId, recordIndex, totalCount);
                                }
                                if (!islinkpresent) {
                                    let parentItems = app.objectList;
                                    parentItems = parentItems.filter(f => { return f.name === queueList.data.selecteddrpdwnObj })
                                    if (parentItems.length > 0) {
                                        let msg = label + " doesn’t have any associated " + parentItems[0].label;
                                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                    }

                                }
                            } else {
                                openDetailviewQueueTab(constants.TASKS, label, recordId, recordIndex, totalCount);
                            }

                            loadQueueList(getObjectParam(response));
                        }
                    }
                })
            }
        } catch (error) {
            console.error("error in CalendarTask.js --> openQueue" + error)
        }
    }

    const openSettings = (event, type) => {
        let label;
        if (type === LinkToType.TYPE_CALENDAR_SETTINGS) {
            label = getLocalizedStrings().label.CALENDAR.MY_CALENDAR_SETTING;
        } else {
            label = getLocalizedStrings().label.CALENDAR.MY_TASK_SETTING;
        }
        let data = {};
        (type === LinkToType.TYPE_CALENDAR_SETTINGS) ? data.type = constants.APPOINTMENTS : data.type = constants.TASKS;

        let style = {};
        style.width = '36%';
        dispatch(getAppCustomDrawer(true, label, type, style, data, null, false))

    }
    const minimizeLeftRightCard = (from) => {
        if (from === 'right') {
            setCalendarViewControl({ right: !calendarViewControl.rightMin ? 50 : '23%', rightMin: !calendarViewControl.rightMin })
        }

        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "calendarMin-right" + projectId + '-' + userId;
        setCookie(cookieName, !calendarViewControl.rightMin);
    }
    const syncCalendarNow = () => {
        try {
            let isAurinkoReAuthRequired = app.me.is_aurinko_reauth_required;
            if (isAurinkoReAuthRequired) {
                let msg = getLocalizedStrings().message.MY_INTEGRATION.RE_AUTHENTICATE_AURINKO;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
            } else {
                let promise = Promise.resolve(HTTPClient.post(endPoints.APPOINTMENT.CALENDAR_SYNC));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            if (isValidParam(response) && response.hasOwnProperty('status')) {
                                if (response.status === 0) {
                                    if (response.hasOwnProperty('data')) {
                                        let syncCal = getStringParam(response.data?.sync_calendar);
                                        if (syncCal === 'No') {
                                            dispatch(showCustomSnackBar(getLocalizedStrings().message.APPOINTMENTS_TASKS.ENABLE_SYNC_CAL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                        } else {
                                            dispatch(showCustomSnackBar(getLocalizedStrings().message.APPOINTMENTS_TASKS.SYNC_STARTED_MSG, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                        }
                                    } 
                                } else {
                                    dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                }
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'calendars.js -> syncCalendarNow()':" + error);
        }
    }

    const updateCalenderDateTimeLine = () => {
        var sObj = document.querySelector(".e-schedule").ej2_instances[0];
        var currentDates = sObj.getCurrentViewDates();
        var currentView = sObj.viewIndex;
        var startDate = currentDates[0];
        if (currentView === 3) {
            startDate = currentDates[15];
        }
        if (startDate !== null && startDate !== undefined) {
            dispatch(setSelectedDate(startDate));
        }
    };
    const getAllTaskView = (from) => {

        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "calendar-view-mode" + projectId + '-' + userId;
        let taskcookieName = "task-view-mode" + projectId + '-' + userId;
        let tempcookieName = "calendarMin-left" + projectId + '-' + userId;
        let view = JSON.parse(getCookie(tempcookieName));
        if (from == 'open') {
            setCookie(cookieName,'Day' );
            setIsAllTakView(true);
            setCookie(taskcookieName, true);
            calendar.calendarInfo.calendarView = 'Day';
            if(view != null && !view){
                setCookie(tempcookieName, !view);
            }
            dispatch(getCalendarRefresh(true));
        } else if(from =='close-cal'){
            setIsAllTakView(false);
            setCookie(taskcookieName, false);
           // calendar.calendarInfo.calendarView = 'Week';
            updateCalenderDateTimeLine();
            dispatch(getCalendarRefresh(true));
        }else {
            setIsAllTakView(false);
            setCookie(taskcookieName, false);
            calendar.calendarInfo.calendarView = 'Week';
            updateCalenderDateTimeLine();
            dispatch(getCalendarRefresh(true));
        }
    }
    let propsTaskApp = {}
    let taskLabel = "Today";
    let taskLabelOption = '';
    if (taskLabel === "Today") {
        taskLabel = getLocalizedStrings().label.COMMON.TODAYS;
        taskLabelOption = getLocalizedStrings().label.COMMON.TODAY;
    }
    else if (taskLabel === "Future") {
        taskLabel = getLocalizedStrings().label.COMMON.FUTURE;
        taskLabelOption = getLocalizedStrings().label.COMMON.FUTURE;
    }
    else if (taskLabel === "Overdue") {
        taskLabel = getLocalizedStrings().label.COMMON.OVERDUE;
        taskLabelOption = getLocalizedStrings().label.COMMON.OVERDUE;
    }
    else if (taskLabel === "Tomorrow") {
        taskLabel = getLocalizedStrings().label.COMMON.TOMORROWS;
        taskLabelOption = getLocalizedStrings().label.COMMON.TOMORROW;
    }


    if (userList.mounted) {
        propsTaskApp = {
            callFrom: 'Tasks',
            moduleId: "Today",
            isScroll: isScroll,
            salesRep: cookieGetSalesRepOption(),
            sortOrder: app.me.calendar_setup.length > 0? app.me.calendar_setup[0].sortOrder:'Date',

        }
        let tab = getActiveTab();
        if (tab !== null) {
            tab.info = { ...tab.info,moduleId:"Today" ,sortOrder: app.me.calendar_setup.length > 0? app.me.calendar_setup[0].sortOrder:'Date',userName: cookieGetSalesRepOption(), status: ['Pending', 'In-progress'], callFrom: 'task_listview' };
            updateActiveTab(tab);
        }
    }

    let sortLabel = app.me.calendar_setup.length > 0? app.me.calendar_setup[0].sortOrder:'Date';
    sortLabel = sortLabel !== null && sortLabel === 'Date' ? getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_SORT_1 : getLocalizedStrings().label.APPOINTMENTS_TASKS.PERSONALIZE_SORT_2;
    let queueIconStyle = { fontSize: '13px', color: '#717171', cursor: 'pointer', paddingTop: '0px', paddingLeft: '4px', opacity: ' 0.8' }
    let portalTasks = getArrayParam(task.data);
    let portalQueueTasks = [];
    if (portalTasks.length > 0) {
        portalQueueTasks = portalTasks.filter(f => { return f.type === constants.TASKS });
    }

    if (portalQueueTasks.length <= 0) {
        queueIconStyle = { fontSize: '13px', color: 'grey', cursor: 'pointer', paddingTop: '0px', paddingLeft: '4px', opacity: ' 0.6' }
    }

    let calendarTaskHeight = window.innerHeight - 190;
    let cheight = calendarViewControl.rightMin ? calendarTaskHeight - 80 : calendarTaskHeight - 80;

    return (
        <div style={{ height: calendarTaskHeight - 60 }}>
            <div style={{ ...styles.row }}>
                <div style={{ ...styles.sf_5 }}>
                    <div style={{ padding: '6', display: 'flex', float: 'left' }}>
                        <Button
                            key='settings'
                            id="calendar-settings"
                            onClick={(e) => openSettings(e, LinkToType.TYPE_CALENDAR_SETTINGS)}
                            title= {getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT_SETTINGS}
                            style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top', backgroundColor: '#ffffff', minWidth: '4%', marginLeft: '4px', width: '31px' }}
                        >
                            <i className="fas fa-cog" style={{ color: '#717171', cursor: 'pointer' }} ></i>
                        </Button>

                        {isNylasSetup &&
                            <Button
                                key='sync'
                                id="sync-now"
                                onClick={syncCalendarNow}
                                title={getLocalizedStrings().label.COMMON.SYNC_NOW}
                                style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top', backgroundColor: '#ffffff', minWidth: '4%', marginLeft: '4px', width: '31px' }}
                            >
                                <i className="fas fa-sync" style={{ color: '#717171', fontSize: '15px', cursor: 'pointer' }} ></i>
                            </Button>
                        }
                        <Button
                            key='All-Appointments'
                            id="c-All-Appointments"
                            onClick={() => openListView(constants.APPOINTMENTS, 'All Appointments')}
                            title={getLocalizedStrings().label.COMMON.ALL_APPOINTMENTS}
                            style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top', backgroundColor: '#ffffff', minWidth: '4%', marginLeft: '4px', width: '31px' }}
                        >
                            <Icon style={{ color: '#717171', cursor: 'pointer' }}>list</Icon>
                        </Button>


                      
                    </div>

                </div>
                
                {!isAllTakView && calendarViewControl.right !== null &&
                    <div  style={{ minHeight: '42px' }}>

                        <div>
                            <div >
                                <div style={{ display: 'flex' }}>
                                    {!calendarViewControl.rightMin && <div style={{ width: '50px', marginTop: '-1px' ,position: 'absolute',right:'22.2%'}}>
                                        <IconButton onClick={() => getAllTaskView("open")} >
                                            <i className='fas fa-chevron-left' style={{ fontSize: '20px' }}></i>
                                            <i className='fas fa-chevron-left' style={{ fontSize: '20px' }}></i>
                                        </IconButton>
                                    </div>}
                                    
                                        <div style={{ width: calendarViewControl.rightMin?'':'50px', marginTop: '-1px', position: 'absolute', right: calendarViewControl.rightMin?'2%':'19%' }}>
                                            <IconButton onClick={() => minimizeLeftRightCard("right")} >
                                                {calendarViewControl.rightMin ?
                                                    <div style={{}}>
                                                        <i className="fas fa-chevron-left" style={{ fontSize: '25px' }}></i>
                                                        <i className="fas fa-chevron-left" style={{ fontSize: '25px' }}></i>
                                                    </div>

                                                    :
                                                    <div>
                                                        <i className="fas fa-chevron-right" style={{ fontSize: '20px' }}></i>
                                                        <i className="fas fa-chevron-right" style={{ fontSize: '20px' }}></i>
                                                    </div>
                                                }
                                            </IconButton>
                                        </div>
                                    

                                    {!calendarViewControl.rightMin && 
                                    <div onClick={(e) => handleMenuToggle(e, 'userlist')} style={{ border: '1px solid rgb(224, 224, 224)', borderRadius: 3, display: 'flex', width: '10%', height: '33px', paddingTop:'6px' ,paddingLeft:'8px',marginTop:'3px', cursor: 'pointer',right:'119px',position:'absolute'}}>

                                        <div style={{ display: 'inline-block', width: 160, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#9e9e9e' }} title={cookieGetSalesRepOption()}>
                                            <span>{cookieGetSalesRepOption()}</span>
                                        </div>
                                        <i className="material-icons" style={{ cursor: 'pointer' }} >keyboard_arrow_down</i>
                                        <Menu
                                            open={Boolean(menuAncher.userlist)}
                                            anchorEl={menuAncher.userlist}
                                            keepMounted
                                            name='userList' >
                                            {getMenuItemList('userOptions')}
                                        </Menu>
                                    </div>}
                                   

                                </div>

                            </div>
                        </div>
                    </div>
                }

                <div>
                        {!isAllTakView && !calendarViewControl.rightMin && <div style={{ padding: '7', display: 'flex',position:'absolute',right:'11px', marginRight:'-5px' }}>
                            <Button
                                key='listView'
                                id="task-listView"
                                title={getLocalizedStrings().label.COMMON.ALL_TASKS}
                                onClick={() => openListView(constants.TASKS, 'All Tasks')}
                                style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top', backgroundColor: '#ffffff', minWidth: '4%', marginLeft: '4px', width: '31px' }}
                            >
                                <i className="material-icons" style={{ color: '#717171', cursor: 'pointer', marginLeft: '1px' }}>list</i>
                            </Button>
                            <Button
                                key='queue'
                                id="task-queue"
                                onClick={(event) => openQueue(event, constants.QUEUE)}
                                title={getLocalizedStrings().label.QUEUE.QUEUE}
                                style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top', backgroundColor: '#ffffff', minWidth: '4%', marginLeft: '4px', width: '31px' }}
                            >
                                <i className="fas fa-play" aria-hidden="true" style={queueIconStyle}></i>
                            </Button>

                            <Button
                                key='settings'
                                id="calendar-settings"
                                onClick={(e) => openSettings(e, LinkToType.TYPE_TASK_SETTINGS)}
                                title={getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK_SETTINGS}
                                style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top', backgroundColor: '#ffffff', minWidth: '4%', marginLeft: '4px', width: '31px' }}
                            >
                                <i className="fas fa-cog" style={{ color: '#717171' }} ></i>
                            </Button>

                        </div>}
                        
                </div>

            </div>
            <div style={{ ...styles.row }}>
                <div style={isAllTakView ? { ...styles.sf_p30 } : { ...styles.sf_12}}>
                    <Calendar object={object} type={type} getAllTaskView={getAllTaskView} />
                </div>
                {isAllTakView && <div style={{ ...styles.sf_p70, marginTop: '-40px' }}>
                    <TasksPage object={constants.TASKS} getAllTaskView={getAllTaskView} cookieGetSalesRepOption = {cookieGetSalesRepOption} cookieSetSalesRepOption = {cookieSetSalesRepOption}/>
                </div>}
                {!isAllTakView && <div  style={{ width: calendarViewControl.right }}>
                    {calendarViewControl.right !== null &&
                        <div>
                            <div className="card">
                               
                                <div className="card-body">
                                    <div style={{ height: cheight, overflowY: 'auto', fontSize: "14px" }} id="task-list">

                                        {!calendarViewControl.rightMin && <div>
                                            <div id="task_list">
                                                <DetailViewTaskApps parentObject={object} propsTaskApp={propsTaskApp} resetScroll={resetScroll} />
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                </div>}
            </div>
        </div>
    )

}

export default CalendarTask;
